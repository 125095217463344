import React from 'react';
import { loadModules } from 'esri-loader';
import ImageGallery from 'react-image-gallery';
import auth from "../common/Auth";
import PropTypes from "prop-types";
import VideoPanel from './VideoPanel';
import MapTwoIcon from '@material-ui/icons/MapTwoTone';
import SearchIcon from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import { GLOBAL } from '../../Global';
import { BarLoader } from 'react-spinners';
import FormDataPage from './FormDataPage';
import { Button } from 'react-bootstrap';
import Functions from '../../Functions/Functions';
import MarkerUtilty from '../../Utility/MarkerUtility';
import OrgUtility from '../../Utility/Utility';
import ReactDOMServer from 'react-dom/server'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

export class WebMapView extends React.Component {
  
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.MarkerUtil = new MarkerUtilty();
    GLOBAL.ASSIGNMENT = {};
    this.state = {
      map: null,
      mapLayerType: [],
      currentFullscreenImage : [],
      images : [],
      videos : [],
      videoCount : 0,
      pictureCount : 0,
      loadingMultimedia : false,
      assignedCustomFormColor : '#3c5873',
      assignedUserColor : '#3c5873',
      newTaskAddressColor : '#3c5873',
      addressSearch : '',
      baseArcgisURL : "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/",
      addressSuggestions : [],
      currentLat : 0,
      currentLon : 0,
      editingNewTask : false,
      mapReportTitle : '',
      mapReportTitleColor : "black",
      arcGisConnectedLayersAccessToken : "",
      defaultLayerColor : "#ff6347",
      addressSearchForSearch : '',
      addressSearchViewOpen : false,
      viewingDetails : false,
      entityToView : null
    };
    this.layerDictionary = {};
    //this.legendStyle = [];
    this.assignTask = this.assignTask.bind(this);
    this.deletePoint = this.deletePoint.bind(this);
    this.markerStyle={};
    this.legendStyle = {};
    this.isFirstLoad = true;
    this.clickThumbNail = this.clickThumbNail.bind(this);
    this.getVideo = this.getVideo.bind(this);
    this.controller = new AbortController();
    this.setVideoIndex = this.setVideoIndex.bind(this);
    this.isFirstRerender = true;
    this.rerenderingMap = false;
    this.updateExtent = true;
    this.setNewTaskLatLon = this.setNewTaskLatLon.bind(this);
    this.createAndAssignTask = this.createAndAssignTask.bind(this);
    this.getUserList = this.getUserList.bind(this);
    this.searchAddress = this.searchAddress.bind(this);
    this.createTask = this.createTask.bind(this);
    this.selectSuggestedAddress = this.selectSuggestedAddress.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.uploadDoc = this.uploadDoc.bind(this);
    this.createMapReport = this.createMapReport.bind(this);
    this.colorLegendItems = this.colorLegendItems.bind(this);
    this.archiveSurvey = this.archiveSurvey.bind(this);
    this.openDetailsView = this.openDetailsView.bind(this);
    this.viewEntity = this.viewEntity.bind(this);
  }


  assignTaskCollection(assignmentUserId, formTasks){
    if(auth.isSuperViewer()){
      return;
    }
    if(assignmentUserId == "-1"){
      this.props.showProgressBar("Unassigning Tasks");
      let body = {
        UserId : auth.employeeId(),
        CompanyId : auth.companyId(),
        FormTaskList : formTasks
      }

      fetch(process.env.API_URL + "/api/formTaskAssignment/unassign",{
        method : "POST",
        headers: {
          Accept : "application/json",
          "Content-Type" : "application/json; charset=utf-8",
          Authorization : "Bearer " + auth.token()
        },
        body: JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          r.json().then(bodyJson =>{
            let json = JSON.parse(bodyJson);
            if(json.FormTaskList){
              this.props.updateResultsFromTaskUnassign(json.FormTaskList);
            }
            this.props.showProgressBar("");
            this.props.setAlert("success", "Tasks Unassigned");
            this.updateExtent = false;
            setTimeout(() =>{this.setState({updateAssignmentArea : false}); this.rerenderMap();},1000);
          });
        }
        else{
          this.props.showProgressBar("");
          this.props.setAlert("error", "Error unassigning tasks.");
        }
      })
      .catch(error =>{
        this.props.showProgressBar("");
        this.props.setAlert("error", "Error unassigning tasks.");
        console.log(error.message);
      });
    }
    else{
      this.props.showProgressBar("Assigning Tasks");
      let body = {
        UserId : auth.employeeId(),
        CompanyId : auth.companyId(),
        FormTaskList : formTasks
      }
      fetch(process.env.API_URL + "/api/formTaskAssignment/assignCollection",{
        method : "POST",
        headers : {
          Accept : "application/json",
          "Content-Type" : "application/json; charset=utf-8",
          Authorization : "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(r => {
        if(r.status == 200){
          r.json().then(bodyJson =>{
            let json = JSON.parse(bodyJson);
            if(json.FormTaskList){
              json.FormTaskList.forEach(formTask =>{
                this.props.updateResultsFromAssignment(formTask);
              });
            }
            this.props.showProgressBar("");
            this.props.setAlert("success", "Tasks Assigned.");
            this.updateExtent = false;
            setTimeout(() =>{this.setState({updatingAssignment : false}); this.rerenderMap();}, 1000);
          });
        }
        else{
          this.props.showProgressBar("");
          this.props.setAlert("error", "Error assigning tasks.");
        }
      })
      .catch(error =>{
        this.props.showProgressBar("");
        this.props.setAlert("error", "Error assigning tasks.");
        console.log(error.message);
      });
    }
    this.state.sketchLayer.remove(GLOBAL.ASSIGNMENT.graphic);
    GLOBAL.ASSIGNMENT.graphic = null;
    GLOBAL.ASSIGNMENT.geometry = null;
  }


  updateAssignmentArea(assignmentUserId, username, gpsSurveyAssignmentId, deleteAssignment){
    if(auth.isSuperViewer()){
      this.props.showProgressBar('');
      return;
    }
    let me = this;
    this.props.showProgressBar("Saving Update...");
    let now = new Date();
    let body = {
      UserId: auth.employeeId(),
      CompanyId : auth.companyId(),
      Geometry: GLOBAL.ASSIGNMENT.geometry,
      AssignedUserId: assignmentUserId,
      GPSSurveyAssignmentId : gpsSurveyAssignmentId,
      DeleteAssignmentArea : deleteAssignment,
      UTCOffset : -now.getTimezoneOffset() / 60
    };
    fetch(process.env.API_URL + "/api/gpsSurveyAssignmentupdate",{
      method: "POST",
      headers:{
        Accept: "application/json",
        "Content-Type":"application/json;charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(bodyJson =>{
          if(bodyJson.length > 0){
            let json = JSON.parse(bodyJson);
            if(json.GpsSurveyAssignment){
              this.props.updateResultsFromGPSSurveyAssignment(json.GpsSurveyAssignment);
            }
            this.props.showProgressBar("");
            this.props.setAlert("success", "Assignment Area Updated.");
            this.updateExtent = false;
            setTimeout(()=>{this.rerenderMap();},1000);
          }
        });
      }
      else{
        this.props.showProgressBar("");
        this.props.setAlert("error", "Error updating assignment area");
      }
    })
    .catch(error =>{
      console.log(error.message);
      this.props.showProgressBar("");
      this.props.setAlert('error','Error updating assignment area');  
    });
    me.state.sketchLayer.remove(GLOBAL.ASSIGNMENT.graphic);
    GLOBAL.ASSIGNMENT.graphic = null;
    GLOBAL.ASSIGNMENT.geometry = null;
  }

  getUserList(){
    let body = {CompanyId: auth.companyId()};
    fetch(process.env.API_URL + "/api/account/users",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson); 
          if(json.ResponseType == 0 && json.UserCollection.length >0){
            let i = json.UserCollection.length;
            while (i--) {
              if (json.UserCollection[i].DeletedBy != null ||json.UserCollection[i].DeletedDate != null || json.UserCollection[i].DeletedDateUTCOffset != null) {
                json.UserCollection.splice(i, 1);
              }
            }
            let trialMode = json.Company != null && json.Company.SubscriptionPlanId.includes("trial");
            Functions.sortOn(json.UserCollection, 'LastName');
            this.setState({users : json.UserCollection, trialMode});
          }
        })
      }
    })
  }
  
  getCustomFormList(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }
    fetch(process.env.API_URL + "/api/account/form",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : 'application/json',
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.FormCollection){
              this.setState({formProjects : json.FormProjectCollection, customFormList : json.FormCollection.filter(x => x.Enabled == true && x.FormTypeId == 3)})
            }
          }
        })
      }
    })
  }

  createTask(){
    let form = this.state.customFormList.find(x => x.FormId == this.state.assignedCustomFormId);
    let now = new Date();
    //Add projects field to form if there are projects available to form. 
    if(this.state.formProjects && this.state.formProjects.length > 0){
      let projects = this.state.formProjects.filter(x => x.FormId == form.FormId);
      if(projects && projects.length > 0 && form.Fields && form.Fields.findIndex(x => x.Name == "Project" && x.Type == "Drop Down") == -1){
        form.Fields.unshift({Name : "Project", Type : "Drop Down", CustomValues : projects.map(x => {return x.ProjectLabel})});
      }
    }
    var newTaskCustomForm = {
      FormDataId : -1,
      Fields : form.Fields,
      FieldsToSave : form.Fields,
      FormId : form.FormId,
      FormTypeId : form.FormTypeId,
      Name : form.Name,
      CompanyId : auth.companyId(),
      CreatedDate : now,
      CreatedDateUTCOffset : - now.getTimezoneOffset()/60,
      CreatedBy : auth.employeeId(),
      ModifiedDate : now,
      ModifiedDateUTCOffset : - now.getTimezoneOffset()/60,
      ModifiedBy : auth.employeeId(),
      AllowPics : form.AllowPics,
      AllowVids : form.AllowVids
    }
    this.setState({newTaskCustomForm});
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      R: parseInt(result[1], 16),
      G: parseInt(result[2], 16),
      B: parseInt(result[3], 16)
    } : null;
  }

  createMapReport(){
    if(!this.state.mapReportTitle || this.state.mapReportTitle == ""){
      this.setState({mapReportTitleColor : 'red'});
      return;
    }
    let reportModal = document.getElementById("mapReportDiv");
    reportModal.style.display = "none";
    this.props.statusTextUpdate("CREATING MAP REPORT...");
    var layers = [];
        for(const layer in this.layerDictionary){
          let currentLayer = this.layerDictionary[layer];
          if(currentLayer && currentLayer.visible && currentLayer.graphics && currentLayer.graphics.length > 0){ 
            if(layer == "whitelineAreas"){
              layers.push({Name : "WhiteLine Areas", Color : "" , Symbol : ""});
            }
            else{
              
              let form = this.state.customFormList.find(x => x.FormId == layer);
              layers.push({Name : form && form.Name ? form.Name : 'Not Available', Color : form && form.Color ? this.hexToRgb(form.Color) : this.hexToRgb('#ff6347'), Symbol : this.MarkerUtil.getPath( form ? form.MarkerSymbol : 'default'), LineMarker : form ? form.LineMarkerSymbol : 'solid'});
            }
          }
        }
        this.view.takeScreenshot().then(screenshot =>{
          let baseString = screenshot.dataUrl.split(",")[1];
          let body ={
            Layers : layers,
            Base64String : baseString,
            OrgId : new OrgUtility().getOrgId(),
            Scale : parseInt(this.view.scale),
            Title : this.state.mapReportTitle,
            UserId : auth.employeeId(),
            CompanyId : auth.companyId()
          }
          fetch(process.env.API_URL + "/api/data/mapReport",{
            method : "POST",
            headers:{
              "Content-Type" : "application/json; charset=utf-8",
              Accept : "application/json",
              Authorization : "Bearer " + auth.token()
            },
            body : JSON.stringify(body)
          })
          .then(r =>r.blob())
          .then(blob =>{
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.style= "display:none";
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "MapReport.pdf";
            a.click();
            window.URL.revokeObjectURL(url);
            this.setState({mapReportTitle : ""});
            this.props.statusTextUpdate("");
          })
          .catch(error =>{
            console.log(error.message);
            this.setState({mapReportTitle : ""});
            this.props.statusTextUpdate("");
            this.props.setAlert("error", "There was a problem creating the Map Report.");
          });
        });
  }


  createAndAssignTask(){
    let missingInfo = false;
    if(!this.state.newTaskLat || !this.state.newTaskLon){
      this.setState({newTaskAddressColor : 'red'});
      missingInfo = true;
    }
    if(!this.state.assignedCustomFormId || this.state.assignedCustomFormId == ""){
      this.setState({assignedCustomFormColor : 'red'});
      missingInfo = true;
    }
    if(!this.state.assignedUserId || this.state.assignedUserId == ""){
      this.setState({assignedUserColor : 'red'});
      missingInfo = true;
    }
    if(missingInfo){
      return;
    }
    let taskModal = document.getElementById("createTaskDiv");
    taskModal.style.display = "none";
    this.props.statusTextUpdate("CREATING TASK...");
    let now = new Date();
    var customForm = this.state.newTaskCustomForm;
    customForm.Latitude = this.state.newTaskLat;
    customForm.Longitude = this.state.newTaskLon;
    customForm.CreatedDate = now;
    customForm.ModifiedDate = now;
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      FormData : customForm,
      NewTaskFromWeb : true
    }
    fetch(process.env.API_URL + "/api/form/save",{
      method : "POST",
      headers : {
        Accept : "application/json",
        "Content-Type" : 'application/json; charset=utf-8',
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        response.json().then(bodyJson =>{
          let response = JSON.parse(bodyJson);
          if(response.ResponseType != 0){
            this.props.statusTextUpdate('');
            this.props.setAlert("error", "There was a problem creating the task.");
          }
          else{
            if(customForm.images && customForm.images.length > 0){
              customForm.images.forEach(async image =>{
                await this.uploadImage(image,response.FormData.FormDataId);
              });
            }
            if(customForm.docs && customForm.docs.length > 0){
              customForm.docs.forEach(async doc =>{
                await this.uploadDoc(doc,response.FormData.FormDataId);
              });
            }
            this.props.setCreatingNewTask(false);
            let newEntity = this.props.addNewEntityToResults(response.FormData);
            this.assignTask("Custom Form",this.state.assignedUserId,newEntity, null);
            this.setState({assignedCustomFormId : '', assignedUserId : "", addressSearch : '', newTaskLat : null, newTaskLon : null, addressSuggestions : [], foundAddress : ''});
          }
        });
      }
      else{
        this.props.statusTextUpdate("");
        this.props.setAlert("error", "There was a problem creating the task.");
        this.setState({assignedCustomFormId : '', assignedUserId : "", addressSearch : '', newTaskLat : null, newTaskLon : null, addressSuggestions : [], foundAddress : ''});
      }
    })
    .catch(() =>{
      this.props.statusTextUpdate("");
      this.props.setAlert("error","There was a problem creating the task.");
      this.setState({assignedCustomFormId : "", assignedUserId : ""});
    });
  }

  async uploadDoc(doc, id){
    let date = new Date();
    if(doc.size > 50000000){
      this.props.statusTextUpdate("");
      this.props.setAlert("error","There was a problem uploading documents for the task.");
      return;
    }
    let body = {
      MultimediaAsset : {
        EntityId : id,
        EntityTypeId : 93,
        CompanyId : auth.companyId(),
        ModifiedBy : auth.employeeId(),
        CreatedBy : auth.employeeId(),
        CreatedDate : date,
        ModifiedDate : date,
        UTCOffset : date.getTimezoneOffset(),
        ModifiedDateUTCOffset : - date.getTimezoneOffset() / 60,
        CreatedDateUTCOffset : - date.getTimezoneOffset() / 60,
        Timestamp : date.getTime(),
        Latitude : 0,
        Longitude : 0,
        Heading : 0,
        MediaTypeId : 3,
        Label : doc.name
      },
      Latitude : 0,
      Longitude : 0,
      Heading : 0,
      CreatedDate : date,
      UTCOffset : date.getTimezoneOffset(),
      Label : doc.name,
      FileType : doc.type
    }
    let fd = new FormData();
    fd.append('Document', doc);
    fd.append('DataObject', JSON.stringify(body));
    let req = new XMLHttpRequest();
    req.open("POST", process.env.API_URL + "/api/document", true);
    req.setRequestHeader("Authorization", "Bearer " + auth.token());
    req.onload = () =>{
      if(req.status == 200){
        return true;
      }
      else {
        this.props.statusTextUpdate("");
        this.props.setAlert("error","There was a problem uploading images for the task.");
      }
    }
    req.onerror = () =>{
      this.props.statusTextUpdate("");
      this.props.setAlert("error","There was a problem uploading images for the task.");
    }
    req.send(fd);
  }

  async uploadImage(image,id){
    let reader = new FileReader();
    reader.onloadend = async () =>{
      let baseString = reader.result;
      baseString = baseString.split(",")[1];
      let date = new Date();
      let body = {
        MultimediaAsset : {
          EntityId : id,
          EntityTypeId : 93,
          CompanyId : auth.companyId(),
          ModifiedBy : auth.employeeId(),
          CreatedBy : auth.employeeId(),
          CreatedDate : date,
          ModifiedDate : date,
          UTCOffset : date.getTimezoneOffset(),
          ModifiedDateUTCOffset : - date.getTimezoneOffset() / 60,
          CreatedDateUTCOffset : - date.getTimezoneOffset() / 60,
          Timestamp : date.getTime(),
          Latitude : 0,
          Longitude : 0,
          Heading : 0,
          MediaTypeId : 0
        },
        Base64Img : baseString
      }
      fetch(process.env.API_URL + "/api/image",{
        method : "POST",
        headers : {
          "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization : "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          return true;
        }
        else {
          this.props.statusTextUpdate("");
          this.props.setAlert("error","There was a problem uploading images for the task.");
        }
      }).catch(error =>{
        console.log(error);
        this.props.statusTextUpdate("");
        this.props.setAlert("error","There was a problem uploading images for the task.");
      });
    }
    reader.readAsDataURL(image.file);
  }

  setNewTaskLatLon(lat, lon){
    if(this.props.creatingNewTask){
      this.setState({newTaskLat : lat, newTaskLon : lon, newTaskAddressColor : '#3c5873'});
      document.getElementById("createTaskDiv").style.display = 'block';
    }
  }

  searchAddress(e, searchType){
    let text = e.target.value;
    if(searchType == "taskSearch"){
      this.setState({addressSearch : text});
    }
    else if(searchType == "addressSearch"){
      this.setState({addressSearchForSearch : text});
    }
    let lat = this.state.currentLat;
    let lon = this.state.currentLon;
    let searchUrl = this.state.baseArcgisURL + "/suggest?f=json&category=Address" + (lat == 0 || lon == 0 ? "" : "&location=" + lon + "," + lat) + "&text=" + text;
    fetch(searchUrl,
      {
        method: 'GET'
      })
      .then(async resp =>{
        if(resp.status == 200){
          resp.json().then(bodyJson =>{
            if(bodyJson.suggestions){
              this.setState({addressSuggestions : bodyJson.suggestions});
            }
            else{
              this.setState({addressSuggestions : []});
            }
          });
        }
        else{
          this.setState({addressSuggestions : []});
        }
      })
      .catch(e =>{
        console.log(e);
        this.setState({addressSuggestions : []});
      })
  }

  selectSuggestedAddressToZoom(suggestion){
    loadModules(['esri/Graphic'])
    .then(([Graphic]) =>{
      let url = this.state.baseArcgisURL + "findAddressCandidates?F=JSON&magicKey=" + suggestion.magicKey;
      if(this.selectedAddressGraphic){
        this.view.graphics.remove(this.selectedAddressGraphic);
      }
      fetch(url, 
        {
          method : "GET"
        })
        .then(async resp =>{
          if(resp.status == 200){
            resp.json().then(bodyJson =>{
              document.getElementById("addressSearchDiv").style.display = "none"; 
              this.setState({addressSearchForSearch : "", addressSuggestions: [] });
              this.view.center = [bodyJson.candidates[0].location.x,bodyJson.candidates[0].location.y];
              this.view.zoom = 18;
              var markerSymbol = {
                type: "text", // autocasts as new TextSymbol()
                color: "red",
                text:"\ue61d", // esri-icon-map-pin
                font: {
                  size: 24,
                  family: "CalciteWebCoreIcons"
                }
              };
              this.selectedAddressGraphic = new Graphic({ geometry : {type : "point", longitude : bodyJson.candidates[0].location.x, latitude : bodyJson.candidates[0].location.y}, symbol : markerSymbol});
              this.view.graphics.add(this.selectedAddressGraphic);
            });
          }
        });
    });
  }

  selectSuggestedAddress(suggestion){
    let url = this.state.baseArcgisURL + "findAddressCandidates?F=JSON&magicKey=" + suggestion.magicKey;
    fetch(url,
      {
        method : "GET"
      })
      .then(async resp =>{
        if(resp.status == 200){
          resp.json().then(bodyJson =>{
            this.setState({newTaskAddressColor : '#3c5873',foundAddress : bodyJson.candidates[0].address, addressSearch : bodyJson.candidates[0].address, newTaskLat : bodyJson.candidates[0].location.y, newTaskLon : bodyJson.candidates[0].location.x, addressSuggestions: []})
          })
        }
      })
  }

  componentDidMount() {
    // setTimeout(() =>{
    //   if (document.getElementsByClassName("esri-sketch-header").length == 0 && document.getElementsByClassName("esri-sketch")[0] != undefined){
    //     var header = document.createElement("div");
    //     header.className = "esri-sketch-header";
    //     header.innerHTML = "ASSIGN TASKS/AREA";
    //     document.getElementsByClassName("esri-sketch")[0].insertBefore(header,document.getElementsByClassName("esri-sketch")[0].childNodes[0]);
    //   }
    // }, 2000);
    if(this.props.ARCGISClientSecret && this.props.ARCGISClientSecret  != '' && this.props.ARCGISClientId && this.props.ARCGISClientId != ''){
      if(this.props.EnterpriseConnection){
        fetch(process.env.API_URL + "/api/arcgis/getcompanyenterprisetoken/" + auth.companyId(),{
          method : "POST",
          headers : {
            "Content-Type" : "application/json; charset=utf-8",
            Accept : "application/json",
            Authorization : "Bearer " + auth.token()
          }
          }).then(response =>{
            response.json().then(bodyJson =>{
              this.setState({arcGisConnectedLayersAccessToken : bodyJson.access_token});
            })
          })
      }
      else{
        fetch("https://www.arcgis.com/sharing/rest/oauth2/token?grant_type=client_credentials&client_id=" + this.props.ARCGISClientId + "&client_secret=" + this.props.ARCGISClientSecret,{
          method : "POST",
          headers : {"Content-Type" : "application/x-www-form-urlencoded"}
        }).then(response =>{
          response.json().then(bodyJson =>{
            this.setState({arcGisConnectedLayersAccessToken : bodyJson.access_token});
          })
        })
      } 
    }   
    window.onclick = e =>{
      let modal = document.getElementById("imageGalleryDiv");
      let taskModal = document.getElementById("createTaskDiv");
      let mapReportModal = document.getElementById("mapReportDiv");
      let addressSearchModal = document.getElementById("addressSearchDiv");
      let deletePointModal = document.getElementById("deletePointDialogDiv");
      let mapFeaturesModal = document.getElementById("mapFeaturesDiv");
      if(e.target == mapReportModal){
        mapReportModal.style.display = "none";
        this.setState({mapReportTitle : "", mapReportTitleColor : 'black'});
      }
      if(e.target == taskModal){
        this.props.setCreatingNewTask(false);
        taskModal.style.display = 'none';
      }
      if(e.target == modal){
        this.setState({images : [], videos : [], thumbnails : [], videoCount : 0, pictureCount: 0});
        modal.style.display = "none";
      }
      if(e.target == addressSearchModal){
        addressSearchModal.style.display = "none";
      }
      if(e.target == deletePointModal){
        deletePointModal.style.display = "none";
        this.setState({deletePointEvent : null, deletePointPopup : null});
      }
      if(e.target == mapFeaturesModal){
        mapFeaturesModal.style.display = "none";
      }
    }
    this.getUserList();
    let me = this;
    this.getCustomFormList();
    window.addEventListener('beforeunload', this.beforeunload.bind(this));
    // ESRI MAP SECTION
    loadModules(['esri/Map', 'esri/views/MapView', 'esri/widgets/LayerList','esri/layers/GraphicsLayer','esri/widgets/Sketch', 'esri/geometry/Point', 'esri/geometry/support/webMercatorUtils', 'esri/core/watchUtils', 'esri/widgets/Zoom', 
    'esri/widgets/BasemapGallery','esri/widgets/Expand', 'esri/config','esri/portal/Portal', 'esri/widgets/Measurement', 'dojo/dom-construct'], { css: true })
    .then(([ArcGISMap, MapView, LayerList, GraphicsLayer, Sketch, Point,WebMercatorUtils, watchUtils, Zoom,BasemapGallery, Expand, esriConfig, Portal,Measurement, domConstruct]) => {
      esriConfig.request.trustedServers.push("https://arcgis.elmutility.com");
      esriConfig.request.interceptors.push({
        urls:["https://golaunchpoint.maps.arcgis.com/","https://ibasemaps-api.arcgis.com/","https://basemaps-api.arcgis.com/"],
        before : function(params){
          params.requestOptions.query = params.requestOptions.query || {};
          params.requestOptions.query.token =  process.env.ESRIAPIKEY;
        }
      });
      if(this.props.CompanyArcGisLayers.length > 0){
        esriConfig.request.interceptors.push({
          urls:this.props.CompanyArcGisLayers.map((layer) =>layer.Url),
          before: function(params){
            params.requestOptions.query = params.requestOptions.query || {};
            params.requestOptions.query.token =  me.state.arcGisConnectedLayersAccessToken;
          }
        });
      }
      esriConfig.portalUrl = 'https://golaunchpoint.maps.arcgis.com/';
      const portal = new Portal();
      portal.load()
      .then(() =>{
      // MAP & VIEW
      let me = this;
      let lastBasemap = localStorage.getItem("basemap");
      const map = new ArcGISMap({basemap: lastBasemap && lastBasemap != "" ? lastBasemap : "arcgis-light-gray"});
      const view = new MapView({container: this.mapRef.current, popup:{defaultPopupTemplateEnabled : true}, map: map, center: [-98,40], zoom: 4, ui: {components: []}});
      this.view = view;
      
      const basemapGallery = new BasemapGallery({ view: this.view});
      basemapGallery.watch('activeBasemap',function(newValue){
        var newBasemap = "";
        switch(newValue.title){
          case "Imagery":
            newBasemap = 'arcgis-imagery-standard';
            break;
          case "Imagery Hybrid":
            newBasemap = "arcgis-imagery"
            break;
          case "Streets":
              newBasemap = "arcgis-streets"
              break;
          case "Topographic":
            newBasemap = "arcgis-topographic"
            break;
          case "Navigation":
            newBasemap = "arcgis-navigation"
            break;
          case "Streets (Night)":
            newBasemap = "arcgis-streets-night"
            break;
          case "Terrain with Labels":
            newBasemap = "arcgis-terrain"
            break;
          case "Light Gray Canvas":
            newBasemap = "arcgis-light-gray"
            break;
          case "Dark Gray Canvas":
            newBasemap = "arcgis-dark-gray"
            break;
          case "Oceans":
            newBasemap = "arcgis-oceans"
            break;
          // case "National Geographic Style Map":
          //   newBasemap = ""
          //   break;
          case "OpenStreetMap":
            newBasemap = "osm-standard"
            break;
          case "Charted Territory Map":
            newBasemap = "arcgis-charted-territory"
            break;
          case "Community Map":
            newBasemap = "arcgis-community"
            break;
          case "Navigation (Dark Mode)":
            newBasemap = "arcgis-navigation-night"
            break;
          case "Newspaper Map":
            newBasemap = "arcgis-newspaper"
            break;
          // case "Human Geography Map":
          //   newBasemap = "17ffb944733-basemap-23"
          //   break;
          // case "Human Geography Dark Map":
          //   newBasemap = "osm-dark-gray"
          //   break;
          case "Modern Antique Map":
            newBasemap = "arcgis-modern-antique"
            break;
          case "Mid-Century Map":
            newBasemap = "arcgis-midcentury"
            break;
          case "Nova Map":
            newBasemap = "arcgis-nova"
            break;
          case "Colored Pencil Map":
            newBasemap = "arcgis-colored-pencil"
            break;
          // case "Outline Map":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "FireFly Imagery Hybrid":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "NAIP Imagery Hybrid":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "USGS National Map":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "USA Topo Maps":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "OpenStreetMap (Blueprint)":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          default:
            console.log(newValue.title);
        }
        localStorage.setItem("basemap",newBasemap);
      });

      const bgExpand = new Expand({
        view : this.view,
        content : basemapGallery
      });

      const measurement = new Measurement();
      measurement.view = view;
      view.ui.add(measurement, "bottom-right");
      var distanceButton = document.createElement("button");
      var areaButton = document.createElement("button");
      var clearButton = document.createElement("button");
      areaButton.className = "esri-widget--button esri-interactive esri-icon-measure-area";
      distanceButton.className = 'esri-widget--button esri-interactive esri-icon-measure-line';
      clearButton.className = "esri-widget--button esri-interactive esri-icon-trash";
      view.ui.add(distanceButton, 'bottom-left');
      distanceButton.addEventListener("click", ()=>{
        distanceButton.classList.add("active");
        measurement.activeTool = "distance";
        areaButton.classList.remove("active");
      });
      view.ui.add(areaButton, "bottom-left");
      areaButton.addEventListener("click", ()=>{
        areaButton.classList.add("active");
        measurement.activeTool = "area";
        distanceButton.classList.remove("active");
      });
      view.ui.add(clearButton, "bottom-left");
      clearButton.addEventListener("click", ()=>{
        distanceButton.classList.remove("active");
        areaButton.classList.remove("active");
        measurement.clear();
      });
      var addressBtn = document.createElement('button');
      addressBtn.innerText = 'Search Address';
      //view.ui.add(addressBtn, "top-right");
      addressBtn.addEventListener('click', () =>{
        document.getElementById("addressSearchDiv").style.display = 'block';
      });
      // var btn = document.createElement('button');
      // btn.innerText = "Map Report";
      // view.ui.add(btn, 'top-right');
      // btn.addEventListener('click',() =>{
      //   document.getElementById("mapReportDiv").style.display = "block";
      // });
      var mapFeaturesButton = document.createElement("button");
      mapFeaturesButton.innerText = "MAP MENU";
      mapFeaturesButton.className = "mapActionButton"
      view.ui.add(mapFeaturesButton, "top-right");
      mapFeaturesButton.addEventListener('click', ()=>{
        document.getElementById("mapFeaturesDiv").style.display = 'block';
      });

      this.view.ui.add(bgExpand, {position : 'bottom-left'});
      // var sketchLayer = new GraphicsLayer({title: 'SKETCH'});
      // map.layers.add(sketchLayer);
      // var sketch = new Sketch({layer: sketchLayer, view: view});
      // view.ui.add(sketch, "top-right");
      var layerAssignmentAreas = new GraphicsLayer({title:"Assignment Areas", id: "assignmentLayer"});
      map.layers.add(layerAssignmentAreas);
      var sketchLayer = new GraphicsLayer({title: 'Graphics', listMode : 'hide'});
      map.layers.add(sketchLayer);
      var sketch = new Sketch({ layer: sketchLayer, view : view, id : 'esri-sketch-leaksurvey', creationMode: "update", snappingOptions: {enabled: true, featureSources: [{layer: sketchLayer, enabled : true},{layer: layerAssignmentAreas, enabled : true}]}});

      sketch.on("create", function(event){
        if(event.state === "complete"){
          var delAction = { title: "Erase", id: "del", className: "esri-icon-erase" };
          var chooseAction = { title: "Select Action", id: "chooseAction", className: "esri-icon-directions" };
          var geoGeometry = WebMercatorUtils.webMercatorToGeographic(event.graphic.geometry);
          GLOBAL.ASSIGNMENT.geometry = geoGeometry;
          GLOBAL.ASSIGNMENT.graphic = event.graphic;
          event.graphic.attributes = {
            name : "NEW ASSIGNMENT AREA",
            type : event.graphic.geometry.type,
            geoGeometry : geoGeometry
          };

          event.graphic.popupTemplate = {
            title: "{name}",
            content: setContentInfo,
            actions: [chooseAction, delAction]
          };

          this.view.popup.open({location: event.graphic.geometry.centroid, fetchFeatures: true});
        }
      });
      sketch.on("update", function(event){
        if(event.state == "complete" || event.state == "cancel"){
          var geoGeometry = WebMercatorUtils.webMercatorToGeographic(event.graphics[0].geometry);
          GLOBAL.ASSIGNMENT.geometry = geoGeometry;
        }
      });

      sketch.on("delete", function(event){
        if(GLOBAL.ASSIGNMENT.graphic != undefined){
          sketchLayer.remove(event.graphics[0]);
          GLOBAL.ASSIGNMENT.graphic = null;
          GLOBAL.ASSIGNMENT.geometry = null;
        }
      });

      sketch.availableCreateTools = ["polygon"];
      this.view.ui.add(sketch, {position : 'top-right'});

      // LAYER LIST
      var layerList = new LayerList({ view: this.view, selectionEnabled: false});
      this.view.ui.add(layerList, { position: "top-right" });
      var zoom = new Zoom({ view : this.view, layout: "vertical"});
      this.view.ui.add(zoom, { position : 'bottom-right'});
      view.on("click",function(event){
        me.setNewTaskLatLon(event.mapPoint.latitude, event.mapPoint.longitude);
      });

      
      
      // ACTIONS
      this.view.popup.on("trigger-action", function(event) {
        if(event.action.id == "viewDetails"){ viewDetails(me, {target : this.view.popup});}
        if(event.action.id == "deletePoint"){ deletePoint(me,{target : this.view.popup})}
        if(event.action.id == "assignTask"){ assignTask({target : this.view.popup});}
        if(event.action.id == "unassignTask"){ unassignTask({target : this.view.popup});}
        if(event.action.id == "viewLarger"){ viewLarger(me, {target : this.view.popup});}
        if(event.action.id == "viewVideo"){ viewVideo(me, {target : this.view.popup});}
        if(event.action.id == "viewMultimedia"){ viewMultimeda(me, {target : this.view.popup});}
        if(event.action.id == "viewPointMultimedia"){ viewPointMultimeda(me, {target : this.view.popup});}
        if(event.action.id == "expandFormData"){ expandFormDataMarker(me, {target : this.view.popup});}
        if(event.action.id == "archive"){archiveSurvey(me,{target : this.view.popup});}
        if(event.action.id == "del"){deleteAssignment(me,{target : this.view.popup});}
        if(event.action.id == "chooseAction"){chooseAction(me,{target : this.view.popup});}
      });

      function viewDetails(context, event){
        if(event.target.selectedFeature.attributes.FormData){
          me.viewEntity(event.target.selectedFeature.attributes.FormData);
        }
      }

      function deletePoint(context, event){
        document.getElementById("deletePointDialogDiv").style.display = 'block';
        me.setState({deletePointEvent : event, deletePointPopup : view.popup});
      }
      function archiveSurvey(context, event){
        let attr = event.target.selectedFeature.attributes;
        me.archiveSurvey(attr, view.popup);
      }

      function expandFormDataMarker(context, event){
        me.view.popup.close();
        me.updateExtent = false;
        let formDataId = event.target.selectedFeature.attributes.FormData.FormDataId;
        me.props.expandFormDataMarker(formDataId);
        me.rerenderMap();
      }

      function deleteAssignment(context,event){
        var userAssignmentId = -1;
        if(event.target.selectedFeature.attributes.name != "NEW ASSIGNMENT AREA"){
          userAssignmentId = event.target.selectedFeature.attributes.GPSSurveyAssignmentId;
        }
        if(userAssignmentId == -1){
          me.state.sketchLayer.remove(GLOBAL.ASSIGNMENT.graphic);
          GLOBAL.ASSIGNMENT.graphic = null;
          GLOBAL.ASSIGNMENT.geometry = null;
        }
        else{
          me.updateAssignmentArea(null,null,userAssignmentId, true);
        }
        view.popup.close();
      }

      function chooseAction(context, event){
        var actionSelect = document.getElementById("assignmentSelect");
        var actionId = actionSelect != null ? actionSelect.value : 0;
        var title = document.getElementsByClassName("esri-popup__header-title")[0];
        var label = document.getElementsByClassName("assignmentLabel")[0];
        var icon = document.getElementsByClassName("esri-icon-directions")[0];
        var buttonText = document.getElementsByClassName("esri-popup__action-text")[0];
        if(actionId == 'assignArea'){
          title.innerHTML = "NEW ASSIGNMENT AREA";
          label.innerHTML = "Assign To: ";
          var action = document.getElementById("assignmentSelect");
          action.innerHTML = me.state.userOptionList;
          icon.className = "esri-popup__icon esri-icon-user";
          buttonText.innerHTML = 'Assign Area';
        }
        else if(actionId == 'assignAllTasks'){
          title.innerHTML = "ASSIGN ALL TASKS";
          label.innerHTML = "Assign To: ";
          var act = document.getElementById("assignmentSelect");
          act.innerHTML = me.state.userOptionList;
          icon.className = "esri-popup__icon esri-icon-user";
          buttonText.innerHTML = 'Assign All Tasks';
        }
        else if(actionId === "unassignAllTasks"){
          unassignAllSelectedTasks(context, event);
        }
        else if(actionId != "-1"){
          if(buttonText.innerHTML == 'Assign Area'){
            assignUser(context,event);
          }
          else if(buttonText.innerHTML == "Assign All Tasks"){
            assignAllSelectedTasks(context, event);
          }
        }
        else{
          console.log(actionId);
        }
      }

      function viewMultimeda(context, event){
        me.setState({loadingMultimedia : true});
        let attr = event.target.selectedFeature.attributes;
        let entityType = 93;
        let id = attr.FormData.FormDataId;
        fetch(process.env.API_URL + "/api/entityImages/" + id + "/" + entityType + "/web",{
          method : "POST",
          headers : {"Content-Type" : "application/json", Authorization : "Bearer " + auth.token()}
        })
        .then(r =>{
          if(r.status == 200){
            r.json().then(bodyJson =>{
              if(bodyJson.length > 0){
                let response = JSON.parse(bodyJson);
                response.MultimediaAssets.sort((a,b) =>(a.timeStamp > b.timeStamp) ? 1 : -1);
                let pictures = response.MultimediaAssets.filter(x => x.MediaTypeId == null || x.MediaTypeId == 0);
                let thumbnails = response.MultimediaAssets.filter(x => x.MediaTypeId == 2);
                let images = [];
                let thumbs = [];
                pictures.forEach(asset =>{
                  let srcUrl = process.env.API_URL + "/api/image/" + asset.MultimediaAssetId;
                  images.push({original : srcUrl, thumbnail : srcUrl});
                });
                thumbnails.forEach(thumb =>{
                  let srcUrl = process.env.API_URL + "/api/image/" + thumb.MultimediaAssetId;
                  thumb.push({original : srcUrl, thumbnail : srcUrl, videoId : thumb.CompareMultimediaAssetId});
                });
                me.setState({images, thumbnails : thumbs, videoCount : thumbs.length, pictureCount : images.length, loadingMultimedia : false});
              }
            });
          }
        });
        document.getElementById("imageGalleryDiv").style.display = 'block';
      }

      function viewPointMultimeda(context, event){
        me.setState({loadingMultimedia : true});
        let attr = event.target.selectedFeature.attributes;
        //let id = attr.FormData.FormDataId;

        let multimediaAssets = null;

        multimediaAssets.sort((a,b) =>(a.timeStamp > b.timeStamp) ? 1 : -1);
        let pictures = multimediaAssets.filter(x => x.MediaTypeId == null || x.MediaTypeId == 0);
        let thumbnails = multimediaAssets.filter(x => x.MediaTypeId == 2);
        let images = [];
        let thumbs = [];
        pictures.forEach(asset =>{
          let srcUrl = process.env.API_URL + "/api/image/" + asset.MultimediaAssetId;
          images.push({original : srcUrl, thumbnail : srcUrl});
        });
        thumbnails.forEach(thumb =>{
          let srcUrl = process.env.API_URL + "/api/image/" + thumb.MultimediaAssetId;
          thumb.push({original : srcUrl, thumbnail : srcUrl, videoId : thumb.CompareMultimediaAssetId});
        });
        me.setState({images, thumbnails : thumbs, videoCount : thumbs.length, pictureCount : images.length, loadingMultimedia : false});
        document.getElementById("imageGalleryDiv").style.display = 'block';
      }

      function viewLarger(context, event){
        let attr = event.target.selectedFeature.attributes;
        let srcUrl = process.env.API_URL + "/api/image/" + attr.MultimediaAssetId;
        me.setState({currentFullscreenImage : [{original : srcUrl, thumbnail : srcUrl}]}, () =>{me._imageGallery.fullScreen();});
      }

      function viewVideo(context, event){
        let attr = event.target.selectedFeature.attributes;
        let videosrcUrl = process.env.API_URL + "/api/video/" + attr.CompareMultimediaAssetId;
        let imageDiv = document.getElementById("image"+attr.MultimediaAssetId);
        imageDiv.innerHTML = "<video loop controls src='" + videosrcUrl + "' style='margin-top : 5px; object-fit : cover; width : 99%; height: 250px;' type='video/mp4'/>";
      }

      function setContentInfo(){
        var node = domConstruct.create("div", { innerHTML:
          "<div class='assignmentDiv'><div class='assignmentLabel'>Choose Action:</div> <select id='assignmentSelect' class='assignmentSelect'>"+
          me.state.actionList + "</select></div>"
        });
        return node;
      }

      //MAP CLICK EVENT
      view.on("click", function(event) {view.hitTest(event).then(getGraphics);});
      function getGraphics(e){
        e.results.forEach(g =>{
          // !PERFORMANCE_UPDATE
          if(g.graphic.FormId || (g.graphic.attributes.clusterId && g.graphic.FormId != undefined)){
            view.cluster_expandCluster(g.graphic, view);
          }
        });
        setTimeout(()=>{
          view.cluster_clusterGraphics(view);
        },500);
      }

      view.mapLayerType = this.state.mapLayerType;
      view.legendStyle = this.legendStyle;
      view.markerStyle = this.markerStyle;

      watchUtils.when(view, "stationary", function(){
        try{
          if(this.extent){
            if(this.cluster_clusterGraphics){
              //clear existing cluster graphics
              this._clusters.length = 0;
              if(this.pointGraphicsByFormId){
                Object.keys(this.pointGraphicsByFormId).forEach(formId =>{
                  if(this.pointGraphicsByFormId[formId])
                  this.pointGraphicsByFormId[formId] = [];
                });
              }
              this.extentGeo = WebMercatorUtils.webMercatorToGeographic(this.extent);
              this.cluster_clusterGraphics(this);
            }
          }
        }
        catch(e){
          console.log('watchUtils_exception',e);
        }
      });

      function assignUser(context, event){
        var assignmentSelect = document.getElementById("assignmentSelect");
        var assignmentId = assignmentSelect.value;
        var username = assignmentSelect.options[assignmentSelect.selectedIndex].innerText;
        var gpsSurveyAssignmentId = -1;
        if(event.target.selectedFeature.attributes.name != "NEW ASSIGNMENT AREA"){
          gpsSurveyAssignmentId = event.target.selectedFeature.attributes.GPSSurveyAssignmentId;
        }
        me.updateAssignmentArea(assignmentId, username, gpsSurveyAssignmentId);
        view.popup.close();
      }

      function assignAllSelectedTasks(context, event){
        var assignmentSelect = document.getElementById("assignmentSelect");
        var assignmentId = assignmentSelect.value;
        var formTasks = [];
        view.map.layers._items.forEach((layer) =>{
          if(layer.graphics && (layer.title != null && layer.title != 'Graphics' && layer.title != 'Assignment Areas') && layer.visible){
            layer.graphics.items.forEach((item) =>{
              let data = item.attributes;
              let form = data.FormData;
              let formTask;
              if(form && event.target.selectedFeature.attributes.geoGeometry.contains(new Point(form.Longitude, form.Latitude))){
                let formTaskType = 6;
                let now = new Date();
                formTask = form.FormTask;
                if(formTask && formTask.AssignedUserId != assignmentId){
                  formTask.ModifiedBy = auth.employeeId();
                  formTask.ModifiedDate = now;
                  formTask.ModifiedDateUTCOffset = now;
                  formTask.AssignedUserId = assignmentId ? assignmentId : formTask.AssignedUserId;
                  formTask.FormTaskType = formTaskType;
                  if(assignmentId == null){
                    //Delete Task
                    formTask.DeletedDate = now;
                    formTask.DeletedDateUTCOffset = -now.getTimezoneOffset() /60;
                    formTask.DeletedBy = auth.employeeId();
                  }
                }
                else if(!formTask){
                  formTask = {
                    FormTaskId : -1,
                    FormTaskType : formTaskType,
                    FormDataId : form.FormDataId,
                    FormId : form.FormId,
                    FormTypeId : form.FormTypeId,
                    CompanyId : auth.companyId(),
                    AssignedUserId : assignmentId,
                    Latitude : form.Latitude,
                    Longitude : form.Longitude,
                    CreatedDate : now,
                    CreatedBy : auth.employeeId(),
                    CreatedDateUTCOffset : -now.getTimezoneOffset() / 60,
                    ModifiedBy : auth.employeeId(),
                    ModifiedDate : now,
                    ModifiedDateUTCOffset : -now.getTimezoneOffset() / 60
                  };
                }
              }
              else if(event.target.selectedFeature.attributes.geoGeometry.contains(new Point(data.Longitude, data.Latitude))){
                let formTaskType = 6;
                let now = new Date();
                formTask = data.FormTask;
                if(formTask && formTask.AssignedUserId != assignmentId){
                  formTask.ModifiedBy = auth.employeeId(),
                  formTask.ModifiedDate = now;
                  formTask.ModifiedDateUTCOffset = now;
                  formTask.AssignedUserId = assignmentId ? assignmentId : formTask.AssignedUserId;
                  formTask.FormTaskType = formTaskType;
                  if(assignmentId == null){
                    //Delete Task
                    formTask.DeletedDate = now;
                    formTask.DeletedDateUTCOffset = - now.getTimezoneOffset() / 60;
                    formTask.DeletedBy = auth.employeeId();
                  }
                }
                else if(!formTask){
                  formTask = {
                    FormTaskId : -1,
                    FormTaskType : formTaskType,
                    FormTypeId : data.Type == "Survey" ? 0 : 1,
                    CompanyId : auth.companyId(),
                    AssignedUserId : assignmentId,
                    Latitude : data.Latitude,
                    Longitude : data.Longitude,
                    CreatedDate : now,
                    CreatedBy : auth.employeeId(),
                    CreatedDateUTCOffset : -now.getTimezoneOffset() / 60,
                    ModifiedBy : auth.employeeId(),
                    ModifiedDateUTCOffset : -now.getTimezoneOffset() / 60,
                    ModifiedDate : now,
                    SiteSurveyId : data.Type == "Survey" ? data.SiteSurveyId : null,
                    InvestigationId : data.Type == "Survey" ? null : data.InvestigationId
                  }
                }
              }
              if(formTask && !formTasks.find(x => x.FormDataId == formTask.FormDataId)){
                formTasks.push(formTask);
              }
            });
          }
        });


        if(formTasks.length == 0) alert("All selected tasks are already assigned to this user.");
        else{
          me.assignTaskCollection(assignmentId, formTasks);
          view.popup.close();
        }
      }

      function unassignAllSelectedTasks(context, event){
        var assignmentSelect = document.getElementById(assignmentSelect);
        var assignmentId = "-1";
        var formTasks = [];
        view.map.layers._items.forEach((layer)=>{
          if(layer.graphics && (layer.title !=null && layer.title != "Graphics" && layer.title != "Assignment Areas") && layer.visible){
            layer.graphics.items.forEach((item)=>{
              let data = item.attributes;
              if(data.FormData && event.target.selectedFeature.attributes.geoGeometry.contains(new Point(data.FormData.Longitude, data.FormData.Latitude)) && data.FormData.FormTaskList && data.FormData.FormTaskList.length > 0){
                data.FormData.FormTaskList.forEach(task =>{
                  if(!formTasks.find(x => x.FormTaskId == task.FormTaskId)){
                    formTasks.push(task);
                  }
                })
              }
            });
          }
        });
        if(formTasks.length == 0){
          alert('All selected tasks are already unassigned.');
        } 
        else{
          formTasks.forEach(task =>{
            let now = new Date();
            task.ModifiedDate = now;
            task.ModifiedDateUTCOffset = -now.getTimezoneOffset() /60;
            task.ModifiedBy = auth.employeeId();
            task.DeletedDateUTCOffset = -now.getTimezoneOffset()/60;
            task.DeletedBy = auth.employeeId();
            task.DeletedDate = now;
          })
          me.assignTaskCollection(assignmentId,formTasks);
          view.popup.close();
        }
      }

      function assignTask(event){
        var popupDiv = document.getElementsByClassName("esri-feature__main-container");
        if(popupDiv && popupDiv.length > 0){
          var buttonText = document.getElementsByClassName("esri-popup__action-text")[1];
          popupDiv = popupDiv[0];
          if(buttonText.innerHTML == "Assign Task"){
            popupDiv.innerHTML = "<div>"
                                +"<div class='assignmentDiv'>"
                                +"<div class='assignmentLabel'>Choose Assignment Type:</div>"
                                +"<select id='assignmentSelect' class='assignmentSelect'>"
                                + (typeof event.target.selectedFeature.attributes.LocateLogNumber === 'undefined' ? "" : "<option value='Walkback Point'>Walkback Point</option>")
                                +"<option value='Walkback Full Survey'>Walkback Full Survey</option>"
                                +"<option value='Custom Form'>Form Task</option></select>"
                                +"</div>"
                                +"<div class='assignmentDiv'>"
                                +  "<div class='assignmentLabel'>Choose User:</div>"
                                +"<select style='margin-left:82px' id='userSelect' class='assignmentSelect'>"+me.state.userOptionList + "</select>"
                                +"</div>"
                                +"</div>"
            buttonText.innerHTML = "Assign User";
          }
          else if(buttonText.innerHTML == "Assign User"){
            var assignmentType = document.getElementById("assignmentSelect").value;
            var userId = document.getElementById("userSelect").value;
            me.assignTask(assignmentType, userId, event.target.selectedFeature.attributes.FormData, view.popup, event.target.selectedFeature.attributes.LocateLogNumber);
          } 
        }
      }

      function unassignTask(event){
        me.unassignTask(event.target.selectedFeature.attributes.FormData, view.popup, event.target.selectedFeature.attributes.LocateLogNumber);
      }

      this.setState({map: map, view:view, sketchLayer:sketchLayer, layerAssignmentAreas : layerAssignmentAreas});
    })
    .catch((error) =>{
      console.log(error);
    });
    });
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition((position) =>{
        this.setState({currentLat : position.coords.latitude, currentLon : position.coords.longitude});
      }, ()=>{
        this.setState({currentLat : 0, currentLon : 0});
      });
    }
    else{
      this.setState({currentLat : 0, currentLon : 0});
    }
  }

  archiveSurvey(survey, popup){
    if(auth.isSuperViewer()){
      if(popup) popup.close();
      return;
    }
    this.props.showProgressBar("Archiving...");
    if(popup) popup.close();
    let now = new Date();
    let formData = survey.FormData;
    formData.FieldsToSave = formData.Fields;
    formData.DeletedDate = now;
    formData.DeletedDateUTCOffset = -now.getTimezoneOffset()/60;
    formData.DeletedBy = auth.userId();
    formData.ModifieddDate = now;
    formData.ModifiedDateUTCOffset = -now.getTimezoneOffset()/60;
    formData.ModifiedBy = auth.userId();
    let body = {
      FormData : formData,
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
    }
    fetch(process.env.API_URL + "/api/form/save",{
      method : "POST",
      headers : {
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        response.json().then(() =>{
          this.props.updateFromArchive(formData);
          this.props.showProgressBar("");
          this.props.setAlert('success','Successfully Archived.');
          this.updateExtent = false;
          popup.close();
          setTimeout(() =>{this.rerenderMap();},1000); //Need to make sure that results had a chance to update and set state.
        });
      }
      else{
        this.props.showProgressBar("");
        this.props.setAlert('error','Error Archiving.');
      }
    }).catch(error =>{
      this.props.showProgressBar("");
      this.props.setAlert('error','Error Archiving.');
      console.log(error);
    });
  }

  deletePoint(event,popup){
    if(auth.isSuperViewer()){
      if(popup) popup.close();
      return;
    }
    this.props.showProgressBar("Deleting Point");
    if(event.target.selectedFeature.attributes.FormData){
      let now = new Date();
      let form = event.target.selectedFeature.attributes.FormData;

      // !PERFORMANCE_UPDATE
      let geometry = JSON.parse(form.GeometryDataMin);
      let pointIndex = event.target.selectedFeature.attributes.pointIndex;

      form.GeometryDataMin = JSON.stringify(geometry); // !PERFORMANCE_UPDATE
      form.FieldsToSave = form.Fields;
      form.PointIndexToDelete = pointIndex;
      form.ModifiedBy = auth.userId();
      form.ModifiedDate = now;
      form.ModifiedDateUTCOffset = -now.getTimezoneOffset()/60;
      
      let body = {
        FormData : form,
        UserId : auth.userId(),
        CompanyId : auth.companyId(),
        StatusType : 0,
        EntityType : 93,
        RequestId : now.valueOf()
      };
      fetch(process.env.API_URL + "/api/form/save",{
        method : "POST",
        headers : {
          Accept : "application/json;",
          "Content-Type" : "application/json; charset=utf-8",
          Authorization : "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(response => response)
      .then((response) =>{
        if(response.status == 200){
          response.json().then(bodyJson =>{
            let json = JSON.parse(bodyJson);
            if(json.FormData){
              this.props.updateResultsFromDeletedPoint(json.FormData);
            }
            this.props.showProgressBar("");
            this.props.setAlert('success','Point Deleted.');
            this.updateExtent = false;
            popup.close();
            setTimeout(() =>{this.rerenderMap();},1000); //Need to make sure that results had a chance to update and set state.
          });
        }
        else{ 
          this.props.showProgressBar("");
          this.props.setAlert('error','Error deleting point.');  
        }
      }).catch(error =>{
        this.props.showProgressBar("");
        this.props.setAlert('error','Error deleting point.');  
        console.log(error);
      });
    } 
  }

  assignTask(assignmentType, userId, gpsSurvey, popup, locateLogNumber){
    if(auth.isSuperViewer()){
      if(popup) popup.close();
      return;
    }
    this.props.showProgressBar("Assigning Task");
    let formTaskType = (assignmentType == "Walkback Point" ? 0 : assignmentType == "Walkback Full Survey" ? 1 : assignmentType == "Custom Form" ? 6 : -1);
    let now = new Date();
    let formTaskList = gpsSurvey.FormTaskList;
    let formTask = null;
    if(formTaskList && formTaskList.length > 0){
      if(formTaskType == 0){
        formTask = formTaskList.find(x => x.LocateLogNumber == locateLogNumber);
      }
      else if( formTaskType == 1){
        formTask = formTaskList.find(x => x.FormTaskType == 1)
      }
    }
    if(formTask){
      formTask.ModifiedBy = auth.employeeId();
      //if userId is null keep id the same but delete task.
      formTask.AssignedUserId = userId ? userId : formTask.AssignedUserId;
      formTask.FormTaskType = formTaskType;
      if(userId == null){
        //Delete Task
        formTask.DeletedDate = now,
        formTask.DeletedDateUTCOffset = -now.getTimezoneOffset() /60,
        formTask.DeletedBy = auth.employeeId()
      }
    }
    else{
      formTask = {
        FormTaskId : -1,
        FormTaskType : formTaskType,
        FormDataId : gpsSurvey.FormDataId,
        FormId : gpsSurvey.FormId,
        FormTypeId : gpsSurvey.FormTypeId,
        CompanyId : auth.companyId(),
        AssignedUserId : userId,
        Latitude : gpsSurvey.Latitude,
        Longitude : gpsSurvey.Longitude,
        GeometryData : null,
        CreatedDate : now,
        CreatedBy : auth.employeeId(),
        CreatedDateUTCOffset : - now.getTimezoneOffset() / 60,
        ModifiedDate : now,
        ModifiedDateUTCOffset : - now.getTimezoneOffset() /60,
        ModifiedBy : auth.employeeId(),
        LocateLogNumber : formTaskType == 0 ? locateLogNumber : null
      };
    }
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      FormTask : formTask
    }

    fetch(process.env.API_URL + "/api/formTaskAssignment/save", {
      method: "POST",
      headers: {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(bodyJson =>{
          let json = JSON.parse(bodyJson)
          if(json.FormTask){
            this.props.updateResultsFromAssignment(json.FormTask);
          }
          this.props.showProgressBar("");
          this.props.setAlert('success','Task ' + ( userId ? 'Assigned' : 'Unassigned'));
          this.updateExtent = false;
          setTimeout(() =>{this.setState({updatingAssignment: false}); this.rerenderMap();},1000); //Need to make sure that results had a chance to update and set state.
        });
      }
      else{
        this.props.showProgressBar("");
        this.props.setAlert('error','Error '+ ( userId ? 'assigning' : 'unassigning'  )+ ' task.');  
      }
    })
    .catch(error =>{
      this.props.showProgressBar("");
      this.props.setAlert('error','Error '+ ( userId ? 'assigning' : 'unassigning'  )+ ' task.');
      console.log(error.message);
    });
    if(popup)popup.close();
  }

  unassignTask(gpsSurvey, popup, locateLogNumber){
    if(auth.isSuperViewer()){
      popup.close();
      return;
    }
    this.props.showProgressBar("Unassigning Tasks");
    let now = new Date();
    let formTaskList = gpsSurvey.FormTaskList;
    let formTaskPoint = null;
    let formTaskFullSurvey = null;
    if(formTaskList && formTaskList.length > 0){
      formTaskFullSurvey = formTaskList.find(x => x.FormTaskType == 1);
      if(typeof locateLogNumber !== "undefined" && locateLogNumber != null) formTaskPoint = formTaskList.find(x => x.LocateLogNumber == locateLogNumber)
    }

    let formTasksToBeUpdated = [];

    //DeletedDate will be set server side.
    if(formTaskPoint){
      //Delete Task
      formTaskPoint.DeletedDateUTCOffset = -now.getTimezoneOffset() /60,
      formTaskPoint.DeletedBy = auth.employeeId();

      formTasksToBeUpdated.push(formTaskPoint);
    }
    if(formTaskFullSurvey){
      //Delete Task
      formTaskFullSurvey.DeletedDateUTCOffset = -now.getTimezoneOffset() /60,
      formTaskFullSurvey.DeletedBy = auth.employeeId();

      formTasksToBeUpdated.push(formTaskFullSurvey);
    }

    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      FormTaskList: formTasksToBeUpdated
    }

    fetch(process.env.API_URL + "/api/formTaskAssignment/unassign", {
      method: "POST",
      headers: {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(bodyJson =>{
          let json = JSON.parse(bodyJson)
          if(json.FormTaskList){
              this.props.updateResultsFromTaskUnassign(json.FormTaskList);
          }
          this.props.showProgressBar("");
          this.props.setAlert('success','Tasks Unassigned');
          this.updateExtent = false;
          setTimeout(() =>{this.setState({updatingAssignment: false}); this.rerenderMap();},1000); //Need to make sure that results had a chance to update and set state.
        });
      }
      else{
        this.props.showProgressBar("");
        this.props.setAlert('error','Error unassigning tasks.');  
      }
    })
    .catch(error =>{
      this.props.showProgressBar("");
      this.props.setAlert('error','Error unassigning tasks.');
      console.log(error.message);
    });
    popup.close();
  }

  openDetailsView(){
    this.setState({viewingDetails : !this.state.viewingDetails});
  }

  viewEntity(element){
    this.setState({viewingDetails: true, entityToView: {FormData: JSON.parse(JSON.stringify(element))} });
  }

  componentDidUpdate(prevProps, prevState){ 
    let multiMediaUpdate = false
    multiMediaUpdate = prevState.videoIndex != this.state.videoIndex || prevState.loadingVideos != this.state.loadingVideos || prevState.loadingMultimedia != this.state.loadingMultimedia || prevState.images != this.state.images || prevState.videos != this.state.videos || prevState.thumbnails != this.state.thumbnails || prevState.videoCount != this.state.videoCount || prevState.pictureCount != this.state.pictureCount;
    window.resize();

    // NO RESULTS
    if (((this.props.resultSet && this.props.resultSet.length == 0) || !this.state.map) || multiMediaUpdate){
      return;
    }

    if(!this.isFirstRerender &&
      this.props.resultSet.FormCollection == prevProps.resultSet.FormCollection 
      && this.props.resultSet.FormDataCollection == prevProps.resultSet.FormDataCollection
      && this.props.resultSet.UsersCollection == prevProps.resultSet.UsersCollection) return;

    this.isFirstRerender = false;

    if(!this.rerenderingMap)this.rerenderMap();
  }

  rerenderMap(){
    this.rerenderingMap = true
    let me = this;
    let _MAX_SURVEYCOUNT_BEFORE_COMBINE = 1000; // map performance max survey and feature counts, to group\combine on map for performance (to user-expand)
    let _MAX_FEATURECOUNT_BEFORE_COMBINE = 50;

    //eslint-disable-next-line
    loadModules(['esri/layers/GraphicsLayer', 'esri/layers/FeatureLayer', 'esri/Graphic', 'esri/geometry/Extent', 'esri/geometry/Point','esri/layers/KMLLayer','esri/layers/support/Field', 'dojo/dom-construct'])
      .then(([GraphicsLayer, FeatureLayer, Graphic, Extent, Point, KMLLayer,Field, domConstruct]) => {

      var pointGraphicsByFormId = {};
      var xmax = -180, xmin = 180, ymax = -90, ymin = 90;
      var userOptionList ="<option value='-1'></option>";
      var actionList = "<option value='-1'></option><option value='assignArea'>Assign Area</option><option value='assignAllTasks'>Assign All Tasks</option><option value='unassignAllTasks'>Unassign All Tasks</option>";
      var assignmentGraphics = [];

      // CLEANUP
      this.state.map.layers.removeAll();
      this.state.map.layers.add(this.state.sketchLayer);
      this.state.map.layers.add(this.state.layerAssignmentAreas)
      
      // AGS LAYERS
      if(this.props.CompanyArcGisLayers.length > 0 && this.state.arcGisConnectedLayersAccessToken){
        this.props.CompanyArcGisLayers.forEach(companyLayer =>{
          var layer = new FeatureLayer({title: companyLayer.Name,url:companyLayer.Url, popupTemplate:{title: companyLayer.Name, content: me.getAutoAttributePopupContent, outFields : ["*"]}});
          this.state.map.layers.add(layer);
        })
      }

      // KML LAYERS
      if(this.props.CompanyKMLSHAPELayers.length > 0){
        this.props.CompanyKMLSHAPELayers.forEach(companyLayer =>{
          if(companyLayer.FileType == "kml"){
            var layer = new KMLLayer({title: companyLayer.Name, url:process.env.API_URL+"/api/GetKMLShapeLayerFile/" + companyLayer.CompanyKMLSHAPELayerId + "/" + companyLayer.SecureCode, popupTemplate:{title: companyLayer.Name, content: me.getAutoAttributePopupContent, outFields : ["*"]}});
            this.state.map.layers.add(layer);
          }
          if(companyLayer.FileType == "shape"){
            let sourceGraphics = [];
            let layers = companyLayer.shapeJSON.layers.map((layer) =>{
              let graphics = layer.featureSet.features.map((feature)=>{
                return Graphic.fromJSON(feature);
              });
              sourceGraphics = sourceGraphics.concat(graphics);
              let featureLayer = new FeatureLayer({
                objectIdField: "FID",
                source: graphics,
                title : layer.layerDefinition.name,
                fields: layer.layerDefinition.fields.map((field) => {
                  return Field.fromJSON(field);
              })
              });
              return featureLayer;
            });
            this.state.map.addMany(layers);
          }
        });
      }

      // ACTIONS
      var delAction = { title: "Erase", id: "del", className: "esri-icon-erase" };
      var detailsPage = { title: "Details", id:"viewDetails", className: "esri-icon-attachment"};
      var deletePoint = {title: "Delete Point", id: "deletePoint", className: "esri-icon-trash"}
      var assignTaskAction = { title: "Assign Task", id: "assignTask", className: "esri-icon-authorize" }; // title must match assign task button function
      var delSurvey = { title: "Archive", id : "archive", className: "esri-icon-trash"}
      var unassignTaskAction = { title: "Unassign", id:"unassignTask", className: "esri-icon-rotate"}
      var viewMultimedia = {title : "Multimedia", id : "viewMultimedia", className : "esri-icon-media"};
      var viewPointMultimedia = {title : "Multimedia (Point)", id : "viewPointMultimedia", className : "esri-icon-media"};
      var expandFormData = { title: "Expand", id: "expandFormData", className: "esri-icon-zoom-out-fixed" };
      // var viewLarger = { title: 'Full Screen', id : 'viewLarger', className:'esri-icon-zoom-out-fixed'};
      // var viewVideo = { title: 'View Video', id : 'viewVideo', className: 'esri-icon-media2'};

      // if(this.props.multimediaAssets){
      //   this.props.multimediaAssets.forEach(element =>{
      //     var title = element.MediaTypeId == 0 || element.MediaTypeId == null ? "Picture" : "Video";
      //     if(this.view){
      //       var newGraphic = new Graphic({
      //         geometry: {type: "point", longitude : element.Longitude, latitude : element.Latitude, spatialReference : {wkid : 4326}},
      //         symbol: {
      //           type: "text",
      //           color : "black",
      //           text : title == "Picture" ? "\ue661" : "\ue662",
      //           haloColor : [255,255,255,1],
      //           haloSize : "1px",
      //           font: {size : 18,family: 'CalciteWebCoreIcons'}
      //         },
      //         attributes : element,
      //         popupTemplate : {
      //           title : "<b><span class='leakSurveyPopupHeader' style='color:black'>" + title + "</span></b>",
      //           actions : [element.MediaTypeId == 0 || element.MediaTypeId == null ? viewLarger : viewVideo],
      //           content: setMultimediaPopupContent,
      //           outFields:["*"]
      //         }
      //       });
      //       this.view._clusterData.push(newGraphic);
      //     }
      //     function setMultimediaPopupContent(multimediaAsset){
      //       var data = multimediaAsset.graphic.attributes;
      //       var srcUrl = process.env.API_URL + "/api/image/" + data.MultimediaAssetId;
      //       var node = domConstruct.create("div", { innerHTML:
      //         "<div id='image" + data.MultimediaAssetId + "'><img src='" + srcUrl + "' alt='' style='margin-top : 5px; object-fit: cover; width: 99%; height: 250px;'/></div>"
      //       });
      //       return node;
      //     }
      //   });
      // }

      // USER COLLECTION
      this.props.resultSet.UsersCollection.forEach(user =>{
        let userOption = "<option value='" + user.UserId+"'>" + user.DisplayNameProper +"</option>";
        userOptionList += userOption;
      });
      
      me.state.userOptionList = userOptionList;
      me.state.actionList = actionList;
      
      // CREATE LAYERS | each unqiue FormId is its own layer
      var formCollection = [];
      var formDataCollection = [];
      me.props.resultSet.FormCollection.forEach(formData => {formCollection[formData.FormId] = formData;});
      me.props.resultSet.FormDataCollection.forEach(formData => {formDataCollection[formData.FormDataId] = formData;});

      // SURVEY POINT GRAPHICS
      var polylineGraphicsByFormId = {};
      var polygonGraphicsByFormId = {};
      var formDataMarkerGraphicsByFormDataId = {};
      var whitelineGraphics = [];
      if(this.props.resultSet.GPSSurveyAssignmentCollection){
        this.props.resultSet.GPSSurveyAssignmentCollection.forEach(assignment =>{
        if(assignment.AssignmentPolygon != null && assignment.AssignmentPolygon != ''){
          var newGraphic = new Graphic({
            geometry: {type: "polygon", rings: JSON.parse(assignment.AssignmentPolygon), spatialReference: { wkid: 4326 }}, 
            symbol: {type: "simple-fill", style: "solid", color: [220, 140, 40, 0.6], outline: {color:[180,120,50,0.9], width:2}},
            attributes: assignment,
            popupTemplate: {
              title: "Assigned: <b>{Username}</b>",
              actions: [delAction],
              outFields:["*"]
            }
          });
          if ((newGraphic.geometry != null && newGraphic.geometry.extent != null)){
            var point = newGraphic.geometry.extent.center;
            var txtSym = {type: "text", color: "white", text: assignment.Username, haloColor: [110,110,110,1], haloSize: "1px", font: { size: 10, weight: "bold" }};
            var lblGra = new Graphic(point, txtSym);
            assignmentGraphics.push(newGraphic);
            assignmentGraphics.push(lblGra);
            
            // Determine Data Extent
             if(!this.firstLoadComplete){
              if(point.longitude != null){
                xmax = xmax < point.longitude ? point.longitude : xmax;
                xmin = xmin > point.longitude ? point.longitude : xmin;
              }
              if(point.latitude != null){
                ymax = ymax < point.latitude ? point.latitude : ymax;
                ymin = ymin > point.latitude ? point.latitude : ymin;
              }
            }
          }
        }
        });
      }

      var layerAssignmentAreas = this.state.layerAssignmentAreas;
      if (layerAssignmentAreas != undefined) {
        layerAssignmentAreas.graphics = assignmentGraphics;
        layerAssignmentAreas.listMode = (assignmentGraphics.length == 0) ? 'hide' : 'show';
        if(!this.props.updatedAssignementLayer)layerAssignmentAreas.visible = false;
      }

      this.props.resultSet.FormDataCollection.forEach(formData => {

        const geometryDataParsed = JSON.parse(formData.GeometryDataMin); // !PERFORMANCE_UPDATE

        if (!formData.isExpanded && ((this.props.resultSet.FormDataCollection.length > _MAX_SURVEYCOUNT_BEFORE_COMBINE && formData.FieldsValues) 
          || (geometryDataParsed.PointsMin && geometryDataParsed.PointsMin.length > _MAX_FEATURECOUNT_BEFORE_COMBINE))) 
        {
          var formDataFieldInfosPoint = [];
          let formDataMarkerAttributes = {};
          var formDataFieldValueData = JSON.parse(formData.FieldsValues);
          formDataFieldValueData.forEach(fieldData => {
            let fieldName = fieldData.Name;
            let fieldValue = fieldData.Value;
            formDataFieldInfosPoint.push({ fieldName: fieldName });
            formDataMarkerAttributes[fieldName] = fieldValue;
          });
          formDataMarkerAttributes['FormData'] = formData;
          var formDataMarkerGraphic = new Graphic({
            geometry: { type: "point", longitude: formData.Longitude, latitude: formData.Latitude, spatialReference: { wkid: 4326 } },
            symbol: {
              type: "simple-marker", 
              path: this.MarkerUtil.getPath(formData.MarkerSymbol), 
              color: formCollection[formData.FormId].Color,
              size: 25,
              outline: {color: [ 222, 222, 222, 0.8 ], width: 1},
              yoffset: "15px"
            },
            attributes: formDataMarkerAttributes,
            popupTemplate: {
              actions: [expandFormData,detailsPage,viewMultimedia, delSurvey],
              title: "<b>" + formData.Name + " - " + formData.FormDataId + "</b>",
              content: [{
                type: "fields",
                fieldInfos: JSON.parse(JSON.stringify(formDataFieldInfosPoint))
              }],
              outFields: ["*"]
            }
          });
          formDataMarkerGraphicsByFormDataId[formData.FormDataId] = formDataMarkerGraphic;
          
          // determine max extent from data
          xmax = (formData.Longitude != 0  && (xmax < formData.Longitude)) ? formData.Longitude : xmax;
          xmin = (formData.Longitude != 0 && (xmin > formData.Longitude)) ? formData.Longitude : xmin;
          ymax = (formData.Latitude != 0 && (ymax < formData.Latitude)) ? formData.Latitude : ymax;
          ymin = (formData.Latitude != 0 && (ymin > formData.Latitude)) ? formData.Latitude : ymin;
          
          //DO NOT CONTINUE PROCESSING INDIVIDUAL FORM DATA FEATURES (return)
          return;
        }
       
        var fieldInfosPoint = [];
        var fieldInfosPolyline = [];
        var fieldInfosPolygon = [];
        
        // create line collection for this formId
        if (!polylineGraphicsByFormId[formData.FormId]) { polylineGraphicsByFormId[formData.FormId] = []; }

        // create polygon collection for this formId
        if (!polygonGraphicsByFormId[formData.FormId]) { polygonGraphicsByFormId[formData.FormId] = []; }

        // create point collection for this formDataId
        if (!pointGraphicsByFormId[formData.FormId]) pointGraphicsByFormId[formData.FormId] = [];

        // add points to the collection for this formId
        
        if (geometryDataParsed && !geometryDataParsed.PointsMin) { geometryDataParsed.PointsMin = [{lat : formData.Latitude, lon : formData.Longitude }] }
        
        if (geometryDataParsed && geometryDataParsed.PointsMin){
        
          // linepaths
          var polylineGraphic;
          var linepaths = formCollection[formData.FormId].GeometryType == 'Line' ? geometryDataParsed.LinePaths : null;
          var polygons = formCollection[formData.FormId].GeometryType == 'Polygon' ? geometryDataParsed.Polygons : null;
          
          // data attributes
          var fieldValueAttributes = {};
          var fieldValueData = JSON.parse(formData.FieldsValues);
            fieldValueData.forEach(fieldData => {
              let fieldName = fieldData.Name ? fieldData.Name : "";
              let fieldValue = fieldData.Value ? fieldData.Value : "";
              if(fieldName != "") fieldInfosPoint.push({fieldName: fieldName});
              if(fieldName != "") fieldInfosPolyline.push({fieldName: fieldName});
              if(fieldName != "") fieldInfosPolygon.push({fieldName: fieldName});
              fieldValueAttributes[fieldName] = (fieldData.Type == "Signature" ) ?  ((fieldValue && fieldValue != "") ? "Signed" : "")  : fieldValue;
          });
          fieldValueAttributes['FormData'] = formData;

          // point attribute fields
          fieldInfosPoint.push({fieldName: 'Altitude'});
          fieldInfosPoint.push({fieldName: 'Latitude'});
          fieldInfosPoint.push({fieldName: 'Longitude'});
          fieldInfosPoint.push({fieldName: 'Dilution Of Precision'});
          fieldInfosPoint.push({fieldName: 'Locate Measured Depth mm'});

          // point geometry
          for (let index = 0; index < geometryDataParsed.PointsMin.length; index++) {
            const pointInfo = geometryDataParsed.PointsMin[index];
            if (pointInfo){
              // point attributes
              
              var pointFieldValueAttributes = JSON.parse(JSON.stringify(fieldValueAttributes));
              pointFieldValueAttributes['Altitude'] = pointInfo.altitude;
              pointFieldValueAttributes['Latitude'] = pointInfo.lat;
              pointFieldValueAttributes['Longitude'] = pointInfo.lon;
              pointFieldValueAttributes['Dilution Of Precision'] = pointInfo.positionalDilutionOfPrecision;
              pointFieldValueAttributes['LocateLogNumber'] = pointInfo.logNumber;
              pointFieldValueAttributes['FormId'] = formData.FormId;
              pointFieldValueAttributes['pointIndex'] = index;
              pointFieldValueAttributes['Locate Measured Depth mm'] = pointInfo.locateMesuredDepthMm;
              let unassignButton = false;
              
              if(formData.FormTaskList && formData.FormTaskList.length > 0){
                let task = formData.FormTaskList.find(x => (x.FormTaskType == 0 && x.LocateLogNumber == pointInfo.logNumber) || (x.FormTaskType == 1 && x.FormDataId == formData.FormDataId));
                if(task){
                  unassignButton = true;
                  if(!fieldInfosPoint.find(x => x.fieldName == 'Assigned')){
                    fieldInfosPoint.push({fieldName : 'Assigned'});
                    fieldInfosPoint.push({fieldName : 'Assigned Task'});
                  }
                  pointFieldValueAttributes['Assigned'] = task.AssignedUserDisplayName;
                  pointFieldValueAttributes['Assigned Task'] = task.FormTaskType == 0 ? "Walkback Point" : task.FormTaskType == 1 ? "Walkback Full Survey" : "";
                }
                else if(fieldInfosPoint.find(x => x.fieldName == "Assigned")){
                  fieldInfosPoint = fieldInfosPoint.filter(x => x.fieldName != 'Assigned' && x.fieldName != 'Assigned Task');
                }
              }
              
              let actions = unassignButton ? [detailsPage,assignTaskAction,unassignTaskAction,deletePoint,viewMultimedia,delSurvey] : [detailsPage,assignTaskAction,deletePoint,viewMultimedia,delSurvey];
              //let hasComments = this.pointHasComment(index, formData, geometryDataParsed);
              //let hasMultimedia = this.pointHasMultimedia(index, formData, geometryDataParsed);
              //if(hasMultimedia) actions.push(viewPointMultimedia);
              //if(hasComments)  {
                // fieldInfosPoint.push({fieldName: 'Point Comments'});
                // pointFieldValueAttributes['Point Comments'] = this.getPointComments(index, formData, geometryDataParsed);
              //}

              var pointGraphic = new Graphic({ 
                geometry: {type: "point", longitude: pointInfo.lon, latitude: pointInfo.lat, spatialReference: { wkid: 4326 }}, 
                symbol: {type: "simple-marker", style: "circle", //path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
                  size:17, color: formCollection[formData.FormId].Color,  path: this.MarkerUtil.getPath(formData.MarkerSymbol), outline: {color: [ 22, 22, 22, 0.25], width: 1}},
                attributes: pointFieldValueAttributes,
                popupTemplate: {
                  actions: actions,
                  title: "<b>" + formData.Name + " - " + formData.FormDataId + "</b>",
                  content: [{
                    type: "fields",
                    fieldInfos: JSON.parse(JSON.stringify(fieldInfosPoint))
                  }],
                  outFields:["*"]
                }
              });

              //IF POINT HAS POINT MULTIMEDIA OR POINT COMMENTS, DECORATE WITH STAR
              // var pointDecorationSymbol = {
              //   type: "simple-marker",
              //   path : "m56,237 74-228 74,228L10,96h240",
              //   color: formCollection[formData.FormId].Color,
              //   size: 10,
              //   outline: {color: [0,0,0,0.5], width: 1},
              //   xoffset: 5,
              //   yoffset: 5
              // }
              // var pointDecorationGraphic = new Graphic({
              //   geometry: {type: "point", longitude: pointInfo.lon, latitude: pointInfo.lat, spatialReference: { wkid: 4326 }},
              //   symbol : pointDecorationSymbol,
              //   attributes: pointFieldValueAttributes,
              // });
              //if(hasComments || hasMultimedia) 
              //pointGraphicsByFormId[formData.FormId].push(pointDecorationGraphic);

              // add graphic to layer
              pointGraphicsByFormId[formData.FormId].push(pointGraphic);

              // determine max extent from data
              xmax = (pointInfo.lon != 0  && (xmax < pointInfo.lon)) ? pointInfo.lon : xmax;
              xmin = (pointInfo.lon != 0 && (xmin > pointInfo.lon)) ? pointInfo.lon : xmin;
              ymax = (pointInfo.lat != 0 && (ymax < pointInfo.lat)) ? pointInfo.lat : ymax;
              ymin = (pointInfo.lat != 0 && (ymin > pointInfo.lat)) ? pointInfo.lat : ymin;
            }
          }

          if (linepaths){

            let unassignButton = false;
            if(formData.FormTaskList && formData.FormTaskList.length > 0){
              let task = formData.FormTaskList.find(x => x.FormTaskType == 1 && x.FormDataId == formData.FormDataId);
              if(task){
                unassignButton = true;
                if(!fieldInfosPolyline.find(x => x.fieldName == 'Assigned')){
                  fieldInfosPolyline.push({fieldName : 'Assigned'});
                  fieldInfosPolyline.push({fieldName : 'Assigned Task'});
                }
                fieldValueAttributes['Assigned'] = task.AssignedUserDisplayName;
                fieldValueAttributes['Assigned Task'] = "Walkback Full Survey";
              }
              else if(fieldInfosPolyline.find(x => x.fieldName == "Assigned")){
                fieldInfosPolyline = fieldInfosPolyline.filter(x => x.fieldName != 'Assigned' && x.fieldName != 'Assigned Task');
              }
            }

            polylineGraphic = new Graphic({
              geometry: {type: "polyline", paths: linepaths}, 
              symbol: {type: "simple-line", color: formCollection[formData.FormId].Color, width: 3, 
              style:formCollection[formData.FormId].LineMarkerSymbol ? formCollection[formData.FormId].LineMarkerSymbol : 'solid'},
              attributes: fieldValueAttributes,
              popupTemplate: {
                actions: unassignButton ? [detailsPage,assignTaskAction,unassignTaskAction,delSurvey] : [detailsPage,assignTaskAction,delSurvey],
                title: "<b>" + formData.Name + " - " + formData.FormDataId + "</b>",
                content: [{
                  type: "fields",
                  fieldInfos: fieldInfosPolyline
                }],
                outFields:["*"]
              }
            });

            // add graphic to layer
            if (polylineGraphic) {
              polylineGraphicsByFormId[formData.FormId].push(polylineGraphic);
            }
          }
          
          if (polygons){

            let unassignButton = false;
            if(formData.FormTaskList && formData.FormTaskList.length > 0){
              let task = formData.FormTaskList.find(x => x.FormTaskType == 1 && x.FormDataId == formData.FormDataId);
              if(task){
                unassignButton = true;
                if(!fieldInfosPolygon.find(x => x.fieldName == 'Assigned')){
                  fieldInfosPolygon.push({fieldName : 'Assigned'});
                  fieldInfosPolygon.push({fieldName : 'Assigned Task'});
                }
                fieldValueAttributes['Assigned'] = task.AssignedUserDisplayName;
                fieldValueAttributes['Assigned Task'] = "Walkback Full Survey";
              }
              else if(fieldInfosPolygon.find(x => x.fieldName == "Assigned")){
                fieldInfosPolygon = fieldInfosPolygon.filter(x => x.fieldName != 'Assigned' && x.fieldName != 'Assigned Task');
              }
            }

            const formColor = formCollection[formData.FormId].Color

            const r = parseInt(formColor.slice(1, 3), 16);
            const g = parseInt(formColor.slice(3, 5), 16);
            const b = parseInt(formColor.slice(5, 7), 16);

            const polygonFillRgba = [r, g, b, 0.5];
            const polygonOutlineRgba = [r, g, b, 1];

            polygons.forEach((polygonRings)=>{
              const polygonGraphic = new Graphic({
                geometry: {type: "polygon", rings: polygonRings}, 
                symbol: {type: "simple-fill", color: polygonFillRgba, style: 'solid', outline: {color: polygonOutlineRgba, width: 1}},
                attributes: fieldValueAttributes,
                popupTemplate: {
                  actions: unassignButton ? [detailsPage,assignTaskAction,unassignTaskAction,delSurvey] : [detailsPage,assignTaskAction,delSurvey],
                  title: "<b>" + formData.Name + " - " + formData.FormDataId + "</b>",
                  content: [{
                    type: "fields",
                    fieldInfos: fieldInfosPolygon
                  }],
                  outFields:["*"]
                }
              });
  
              // add graphic to layer
              if (polygonGraphic) {
                polygonGraphicsByFormId[formData.FormId].push(polygonGraphic);
              }
            });
          }

          // whiteline data
          var whitelineParsed = JSON.parse(formData.WhitelineData);
          if (whitelineParsed && typeof(whitelineParsed) != "string"){
            // generate whiteline polygon rings
            var rings = [[]];
            whitelineParsed.forEach(point => {
              rings[0].push([point.Lon, point.Lat]);
            });

            var newGraphic =  new Graphic({
              geometry: {type: "polygon", rings: rings, spatialReference: { wkid: 4326 }}, 
              symbol: {type: "simple-fill", style: "solid", color: [255, 255, 255, 0.8], outline: {color:[200,200,200,0.9], width:2}},
              attributes: fieldValueAttributes,
              popupTemplate: {
                actions: [detailsPage],
                title: "<b>" + formData.Name + " - " + formData.FormDataId + "</b>",
                content: [{
                  type: "fields",
                  fieldInfos: fieldInfosPolyline
                }],
                outFields:["*"]
              }
            });

            if (rings[0].length > 0){
              whitelineGraphics.push(newGraphic);
            }
            
          }
        }
      });

      //VIEW and CLUSTER CONFIG
      let v = this.view;
      if(v){
        v._clusterTolerance = 1;
        v._clusterData = [];
        v._clusters = [];
        v._singles = [];
        v.pointGraphicsByFormId = pointGraphicsByFormId;
        v.formDataMarkerGraphicsByFormDataId = formDataMarkerGraphicsByFormDataId;
        v.formCollection = formCollection
        v.formDataCollection = formDataCollection
        v._clusterSizeMax = 999;
        v._resolutionFactor = 2000;
        v._clusterCountDisplayMin = 20;
        v._polylineRenderMinZoomLevel = 15;
        v.layerDictionary = this.layerDictionary;
      }
      
      if(this.view){
          this.view.cluster_expandCluster = function cluster_expandCluster(c, ref){
            //label click - find realted cluster and use to expand
            var c2; if(c.attributes.clusterId && !c.singles){
              c2 = ref._clusters.find(e => (e.attributes.clusterId == c.attributes.clusterId));
              if(c2){c = c2;}
            }

            //show singles - record zoom level to ensure it doesn't recluster until zoomed out
            c.singles.forEach(s => {s.attributes.zoom=ref.zoom;pointGraphicsByFormId[c.FormId].push(s);});

            //remove cluster & label
            pointGraphicsByFormId[c.FormId].forEach(ec => {
              if(ec.attributes && ec.attributes.clusterId == c.attributes.clusterId && ec.attributes.clusterCount > 1) ec.visible = false;
            });

            Object.keys(pointGraphicsByFormId).forEach(formId =>{
              if(!ref.layerDictionary[formId]){
                let element = pointGraphicsByFormId[formId][0].attributes;
                var layer = new GraphicsLayer({title: element.FormData.Name});
                ref.layerDictionary[formId] = {layer};
                this.mapLayerType.push((typeof(formId) == "string") ? parseInt(formId) : formId);
                this.legendStyle[formId + 'C'] = element.FormData.Color; 
                this.markerStyle[element.FormData.Name] = element.FormData.MarkerSymbol ? element.FormData.MarkerSymbol : 'default';
                this.legendStyle[element.FormData.Name] = element.FormData.Color; 
                this.map.layers.add(layer);
              }
            });

            Object.keys(formDataMarkerGraphicsByFormDataId).forEach(formDataId =>{
              let formData = formDataCollection[formDataId];
              let form = formCollection[formData.FormId];
              if(!ref.layerDictionary[form.FormId]){
                var layer = new GraphicsLayer({title: form.Name});
                ref.layerDictionary[form.FormId] = {layer};
                this.mapLayerType.push((typeof(form.FormId) == "string") ? parseInt(form.FormId) : form.FormId);
                this.legendStyle[form.FormId + 'C'] = form.Color; 
                this.markerStyle[form.Name] = form.MarkerSymbol ? form.MarkerSymbol : 'default';
                this.legendStyle[form.Name] =form.Color; 
                this.map.layers.add(layer);
              }
            });

            Object.keys(ref.layerDictionary).forEach((formId)=>{
              ref.layerDictionary[formId].graphics = [];
            });
            //update layer graphics
            Object.keys(pointGraphicsByFormId).forEach(formId =>{
              //this.layerDictionary[formId].graphics = [];
              if(ref.zoom >= ref._polylineRenderMinZoomLevel && (polylineGraphicsByFormId[formId] ||  polygonGraphicsByFormId[formId])) {
                if(polylineGraphicsByFormId[formId]){
                  polylineGraphicsByFormId[formId].forEach((polyLineGraphic)=>{
                    if(polylineGraphicIsInExtent(polyLineGraphic, ref.extentGeo)){
                      this.layerDictionary[formId].graphics.add(polyLineGraphic);
                    }
                  });
                }
                if(polygonGraphicsByFormId[formId]){
                  polygonGraphicsByFormId[formId].forEach((polygonGraphic)=>{
                    if(polygonGraphicIsInExtent(polygonGraphic, ref.extentGeo)){
                      this.layerDictionary[formId].graphics.add(polygonGraphic);
                    }
                  });
                }
              } else{
                ref.layerDictionary[formId].graphics = [];
              }
              pointGraphicsByFormId[formId].forEach((graphic)=>{
                if(graphic.attributes.FormData && 
                  graphic.attributes.FormData.Latitude && 
                  graphic.attributes.FormData.Longitude && 
                  pointIsInExtent(graphic.attributes.FormData.Latitude, graphic.attributes.FormData.Longitude, ref.extentGeo))
                ref.layerDictionary[formId].graphics.add(graphic);
              });
            });

            Object.keys(formDataMarkerGraphicsByFormDataId).forEach(formDataId =>{
              let formId = formDataCollection[formDataId].FormId;
              ref.layerDictionary[formId].graphics.add(formDataMarkerGraphicsByFormDataId[formDataId]);
            });
          

        }
        // ** CLUSTER GRAPHICS
        this.view.cluster_clusterGraphics = function cluster_clusterGraphics(ref){
          let addedLayer = false;
          //ALL DATA create clusters and singles
          for(var j = 0, jl = ref._clusterData.length; j < jl; j++){
            var p = ref._clusterData[j];
            var clustered = false;
            var inExtent = p.geometry.x < ref.extentGeo.xmax && p.geometry.x > ref.extentGeo.xmin && p.geometry.y < ref.extentGeo.ymax && p.geometry.y > ref.extentGeo.ymin;
            if(!inExtent) continue; //not in the extent, continue without drawing feature

            //ADD FEATURE TO EXISTING CLUSTER
            for(var i = 0; i < ref._clusters.length; i++){
              var c = ref._clusters[i];
              //POINT check if its in a CLUSTER RANGE
              if(cluster_clusterTest(p, c, ref) && c.attributes.clusterCount < ref._clusterSizeMax && c.FormId == p.attributes.FormId){
                cluster_clusterAddPoint(p, c);
                clustered = true;
                break;
              }
            }

            // create new cluster
            if(!clustered){
              cluster_clusterCreate(p, ref);
            }
          }

          //show all clusters
          ref._clusters.forEach(c => {cluster_showCluster(c, ref);});

          //update layer grpahics          
          Object.keys(pointGraphicsByFormId).forEach(formId =>{
            if(!this.layerDictionary[formId] && pointGraphicsByFormId[formId].length > 0){
              addedLayer = true;
              let element = pointGraphicsByFormId[formId][0].attributes;
              if(!element.FormData){
                element = pointGraphicsByFormId[formId][0].singles[0].attributes;
              }
              var layer = new GraphicsLayer({title: element.FormData.Name});
              this.layerDictionary[formId] = {layer};
              this.mapLayerType.push((typeof(formId) == "string") ? parseInt(formId) : formId);
              this.legendStyle[formId] = element.FormData.Color; 
              this.markerStyle[element.FormData.Name] = element.FormData.MarkerSymbol ? element.FormData.MarkerSymbol : 'default';
              this.legendStyle[element.FormData.Name] = element.FormData.Color; 
              this.map.layers.add(layer);
            }
          });

          Object.keys(formDataMarkerGraphicsByFormDataId).forEach(formDataId =>{
            let formData = formDataCollection[formDataId];
            let form = formCollection[formData.FormId];
            if(!this.layerDictionary[form.FormId]){
              addedLayer = true;
              var layer = new GraphicsLayer({title: form.Name});
              this.layerDictionary[form.FormId] = {layer};
              this.mapLayerType.push((typeof(form.FormId) == "string") ? parseInt(form.FormId) : form.FormId);
              this.legendStyle[form.FormId + 'C'] = form.Color; 
              this.markerStyle[form.Name] = form.MarkerSymbol ? form.MarkerSymbol : 'default';
              this.legendStyle[form.Name] =form.Color; 
              this.map.layers.add(layer);
            }
          });

          Object.keys(this.layerDictionary).forEach((formId)=>{
            this.layerDictionary[formId].graphics = [];
          });

          Object.keys(pointGraphicsByFormId).forEach(formId =>{
            //this.layerDictionary[formId].graphics = [];
            if(ref.zoom >= ref._polylineRenderMinZoomLevel  && (polylineGraphicsByFormId[formId] ||  polygonGraphicsByFormId[formId])) {
              if(polylineGraphicsByFormId[formId]){
                polylineGraphicsByFormId[formId].forEach((polyLineGraphic)=>{
                  if(polylineGraphicIsInExtent(polyLineGraphic, ref.extentGeo)){
                    this.layerDictionary[formId].graphics.add(polyLineGraphic);
                  }
                });
              }
              if(polygonGraphicsByFormId[formId]){
                polygonGraphicsByFormId[formId].forEach((polygonGraphic)=>{
                  if(polygonGraphicIsInExtent(polygonGraphic, ref.extentGeo)){
                    this.layerDictionary[formId].graphics.add(polygonGraphic);
                  }
                });
              }
            }
            pointGraphicsByFormId[formId].forEach((graphic)=>{
              this.layerDictionary[formId].graphics.add(graphic);
            });
            //this.layerDictionary[formId].graphics = pointGraphicsByFormId[formId];
          });

          Object.keys(formDataMarkerGraphicsByFormDataId).forEach(formDataId =>{
            let formId = formDataCollection[formDataId].FormId;
            let graphic = formDataMarkerGraphicsByFormDataId[formDataId];
            if(pointIsInExtent(graphic.attributes.FormData.Latitude, graphic.attributes.FormData.Longitude, ref.extentGeo))
            this.layerDictionary[formId].graphics.add(graphic);
          });

          if(addedLayer){
            setTimeout(()=> {
              if (document.getElementsByClassName("esri-layer-list__item-container") != undefined) {
                var listItemCollection = document.getElementsByClassName("esri-layer-list__item-container");
                for (let index = 0; index < listItemCollection.length; index++) {
                  listItemCollection[index].style.borderLeftColor = this.legendStyle[listItemCollection[index].firstElementChild.lastElementChild.innerHTML];
                }
              }
            }, 1000);
          }
        }
        
      } // ** VIEW initialization

        //CLUSTER TEST if point is inside cluster range
        function cluster_clusterTest(p, cluster, ref){
          var distance = (Math.sqrt(Math.pow((cluster.x - p.geometry.x), 2) + Math.pow((cluster.y - p.geometry.y), 2)) / ref.resolution * ref._resolutionFactor);
          return (distance <= ref._clusterTolerance);
        }
  
        //CLUSTER ADD POINT
        function cluster_clusterAddPoint(p, cluster){
          //average in the new point to the cluster geometry
          var count, x, y;
          count = cluster.attributes.clusterCount;
          x = (p.geometry.x + (cluster.x * count)) / (count + 1);
          y = (p.geometry.y + (cluster.y * count)) / (count + 1);
          cluster.x = x;
          cluster.y = y;
  
          //build an extent that includes all points in a cluster
          //extents are for debug/testing only not used by the layer
          if (p.geometry.x < cluster.attributes.extent[0]){
            cluster.attributes.extent[0] = p.geometry.x;
          } else if (p.x > cluster.attributes.extent[2]){
            cluster.attributes.extent[2] = p.geometry.x;
          }
          if(p.geometry.y < cluster.attributes.extent[1]){
            cluster.attributes.extent[1] = p.geometry.y;
          } else if (p.geometry.y > cluster.attributes.extent[3]){
            cluster.attributes.extent[3] = p.geometry.y;
          }
  
          //increment the count & add single
          if(!p.attributes.MultimediaAssetId){
            cluster.attributes.clusterCount++;
          }
          cluster.singles.push(p);
  
          //give the graphic a cluster id
          p.attributes.clusterId = cluster.attributes.clusterId+'s';
        }
  
        //CREATE CLUSTER
        function cluster_clusterCreate(p, ref){
          var clusterId = ref._clusters.length + 1;
          p.attributes.clusterId = clusterId;
          var cluster = {
            "x" : p.geometry.x,
            "y" : p.geometry.y,
            "attributes" : {
              "clusterCount" : 1,
              "clusterId" : clusterId,
              "extent" : [p.geometry.x, p.geometry.y, p.geometry.x, p.geometry.y]
            },
            "singles" : [p], // add point as first single in cluster
            FormId : p.attributes.FormId
          };
  
          ref._clusters.push(cluster);
        }
  
        //CREATE CLUSTER GRAPHIC
        function cluster_showCluster(c, ref){
          //show singles - if the cluster has been expanded by user and the map has not been zoomed out
          var singlesExpanded = false;
          c.singles.forEach(s =>{
            if(s.attributes.zoom && s.attributes.zoom <= ref.zoom){
              if(pointGraphicsByFormId[c.FormId]){
                pointGraphicsByFormId[c.FormId].push(s);
              }
              else{
                pointGraphicsByFormId[c.FormId] = [];
                pointGraphicsByFormId[c.FormId].push(s);
              }
              singlesExpanded = true;
            }
            if(s.attributes.zoom && s.attributes.zoom > ref.zoom){
              s.attributes.zoom == null;
            }
          });
  
          // show singles - instead of cluster if in the extent if there is exactly 1 single feature in the cluster
          if (( c.attributes.clusterCount < ref._clusterCountDisplayMin) || (c.attributes.clusterCount == 1) || (c.attributes.zoom && c.attributes.zoom > ref.zoom)){
            c.singles.forEach(s => {
              if (!s.zoom){
                if(pointGraphicsByFormId[c.FormId]){
                  pointGraphicsByFormId[c.FormId].push(s);
                }
                else{
                  pointGraphicsByFormId[c.FormId] = [];
                  pointGraphicsByFormId[c.FormId].push(s);
                }
            }
            });
          }
          else if(!singlesExpanded) { // if this clusters singles are expanded, do not recluster
            //create cluster
            if(!ref.legendStyle[c.FormId + "C"]){
              let color = formCollection[c.FormId].Color;
              ref.legendStyle[c.FormId + "C"] = color ? color : (c.singles[0].attributes.FormData.Type == "Survey" ? '#176782' : '#5e2828') ;
            }
            var g = new Graphic({ 
              geometry: {type: "point", longitude: c.x, latitude: c.y, spatialReference: { wkid: 4326 }}, 
              symbol: {
                type: "simple-marker", 
                path: me.MarkerUtil.getPath(c.singles[0] && c.singles[0].attributes && c.singles[0].attributes.FormData && c.singles[0].attributes.FormData.MarkerSymbol ? c.singles[0].attributes.FormData.MarkerSymbol : ''), 
                color: ref.legendStyle[c.FormId + 'C'],
                size: 25,
                outline: {color: [ 222, 222, 222, 0.8 ], width: 1}
              },
              attributes: c.attributes
            });
  
            //add to graphics collection
            g.singles = c.singles;
            g.FormId = c.FormId;
            if(pointGraphicsByFormId[c.FormId]){
              pointGraphicsByFormId[c.FormId].push(g);
            }
            else{
              pointGraphicsByFormId[c.FormId] = [];
              pointGraphicsByFormId[c.FormId].push(g);
            }
  
            // add label
            var label = new Graphic({ geometry: {type: "point", longitude: c.x, latitude : c.y, spatialReference: { wkid: 4326}},
            symbol: {type: "text", color:"white", text: g.attributes.clusterCount, haloColor : [90,90,90,1], haloSize: "1px", font:{ size: 10, weight: "bold"}}});
            label.attributes = {clusterId : c.attributes.clusterId, clusterCount: g.attributes.clusterCount};
            if(pointGraphicsByFormId[c.FormId]){
              pointGraphicsByFormId[c.FormId].push(label);
            }
            else{
              pointGraphicsByFormId[c.FormId] = [];
              pointGraphicsByFormId[c.FormId].push(label);
            }
          }
        }

        function polylineGraphicIsInExtent(polylineGraphic, extent){
          let isInExtent = false;
          check:
          for(var pathsIndex in polylineGraphic.geometry.paths){
            let lineSegment = polylineGraphic.geometry.paths[pathsIndex];
            for(var lineSegmentIndex in lineSegment){
              let point = lineSegment[lineSegmentIndex];
              var pointObj = new Point({
                latitude: point[1],
                longitude: point[0]
              });
              if(extent.contains(pointObj)){
                isInExtent = true;
                break check;
              }
            }
          }
          return isInExtent;
        }

        function polygonGraphicIsInExtent(polygonGraphic, extent){
          let isInExtent = false;
          check:
          for(var pointIndex in polygonGraphic.geometry.rings[0]){
            let point = polygonGraphic.geometry.rings[0][pointIndex];
            var pointObj = new Point({
                latitude: point[1],
                longitude: point[0]
            });
            if(extent.contains(pointObj)){
              isInExtent = true;
              break check;
            }
          }
          return isInExtent;
        }

        function pointIsInExtent(lat, lon, extent){
          let isInExtent = false;
          var pointObj = new Point({
            latitude: lat,
            longitude: lon
          });
          if(extent.contains(pointObj)){
            isInExtent = true;
          }
          return isInExtent;
        }
      
      // WHITELINE LAYER GEOMETRY
      var layerWhiteline = new GraphicsLayer({title: 'Whiteline Areas'});
      layerWhiteline.graphics = whitelineGraphics;
      this.state.map.layers.add(layerWhiteline);
      this.layerDictionary["whitelineAreas"] = layerWhiteline;

      // FORM DATA LAYER GEOMETRY
      if (this.state.map){
        var layersToAddToMap = {}
        formCollection.forEach(form => {
          var layerFound = this.layerDictionary[form.FormId];
          var layer = layerFound ? this.layerDictionary[form.FormId] : new GraphicsLayer({title: form.Name});
          if (!layerFound) this.layerDictionary[form.FormId] = layer;
          this.legendStyle[form.Name] = form.Color;
          this.legendStyle[form.FormId] = form.Color;
          if (polylineGraphicsByFormId[form.FormId]){
            //layer.graphics = polylineGraphicsByFormId[form.FormId];
            pointGraphicsByFormId[form.FormId].forEach((graphic)=>{
              this.view._clusterData.push(graphic);
            });
            layersToAddToMap[form.FormId] = layer;
          }
        });
        formDataCollection.forEach((formData)=>{
          let form = formCollection[formData.FormId];
          var layerFound = this.layerDictionary[form.FormId];
          var layer = layerFound ? this.layerDictionary[form.FormId] : new GraphicsLayer({title: form.Name});
          if (!layerFound) this.layerDictionary[form.FormId] = layer;
          this.legendStyle[form.Name] = form.Color;
          this.legendStyle[form.FormId] = form.Color;
          layersToAddToMap[form.FormId] = layer;
        });
        Object.keys(layersToAddToMap).forEach(formId =>{
          this.state.map.layers.add(layersToAddToMap[formId]);
        });
      }

      //if (this.layerDictionary != undefined){
      // SHOW ONLY LAYERS WITH RESULTS
      //this.state.surveyTypeList.forEach(surveyType => {
        // this.layerDictionary[surveyType].layer.visible = true;
        // this.layerDictionary[surveyType].layer.graphics = graphics[surveyType];
        // this.layerDictionary[surveyType].layer.listMode = (graphics[surveyType].length == 0) ? 'hide' : 'show';  
        //console.log(surveyType, graphics[surveyType], (graphics[surveyType].length == 0), this.layerDictionary[surveyType].layer.listMode)
      //});

      // RESTORE PREVIOUS EXTENT OR ZOOM TO RESULTS
      if(this.props.detailsSurveyLat && this.props.detailsSurveyLon){
        this.view.center = [this.props.detailsSurveyLon, this.props.detailsSurveyLat];
        this.view.zoom = 15;
      }
      else{
        let extentRestored = false;
        if(this.isFirstLoad){
          const mapExtent = sessionStorage.getItem("gpsSurveyExtent");
          if(mapExtent != null && mapExtent != "null"){
            this.view.extent = JSON.parse(mapExtent);
            extentRestored = true
          }
          this.isFirstLoad = false;
        }
        if (this.view && this.updateExtent && !extentRestored){
          if (xmin < 180 && xmax > -180){
            this.view.extent = new Extent({xmin: xmin-0.0005, ymin: ymin-0.0005, xmax: xmax+0.0005, ymax: ymax+0.0005, spatialReference: { wkid: 4326 }});
          }
          else
          {
            this.view.center = [-98, 40];
            this.view.zoom = 4;
          }
        }
        if (this.view && !this.updateExtent && !extentRestored){
          this.view.extent = this.view.extent;
        }
      }
      var filterUser = document.getElementById("filterUser");
      userOptionList = userOptionList.slice(0,19) + "* Any User" + userOptionList.slice(19);
      filterUser.innerHTML = userOptionList;
      clearInterval(this.colorInterval);

      this.colorInterval = setInterval(() => this.colorLegendItems(), 1250);
      this.rerenderingMap = false;
    });
  }

  pointHasComment(pointIndex, formData, geometryData) {
    let pointTimestamp = null;
    let pointComment = null;
    let pointInfo = geometryData.Points[pointIndex].tiltInfo ? geometryData.Points[pointIndex].tiltInfo : geometryData.Points[pointIndex].gpsInfo
    let now = new Date();
    pointTimestamp = new Date(Date.parse(pointInfo.positionDateTimeUtc) + (-now.getTimezoneOffset() * 60000)).getTime();
    if (formData.PointComments) {
      pointComment = formData.PointComments.find((x) => {
        return x.PointTimestamp == pointTimestamp;
      });
    if (pointComment && pointComment.Comment && pointComment.Comment.trim() != '') return true;
    }
    return false;
  }

  pointHasMultimedia(pointIndex, formData, geometryData) {
    let pointTimestamp = null;
    let pointMultimedia = null;
    let pointInfo = geometryData.Points[pointIndex].tiltInfo ? geometryData.Points[pointIndex].tiltInfo : geometryData.Points[pointIndex].gpsInfo
    let now = new Date();
    pointTimestamp = new Date(Date.parse(pointInfo.positionDateTimeUtc) + (-now.getTimezoneOffset() * 60000)).getTime();
    if (formData.PointMultimedia) {
      pointMultimedia = formData.PointMultimedia.find((x) => {
        return x.PointTimestamp == pointTimestamp;
      });
    if (pointMultimedia) return true;
    }
    return false;
  }

  getPointMultimedia(pointIndex, formData, geometryData) {
    let pointTimestamp = null;
    let pointMultimedia = null;
    let pointInfo = geometryData.Points[pointIndex].tiltInfo ? geometryData.Points[pointIndex].tiltInfo : geometryData.Points[pointIndex].gpsInfo
    let now = new Date();
    pointTimestamp = new Date(Date.parse(pointInfo.positionDateTimeUtc) + (-now.getTimezoneOffset() * 60000)).getTime();
    if (formData.PointMultimedia) {
      pointMultimedia = formData.PointMultimedia.filter((x) => {
        return x.PointTimestamp == pointTimestamp;
      });
    if (pointMultimedia && pointMultimedia.length > 0) return pointMultimedia;
    }
    return [];
  }
  
  getPointComments(pointIndex, formData, geometryData) {
    let pointTimestamp = null;
    let pointComment = null;
    let pointInfo = geometryData.Points[pointIndex].tiltInfo ? geometryData.Points[pointIndex].tiltInfo : geometryData.Points[pointIndex].gpsInfo
    let now = new Date();
    pointTimestamp = new Date(Date.parse(pointInfo.positionDateTimeUtc) + (-now.getTimezoneOffset() * 60000)).getTime();
    if (formData.PointComments) {
      pointComment = formData.PointComments.find((x) => {
        return x.PointTimestamp == pointTimestamp;
      });
    if (pointComment && pointComment.Comment && pointComment.Comment.trim() != '') return pointComment.Comment;
    }
    return '';
  }

  getAutoAttributePopupContent(feature){
    let attributes = feature.graphic.attributes;
    let content = "<table class='esri-widget__table' summary='List of attributes and values'>";
    Object.keys(attributes).forEach(key =>{
      if(key != "OBJECTID" && key != "Shape" && key != "Shape__Length" && key != "Shape__Area"){
        content += "<tr><th class='esri-feature__field-header'>" + key + "</th><td class='esri-feature__field-data'>"  +  (attributes[key] ? attributes[key] : "" ) + "</td></tr>"
      }
    });
    content +="</table>";
    return content;
  }

  colorLegendItems()
  {
    let companyLayerNames = [];
    if(this.props.CompanyArcGisLayers && this.props.CompanyArcGisLayers.length > 0){
      companyLayerNames = this.props.CompanyArcGisLayers.map(layer => layer.Name);
    }
    if(this.props.CompanyKMLSHAPELayers && this.props.CompanyKMLSHAPELayers.length > 0){
      this.props.CompanyKMLSHAPELayers.forEach(layer =>{
        if(layer.FileType == "kml"){
          companyLayerNames.push(layer.Name);
        }
        if(layer.FileType == "shape"){
          companyLayerNames.push(layer.Name);
          if(layer.shapeJSON && layer.shapeJSON.layers){
            layer.shapeJSON.layers.forEach(shapeLayer =>{
              companyLayerNames.push(shapeLayer.layerDefinition.name);
            })
          }
        }
      });
    }

    // COLOR LEGEND ITEMS
    if (document.getElementsByClassName("esri-layer-list__item-container") != undefined && this.state.customFormList) {
      var listItemCollection = document.getElementsByClassName("esri-layer-list__item-container");
      for (let index = 0; index < listItemCollection.length; index++) {
        let layerName = listItemCollection[index].querySelector('.esri-layer-list__item-title').innerHTML;
        if(listItemCollection[index].firstElementChild.lastElementChild){
          listItemCollection[index].style.borderLeftColor = this.legendStyle[layerName] || this.state.defaultLayerColor;
          let parent = listItemCollection[index].parentElement;
          let grandparent = parent.parentElement;
          let greatGrand = grandparent.parentElement;
          if(!listItemCollection[index].innerHTML.includes('<svg ') && layerName != 'Whiteline Areas' && layerName !="Assignment Areas" && !companyLayerNames.includes(layerName) && !parent.classList.contains("esri-layer-list__item--has-children")&& !grandparent.classList.contains("esri-layer-list__item--has-children")&& !greatGrand.classList.contains("esri-layer-list__item--has-children")){
            let form = this.state.customFormList.find(x => x.Name == layerName);
            let marker = document.createElement("div");
            marker.innerHTML = ReactDOMServer.renderToString(<svg style={{position : 'relative'}} height="25px" width="25px" fill={this.legendStyle[layerName]}>
               <path d={this.MarkerUtil.getPath(form ? form.MarkerSymbol : "default")}></path>
              </svg>);
            listItemCollection[index].insertBefore(marker, listItemCollection[index].firstChild);
          }
        }
      }
    }
    clearInterval(this.colorInterval);
  }

  componentWillUnmount() {
    if (this.view) { this.view.container = null; this.beforeunload.bind(this); }
    window.removeEventListener('beforeunload', this.beforeunload.bind(this));
    clearInterval(this.colorInterval);
  }

  beforeunload(){
    if(this.view){
      sessionStorage.setItem("gpsSurveyExtent", JSON.stringify(this.view.extent));
    }
  }

  clickThumbNail(index){
    let id = this.state.thumbnails[index].videoId;
    let videoIndex = this.state.videos.findIndex(x => x.assetId == id);
    if(this.state.videos.length == 0 || videoIndex == -1){
      this.getVideo(id);
    }
    else{
      this.setState({videoIndex});
    }
  }

  getVideo(assetId){
    this.setState({loadingVideos : true});
    fetch(process.env.API_URL + "/api/video/" + assetId,{
      method : 'GET',
      headers : {
        "Content-Type" : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      signal : this.controller.signal
    })
    .then(r => r.blob())
    .then(blob =>{
      let videos = this.state.videos;
      let vid = URL.createObjectURL(blob);
      videos.push({uri : vid, assetId});
      this.setState({videos, videoIndex : videos.length -1});
      this.setState({ loadingVideos : false});
    }).catch(error =>{console.log(error.message); this.setState({loadingVideos : false})});
  }

  setVideoIndex(direction){
    let index = this.state.videoIndex + direction;
    if(index == -1){
      index = this.state.videos.length -1;
    }
    else if(index == this.state.videos.length){
      index = 0;
    }
    this.setState({videoIndex : index});
  }

  render() {
    return (
      <div className="webmap" ref={this.mapRef} >
        {/* <div id="imageGalleryDiv" style={{display : 'none'}}>
          <ImageGallery items={this.state.currentFullscreenImage}
            showFullScreenButton={true}
            showPlayButton={false}
            onScreenChange={fullScreen =>{
              if(fullScreen){
                document.getElementById("imageGalleryDiv").style.display = 'flex';
              }
              else{
                document.getElementById("imageGalleryDiv").style.display = 'none';
              }
            }}
            ref={i => this._imageGallery = i}
          />
        </div> */}
        <Dialog open={this.state.viewingDetails}aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth='lg' fullWidth='true' scroll='paper'>
          <DialogContent>
            {this.state.entityToView && <FormDataPage location={{formData : this.state.entityToView}} closeEntity={() =>{this.openDetailsView()}} fromDashboard={true} hideClose={true}/>}
          </DialogContent>
          <DialogActions>
            <Button className="buttonAction btn btn-primary" onClick={this.openDetailsView} color="primary">
              <label className="buttonText small" style={{margin:0, cursor: 'pointer'}}>Close</label>
            </Button>
          </DialogActions>
        </Dialog>
        <div id="mapFeaturesDiv" className="modal" style={{paddingTop : 0}}>
          <div style={{width: 253, marginTop: 168, position: 'absolute', right: 12}}>
            <div className="row" style={{background: '#a8a8a8', marginRight: '15px !important'}}>
            <div className="menuDialogMapOption">
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit fullWidth mapMenuButton" onClick={() =>{
                  document.getElementById("mapFeaturesDiv").style.display = 'none';
                  document.getElementById("addressSearchDiv").style.display = 'block';
                }}><SearchIcon style={{marginRight: 8}}/>Search Address</Button>
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit fullWidth mapMenuButton" onClick={() =>{
                  document.getElementById("mapFeaturesDiv").style.display = 'none';
                  this.view.takeScreenshot().then(screenshot =>{
                    const downloadLink = document.createElement('a');
                    document.body.appendChild(downloadLink);
                    downloadLink.href = screenshot.dataUrl;
                    downloadLink.target = '_self';
                    downloadLink.download = "Map.jpeg";
                    downloadLink.click(); 
                  });
                }}><ScreenShareIcon style={{marginRight: 8}}/>Map Print</Button>
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit fullWidth mapMenuButton" onClick={() =>{
                  document.getElementById("mapFeaturesDiv").style.display = 'none';
                  document.getElementById("mapReportDiv").style.display = 'block';
                }}><DescriptionIcon style={{marginRight: 8}}/>Map Report</Button>
              </div>
            </div>
          </div>
        </div>
        <div id="deletePointDialogDiv" className='modal' style={{paddingTop : 0}}>
          <div className="modal-content" style={{width : '66%', marginTop : '3%', maxHeight : '90%'}}>
            <span className="close text-right" style={{top: 0, left: '63vw'}} onClick={() =>{document.getElementById("deletePointDialogDiv").style.display = "none";this.setState({deletePointEvent : null, deletePointPopup : null}); }}>
              &times;
            </span>
            <div className=" labelHeaderBar labelHeaderBarTight noMultiMediaHeader"> <label className="labelHeaderName" style={{margin : "0 auto"}}>Confirm Point Deletion</label></div>
            <div className="row">
              <div style = {{margin: '0 auto', marginTop:  10, marginBottom : 15}}>
                <label className="labelHeaderName">Are you sure you want to delete this point? This is an irreversible action.</label>
              </div>
            </div>
            <div className="row">
              <div style={{margin: "0 auto", marginTop : 15, marginBottom : 10}}>
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit" style={{position : 'relative', left:117}} onClick={() =>{document.getElementById("deletePointDialogDiv").style.display = "none";this.deletePoint(this.state.deletePointEvent, this.state.deletePointPopup);}}>
                  <label style={{margin:0, cursor:'pointer'}} className="buttonText">Confirm</label>
                </Button>
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit" style={{position : 'relative', left:117}} onClick={() =>{document.getElementById("deletePointDialogDiv").style.display = "none";this.setState({deletePointEvent : null, deletePointPopup : null});}}>
                  <label style={{margin:0, cursor:'pointer'}} className="buttonText">Cancel</label>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div id="addressSearchDiv" className="modal" style={{paddingTop : 0}}>
          <div className="modal-content" style={{width : '50%', marginTop: '200px', maxHeight : "90%"}}>
            <span className="close text-right" style={{top: 0, right: '8px'}} onClick={() =>{document.getElementById("addressSearchDiv").style.display = "none"; }}>
              &times;
            </span>
            <div className=" labelHeaderBar labelHeaderBarTight noMultiMediaHeader"> <label className="labelHeaderName" style={{margin : "0 auto"}}>Find Address and Select to Pin on map</label></div>
            <div className="row">
              <div style = {{margin: '0 auto', marginTop:  10, marginBottom : 15}}>
                <label className="labelHeaderName">ADDRESS: </label>
                <input className="form-control" style={{width : '400px', display : 'inline', marginTop:18}} type={"text"} value={this.state.addressSearchForSearch} onChange={(e) => {this.searchAddress(e, 'addressSearch');}}/>
              </div>
            </div>
            <div style={{marginBottom : 15}}>
              {this.state.addressSuggestions && this.state.addressSuggestions.length > 0 && this.state.addressSuggestions.map((suggestion,index)=>(
              <div key={index} className = "row underline hoverBold" style={{cursor : 'pointer'}} onClick={() =>{this.selectSuggestedAddressToZoom(suggestion)}}>
                <div style={{margin: '0 auto'}}>
                  {suggestion.text}
                </div>
              </div>))}
              </div>
          </div>
        </div>
        <div id="mapReportDiv" className="modal" style={{paddingTop : 0}}>
          <div className="modal-content modalDialogCustom" >
            <span className="close text-right" style={{top: 0, right: '8px'}} onClick={() =>{document.getElementById("mapReportDiv").style.display = "none"; this.setState({mapReportTitle : "", mapReportTitleColor : 'black'});}}>
              &times;
            </span>
            <div className="labelHeaderBar labelHeaderBarTight noMultiMediaHeader"><label className="labelHeaderName" style={{margin : "0 auto"}}>Map Report</label></div>
            <div className="row">
              <div style={{margin: '0 auto', marginTop : 10}}>
                <label className="labelHeaderName" style={{color : this.state.mapReportTitleColor}}>Map Title: </label>
                <input className="form-control" style={{width : 285, display: 'inline'}} type={"text"} value={this.state.mapReportTitle} onChange={(e) =>{this.setState({mapReportTitle : e.target.value, mapReportTitleColor : 'black'})}} />
              </div>
            </div>
            <div className="row">
              <div style={{margin: "0 auto", marginTop : 15, marginBottom : 10}}>
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit" style={{position : 'relative', left:117}} onClick={() =>{this.createMapReport()}}>
                  <label style={{margin:0, cursor:'pointer'}} className="buttonText">Create Map Report </label>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div id ="createTaskDiv" className="modal" style={{paddingTop: 0}}>
          <div className="modal-content modalDialogCustom">
            <span className="close text-right" style={{top: 0, right: "8px"}} onClick={()=>{document.getElementById("createTaskDiv").style.display = "none"; this.props.setCreatingNewTask(false)}}>
              &times;
            </span>
            <div className=" labelHeaderBar labelHeaderBarTight noMultiMediaHeader"> <label className="labelHeaderName" style={{margin : "0 auto"}}>Enter Address Or Select Location, Assign Form and User</label></div>
            <div className="row">
              <div style={{margin: '0 auto', marginTop : 10}}>
                <label className="labelHeaderName" style={{color : this.state.newTaskAddressColor}}>ADDRESS: </label> 
                <input className="form-control" style={{width : 285, display : 'inline'}} type={"text"} value={this.state.addressSearch} onChange={(e) => {this.searchAddress(e, 'taskSearch');}}/>
                <Button title="Click on map to select location" className="buttonAction  btn btn-primary btnSubmit" style={{display: 'inline', margin: 10}} onClick={() =>{this.props.setCreatingNewTask(true); this.setState({foundAddress : "", addressSuggestions : [], addressSearch : ""}); document.getElementById("createTaskDiv").style.display = "none";}}><MapTwoIcon style={{position : 'relative', bottom : 4}}/><label className="buttonText">Map Location</label></Button>
              </div>
            </div>
            {this.state.addressSuggestions && this.state.addressSuggestions.length > 0 && this.state.addressSuggestions.map((suggestion,index)=>(
            <div key={index} className = "row underline hoverBold" style={{cursor : 'pointer'}} onClick={() =>{this.selectSuggestedAddress(suggestion)}}>
              <div style={{margin: '0 auto'}}>
                {suggestion.text}
              </div>
            </div>))}
            {this.state.newTaskLat && this.state.newTaskLon && 
            <div className = "row">
              <div style={{margin: '0 auto', marginBottom : 10}}>
                {this.state.foundAddress && this.state.foundAddress != "" && <label className="labelHeaderName">Found: </label>}
                {this.state.foundAddress} 
                {this.state.foundAddress && this.state.foundAddress != "" && " - "} 
                <label className="labelHeaderName">Latitude: </label>
                {this.state.newTaskLat.toFixed(5)},
                <label className="labelHeaderName">Longitude: </label>
                {this.state.newTaskLon.toFixed(5)}
              </div>
            </div>}
            <div className="row">
              <div style={{margin:'0 auto'}}>
                <label className="labelHeaderName" style={{color : this.state.assignedCustomFormColor}}>CUSTOM FORM: </label>
                <select className ="assignmentSelect" value={this.state.assignedCustomFormId} onChange={e =>{this.setState({assignedCustomFormId: e.target.value, assignedCustomFormColor : '#3c5873', editingNewTask : false},() =>{this.createTask()})}}>
                  <option></option>
                  {this.state.customFormList && this.state.customFormList.length > 0 && this.state.customFormList.map((customForm, index)=>(
                    <option key={index} value={customForm.FormId}>{customForm.Name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div style={{margin:'0 auto'}}>
                <label className="labelHeaderName" style={{marginRight : 20, color : this.state.assignedUserColor}}>ASSIGN USER: </label>
                <select className="assignmentSelect" value={this.state.assignedUserId} onChange={e =>{this.setState({assignedUserId : e.target.value, assignedUserColor : '#3c5873'})}}>
                  <option></option>
                  {this.state.users && this.state.users.length > 0 && this.state.users.map((user,index) => (this.state.trialMode || (user.LicenseIdCollection && (user.LicenseIdCollection.includes("site") || user.LicenseIdCollection.includes("investigation")))) && (
                    <option key={index} value={user.UserId}>{user.DisplayNameProper}</option>
                  ))}
                </select>
              </div>
            </div>
            {this.state.editingNewTask && this.state.newTaskCustomForm && <div className="row">
              <div style={{margin: '0 auto'}}>
                <FormDataPage updateTaskDocuments={(docs) =>{let newTaskCustomForm = this.state.newTaskCustomForm; newTaskCustomForm.docs = docs; this.setState({newTaskCustomForm})}} updateTaskImages={(images) =>{let newTaskCustomForm = this.state.newTaskCustomForm; newTaskCustomForm.images = images; this.setState({newTaskCustomForm})}} location={{formData: {FormData : this.state.newTaskCustomForm}}} fromTaskMap={true} closeEntity={() =>{this.closeEntity()}}/> 
              </div>
            </div>}
            <div className="row" style={{marginTop: 5}}>
              <div style={{margin: '0 auto'}}>
                {this.state.newTaskCustomForm && <Button className='buttonAction buttonAccount btn btn-primary btnSubmit' style={{position : 'relative', left : 110}} onClick={() =>{this.setState({editingNewTask : !this.state.editingNewTask})}}>
                    <label style={{margin:0, cursro: 'pointer'}} className="buttonText">{this.state.editingNewTask ? "Hide" : "Edit"} Data</label>
                </Button>}
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit" style={{position : 'relative', left: 120}} onClick={() =>{this.createAndAssignTask()}}>
                  <label style={{margin:0, cursor:'pointer'}} className="buttonText">Create Task</label>
                </Button>
              </div>
            </div>
            <div style={{height: 25}}/>
          </div>
        </div>

        <div id = "imageGalleryDiv" className="modal" style={{paddingTop : 0}}>
          <div className = "modal-content" style={{width : '66%', marginTop :(this.state.videoCount == 0 && this.state.pictureCount == 0) || this.state.loadingMultimedia ? "10%" : "3%"}}>
            {this.state.loadingMultimedia ? <div className="barLoaderPortal" style={{width: '75%'}}><BarLoader sizeUnit={"px"} size={200} color={"#095399"} loading={this.state.loadingMultimedia}/></div> : <div>
            <span className = "close text-right" style={{top : 0, left : "63vw"}} onClick = {()=>{document.getElementById("imageGalleryDiv").style.display ="none";this.setState({images : [], videos : [], thumbnails : [], videoCount : 0, pictureCount : 0})}}>
              &times;
            </span>
            {this.state.pictureCount > 0 && <div>
              <div className=" labelHeaderBar labelHeaderBarTight"> <label className="labelHeaderName">Photos ({this.state.pictureCount})</label></div>
              <div className = "row carouselRow">
                <div className="carouselGalleryDiv">
                  <ImageGallery items={this.state.images} 
                    showFullscreenButton={this.state.images.length > 0}
                    showPlayButton={this.state.images.length > 0}
                    ref={i => this._imageGallery = i}
                    />
                  </div>
                </div>
            </div>}
            {this.state.videoCount > 0 && 
            <VideoPanel 
              setVideoIndex={this.setVideoIndex}
              videoIndex={this.state.videoIndex} 
              clickThumbNail={this.clickThumbNail} 
              thumbnails={this.state.thumbnails} 
              loadingVideos={this.state.loadingVideos} 
              videoCount={this.state.videoCount} 
              videos={this.state.videos}
              removeVideo={this.removeVideo}
              hideDelete={true}
            />}
            {(this.state.videoCount == 0 && this.state.pictureCount == 0) &&
              <div className=" labelHeaderBar labelHeaderBarTight noMultiMediaHeader"> <label className="labelHeaderName" style={{margin : "0 auto"}}>No Multimedia</label></div>
            }
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

WebMapView.propTypes = {
  searchRequest: PropTypes.func,
  resultSet : PropTypes.object,
  showProgressBar : PropTypes.func,
  setAlert : PropTypes.func,
  updateResultsFromAssignment : PropTypes.func,
  updateResultsFromTaskUnassign: PropTypes.func,
  multimediaAssets : PropTypes.array,
  detailsSurveyLon : PropTypes.number,
  detailsSurveyLat : PropTypes.number,
  expandFormDataMarker: PropTypes.func,
  setCreatingNewTask : PropTypes.func,
  creatingNewTask : PropTypes.bool,
  addNewEntityToResults : PropTypes.func,
  statusTextUpdate : PropTypes.func,
  updateResultsFromDeletedPoint : PropTypes.func,
  CompanyArcGisLayers : PropTypes.array,
  CompanyKMLSHAPELayers : PropTypes.array,
  ARCGISClientSecret : PropTypes.string,
  ARCGISClientId : PropTypes.string,
  updateFromArchive : PropTypes.func,

};

export default WebMapView;