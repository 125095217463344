/* eslint react/prop-types: 0 */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const RootCauses = (props) => {
  const [graphData, setGraphData] = React.useState([{name: "No Data", y: 1}]);
  const [chart, setChart] = React.useState(null);

  const chartCallback = c => setChart(c);

  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  React.useEffect(() => {
    // console.group("RootCausesWidget");
    if(props.data){
      // console.log("Mounted Data : ", props.data);
      // console.log("Root Cause Data : ", props.data.filter(item => item.RootCause || item.RootCauseList));
      let counts = {};
      let finalData = [];
      props.data.forEach(incident => {
        if(incident.RootCause != null && incident.RootCause != "" )counts[incident.RootCause] ? counts[incident.RootCause] += 1 : counts[incident.RootCause] = 1;
        else counts["No Root Cause"] ? counts["No Root Cause"] += 1 : counts["No Root Cause"] = 1;
      });
      Object.keys(counts).forEach(key => {
        finalData.push({ name: key, y: counts[key] }) 
      });
      // console.log("Final Data : ", finalData)
      setGraphData(finalData.length > 0 ? finalData : [{ name: "No Data", y: 1 }]);
    }
    // console.groupEnd("RootCausesWidget");
  }, [props.data])

  return (
    <HighchartsReact 
      containerProps={{ className: "w-100 h-100 border border-dark" }} 
      highcharts={Highcharts} 
      allowChartUpdate={true} 
      callback={chartCallback}
      constructorType="chart"
      options={{
        chart: { type: "pie", backgroundColor: "#E9E9EF" },
        title: { text: "Root Cause" },
        plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              point: {
                events:{
                  click: function(event){
                    props.filterCallback({ SingleRootCause: event.point.name, FacilityNearMissFalsey: true }, `Showing Investigations with a Root Cause: ${event.point.name}`);
                  }
                }
              },
              // dataLabels: {
              //   formatter: function(){
              //     return RootCausesDisplayValues[this.point.name]
              //   }
              // },
              // tooltip: {
              //   headerFormat: "",
              //   pointFormatter: function(){
              //     return `${RootCausesDisplayValues[this.name]} : ${this.y}`;
              //   }
              // }
          }
        },
        series: graphData ? [
          {
            name: "Root Causes",
            type: "pie",
            data: graphData
          }
        ] : [],
        credits: { enabled: false },
        legend: { enabled: false }
      }}
    />
  );
}

export default RootCauses;