/* eslint react/prop-types: 0 */
import React from "react";
import ReactGridLayout, { WidthProvider } from 'react-grid-layout';
import CountBox from './CountBox';
import PropTypes from "prop-types";
import BarGraphBox from './BarGraphBox';
import SurveysPerDay from './SurveysPerDay';
import StackedSurveysByPerson from './StackedSurveysByPerson';
import AuditByPerson from './AuditByPerson';
import auth from '../../common/Auth';
import { BarLoader } from "react-spinners";
import "react-grid-layout/css/styles.css";
const GridLayout = WidthProvider(ReactGridLayout);

const SurveyType = {
  0 : "Survey",
  1 : "Incident",
  10: "External",
  11: "LPDamage",
  12: "Logo",
  13: "GPSSurvey",
  14: "Inaccessible",
  15: "Aoc",
  16: "Leak Survey",
  17: "Assignment Area",
  18: "Initial Audit",
  19: "100Audit-",
  20: "Documentation Audit",
  21: "Field Audit",
  22: "Meter Inspection - Outside",
  23: "Custom GIS",
  24: "Meter Inspection - Inside",
  25: "Inaccessible, Open, No Visit",
  26: "Inaccessible, Open, One Visit",
  27: "Inaccessible, Open, Two Visit",
  28: "Inaccessible, Open, Three+ Visit",
  93: "Custom Form",
  Survey : 0,
  Incident : 1,
  External : 10,
  LPDamage : 11,
  Logo : 12,
  GPSSurvey : 13,
  LeakSurveyInaccessible : 14,
  LeakSurveyAoc : 15,
  LeakSurveyLeak : 16,
  LeakSurveyAssignmentArea : 17,
  LeakSurveyInitialAudit : 18,
  LeakSurvey100Audit : 19,
  LeakSurveyDocumentationAudit : 20,
  LeakSurveyFieldAudit : 21,
  LeakSurveyMeterInspection : 22,
  CustomPipeline : 23,
  LeakSurveyMeterInspectionInside : 24,
  LeakSurveyInaccessibleNoVisit : 25,
  LeakSurveyInaccessibleOneVisit : 26,
  LeakSurveyInaccessibleTwoVisit : 27,
  LeakSurveyInaccessibleThreePlusVisit : 28,
  CustomForm : 93
}

const defaultLayout = [
  { i: "count-pass-14", x: 0, y: 0, w: 4, h: 1, static: false, minW: 6, maxW: 6, minH: 1, maxH: 2},
  { i: "count-pass-15", x: 0, y: 1, w: 4, h: 1, static: false, minW: 6, maxW: 6, minH: 1, maxH: 2},
  { i: "count-pass-16", x: 0, y: 2, w: 4, h: 1, static: false, minW: 6, maxW: 6, minH: 1, maxH: 2},
  { i: "count-pass-22", x: 0, y: 3, w: 4, h: 1, static: false, minW: 6, maxW: 6, minH: 1, maxH: 2},
  { i: "count-pass-23", x: 0, y: 4, w: 4, h: 1, static: false, minW: 6, maxW: 6, minH: 1, maxH: 2},
  { i: "count-pass-24", x: 0, y: 5, w: 4, h: 1, static: false, minW: 6, maxW: 6, minH: 1, maxH: 2},
  { i: "count-pass-25", x: 5, y: 0, w: 4, h: 1, static: false, minW: 6, maxW: 6, minH: 1, maxH: 2},
  { i: "count-pass-26", x: 5, y: 1, w: 4, h: 1, static: false, minW: 6, maxW: 6, minH: 1, maxH: 2},
  { i: "count-pass-27", x: 5, y: 2, w: 4, h: 1, static: false, minW: 6, maxW: 6, minH: 1, maxH: 2},
  { i: "count-pass-28", x: 5, y: 3, w: 4, h: 1, static: false, minW: 6, maxW: 6, minH: 1, maxH: 2},
  // { i: "countsByPerson", x: 0, y: 0, w: 6, h: 4, static: false, minW: 5, maxW: 12, minH: 4, maxH: 6},
  { i: "footageCount", x: 0, y: 8, w: 6, h: 4, static: false, minW: 5, maxW: 12, minH: 4, maxH: 6},
  { i: "surveysPerDay", x: 6, y: 4, w: 6, h: 4, static: false, minW: 5, maxW: 12, minH: 4, maxH: 6},
  { i: "surveysPerPerson", x: 6, y: 4, w: 6, h: 4, static: false, minW: 5, maxW: 12, minH: 4, maxH: 6},
  { i: "auditCounts", x: 6, y: 8, w: 6, h: 4, static: false, minW: 5, maxW: 12, minH: 4, maxH: 6},
  // { i: "contigentInaccessibleSurveys1", x: 4, y: 0, w: 4, h: 4, static: false, minW: 2, maxW: 6, minH: 3, maxH: 6},
  // { i: "contigentInaccessibleSurveys2", x: 8, y: 0, w: 4, h: 4, static: false, minW: 2, maxW: 6, minH: 3, maxH: 6},
  // { i: "contigentInaccessibleSurveys3", x: 8, y: 0, w: 4, h: 4, static: false, minW: 2, maxW: 6, minH: 3, maxH: 6},
  { i: "inaccessibleGauge", x: 8, y: 0, w: 4, h: 4, static: false, minW: 2, maxW: 6, minH: 3, maxH: 6}
];
// Example mobile layout
// const defaultMobileLayout = [
//   {w: 12, h: 2, x: 0, y: 0, i: "count-pass-14", static: true},
//   {w: 12, h: 2, x: 0, y: 2, i: "count-pass-15", static: true},
//   {w: 12, h: 2, x: 0, y: 4, i: "count-pass-16", static: true},
//   {w: 12, h: 2, x: 0, y: 6, i: "count-pass-22", static: true},
//   {w: 12, h: 4, x: 0, y: 8, i: "surveysPerDay", static: true},
//   {w: 12, h: 4, x: 0, y: 28, i: "surveysPerPerson", static: true},
//   {w: 12, h: 4, x: 0, y: 24, i: "footageCount", static: true},
//   {w: 12, h: 4, x: 0, y: 12, i: "auditCounts", static: true},
//   {w: 12, h: 4, x: 0, y: 20, i: "contigentInaccessibleSurveys1", static: true},
//   {w: 12, h: 4, x: 0, y: 16, i: "contigentInaccessibleSurveys2", static: true}
// ]

let renderCount = 0;

class DraggableArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      layout: defaultLayout,
      refreshKeys: {
        // countsByPerson: 0,
        footageCount: 0,
        surveysPerDay: 0,
        surveysPerPerson: 0,
        auditCounts: 0,
        // contigentInaccessibleSurveys1: 0,
        // contigentInaccessibleSurveys2: 0,
        // contigentInaccessibleSurveys3: 0,
        inaccessibleGauge: 0
      },
      loadingLayout: false,
      originalLayouts: null,
    }
  }

  componentDidMount(){
    this.setState({ loadingLayout: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let requestBody = {
      UserId: auth.userId(),
      DashboardLayoutUpdate: false,
      User: {
        DashboardLayout: null,
        UserId: auth.userId()
      }
    };
    fetch(process.env.API_URL+"/api/account/dashboardlayout", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body: JSON.stringify(requestBody)
    })
      .then(res => res.json())
      .then(response => {
        let r = JSON.parse(response);
        if(r.DashboardLayout){
          let layouts = JSON.parse(r.DashboardLayout);
          this.setState({ originalLayouts: layouts });
          if(layouts.leaksurvey){
            let finalLayout = defaultLayout.map(item => {
              let userItem = layouts.leaksurvey.find(userItem => userItem.i === item.i);
              if(userItem){
                return { ...userItem, x: userItem.x, y: userItem.y, w: userItem.w, h: userItem.h };
              }else{ return item }
            });
            // console.log("Leak Survey Layout : ", finalLayout);
            this.setState({ layout: finalLayout });
          }else{
            this.setState({ layout: defaultLayout });
          }
        }else{
          this.setState({ layout: defaultLayout });
        }
      })
      .catch(error => console.log('error', error))
      .finally(() => {this.setState({ loadingLayout: false }); this.forceUpdate()});
  }
  //eslint-disable-next-line
  handleResizeChange = (layout, oldItem, newItem, placeholder, e, element) => {
    e.preventDefault();
    // Changes refresh keys of widgets based on their id
    let item = newItem.i;
    this.setState({ 
      refreshKeys: {...this.state.refreshKeys, [item] : this.state.refreshKeys[item] + 1} 
    });
    this.saveLayout(layout);
  }
  //eslint-disable-next-line
  componentDidUpdate(prevProps, prevState){
    let strPrev = JSON.stringify(prevProps.data);
    let strCur = JSON.stringify(this.props.data);
    if(strPrev !== strCur){
      let refreshKeys = this.state.refreshKeys;
      refreshKeys.surveysPerPerson += 1;
      this.setState({refreshKeys});
      this.forceUpdate();
    }
  }

  saveLayout = (newLayout) => {
    this.setState({ layout: newLayout });
    // console.log("Save Layout : ", newLayout);
    if(renderCount > 1) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      let requestBody = {
        UserId: auth.userId(),
        DashboardLayoutUpdate: true,
        User: {
          DashboardLayout: JSON.stringify({
            ...this.state.originalLayouts,
            leaksurvey: newLayout
          }),
          UserId: auth.userId()
        }
      };
      fetch(process.env.API_URL+"/api/account/dashboardlayout", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin" : "*",
          "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization : "Bearer " + auth.token()
        },
        body: JSON.stringify(requestBody)
      })
        .then(res => res.json())
        .then(response => {
          // successful call, do something?
          //eslint-disable-next-line
          let r = JSON.parse(response);
          // console.log("Dashboards : ", JSON.parse(r.DashboardLayout))
        })
        .catch(error => console.log('error', error));
    }
    renderCount += 1;
  }

  render() {
    const { layout, refreshKeys, loadingLayout } = this.state;
    if(loadingLayout){
      return(
        <div style={this.props.tableOpen ? {height: "30%"} : {height: "calc(100% - 45px)"}}>
          <div className="row mapspin">
            <div className="spinnerDiv">
              <div className="authSpinnerDiv">
                <div className="authSpinnerDivMessage">Loading Layout...</div>
                <div className="barLoaderPortal">
                  <BarLoader sizeUnit={"px"} size={150} color={"#095399"}loading={true}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <GridLayout
        cols={12}
        style={this.props.tableOpen ? {height: "30%"} : {height: "calc(100% - 45px)"}}
        className="dashboard-grid w-100 d-flex justify-content-between"
        layout={layout}
        onLayoutChange={this.saveLayout}
        // rowHeight={(window.innerHeight - 135)/12} // 135
        rowHeight={65}
        margin={[10, 10]}
        autoSize={false}
        onResizeStop={this.handleResizeChange}
      >
        { // Rendering count boxes
          Object.keys(this.props.countsByType).map(key => (
            <div key={`count-pass-${key}`} className="border border-dark">
              <CountBox surveyTypes={SurveyType} filterCallback={this.props.filterCallback} id={key} label={SurveyType[key]} count={this.props.countsByType[key]} />
            </div>
          ))
        }
        { // Surveys per day per person graph
          <div key="surveysPerDay"><SurveysPerDay filterCallback={this.props.filterCallback} refreshKey={refreshKeys.surveysPerDay} graphTitle="Surveys by Date\Person" categories={this.props.dateRange} data={this.props.data}/></div>
        }
        { // Stacked surveys by person, by survey type graph
          <div key="surveysPerPerson"><StackedSurveysByPerson filterCallback={this.props.filterCallback} refreshKey={refreshKeys.surveysPerPerson} graphTitle="Surveys Per Person" data={this.props.data} /></div>
        }
        { // Footage counts graph
          <div key="footageCount"><BarGraphBox filterCallback={this.props.filterCallback} refreshKey={refreshKeys.footageCount} data={this.props.footageCounts} graphTitle="Footage Counts" tooltipUnit="Distance" yAxisLabel="Feet" /></div>
        }
        { // Audits Count Graph
          <div key="auditCounts"><AuditByPerson refreshKey={refreshKeys.auditCounts} auditData={this.props.auditData} /></div>
        }
        {/* {
          <div key="inaccessibleGauge">
            <InaccessibleGauge filterCallback={this.props.filterCallback} refreshKey={refreshKeys.inaccessibleGauge} data={this.props.data} status="Open" />
          </div>
        } */}
      </GridLayout>
    );
  }
}

DraggableArea.propTypes = {
  countsByType : PropTypes.object,
  data : PropTypes.array,
  dateRange : PropTypes.array,
  surveysByPerson : PropTypes.array,
  footageCounts : PropTypes.array
}

export default DraggableArea;