import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Button } from 'react-bootstrap';
import { BarLoader } from 'react-spinners';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import auth from '../common/Auth';
import AddBoxSharpIcon from '@material-ui/icons/LibraryAddTwoTone';
import SaveSharpIcon from '@material-ui/icons/SaveTwoTone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types'

class ProjectManagementTab extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      companyProjects : [{
        ProjectLabel : "All",
        ProjectId : -1,
        DeletedDate : new Date()
      }],
      editedFormProjects : [],
      updateStatusText : "",
      loadingText : "",
      indexToDelete : -1,
      showWarning : false,
      alertSeverity : "success"
    }
    this.handleCloseWarning = this.handleCloseWarning.bind(this);
  }

  handleCloseWarning(){
    this.setState({showWarning : false});
  }

  addProject(){
    if(auth.isSuperViewer()){
      return;
    }
    let companyProjects = this.state.companyProjects;
    companyProjects.push({
      ProjectLabel : "",
      ProjectId : -1,
      Unsaved : true
    });
    this.setState({companyProjects})
  }

  updateProjects(){
    if(auth.isSuperViewer()){
      return;
    }
    let companyId = auth.companyId();
    let realCompany = localStorage.getItem("actualCompanyId");
    if(companyId != realCompany){
      return;
    }
    let update = true;
    for(let i=0; i<this.state.companyProjects.length; i++){
      if(this.state.companyProjects[i].ProjectLabel.length == 0 && this.state.companyProjects[i].DeletedDate == null){
        update = false;
      }
    }
    if(update){
      this.setState({loadingText : "UPDATING Projects...", loadingProjects : true});
      let spliceIndex = 0;
      let Projects = JSON.parse(JSON.stringify(this.state.companyProjects));
      if(Projects.find(x => x.ProjectLabel == "All")){
        spliceIndex = Projects.indexOf(x => x.ProjectLabel == "All");
        if(spliceIndex == -1){
          for(let i = 0; i < Projects.length; i++){
            if(Projects[i].ProjectLabel == "All"){
              spliceIndex = i;
            }
          }
        }
        Projects.splice(spliceIndex,1);
      }
      let updatedFormProjects = [];
      this.state.editedFormProjects.forEach(project =>{
        if(this.props.formProjects.findIndex(x => x.ProjectId == project.ProjectId && x.FormId == project.FormId) == -1){
          //Need to add so that it will be deleted.
          updatedFormProjects.push({FormId : project.FormId, ProjectId: project.ProjectId, Add : false});
        }
        else{
          //Need to add so that it will be added.
          updatedFormProjects.push({FormId : project.FormId, ProjectId: project.ProjectId, Add : true});
        }
      });
      let body = {
        UserId : auth.employeeId(),
        CompanyId : auth.companyId(),
        Projects,
        FormProjects : updatedFormProjects,
        UTCOffset : - (new Date().getTimezoneOffset()/60)
      };
      fetch(process.env.API_URL + "/api/account/updateProjects",{
        method: "POST",
        headers:{
          "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization : "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          r.json().then(responseJson =>{
            let json = JSON.parse(responseJson);
            if(json.ResponseType == 0){
              let companyProjects = [{
                ProjectLabel : "All",
                ProjectId : -1,
                DeletedDate : new Date()
              }];
              companyProjects = companyProjects.concat(json.Projects);
              this.setState({companyProjects, updateStatusText : "Updated Projects.", loadingText : "", alertSeverity : "success", loadingProjects : false});
              this.props.setProjects(companyProjects);
            }
            else{
              this.setState({updateStatusText : "There was a problem updating projects. Try again.", loadingText : "", alertSeverity : "error", loadingProjects : false});
            }
          });
        }
      })
      .catch(err =>{
        this.setState({updateStatusText : "There was a problem updating projects. Try again." + err.message, loadingProjects : false, alertSeverity : "error"});
      })
    }
    else{
      this.setState({updateStatusText : "Enter in a name for the Project.", alertSeverity : "warning"});
    }
  }

  getProjects(){
    this.setState({ loadingText : "LOADING PROJECTS..." });
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getProjects",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.Projects){
              let companyProjects = [{
                ProjectLabel : "All",
                ProjectId : -1,
                DeletedDate : new Date()
              }];
              companyProjects = companyProjects.concat(json.Projects);
              this.setState({companyProjects});
              this.props.setProjects(companyProjects);
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error.message);
    })
    .finally(() =>{
      this.setState({loadingText : ""});
    });
  }

  handleClose = () =>{
    this.setState({ updateStatusText : ""});
  }

  deleteProject(index){
    if(auth.isSuperViewer()){
      return;
    }
    let companyProjects = this.state.companyProjects;
    companyProjects[index].DeletedDate = new Date();
    companyProjects[index].DeletedBy = auth.employeeId();
    companyProjects[index].DeletedDateUTCOffset = - new Date().getTimezoneOffset() / 60;
    this.setState({companyProjects});
  }

  componentDidMount(){
    this.getProjects();
  }

  render(){
    return(
      <div className="d-flex justify-content-center flex-row">
        <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText : ''})}} autoHideDuration={6000} onClose={this.handleClose}
          anchorOrigin={{vertical : 'top', horizontal : 'center'}}>
          <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
            {this.state.updateStatusText}
          </Alert>
        </Snackbar>
        <div style={{ width: "100%", marginTop : 20}}>
          <div className="labelHeaderBar labelHeaderBarTight" style={{marginTop : 0, padding:10}}>
            Project Management
            <div className="labelHeaderBarSubHeader">Create Project to allow users to select projects in specified forms</div>
          </div>
          {this.state.loadingText && <div className="row d-flex align-items-center justify-content-center">
            <div style={{position : 'absolute'}} className="d-flex align-items-center justify-content-center authSpinnerDiv">
              <div className="authSpinnerDivMessage">{this.state.loadingText}</div>
              <div className="barLoaderPortal">
                <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={true}/>
              </div>
            </div>
          </div>}
          <div className="licenseEditDivContent">
            {this.state.companyProjects && <div>
              {this.state.companyProjects.map((project, index) => (!project.DeletedDate && 
                <div key={index} style={{display : "felx", flexDirection : "column"}}>
                  <div className="row notificationRowDiv">
                    <label htmlFor={"projectName" + index} className="accountHeader notificationLabel notificationRowControl">Project Name:</label>
                    {project.Unsaved && <input id={"projectName" + index} value={project.ProjectLabel} className="form-control notificationRowControl large"
                    onChange={e => {let companyProjects = this.state.companyProjects; companyProjects[index].ProjectLabel = e.target.value; this.setState({companyProjects})}}/>}
                    <span className="triggerFormLabel">{!project.Unsaved &&project.ProjectLabel}</span>

                    <div id={"subscriptionFormList" + index} className="dropdown-check-list" >
                      <span className="anchor" onClick={() =>{
                        let checkList = document.getElementById("subscriptionFormList"+index);
                        if(checkList.classList.contains("visible"))checkList.classList.remove("visible");
                        else checkList.classList.add("visible");
                      }}>Select Forms</span>
                        <ul className="items">
                          <li><input checked={(this.props.formProjects && this.props.FormCollection) && (this.props.formProjects.filter(x => x.ProjectId == project.ProjectId).length >= this.props.FormCollection.length)} onChange={()=>{
                            //All is checked need to remove all.
                            if(this.props.formProjects.filter(x => x.ProjectId == project.ProjectId).length >= this.props.FormCollection.length){
                              this.props.FormCollection.forEach(form =>{
                                let editedFormProjects = this.state.editedFormProjects;
                                let index = editedFormProjects.findIndex(x => x.ProjectId == project.ProjectId && x.FormId == form.FormId);
                                if(index == -1){
                                  editedFormProjects.push({ProjectId : project.ProjectId, FormId : form.FormId});
                                  this.setState({editedFormProjects});
                                }
                                else{
                                  editedFormProjects.splice(index,1);
                                  this.setState({editedFormProjects});
                                }
                                this.props.editFormProjects(project.ProjectId, form.FormId);
                              });
                            }
                            //Need to add all;
                            else{
                              this.props.FormCollection.forEach(form =>{
                                let editedFormProjects = this.state.editedFormProjects;
                                if(this.props.formProjects.findIndex(x => x.ProjectId == project.ProjectId && form.FormId == x.FormId) == -1){
                                  let index = editedFormProjects.findIndex(x => x.ProjectId == project.ProjectId && x.FormId == form.FormId);
                                  if(index == -1){
                                    editedFormProjects.push({ProjectId : project.ProjectId, FormId : form.FormId});
                                    this.setState({editedFormProjects});
                                  }
                                  else{
                                    editedFormProjects.splice(index,1);
                                    this.setState({editedFormProjects});
                                  }
                                  this.props.editFormProjects(project.ProjectId, form.FormId);
                                  }
                              });
                            }
                          }} type="checkbox"/><div style={{marginLeft:5, display: 'inline-block'}} onClick={() =>{
                            //All is checked need to remove all.
                            if(this.props.formProjects.filter(x => x.ProjectId == project.ProjectId).length >= this.props.FormCollection.length){
                              this.props.FormCollection.forEach(form =>{
                                let editedFormProjects = this.state.editedFormProjects;
                                let index = editedFormProjects.findIndex(x => x.ProjectId == project.ProjectId && x.FormId == form.FormId);
                                if(index == -1){
                                  editedFormProjects.push({ProjectId : project.ProjectId, FormId : form.FormId});
                                  this.setState({editedFormProjects});
                                }
                                else{
                                  editedFormProjects.splice(index,1);
                                  this.setState({editedFormProjects});
                                }
                                this.props.editFormProjects(project.ProjectId, form.FormId);
                              });
                            }
                            //Need to add all;
                            else{
                              this.props.FormCollection.forEach(form =>{
                                let editedFormProjects = this.state.editedFormProjects;
                                if(this.props.formProjects.findIndex(x => x.ProjectId == project.ProjectId && form.FormId == x.FormId) == -1){
                                  let index = editedFormProjects.findIndex(x => x.ProjectId == project.ProjectId && x.FormId == form.FormId);
                                  if(index == -1){
                                    editedFormProjects.push({ProjectId : project.ProjectId, FormId : form.FormId});
                                    this.setState({editedFormProjects});
                                  }
                                  else{
                                    editedFormProjects.splice(index,1);
                                    this.setState({editedFormProjects});
                                  }
                                  this.props.editFormProjects(project.ProjectId, form.FormId);
                                  }
                              });
                            }
                          }}>All</div></li>
                          {this.props.FormCollection && this.props.FormCollection.length > 0 && this.props.FormCollection.map((form, i) =>(
                            <li key={i}><input checked={this.props.formProjects && (this.props.formProjects.findIndex(x => x.ProjectId == project.ProjectId && x.FormId == form.FormId) != -1)} onChange={() =>{
                              let editedFormProjects = this.state.editedFormProjects;
                              let index = editedFormProjects.findIndex(x => x.ProjectId == project.ProjectId && x.FormId == form.FormId);
                              if(index == -1){
                                editedFormProjects.push({ProjectId : project.ProjectId, FormId : form.FormId});
                                this.setState({editedFormProjects});
                              }
                              else{
                                editedFormProjects.splice(index,1);
                                this.setState({editedFormProjects});
                              }
                              this.props.editFormProjects(project.ProjectId, form.FormId);
                            }} type="checkbox"/><div style={{marginLeft: 5, display: "inline-block"}} onClick={() =>{
                              let editedFormProjects = this.state.editedFormProjects;
                              let index = editedFormProjects.findIndex(x => x.ProjectId == project.ProjectId && x.FormId == form.FormId)
                              if(index == -1){
                                editedFormProjects.push({ProjectId : project.ProjectId, FormId : form.FormId});
                                this.setState({editedFormProjects});
                              }
                              else{
                                editedFormProjects.splice(index,1);
                                this.setState({editedFormProjects});
                              }
                              this.props.editFormProjects(project.ProjectId, form.FormId);
                            }}>{form.Name}</div></li>
                          ))}
                        </ul>
                    </div>
                    <Button onClick={() =>{if(project.ProjectId != -1){this.setState({indexToDelete: index, showWarning : true})} else{this.deleteProject(index);}}} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                      <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                    </Button>
                  </div>
                </div>))}  
            </div>}
            <div className="horizontalLine"></div>
            <div className="row" style={{ marginTop : 10}}>
              <div className="justify-content-left">
                { ! this.state.loadingProjects && <div>
                  <Button  onClick={() =>{this.addProject();}} className="buttonAction buttonAccount">
                    <AddBoxSharpIcon className="buttonIcon"/>
                    <label style={{margin: 0, cursor : 'pointer'}} className="buttonText">New Project</label>
                  </Button>
                  <Button onClick={()=>{this.updateProjects();}} className="buttonAction buttonAccount">
                    <SaveSharpIcon className="buttonIcon"/>
                    <label style={{ margin: 0, cursor: 'pointer'}} className="buttonText">Save</label>
                  </Button>
                </div>}
                { this.state.loadingProjects && 
                <div className="row d-flex align-items-center justify-content-center" style={{width: 500, marginTop:10}}>
                  <div className=" d-flex align-items-center justify-content-center authSpinnerDiv" style={{width:'100%'}}>
                    <span className="labelLoadingBar">UPDATING PROJECTS...</span>
                    <div className="barLoaderPortal">
                      <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={true}/>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
        <Dialog open={this.state.showWarning} onClose={() =>{this.handleCloseWarning()}}>
          <DialogTitle>{"Do you want to delete this Project"}</DialogTitle>
          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={() =>this.handleCloseWarning()}>
              <label className="buttonText small" style={{margin: 0, cursor : "pointer"}}>Cancel</label>
            </Button>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={() =>{this.handleCloseWarning(); this.deleteProject(this.state.indexToDelete);}}>
              <label className="buttonText small" style={{margin: 0, cursor : 'pointer'}}>Accept</label>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ProjectManagementTab.propTypes = {
  setProjects : PropTypes.func,
  FormCollection : PropTypes.array,
  formProjects : PropTypes.array,
  editFormProjects : PropTypes.func
}

export default ProjectManagementTab;