import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import PropTypes from "prop-types";

highchartsMore(Highcharts);

const CountByTechDetailed = (props) =>{
  const [chart, setChart] = React.useState(null);
  const [sComplete, setData] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  const [lookupTable, setLookupTable] = React.useState([]);

  const chartCallback = c => setChart(c);

  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  React.useEffect(() => {
    console.time('CountByDateDetailed');

    let sComplete = [];
    let lookupTable = [];
    
    if(props.data){
      
      let range = getNameRange(props.data);
      props.data.forEach(row => {
        let formId = -1;
        let nameIndex = -1;
        let displayName = "";

        try{
          if (props.formData != undefined) formId = props.formData.filter(function(x){ return x.FormId == row.FormId})[0].Name;
        }
        catch(eat){
          //eat
        }

        var filtervalue = props.useModified ? row.ModifiedBy : row.CreatedBy; 
        displayName = props.useModified ? row.ModifiedByDisplayName : row.CreatedByDisplayName;
        lookupTable[displayName] = filtervalue;
        
        if (formId != undefined){
            
          nameIndex = range.findIndex(i => i === displayName);
          let formIndex = sComplete.findIndex(i => i.name === formId);
          if (formIndex >= 0)
          {
            sComplete[formIndex].data[nameIndex] = sComplete[formIndex].data[nameIndex] + 1;
          }
          else
          {
            let dataArray = new Array(range.length).fill(0);
            
            sComplete.push({name: formId, data: dataArray, filtervalue: filtervalue});
            formIndex = sComplete.findIndex(i => i.name === formId);
            sComplete[formIndex].data[nameIndex] = 1;
          }
        }

      });

      setData(sComplete);
      setLookupTable(lookupTable);
    }
    console.timeEnd('CountByDateDetailed');
  }, [props.data])

  const getNameRange = (data) => {

    let namelist = [];
    let displayName = "";

    data.forEach(row =>{
      if (row.FormData != undefined) displayName = props.useModified ? row.FormData.ModifiedByDisplayName : row.FormData.CreatedByDisplayName; else displayName = props.useModified ? row.ModifiedByDisplayName : row.CreatedByDisplayName;
      if (!namelist.includes(displayName)) namelist.push(displayName);
    });

    namelist.sort();
    setCategories(namelist);
    return namelist;
  }

  return(
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark"}}
      highcharts={Highcharts}
      allowChartUpdate={true}
      callback={chartCallback}
      constructorType="chart"
      options={{
        chart : { type: "column", backgroundColor : "#E1E9EF"},
        title: { text: "Forms By Tech"},
        plotOptions:{
          series: {
            cursor : "pointer",
            stacking: "normal",
            marker: {
              enabled: false,
              symbol : "circle",
              radius: 4, 
              states :{
                hover: {
                  enabled: true
                }
              }
            },
            point: {
              events: {
                click: function(event){
                  props.filterCallback({ 
                    filterType: 'ByTechLookup',
                    filterValue: event.point.category,
                    lookupTable: lookupTable
                  },
                  `${event.point.category}`)
                }
              }
            }
          }
        },
        xAxis: { categories: categories || []},
        yAxis: { title: { text : "Count by Tech"}},
        tooltip: { shared: true },
        series: sComplete,
        credits: {enabled: false},
        legend: {enabled: true}
      }}
    />
  );
}

CountByTechDetailed.propTypes = {
  refreshKey : PropTypes.number,
  dateFrom : PropTypes.string,
  dateTo : PropTypes.string,
  data : PropTypes.array,
  formData : PropTypes.array,
  filterCallback : PropTypes.func,
  formCollection : PropTypes.array,
  useModified: PropTypes.bool
}

export default CountByTechDetailed;