import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./home/HomePage";
import AboutPage from "./about/AboutPage";
import Header from "./common/Header";
import PageNotFound from "./PageNotFound";
import ServicesPage from "./services/ServicesPage";
import LoginPage from "./login/LoginPage";
import DashboardPage from "./dashboard/DashboardPage";
import UsersPage from "./users/UsersPage"; 
import AccountPage from "./account/AccountPage";
import LogoutPage from "./login/LogoutPage";
import ReportingPage from "./portal/ReportingPage";
import SurveyPage from "./portal/SurveyPage";
import InvestigationPage from "./portal/InvestigationPage";
import FormDataPage from "./portal/FormDataPage";
import MapPage from "./portal/MapPageGPSSurvey";
import MapPageLeakSurvey from "./portal/MapPageLeakSurvey";
import MapPageSurveyInvestigation from './portal/MapPageSurveyInvestigation';
import SignUpPage from "./signup/SignUpPage";
import LeakSurveyPage from './portal/LeakSurveyPage';
import SiteSafePage from './portal/SiteSafePage';
import Register from "./register/Register";
import Privacy from "./portal/Privacy";
import Footer from "./common/Footer";
import Contact from "./contact/Contact";
import UserGroupPage from './portal/UserGroupsPage';
import PasswordResetPage from "./login/PasswordResetPage";
import EditPassword from './Profile/EditPassword';
import SupportPage from './SupportPage';
import UpdatesPage from './Updates';
import DataDownloadPage from "./portal/DataDownloadPage";
import { ProtectedRoute } from "./common/ProtectedRoute";
import auth from "./common/Auth";
import "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import LeftMenu from "./common/LeftMenu";
import "react-image-gallery/styles/css/image-gallery.css";
import "./app.css";
import PageNotLicensed from "./PageNotLicensed";
import PageRoleUnauthorized from "./PageRoleUnauthorized";
import sizes from "../Global/sizes"
import PricingPage from "./home/PricingPage";
import ProductsPage from "./home/ProductsPage";
import LearningCenterPage from "./home/LearningCenterPage";
import ArcGisAuthPage from "./arcgis/ArcGisAuthPage";
import CacheBuster from './CacheBuster';
import OrgUtility from '../Utility/Utility'

function swap() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}

function onHomePage(){
  return window.location.pathname == "/";
}

function shouldDisplaySideBar(){
  let path = window.location.pathname;
  if (path == "/" || path =="/privacy" || path =="/pricing" || path =="/products" || path == "/learningcenter" || path == "/login" || path == "/register" || path == "/arcgisauth")
  {
    return false;
  }
  
  return true;
}

function checkAuth() {
  let retVal = false;
  if (typeof auth.isAuthenticated() === "string") {
    if (localStorage.getItem("access_token") != null) {
      let today = new Date();
      if (today < Date.parse(localStorage.getItem("expires"))) {
        retVal = true;
      }
    }
  } else {
    retVal = auth.isAuthenticated();
  }
  return retVal;
}

function checkManager(){
  let retVal = false;
  let _ManagerRoleId = 5;
  if (localStorage.getItem("roles") != null) {
    let arr = localStorage.getItem("roles").split(",");
    arr.forEach(element => {
      if (element == _ManagerRoleId) {
        retVal = true;
      }
    });
  }
  return retVal;
}

function checkViewer(){
  let retVal = false;
  let _ViewerRoleId = 9;
  if (localStorage.getItem("roles") != null) {
    let arr = localStorage.getItem("roles").split(",");
    arr.forEach(element => {
      if (element == _ViewerRoleId) {
        retVal = true;
      }
    });
  }
  return retVal;
}

function checkSubscription(){
  if (localStorage.getItem("subscription") != null) {
    return (localStorage.getItem("subscription") == "True");
  }

  return false;
}

function getRoles(){
  let retVal = [];
  if (localStorage.getItem("roles") != null) {
    let arr = localStorage.getItem("roles").split(",");
    return arr;
  }
  return retVal;
}

function checkAdmin() {
  let isAdmin = false;
  let _AdminRoleId = 1;
  var roles = localStorage.getItem("roles");
  if (roles != null) { roles.split(',').forEach(element => { if (element == _AdminRoleId) { isAdmin = true; } });}
  return isAdmin; 
}

function checkSuper(){
  let isSuper = false;
  let _SuperAdminRoleId = 0;
  let _SuperViewerRoleId = 11;
  var roles = localStorage.getItem("roles");
  if(roles != null) { roles.split(',').forEach(element => { if (element == _SuperAdminRoleId || element == _SuperViewerRoleId) { isSuper = true;}});}
  return isSuper;
}

function checkMultiCompany(){
  var userAccessCompanyIds = localStorage.getItem("userAccessCompanyIds");
  if(userAccessCompanyIds != null) return true;
  return false;
}

function getCompanies(){
  let companies = [];
  if (checkSuper()){
    let list = localStorage.getItem("companyList");
    if(list && list !=""){
      companies = JSON.parse(list);
    }
    else{
      auth.getCompanyList(auth.token(), auth.employeeId(),true);
    }
  } else if (checkMultiCompany()){
    let list = localStorage.getItem("companyList");
    if(list && list !=""){
      companies = JSON.parse(list);
    }
    else{
      auth.getUserAccessCompanyList(auth.token(), true);
    }
  }

  var roles = localStorage.getItem("roles");
  if (roles != null){
    let _SVRoleId = '11';
    let _SARoleId = '0';
    if ((roles.split(',').indexOf(_SVRoleId) != -1 && roles.split(',').indexOf(_SARoleId) == -1)){
      let userCompanyId = auth.companyId();
      let userCompany = companies.find(x => x.CompanyId == userCompanyId);
      if (userCompany.OrgId != 1){
        companies = companies.filter(x => x.OrgId == userCompany.OrgId);
      }
    }
  }
  return companies;
}

function hasLicense(licenseId){
    const licensesString = auth.licenses();
    if(licensesString){
      const licenseArray = licensesString.split(',');
      if(licenseArray.indexOf(licenseId) > -1)
        return true;
      return false;
    }else{
      return false;
    }
}

function launchPoint(){
  let path = window.location.pathname;
  if (path =="/mobileforce"){ return true; }
  return false;
}

function shouldDisplayFooter(){
  let path = window.location.pathname;

  if (path == "/" || 
      path =="/register" || 
      path =="/login" || 
      path == "/siteright" || 
      path == "/mobileforce" || 
      path == "/contact" ||
      path == "/pricing"||
      path == "/products"||
      path == "/learningcenter")
  {
    return true;
  }

  return false;
}

function App() {
  
  let mainContentstyle = { backgroundPosition: "center", backgroundColor: "#E9E9EF"}; 
  var orgUtil = new OrgUtility();
  var orgId = orgUtil.getOrgId();

  if(window.location.pathname != "/arcgisauth"){
    if (orgId == 4) 
      mainContentstyle =  {backgroundImage: "url(/src/landing_org4.jpg)", backgroundPosition: "center"}; 
    else
      mainContentstyle =  {backgroundImage: "url(/src/bak_login.png)", backgroundPosition: "center"}; 
  }

  let forceUpdate = swap();

  // STYLE CSS by ORG
  new OrgUtility().updateOrgCSS();

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload}) =>{
        if(loading){ return null;}
        if(!loading && !isLatestVersion){
          refreshCacheAndReload();
        }
        return(
          <div className="container-fluid">
          <Header isAuth={checkAuth()} onLaunchPoint = {launchPoint()} userName={auth.userName()} isMultiCompany={checkMultiCompany()} isSuper={checkSuper()} companies = {getCompanies()}/>
          <div id="extraSpace" style={{ minHeight: sizes.homePageHeaderHeight, display: 'none' }} />
          <div id="contentMain" className="contentMain" style={mainContentstyle} >
            <div id="pageContainer" className="row p-0 m-0 "> 
            { shouldDisplaySideBar() &&
              <div id = "sideBarContainer" className="col-2 p-0 m-0"> 
                <LeftMenu isAuth={checkAuth()} isViewer={checkViewer()} isAdmin={checkAdmin()} isSuperAdmin={checkSuper()} 
                  isManager={checkManager()} roles={getRoles()} isSubscribed={checkSubscription()} hasLicense = {(licenseId)=>hasLicense(licenseId)} /> 
              </div> 
            }
            
            <Switch>
              
              <Route exact path="/" render={props => <HomePage {...props} forceRefresh={forceUpdate} />} />
              <Route path="/pricing" render={props => <PricingPage {...props} forceRefresh={forceUpdate}/>} /> 
              <Route path="/products" render={props => <ProductsPage {...props} forceRefresh={forceUpdate}/>} />
              <Route path="/learningcenter" render={props => <LearningCenterPage {...props} forceRefresh={forceUpdate}/>} />
              <Route path="/about" component={AboutPage} forceRefresh={forceUpdate} />
              <Route path="/services" component={ServicesPage} forceRefresh={forceUpdate} />
              <Route path="/contact" render={props => <Contact {...props} forceRefresh={forceUpdate}/>} /> 
              <Route path="/reset" render={props => <PasswordResetPage {...props} swap={forceUpdate}/>} />
              <Route path="/register" render={props => <Register {...props} swap = {forceUpdate}/>} />
              <Route path="/privacy" render={props => <Privacy {...props} swap = {forceUpdate}/>} />
              <Route path="/login" render={props => <LoginPage {...props} swap={forceUpdate} />} />
              <Route path="/logout" render={props => <LogoutPage {...props} swap={forceUpdate} />} />
              <Route path="/signup" component={SignUpPage} />
              <Route path="/arcgisauth" component={ArcGisAuthPage} />

              { <ProtectedRoute path="/dashboard/:id?" component={(checkAdmin() || checkSuper() || checkManager() || checkViewer()) ? DashboardPage : PageRoleUnauthorized} forceRefresh={forceUpdate} /> }
              { <ProtectedRoute path="/usergroup/:id?/:mode?" component={(checkAdmin() || checkSuper() ? UserGroupPage : PageRoleUnauthorized)} forceRefresh={forceUpdate} />}
              { <ProtectedRoute path="/reporting" component={ReportingPage} forceRefresh={forceUpdate} /> }
              { <ProtectedRoute path="/survey" component={SurveyPage} forceRefresh={forceUpdate} /> }
              { <ProtectedRoute path="/investigation" component={InvestigationPage} forceRefresh={forceUpdate} /> }
              { <ProtectedRoute path="/formdata" component={FormDataPage} forceRefresh={forceUpdate} /> }
              { <ProtectedRoute path="/leaksurveyDetails" component={LeakSurveyPage} forceRefresh={forceUpdate}/>}
              { <ProtectedRoute path="/siteSafeDetails" component={SiteSafePage} forceRefresh={forceUpdate}/>}
              { <ProtectedRoute path="/download" component={DataDownloadPage} forceRefresh={forceUpdate} /> }
              { <ProtectedRoute path="/editPassword" component={EditPassword} forceRefresh={forceUpdate}/> }
              { <ProtectedRoute path="/support" component={SupportPage} forceRefresh={forceUpdate}/> }
              { <ProtectedRoute path="/updates" component={UpdatesPage} forceRefresh={forceUpdate}/> }
              
              {/* Role Protected Routes */}
              { <ProtectedRoute path="/users" component={checkAdmin() || checkSuper() ? UsersPage : PageRoleUnauthorized} forceRefresh={forceUpdate} /> }
              { <ProtectedRoute path="/configuration/" component={(checkAdmin() || checkSuper() || checkManager()) ? AccountPage : PageRoleUnauthorized} forceRefresh={forceUpdate} />}
              
              {/* License Protected Routes */}
              { <ProtectedRoute path="/map" component={auth.hasLicenseForPage('map') ? MapPage : PageNotLicensed} forceRefresh={forceUpdate}/> }
              { <ProtectedRoute path="/leaksurvey" component={auth.hasLicenseForPage('leaksurvey') ? MapPageLeakSurvey : PageNotLicensed} forceRefresh={forceUpdate}/> }
              { <ProtectedRoute path="/surveymap" component={auth.hasLicenseForPage('sitemap') ? MapPageSurveyInvestigation : PageNotLicensed} forceRefresh={forceUpdate}/> }


              <Route component={PageNotFound} />

            </Switch>

            
            </div>
            <Footer displayFooter={shouldDisplayFooter()} onLaunchPoint={launchPoint()} onMainPage = {onHomePage()}/>
          </div>
          
        
        </div>
        );
      }}
    </CacheBuster>
  );
}

export default App;