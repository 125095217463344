import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import MapTwoToneIcon from '@material-ui/icons/MapTwoTone';
import FindInPageTwoToneIcon from '@material-ui/icons/FindInPageTwoTone';
import PeopleOutlineSharpIcon from '@material-ui/icons/PeopleOutlineSharp';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import SettingsApplicationsTwoToneIcon from '@material-ui/icons/SettingsApplicationsTwoTone';

class LeftMenu extends React.Component {
  constructor(props) {
    super(props);
  }

  renderLeft() {

    if (!this.props.isAuth) {
      return (
        <div id="sideBarDiv" className="col p-0 m-0"/>
      );
    } else {
      return (
        <div id="sideBarDiv" className="col p-0 m-0 sideBarDiv" >
          {
            (this.props.isSuperAdmin || this.props.isViewer || this.props.isAdmin || this.props.isManager)&&(
            <div className="row d-flex align-items-center justify-content-center flex-column p-0 m-0 sideBarIcon">
              <IconButton className="iconButton" title="Dashboard" component={NavLink} to="/dashboard" disabled={!this.props.isSubscribed} classes={{root: 'icon-button-root'}}>
                <DashboardTwoToneIcon className="sideButton" />
                <label className="labelSideBar">Dashboard</label>
              </IconButton>
            </div>
            )
          }

          <div className="row d-flex align-items-center justify-content-center flex-column p-0 m-0 sideBarIcon">
            <IconButton className="iconButton" title="Data & Reports" component={NavLink} to="/reporting" disabled={!this.props.isSubscribed} classes={{root: 'icon-button-root'}}>
              <FindInPageTwoToneIcon className="sideButton" />
              <label className="labelSideBar">DATA & REPORTS</label>
            </IconButton>
          </div>
  
          {(this.props.isSubscribed && (this.props.hasLicense("site") || this.props.hasLicense('investigation') || this.props.hasLicense('customforms'))) && <div className="row d-flex align-items-center justify-content-center flex-column p-0 m-0 sideBarIcon">
            <IconButton className="iconButton" title="Site Survey Map" component={NavLink} to="/surveymap" disabled={!this.props.isSubscribed} classes={{root: 'icon-button-root'}}>
              <MapTwoToneIcon className="sideButton"/>
              <label className="labelSideBar">{this.props.hasLicense("ticket") ? "Ticket" : "DATA"} MAP</label>
            </IconButton>
          </div>}

          {this.props.hasLicense("gps") && <div className="row d-flex align-items-center justify-content-center flex-column p-0 m-0 sideBarIcon">
            <IconButton className="iconButton" title="GPS Survey Map" component={NavLink} to="/map" disabled={!this.props.isSubscribed} classes={{root: 'icon-button-root'}}>
              <MapTwoToneIcon className="sideButton" />
              <label className="labelSideBar">GPS DATA MAP</label>
            </IconButton>
          </div>}

          {(this.props.isSubscribed && this.props.hasLicense("leak")) && <div className="row d-flex align-items-center justify-content-center flex-column p-0 m-0 sideBarIcon">
            <IconButton className="iconButton" title="GIS DATA Map" component={NavLink} to="/leaksurvey" disabled={!this.props.isSubscribed} classes={{root: 'icon-button-root'}}>
              <MapTwoToneIcon className="sideButton" />
              <label className="labelSideBar">GIS DATA MAP</label>
            </IconButton>
          </div>}

          {(this.props.isAdmin || this.props.isSuperAdmin) &&
          <div className="row d-flex align-items-center justify-content-center flex-column p-0 m-0 sideBarIcon">
            <IconButton className="iconButton" title="User & Group Management" component={NavLink} to="/usergroup" disabled={!this.props.isSubscribed} classes={{root: 'icon-button-root'}}>
              <PeopleOutlineSharpIcon className="sideButton" />
              <label className="labelSideBar">USERS</label>
            </IconButton>
          </div>}

          {(this.props.isAdmin || this.props.isSuperAdmin || this.props.isManager) && 
          <div className="row d-flex align-items-center justify-content-center flex-column p-0 m-0 sideBarIcon">
            <IconButton className="iconButton" title="Account Configuration" component={NavLink} to="/configuration" classes={{root: 'icon-button-root'}}>
              <SettingsApplicationsTwoToneIcon className="sideButton" />
              <label className="labelSideBar">CONFIGURATION</label>
            </IconButton>
          </div>}

        </div>
      ); 
    }
  }

  render() {
    return this.renderLeft();
  }
}

LeftMenu.propTypes = {
  isAuth: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  isManager: PropTypes.bool,
  isSubscribed: PropTypes.bool,
  roles: PropTypes.array,
  hasLicense: PropTypes.func,
  isViewer : PropTypes.bool
};

export default LeftMenu;