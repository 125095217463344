import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

class PricingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.props.forceRefresh();
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

 
  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  render() {

    // REDIRECT TO PRIVACY POLICY
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params.get('p') != null) return <Redirect to="/privacy" />
    let mobileWidth = 900;

    return (
      <div className="T  p-0 m-0" style={{ backgroundColor: "transparent", minWidth: "100%", overflowX: "hidden" }}>
        <div className="pricingPageHeader">
          Put the power of documentation into the hands of any team member
        </div>
        {this.state.width >= mobileWidth ?
          <div className="pricingContainer">
            <div className="pricingWideRow">
            <div className="pricingWideColumn">
                <div className="pricingColumnInner pricingColumnTop">
                  GPS SURVEY
            </div>
                <div className="pricingColumnInner pricingWideColumnBottom">
                  <div className="pricingPriceHeader">
                    <div className="pricingPriceHeaderLabel">$50 / month</div>
                    <div className="pricingPriceHeaderSublabel">per user</div>
                  </div>
                  <div className="pricingFreeTrialButtonContainer">
                    <div className="pricingFreeTrialButton">
                      <a href="/register" className="marketAnchor">
                        <div className="pricingFreeTrialButtonText">
                          Start 30 Day Free Trial
                          </div>
                      </a>
                    </div>
                  </div>
                  <hr className="pricingHr" />
                  <div className="pricingPlanFeatureContainer">
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Precision Location Services – GNSS/RTK
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Define unique Data Dictionaries
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Customize geometry data types
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Work on multiple surveys at the same time with active switching
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Real-time Mobile Map Creator
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Symbology Editor
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Real-time map visualization and editing
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      GIS Export, KML, SHP, and CAD
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      ArcGIS Online Publishing – Export your data directly to your enterprise cloud
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Customized Reporting
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Geotagged Multimedia
                  </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricingWideColumn">
                <div className="pricingColumnInner pricingColumnTop">
                  GIS SURVEY
                </div>
                <div className="pricingColumnInner pricingWideColumnBottom">
                  <div className="pricingPriceHeader">
                    <div className="pricingPriceHeaderLabel">$65 / month</div>
                    <div className="pricingPriceHeaderSublabel">per user</div>
                  </div>
                  <div className="pricingFreeTrialButtonContainer">
                    <div className="pricingFreeTrialButton">
                      <a href="/register" className="marketAnchor">
                        <div className="pricingFreeTrialButtonText">
                          Start 30 Day Free Trial
                          </div>
                      </a>
                    </div>
                  </div>
                  <hr className="pricingHr" />
                  <div className="pricingPlanFeatureContainer">
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Optimized Route Planner
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      User Area and Task Assignment
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Digital Trails
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Workflow Management tools
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Geotagged Multimedia
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Customized Reports
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Real-time Alert Notifications
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Digital Leak Spread
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Map Sketch &amp; Notes
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Location &amp; Date
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Data Map
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Reporting &amp; Dashboards
                  </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricingWideColumn">
                <div className="pricingColumnInner pricingColumnTop">
                  CUSTOM FORMS
                </div>
                <div className="pricingColumnInner pricingWideColumnBottom">
                  <div className="pricingPriceHeader">
                    <div className="pricingPriceHeaderLabel">$50 / month</div>
                  </div>
                  <div className="pricingFreeTrialButtonContainer">
                    <div className="pricingFreeTrialButton">
                      <a href="/register" className="marketAnchor">
                        <div className="pricingFreeTrialButtonText">
                          Start 30 Day Free Trial
                          </div>
                      </a>
                    </div>
                  </div>
                  <hr className="pricingHr" />
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                    Define unique Data Dictionaries
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                    Auto-generate Forms to match existing data with Import Tools
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                    Organize with Sections and Groups
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                    Real-time notifications and alerts
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                    Add Maps with user notations
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                      Workflow Editor
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                    Geo-tagged multimedia
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                    Assign any New or Existing Forms as Tasks
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                      Location &amp; Date
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                    Visualize Data Entries with Admin Map
                  </div>
                  </div>
                </div>
              </div>

              <div className="pricingWideColumn">
                <div className="pricingColumnInner pricingColumnTop">
                  SITE SURVEY
                </div>
                <div className="pricingColumnInner pricingWideColumnBottom">
                  <div className="pricingPriceHeader">
                    <div className="pricingPriceHeaderLabel">$12 / month</div>
                    <div className="pricingPriceHeaderSublabel">per user</div>
                  </div>
                  <div className="pricingFreeTrialButtonContainer">
                    <div className="pricingFreeTrialButton">
                      <a href="/register" className="marketAnchor">
                        <div className="pricingFreeTrialButtonText">
                          Start 30 Day Free Trial
                          </div>
                      </a>
                    </div>
                  </div>
                  <hr className="pricingHr" />
                  <div className="pricingPlanFeatureContainer">
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Geotagged Multimedia
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Customized Reporting
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Data Visualization with Map tools
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Survey assignment and workflow tools
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Real-time notifications
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Dashboards
                  </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pricingWideColumn">
                <div className="pricingColumnInner pricingColumnTop">
                  INCIDENT INVESTIGATION
                </div>
                <div className="pricingColumnInner pricingWideColumnBottom">
                  <div className="pricingPriceHeader">
                    <div className="pricingPriceHeaderLabel">$22 / month</div>
                    <div className="pricingPriceHeaderSublabel">per user</div>
                  </div>
                  <div className="pricingFreeTrialButtonContainer">
                    <div className="pricingFreeTrialButton">
                      <a href="/register" className="marketAnchor">
                        <div className="pricingFreeTrialButtonText">
                          Start 30 Day Free Trial
                          </div>
                      </a>
                    </div>
                  </div>
                  <hr className="pricingHr" />
                  <div className="pricingPlanFeatureContainer">
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Camera Overlay Photo comparison tool
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Map Sketching with notation
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Geotagged Multimedia
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Customized Reporting
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      DIRT Integration
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Data Visualization with Map tools
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Survey assignment and workflow tools
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                      Real-time notifications
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="pricingContainer">
            <div className="pricingMobileRow">
              <div className="pricingMobileColumn">
                <div className="pricingColumnInner pricingColumnTop">
                  GPS SURVEY
               </div>
                <div className="pricingColumnInner pricingMobileColumnBottom">
                  <div className="pricingPriceHeader">
                    <div className="pricingPriceHeaderLabel">$50 / month</div>
                    <div className="pricingPriceHeaderSublabel">per user</div>
                  </div>
                  <div className="pricingFreeTrialButtonContainer">
                    <div className="pricingFreeTrialButton">
                      <a href="/register" className="marketAnchor">
                        <div className="pricingFreeTrialButtonText">
                          Start 30 Day Free Trial
                          </div>
                      </a>
                    </div>
                  </div>
                  <hr className="pricingHr" />
                  <div className="pricingPlanFeatureContainer">
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Precision Location Services GNSS/RTK
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Custom Data Dictionary
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Geometry Selector
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Symbology Editor
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Real-time Mobile Map Creator
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Shapefile &amp; KML Export
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Reporting
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Dashboards
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Photos &amp; Videos
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Map Sketch &amp; Notes
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Location &amp; Date
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricingMobileRow">
              <div className="pricingMobileColumn">
                <div className="pricingColumnInner pricingColumnTop">
                  GIS SURVEY
                </div>
                <div className="pricingColumnInner pricingMobileColumnBottom">
                  <div className="pricingPriceHeader">
                    <div className="pricingPriceHeaderLabel">$65 / month</div>
                    <div className="pricingPriceHeaderSublabel">per user</div>
                  </div>
                  <div className="pricingFreeTrialButtonContainer">
                    <div className="pricingFreeTrialButton">
                      <a href="/register" className="marketAnchor">
                        <div className="pricingFreeTrialButtonText">
                          Start 30 Day Free Trial
                          </div>
                      </a>
                    </div>
                  </div>
                  <hr className="pricingHr" />
                  <div className="pricingPlanFeatureContainer">
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Optimized Route Planner
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Asset Assignment
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Digital Trails
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Virtual Auditor
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Workflow Creator
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Photos &amp; Videos
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Real-time Alert Notifications
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Digital Leak Spread
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Map Sketch &amp; Notes
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Location &amp; Date
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Data Map
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Reporting &amp; Dashboards
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricingMobileRow">
              <div className="pricingMobileColumn">
                <div className="pricingColumnInner pricingColumnTop">
                  CUSTOM FORMS
               </div>
                <div className="pricingColumnInner pricingMobileColumnBottom">
                  <div className="pricingPriceHeader">
                    <div className="pricingPriceHeaderLabel">$50 / month</div>
                  </div>
                  <div className="pricingFreeTrialButtonContainer">
                    <div className="pricingFreeTrialButton">
                      <a href="/register" className="marketAnchor">
                        <div className="pricingFreeTrialButtonText">
                          Start 30 Day Free Trial
                          </div>
                      </a>
                    </div>
                  </div>
                  <hr className="pricingHr" />
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                      Form Creator
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                      Dashboard Designer
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                      Report Builder
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                      Workflow Editor
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                      Field Validator
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                      Alert Generator
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                      Photos &amp; Videos
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                      Map Sketch
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                      Location &amp; Date
                  </div>
                  </div>
                  <div className="pricingPlanFeatureRow">
                    <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                      &#10003;
                  </div>
                    <div className="pricingPlanFeatureColumn">
                      Data Map
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricingMobileRow">
              <div className="pricingMobileColumn">
                <div className="pricingColumnInner pricingColumnTop">
                  SITE SURVEY
                </div>
                <div className="pricingColumnInner pricingMobileColumnBottom">
                  <div className="pricingPriceHeader">
                    <div className="pricingPriceHeaderLabel">$12 / month</div>
                    <div className="pricingPriceHeaderSublabel">per user</div>
                  </div>
                  <div className="pricingFreeTrialButtonContainer">
                    <div className="pricingFreeTrialButton">
                      <a href="/register" className="marketAnchor">
                        <div className="pricingFreeTrialButtonText">
                          Start 30 Day Free Trial
                          </div>
                      </a>
                    </div>
                  </div>
                  <hr className="pricingHr" />
                  <div className="pricingPlanFeatureContainer">
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Photos &amp; Videos
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Map Sketch
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Location &amp; Date
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Data Map
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Reporting
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Dashboards
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricingMobileRow">
              <div className="pricingMobileColumn">
                <div className="pricingColumnInner pricingColumnTop">
                  INCIDENT INVESTIGATION
                </div>
                <div className="pricingColumnInner pricingMobileColumnBottom">
                  <div className="pricingPriceHeader">
                    <div className="pricingPriceHeaderLabel">$22 / month</div>
                    <div className="pricingPriceHeaderSublabel">per user</div>
                  </div>
                  <div className="pricingFreeTrialButtonContainer">
                    <div className="pricingFreeTrialButton">
                      <a href="/register" className="marketAnchor">
                        <div className="pricingFreeTrialButtonText">
                          Start 30 Day Free Trial
                          </div>
                      </a>
                    </div>
                  </div>
                  <hr className="pricingHr" />
                  <div className="pricingPlanFeatureContainer">
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Photos &amp; Videos
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Shadow Pics
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Map Sketch
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Location &amp; Date
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        DIRT Import
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Data Map
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Reporting
                  </div>
                    </div>
                    <div className="pricingPlanFeatureRow">
                      <div className="pricingPlanCheckMarkColumn pricingPlanCheckMark">
                        &#10003;
                  </div>
                      <div className="pricingPlanFeatureColumn">
                        Dashboards
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
PricingPage.propTypes = {
  forceRefresh: PropTypes.func,
  changeBackground: PropTypes.func
};
export default PricingPage;
