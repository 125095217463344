import React from 'react';
import PropTypes from 'prop-types';
import AddBoxSharpIcon from '@material-ui/icons/LibraryAddTwoTone'
import { Button } from 'react-bootstrap';
import auth from '../common/Auth';
import { BarLoader } from 'react-spinners';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

class PhotoUpload extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      pendingPictures : 0,
      error : false,
      loading : false,
      updateStatusText :""
    };
    this.uploadPhotos = this.uploadPhotos.bind(this);
    this.readAndUpload = this.readAndUpload.bind(this);
  }

  uploadPhotos(event){
    let files = event.target.files;
    if(files.length > 0){
      if(this.props.startLoading)this.props.startLoading();
      this.setState({loading : true});
      this.setState({pendingPictures : files.length });
      for(let i =0; i< files.length; i++){
        this.readAndUpload(files[i]);
      }
    }
    event.target.value = '';
  }

  readAndUpload(img){
    if(this.props.fromTaskMap){
      let pendingPictures = this.state.pendingPictures;
      this.props.addToGallery(img);
      pendingPictures--;
      this.setState({pendingPictures});
      if(this.state.pendingPictures == 0){
        this.setState({loading : false});
      }
      return;
    }
    let reader = new FileReader();
    reader.onloadend = () =>{
      let baseString = reader.result;
      baseString = baseString.split(",")[1];
      let date = new Date();
      let body ={
        MultimediaAsset : {
          EntityId : this.props.entityId,
          EntityTypeId : this.props.entityTypeId,
          Companyid : auth.companyId(),
          ModifiedBy : auth.employeeId(),
          CreatedBy : auth.employeeId(),
          CreatedDate : date,
          ModifiedDate : date,
          UTCOffset : date.getTimezoneOffset(),
          ModifiedDateUTCOffset : -date.getTimezoneOffset() /60,
          CreatedDateUTCOffset : -date.getTimezoneOffset() /60,
          Timestamp : date.getTime(),
          Latitude : 0,
          Longitude : 0,
          Heading : 0,
          MediaTypeId : 0
        },
        Base64Img  : baseString
      }
      fetch(process.env.API_URL + "/api/image",{
        method : "Post",
        headers : {
          "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization  : "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(r =>{
        let pendingPictures = this.state.pendingPictures;
        pendingPictures--;
        this.setState({pendingPictures});
        if(r.status == 200){
          this.props.addToGallery(img);
        }
        else{
          this.setState({error : true});
        }
        if(pendingPictures == 0){
          if(this.props.finishUploading)this.props.finishUploading(this.state.error);
          let updateStatusText = "All pictures were uploaded.";
          let alertSeverity = "success"
          if(this.state.error){
            updateStatusText = "There was a problem uploading one or more of your pictures."
            alertSeverity = "error"
          }
          this.setState({error : false, pendingPictures : 0, loading : false, updateStatusText, alertSeverity});
        }
      })
      //eslint-disable-next-line
      .catch(error =>{
        let pendingPictures = this.state.pendingPictures;
        pendingPictures--;
        this.setState({pendingPictures, error : true});
        if(pendingPictures == 0){
          if(this.props.finishUploading)this.props.finishUploading(this.state.error);
          this.setState({error : false, pendingPictures : 0, loading : false, updateStatusText : "There was a problem uploading one or more of your pictures.", alertSeverity : "error"});
        }
      });
    }
    if(img){
      reader.readAsDataURL(img);
    }
  }

  render(){
    return(
      <div>
        <Snackbar open={this.state.updateStatusText !=""} onClick={() =>{this.setState({updateStatusText : ''})}} autoHideDuration={6000} onClose={()=>{}}
          anchorOrigin={{ vertical : 'top', horizontal : 'center'}}>
            <Alert onClose={()=>{}} severity={this.state.alertSeverity} variant="filled">
              {this.state.updateStatusText}
            </Alert>
          </Snackbar>
        <div className ="labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Add Additional Photos</label></div>
        <div className="detailContentPanel">
        <Button className = "buttonAction buttonAccount">
          <AddBoxSharpIcon className="buttonIcon"/>
          <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText">Add Photos...</label>
        </Button>
        <input type="file" onChange={this.uploadPhotos} className="form-control-file uploadButton" id="uploadPicture" multiple/>
        <div style ={{position : "relative", bottom : 25, left : 165}}><BarLoader sizeUnit={"px"} size={25} color={"#095399"} loading={this.state.loading}/></div>
        </div>
      </div>
    )
  }
}

PhotoUpload.propTypes = {
  entityId  : PropTypes.number,
  entityTypeId : PropTypes.number,
  finishUploading : PropTypes.func,
  startLoading : PropTypes.func,
  addToGallery : PropTypes.func,
  fromTaskMap : PropTypes.bool
};

export default PhotoUpload;