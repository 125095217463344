import React from "react";
import PropTypes from "prop-types";
import Tab from 'react-bootstrap/Tab';
import UsersPage from '../users/UsersPage';
import auth from '../common/Auth';
import { withRouter, Link } from 'react-router-dom';
import OrgUtility from '../../Utility/Utility';

class UserGroupsPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeItem : this.props.match.params.id || "users"
    }
  }

  componentDidUpdate(){
    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }
  
  componentDidMount(){
    window.resize();
    this.props.forceRefresh();
  }

  changeActiveItem = activeItem =>{
    console.log(activeItem);
    this.setState({ activeItem });
    this.forceUpdate();
  }

  checkAuth(){
    let retVal = false;
    if (typeof auth.isAuthenticated() === "string"){
      if(localStorage.getItem("access_token") != null){
        let today = new Date();
        if(today < Date.parse(localStorage.getItem("expires"))){
          retVal = true;
        }
      }
    } else{
      retVal = auth.isAuthenticated();
    }
    return retVal;
  }

  hasLicense(licenseId){
    const licensesString = auth.licenses();
    if(licensesString){
      const licenseArray = licensesString.split(',');
      if(licenseArray.indexOf(licenseId) > -1){
        return true;
      }
    }
    return false;
  }

  checkSuperAdmin(){
    let isSuper = false;
    let _SuperAdminRoleId = 0;
    let roles = localStorage.getItem("roles");
    if(roles != null){
      roles.split(',').forEach(element =>{
        if(element == _SuperAdminRoleId){
          isSuper = true;
        }
      });
    }
    return isSuper;
  }

  render(){
    const { activeItem } = this.state;
    return(
      <div className="col-10 d-flex flex-column p-0 m-0 container container-fluid" style={{backgroundColor: "#E9E9EF"}}>
        <Tab.Container activeKey={activeItem} onSelect={()=> null} id="user-tab-con">
          <div className="pageBar p-0 d-flex">
            {
              <Link
                to="/usergroup/users"
                // style={activeItem === "users" ? {cursor: "pointer", borderBottom: "3px solid #16548E"} :  {cursor: "pointer"}}
                onClick={() => this.changeActiveItem("users")}
                className={activeItem === "users" ? "labelHeader barHeaderDiv selected pr-5" : "labelHeader barHeaderDiv pr-5" }
              >
                Users
              </Link>
            }
          </div>
          <Tab.Content>
            {
              <Tab.Pane id="usergroup-users-tab" eventKey="users" mountOnEnter unmountOnExit>
                <UsersPage {...this.props}/>
              </Tab.Pane>
            }
          </Tab.Content>
        </Tab.Container>
      </div>
    )
  }

}

UserGroupsPage.propTypes ={
  forceRefresh : PropTypes.func,
  match : PropTypes.object
};

export default withRouter(UserGroupsPage);