
const GLOBAL = {
  HEADER: {
    BREADCRUMBS: []
  },
  SEARCH: {
    criteria: "",
    fromDate: "",
    toDate: ""
  },
  DATA: {
    investigation: {},
    survey: {},
    leakSurvey: {},
  }
};
export default GLOBAL;
