/* eslint react/prop-types: 0 */
import React from "react";
import PropTypes from "prop-types";
import auth from '../common/Auth'; 
import ImageGallery from 'react-image-gallery';
import PhotoUpload from './PhotoUpload';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BarLoader } from "react-spinners";
import Popup from "reactjs-popup";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Button } from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

class LeakSurveyBody extends React.Component{
  constructor(props) {
    super(props);
    let leakSurveyOptions = localStorage.getItem("leakSurveyOptions");
    let options;
    if(leakSurveyOptions && leakSurveyOptions != ''){
      options = JSON.parse(leakSurveyOptions);
    }
    this.RegulatorModelDic = options && options.RegulatorModelList ? options.RegulatorModelList : null;
    this.state = {
      imgUrl: "",
      galleryItems : [],
      images: [],
      loading: false,
      picUrl: null,
      modalOpen: false,
      currentIndex : 0,
      updateStatusText : "",
      alertSeverity : "success",
      StateList : options && options.StateList ? options.StateList : [],
      FacilityTypeList : options && options.FacilityTypeList ? options.FacilityTypeList : [],
      ReasonList : options && options.ReasonList ? options.ReasonList : [],
      StatusList : options && options.StatusList ? options.StatusList : [],
      LeakClassList : options && options.LeakClassList ? options.LeakClassList : [],
      ManufacturerList : options && options.ManufacturerList ? options.ManufacturerList : [],
      AocList : options && options.AocList ? options.AocList : [],
      CorrosionGradeList : options && options.CorrosionGradeList ? options.CorrosionGradeList : [],
      MaterialList : options && options.MaterialList ? options.MaterialList : [],
      SurfaceTypeList : options && options.SurfaceTypeList ? options.SurfaceTypeList : [],
      RegulatorMakeList : options && options.RegulatorMakeList ? options.RegulatorMakeList : [],
      RegulatorModelList : this.setRegulatorModelOptions(props.regulatorMake),
      MeterLocationList : options && options.MeterLocationList ? options.MeterLocationList : [], 
      specialCompany : auth.companyId() == -1 || auth.companyId() == 95 //BLACKHILLS
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
    this.setRegulatorModelOptions = this.setRegulatorModelOptions.bind(this);
    this.getMultimedia = this.getMultimedia.bind(this);
    this.controller = new AbortController();
  }

  componentDidMount() {
    this.getMultimedia();
  }

  getMultimedia(){
    if (this.props.leakSurvey != undefined && this.props.leakSurvey.MultimediaAssets != null){
      this.setState({MultimediaAssets : this.props.leakSurvey.MultimediaAssets});
      this.props.leakSurvey.MultimediaAssets.forEach((element, index) =>{
        this.getPic(element.MultimediaAssetId, element.Timestamp, index);
      });
    }
    else if(this.props.leakSurvey != undefined){ 
      fetch(process.env.API_URL + "/api/entityImages/" + (this.props.upToDateLeakSurveyId ? this.props.upToDateLeakSurveyId : this.props.leakSurvey.LeakSurveyId) + "/" + this.props.leakSurvey.EntityTypeId + "/web",{
        method: "POST",
        headers: {"Content-Type" : "application/json", Authorization: "Bearer " + auth.token()}
      })
      .then(r => {
        if(r.status==200){
          r.json().then(bodyJson =>{
            if(bodyJson.length >0){
              let response = JSON.parse(bodyJson);
              this.setState({MultimediaAssets : response.MultimediaAssets});
              this.props.setPictureCount(response.MultimediaAssets.length);
              response.MultimediaAssets.forEach((element, index)=>{ this.getPic(element.MultimediaAssetId,element.Timestamp,index); });
            }
          });
        }
      });
    }
  }

  componentDidUpdate(previousProps){ 
    if( previousProps.leakSurvey && this.props.leakSurvey && (previousProps.leakSurvey.Id != this.props.leakSurvey.Id)){
      this.controller.abort();
      this.controller = new AbortController();
      this.setState({images: [],videos:[],blobs: [],pictureCount: 0,galleryItems : [],videoCount: 0},() =>{this.getMultimedia()});
    }
    window.resize(); 
  }

  handleClose(){}

  getPic(assetId, timeStamp){
    this.setState({ loading: true });
    fetch(process.env.API_URL + "/api/image/" + assetId,{
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + auth.token()},
      signal : this.controller.signal
    })
      .then(r => r.blob())
      .then(blob => {
        let images = this.state.images;
        images.push({original: URL.createObjectURL(blob),thumbnail: URL.createObjectURL(blob), assetId, timeStamp});
        images.sort((a,b) => (a.timeStamp > b.timeStamp) ? 1 : -1);
        this.setState({images});
        let tempArr = this.state.galleryItems;
        tempArr.push(blob);
        if (this.state.MultimediaAssets && this.state.galleryItems.length == this.state.MultimediaAssets.length){
          this.setState({ loading: false });
        }
      })
      .catch(error => {
        this.setState({ loading: false });
        console.log(error.message);
      });
  }

  setRegulatorModelOptions(make){
    if(!this.RegulatorModelDic || make == ""){
      return [];
    }
    else{
      if(!this.RegulatorModelDic[make]){
        return [];
      }
      return this.RegulatorModelDic[make];
    }
  }

  openModal(){
    this.setState({ modalOpen: true }, () =>{
      setTimeout(() => {
        let pic = document.getElementById("popupPic");
        if(pic.width > pic.height){
          let modal = document.getElementsByClassName("popup-content")[0];
          modal.classList.add("popup-horizontal");
        }
      }, 100);
    });
  }

  closeModal() {
    this.setState({ modalOpen: false});
  }

  clickPic(){
    this.setState({
      picUrl: this.state.images[this._imageGallery.getCurrentIndex()].original
    });
    this.openModal();
  }

  removePhoto(){
    let index = this._imageGallery.getCurrentIndex();
    let MultimediaAsset = this.state.MultimediaAssets[index];
    let date = new Date();
    MultimediaAsset.DeletedBy = auth.userId();
    MultimediaAsset.DeletedDate = date;
    MultimediaAsset.DeletedDateUTCOffset = -date.getTimezoneOffset()/60;
    MultimediaAsset.ModifiedBy = auth.userId();
    MultimediaAsset.ModifiedDate = date;
    MultimediaAsset.ModifiedDateUTCOffset = -date.getTimezoneOffset()/60;
    let body = {
      MultimediaAsset
    }
    fetch(process.env.API_URL + "/api/image/update",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(req =>{
      if(req.status == 200){
        req.json().then(responseJson =>{
          let response = JSON.parse(responseJson);
          if(response.MultimediaAsset == null){
            this.setState({updateStatusText : "Something went wrong. Try again.", alertSeverity : 'error'});
          }
          else{
            let index = this.state.MultimediaAssets.findIndex(x => x.MultimediaAssetId == response.MultimediaAsset.MultimediaAssetId);
            if(index != -1){
              let images = JSON.parse(JSON.stringify(this.state.images));
              images.splice(index,1);
              this.setState({images, updateStatusText : "Photo was removed.", alertSeverity : 'success'});
            }
            else{
              this.setState({updateStatusText : "Something went wrong. Try again.", alertSeverity : "error"});
            }
          }
        });
      }
    })
    //eslint-disable-next-line
    .catch(error =>{
      this.setState({updateStatusText : "Something went wrong. Try again.", alertSeverity : "error"});
    })
  }

  render() {
    let timeZone = " " + Intl.DateTimeFormat().resolvedOptions().timeZone;
    let type;
    switch(this.props.leakSurvey.LeakSurveyTypeId){
      case 14:
        type = "Inaccessible";
      break;
      case 15:
        type = "AOC";
      break;
      case 16:
        type = "Leak Survey";
      break;
      case 22:
        type = "Meter Inspection";
      break;
      default:
        type = "Leak Survey";
      break;
    }
    return (
      <div className = "container-fluid">
        <div id={this.props.fromDashboard ? "" : "InvestigationBodyMainDiv"} name={this.props.fromDashboard ? "" : "InvestigaitonBodyMainDiv"} className="detailMain" style={{ overflowY : "auto"}}>
          <div className="detailContent">
            <div className="labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">{type} Details</label></div>
            {!this.props.editing ? <div className="detailContentPanel">
              <label className="labelDetailName">Address: </label> {this.props.leakSurvey.Address},{" "}
              {this.props.leakSurvey.City},{" "}
              {this.props.leakSurvey.State}
              <br/>
              <label className="labelDetailName">{type} Date: </label>{" "}
              {new Date(this.props.leakSurvey.CreatedDate + "Z").toLocaleString("en-us",{month : "short", day: "numeric", year: "numeric", hour:"numeric",minute:"numeric"}) + timeZone}
              {(type == "Leak Survey") && <div>
              <label className="labelDetailName">Lel %: </label> {this. props.leakSurvey.LELValue}{this.props.leakSurvey.LELValue && "%"}
              <br/>
              <label className="labelDetailName">Gas %: </label> {this. props.leakSurvey.GasValue}{this.props.leakSurvey.GasValue && "%"}
              <br/>
              <label className="labelDetailName">Leak Class: </label> {this.props.leakSurvey.LeakClass}
              <br/>
              <label className="labelDetailName">Service Order Number: </label> {this.props.leakSurvey.ServiceOrderNumber}
              <br/>
              <label className="labelDetailName">Responder: </label> {this.props.leakSurvey.Responder}
              </div>}
              { type == 'AOC' &&<br/> }
              {type != 'Inaccessible' && <div>
              <label className="labelDetailName">Meter Manufacturer: </label> {this.props.leakSurvey.MeterManufacturer}
              <br/>
              <label className="labelDetailName">Meter Number: </label> {this.props.leakSurvey.MeterNumber}
              </div>}
              
              {type == "Leak Survey" && <div>
              <label className="labelDetailName">Facility Type: </label> {this.props.leakSurvey.Object}
              <br/>
              <label className="labelDetailName">Gas Leak Above or Below Ground: </label> {this.props.leakSurvey.GasLeakBelowGround == true ? "Below Ground" : "Above Ground"}
              <br/>
              </div>}
              {type =="Inaccessible" && <div><label className="labelDetailName">Reason Inaccessible: </label> {this.props.leakSurvey.ReasonInaccessible}</div>}
              {(type == "AOC") && <div>
              <label className="labelDetailName">Abnormal Operating Condition: </label> {this.props.leakSurvey.AbnormalOperatingCondition}</div>}
              {(type == "AOC" || type == "Meter Inspection") && <div>
                <label className="labelDetailName">Corrosion Grade: </label>  {this.props.leakSurvey.CorrosionGrade}
                <br/>
                <label className="labelDetailName">Meter Inside:</label> {this.props.leakSurvey.MeterInside == true ? "Yes" : "No"}
                {this.state.specialCompany && 
                <div>
                  <label className="labelDetailName">Regulator Make: </label>  {this.props.leakSurvey.RegulatorMake}
                  <br/>
                  <label className="labelDetailName">Regulator Model: </label> {this.props.leakSurvey.RegulatorModel}
                  <br/>
                  <label className="labelDetailName">Meter Location: </label> {this.props.leakSurvey.MeterLocation}
                </div>}
              </div>}
              <label className="labelDetailName">Assigned User: </label>{this.props.leakSurvey.AssignedUserDisplayName}
              <br/>
              <label className="labelDetailName">Status: </label> {this.props.leakSurvey.Status}
              <br/>
              <label className="labelDetailName">Comments: </label> {this.props.leakSurvey.Comments}
            </div>  :
            <div className="detailContentPanel">
              <div className = "row">
                <div className="col-3">
                  <label className="labelDetailName">Street Address: </label> 
                </div>
                <div className="col">
                  <input type="text" className="form-control entity-edit-text" value={this.props.streetAddress} onChange={(e)=>{this.props.editStreetAddress(e.target.value)}}/>
                </div>
              </div>
              <div className = "row">
                <div className="col-3">
                  <label className="labelDetailName">City: </label> 
                </div>
                <div className="col">
                  <input type="text" className="form-control entity-edit-text" value={this.props.city} onChange={(e)=>{this.props.editCity(e.target.value)}}/>
                </div>
              </div>
              <div className = "row">
                <div className="col-3">
                  <label className="labelDetailName">State: </label> 
                </div>
                <div className="col">
                  <select className ="form-control entity-edit-text" value={this.props.stateAddress} onChange={(e) =>{this.props.editStateAddress(e.target.value)}}>
                    {this.state.StateList.map((state, index) =>( <option key ={index} value={state}>{state}</option>))}
                  </select>
                </div>
              </div>
              {(type == "Leak Survey") && <div>
                <div className = "row">
                  <div className="col-3">
                    <label className="labelDetailName">Gas %: </label> 
                  </div>
                  <div className="col">
                    <input type="number" min="0" max="100" className="form-control entity-edit-text" value={this.props.lelValue} onChange={(e)=>{this.props.editLELValue(e.target.value)}}/>
                  </div>
                  <div className="col">
                    <input type="number" min="0" max="100" className="form-control entity-edit-text" value={this.props.gasValue} onChange={(e)=>{this.props.editGasValue(e.target.value)}}/>
                  </div>
                </div>
                <div className = "row">
                  <div className="col-3">
                    <label className="labelDetailName">Leak Class: </label> 
                  </div>
                  <div className="col">
                  <select className ="form-control entity-edit-text" value={this.props.leakClass} onChange={(e) =>{this.props.editLeakClass(e.target.value)}}>
                    {this.state.LeakClassList.map((leak, index) =>( <option key ={index} value={leak}>{leak}</option>))}
                  </select>
                  </div>
                </div>
                <div className = "row">
                  <div className="col-3">
                    <label className="labelDetailName">Service Order Number: </label> 
                  </div>
                  <div className="col">
                    <input type="number" className="form-control entity-edit-text" value={this.props.serviceOrderNumber} onChange={(e)=>{this.props.editServiceOrderNumber(e.target.value)}}/>
                  </div>
                </div>
                <div className = "row">
                <div className="col-3">
                  <label className="labelDetailName">Responder: </label> 
                </div>
                <div className="col">
                  <input type="text" className="form-control entity-edit-text" value={this.props.responder} onChange={(e)=>{this.props.editResponder(e.target.value)}}/>
                </div>
              </div>
              </div>}
              {(type !="Inaccessible") && <div>
                <div className = "row">
                  <div className="col-3">
                    <label className="labelDetailName">Meter Manufacturer: </label> 
                  </div>
                  <div className="col">
                  <select className ="form-control entity-edit-text" value={this.props.meterManufacturer} onChange={(e) =>{this.props.editMeterManufacturer(e.target.value)}}>
                    {this.state.ManufacturerList.map((meter, index) =>( <option key ={index} value={meter}>{meter}</option>))}
                  </select>
                  </div>
                </div>
                <div className = "row">
                  <div className="col-3">
                    <label className="labelDetailName">Meter Number: </label> 
                  </div>
                  <div className="col">
                    <input type="text" className="form-control entity-edit-text" value={this.props.meterNumber} onChange={(e)=>{this.props.editMeterNumber(e.target.value)}}/>
                  </div>
                </div>
              </div>
              }
              {(type == "Leak Survey") && <div>
                <div className = "row">
                  <div className="col-3">
                    <label className="labelDetailName">Facility Type: </label> 
                  </div>
                  <div className="col">
                    <select className ="form-control entity-edit-text" value={this.props.object} onChange={(e) =>{this.props.editObject(e.target.value)}}>
                      {this.state.FacilityTypeList.map((facility, index) =>( <option key ={index} value={facility}>{facility}</option>))}
                    </select>
                  </div>
                </div>
                <div className = "row">
                  <div className="col-3">
                    <label className="labelDetailName">Gas Leak Below Ground: </label> 
                  </div>
                  <div className="col">
                  <input type="checkbox"  checked={this.props.gasLeakBelowGround ? this.props.gasLeakBelowGround : false} value={this.props.gasLeakBelowGround ? this.props.gasLeakBelowGround : false} className="form-control customFormRowControl xsmall entity-edit-checkbox" onChange={(e) => {this.props.editGasLeakBelowGround(e.target.value != 'true')}} />
                  </div>
                </div>
              </div>
              }
              {(type == "Inaccessible") && <div>
              <div className = "row">
                  <div className="col-3">
                    <label className="labelDetailName">Reason Inaccessible: </label> 
                  </div>
                  <div className="col">
                    <select className ="form-control entity-edit-text" value={this.props.reasonInaccessible} onChange={(e) =>{this.props.editReasonInaccessible(e.target.value)}}>
                      {this.state.ReasonList.map((reason, index) =>( <option key ={index} value={reason}>{reason}</option>))}
                    </select>
                  </div>
                </div>
              </div>}
              {(type == "AOC") && <div>
              <div className = "row">
                  <div className="col-3">
                    <label className="labelDetailName">Abnormal Operating Condition: </label> 
                  </div>
                  <div className="col">
                    <select className ="form-control entity-edit-text" value={this.props.aoc} onChange={(e) =>{this.props.editAOC(e.target.value)}}>
                      {this.state.AocList.map((aoc, index) =>( <option key ={index} value={aoc}>{aoc}</option>))}
                    </select>
                  </div>
                </div>
              </div>}
              {(type == "AOC" || type =="Meter Inspection") && <div>
                <div className = "row">
                  <div className="col-3">
                    <label className="labelDetailName">Corrosion Grade: </label> 
                  </div>
                  <div className="col">
                    <select className ="form-control entity-edit-text" value={this.props.corrosionGrade} onChange={(e) =>{this.props.editCorrosionGrade(e.target.value)}}>
                      {this.state.CorrosionGradeList.map((grade, index) =>( <option key ={index} value={grade}>{grade}</option>))}
                    </select>
                  </div>
                </div>
                <div className = "row">
                  <div className="col-3">
                    <label className="labelDetailName">Meter Inside: </label> 
                  </div>
                  <div className="col">
                    <input type="checkbox"  checked={this.props.meterInside ? this.props.meterInside : false} value={this.props.meterInside ? this.props.meterInside : false} className="form-control customFormRowControl xsmall entity-edit-checkbox" onChange={(e) => {this.props.editMeterInside(e.target.value != 'true')}} />
                  </div>
                </div>
                {this.state.specialCompany && 
                  <div>
                    <div className = "row">
                      <div className ="col-3">
                        <label className="labelDetailName">Regulator Make: </label>
                      </div>
                      <div className="col">
                        <select className="form-control entity-edit-text" value={this.props.regulatorMake} onChange={(e) =>{this.setState({RegulatorModelList : this.setRegulatorModelOptions(e.target.value)});this.props.editRegulatorMake(e.target.value)}}>
                          {this.state.RegulatorMakeList.map((make,index) => ( <option key={index} value={make}>{make}</option>))}
                        </select>
                      </div>
                    </div>
                    <div className = "row">
                      <div className ="col-3">
                        <label className="labelDetailName">Regulator Model: </label>
                      </div>
                      <div className = "col">
                        <select className="form-control entity-edit-text" value={this.props.regulatorModel} onChange={(e) =>{this.props.editRegulatorModel(e.target.value)}}>
                          {this.state.RegulatorModelList.map((model,index) => ( <option key={index} value={model}>{model}</option>))}
                        </select>
                      </div>
                    </div>
                    <div className = "row">
                      <div className ="col-3">
                        <label className="labelDetailName">Meter Location: </label>
                      </div>
                      <div className="col">
                        <select className="form-control entity-edit-text" value={this.props.meterLocation} onChange={(e) =>{this.props.editMeterLocation(e.target.value)}}>
                          {this.state.MeterLocationList.map((location,index) => ( <option key={index} value={location}>{location}</option>))}
                        </select>
                      </div>
                    </div>
                  </div>}
              </div>}
              <div className="row">
                <div className="col-3">
                  <label className="labelDetailName">Status: </label>
                </div>
                <div className="col">
                  <select className="form-control entity-edit-text" value={this.props.status} onChange={(e) =>{this.props.editStatus(e.target.value)}}>
                    {this.state.StatusList.map((status, index) => (<option key={index} value={status}>{status}</option>))}
                  </select>
                </div>
              </div>
              <div className = "row">
                <div className="col-3">
                  <label className="labelDetailName">Comments: </label> 
                </div>
                <div className="col">
                  <textarea type="text" className="form-control entity-edit-text-comment" value={this.props.comments} onChange={(e)=>{this.props.editComments(e.target.value)}}/>
                </div>
              </div>
            </div>}
          
            {/* PHOTOS  */}
            <div className=" labelHeaderBar labelHeaderBarTight"> <label className="labelHeaderName">Photos ({this.state.MultimediaAssets != null ? this.state.MultimediaAssets.length : 0})</label></div>
            <div className="detailContentPanel">
              <div className="barLoaderPortal">
                <BarLoader sizeUnit={"px"} size={200} color={"#095399"} loading={this.state.loading}/>
              </div>
              {this.state.MultimediaAssets != null && this.state.MultimediaAssets.length > 0 ? <div className = "row carouselRow">

              <div className="carouselGalleryDiv">
                <ImageGallery items={this.state.images}
                  onClick={() =>{ this.clickPic();}}
                  showFullscreenButton={this.state.images.length > 0}
                  showPlayButton={this.state.images.length > 0}
                  ref={i => this._imageGallery = i}
                />
                
                <Popup
                  modal={true}
                  lockScroll={false}
                  open={this.state.modalOpen}
                  onClose={this.closeModal}
                  closeOnDocumentClick
                  closeOnEscape
                  position='top left'
                >
                  <div style={{height:"100%"}} className="justify-content-center text-center">
                    <img className="justify-content-center text-center popupPic" id="popupPic" src={this.state.picUrl}/>
                  </div>
                </Popup>
                </div>
                <div className="image-galleryDeleteButtonDiv">
                  {this.state.images.length > 0 && !this.props.showingHistoryRecord && 
                  <Button className = "buttonAction buttonAccount" onClick={this.removePhoto}>
                    <HighlightOffIcon className ="buttonIcon"/>
                    <label style={{margin : 0, cursor : 'pointer'}} className="buttonText">Delete</label>
                  </Button>
                  }
                </div>
              </div> : null }
            </div>
            {!this.props.showingHistoryRecord && <PhotoUpload entityId={this.props.leakSurvey.LeakSurveyId} entityTypeId={this.props.leakSurvey.LeakSurveyTypeId} addToGallery={(image)=>{let ma = this.state.MultimediaAssets; ma.length++;let pic = URL.createObjectURL(image); let pictures = this.state.images; pictures.push({original : pic, thumbnail : pic}); let tempArr = this.state.galleryItems; tempArr.push(image); this.setState({ images : pictures, blobs : tempArr, MultimediaAssets : ma})}} />}
            {this.state.updateStatusText != "" && 
            <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={6000} onClose={this.handleClose} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
            {this.state.updateStatusText}
            </Alert>
          </Snackbar>}
          </div>
        </div>
      </div>
    )
  }
}

LeakSurveyBody.propTypes = {
  leakSurvey: PropTypes.object,
  setPictureCount : PropTypes.func,
  showingHistoryRecord : PropTypes.bool,
  upToDateLeakSurveyId : PropTypes.number,
  fromDashboard : PropTypes.bool
};

export default LeakSurveyBody;