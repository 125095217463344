import React from "react";
//import { withRouter } from "react-router";
import auth from './common/Auth';
import OrgUtility from '../Utility/Utility'

class SupportPage extends React.Component{
  constructor(props){
    super(props);
    this.state ={
      securityPin : ''
    }
  }

  componentDidMount(){
    this.setState({
      securityPin: auth.getSecureCode(),
      orgId: new OrgUtility().getOrgId(),
      mu: 'cm',
      cu: 'a'
    });
  }

  componentDidUpdate(){ 
    window.resize();

    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  updateMU(muVal){
    this.setState({mu:muVal});
  }

  updateCU(cuVal){
    this.setState({cu:cuVal});
  }

  render(){
    return(
      <div className="col-10 d-flex justify-content-center p-0 m-0" style={{ backgroundColor : "#E9E9EF"}}>
        <div id="supportDetailContentPanel" style={{width : '40%', padding: '20px'}}>
          <div  className="detailContentPanel">
            <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10}}>SUPPORT
            </div>
            <div className='licenseEditDivContent'>
            {this.state.orgId != 4 && <div className="labelHeaderBarSubHeader"><div className="supportDetail" style={{margin: 4, fontSize:14}}>
                {this.state.orgId == 3 ? null : 
                  this.state.orgId == 2 ? <a style={{color : '#596473' }} href="https://www.vivax-metrotech.com/support/">https://www.vivax-metrotech.com/support/</a> : 
                  this.state.orgId == 4 ? <a style={{color : '#596473' }} href="https://www.pelicancorp.com/us/home/">https://www.pelicancorp.com/us/home</a> : 
                  "SUPPORT - 844.303.7453 -"}</div>
                {this.state.orgId == 3 ? <div className="supportDetail" style={{margin: 4}}><a style={{color : '#596473' }} href="mailto:support@utili-source.net">SUPPORT@UTILI-SOURCE.NET</a></div> : 
                  this.state.orgId == 4 ? <div className="supportDetail" style={{margin: 4}}><a style={{color : '#596473' }} href="mailto:support@tcapages.net">support@tcapages.net</a></div> : 
                  this.state.orgId == 2 ? null : <div className="supportDetail" style={{margin: 4}}><a style={{color : '#596473' }} href="mailto:support@golaunchpoint.com">SUPPORT@GOLAUNHPOINT.COM</a></div>}</div>}
              <div className="labelDetailName" style={{fontSize:14}}>Company Pin Code: {this.state.securityPin}</div>
            </div>
          </div>
          
          <div className="detailContentPanel">
            <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10}}>CONFIGURATION
            </div>
            <div className='licenseEditDivContent'>
            <div className="labelHeaderBarSubHeader">Measurement Units</div>
              <input id="mumm" style={{marginLeft: 5, height:10}} type = "radio" checked={this.state.mu == 'mm'} onChange={() =>{this.updateMU('mm')}}/>
              <label htmlFor="mumm" style={{marginLeft: 5, height:10, cursor:"pointer"}}>Millimeters (mm)</label>
              <br/>
              <input id="mucm" style={{marginLeft: 5, height:10}} type = "radio" checked={this.state.mu == 'cm'}onChange={() =>{this.updateMU('cm')}}/>
              <label htmlFor="mucm" style={{marginLeft: 5, height:10, cursor:"pointer"}}>Centimeters (cm)</label>
              <br/>
              <input id="mum" style={{marginLeft: 5, height:10}} type = "radio" checked={this.state.mu == 'm'} onChange={() =>{this.updateMU('m')}}/>
              <label htmlFor="mum" style={{marginLeft: 5, height:10, cursor:"pointer"}}>Meters (m)</label>
            </div>
            
            <div className='licenseEditDivContent'>
            <div className="labelHeaderBarSubHeader">Current Units</div>
              <input id="cua" style={{marginLeft: 5, height:10}} type = "radio" checked={this.state.cu == 'a'} onChange={() =>{this.updateCU('a')}}/>
              <label htmlFor="cua" style={{marginLeft: 5, height:10, cursor:"pointer"}}>Amperes (A)</label>
              <br/>
              <input id="cuma" style={{marginLeft: 5, height:10}} type = "radio" checked={this.state.cu == 'ma'}onChange={() =>{this.updateCU('ma')}}/>
              <label htmlFor="cuma" style={{marginLeft: 5, height:10, cursor:"pointer"}}>Milliamperes (mA)</label>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SupportPage;