import React from 'react'
import auth from '../common/Auth';
import { loadModules } from 'esri-loader';
import ImageGallery from 'react-image-gallery';
import { GLOBAL } from '../../Global';
import VideoPanel from './VideoPanel';
import MapTwoIcon from '@material-ui/icons/MapTwoTone';
import SearchIcon from '@material-ui/icons/Search';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import { Button } from 'react-bootstrap';
import { BarLoader } from 'react-spinners';
import FormDataPage from './FormDataPage';
import PropTypes from 'prop-types';
import Functions from '../../Functions/Functions';
import MarkerUtilty from '../../Utility/MarkerUtility';
import ReactDOMServer from 'react-dom/server';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SurveyPage from './SurveyPage';
import InvestigationPage from './InvestigationPage';
import Autolinker from 'autolinker';

class WebMapViewSurveyInvestigation extends React.Component{
  constructor(props){
    super(props);
    this.MarkerUtil = new MarkerUtilty();
    this.entityType = {
      Survey : 0,
      Investigation : 1
    }
    GLOBAL.ASSIGNMENT = {};
    this._DIG_REFRESH = 'DIG';
    this._USER_REFRESH = 'USER';
    this._ARCHIVE_REFRESH = 'ARCHIVE';
    this._UNASSIGN_REFRESH = 'UNASSIGN';
    this._ASSIGNTASK_REFRESH = 'ASSIGN TASK';
    this._ASSIGNMENT_AREA_REFRESH = 'ASSIGNMENT AREA';
    this._USER_ASSIGN_REFRESH = 'USER ASSIGN';
    this._FORM_TASK_ASSIGN_REFRESH = 'FORM TASK ASSIGN';
    this._DIDUPDATE_REFRESH = 'DID UPDATE';

    var colorAssignmentArea = '#de9232';
    var colorSurveys = '#16548e';
    var colorInvestigations = '#8a4a4a';
    var colorE0C = '#176782';
    var colorE1C = '#5e2828';
    var colorMultimediaAssets = 'black';
    this.markerStyle = {
      'Surveys' : 'default',
      'Investigations' : 'default',
      0 : 'default',
      1 : 'default'
    }
    this.legendStyle = {
      'Surveys':colorSurveys,
      'Investigations': colorInvestigations,
      'Multimedia Assets' : colorMultimediaAssets,
      'Assignment Areas':colorAssignmentArea,
      0 : colorSurveys,
      1:  colorInvestigations,
      '-1' : colorMultimediaAssets,
      '-1C' : colorMultimediaAssets,
      '0C' : colorE0C,
      '1C' : colorE1C,
      'DueToday' : '#1703fc',
      'PastDue' : '#fc0303',
      'DueInAwhile' : '#000000',
      'Emergency' : '#fc7b03',
    }
    this.SurveyName = {
      0 : "Site Survey",
      1 : "Investigation"
    }
    this.userLocationMarkerPath = "M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z";
    this.userLocationColor = "#16548E"
    this.uniqueListViewItems = [];
    this.state = {
      ticketFeatureLayers : [],
      map : null,
      mapLayerType : [0,1], //-1
      zoomToExtent : true,
      users : [],
      beginScale : -1,
      currentFullscreenImage : [],
      images : [],
      videos : [],
      videoCount : 0,
      loadingMultimedia : false,
      assignedCustomFormColor : '#3c5873',
      assignedUserColor : '#3c5873',
      addressSearch : '',
      addressSearchForSearch : '',
      baseArcgisURL : "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/",
      addressSuggestions : [],
      currentLat : 0,
      currentLon : 0,
      newTaskAddressColor : '#3c5873',
      editingNewTask :false,
      arcGisConnectedLayersAccessToken : "",
      defaultLayerColor : "#ff6347",
      addressSearchViewOpen : false,
      viewingDetails : false,
      combineAreas : false,
      entityToView : null,
      AssignmentAreaColors : [[216,222,50,0.6],[22,84,142,0.6],[23,103,130,0.6],[94,40,40,0.6],[23,3,252,0.6],[252,3,3,0.6],[252,255,3,0.6],[60,88,115,0.6],[255,99,71,0.6],[135,33,194,0.6]]
    }
    this.mapRef = React.createRef();
    this.setTicketToAssign = this.setTicketToAssign.bind(this); 
    this.zoomToLatLon = this.zoomToLatLon.bind(this);
    this.clickThumbNail = this.clickThumbNail.bind(this);
    this.getVideo = this.getVideo.bind(this);
    this.controller = new AbortController();
    this.setVideoIndex = this.setVideoIndex.bind(this);
    this.setNewTaskLatLon = this.setNewTaskLatLon.bind(this);
    this.createAndAssignTask = this.createAndAssignTask.bind(this);
    this.searchAddress = this.searchAddress.bind(this);
    this.selectSuggestedAddress = this.selectSuggestedAddress.bind(this);
    this.selectSuggestedAddressToZoom = this.selectSuggestedAddressToZoom.bind(this);
    this.createTask = this.createTask.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.uploadDoc = this.uploadDoc.bind(this);
    this.archiveSurvey = this.archiveSurvey.bind(this);
    this.isFirstRerender = true;
    this.rerenderingMap = false;
    this.prevResultSet = null;
    this.openDetailsView = this.openDetailsView.bind(this);
    this.viewEntity = this.viewEntity.bind(this);
    this.uploadTicketAreas = this.uploadTicketAreas.bind(this);
    this.updateFromEdit = this.updateFromEdit.bind(this);
    this.isUserInSelectedAreaOrFeature = this.isUserInSelectedAreaOrFeature.bind(this);
  }


  isUserInSelectedAreaOrFeature(user){
    let inSelectedArea = false;
    let inSelectedFeature = false;
    if(this.props.userAssignmentAreas && this.props.userAssignmentAreas.length > 0){
      if(this.props.selectedTicketAreaFeatures && this.props.selectedTicketAreaFeatures.length > 0 && this.props.selectedTicketAreaFeatures[0] != 'FirstLoad'){
        let thisUsersFeatures = this.props.userAssignmentAreas.filter(x => x.UserId == user.UserId && x.CompanyArcGisLayerId);
        thisUsersFeatures.forEach(area => {
          if(this.props.selectedTicketAreaFeatures.findIndex(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId) != -1){
            inSelectedFeature = true;
          }
        });
      }
      else if(this.props.selectedTicketAreaFeatures && this.props.selectedTicketAreaFeatures.length > 0 && this.props.selectedTicketAreaFeatures[0] == 'FirstLoad'){
        if(this.props.CompanyArcGisLayerFeatures){
          let thisUsersFeatures = this.props.userAssignmentAreas.filter(x => x.UserId == user.UserId && x.CompanyArcGisLayerId);
          thisUsersFeatures.forEach(area => {
            if(this.props.CompanyArcGisLayerFeatures.findIndex(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId) != -1){
              inSelectedFeature = true;
            }
          });
        }
      }
      if(this.props.ticketAssignmentAreas.length > 0 && this.props.filterTicketAssignmentAreasValue && this.props.filterTicketAssignmentAreasValue.length > 0){
        let thisUsersFeatures = this.props.userAssignmentAreas.filter(x => x.UserId == user.UserId && x.AssignmentAreaId);
        thisUsersFeatures.forEach(area => {
          if(this.props.filterTicketAssignmentAreasValue.findIndex(x => x.AssignmentAreaId == area.AssignmentAreaId) != -1){
            inSelectedArea = true;
          }
        });
      }
    }
    return (inSelectedArea || inSelectedFeature) && (user.LicenseIdCollection && (user.LicenseIdCollection.includes("site") || user.LicenseIdCollection.includes("investigation") || user.LicenseIdCollection.includes("investigation")))
  }

  getUserList(){
    let body = {CompanyId : auth.companyId()};
    fetch(process.env.API_URL + "/api/account/users",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status ==  200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0 && json.UserCollection.length>0){
            let i = json.UserCollection.length;
            while(i--){
              if(json.UserCollection[i].DeletedBy != null || json.UserCollection[i].DeletedDate != null || json.UserCollection[i].DeletedDateUTCOffset != null){
                json.UserCollection.splice(i,1);
              }
            }
            let trialMode = json.Company != null && json.Company.SubscriptionPlanId.includes("trial")
            Functions.sortOn(json.UserCollection, 'LastName');
            this.setState({users : json.UserCollection,trialMode});
            this.rerenderMap(this._USER_REFRESH);
          }
        })
      }
    })
  }

  startTechLocationFetchInterval(){
    if(this.state.users.length == 0 || !this.view || !this.layerDictionary["layerUserLocation"]){
      setTimeout(()=>{this.startTechLocationFetchInterval()}, 1000);
      return;
    }
    this.layerDictionary["layerUserLocation"].layer.listMode = 'show';
    this.layerDictionary["layerUserLocation"].layer.visible = true;
    this.fetchTechLocation();
    this.techLocationFetchInterval = setInterval(this.fetchTechLocation.bind(this), 300000); // 5 mins
  }

  fetchTechLocation(){
    let me = this;
    let body = {CompanyId : auth.companyId()};
    fetch(process.env.API_URL + "/api/account/users/UserLastLocationCollection",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status ==  200){
        r.json().then(responseJson =>{
            this.userLastLocationCollection = responseJson.filter(x=> this.state.users.findIndex(y=>y.UserId == x.userId && (y.LicenseIdCollection.includes("site") || y.LicenseIdCollection.includes("investigation"))) > -1);
            this.rerenderUserLocations();            
        });
      }
    }).catch((ex)=>{
      console.log(ex);
    });
  }

  rerenderUserLocations(){
    if(!this.userLastLocationCollection) return;

    var userLocationsToRender =  this.props.filterUserValue != -1 ? this.userLastLocationCollection.filter(x => x.userId == this.props.filterUserValue) : this.userLastLocationCollection;
    loadModules(['esri/Graphic'])
    .then(([Graphic]) =>{
      this.layerDictionary["layerUserLocation"].layer.graphics.removeAll();
      for(var i=0; i < userLocationsToRender.length; i++){
        var user = this.state.users.find(x=>x.UserId == userLocationsToRender[i].userId);
        var popupContent = "<table class='esri-widget__table' summary='List of attributes and values'>";
        popupContent += "<tr><th class='esri-feature__field-header'>Phone</th><td class='esri-feature__field-data'>" + (user.PhoneNumber ? ("<a href=\"tel:" + user.PhoneNumber.replace(/\D/g,'') + "\">" + user.PhoneNumber + "</a>" ) : "") + "</td></tr>"
        popupContent += "<tr><th class='esri-feature__field-header'>Email</th><td class='esri-feature__field-data'>" + Autolinker.link(user.EmailAddress) + "</td></tr>"
        popupContent += "<tr><th class='esri-feature__field-header'>Location Captured</th><td class='esri-feature__field-data'>" + new Date(userLocationsToRender[i].locationDatetime).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}) + " (local time)</td></tr>"
        popupContent +="</table>";
        var markerSymbol = {
          type: "simple-marker", 
          path: this.userLocationMarkerPath, 
          color: this.userLocationColor,
          size: 18,
          outline: {color: [ 222, 222, 222, 0.8 ], width: 1}
        };
        if(this.isUserInSelectedAreaOrFeature(user))this.layerDictionary["layerUserLocation"].layer.graphics.add( new Graphic({ 
          geometry : {type : "point", longitude : userLocationsToRender[i].longitude, latitude : userLocationsToRender[i].latitude}, 
          symbol : markerSymbol,
          popupTemplate: {
            title: "<b><span class='leakSurveyPopupHeader'>" + user.DisplayNameProper + "</span></b>",
            content: popupContent,
            outFields:["*"]
         }
        }));
      }
    });
  }

  getCompanyConfiguration(){
    if(this.techLocationFetchInterval) clearInterval(this.techLocationFetchInterval);
    this.techLocationFetchInterval = null;
    let body = {
      UserId : auth.employeeId(),
      CompanyId  : auth.companyId()
    }
    fetch(process.env.API_URL + "/api/account/company/GetCompanyConfiguration",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          responseJson = JSON.parse(responseJson);
          console.log(responseJson);
          if(responseJson.AppLocationTrackingEnabled){
            this.startTechLocationFetchInterval();
          }
        });
      } else {
        console.log("getCompanyConfiguration: Error getting company config. Status: " + r.status);
      }
    })
    .catch(error =>{
      console.log("getCompanyConfiguration: Error getting company config: " + error);
    });
  }

  hexToRGB(hex){
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16),parseInt(result[2], 16),parseInt(result[3], 16)]
    : null;
  }

  getCustomFormList(){
    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId()
    }
    fetch(process.env.API_URL + "/api/account/form",{
      method: "POST",
      headers:{
        "Content-Type": "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.FormCollection){
              this.setState({formProjects : json.FormProjectCollection, customFormList : json.FormCollection.filter(x => x.Enabled == true && (x.FormTypeId == 0 || x.FormTypeId == 1 || x.FormTypeId == 4))});
            }
          }
        });
      }
    });
  }

  createTask(){
    let form = this.state.customFormList.find(x => x.FormId == this.state.assignedCustomFormId);
    let now = new Date();
    //Add projects field to form if there are projects available to form. 
    if(this.state.formProjects && this.state.formProjects.length > 0){
      let projects = this.state.formProjects.filter(x => x.FormId == form.FormId);
      if(projects && projects.length > 0 && form.Fields && form.Fields.findIndex(x => x.Name == "Project" && x.Type == "Drop Down") == -1){
        form.Fields.unshift({Name : "Project", Type : "Drop Down", CustomValues : projects.map(x => {return x.ProjectLabel})});
      }
    }
    var newTaskCustomForm = {
      FormDataId : -1,
      Fields : form.Fields,
      FieldsToSave : form.Fields,
      FormId : form.FormId,
      FormTypeId : form.FormTypeId,
      Name : form.Name,
      CompanyId : auth.companyId(),
      CreatedDate : now,
      CreatedDateUTCOffset : - now.getTimezoneOffset()/60,
      CreatedBy : auth.employeeId(),
      ModifiedDate : now,
      ModifiedDateUTCOffset : - now.getTimezoneOffset()/60,
      ModifiedBy : auth.employeeId(),
      AllowPics : form.AllowPics,
      AllowVids : form.AllowVids
    }
    this.setState({newTaskCustomForm});
  }

  getGeometryData(formDataId, popup){
    this.props.showProgressBar("Loading Dig Area...");
    if(popup) popup.close();
    let body = {
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      FormDataId : formDataId
    }
    fetch(process.env.API_URL + "/api/FormData/GetGeometryData",{
      method : "POST",
      headers : {
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : 'Bearer ' + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        response.json().then(bodyJson =>{
          var json = JSON.parse(bodyJson);
          if(json.GeometryData){
            this.props.addGeoDataToFormData(formDataId, JSON.parse(json.GeometryData));
            this.updateExtent = false;
           setTimeout(() =>{this.rerenderMap(this._DIG_REFRESH);},100); //Need to make sure that results had a chance to update and set state.
          }
          this.props.showProgressBar("");
          this.props.setAlert('success','Dig Area Loaded.');
          
        });
      }
      else{
        this.props.showProgressBar("");
        this.props.setAlert('error','Error Loading Dig Area.');  
      }
    }).catch(error =>{
      this.props.showProgressBar("");
      this.props.setAlert('error','Error Loading Dig Area.');  
      console.log(error);
    });
  }

  archiveSurvey(survey, popup){
    if(auth.isSuperViewer()){
      if(popup) popup.close();
      return;
    }
    this.props.showProgressBar("Archiving...");
    if(popup) popup.close();
    let formData;
    let now = new Date();
    let isInvestigation = survey.InvestigationId != null;
    if(survey.FormData){
      formData = survey.FormData;
      formData.FieldsToSave = formData.Fields;
      formData.DeletedDate = now;
      formData.DeletedDateUTCOffset = -now.getTimezoneOffset()/60;
      formData.DeletedBy = auth.userId();
      formData.ModifieddDate = now;
      formData.ModifiedDateUTCOffset = -now.getTimezoneOffset()/60;
      formData.ModifiedBy = auth.userId();
    }
    else{
      survey.DeletedDate = now;
      survey.DeletedDateUTCOffset = -now.getTimezoneOffset()/60;
      survey.DeletedBy = auth.userId();
      survey.ModifieddDate = now;
      survey.ModifiedDateUTCOffset = -now.getTimezoneOffset()/60;
      survey.ModifiedBy = auth.userId();
    }
    let url = process.env.API_URL;
    url += (formData != null ? "/api/form/save": (isInvestigation ? "/api/investigation/save" : "/api/survey/save"));
    let body = {
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      SiteSurvey : formData != null ? null : (isInvestigation ? null : survey),
      Investigation : formData != null ? null : (isInvestigation ? survey : null),
      FormData : formData,
      StatusType : 0,
      EntityType : formData != null ? 93 : (isInvestigation ? 1 : 0)
    }
    fetch(url,{
      method : "POST",
      headers : {
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : 'Bearer ' + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        response.json().then(() =>{
          this.props.updateFromArchive(survey, isInvestigation, formData != null);
          this.props.showProgressBar("");
          this.props.setAlert('success','Successfully Archived.');
          this.updateExtent = false;
          popup.close();
          setTimeout(() =>{this.rerenderMap(this._ARCHIVE_REFRESH);},1000); //Need to make sure that results had a chance to update and set state.
        });
      }
      else{
        this.props.showProgressBar("");
        this.props.setAlert('error','Error Archiving.');  
      }
    }).catch(error =>{
      this.props.showProgressBar("");
      this.props.setAlert('error','Error Archiving.');  
      console.log(error);
    });
  }

  uploadTicketAreas(){
    if(auth.isViewer() && !auth.isSuperAdmin()){
      return;
    }
    let files = document.querySelector("input[type=file]")["files"];
    if(files.length > 0){
      let mapFeaturesModal = document.getElementById("mapFeaturesDiv");
      mapFeaturesModal.style.display = "none";
      this.props.showProgressBar("Uploading ShapeFile...");
      let shapeFile = files[0];
      let date = new Date;
      let body ={
        UTCOffset : - (date.getTimezoneOffset() /60),
        UserId : auth.employeeId(),
        CompanyId : auth.companyId(),
        CombineAreas : this.state.combineAreas
      }
      let fd = new FormData();
      fd.append('shapeFile', shapeFile);
      fd.append('DataObject', JSON.stringify(body));
      let req = new XMLHttpRequest();
      req.open("POST", process.env.API_URL + "/api/AssignmentAreaShapeFileUpload",true);
      req.setRequestHeader("Authorization" , "Bearer " + auth.token());
      req.onload = () =>{
        var response = JSON.parse(req.response);
        if(req.status == 500){
          this.props.showProgressBar("");
          this.props.setAlert("error", "Error uploading ShapeFile: " + response.exceptionMessage + " Please contact support at 844-303-7453") ;
        }
        else if(req.status == 200){
          this.props.showProgressBar("");
          let res = JSON.parse(response);
          if(res.ResponseType == 1){
            this.props.setAlert("warning", "ShapeFile Not Uploaded : " + res.ValidationMessageCollection[0].MessageText);
          }
          else{
            this.props.setAlert("success", "Successfully uploaded ShapeFile. Created " + res.AssignmentAreaCollection.length + " Ticket Areas");
            this.props.updateResultsFromShapefileUpload(res);
          }
        }
        else{
          this.props.showProgressBar("");
          this.props.setAlert("error", "Error uploading ShapeFile. Please contact support at 844-303-7453");
        }
      }
      req.onerror = (e) =>{
        console.log(e.Message);
        this.props.showProgressBar("");
        this.props.setAlert("error", "Error uploading ShapeFile: " + e.message + " Please contact support at 844-303-7453")
      }
      req.send(fd);
    }
  }

  updateFromEdit(formData){
    
    this.props.updateFormFromEdit(formData);
    if(this.view.popup){
      this.view.popup.close();
    }
  }

  async createAndAssignTask(){
    let missingInfo = false;
    if(!this.state.newTaskLat || !this.state.newTaskLon){
      this.setState({newTaskAddressColor : 'red'});
      missingInfo = true;
    }
    if(!this.state.assignedCustomFormId || this.state.assignedCustomFormId == ""){
      this.setState({assignedCustomFormColor : 'red'});
      missingInfo = true;
    }
    if(!this.state.assignedUserId || this.state.assignedUserId == ""){
      this.setState({assignedUserColor: 'red'});
      missingInfo = true;
    }
    if(missingInfo){
      return;
    }
    let taskModal = document.getElementById("createTaskDiv");
    taskModal.style.display = "none";
    this.props.statusTextUpdate("CREATING TASK...");
    let now = new Date();
    var customForm = this.state.newTaskCustomForm;
    customForm.Latitude = this.state.newTaskLat;
    customForm.Longitude = this.state.newTaskLon;
    customForm.CreatedDate = now;
    customForm.ModifiedDate = now;
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      FormData : customForm,
      NewTaskFromWeb : true
    }

    fetch(process.env.API_URL + "/api/form/save",{
      method: "POST",
      headers: {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        response.json().then(bodyJson =>{
          let response = JSON.parse(bodyJson);
          if(response.ResponseType != 0){
            this.props.statusTextUpdate("");
            this.props.setAlert("error","There was a problem creating the task.");
          }
          else{
            if(customForm.images && customForm.images.length > 0){
              customForm.images.forEach(async image =>{
                await this.uploadImage(image,response.FormData.FormDataId);
              });
            }
            if(customForm.docs && customForm.docs.length > 0){
              customForm.docs.forEach(async doc =>{
                await this.uploadDoc(doc,response.FormData.FormDataId);
              });
            }
            this.props.setCreatingNewTask(false);
            let newEntity = this.props.addNewEntityToResults(response.FormData);
            this.assignTask(this.state.assignedUserId,newEntity);
            this.setState({assignedCustomFormId : "", assignedUserId : "", addressSearch : '', newTaskLat : null, newTaskLon : null, addressSuggestions : [], foundAddress : '', newTaskCustomForm : null, editingNewTask : false});
          }
          
        });
      }
      else{
        this.props.statusTextUpdate("");
        this.props.setAlert("error","There was a problem creating the task.");
        this.setState({assignedCustomFormId : "", assignedUserId : "", addressSearch : '', newTaskLat : null, newTaskLon : null, addressSuggestions : [], foundAddress : '', newTaskCustomForm : null, editingNewTask : false});
      }
    })
    .catch(() =>{
      this.props.statusTextUpdate("");
      this.props.setAlert("error","There was a problem creating the task.");
      this.setState({assignedCustomFormId : "", assignedUserId : ""});
    });
  }

  async uploadDoc(doc, id){
    let date = new Date();
    if(doc.size > 50000000){
      this.props.statusTextUpdate("");
      this.props.setAlert("error","There was a problem uploading documents for the task.");
      return;
    }
    let body = {
      MultimediaAsset : {
        EntityId : id,
        EntityTypeId : 93,
        CompanyId : auth.companyId(),
        ModifiedBy : auth.employeeId(),
        CreatedBy : auth.employeeId(),
        CreatedDate : date,
        ModifiedDate : date,
        UTCOffset : date.getTimezoneOffset(),
        ModifiedDateUTCOffset : - date.getTimezoneOffset() / 60,
        CreatedDateUTCOffset : - date.getTimezoneOffset() / 60,
        Timestamp : date.getTime(),
        Latitude : 0,
        Longitude : 0,
        Heading : 0,
        MediaTypeId : 3,
        Label : doc.name
      },
      Latitude : 0,
      Longitude : 0,
      Heading : 0,
      CreatedDate : date,
      UTCOffset : date.getTimezoneOffset(),
      Label : doc.name,
      FileType : doc.type
    }
    let fd = new FormData();
    fd.append('Document', doc);
    fd.append('DataObject', JSON.stringify(body));
    let req = new XMLHttpRequest();
    req.open("POST", process.env.API_URL + "/api/document", true);
    req.setRequestHeader("Authorization", "Bearer " + auth.token());
    req.onload = () =>{
      if(req.status == 200){
        return true;
      }
      else {
        this.props.statusTextUpdate("");
        this.props.setAlert("error","There was a problem uploading images for the task.");
      }
    }
    req.onerror = () =>{
      this.props.statusTextUpdate("");
      this.props.setAlert("error","There was a problem uploading images for the task.");
    }
    req.send(fd);
  }

  async uploadImage(image,id){
    let reader = new FileReader();
    reader.onloadend = async () =>{
      let baseString = reader.result;
      baseString = baseString.split(",")[1];
      let date = new Date();
      let body = {
        MultimediaAsset : {
          EntityId : id,
          EntityTypeId : 93,
          CompanyId : auth.companyId(),
          ModifiedBy : auth.employeeId(),
          CreatedBy : auth.employeeId(),
          CreatedDate : date,
          ModifiedDate : date,
          UTCOffset : date.getTimezoneOffset(),
          ModifiedDateUTCOffset : - date.getTimezoneOffset() / 60,
          CreatedDateUTCOffset : - date.getTimezoneOffset() / 60,
          Timestamp : date.getTime(),
          Latitude : 0,
          Longitude : 0,
          Heading : 0,
          MediaTypeId : 0
        },
        Base64Img : baseString
      }
      fetch(process.env.API_URL + "/api/image",{
        method : "POST",
        headers : {
          "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization : "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          return true;
        }
        else {
          this.props.statusTextUpdate("");
          this.props.setAlert("error","There was a problem uploading images for the task.");
        }
      }).catch(error =>{
        console.log(error);
        this.props.statusTextUpdate("");
        this.props.setAlert("error","There was a problem uploading images for the task.");
      });
    }
    reader.readAsDataURL(image.file);
  }

  assignTaskCollection(assignmentUserId, formTasks){
    if(auth.isSuperViewer())  {
      return;
    }
    if(assignmentUserId == "-1"){
      this.props.showProgressBar("Unassigning Tasks");
      let body = {
        UserId : auth.employeeId(),
        CompanyId : auth.companyId(),
        FormTaskList : formTasks
      }

      fetch(process.env.API_URL + "/api/formTaskAssignment/unassign",{
        method: "POST",
        headers:{
          Accept : "application/json",
          "Content-Type" : "application/json; charset=utf-8",
          Authorization: "Bearer " + auth.token()
        },
        body: JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          r.json().then(bodyJson =>{
            let json = JSON.parse(bodyJson);
            if(json.FormTaskList){
              this.props.updateResultsFromTaskUnassign(json.FormTaskList);
            }
            this.props.showProgressBar("");
            this.props.setAlert("success", "Tasks Unassigned");
            this.updateExtent = false;
            setTimeout(() =>{this.setState({updatingAssignment : false}); this.rerenderMap(this._UNASSIGN_REFRESH);},1000);
          });
        }
        else{
          this.props.showProgressBar("");
          this.props.setAlert("error", "Error unassigning tasks.");
        }
      })
      .catch(error =>{
        this.props.showProgressBar("");
        this.props.setAlert("error", "Error unassigning tasks.");
        console.log(error.message);
      });
    }
    else{
      this.props.showProgressBar("Assigning Tasks");
      let body = {
        UserId : auth.employeeId(),
        CompanyId : auth.companyId(),
        FormTaskList : formTasks
      }

      fetch(process.env.API_URL + "/api/formTaskAssignment/assignCollection",{
        method : "POST",
        headers : {
          Accept : "applicaiton/json",
          "Content-Type" : "application/json; charset=utf-8",
          Authorization : "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          r.json().then(bodyJson =>{
            let json = JSON.parse(bodyJson);
            if(json.FormTaskList){
              json.FormTaskList.forEach(formTask =>{
                this.props.updateResultsFromAssignment(formTask);
              });
            }
            this.props.showProgressBar("");
            this.props.setAlert("success", "Tasks Assigned.");
            this.updateExtent = false;
            setTimeout(() =>{this.setState({updatingAssignment : false}); this.rerenderMap(this._ASSIGNTASK_REFRESH);}, 100);
          });
        }
        else{
          this.props.showProgressBar("");
          this.props.setAlert("error","Error assigning tasks.");
        }
      })
      .catch(error =>{
        this.props.showProgressBar("");
        this.props.setAlert("error", "Error assigning tasks.");
        console.log(error.message);
      })
    }
    this.state.sketchLayer.remove(GLOBAL.ASSIGNMENT.graphic);
    GLOBAL.ASSIGNMENT.graphic = null;
    GLOBAL.ASSIGNMENT.geometry = null;
  }

  setNewTaskLatLon(lat, lon){
    if(this.props.creatingNewTask){
      this.setState({newTaskLat : lat, newTaskLon : lon, newTaskAddressColor : '#3c5873'});
      document.getElementById("createTaskDiv").style.display = 'block';
    }
  }

  getTicketAreaExpression(layer){
    var ticketAreas = this.props.selectedTicketAreaFeatures;
    if(!layer.loaded || (!ticketAreas || (ticketAreas.length == 1 && ticketAreas[0] == "FirstLoad"))){
      //Need to only display layers available to the user
      if(!this.props.CompanyArcGisLayerFeatures){
        return "1=1";
      }
      else if(layer.fields){
        let featureAreas = this.props.CompanyArcGisLayerFeatures.filter(x => x.CompanyArcGISLayerId == layer.CompanyArcGisLayerId)
        let OID = layer.fields.find(x => x.type == "oid"); 
        var featureAreasidList = "(";
        featureAreas.forEach((area,index) =>{
          featureAreasidList += area.FeatureId + (index != featureAreas.length -1 ? "," : ")");
        });
        //Do not return anything if no selected features
        if(featureAreas.length < 1) return OID.name + "= -1";
        return OID.name + " in " + featureAreasidList;
      }
    } 
    else{
      ticketAreas = ticketAreas.filter(x => x.CompanyArcGISLayerId == layer.CompanyArcGisLayerId);
    //Have to check and see what the id of the feature is to use. 
      let OID = layer.fields.find(x => x.type == "oid"); 
      var idList = "(";
      ticketAreas.forEach((area,index) =>{
        idList += area.FeatureId + (index != ticketAreas.length -1 ? "," : ")");
      });
      //Do not return anything if no selected features
      if(ticketAreas.length < 1) return OID.name + "= -1";
      return OID.name + " in " + idList;
    }
  }

  searchAddress(e, searchType){
    let text = e.target.value;
    if(searchType == "taskSearch"){
      this.setState({addressSearch : text});
    }
    else if(searchType == "addressSearch"){
      this.setState({addressSearchForSearch : text});
    }
    let lat = this.state.currentLat;
    let lon = this.state.currentLon; 
    let searchUrl = this.state.baseArcgisURL + "/suggest?f=JSON&category=Address" + (lat == 0 || lon == 0 ?  "" : "&location=" + lon + "," + lat) +"&text=" + text;
    fetch(searchUrl,
      {
        method: 'GET'
      })
      .then(async resp =>{
        if(resp.status == 200){
          resp.json().then(bodyJson =>{
            if(bodyJson.suggestions){
              this.setState({addressSuggestions : bodyJson.suggestions});
            }
            else{
              this.setState({addressSuggestions : []});
            }
          });
        }
        else{
          this.setState({addressSuggestions : []});
        }
      })
      .catch(e =>{
        console.log(e);
        this.setState({addressSuggestions : []});
      })
  }

  zoomToLatLon(lat,lon){
    this.view.center = [lon,lat];
    this.view.zoom = 18;
    
  }

  selectSuggestedAddressToZoom(suggestion){
    loadModules(['esri/Graphic'])
    .then(([Graphic]) =>{
      let url = this.state.baseArcgisURL + "findAddressCandidates?F=JSON&magicKey=" + suggestion.magicKey;
      if(this.selectedAddressGraphic){
        this.view.graphics.remove(this.selectedAddressGraphic);
      }
      fetch(url, 
        {
          method : "GET"
        })
        .then(async resp =>{
          if(resp.status == 200){
            resp.json().then(bodyJson =>{
              document.getElementById("addressSearchDiv").style.display = "none"; 
              this.setState({addressSearchForSearch : "", addressSuggestions: [] });
              this.view.center = [bodyJson.candidates[0].location.x,bodyJson.candidates[0].location.y];
              this.view.zoom = 18;
              var markerSymbol = {
                type: "text", // autocasts as new TextSymbol()
                color: "red",
                text:"\ue61d", // esri-icon-map-pin
                font: {
                  size: 24,
                  family: "CalciteWebCoreIcons"
                }
              };
              this.selectedAddressGraphic = new Graphic({ geometry : {type : "point", longitude : bodyJson.candidates[0].location.x, latitude : bodyJson.candidates[0].location.y}, symbol : markerSymbol});
              this.view.graphics.add(this.selectedAddressGraphic);
            });
          }
        });
    });
  }

  selectSuggestedAddress(suggestion){
    let url = this.state.baseArcgisURL + "findAddressCandidates?F=JSON&magicKey=" + suggestion.magicKey;
    fetch(url, 
      {
        method : "GET"
      })
      .then(async resp =>{
        if(resp.status == 200){
          resp.json().then(bodyJson =>{
            this.setState({newTaskAddressColor : '#3c5873',foundAddress : bodyJson.candidates[0].address, addressSearch : bodyJson.candidates[0].address, newTaskLat : bodyJson.candidates[0].location.y, newTaskLon : bodyJson.candidates[0].location.x, addressSuggestions: [] });
          });
        }
      });
  }

  componentDidMount(){
    if(this.props.ARCGISClientSecret && this.props.ARCGISClientSecret  != '' && this.props.ARCGISClientId && this.props.ARCGISClientId != ''){
      if(this.props.EnterpriseConnection){
        fetch(process.env.API_URL + "/api/arcgis/getcompanyenterprisetoken/" + auth.companyId(),{
          method : "POST",
          headers : {
            "Content-Type" : "application/json; charset=utf-8",
            Accept : "application/json",
            Authorization : "Bearer " + auth.token()
          }
          }).then(response =>{
            response.json().then(bodyJson =>{
              this.setState({arcGisConnectedLayersAccessToken : bodyJson.access_token});
            })
          })
      }
      else{
        fetch("https://www.arcgis.com/sharing/rest/oauth2/token?grant_type=client_credentials&client_id=" + this.props.ARCGISClientId + "&client_secret=" + this.props.ARCGISClientSecret,{
        method : "POST",
        headers : {"Content-Type" : "application/x-www-form-urlencoded"}
      }).then(response =>{
        response.json().then(bodyJson =>{
          this.setState({arcGisConnectedLayersAccessToken : bodyJson.access_token});
        })
      });}
    }
    // setTimeout(() =>{
    //   if (document.getElementsByClassName("esri-sketch-header").length == 0 && document.getElementsByClassName("esri-sketch")[0] != undefined){
    //     var header = document.createElement("div");
    //     header.className = "esri-sketch-header";
    //     header.innerHTML = "ASSIGN TASKS/AREA";
    //     document.getElementsByClassName("esri-sketch")[0].insertBefore(header,document.getElementsByClassName("esri-sketch")[0].childNodes[0]);
    //   }
    // }, 2000);

    window.onclick = e =>{
      let modal = document.getElementById("imageGalleryDiv");
      let taskModal = document.getElementById("createTaskDiv");
      let addressSearchModal = document.getElementById("addressSearchDiv");
      let assignTaskModal = document.getElementById("assignTaskDiv");
      let mapFeaturesModal = document.getElementById("mapFeaturesDiv");
      if(e.target == taskModal){
        this.props.setCreatingNewTask(false);
        taskModal.style.display = "none";
      }
      if(e.target == modal){
        this.setState({images : [], videos : [], thumbnails : [], videoCount : 0, pictureCount: 0});
        modal.style.display = "none";
      }
      if(e.target == addressSearchModal){
        addressSearchModal.style.display = "none";
      }
      if(e.target == assignTaskModal){
        assignTaskModal.style.display = "none";
      }
      if(e.target == mapFeaturesModal){
        mapFeaturesModal.style.display = "none";
      }
    }
    this.getUserList();
    this.getCompanyConfiguration();
    this.getCustomFormList();
    let me = this;
    window.addEventListener('beforeunload', this.beforeunload.bind(this));

    //ESRI MAP SECTION
    loadModules(['esri/Map', 'esri/views/MapView','esri/widgets/LayerList',
    'esri/layers/GraphicsLayer', 'esri/geometry/support/webMercatorUtils',
    'esri/geometry/geometryEngine', 'esri/widgets/Sketch', 'esri/geometry/Point', 'esri/core/watchUtils', 'esri/widgets/Zoom', 'esri/widgets/BasemapGallery',  'esri/widgets/Expand', 'esri/config', 'esri/portal/Portal', 'esri/widgets/Measurement', 'esri/layers/FeatureLayer', 'dojo/dom-construct'], { css: true })
    .then(([ArcGISMap, MapView, LayerList, GraphicsLayer,
      WebMercatorUtils, geometryEngine, Sketch, Point, watchUtils, Zoom,BasemapGallery, Expand, esriConfig,Portal, Measurement, FeatureLayer, domConstruct]) =>{
        esriConfig.request.interceptors.push({
          urls:["https://golaunchpoint.maps.arcgis.com/","https://ibasemaps-api.arcgis.com/","https://basemaps-api.arcgis.com/"],
          before : function(params){
            params.requestOptions.query = params.requestOptions.query || {};
            params.requestOptions.query.token =  process.env.ESRIAPIKEY;
          }
        });
        if(this.props.CompanyArcGisLayers.length > 0){
          esriConfig.request.interceptors.push({
            urls:this.props.CompanyArcGisLayers.map((layer) =>layer.Url),
            before: function(params){
              params.requestOptions.query = params.requestOptions.query || {};
              params.requestOptions.query.token =  me.state.arcGisConnectedLayersAccessToken;
            }
          });
        }
        esriConfig.portalUrl = 'https://golaunchpoint.maps.arcgis.com/';
      const portal = new Portal();
      portal.load()
      .then(() =>{
        //LOCAL REF
      me.geometryEngine = geometryEngine;
      
      //MAP VIEW
      let lastBasemap = localStorage.getItem("basemap");
      const map = new ArcGISMap({basemap: lastBasemap && lastBasemap != "" ? lastBasemap : "arcgis-light-gray"});
      this.map = map;
      const view = new MapView({container: this.mapRef.current, map: map, center: [-98,40], zoom: 4, ui: {components: []}});
      this.view = view;
      //BASEMAP
      //const basemapToggle = new BasemapToggle({ view: this.view, nextBasemap: "hybrid"});
      const measurement = new Measurement();
      measurement.view = view;
      view.ui.add(measurement, "bottom-right");
      var distanceButton = document.createElement("button");
      var areaButton = document.createElement("button");
      var clearButton = document.createElement("button");
      areaButton.className = "esri-widget--button esri-interactive esri-icon-measure-area";
      distanceButton.className = 'esri-widget--button esri-interactive esri-icon-measure-line';
      clearButton.className = "esri-widget--button esri-interactive esri-icon-trash";
      view.ui.add(distanceButton, 'bottom-left');
      distanceButton.addEventListener("click", ()=>{
        distanceButton.classList.add("active");
        measurement.activeTool = "distance";
        areaButton.classList.remove("active");
      });
      view.ui.add(areaButton, "bottom-left");
      areaButton.addEventListener("click", ()=>{
        areaButton.classList.add("active");
        measurement.activeTool = "area";
        distanceButton.classList.remove("active");
      });
      view.ui.add(clearButton, "bottom-left");
      clearButton.addEventListener("click", ()=>{
        distanceButton.classList.remove("active");
        areaButton.classList.remove("active");
        measurement.clear();
      });
      var addressBtn = document.createElement("button");
      addressBtn.innerText = "Search Address";
      //view.ui.add(addressBtn, "top-right");
      addressBtn.addEventListener('click', ()=>{
        document.getElementById("addressSearchDiv").style.display = 'block';
      });
      var btn = document.createElement('button');
      btn.innerText = "Export Map";
      //view.ui.add(btn, 'top-right');
      btn.addEventListener('click',() =>{
        view.takeScreenshot().then(screenshot =>{
          const downloadLink = document.createElement('a');
          document.body.appendChild(downloadLink);
          downloadLink.href = screenshot.dataUrl;
          downloadLink.target = '_self';
          downloadLink.download = "Map.jpeg";
          downloadLink.click(); 
        });
      });
      var mapFeaturesButton = document.createElement("button");
      mapFeaturesButton.innerText = "MAP MENU";
      mapFeaturesButton.className = "mapActionButton"
      view.ui.add(mapFeaturesButton, "top-right");
      mapFeaturesButton.addEventListener('click', ()=>{
        document.getElementById("mapFeaturesDiv").style.display = 'block';
      });
      const basemapGallery = new BasemapGallery({view : this.view});
      basemapGallery.watch('activeBasemap',function(newValue){
        var newBasemap = "";
        switch(newValue.title){
          case "Imagery":
            newBasemap = 'arcgis-imagery-standard';
            break;
          case "Imagery Hybrid":
            newBasemap = "arcgis-imagery"
            break;
          case "Streets":
              newBasemap = "arcgis-streets"
              break;
          case "Topographic":
            newBasemap = "arcgis-topographic"
            break;
          case "Navigation":
            newBasemap = "arcgis-navigation"
            break;
          case "Streets (Night)":
            newBasemap = "arcgis-streets-night"
            break;
          case "Terrain with Labels":
            newBasemap = "arcgis-terrain"
            break;
          case "Light Gray Canvas":
            newBasemap = "arcgis-light-gray"
            break;
          case "Dark Gray Canvas":
            newBasemap = "arcgis-dark-gray"
            break;
          case "Oceans":
            newBasemap = "arcgis-oceans"
            break;
          // case "National Geographic Style Map":
          //   newBasemap = ""
          //   break;
          case "OpenStreetMap":
            newBasemap = "osm-standard"
            break;
          case "Charted Territory Map":
            newBasemap = "arcgis-charted-territory"
            break;
          case "Community Map":
            newBasemap = "arcgis-community"
            break;
          case "Navigation (Dark Mode)":
            newBasemap = "arcgis-navigation-night"
            break;
          case "Newspaper Map":
            newBasemap = "arcgis-newspaper"
            break;
          // case "Human Geography Map":
          //   newBasemap = "17ffb944733-basemap-23"
          //   break;
          // case "Human Geography Dark Map":
          //   newBasemap = "osm-dark-gray"
          //   break;
          case "Modern Antique Map":
            newBasemap = "arcgis-modern-antique"
            break;
          case "Mid-Century Map":
            newBasemap = "arcgis-midcentury"
            break;
          case "Nova Map":
            newBasemap = "arcgis-nova"
            break;
          case "Colored Pencil Map":
            newBasemap = "arcgis-colored-pencil"
            break;
          // case "Outline Map":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "FireFly Imagery Hybrid":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "NAIP Imagery Hybrid":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "USGS National Map":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "USA Topo Maps":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          // case "OpenStreetMap (Blueprint)":
          //   newBasemap = "arcgis-colored-pencil"
          //   break;
          default:
            console.log(newValue.title);
        }
        localStorage.setItem("basemap",newBasemap);
      });
      const bgExpand = new Expand({
        view : this.view,
        content : basemapGallery
      });
      
      this.view.ui.add(bgExpand, {position : 'bottom-left'});
      var zoom = new Zoom({ view : this.view, layout: "vertical"});
      this.view.ui.add(zoom, { position : 'bottom-right'});
      view.on("click", function(event){
        me.setNewTaskLatLon(event.mapPoint.latitude, event.mapPoint.longitude);
      });

      //DYNAMIC DATA LAYERS
      this.layerDictionary = new Object();
      var layerSurveyType = new GraphicsLayer({title: 'Surveys'});
      var layerInvestigationType = new GraphicsLayer({title: 'Investigations'});
      var layerAssignmentAreas = new GraphicsLayer({title: 'Assignment Areas', listMode: 'hide'});
      var layerTicketAssignmentAreas = new GraphicsLayer({title: 'Ticket Areas', listMode : 'hide'});
      var layerDigArea = new GraphicsLayer({title: "Dig Areas", listMode : 'hide'});
      var layerUserLocation = new GraphicsLayer({title: 'User Locations', listMode : 'hide'});
      //var layerMultimediaAssets = new GraphicsLayer({title: 'Multimedia Assets'});
      
      this.layerDictionary[0] = {layer: layerSurveyType};
      this.layerDictionary[1] = {layer: layerInvestigationType};
      this.layerDictionary[3] = {layer: layerAssignmentAreas};
      this.layerDictionary["assignment"] = {layer: layerAssignmentAreas};
      this.layerDictionary["ticketAssignmentArea"] = {layer: layerTicketAssignmentAreas};
      this.layerDictionary["layerDigArea"] = {layer: layerDigArea};
      this.layerDictionary["layerUserLocation"] = {layer: layerUserLocation};
      //this.layerDictionary['-1'] = {layer: layerMultimediaAssets};

      map.layers.add(layerAssignmentAreas);
      map.layers.add(layerTicketAssignmentAreas);
      map.layers.add(layerDigArea);
      map.layers.add(layerSurveyType);
      map.layers.add(layerInvestigationType);
      map.layers.add(layerUserLocation);
      
      //map.layers.add(layerMultimediaAssets);
      var ticketFeatureLayers = [];
      if(this.props.CompanyArcGisLayers.length > 0 && this.state.arcGisConnectedLayersAccessToken){
        this.props.CompanyArcGisLayers.forEach(companyLayer =>{
          var layer = new FeatureLayer({title: companyLayer.Name, url:companyLayer.Url, popupTemplate:{title: companyLayer.Name, content: me.getAutoAttributePopupContent, outFields : ["*"]}});
          if(companyLayer.LayerType == "TicketArea"){
            layer.CompanyArcGisLayerId = companyLayer.CompanyArcGisLayerId;
            layer.definitionExpression = this.getTicketAreaExpression(layer);
            ticketFeatureLayers.push(layer);
          }
          map.layers.add(layer);
        });
      }

      map.reorder(layerUserLocation, map.layers.length - 1);

      var sketchLayer = new GraphicsLayer({title: 'Graphics', listMode : 'hide'});
      map.layers.add(sketchLayer);
      var sketch = new Sketch({ layer: sketchLayer, view : view, id : 'esri-sketch-leaksurvey', 
        snappingOptions: {enabled: true, featureSources: [{layer: sketchLayer, enabled : true},{layer: layerTicketAssignmentAreas, enabled : true},{layer: layerAssignmentAreas, enabled : true}]}});

      sketch.on("create", function(event){
        if(event.state === "complete"){
          var delAction = { title: "Erase", id: "del", className: "esri-icon-erase" };
          var chooseAction = { title: "Select Action", id: "chooseAction", className: "esri-icon-directions" };
          var geoGeometry = WebMercatorUtils.webMercatorToGeographic(event.graphic.geometry);
          GLOBAL.ASSIGNMENT.geometry = geoGeometry;
          GLOBAL.ASSIGNMENT.graphic = event.graphic;

          event.graphic.attributes = {
            name : "NEW ASSIGNMENT AREA",
            type : event.graphic.geometry.type,
            geoGeometry : geoGeometry
          };

          event.graphic.popupTemplate = {
            title: "{name}",
            content: setContentInfo,
            actions: [chooseAction, delAction]
          };

          this.view.popup.open({location: event.graphic.geometry.centroid, fetchFeatures: true});
        }
      });

      sketch.on("update", function(event){
        if(event.state == "complete" || event.state == "cancel"){
          var geoGeometry = WebMercatorUtils.webMercatorToGeographic(event.graphics[0].geometry);
          GLOBAL.ASSIGNMENT.geometry = geoGeometry;
        }
      });

      sketch.on("delete", function(event){
        if(GLOBAL.ASSIGNMENT.graphic != undefined){
          sketchLayer.remove(event.graphics[0]);
          GLOBAL.ASSIGNMENT.graphic = null;
          GLOBAL.ASSIGNMENT.geometry = null;
        }
      });

      sketch.availableCreateTools = ["polygon"];
      this.view.ui.add(sketch, {position : 'top-right'});

      this.view.popup.on("trigger-action", function(event){
        if(event.action.id == "viewDetails"){ viewDetails(me, {target : this.view.popup});}
        if(event.action.id == "viewDigArea"){ viewDigArea(me, {target : this.view.popup});}
        if(event.action.id == "viewMultimedia"){ viewMultimedia(me, {target : this.view.popup});}
        if(event.action.id == "viewLarger") { viewLarger(me, {target : this.view.popup});}
        if(event.action.id == "viewVideo") { viewVideo(me, {target : this.view.popup});}
        if(event.action.id == "assignTask")  { assignTask(me, {target : this.view.popup});}
        if(event.action.id == "unassignTask"){ unassignTask({target : this.view.popup});}
        if(event.action.id == "del"){deleteAssignment(me,{target : this.view.popup});}
        if(event.action.id == "delTicketArea"){deleteTicketAssignmentArea(me,{target : this.view.popup});}
        if(event.action.id == "updateLabel"){updateAssignmentLabel(me,{target : this.view.popup});}
        if(event.action.id == "archive"){archiveSurvey(me,{target : this.view.popup});}
        if(event.action.id == "chooseAction"){chooseAction(me,{target : this.view.popup});}
      });

      view.layerDictionary = this.layerDictionary;
      view.legendStyle = this.legendStyle;
      view.markerStyle = this.markerStyle;
      view.mapLayerType = this.state.mapLayerType;

      // Display the loading indicator when the view is updating
      watchUtils.whenTrue(view, "updating", function() {
        //document.getElementById("loading").style.display = 'unset';
      });

      // Hide the loading indicator when the view stops updating
      watchUtils.whenFalse(view, "updating", function() {
        //document.getElementById("loading").style.display = 'none';
      });

      //MAP CLICK EVENT
      view.on("click", function(event) {view.hitTest(event).then(getGraphics);});
      function getGraphics(e){
        e.results.forEach(g =>{
          if(g.graphic.EntityTypeId || (g.graphic.attributes.clusterId && !g.graphic.EntityTypeId)){
            view.cluster_expandCluster(g.graphic, view);
          }
        });
      }

      watchUtils.when(view, "stationary", function(){
        try{
          if(this.extent){
            if(this.cluster_clusterGraphics){
              //clear existing cluster graphics
              this._clusters.length = 0;
              if(this.surveyGraphics){
                this.mapLayerType.forEach(graphicLayer =>{
                  if(this.surveyGraphics[graphicLayer])
                  this.surveyGraphics[graphicLayer].length =0;
                });
              }
              this.extentGeo = WebMercatorUtils.webMercatorToGeographic(this.extent);
              this.cluster_clusterGraphics(this);
            }
          }
        }
        catch(e){
          console.log('watchUtils_exception',e);
        }
      });

      function chooseAction(context, event){
        var actionSelect = document.getElementById("assignmentSelect");
        var actionId = actionSelect != null ? actionSelect.value : 0;
        var assignmentLabel = document.getElementById("assignmentlabel");
        if(assignmentLabel.style.display == "block"){
          actionId = 0;
        }
        var title = document.getElementsByClassName("esri-popup__header-title")[0];
        var label = document.getElementsByClassName("assignmentLabel")[0];
        var icon = document.getElementsByClassName("esri-icon-directions")[0];
        var buttonText = document.getElementsByClassName("esri-popup__action-text")[0];
        if(actionId == 'assignAllTasks'){
          title.innerHTML = "ASSIGN ALL TASKS";
          label.innerHTML = "Assign To: ";
          var action = document.getElementById("assignmentSelect");
          action.innerHTML = me.state.userOptionList;
          icon.className = "esri-popup__icon esri-icon-user";
          buttonText.innerHTML = 'Assign All';
        }
        else if(actionId == "assignArea"){
          title.innerHTML = "NEW USER ASSIGNMENT AREA";
          label.innerHTML = "Assign To: ";
          action = document.getElementById("assignmentSelect");
          action.innerHTML = me.state.userOptionList;
          icon.className = "esri-popup__icon esri-icon-user";
          buttonText.innerHTML = 'Assign';
        }
        else if(actionId == "assignTicketArea"){
          title.innerHTML = "NEW TICKET ASSIGNMENT AREA";
          label.innerHTML = "Area Name: ";
          action = document.getElementById("assignmentSelect");
          action.style.display = "none";
          assignmentLabel.style.display = "block";
          icon.className = "esri-popup__icon esri-icon-user";
          buttonText.innerHTML = 'Create Area';
        }
        else if(actionId === "unassignAllTasks"){
          unassignAllSelectedTasks(context, event);
        }
        else if(actionId != "-1"){
          if(buttonText.innerHTML == "Assign"){
            assignUserArea(context,event);
          }
          else if(buttonText.innerHTML == "Assign All"){
            assignAllSelectedTasks(context, event);
          }
          else if(buttonText.innerHTML == "Create Area"){
            assignTicketArea(context,event);
          }
        }
        else{
          console.log(actionId);
        }
      }

      function viewDetails(context, event){
        let attr = event.target.selectedFeature.attributes;
        me.viewEntity(attr.FormData);
        if(attr.FormData){
          me.viewEntity(attr.FormData,'form');
        }
        else if(attr.EntityTypeId == 0){
          me.viewEntity(attr,'survey');
        }
        else if(attr.EntityTypeId == 1){
          me.viewEntity(attr,'investigation');
        }
      }

      function viewDigArea(context, event){
        let attr = event.target.selectedFeature.attributes;
        me.getGeometryData(attr.FormData.FormDataId, view.popup);
      }

      function archiveSurvey(context, event){
        let attr = event.target.selectedFeature.attributes;
        me.archiveSurvey(attr, view.popup);
      }

      function deleteAssignment(context,event){
        var userAssignmentId = -1;
        if(event.target.selectedFeature.attributes.name != "NEW ASSIGNMENT AREA"){
          userAssignmentId = event.target.selectedFeature.attributes.UserAssignmentId;
        }
        if(userAssignmentId == -1){
          me.state.sketchLayer.remove(GLOBAL.ASSIGNMENT.graphic);
          GLOBAL.ASSIGNMENT.graphic = null;
          GLOBAL.ASSIGNMENT.geometry = null;
        }
        else{
          me.updateAssignmentArea(null,null,userAssignmentId, true);
        }
        view.popup.close();
      }

      function deleteTicketAssignmentArea(context, event){
        if(auth.isViewer()){
          view.popup.close();
          return;
        }
        var assignmentId = -1;
        if(event.target.selectedFeature.attributes.name != "NEW ASSIGNMENT AREA"){
          assignmentId = event.target.selectedFeature.attributes.AssignmentAreaId;
        }
        me.updateTicketAssignmentArea(null,null,assignmentId, true);
        view.popup.close();
      }

      function updateAssignmentLabel(context, event){
        if(auth.isViewer() && !auth.isAdmin() && !auth.isSuperAdmin() ){
          view.popup.close();
          return;
        }
        var assignmentLabel = document.getElementById("assignmentlabel");
        var assignmentColor = document.getElementById("assignmentColor");
        var assignmentId = event.target.selectedFeature.attributes.AssignmentAreaId;
        if(event.target.selectedFeature.attributes.GroupId){
          me.props.ticketAssignmentAreas.forEach(area =>{
            if(area.GroupId == event.target.selectedFeature.attributes.GroupId)
              me.updateTicketAssignmentArea(assignmentLabel.value, assignmentColor.value,area.AssignmentAreaId);
          });
        }
        else{
          me.updateTicketAssignmentArea(assignmentLabel.value, assignmentColor.value,assignmentId);
        }
        view.popup.close();
      }

      function assignUserArea(context, event){
        var assignmentSelect = document.getElementById("assignmentSelect");
        var assignedUserId = assignmentSelect.value;
        var username = assignmentSelect.options[assignmentSelect.selectedIndex].innerText;
        var userAssignmentId = -1;

        if(event.target.selectedFeature.attributes.name != "NEW ASSIGNMENT AREA"){
          userAssignmentId = event.target.selectedFeature.attributes.UserAssignmentId;
        }

        me.updateAssignmentArea(assignedUserId, username, userAssignmentId);
        view.popup.close(); 
      }

      function assignTicketArea(context, event){
        if(auth.isViewer() && !auth.isAdmin() && !auth.isSuperAdmin() ){
          view.popup.close();
          return;
        }
        var assignmentLabel = document.getElementById("assignmentlabel");
        var assignmentColor = document.getElementById("assignmentColor");
        var assignmentId = -1;

        if(event.target.selectedFeature.attributes.name != "NEW ASSIGNMENT AREA"){
          assignmentId = event.target.selectedFeature.attributes.AssignmentAreaId;
        }
        if(!assignmentColor){
          assignmentColor = '#de9232';
        }
        me.updateTicketAssignmentArea(assignmentLabel.value, assignmentColor.value,assignmentId);
        view.popup.close();
      }

      function assignAllSelectedTasks(context, event){
        var assignmentSelect = document.getElementById("assignmentSelect");
        var assignmentId = assignmentSelect.value;
        var formTasks = [];
        view.map.layers._items.forEach((layer) =>{
          if(layer.graphics && (layer.title != null && layer.title != 'Graphics' && layer.title != 'Assignment Areas' && layer.title != "Ticket Areas" && layer.title != "User Locations") && me.getCachedLayerVisibility(layer.title)){
            layer.graphics.items.forEach((item) =>{
              let data = item.attributes;
              let form = data.FormData;
              let formTask;
              if(form && event.target.selectedFeature.attributes.geoGeometry.contains(new Point(form.Longitude, form.Latitude))){
                let formTaskType = form.FormTypeId == 0 ? 2 : form.FormTypeId == 4 ? 5 : 3;
                let now = new Date();
                formTask = form.FormTask;
                if(formTask && formTask.AssignedUserId != assignmentId){
                  formTask.ModifiedBy = auth.employeeId();
                  formTask.ModifiedDate = now;
                  formTask.ModifiedDateUTCOffset = now;
                  formTask.AssignedUserId = assignmentId ? assignmentId : formTask.AssignedUserId;
                  formTask.FormTaskType = formTaskType;
                  //DONOT DELETE TASK
                  // if(assignmentId == null){
                  //   //Delete Task
                  //   formTask.DeletedDate = now;
                  //   formTask.DeletedDateUTCOffset = -now.getTimezoneOffset() /60;
                  //   formTask.DeletedBy = auth.employeeId();
                  // }
                }
                else if(!formTask){
                  formTask = {
                    FormTaskId : -1,
                    FormTaskType : formTaskType,
                    FormDataId : form.FormDataId,
                    FormId : form.FormId,
                    FormTypeId : form.FormTypeId,
                    CompanyId : auth.companyId(),
                    AssignedUserId : assignmentId,
                    Latitude : form.Latitude,
                    Longitude : form.Longitude,
                    CreatedDate : now,
                    CreatedBy : auth.employeeId(),
                    CreatedDateUTCOffset : -now.getTimezoneOffset() / 60,
                    ModifiedBy : auth.employeeId(),
                    ModifiedDate : now,
                    ModifiedDateUTCOffset : -now.getTimezoneOffset() / 60
                  };
                }

                if(form.FormTypeId == 4){
                  //need to assign all tasks with same ticket number as well.
                  let ticketNumber = form.Fields.find(x => x.Name == "TicketNumber");
                  if(ticketNumber){
                    let sameTicketNumberList = layer.graphics.items.filter(x => x.attributes && x.attributes.FormData && x.attributes.FormData.FormTypeId == 4 && x.attributes.FormData.FormId == form.FormId && x.attributes.FormData.FormDataId != form.FormDataId && x.attributes.FormData.Fields && x.attributes.FormData.Fields.find(x => x.Name == "TicketNumber" && x.Value == ticketNumber.Value));
                    sameTicketNumberList.forEach((ticket) =>{
                      let tData = ticket.attributes;
                      let tForm = tData.FormData;
                      let tFormTask;

                      let tFormTaskType = tForm.FormTypeId == 0 ? 2 : tForm.FormTypeId == 4 ? 5 : 3;
                      let now = new Date();
                      tFormTask = tForm.FormTask;
                      if(tFormTask && tFormTask.AssignedUserId != assignmentId){
                        tFormTask.ModifiedBy = auth.employeeId();
                        tFormTask.ModifiedDate = now;
                        tFormTask.ModifiedDateUTCOffset = now;
                        tFormTask.AssignedUserId = assignmentId ? assignmentId : tFormTask.AssignedUserId;
                        tFormTask.FormTaskType = tFormTaskType;
                        //DONOT DELETE TASK
                        // if(assignmentId == null){
                        //   //Delete Task
                        //   tFormTask.DeletedDate = now;
                        //   tFormTask.DeletedDateUTCOffset = -now.getTimezoneOffset() /60;
                        //   tFormTask.DeletedBy = auth.employeeId();
                        // }
                      }
                      else if(!tFormTask){
                        tFormTask = {
                          FormTaskId : -1,
                          FormTaskType : tFormTaskType,
                          FormDataId : tForm.FormDataId,
                          FormId : tForm.FormId,
                          FormTypeId : tForm.FormTypeId,
                          CompanyId : auth.companyId(),
                          AssignedUserId : assignmentId,
                          Latitude : tForm.Latitude,
                          Longitude : tForm.Longitude,
                          CreatedDate : now,
                          CreatedBy : auth.employeeId(),
                          CreatedDateUTCOffset : -now.getTimezoneOffset() / 60,
                          ModifiedBy : auth.employeeId(),
                          ModifiedDate : now,
                          ModifiedDateUTCOffset : -now.getTimezoneOffset() / 60
                        };
                      }
                      if(tFormTask && tFormTask.FormTaskId == -1 || formTasks.findIndex(x => x.FormTaskId == tFormTask.FormTaskId) == -1){
                        formTasks.push(tFormTask)
                      }
                    });
                  }
                }
              }
              else if(event.target.selectedFeature.attributes.geoGeometry.contains(new Point(data.Longitude, data.Latitude))){
                let formTaskType = data.Type == "Survey" ? 2 : 3;
                let now = new Date();
                formTask = data.FormTask;
                if(formTask && formTask.AssignedUserId != assignmentId){
                  formTask.ModifiedBy = auth.employeeId(),
                  formTask.ModifiedDate = now;
                  formTask.ModifiedDateUTCOffset = now;
                  formTask.AssignedUserId = assignmentId ? assignmentId : formTask.AssignedUserId;
                  formTask.FormTaskType = formTaskType;
                  // DONOT DELETE TASK
                  // if(assignmentId == null){
                  //   Delete Task
                  //   formTask.DeletedDate = now;
                  //   formTask.DeletedDateUTCOffset = - now.getTimezoneOffset() / 60;
                  //   formTask.DeletedBy = auth.employeeId();
                  // }
                }
                else if(!formTask){
                  formTask = {
                    FormTaskId : -1,
                    FormTaskType : formTaskType,
                    FormTypeId : data.Type == "Survey" ? 0 : 1,
                    CompanyId : auth.companyId(),
                    AssignedUserId : assignmentId,
                    Latitude : data.Latitude,
                    Longitude : data.Longitude,
                    CreatedDate : now,
                    CreatedBy : auth.employeeId(),
                    CreatedDateUTCOffset : -now.getTimezoneOffset() / 60,
                    ModifiedBy : auth.employeeId(),
                    ModifiedDateUTCOffset : -now.getTimezoneOffset() / 60,
                    ModifiedDate : now,
                    SiteSurveyId : data.Type == "Survey" ? data.SiteSurveyId : null,
                    InvestigationId : data.Type == "Survey" ? null : data.InvestigationId
                  }
                }
              }
              if(formTask && (formTask.FormTaskId == -1 || formTasks.findIndex(x => x.FormTaskId == formTask.FormTaskId) == -1)){
                formTasks.push(formTask);
              }
            });
          }
        });


        if(formTasks.length == 0) alert("All selected tasks are already assigned to this user.");
        else{
          me.assignTaskCollection(assignmentId, formTasks);
          view.popup.close();
        }
      }

      function unassignAllSelectedTasks(context, event){
        var assignmentSelect = document.getElementById(assignmentSelect);
        var assignmentId = "-1";
        var formTasks = [];
        view.map.layers._items.forEach((layer)=>{
          if(layer.graphics && (layer.title != null && layer.title != 'Graphics' && layer.title != 'Assignment Areas' && layer.title != "Ticket Areas" && layer.title != "User Locations") && me.getCachedLayerVisibility(layer.title)){
            layer.graphics.items.forEach((item)=>{
              let data = item.attributes;
              if(data.FormData && event.target.selectedFeature.attributes.geoGeometry.contains(new Point(data.FormData.Longitude, data.FormData.Latitude)) && data.FormData.FormTask){
                let ticketNumber = data.FormData.Fields.find(x => x.Name == "TicketNumber");
                if(ticketNumber){
                  let sameTicketNumberList = layer.graphics.items.filter(x => x.attributes && x.attributes.FormData && x.attributes.FormData.FormTypeId == 4 && x.attributes.FormData.FormId == data.FormData.FormId && x.attributes.FormData.FormDataId != data.FormData.FormDataId && x.attributes.FormData.Fields && x.attributes.FormData.Fields.find(x => x.Name == "TicketNumber" && x.Value == ticketNumber.Value));
                  sameTicketNumberList.forEach((ticket) =>{
                    let tData = ticket.attributes;
                    if(tData.FormData && tData.FormData.FormTask && formTasks.findIndex(x => x.FormTaskId == tData.FormData.FormTask.FormTaskId) == -1){
                      formTasks.push(tData.FormData.FormTask);
                    }
                  });
                }
                if(formTasks.findIndex(x => x.FormTaskId == data.FormData.FormTask.FormTaskId) == -1)formTasks.push(data.FormData.FormTask);
              }
              else if(event.target.selectedFeature.attributes.geoGeometry.contains(new Point(data.Longitude, data.Latitude)) && data.FormTask){
                formTasks.push(data.FormTask);
              }
            });
          }
        });
        if(formTasks.length == 0) alert('All selected tasks are already unassigned.');
        else{
          formTasks.forEach(task =>{
            let now = new Date();
            task.ModifiedDate = now;
            task.ModifiedDateUTCOffset = -now.getTimezoneOffset() /60;
            task.ModifiedBy = auth.employeeId();
            //Changed to set assigned userid to null and keep task not deleted. 
            task.AssignedUserId = null;
            task.AssignedUserDisplayName = null;
            // task.DeletedDateUTCOffset = -now.getTimezoneOffset()/60;
            // task.DeletedBy = auth.employeeId();
          })
          me.assignTaskCollection(assignmentId,formTasks);
          view.popup.close();
        }
      }

      function assignTask(context,event){
        var popupDiv = document.getElementsByClassName("esri-feature__main-container");
        if(popupDiv && popupDiv.length > 0){
          var buttonText = document.getElementsByClassName("esri-popup__action-text")[0];
          popupDiv = popupDiv[0];
          if(buttonText.innerHTML == "Assign Task"){
            popupDiv.innerHTML = "<div>"
                                 +"<div class='assignmentDiv'>"
                                 +"<div class='assignmentLabel'>Choose User:</div>"
                                 +"<select id='userSelect' class='assignmentSelect'>" + me.state.userOptionList + "</select>"
                                 +"</div>"
                                 +"</div>";
            buttonText.innerHTML ="Assign User"; 
          }
          else if(buttonText.innerHTML == "Assign User"){
            var userId = document.getElementById("userSelect").value;
            me.assignTask(userId, event.target.selectedFeature.attributes, view.popup);
          }
        }
      }

      function unassignTask(event){
        me.unassignTask(event.target.selectedFeature.attributes.FormData ? event.target.selectedFeature.attributes.FormData : event.target.selectedFeature.attributes, view.popup);
      }

      function viewMultimedia(context, event){
        me.setState({loadingMultimedia : true})
        let attr = event.target.selectedFeature.attributes;
        let entityType = attr.CustomData ? 93 : attr.InvestigationId ? 1 : 0;
        let id = entityType == 93 ? attr.FormData.FormDataId : entityType == 1 ? attr.InvestigationId : attr.SiteSurveyId;
        fetch(process.env.API_URL + "/api/entityImages/" + id + "/" + entityType + "/web",{
          method : "POST",
          headers : {"Content-Type" : "application/json", Authorization : "Bearer " + auth.token()}
        })
        .then(r =>{
          if(r.status == 200){
            r.json().then(bodyJson =>{
              if(bodyJson.length > 0){
                let response = JSON.parse(bodyJson);
                response.MultimediaAssets.sort((a,b) =>(a.timeStamp > b.timeStamp) ? 1 : -1);
                let pictures = response.MultimediaAssets.filter(x => x.MediaTypeId == null || x.MediaTypeId == 0);
                let thumbnails = response.MultimediaAssets.filter(x => x.MediaTypeId == 2);
                let images = [];
                let thumbs = [];
                pictures.forEach(asset =>{
                  let srcUrl = process.env.API_URL + "/api/image/" + asset.MultimediaAssetId;
                  images.push({original : srcUrl, thumbnail : srcUrl});
                });
                thumbnails.forEach(thumb =>{
                  let srcUrl = process.env.API_URL + "/api/image/" + thumb.MultimediaAssetId;
                  thumbs.push({original : srcUrl, thumbnail : srcUrl, videoId : thumb.CompareMultimediaAssetId});
                });
                me.setState({images, thumbnails : thumbs, videoCount : thumbs.length, pictureCount : images.length, loadingMultimedia : false});
              }
            });
          }
        });
        document.getElementById("imageGalleryDiv").style.display = 'block';
      }

      function viewLarger(context, event){
        let attr = event.target.selectedFeature.attributes;
        let srcUrl = process.env.API_URL + "/api/image/" + attr.MultimediaAssetId
        me.setState({currentFullscreenImage : [{original : srcUrl, thumbnail : srcUrl}]},() =>{me._imageGallery.fullScreen();});
      }

      function viewVideo(context,event){
        let attr = event.target.selectedFeature.attributes;
        var videosrcUrl = process.env.API_URL + "/api/video/" + attr.CompareMultimediaAssetId;
        let imageDiv = document.getElementById('image'+attr.MultimediaAssetId);
        imageDiv.innerHTML = "<video loop controls src='" + videosrcUrl + "' style='margin-top : 5px; object-fit: cover; width: 99%; height: 250px;' type='video/mp4'/>";
      }

      function setContentInfo(){
        var node = domConstruct.create("div", { innerHTML:
          "<div class='assignmentDiv'><div class='assignmentLabel'>Choose Action:</div><input id='assignmentlabel' type='text' maxLength='200' style='display:none;' class='assignmentSelect'></input> <select id='assignmentSelect' class='assignmentSelect'>"+
          me.state.actionList + "</select></div>"
        });
        return node;
      }
      
      //LAYER LIST
      var layerList = new LayerList({ view : this.view, selectionEnabled: false, listItemCreatedFunction: this.defineLayerListActions.bind(this)});
      this.view.ui.add(layerList, {position : 'top-right'});
      this.setState({map: map,ticketFeatureLayers, view:view, sketchLayer:sketchLayer, layerAssignmentAreas: layerAssignmentAreas,layerTicketAssignmentAreas : layerTicketAssignmentAreas, layerDigArea : layerDigArea});
      })
      .catch((error) =>{
        console.log(error);
      });
    });

    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition((position) =>{
        this.setState({currentLat : position.coords.latitude, currentLon : position.coords.longitude});
      },() =>{
        this.setState({currentLat : 0, currentLon : 0});
      });
    }
    else{
      this.setState({currentLat : 0, currentLon : 0})
    }
  }

  getAutoAttributePopupContent(feature){
    let attributes = feature.graphic.attributes;
    let content = "<table class='esri-widget__table' summary='List of attributes and values'>";
    Object.keys(attributes).forEach(key =>{
      if(key != "OBJECTID" && key != "Shape" && key != "Shape__Length" && key != "Shape__Area"){
        content += "<tr><th class='esri-feature__field-header'>" + key + "</th><td class='esri-feature__field-data'>"  +  Autolinker.link(attributes[key] ? attributes[key] : "" ) + "</td></tr>"
      }
    });
    content +="</table>";
    return content;
  }

  updateTicketAssignmentArea(label, color, assignmentId, deleteAssignment){
    if(auth.isSuperViewer()){
      this.props.showProgressBar('');
      return;
    }
    if(!deleteAssignment && (!label || label == "")){
      this.props.setAlert("warning", "Label must have value.");
      return;
    }
    if(!deleteAssignment && (label && label.includes(","))){
      this.props.setAlert("warning", "Label cannot have a comma in it.");
      return;
    }
    if(!deleteAssignment && assignmentId == -1 && !GLOBAL.ASSIGNMENT.geometry){
      this.props.setAlert("warning", "Area must have geometry.");
      return;
    }

    let me = this;
    this.props.showProgressBar("Saving Update...");
    let now = new Date();
    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      Geometry: GLOBAL.ASSIGNMENT.geometry,
      Label: label,
      Color : color,
      AssignmentAreaId: assignmentId,
      DeleteAssignmentArea: deleteAssignment,
      UTCOffset: - now.getTimezoneOffset() / 60
    };

    fetch(process.env.API_URL + "/api/assignmentAreaUpdate",{
      method: "POST",
      headers:{
        Accept:"application/json",

        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(bodyJson =>{
          if(bodyJson.length > 0){
            let json = JSON.parse(bodyJson);
            if(json.AssignmentArea){
              this.props.updateResultsFromAssignmentArea(json.AssignmentArea);
            }
            this.props.showProgressBar("");
            this.props.setAlert("success", "Ticket Assignment Area updated.");
            this.updateExtent = false;
            setTimeout(() => {this.rerenderMap(this._ASSIGNMENT_AREA_REFRESH);},1000);
          }
        })
      }
      else{
        this.props.showProgressBar("");
        this.props.setAlert("error", "Error updating Ticket Assignemnt Area");
      }
    })
    .catch(error =>{
      console.log(error.message);
      this.props.showProgressBar("");
        this.props.setAlert("error", "Error updating Ticket Assignemnt Area");
    });

    me.state.sketchLayer.remove(GLOBAL.ASSIGNMENT.graphic);
    GLOBAL.ASSIGNMENT.graphic = null;
    GLOBAL.ASSIGNMENT.geometry = null;
  }

  updateAssignmentArea(assignmentUserId, username, userAssignmentId, deleteAssignment){
    if(auth.isSuperViewer()){
      this.props.showProgressBar('');
      return;
    }
    let me = this;
    this.props.showProgressBar("Saving Update...");
    let now = new Date();
    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      Geometry: GLOBAL.ASSIGNMENT.geometry,
      AssignedUserId: assignmentUserId,
      UserAssignmentId: userAssignmentId,
      DeleteAssignmentArea: deleteAssignment,
      UTCOffset: - now.getTimezoneOffset() / 60
    };

    fetch(process.env.API_URL + "/api/userAssignmentUpdate",{
      method: "POST",
      headers:{
        Accept:"application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(bodyJson =>{
          if(bodyJson.length > 0){
            let json = JSON.parse(bodyJson);
            if(json.UserAssignment){
              this.props.updateResultsFromUserAssignment(json.UserAssignment);
            }
            this.props.showProgressBar("");
            this.props.setAlert("success", "Assignment Area updated.");
            this.updateExtent = false;
            setTimeout(() =>{this.rerenderMap(this._USER_ASSIGN_REFRESH);},1000);
          }
        });
      }
      else{
        this.props.showProgressBar("");
        this.props.setAlert('error','Error updating assignment area');  
      }
    })
    .catch(error =>{
      console.log(error.message);
      this.props.showProgressBar("");
      this.props.setAlert('error','Error updating assignment area');  
    });

    me.state.sketchLayer.remove(GLOBAL.ASSIGNMENT.graphic);
    GLOBAL.ASSIGNMENT.graphic = null;
    GLOBAL.ASSIGNMENT.geometry = null;
  }

  assignTask(userId, entity, popup, fromAssignTask){
    if(auth.isSuperViewer()){
      if(popup) popup.close();
      return;
    }
    let form = entity.FormData;
    let formTask;
    this.props.showProgressBar("Assigning Task");
    if(form){
      if(!fromAssignTask && form.FormTypeId == 4){
        //Need to assign all tasks with formdata with same ticket number as well. 
        let ticketNumber = form.Fields.find(x => x.Name =="TicketNumber");
        if(ticketNumber){
          let sameTicketNumberList = this.props.resultSet.filter(x => x.FormData && x.FormData.FormTypeId == 4 && x.FormData.FormId == form.FormId &&  x.FormData.FormDataId != form.FormDataId &&  x.FormData.Fields && x.FormData.Fields.find( x=> x.Name == "TicketNumber" && x.Value == ticketNumber.Value));
          sameTicketNumberList.forEach((form) =>{
            this.assignTask(userId, form, popup, true);
          });
        }
      }
      let formTaskType = form.FormTypeId == 0 ? 2 : form.FormTypeId == 4 ? 5 : 3;
      let now = new Date();
      formTask = form.FormTask;
      if(formTask){
        formTask.ModifiedBy = auth.employeeId();
        
        //if userId is null keep id the same but delete task.
        formTask.AssignedUserId = userId ? userId : formTask.AssignedUserId;
        formTask.AssignedUserDisplayName = userId ? formTask.AssignedUserDisplayName : null
        formTask.FormTaskType = formTaskType;
        //DONOT DELETE TASK
        // if(userId == null){
        //   //Delete Task
        //   formTask.DeletedDate = now;
        //   formTask.DeletedDateUTCOffset = -now.getTimezoneOffset() / 60;
        //   formTask.DeletedBy = auth.employeeId();
        // }
      }
      else{
        formTask = {
          FormTaskId : -1,
          FormTaskType : formTaskType,
          FormDataId : form.FormDataId,
          FormId : form.FormId,
          FormTypeId : form.FormTypeId,
          CompanyId : auth.companyId(),
          AssignedUserId : userId,
          Latitude : form.Latitude,
          Longitude : form.Longitude,
          CreatedDate : now,
          CreatedBy : auth.employeeId(),
          CreatedDateUTCOffset : - now.getTimezoneOffset() / 60,
          ModifiedBy : auth.employeeId(),
          ModifiedDate : now,
          ModifiedDateUTCOffset : - now.getTimezoneOffset() / 60,
        };
      }
    }
    else{
      let formTaskType = entity.Type == "Survey" ? 2 : 3;
      let now = new Date();
      formTask = entity.FormTask;
      if(formTask){
        formTask.ModifiedBy = auth.employeeId();
        formTask.AssignedUserId = userId ? userId : formTask.AssignedUserId;
        formTask.FormTaskType = formTaskType;
        //DO NOT DELETE TASK
        // if(userId == null){
        //   //Delete Task
        //   formTask.DeletedDate = now;
        //   formTask.DeletedDateUTCOffset = -now.getTimezoneOffset() / 60;
        //   formTask.DeletedBy = auth.employeeId();
        // }
      }
      else{
        formTask = {
          FormTaskId : -1,
          FormTaskType : formTaskType,
          FormTypeId : entity.Type == "Survey" ? 0 : 1,
          CompanyId : auth.companyId(),
          AssignedUserId : userId,
          Latitude : entity.Latitude,
          Longitude : entity.Longitude,
          CreatedDate : now,
          CreatedBy : auth.employeeId(),
          CreatedDateUTCOffset : -now.getTimezoneOffset() / 60,
          ModifiedBy : auth.employeeId(),
          ModifiedDate : now,
          ModifiedDateUTCOffset : -now.getTimezoneOffset() / 60,
          SiteSurveyId : entity.Type == "Survey" ? entity.SiteSurveyId : null,
          InvestigationId : entity.Type == "Survey" ? null : entity.InvestigationId
        }
      }
    }
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      FormTask : formTask
    }
    fetch(process.env.API_URL + "/api/formTaskAssignment/save",{
      method : "POST",
      headers : {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer "+ auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(bodyJson =>{
          let json = JSON.parse(bodyJson);
          if(json.FormTask){
            this.props.updateResultsFromAssignment(json.FormTask);
          }
          this.props.showProgressBar("");
          this.props.setAlert("success", "Task " + ( userId ? "Assigned" : "Unassigned"));
          this.updateExtent = false;
          setTimeout(() => {this.setState({updatingAssignment: false}); this.rerenderMap(this._FORM_TASK_ASSIGN_REFRESH);}, 100);
        })
      }
      else{
        this.props.showProgressBar("");
        this.props.setAlert('error','Error '+ ( userId ? 'assigning' : 'unassigning'  )+ ' task.');  
      }
    }).catch(error =>{
      this.props.showProgressBar("");
      this.props.setAlert('error','Error '+ ( userId ? 'assigning' : 'unassigning'  )+ ' task.');
      console.log(error.message);
    });
    if(popup)popup.close();
  }

  unassignTask(formData, popup){
    if(auth.isSuperViewer()){
      popup.close();
      return;
    }
    this.props.showProgressBar("Unassigning Task");
    let now = new Date();
    let formTask = formData.FormTask
    formTask.AssignedUserId = null;
    //CHANGED TO Keep task undeleted with assignedUserId = null;
    // formTask.DeletedDateUTCOffset = -now.getTimezoneOffset() / 60;
    // formTask.DeletedBy = auth.employeeId();
    formTask.ModifiedDateUTCOffset = -now.getTimezoneOffset() / 60;
    formTask.ModifiedDate = now;
    formTask.ModifiedBy = auth.employeeId();
    let taskList = [formTask]
    if(formData.FormTypeId == 4){
      //Need to assign all tasks with formdata with same ticket number as well. 
      let ticketNumber = formData.Fields.find(x => x.Name =="TicketNumber");
      if(ticketNumber){
        let sameTicketNumberList = this.props.resultSet.filter(x => x.FormData && x.FormData.FormTypeId == 4 && x.FormData.FormId == formData.FormId &&  x.FormData.FormDataId != formData.FormDataId &&  x.FormData.Fields && x.FormData.Fields.find( x=> x.Name == "TicketNumber" && x.Value == ticketNumber.Value));
        sameTicketNumberList.forEach((form) =>{
          let associatedTask = form.FormData.FormTask;
          associatedTask.AssignedUserId = null;
          //CHANGED TO Keep task undeleted with assignedUserId = null;
          // associatedTask.DeletedDateUTCOffset = -now.getTimezoneOffset() / 60;
          // associatedTask.DeletedBy = auth.employeeId();
          associatedTask.ModifiedDateUTCOffset = -now.getTimezoneOffset() / 60;
          associatedTask.ModifiedDate = now;
          associatedTask.ModifiedBy = auth.employeeId();
          taskList.push(associatedTask);
        });
      }
    }

    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      FormTaskList: taskList
    }

    fetch(process.env.API_URL + "/api/formTaskAssignment/unassign",{
      method : "POST",
      headers: {
        Accept : "appliction/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(bodyJson =>{
          let json = JSON.parse(bodyJson);
          if(json.FormTaskList){
            this.props.updateResultsFromTaskUnassign(json.FormTaskList);
          }
          this.props.showProgressBar("");
          this.props.setAlert('success','Task Unassigned');
          this.updateExtent = false;
          setTimeout(() =>{this.setState({updatingAssignment: false}); this.rerenderMap(this._ASSIGNTASK_REFRESH);},1000);
        });
      }
      else{
        this.props.showProgressBar("");
        this.props.setAlert('error','Error unassigning task.');  
      }
    })
    .catch(error =>{
      this.props.showProgressBar("");
      this.props.setAlert('error','Error unassigning task.');
      console.log(error.message);
    });
    popup.close();
  }

  openDetailsView(){
    this.setState({viewingDetails : !this.state.viewingDetails});
  }

  viewEntity(element, type){
    if(type =="form"){
      let formData = JSON.parse(JSON.stringify(element));
      this.setState({viewingDetails: true, entityToView: {FormData: formData, FormTask : formData.FormTask} });
    }
    else if(type == "survey"){
      this.setState({viewingDetails: true, entityToView: element});
    }
    else if(type == "investigation"){
      this.setState({viewingDetails: true, entityToView: element});
    }
  }

  componentDidUpdate(prevProps,prevState){
    //console.debug('componentDidUpdate', prevProps, prevState);

    if(this.state.ticketFeatureLayers){
      this.state.ticketFeatureLayers.forEach(layer =>{
        layer.definitionExpression = this.getTicketAreaExpression(layer);
      })
    }
    let multiMediaUpdate = false
    multiMediaUpdate = prevState.videoIndex != this.state.videoIndex || prevState.loadingVideos != this.state.loadingVideos || prevState.loadingMultimedia != this.state.loadingMultimedia || prevState.images != this.state.images || prevState.videos != this.state.videos || prevState.thumbnails != this.state.thumbnails || prevState.videoCount != this.state.videoCount || prevState.pictureCount != this.state.pictureCount;
    
    //NO RESULT do not need a data refresh
    if (this.props.resultSet != undefined && 
      this.props.resultSet != null && 
      this.props.resultSet.length == 0 || 
      this.props.resultSet == null ||
      this.props.refresh == false || multiMediaUpdate) { 
      return; 
    }
    
    if(!this.isFirstRerender && this.props.resultSet == this.prevResultSet && this.firstLoadComplete) return;
    this.prevResultSet = this.props.resultSet;
    this.isFirstRerender = false;
    

    if(!this.rerenderingMap) this.rerenderMap(this._DIDUPDATE_REFRESH);
    if((this.props.filterUserValue != prevProps.filterUserValue) || (this.props.selectedTicketAreaFeatures != prevProps.selectedTicketAreaFeatures ) || (this.props.filterTicketAssignmentAreasValue != prevProps.filterTicketAssignmentAreasValue)) this.rerenderUserLocations();
  }

  rerenderMap(option){
    
    console.debug('rerenderMap', option);

    //RESIZE force the map to redraw within the window size
    this.rerenderingMap = true;
    window.resize();

    loadModules(['esri/Graphic', 'esri/geometry/Extent', 'esri/layers/GraphicsLayer', 'dojo/dom-construct']).then(([Graphic, Extent, GraphicsLayer, domConstruct]) => {
      
      // ACTIONS
      var detailsPage = { title: 'Details', id: "viewDetails", className: "esri-icon-attachment"};
      var digAreaAction = { title: 'Dig Area', id: "viewDigArea", className: "esri-icon-polygon"};
      var viewMultimedia = {title: 'Multimedia', id : "viewMultimedia", className: "esri-icon-media"};
      var assignTaskAction = {title: 'Assign Task', id: 'assignTask', className : "esri-icon-authorize"}; 
      var unassignTaskAction = {title: 'Unassign', id:"unassignTask", className: 'esri-icon-rotate'};
      var delAction = {title: "Erase", id: "del", className: "esri-icon-erase"};
      var delTicketAreaAction = {title: "Erase", id: "delTicketArea", className: "esri-icon-erase"};
      var updateLabelAction = {title : "Update Label & Color", id: "updateLabel", className:"esri-icon-edit"}
      var delSurvey = {title: "Archive", id : "archive", className: "esri-icon-trash"}
      var xmax = -180, xmin = 180, ymax = -90, ymin = 90;
      var userOptionCollection = [];
      var surveyGraphics = {0:[],1:[]}
      var newgraphic = null;
      var assignmentGraphics = [];
      var ticketAssignmentAreaGraphics = [];
      var digAreaGraphics = [];
      let me = this;
      var filterUserValue = -1;
      var filterStatusValue = -1;
      var filterDueDateValue = -1;
      var filterTicketTypeValue = -1;
      var graphicAdded = false;
      this.legendStyle['Assignment Areas'] = "#de9232";
      this.legendStyle['Ticket Areas'] = '#d8de32';
      this.legendStyle['DueToday'] =  '#1703fc';
      this.legendStyle['PastDue'] =  '#fc0303';
      this.legendStyle['DueInAwhile'] = '#000000';
      this.legendStyle['Emergency'] =  '#fc7b03';

      //FILTER USER AND STATUS
      if(document.getElementById("filterStatus")){
        filterUserValue = document.getElementById("filterUser").value;
        filterStatusValue = document.getElementById("filterStatus").value;
        if(document.getElementById("filterDueDate"))filterDueDateValue = document.getElementById("filterDueDate").value;
        if(document.getElementById("filterTicketType"))filterTicketTypeValue = document.getElementById("filterTicketType").value;
      }

      const filterUserValueFromStorage = sessionStorage.getItem("searchStripFilterUserValue");

      var userOptionList = "<option value='-1'></option>";
      var actionList = "<option value='-1'></option>"
                       + "<option value='assignArea'>Assign User Area</option><option value='assignTicketArea'>Assign Ticket Area</option><option value='assignAllTasks'>Assign All Tasks</option><option value='unassignAllTasks'>Unassign All Tasks</option>";
      if(me.state.users && me.state.users.length > 0){
        me.state.users.forEach(user =>{
          var inSelectedAreaOrFeature = me.isUserInSelectedAreaOrFeature(user);
          
          if((inSelectedAreaOrFeature) && (this.state.trialMode || (user.LicenseIdCollection && (user.LicenseIdCollection.includes("site") || user.LicenseIdCollection.includes("investigation"))))){
            let userOption = "<option value='" + user.UserId+"'>" + user.DisplayNameProper + "</option>"
            let newUserOptionElement = document.createElement("option");
            newUserOptionElement.text = user.DisplayNameProper;
            newUserOptionElement.value = user.UserId;
            newUserOptionElement.select = user.UserId == filterUserValueFromStorage;
            userOptionList += userOption;
            userOptionCollection.push(newUserOptionElement);
          }
        });
      }
      me.state.userOptionList = userOptionList;
      me.state.actionList = actionList;
      var filterUser = document.getElementById("filterUser");
      if(filterUser){
        let firstOption = filterUser.options[0];
        var i, L = filterUser.options.length - 1;
        for(i = L; i >= 0; i--) {
          filterUser.remove(i);
        }
        filterUser.options.add(firstOption);
        var unassigned = document.createElement("option");
        unassigned.text = "* Unassigned";
        unassigned.value = "unassigned";
        unassigned.select = filterUserValueFromStorage == "unassigned";
        filterUser.options.add(unassigned);
      }
      //This seems like it could go in the same if as the unassigned option. But if it is the user options are never added for some reason. 
      if(filterUser && filterUser.options.length <= 2){
        userOptionCollection.forEach(userOption => {filterUser.options.add(userOption);});
      }
      
      //VIEW and CLUSTER CONFIG
      let v = this.view;
      if(v){
        v._clusterTolerance = 1;
        v._clusterData = [];
        v._clusters = [];
        v._singles = [];
        v.surveyGraphics = surveyGraphics;
        v._clusterSizeMax = 999;
        v._resolutionFactor = 2000;
        v._clusterCountDisplayMin = 20;
      }
      if(this.view){
        this.view.cluster_expandCluster = function cluster_expandCluster(c, ref){
          //label click - find realted cluster and use to expand
          var c2; if(c.attributes.clusterId && !c.singles){
            c2 = ref._clusters.find(e => (e.attributes.clusterId == c.attributes.clusterId));
            if(c2){c = c2;}
          }

          //show singles - record zoom level to ensure it doesn't recluster until zoomed out
          c.singles.forEach(s => {s.attributes.zoom=ref.zoom;surveyGraphics[c.EntityTypeId].push(s);});

          //remove cluster & label
          surveyGraphics[c.EntityTypeId].forEach(ec => {
            if(ec.attributes && ec.attributes.clusterId == c.attributes.clusterId && ec.attributes.clusterCount > 1) ec.visible = false;
          });
          Object.keys(surveyGraphics).forEach(survey =>{
            if(!ref.layerDictionary[survey]){
              let element = surveyGraphics[survey][0].attributes;
              var layer = new GraphicsLayer({title: element.FormData.Name});
              ref.layerDictionary[survey] = {layer};
              this.mapLayerType.push((typeof(survey) == "string") ? parseInt(survey) : survey);
              this.legendStyle[survey + 'C'] = element.FormData.Color ? element.FormData.Color :( element.FormData.FormTypeId == 0 ? this.legendStyle[0] : this.legendStyle[1]); 
              this.markerStyle[element.FormData.Name] = element.FormData.MarkerSymbol ? element.FormData.MarkerSymbol : 'default';
              this.legendStyle[element.FormData.Name] = element.FormData.Color ? element.FormData.Color : (element.FormData.FormTypeId == 0 ? this.legendStyle[0] : this.legendStyle[1]); 
              this.map.layers.add(layer);
            }
          });

          //update layer graphics
          ref.mapLayerType.forEach(surveyType =>{
            ref.layerDictionary[surveyType].layer.graphics = surveyGraphics[surveyType];
          });
        }

        // ** CLUSTER GRAPHICS
        this.view.cluster_clusterGraphics = function cluster_clusterGraphics(ref){
          let addedLayer = false;
          //ALL DATA create clusters and singles
          for(var j = 0, jl = ref._clusterData.length; j < jl; j++){
            var p = ref._clusterData[j];
            var clustered = false;
            var inExtent = p.geometry.x < ref.extentGeo.xmax && p.geometry.x > ref.extentGeo.xmin && p.geometry.y < ref.extentGeo.ymax && p.geometry.y > ref.extentGeo.ymin;
            if(!inExtent) continue; //not in the extent, continue without drawing feature
            //ADD FEATURE TO EXISTING CLUSTER
            for(var i = 0; i < ref._clusters.length; i++){
              var c = ref._clusters[i];
              //POINT check if its in a CLUSTER RANGE
              if(cluster_clusterTest(p, c, ref) && c.attributes.clusterCount < ref._clusterSizeMax && c.EntityTypeId == p.attributes.EntityTypeId){
                cluster_clusterAddPoint(p, c);
                clustered = true;
                break;
              }
            }

            // create new cluster
            if(!clustered){
              cluster_clusterCreate(p, ref);
            }
          }

          //show all clusters
          ref._clusters.forEach(c => {cluster_showCluster(c, ref);});

          //update layer grpahics
          
          Object.keys(surveyGraphics).forEach(survey =>{
            if(!this.layerDictionary[survey]){
              addedLayer = true;
              let element = surveyGraphics[survey][0].attributes;
              if(!element.FormData){
                element = surveyGraphics[survey][0].singles[0].attributes;
              }
              var layer = new GraphicsLayer({title: element.FormData.Name});
              this.layerDictionary[survey] = {layer};
              this.mapLayerType.push((typeof(survey) == "string") ? parseInt(survey) : survey);
              this.legendStyle[survey] = element.FormData.Color ? element.FormData.Color :( element.FormData.FormTypeId == 0 ? this.legendStyle[0] : this.legendStyle[1]); 
              this.markerStyle[element.FormData.Name] = element.FormData.MarkerSymbol ? element.FormData.MarkerSymbol : 'default';
              this.legendStyle[element.FormData.Name] = element.FormData.Color ? element.FormData.Color : (element.FormData.FormTypeId == 0 ? this.legendStyle[0] : this.legendStyle[1]); 
              this.map.layers.add(layer);
            }
          });
          let featuresList = [];
          //For now only add tickets
          this.mapLayerType.forEach(surveyType => {
            if(surveyGraphics[surveyType]){
              surveyGraphics[surveyType].forEach(graphic =>{
                if(graphic.singles && graphic.singles.length != 0){
                  graphic.singles.forEach(single =>{
                    if(single.attributes && single.attributes.FormData && single.attributes.FormData.FormTypeId == 4){
                      featuresList.push(single.attributes);
                    }
                  })
                }
                else if(graphic.attributes && graphic.attributes.FormData && graphic.attributes.FormData.FormTypeId == 4){
                  featuresList.push(graphic.attributes);
                }
              });
            }
            this.layerDictionary[surveyType].layer.graphics = surveyGraphics[surveyType];
          });
          me.props.updateDataInExtent(featuresList);
          if(addedLayer){
            setTimeout(()=> {
              if (document.getElementsByClassName("esri-layer-list__item-container") != undefined) {
                var listItemCollection = document.getElementsByClassName("esri-layer-list__item-container");
                for (let index = 0; index < listItemCollection.length; index++) {
                  listItemCollection[index].style.borderLeftColor = this.legendStyle[listItemCollection[index].firstElementChild.lastElementChild.innerHTML];
                }
              }
            }, 1000);
            
          }
        }
      } // ** VIEW initialization
      
      //CLUSTER TEST if point is inside cluster range
      function cluster_clusterTest(p, cluster, ref){
        var distance = (Math.sqrt(Math.pow((cluster.x - p.geometry.x), 2) + Math.pow((cluster.y - p.geometry.y), 2)) / ref.resolution * ref._resolutionFactor);
        return (distance <= ref._clusterTolerance);
      }

      //CLUSTER ADD POINT
      function cluster_clusterAddPoint(p, cluster){
        //average in the new point to the cluster geometry
        var count, x, y;
        count = cluster.attributes.clusterCount;
        x = (p.geometry.x + (cluster.x * count)) / (count + 1);
        y = (p.geometry.y + (cluster.y * count)) / (count + 1);
        cluster.x = x;
        cluster.y = y;

        //build an extent that includes all points in a cluster
        //extents are for debug/testing only not used by the layer
        if (p.geometry.x < cluster.attributes.extent[0]){
          cluster.attributes.extent[0] = p.geometry.x;
        } else if (p.x > cluster.attributes.extent[2]){
          cluster.attributes.extent[2] = p.geometry.x;
        }
        if(p.geometry.y < cluster.attributes.extent[1]){
          cluster.attributes.extent[1] = p.geometry.y;
        } else if (p.geometry.y > cluster.attributes.extent[3]){
          cluster.attributes.extent[3] = p.geometry.y;
        }

        //increment the count & add single
        if(!p.attributes.MultimediaAssetId){
          cluster.attributes.clusterCount++;
        }
        cluster.singles.push(p);

        //give the graphic a cluster id
        p.attributes.clusterId = cluster.attributes.clusterId+'s';
      }

      //CREATE CLUSTER
      function cluster_clusterCreate(p, ref){
        var clusterId = ref._clusters.length + 1;
        p.attributes.clusterId = clusterId;
        var cluster = {
          "x" : p.geometry.x,
          "y" : p.geometry.y,
          "attributes" : {
            "clusterCount" : 1,
            "clusterId" : clusterId,
            "extent" : [p.geometry.x, p.geometry.y, p.geometry.x, p.geometry.y]
          },
          "singles" : [p], // add point as first single in cluster
          EntityTypeId : p.attributes.EntityTypeId
        };

        ref._clusters.push(cluster);
      }

      //CREATE CLUSTER GRAPHIC
      function cluster_showCluster(c, ref){
        //show singles - if the cluster has been expanded by user and the map has not been zoomed out
        var singlesExpanded = false;
        c.singles.forEach(s =>{
          if(s.attributes.zoom && s.attributes.zoom <= ref.zoom){
            if(surveyGraphics[c.EntityTypeId]){
              surveyGraphics[c.EntityTypeId].push(s);
            }
            else{
              surveyGraphics[c.EntityTypeId] = [];
              surveyGraphics[c.EntityTypeId].push(s);
            }
            singlesExpanded = true;
          }
          if(s.attributes.zoom && s.attributes.zoom > ref.zoom){
            s.attributes.zoom == null;
          }
        });

        // show singles - instead of cluster if in the extent if there is exactly 1 single feature in the cluster
        if (( c.attributes.clusterCount < ref._clusterCountDisplayMin) || (c.attributes.clusterCount == 1) || (c.attributes.zoom && c.attributes.zoom > ref.zoom)){
          c.singles.forEach(s => {
            if (!s.zoom){
              if(surveyGraphics[c.EntityTypeId]){
                surveyGraphics[c.EntityTypeId].push(s);
              }
              else{
                surveyGraphics[c.EntityTypeId] = [];
                surveyGraphics[c.EntityTypeId].push(s);
              }
          }
          });
        }
        else if(!singlesExpanded) { // if this clusters singles are expanded, do not recluster
          //create cluster
          if(!ref.legendStyle[c.EntityTypeId + "C"]){
            let color = c.singles[0].attributes.FormData.Color;
            ref.legendStyle[c.EntityTypeId + "C"] = color ? color : (c.singles[0].attributes.FormData.Type == "Survey" ? '#176782' : '#5e2828') ;
          }
          var g = new Graphic({ 
            geometry: {type: "point", longitude: c.x, latitude: c.y, spatialReference: { wkid: 4326 }}, 
            symbol: {
              type: "simple-marker", 
              path: me.MarkerUtil.getPath(c.singles[0] && c.singles[0].attributes && c.singles[0].attributes.FormData && c.singles[0].attributes.FormData.MarkerSymbol ? c.singles[0].attributes.FormData.MarkerSymbol : ''), 
              color: ref.legendStyle[c.EntityTypeId + 'C'],
              size: 25,
              outline: {color: [ 222, 222, 222, 0.8 ], width: 1}
            },
            attributes: c.attributes
          });

          //add to graphics collection
          g.singles = c.singles;
          g.EntityTypeId = c.EntityTypeId;
          if(surveyGraphics[c.EntityTypeId]){
            surveyGraphics[c.EntityTypeId].push(g);
          }
          else{
            surveyGraphics[c.EntityTypeId] = [];
            surveyGraphics[c.EntityTypeId].push(g);
          }

          // add label
          var label = new Graphic({ geometry: {type: "point", longitude: c.x, latitude : c.y, spatialReference: { wkid: 4326}},
          symbol: {type: "text", color:"white", text: g.attributes.clusterCount, haloColor : [90,90,90,1], haloSize: "1px", font:{ size: 10, weight: "bold"}}});
          label.attributes = {clusterId : c.attributes.clusterId};
          if(surveyGraphics[c.EntityTypeId]){
            surveyGraphics[c.EntityTypeId].push(label);
          }
          else{
            surveyGraphics[c.EntityTypeId] = [];
            surveyGraphics[c.EntityTypeId].push(label);
          }
        }
      }

      var filteredAreas = [];
      if(this.props.filterTicketAssignmentAreasValue && this.props.filterTicketAssignmentAreasValue.length > 0 && this.props.formDataAssignmentAreas){
        this.props.filterTicketAssignmentAreasValue.forEach(area =>{
          filteredAreas = filteredAreas.concat(this.props.formDataAssignmentAreas.filter(x => x.AssignmentAreaId == area.AssignmentAreaId));
        });
      }
      
      if(this.props.selectedTicketAreaFeatures && this.props.selectedTicketAreaFeatures.length > 0 && this.props.formDataAssignmentAreas){
        this.props.selectedTicketAreaFeatures.forEach(area =>{
          filteredAreas = filteredAreas.concat(this.props.formDataAssignmentAreas.filter(x => x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureObjectId == area.FeatureId));
        });
      }

      let surveyCount = 0;
      let incidentCount = 0;
      console.time('DRAWING');

      this.props.resultSet.forEach(element =>{
        
        graphicAdded = false;
        var timeTillDue = 10000000;
        var dayDue = "DueInAwhile"
        var isEmergency = false;
        let pastDue = false;
        let anyArea = (this.props.filterTicketAssignmentAreasValue && this.props.ticketAssignmentAreas && (this.props.filterTicketAssignmentAreasValue.length == this.props.ticketAssignmentAreas.length ))&& 
          ( this.props.selectedTicketAreaFeatures && (this.props.selectedTicketAreaFeatures.length == this.props.CompanyArcGisLayerFeatures.length || 
          (this.props.selectedTicketAreaFeatures.length == 1 && this.props.selectedTicketAreaFeatures[0] == "FirstLoad")));
        
        // TICKETS
        if(element.FormData && element.FormData.FormTypeId == 4){
          var dueDate = new Date(element.FormData.Fields.find(x => x.Name == "Due Date").Value);
          timeTillDue = (dueDate - new Date()) / 1000
          if(timeTillDue < 0){
            dayDue = "PastDue";
            if(!element.FormData.FormTask || element.FormData.FormTask.FormTaskStatus == 0 || !element.FormData.FormTask.CloseDate)pastDue = true;
          }
          else if(timeTillDue < 86400){
            dayDue = "DueToday";
          }
          var ticketType = "";
          var typeField = element.FormData.Fields.find(x => x.Name == "Ticket Type");
          if(typeField) ticketType = typeField.Value;
          isEmergency = ticketType == "Emergency";
          if(element.FormData.FormTask && element.FormData.FormTask.CloseDate){
            pastDue = ((dueDate - new Date(element.FormData.FormTask.CloseDate)) /1000) < 0;
            //If ticket has closed date, then color black if it was closed on time and red if it was closed past due date. 
            if(!pastDue) dayDue = "DueInAwhile";
            else dayDue = "PastDue";
          }
          
        }

        if((element.Type == "Survey" || element.Type =="Investigation") && this.view){
          if((filterUserValue == -1 || (filterUserValue == "unassigned" && ((element.FormData && (!element.FormData.FormTask || !element.FormData.FormTask.AssignedUserId)) || 
          (!element.FormData && (!element.FormTask || !element.FormTask.AssignedUserId)))) || ((element.FormTask && element.FormTask.AssignedUserId == filterUserValue) || (element.FormData && element.FormData.FormTask && element.FormData.FormTask.AssignedUserId == filterUserValue))) 
          && (filterDueDateValue == -1 || (filterDueDateValue == "PastDue" && pastDue) || (filterDueDateValue == "Today" && timeTillDue > 0 && timeTillDue < 86400) || (filterDueDateValue == "1-Day" && timeTillDue > 86400 && timeTillDue < 172800) || (filterDueDateValue == "2-Days" && timeTillDue > 172800 && timeTillDue < 259200))
          && (filterTicketTypeValue == -1 || (filterTicketTypeValue == "Emergency" && isEmergency) || (filterTicketTypeValue == ticketType))
          && (anyArea || (this.props.formDataAssignmentAreas && element.FormData && filteredAreas.find(x => x.FormDataId == element.FormData.FormDataId)))
          && (filterStatusValue == -1 || (filterStatusValue == "Open" && ((element.FormTask && element.FormTask.FormTaskStatus == 0) || (element.FormData && element.FormData.FormTask && element.FormData.FormTask.FormTaskStatus == 0)))
          || (filterStatusValue == "Closed" && ((element.FormTask && element.FormTask.FormTaskStatus == 1) || (element.FormData && element.FormData.FormTask && element.FormData.FormTask.FormTaskStatus == 1)))
          || (filterStatusValue == "Project" && ((element.FormTask && element.FormTask.FormTaskStatus == 2) || (element.FormData && element.FormData.FormTask && element.FormData.FormTask.FormTaskStatus == 2)))
          || (filterStatusValue == "Canceled" && ((element.FormTask && element.FormTask.FormTaskStatus == 3) || (element.FormData && element.FormData.FormTask && element.FormData.FormTask.FormTaskStatus == 3))))){
            if(element.Type == "Survey"){
              surveyCount++;
            }
            else if(element.Type == "Investigation"){
              incidentCount++;
            }
            if(!this.legendStyle[element.EntityTypeId]){
              if(element.FormData.Color){
                this.legendStyle[element.FormData.Name] = element.FormData.Color;
                this.legendStyle[element.EntityTypeId] = element.FormData.Color;
              }
              else{
                this.legendStyle[element.EntityTypeId] = (element.Type == "Survey" ? this.legendStyle[0] : this.legendStyle[1]);
              }
            }
            if(!this.markerStyle[element.EntityTypeId]){
              this.markerStyle[element.EntityTypeId] = element.FormData.MarkerSymbol ? element.FormData.MarkerSymbol : 'default';
            }
            let isAssigned = element.FormTask || (element.FormData && element.FormData.FormTask);
            newgraphic = new Graphic({ 
              geometry: {type: "point", longitude: element.FormData ? element.FormData.Longitude : element.Longitude, latitude: element.FormData ? element.FormData.Latitude : element.Latitude, spatialReference: { wkid: 4326 }}, 
              symbol: {
                type: "simple-marker", 
                path: this.MarkerUtil.getPath(element.FormData ? element.FormData.MarkerSymbol : ''), 
                //path:"M12 22c0 18 20 40 20 40s20-22 20-40a20 20 0 0 0-40 0zm12 0a8 8 0 1 1 8 8 8 8 0 0 1-8-8z",
                color: this.legendStyle[((element.FormData && element.FormData.FormTypeId == 4) ? (isEmergency ? "Emergency" : dayDue) : element.EntityTypeId)],
                size: 23,
                outline: {color: [ 222, 222, 222, 0.8 ], width: 1}
              },
  
              attributes: element,
              popupTemplate: {
                title: "<b><span class='leakSurveyPopupHeader' style='color:" + this.legendStyle[(element.FormData && element.FormData.FormTypeId == 4 ? (isEmergency ? "Emergency" : dayDue) : element.EntityTypeId)] + "'>" + (element.FormData ? element.FormData.Name :this.SurveyName[element.EntityTypeId]) + "</span></b>",
                actions: isAssigned ? [assignTaskAction,detailsPage,unassignTaskAction, digAreaAction, viewMultimedia, delSurvey] : [assignTaskAction,detailsPage,digAreaAction,viewMultimedia, delSurvey],
                content: setTaskAssignmentInfo,
                outFields:["*"]
             }
            });
            this.view._clusterData.push(newgraphic);
            if(element.FormData && element.FormData.GeometryData && element.FormData.GeometryData.Polygons){
              var polyColor = this.legendStyle[(isEmergency ? "Emergency" : dayDue)];
              polyColor = me.hexToRGB(polyColor);
              //Add transparency
              polyColor.push(.6);
              var newDigAreaGraphic = new Graphic({
                geometry:{type: "polygon", rings: element.FormData.GeometryData.Polygons, spatialReference: {wkid: 4326 }},
                symbol: {type: "simple-fill", style: "solid", color: polyColor, outlilne: {color: polyColor, width:2}},
                attributes: element,
                popupTemplate:{
                  title: "<b><span class='leakSurveyPopupHeader' style='color:" + this.legendStyle[(element.FormData && element.FormData.FormTypeId == 4 ? (isEmergency ? "Emergency" : dayDue) : element.EntityTypeId)] + "'>" + (element.FormData ? element.FormData.Name :this.SurveyName[element.EntityTypeId]) + "</span></b>",
                actions: isAssigned ? [assignTaskAction,detailsPage,unassignTaskAction,viewMultimedia, delSurvey] : [assignTaskAction,detailsPage,viewMultimedia, delSurvey],
                content: setTaskAssignmentInfo,
                outFields:["*"]
                }
              });
              digAreaGraphics.push(newDigAreaGraphic);
            }
            graphicAdded = true;
            var layerDigArea = this.state.layerDigArea;
            if(layerDigArea != undefined){
              layerDigArea.graphics = digAreaGraphics;
              layerDigArea.listMode = 'hide';
            }
          }
          //CALCULATE DATA EXTENT
          //Don't include 0,0 location errors in extent.
          if(element.Longitude != null && element.Longitude != 0 && graphicAdded){
            xmax = xmax < element.Longitude ? element.Longitude : xmax;
            xmin = xmin > element.Longitude ? element.Longitude : xmin;
          }
          if(element.FormData && element.FormData.Longitude != null && element.FormData.Longitude != 0 && graphicAdded){
            xmax = xmax < element.FormData.Longitude ? element.FormData.Longitude : xmax;
            xmin = xmin > element.FormData.Longitude ? element.FormData.Longitude : xmin;
          }
          if(element.Latitude != null && element.Latitude != 0 && graphicAdded){
            ymax = ymax < element.Latitude ? element.Latitude : ymax;
            ymin = ymin > element.Latitude ? element.Latitude : ymin;
          }
          if(element.FormData && element.FormData.Latitude != null && element.FormData.Latitude != 0 && graphicAdded){
            ymax = ymax < element.FormData.Latitude ? element.FormData.Latitude : ymax;
            ymin = ymin > element.FormData.Latitude ? element.FormData.Latitude : ymin;
          }
        }
        else if(element.Type == "AssignmentArea" && element.AssignmentPolygon != null){
          var color = element.Color;
          if(!color || color == ""){
            color = this.state.AssignmentAreaColors[Math.floor(Math.random() * 9)];
            if(element.GroupId != null){
              var AssignmentAreaColors = this.state.AssignmentAreaColors;
              if(AssignmentAreaColors[element.GroupId] == null){
                AssignmentAreaColors[element.GroupId] = color;
                this.setState({AssignmentAreaColors});
              }
              else{
                color = this.state.AssignmentAreaColors[element.GroupId];
              }
            }
          }
          else{
            color = me.hexToRGB(color);
            color.push(.6);//Add transparency
          }

          var newTicketGraphic = new Graphic({
            geometry:{type: "polygon", rings: JSON.parse(element.AssignmentPolygon), spatialReference: {wkid: 4326 }},
            symbol: {type: "simple-fill", style: "solid", color: color, outlilne: {color: [180,120,50,0.9], width:2}},
            attributes: element,
            popupTemplate:{
              title: "<b>{Label}</b>",
              content: setAssignmentAreaInfo,
              actions: [updateLabelAction,delTicketAreaAction],
              outFields:["*"]
            }
          });

          if(newTicketGraphic.geometry != null && newTicketGraphic.geometry.extent != null){
            var ticketpoint = newTicketGraphic.geometry.extent.center;
            var tickettxtSym = {type: 'text', color: 'white', text: element.Label, haloColor: [110,110,110,1], haloSize:"1px", font:{ size:10, weight:"bold"}};
            var ticketlblGra = new Graphic(ticketpoint,tickettxtSym);
            if(this.props.filterTicketAssignmentAreasValue.find(x => x.AssignmentAreaId == element.AssignmentAreaId)){
              ticketAssignmentAreaGraphics.push(newTicketGraphic);
              ticketAssignmentAreaGraphics.push(ticketlblGra);
            }

            // Determine Data Extent
            if(!this.firstLoadComplete){
              if(ticketpoint.longitude != null){
                xmax = xmax < ticketpoint.longitude ? ticketpoint.longitude : xmax;
                xmin = xmin > ticketpoint.longitude ? ticketpoint.longitude : xmin;
              }
              if(ticketpoint.latitude != null){
                ymax = ymax < ticketpoint.latitude ? ticketpoint.latitude : ymax;
                ymin = ymin > ticketpoint.latitude ? ticketpoint.latitude : ymin;
              }
            }

            var layerTicketAssignmentAreas = this.state.layerTicketAssignmentAreas;
            if(layerTicketAssignmentAreas != undefined){
              layerTicketAssignmentAreas.graphics = ticketAssignmentAreaGraphics;
              layerTicketAssignmentAreas.listMode = (ticketAssignmentAreaGraphics.length == 0) ? 'hide' : 'show';
              layerTicketAssignmentAreas.visible = this.firstLoadComplete ? layerTicketAssignmentAreas.visible : false;
            }
          }
        }
        else if(element.Type == "UserAssignment" && element.AssignmentPolygon != null){
          var newGraphic = new Graphic({
            geometry:{type: "polygon", rings: JSON.parse(element.AssignmentPolygon), spatialReference: {wkid: 4326 }},
            symbol: {type: "simple-fill", style: "solid", color: [220,140,40,0.6], outlilne: {color: [180,120,50,0.9], width:2}},
            attributes: element,
            popupTemplate:{
              title: "Assigned: <b>{Username}</b>",
              actions: [delAction],
              outFields:["*"]
            }
          });

          if(newGraphic.geometry != null && newGraphic.geometry.extent != null){
            var point = newGraphic.geometry.extent.center;
            var txtSym = {type: 'text', color: 'white', text: element.Username, haloColor: [110,110,110,1], haloSize:"1px", font:{ size:10, weight:"bold"}};
            var lblGra = new Graphic(point,txtSym);

            // FILTER USER | and add to graphic and label to their collections
            if(filterUserValue == -1 || element.AssignedUserId == filterUserValue){
              assignmentGraphics.push(newGraphic);
              assignmentGraphics.push(lblGra);
            }

            // Determine Data Extent
            if(!this.firstLoadComplete){
              if(point.longitude != null){
                xmax = xmax < point.longitude ? point.longitude : xmax;
                xmin = xmin > point.longitude ? point.longitude : xmin;
              }
              if(point.latitude != null){
                ymax = ymax < point.latitude ? point.latitude : ymax;
                ymin = ymin > point.latitude ? point.latitude : ymin;
              }
            }

            var layerAssignmentAreas = this.state.layerAssignmentAreas;
            if(layerAssignmentAreas != undefined){
              layerAssignmentAreas.graphics = assignmentGraphics;
               layerAssignmentAreas.listMode = (assignmentGraphics.length == 0) ? 'hide' : 'show';
               layerAssignmentAreas.visible = this.firstLoadComplete ? layerAssignmentAreas.visible : false;
            }
          }
        }
      }); console.timeEnd('DRAWING');

      function setAssignmentAreaInfo(feature){
        var innerHTML = "<input id='assignmentlabel' type='text' value='"+ feature.graphic.attributes.Label +"' maxLength='200' class='assignmentSelect'></input>";
        innerHTML += "<br/><input id='assignmentColor' type='color' value='"+ feature.graphic.attributes.Color +"' maxLength='200' class=''></input>";
        var investigationNode = domConstruct.create("div", { innerHTML });
        return investigationNode;
      }

      function setTaskAssignmentInfo(feature){
        var data = feature.graphic.attributes;
        if(data.FormData && data.FormData.Fields){
          let innerHTML = "<table class='esri-widget__table' summary='List of attributes and values'><tbody>";
          for(let i = 0; i < 5 && i < data.FormData.Fields.length; i++){
            let currentField = data.FormData.Fields[i];
            innerHTML += "<tr><th class='esri-feature__field-header'>" + currentField.Name + "</th><td class='esri-feature__field-data'>" + (currentField.Type == "Signature" ? ((currentField.Value && currentField.Value != "") ? "Signed" : "") : Autolinker.link(currentField.Value ? currentField.Value : "")) + "</td></tr>"
          }
          if(data.FormData.FormTask){
            innerHTML += "<tr><th class='esri-feature__field-header'>Assigned User</th><td class='esri-feature__field-data'>"+ (data.FormData.FormTask.AssignedUserDisplayName ? data.FormData.FormTask.AssignedUserDisplayName : '') +"</td></tr>"
            innerHTML += "<tr><th class='esri-feature__field-header'>Status</th><td class='esri-feature__field-data'>"+( data.FormData.FormTask.FormTaskStatus == 0 ? "Open" : data.FormData.FormTask.FormTaskStatus == 1 ?"Closed" : data.FormData.FormTask.FormTaskStatus == 2 ? "Project" :"Canceled")  +"</td></tr>"
          }
          innerHTML += "</tbody></table>"
          var customNode = domConstruct.create("div", { innerHTML });
          return customNode;
        }
        else if (data.EntityTypeId == 0){
          let innerHTML = "<table class='esri-widget__table' summary='List of attributes and values'><tbody><tr>" +
          "<th class='esri-feature__field-header'>Address</th><td class='esri-feature__field-data'>" + (data.Address ? data.Address : "") + "</td></tr><tr>" +
          "<th class='esri-feature__field-header'>Survey Date</th><td class='esri-feature__field-data'>" + (data.CreatedDate ? data.CreatedDate: "") + "</td></tr><tr>" +
          "<th class='esri-feature__field-header'>Ticket Number</th><td class='esri-feature__field-data'>‎" + (data.TicketNumber ? data.TicketNumber : "") + "</td></tr><tr>" +
          "<th class='esri-feature__field-header'>Job Id/Name</th><td class='esri-feature__field-data'>" + (data.JobName ? data.JobName: "") + "</td></tr><tr>" +
          "<th class='esri-feature__field-header'>Comments</th><td class='esri-feature__field-data'>" + Autolinker.link(data.Comments ? data.Comments : "") + "</td></tr>"
          if(data.FormTask){
            innerHTML +="<tr><th class='esri-feature__field-header'>Assigned User</th><td class='esri-feature__field-data'>" + (data.FormData.FormTask.AssignedUserDisplayName ? data.FormData.FormTask.AssignedUserDisplayName : '') +" </td></tr>"
            innerHTML += "<tr><th class='esri-feature__field-header'>Status</th><td class='esri-feature__field-data'>"+ (data.FormTask.FormTaskStatus == 0 ? "Open" : data.FormTask.FormTaskStatus == 1 ?"Closed" : data.FormTask.FormTaskStatus == 2 ? "Project" : "Canceled") +"</td></tr>"
          }
          innerHTML += "</tbody></table>";
          var node = domConstruct.create("div", { innerHTML });
          return node;
        }
        else if (data.EntityTypeId == 1){
          let innerHTML = "<table class='esri-widget__table' summary='List of attributes and values'><tbody><tr>" +
          "<th class='esri-feature__field-header'>Address</th><td class='esri-feature__field-data'>" + (data.Address ? data.Address : "") + "</td></tr><tr>" +
          "<th class='esri-feature__field-header'>Investigation Date</th><td class='esri-feature__field-data'>" + (data.CreatedDate ? data.CreatedDate: "") + "</td></tr><tr>" +
          "<th class='esri-feature__field-header'>Arrival Date and Time</th><td class='esri-feature__field-data'>‎" + (data.ArrivalDateTime ? data.ArrivalDateTime : "") + "</td></tr><tr>" +
          "<th class='esri-feature__field-header'>Right-of-Way</th><td class='esri-feature__field-data'>" + (data.Rightofway ? data.Rightofway: "") + "</td></tr><tr>" +
          "<th class='esri-feature__field-header'>Comments</th><td class='esri-feature__field-data'>" + Autolinker.link(data.Comments ? data.Comments : "") + "</td></tr>"

          if(data.FormTask){
            innerHTML +="<tr><th class='esri-feature__field-header'>Assigned User</th><td class='esri-feature__field-data'>" + (data.FormData.FormTask.AssignedUserDisplayName ? data.FormData.FormTask.AssignedUserDisplayName : '')+" </td></tr>"
            innerHTML += "<tr><th class='esri-feature__field-header'>Status</th><td class='esri-feature__field-data'>"+ (data.FormTask.FormTaskStatus == 0 ? "Open" : data.FormTask.FormTaskStatus == 1 ? "Closed" : data.FormTask.FormTaskStatus == 2 ? "Project" : "Canceled") +"</td></tr>"
          }
          innerHTML += "</tbody></table>";
          var investigationNode = domConstruct.create("div", { innerHTML });
          return investigationNode;
        }
      }

      //COLOR LEGEND LAYERS
      clearInterval(this.colorInterval);
      this.colorInterval = setInterval(()=> this.colorLegendItems(), 1000);

      //REFRESH MAP, VIEW AND LAYERS
      if(this.layerDictionary != undefined && (option != this._DIG_REFRESH)){
        //update layer graphics
        this.view.extent = this.view.extent;
        //update zoom extent
        if(this.props.detailsSurveyLon && this.props.detailsSurveyLat){
          this.view.center = [this.props.detailsSurveyLon,this.props.detailsSurveyLat];
          this.view.zoom = 15;
        }
        else if(me.props.updateMapExtent){
          if(me.props.zoomToExtent){
            if(xmin < 180 && xmax > -180)
              this.view.extent = new Extent({xmin: xmin-0.005, ymin: ymin-0.005, xmax: xmax+0.005, ymax : ymax+0.005, spatialReference: {wkid: 4326}});
            else { this.view.center = [-98, 40]; this.view.zoom = 4;}
          }
          else{
            const mapZoom = sessionStorage.getItem("surveyMapZoom");
            const mapExtent = sessionStorage.getItem("surveyMapExtent");
            if(mapZoom != null && mapExtent != null && mapZoom != "null" && mapExtent != "null"){
              this.view.extent = JSON.parse(mapExtent);
              this.view.zoom = parseInt(mapZoom);
            }
            else{
              this.view.center = [-98,40];
              this.view.zoom = 4;
            }
          }
        }

        // apply header labels and extra controls
        // if (document.getElementsByClassName("esri-sketch-header").length == 0 && document.getElementsByClassName("esri-sketch")[0] != undefined){
        //   var header = document.createElement("div");
        //   header.className = "esri-sketch-header";
        //   header.innerHTML = "ASSIGN TASKS/AREA";
        //   document.getElementsByClassName("esri-sketch")[0].insertBefore(header,document.getElementsByClassName("esri-sketch")[0].childNodes[0]);
        // }
        this.firstLoadComplete = true;
      }

      this.props.setIncidentCount(incidentCount);
      this.props.setSurveyCount(surveyCount);
      this.rerenderingMap = false;
    });
  }

  colorLegendItems(){ 
    console.time('colorLegendItems');
    let companyLayerNames = [];
    if(this.props.CompanyArcGisLayers && this.props.CompanyArcGisLayers.length > 0){
      companyLayerNames = this.props.CompanyArcGisLayers.map(layer => layer.Name);
    }
    if (document.getElementsByClassName("esri-layer-list__item-container") != undefined) {
      var listItemCollection = document.getElementsByClassName("esri-layer-list__item-container");
      for (let index = 0; index < listItemCollection.length; index++) {
        let layerName = listItemCollection[index].querySelector('.esri-layer-list__item-title').innerHTML;
        if(listItemCollection[index].firstElementChild.lastElementChild){
          if(layerName == "User Locations"){
            listItemCollection[index].style.borderLeftColor = this.userLocationColor; 
            if(!listItemCollection[index].innerHTML.includes('<svg ')){
              let marker = document.createElement("div");
              marker.innerHTML = ReactDOMServer.renderToString(<svg className="bi bi-person-fill" viewBox="0 0 16 16" height="25px" width="25px" fill={this.userLocationColor}>
                 <path d={this.userLocationMarkerPath}></path>
                </svg>);
              listItemCollection[index].insertBefore(marker, listItemCollection[index].firstChild);
            }
          } else{
            listItemCollection[index].style.borderLeftColor = this.legendStyle[layerName] || this.state.defaultLayerColor; 
            if(!listItemCollection[index].innerHTML.includes('<svg ') && this.markerStyle[layerName] && !companyLayerNames.includes(layerName)){
              let marker = document.createElement("div");
              marker.innerHTML = ReactDOMServer.renderToString(<svg style={{position : 'relative'}} height="25px" width="25px" fill={this.legendStyle[layerName]}>
                 <path d={this.MarkerUtil.getPath(this.markerStyle[layerName])}></path>
                </svg>);
              listItemCollection[index].insertBefore(marker, listItemCollection[index].firstChild);
            }
          }
        }
      }
    }

    clearInterval(this.colorInterval);

    console.timeEnd('colorLegendItems');
  }

  defineLayerListActions(event){
    var item = event.item;
    if(!item.parent){
      if(!this.uniqueListViewItems.includes(item.title)){
        item.visible = this.getCachedLayerVisibility(item.title);
        this.uniqueListViewItems.push(item.title);
        item.watch("visible", (visible)=>this.setCachedLayerVisibility(item.title, visible));
      }
    }
  }

  getCachedLayerVisibility(title){
    let valueFromStorage = null;
    if(title == "Surveys"){
      valueFromStorage = sessionStorage.getItem("SurveysLayerVisibility");
    }
    if(title == "Investigations"){
      valueFromStorage = sessionStorage.getItem("InvestigationsLayerVisibility");
    }
    return valueFromStorage == null || valueFromStorage == 'undefined' || valueFromStorage == 'true';
  }

  setCachedLayerVisibility(title, visible){
    let button;
    switch(title){
      case 'Surveys':
        button = document.getElementById('surveyBlock');
        break;
      case "Investigations":
        button = document.getElementById('investigationBlock');
        break;
      default:
        break;
    }

    if(button && button.classList.contains('countBlockDisabled') && visible){
      button.classList.remove('countBlockDisabled');
    }
    else if(button && !button.classList.contains('countBlockDisabled') && !visible){
      button.classList.add('countBlockDisabled');
    }

    if(this.props.disableType){
      this.props.disableType(title,visible);
    }
    if(title == "Surveys"){
      sessionStorage.setItem("SurveysLayerVisibility",visible);
    }
    if(title =="Investigations"){
      sessionStorage.setItem("InvestigationsLayerVisibility", visible);
    }
  }

  clickThumbNail(index){
    let id = this.state.thumbnails[index].videoId;
    let videoIndex = this.state.videos.findIndex(x => x.assetId == id);
    if(this.state.videos.length == 0 || videoIndex == -1){
      this.getVideo(id);
    }
    else{
      this.setState({videoIndex});
    }
  }

  getVideo(assetId){
    this.setState({ loadingVideos : true });
    fetch(process.env.API_URL + "/api/video/" + assetId,{
      method :"GET",
      headers : {
        "Content-Type" : "application/json",
        Authorization : "Bearer "+ auth.token()
      },
      signal : this.controller.signal
    })
    .then(r => r.blob())
    .then(blob =>{
      let videos = this.state.videos;
      let vid = URL.createObjectURL(blob);
      videos.push({uri : vid, assetId});
      this.setState({videos, videoIndex : videos.length -1});
      this.setState({loadingVideos : false});
    }).catch(error =>{console.log(error.message); this.setState({ loadingVideos : false})});
  }

  setVideoIndex(direction){
    let index = this.state.videoIndex + direction;
    if(index == -1){
      index = this.state.videos.length -1;
    }
    else if(index == this.state.videos.length){
      index = 0;
    }
    this.setState({videoIndex : index});
  }

  componentWillUnmount(){
    this.beforeunload();
    if(this.view) { this.view.container = null;}
    clearInterval(this.colorInterval);
    if(this.techLocationFetchInterval) clearInterval(this.techLocationFetchInterval);
    this.techLocationFetchInterval = null;
  }

  beforeunload(){
    if(this.view){
      sessionStorage.setItem("surveyMapZoom", this.view.zoom.toString());
      sessionStorage.setItem("surveyMapExtent",JSON.stringify(this.view.extent)); 
    }
  }

  setLayerVisibility(layer, visibility){
    if(this.layerDictionary){
      switch(layer){
        case 'survey':
          if(this.layerDictionary[0] && this.layerDictionary[0].layer){
            this.layerDictionary[0].layer.visible = visibility;
          }
          break;
        case 'invest':
          if(this.layerDictionary[1] && this.layerDictionary[1].layer){
            this.layerDictionary[1].layer.visible = visibility;
          }
          break;
        default : 
          break;
      }
    }
  }
  setTicketToAssign(ticket){
    this.setState({ticketToAssign : ticket});
  }

  render(){
    return(
      <div className="webmap" ref={this.mapRef}>
        {/* <div id="imageGalleryDiv" style={{display : 'none'}}>
          <ImageGallery items={this.state.currentFullscreenImage}
            showFullScreenButton={true}
            showPlayButton={false}
            onScreenChange={fullScreen =>{
              if(fullScreen){
                document.getElementById("imageGalleryDiv").style.display = 'flex';
              }
              else{
                document.getElementById("imageGalleryDiv").style.display = 'none';
              }
            }}
            ref={i => this._imageGallery = i}
          />
        </div> */}
        <Dialog open={this.state.viewingDetails} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth='lg' fullWidth='true' scroll='paper'>
          <DialogContent>
            {(this.state.entityToView && this.state.entityToView.FormData )&& <FormDataPage userCollection={this.state.users} updatefromEditMap={this.updateFromEdit} location={{formData : this.state.entityToView, mapView : true}} closeEntity={() =>{this.openDetailsView()}} fromDashboard={true} hideClose={true}/>}
            {(this.state.entityToView && !this.state.entityToView.FormData && this.state.entityToView.Type =="Survey" )&& <SurveyPage location={{survey : this.state.entityToView}} closeEntity={() =>{this.openDetailsView()}} fromDashboard={true} hideClose={true}/>}
            {(this.state.entityToView && !this.state.entityToView.FormData && this.state.entityToView.Type =="Investigation" )&& <InvestigationPage location={{incident : this.state.entityToView}} closeEntity={() =>{this.openDetailsView()}} fromDashboard={true} hideClose={true}/>}
          </DialogContent>
          <DialogActions>
            <Button className="buttonAction btn btn-primary" onClick={this.openDetailsView} color="primary">
              <label className="buttonText small" style={{margin:0, cursor: 'pointer'}}>Close</label>
            </Button>
          </DialogActions>
        </Dialog>
        <div id="mapFeaturesDiv" className="modal" style={{paddingTop : 0}}>
          <div style={{width: 253, marginTop: 168, position: 'absolute', right: 12}}>
            {/* <span className="close text-right" style={{top: 0, left: '63vw'}} onClick={() =>{document.getElementById("mapFeaturesDiv").style.display = "none"; }}>
              &times;
            </span> */}
            {/* <div className=" labelHeaderBar labelHeaderBarTight noMultiMediaHeader"><label className="labelHeaderName" style={{margin : "0 auto"}}>Map Action</label></div> */}
            <div className="row" style={{background: '#a8a8a8', marginRight: '15px !important'}}>
            <div className="menuDialogMapOption">
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit fullWidth mapMenuButton" onClick={() =>{
                  document.getElementById("mapFeaturesDiv").style.display = 'none';
                  document.getElementById("addressSearchDiv").style.display = 'block';
                }}><SearchIcon style={{marginRight: 8}}/>Search Address</Button>
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit fullWidth mapMenuButton" onClick={() =>{
                  document.getElementById("mapFeaturesDiv").style.display = 'none';
                  this.view.takeScreenshot().then(screenshot =>{
                    const downloadLink = document.createElement('a');
                    document.body.appendChild(downloadLink);
                    downloadLink.href = screenshot.dataUrl;
                    downloadLink.target = '_self';
                    downloadLink.download = "Map.jpeg";
                    downloadLink.click(); 
                  });
                }}><ScreenShareIcon style={{marginRight: 8}}/>Map Export</Button>
                {(!auth.isViewer() || (auth.isAdmin() || auth.isSuperAdmin()) ) && <Button className="buttonAction buttonAccount btn btn-primary btnSubmit fullWidth mapMenuButton mapMenuButton" >Upload Ticket Areas</Button>}
                {(!auth.isViewer() || (auth.isAdmin() || auth.isSuperAdmin()) ) && <input type="file" onChange={this.uploadTicketAreas} accept='.zip' className="form-control-file uploadTicketAreasButton" id="uploadTicketAreas" />}
              </div>
            </div>
          </div>
        </div>
        <div id="addressSearchDiv" className="modal" style={{paddingTop : 0}}>
          <div className="modal-content" style={{width : '50%', marginTop: '200px', maxHeight : "90%"}}>
            <span className="close text-right" style={{top: 0, right: '8px'}} onClick={() =>{document.getElementById("addressSearchDiv").style.display = "none"; }}>
              &times;
            </span>
            <div className=" labelHeaderBar labelHeaderBarTight noMultiMediaHeader"> <label className="labelHeaderName" style={{margin : "0 auto"}}>Find Address and Select to Pin on map</label></div>
            <div className="row">
              <div style = {{margin: '0 auto', marginTop:  10, marginBottom : 15}}>
                <label className="labelHeaderName">ADDRESS: </label>
                <input className="form-control" style={{width : '400px', display : 'inline', marginTop:18}} type={"text"} value={this.state.addressSearchForSearch} onChange={(e) => {this.searchAddress(e, 'addressSearch');}}/>
              </div>
            </div>
            <div style={{marginBottom : 15}}>
              {this.state.addressSuggestions && this.state.addressSuggestions.length > 0 && this.state.addressSuggestions.map((suggestion,index)=>(
              <div key={index} className = "row underline hoverBold" style={{cursor : 'pointer'}} onClick={() =>{this.selectSuggestedAddressToZoom(suggestion)}}>
                <div style={{margin: '0 auto'}}>
                  {suggestion.text}
                </div>
              </div>))}
              </div>
          </div>
        </div>
        <div id="assignTaskDiv" className="modal" style={{paddingTop : 0}}>
          <div className="modal-content" style={{width : '50%', marginTop: '200px', maxHeight : "90%"}}>
            <span className="close text-right" style={{top: 0, right: '8px'}} onClick={() =>{document.getElementById("assignTaskDiv").style.display = "none"; }}>
              &times;
            </span>
            <div className=" labelHeaderBar labelHeaderBarTight noMultiMediaHeader"> <label className="labelHeaderName" style={{margin : "0 auto"}}>Assign Task</label></div>
            <div className="row">
              <div style = {{margin: '0 auto', marginTop:  10, marginBottom : 15}}>
                <label className="labelHeaderName">Choose User: </label>
                <select className="assignmentSelect" value={this.state.assignedUserId} onChange={e =>{this.setState({assignedUserId : e.target.value, assignedUserColor : '#3c5873'})}}>
                  <option></option>
                  {this.state.users && this.state.users.length > 0 && this.state.users.map((user,index) => (this.state.trialMode || (user.LicenseIdCollection && (user.LicenseIdCollection.includes("site") || user.LicenseIdCollection.includes("investigation")))) && (
                    <option key={index} value={user.UserId}>{user.DisplayNameProper}</option>
                  ))}
                </select>
              </div>
            </div>
            <div style={{marginBottom : 15}}>
            <Button className="buttonAction buttonAccount btn btn-primary btnSubmit" style={{position : 'relative', left: 120}} onClick={() =>{if(this.state.assignedUserId && this.state.ticketToAssign){
                this.assignTask(this.state.assignedUserId, this.state.ticketToAssign);
                document.getElementById("assignTaskDiv").style.display = 'none';
              }
              else{
                this.props.setAlert("warning","Select a user to assign.");
              }
              }}>
                  <label style={{margin:0, cursor:'pointer'}} className="buttonText">Assign Task</label>
                </Button>
              </div>
          </div>
        </div>
        <div id ="createTaskDiv" className="modal" style={{paddingTop: 0}}>
          <div className="modal-content modalDialogCustom">
            <span className="close text-right" style={{top: 0, right: "8px"}} onClick={()=>{document.getElementById("createTaskDiv").style.display = "none"; this.props.setCreatingNewTask(false)}}>
              &times;
            </span>
            <div className=" labelHeaderBar labelHeaderBarTight noMultiMediaHeader"> <label className="labelHeaderName" style={{margin : "0 auto"}}>Enter Address Or Select Location, Assign Form and User</label></div>
            <div className="row">
              <div style={{margin: '0 auto', marginTop : 10}}>
                <label className="labelHeaderName" style={{color : this.state.newTaskAddressColor}}>ADDRESS: </label> 
                <input className="form-control" style={{width : 285, display : 'inline'}} type={"text"} value={this.state.addressSearch} onChange={(e) => {this.searchAddress(e, 'taskSearch');}}/>
                <Button title="Click on map to select location" className="buttonAction  btn btn-primary btnSubmit" style={{display: 'inline', margin: 10}} onClick={() =>{this.props.setCreatingNewTask(true); this.setState({foundAddress : "", addressSuggestions : [], addressSearch : ""}); document.getElementById("createTaskDiv").style.display = "none";}}><MapTwoIcon style={{position : 'relative', bottom : 4}}/><label className="buttonText">Map Location</label></Button>
              </div>
            </div>
            {this.state.addressSuggestions && this.state.addressSuggestions.length > 0 && this.state.addressSuggestions.map((suggestion,index)=>(
            <div key={index} className = "row underline hoverBold" style={{cursor : 'pointer'}} onClick={() =>{this.selectSuggestedAddress(suggestion)}}>
              <div style={{margin: '0 auto'}}>
                {suggestion.text}
              </div>
            </div>))}
            {this.state.newTaskLat && this.state.newTaskLon && 
            <div className = "row">
              <div style={{margin: '0 auto', marginBottom : 10}}>
                {this.state.foundAddress && this.state.foundAddress != "" && <label className="labelHeaderName">Found: </label>}
                {this.state.foundAddress} 
                {this.state.foundAddress && this.state.foundAddress != "" && " - "} 
                <label className="labelHeaderName">Latitude: </label>
                {this.state.newTaskLat.toFixed(5)},
                <label className="labelHeaderName">Longitude: </label>
                {this.state.newTaskLon.toFixed(5)}
              </div>
            </div>}
            <div className="row">
              <div style={{margin:'0 auto'}}>
                <label className="labelHeaderName" style={{color : this.state.assignedCustomFormColor}}>CUSTOM FORM: </label>
                <select className ="assignmentSelect" value={this.state.assignedCustomFormId} onChange={e =>{this.setState({assignedCustomFormId: e.target.value, assignedCustomFormColor : '#3c5873', editingNewTask : false},() =>{this.createTask()})}}>
                  <option></option>
                  {this.state.customFormList && this.state.customFormList.length > 0 && this.state.customFormList.map((customForm, index)=>(
                    <option key={index} value={customForm.FormId}>{customForm.Name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div style={{margin:'0 auto'}}>
                <label className="labelHeaderName" style={{marginRight : 20, color : this.state.assignedUserColor}}>ASSIGN USER: </label>
                <select className="assignmentSelect" value={this.state.assignedUserId} onChange={e =>{this.setState({assignedUserId : e.target.value, assignedUserColor : '#3c5873'})}}>
                  <option></option>
                  {this.state.users && this.state.users.length > 0 && this.state.users.map((user,index) => (this.state.trialMode || (user.LicenseIdCollection && (user.LicenseIdCollection.includes("site") || user.LicenseIdCollection.includes("investigation")))) && (
                    <option key={index} value={user.UserId}>{user.DisplayNameProper}</option>
                  ))}
                </select>
              </div>
            </div>
            {this.state.editingNewTask && this.state.newTaskCustomForm && <div className="row">
              <div style={{margin: '0 auto'}}>
                <FormDataPage updateTaskDocuments={(docs) =>{let newTaskCustomForm = this.state.newTaskCustomForm; newTaskCustomForm.docs = docs; this.setState({newTaskCustomForm})}} updateTaskImages={(images) =>{let newTaskCustomForm = this.state.newTaskCustomForm; newTaskCustomForm.images = images; this.setState({newTaskCustomForm})}} location={{formData: {FormData : this.state.newTaskCustomForm}}} fromTaskMap={true} closeEntity={() =>{this.closeEntity()}}/> 
              </div>
            </div>}
            <div className="row" style={{marginTop: 5}}>
              <div style={{margin: '0 auto'}}>
                {this.state.newTaskCustomForm && <Button className='buttonAction buttonAccount btn btn-primary btnSubmit' style={{position : 'relative', left : 110}} onClick={() =>{this.setState({editingNewTask : !this.state.editingNewTask})}}>
                    <label style={{margin:0, cursro: 'pointer'}} className="buttonText">{this.state.editingNewTask ? "Hide" : "Edit"} Data</label>
                </Button>}
                <Button className="buttonAction buttonAccount btn btn-primary btnSubmit" style={{position : 'relative', left: 120}} onClick={() =>{this.createAndAssignTask()}}>
                  <label style={{margin:0, cursor:'pointer'}} className="buttonText">Create Task</label>
                </Button>
              </div>
            </div>
            <div style={{height: 25}}/>
          </div>
        </div>
        <div id = "imageGalleryDiv" className="modal" style={{paddingTop : 0}}>
          <div className = "modal-content" style={{width : '66%', marginTop :(this.state.videoCount == 0 && this.state.pictureCount == 0) || this.state.loadingMultimedia ? "10%" : "3%"}}>
            {this.state.loadingMultimedia ? <div className="barLoaderPortal" style={{width: '75%'}}><BarLoader sizeUnit={"px"} size={200} color={"#095399"} loading={this.state.loadingMultimedia}/></div> : <div>
            <span className = "close text-right" style={{top : 0, left : "63vw"}} onClick = {()=>{document.getElementById("imageGalleryDiv").style.display ="none";this.setState({images : [], videos : [], thumbnails : [], videoCount : 0, pictureCount : 0})}}>
              &times;
            </span>
            {this.state.pictureCount > 0 && <div>
              <div className=" labelHeaderBar labelHeaderBarTight"> <label className="labelHeaderName">Photos ({this.state.pictureCount})</label></div>
              <div className = "row carouselRow">
                <div className="carouselGalleryDiv">
                  <ImageGallery items={this.state.images} 
                    showFullscreenButton={this.state.images.length > 0}
                    showPlayButton={this.state.images.length > 0}
                    ref={i => this._imageGallery = i}
                    />
                  </div>
                </div>
            </div>}
            {this.state.videoCount > 0 && 
            <VideoPanel 
              setVideoIndex={this.setVideoIndex}
              videoIndex={this.state.videoIndex} 
              clickThumbNail={this.clickThumbNail} 
              thumbnails={this.state.thumbnails} 
              loadingVideos={this.state.loadingVideos} 
              videoCount={this.state.videoCount} 
              videos={this.state.videos}
              removeVideo={this.removeVideo}
              hideDelete={true}
            />}
            {(this.state.videoCount == 0 && this.state.pictureCount == 0) &&
              <div className=" labelHeaderBar labelHeaderBarTight noMultiMediaHeader"> <label className="labelHeaderName" style={{margin : "0 auto"}}>No Multimedia</label></div>
            }
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

WebMapViewSurveyInvestigation.propTypes = {
  resultSet : PropTypes.array,
  refresh : PropTypes.bool,
  disableType : PropTypes.func,
  multimediaAssets : PropTypes.array,
  detailsSurveyLon : PropTypes.number,
  detailsSurveyLat : PropTypes.number,
  showProgressBar : PropTypes.func,
  setAlert : PropTypes.func,
  updateResultsFromAssignment : PropTypes.func,
  updateResultsFromTaskUnassign : PropTypes.func,
  searchRequest : PropTypes.func,
  updateResultsFromUserAssignment : PropTypes.func,
  addNewEntityToResults : PropTypes.func,
  creatingNewTask : PropTypes.bool,
  setCreatingNewTask : PropTypes.func,
  statusTextUpdate : PropTypes.func,
  CompanyArcGisLayers : PropTypes.array,
  ARCGISClientSecret : PropTypes.string,
  ARCGISClientId : PropTypes.string,
  updateFromArchive : PropTypes.func
}

export default WebMapViewSurveyInvestigation;