/* eslint react/prop-types: 0 */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ExcavationWorkPerformed = (props) => {
  const [graphData, setGraphData] = React.useState([]);
  const [graphCategories, setGraphCategories] = React.useState([]);
  const [chart, setChart] = React.useState(null);

  const chartCallback = c => setChart(c);

  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  React.useEffect(() => {
    if(props.data){
      let keylist = {};
      let equipmentList = [];
      let workPerformedList = [];
      props.data.forEach(item => {
        // console.log(typeof item.WorkPerfomed);
        if(item.WorkPerformed && !workPerformedList.includes(item.WorkPerformed)) {
          workPerformedList.push(item.WorkPerformed);
        }
        if(item.ExcavationEquipment && !equipmentList.includes(item.ExcavationEquipment)){
          equipmentList.push(item.ExcavationEquipment);
        }
        if(keylist[item.ExcavationEquipment] && item.ExcavationEquipment && item.WorkPerformed){
          // console.log("Values", item.ExcavationEquipment, item.WorkPerformed);
          keylist[item.ExcavationEquipment][item.WorkPerformed] ? 
          keylist[item.ExcavationEquipment][item.WorkPerformed] += 1 :
          keylist[item.ExcavationEquipment][item.WorkPerformed] = 1;
        }else if(item.ExcavationEquipment && item.WorkPerformed){
          // console.log("Values", item.ExcavationEquipment, item.WorkPerformed);
          keylist[item.ExcavationEquipment] = {};
          keylist[item.ExcavationEquipment][item.WorkPerformed] = 1;
        }
      });
      // console.group("Excavation Work Performed Parse");
      // console.groupEnd("Excavation Work Performed Parse");
      let cats = [];
      let setup = {};
      Object.keys(keylist).forEach(key => {
        let item = keylist[key];
        cats.push(key);
        workPerformedList.forEach(subCat => {
          if(setup[subCat]){ 
            setup[subCat].push(item[subCat] ? item[subCat] : 0);
          }
          else{
            setup[subCat] = [];
            setup[subCat].push(item[subCat] ? item[subCat] : 0);
          }
        })
      })
      // console.log("Final Setup : ", setup);
      let finalData = [];
      Object.keys(setup).forEach(series => {
        finalData.push({ name: series, data: setup[series] });
      })
      // console.log("Final Data : ", cats, finalData);
      setGraphCategories(cats);
      setGraphData(finalData);
    }
  }, [props.data])

  return (
    <HighchartsReact 
      containerProps={{ className: "w-100 h-100 border border-dark" }} 
      highcharts={Highcharts} 
      allowChartUpdate={true} 
      callback={chartCallback}
      constructorType="chart"
      options={{
        chart: { type: "column", backgroundColor: "#E9E9EF" },
        title: { text: "Excavation Equipment Count by Work Performed" },
        plotOptions: {
          series: {
            stacking: "normal",
            cursor: "pointer",
            point: {
              events: {
                click: function(event){
                  // console.log("Event: ", event);
                  // event.point.series.name
                  props.filterCallback({ 
                    ExcavationEquipment: event.point.category, 
                    WorkPerformed: event.point.series.name 
                  }, `Showing Investigations with Work Performed: ${event.point.series.name}, and Equipment: ${event.point.category}`);
                }
              }
            }
          }
        },
        xAxis: {
          categories: graphCategories
        },
        yAxis: {
          title: { text: "Damages" },
          allowDecimals: false,
        },
        series: graphData || [],
        credits: { enabled: false },
        legend: { enabled: true }
      }}
    />
  );
}

export default ExcavationWorkPerformed;