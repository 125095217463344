
const DataGroupHelpers = {

  toggleDataGroup(id, checked,dataGroups){
    let foundGroup = dataGroups.find(x => x.DataGroupId == id);
    if(checked){
      let ancestors = this.getAllAncestors(foundGroup, dataGroups);
      ancestors.forEach(groupId =>{
        dataGroups.find(x => x.DataGroupId == groupId).showData = checked;
      });
    }
    else{
      let offspring = this.getAllOffspring(foundGroup);
      offspring.forEach(groupId =>{
        dataGroups.find(x => x.DataGroupId == groupId).showData = checked;
      });
    }
    return dataGroups;
  },

  getAllAncestors(dataGroup, dataGroups){
    let idList = [];
    idList.push(dataGroup.DataGroupId);
    if(dataGroup.ParentId){
      let parent = dataGroups.find(x => x.DataGroupId == dataGroup.ParentId);
      idList = idList.concat(this.getAllAncestors(parent,dataGroups));
    }
    return idList;
  },

  getAllOffspring(dataGroup){
    let idList = [];
    idList.push(dataGroup.DataGroupId);
    if(dataGroup.children){
      dataGroup.children.forEach(child =>{
        idList = idList.concat(this.getAllOffspring(child));
      });
    }
    return idList;
  },

  getDataGroups(UserId, CompanyId, token){
    let body ={
      UserId,
      CompanyId
    }
    return fetch(process.env.API_URL + "/api/account/getDataGroups",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "applicaiton/json",
        Authorization : "Bearer " + token
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        return r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.Groups){
              let dataGroups = json.Groups;
              let idMapping = dataGroups.reduce((acc, el, i) =>{
                acc[el.DataGroupId] = i;
                return acc;
              }, {});
              dataGroups.forEach(group =>{
                group.expanded = true;
                group.showData = true;
                if(!group.ParentId){
                  group.isRoot = true;
                  return;
                }
                let parent = dataGroups[idMapping[group.ParentId]];
                if(parent)parent.children = [...(parent.children || []), group];
              });
              return dataGroups;
            }
          }
        });
      }
    });
  },

  expandGroup(expanded, dataGroupId, dataGroups){
    let foundGroup = dataGroups.find(x => x.DataGroupId == dataGroupId);
    foundGroup.expanded = !expanded;
    return dataGroups;
  }

} 

export { DataGroupHelpers as default }