import React from 'react';
import PropTypes from 'prop-types';
import { Button } from "react-bootstrap";
import { BarLoader } from 'react-spinners';
import SaveSharpIcon from '@material-ui/icons/SaveTwoTone';
import auth from '../common/Auth';
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert';
import OrgUtility from '../../Utility/Utility';
import Functions from '../../Functions/Functions';

class GroupsPage extends React.Component{
  constructor(){
    super();
    this.state = {
      windowWidth : window.innerWidth,
      windowHeight : window.innerHeight,
      users: [],
      selectedUsers:[],
      filteredUsers : [],
      userGroups : [],
      filterValue:"",
      statusText : "",
      updateStatusText : "",
      alertSeverity: "success",
      Groups : [{
        GroupLabel : "<Select a group>",
        CompanyGroupId : -1
      }],
      currentGroup : {
        GroupLabel : "",
        CompanyGroupId : -1
      }
    }
    this.saveUserGroups = this.saveUserGroups.bind(this);
    this.saveDataUserGroups =this.saveDataUserGroups.bind(this);
  }

  getGroups(){
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }
    fetch(process.env.API_URL + "/api/account/getGroups",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.Groups){
              let Groups = [{
                GroupLabel : "<Select a group>",
                CompanyGroupId : -1
              }]
              Groups = Groups.concat(json.Groups);
              this.setState({Groups, userGroups : json.UserGroups});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error.message);
    });
  }

  handleClose(){}

  getAllOffspring(dataGroup){
    let idList = [];
    idList.push(dataGroup.DataGroupId);
    if(dataGroup.children){
      dataGroup.children.forEach(child =>{
        idList = idList.concat(this.getAllOffspring(child));
      });
    }
    return idList;
  }

  getAllAncestors(dataGroup){
    let idList = [];
    idList.push(dataGroup.DataGroupId);
    if(dataGroup.ParentId){
      let parent = this.state.Groups.find(x => x.DataGroupId == dataGroup.ParentId);
      idList = idList.concat(this.getAllAncestors(parent));
    }
    return idList;
  }

  saveDataUserGroups(){
    if(auth.isSuperViewer()){
      return;
    }
    let newUserGroups = [];
    let selectedUsers = this.state.selectedUsers;
    let userGroups = this.state.userGroups;
    let currentGroup = typeof(this.state.currentGroup) == 'string' ? JSON.parse(this.state.currentGroup) : this.state.currentGroup;
    if(currentGroup.DataGroupId == -1){
      this.setState({updateStatusText : "Select a group before saving.", alertSeverity : "warning"});
      return;
    }
    selectedUsers.forEach(user =>{
      //Add new users to group but check to make sure they aren't already in the group.
      //Also need to add users to all ancestors of the group that is selected. 
      let ancestors = this.getAllAncestors(currentGroup);
      ancestors.forEach(groupId =>{
        if(!userGroups.find(x => x.UserId == user.UserId && x.DataGroupId == groupId) && !newUserGroups.find(x => x.UserId == user.UserId && x.DataGroupId == groupId && x.Add == true)){
          newUserGroups.push({UserId : user.UserId, DataGroupId : groupId, Add : true});
        }
      });
    });
    userGroups.forEach(userGroup =>{
      //Add removed users so they will be deleted.
      if(!selectedUsers.find(x => x.UserId == userGroup.UserId)){
        //Need to Delete the entry for all the children if user is unassigned from parent. 
        let offspringList = this.getAllOffspring(currentGroup);
        offspringList.forEach(groupId =>{
          if(!newUserGroups.find(x => x.UserId == userGroup.UserId && x.DataGroupId == groupId && x.Add == false)){
            newUserGroups.push({UserId : userGroup.UserId, DataGroupId : groupId, Add : false});
          }
        });
      }
    });
    this.setState({ statusText : 'SAVING GROUPS...'});
    let body={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      UserGroups : newUserGroups
    }
    fetch(process.env.API_URL + "/api/account/updateDataGroupUsers",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({userGroups : json.UserGroups, statusText : "", updateStatusText : "User Group Assignment saved.", alertSeverity : "success",selectedUsers : [], groupSelected : false});
            this.props.updateUserGroups(json.UserGroups);
          }
          else{
            this.setState({statusText : "", updateStatusText : "Something went wrong. Try again.", alertSeverity : "error"});
          }
        });
      }
      else{
        this.setState({statusText : "", updateStatusText : "Something went wrong. Try again.", alertSeverity : "error"});
      }
    })
    .catch(error =>{
      console.log(error.message);
      this.setState({statusText :"", updateStatusText : "Something went wrong. Try again.", alertSeverity : "error"});
    });
  }

  saveUserGroups(){
    if(auth.isSuperViewer()){
      return;
    }
    let newUserGroups = [];
    let selectedUsers = this.state.selectedUsers;
    let userGroups = this.state.userGroups;
    let currentGroup = typeof(this.state.currentGroup) == 'string' ? JSON.parse(this.state.currentGroup) : this.state.currentGroup;
    if(currentGroup.CompanyGroupId == -1){
      this.setState({updateStatusText : "Select a group before saving.", alertSeverity : "warning"});
      return;
    }
    selectedUsers.forEach(user =>{
      //Add new users to group but check to make sure they aren't already in the group.
      if(!userGroups.find(x => x.UserId == user.UserId && x.CompanyGroupId == currentGroup.CompanyGroupId)){
        newUserGroups.push({UserId : user.UserId, CompanyGroupId : currentGroup.CompanyGroupId, Add : true});
      }
    });
    userGroups.forEach(userGroup =>{
      //Add removed users so they will be deleted.
      if(userGroup.CompanyGroupId == currentGroup.CompanyGroupId && !selectedUsers.find(x => x.UserId == userGroup.UserId)){
        newUserGroups.push({UserId : userGroup.UserId, CompanyGroupId : currentGroup.CompanyGroupId, Add : false})
      }
    });
    this.setState({ statusText : 'SAVING GROUPS...' });
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      UserGroups : newUserGroups
    }
    fetch(process.env.API_URL + "/api/account/updateUserGroups",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({userGroups : json.UserGroups,statusText : "", updateStatusText : "User Group Assignment Saved.", alertSeverity : "success", selectedUsers : [], groupSelected : false});
          }
          else{
            this.setState({statusText : "",updatestatusText : "Something went wrong. Try again.", alertSeverity : "error"})
          }
        });
      }
      else{
        this.setState({statusText : "", updateStatusText : "Something went wrong. Try again.", alertSeverity  : "error"});
      }
    })
    .catch(error =>{
      console.log(error.message);
      this.setState({statusText : "", updateStatusText : "Something went wrong. Try again.", alertSeverity  : "error"});
    })
  }

  selectUsers(user){
    let selectedUsers = [];
    if(typeof(user) == "string"){
      if(user == "ADDALL"){
        this.state.users.forEach(user =>{
          selectedUsers.push(user);
        });
      }
    }
    else{
      selectedUsers = this.state.selectedUsers;
      if(selectedUsers.find(x => x.UserId === user.UserId)){
        let index = selectedUsers.indexOf(x => x.UserId == user.UserId);
        if(index == -1){ //Sometimes indexOf doesn't work correctly for some reason.
          selectedUsers.forEach(selectedUser =>{
            index++;
            if(user.UserId == selectedUser.UserId){
              selectedUsers.splice(index,1);
            }
          })
        }
        else{
          selectedUsers.splice(index,1);
        }
      }else{
        selectedUsers.push(user);
      }
    }
    
    this.setState({selectedUsers});
  }

  getUsers(){
    let body = {CompanyId: auth.companyId()};
    fetch(process.env.API_URL + "/api/account/users",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0 && json.UserCollection.length >0){
            let i = json.UserCollection.length;
            while (i--) {
              if (json.UserCollection[i].DeletedBy != null ||json.UserCollection[i].DeletedDate != null || json.UserCollection[i].DeletedDateUTCOffset != null) {
                //json.UserCollection.push(json.UserCollection[i]); remove users who are disabled/deleted
                json.UserCollection.splice(i, 1);
              }
            }
            Functions.sortOn(json.UserCollection, 'LastName');
            this.setState({users : json.UserCollection, filteredUsers : json.UserCollection, statusText : ""});
          }
        })
      }
    })
  }

  filterUsers(){
    if(this.state.users != null && this.state.users.length >0){
      let newFilteredUsers = [];
      this.state.users.forEach(user =>{
        if(this.state.filterValue == ""){
          newFilteredUsers.push(user);
        }
        else if( user.DisplayNameProper && (user.DisplayNameProper.replace(",","").toUpperCase().indexOf(this.state.filterValue.toUpperCase()) > -1)){
          newFilteredUsers.push(user);
        }
      });
      this.setState({ filteredUsers : newFilteredUsers });
    }
  }

  selectGroup(e, fromParent){
    let selectedUsers = [];
    let group;
    if(fromParent){
      group = this.props.dataGroups.find(x => x.DataGroupId == e);
    }
    else{
      group = JSON.parse(e.target.value);
    }
    this.state.userGroups.forEach(userGroup => {
      if(!this.props.dataGroups && userGroup.CompanyGroupId == group.CompanyGroupId){
        let user = this.state.users.find(x => x.UserId == userGroup.UserId);
        //If user was deleted or disabled it will still be in the group but won't be in users list causing issues. 
        if(user){
          selectedUsers.push(user);
        }
      }
      else if(this.props.dataGroups && userGroup.DataGroupId == group.DataGroupId){
        let user = this.state.users.find(x => x.UserId == userGroup.UserId);
        if(user){
          selectedUsers.push(user);
        }
      }
    });
    this.setState({currentGroup : fromParent ? group : e.target.value,selectedUsers,groupSelected : true});
  }

  componentDidMount(){
    this.setState({statusText : "RETRIEVING DATA..."});
    this.props.forceRefresh();
    if(this.props.dataGroups){
      let Groups = [{
        GroupLabel : "<Select a group>",
        CompanyGroupId : -1
      }]
      Groups = Groups.concat(this.props.dataGroups);
      this.setState({Groups, userGroups : this.props.UserGroups});
    }
    else{
      this.getGroups();
    }
    this.getUsers();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions(){
    this.setState({windowWidth : window.innerWidth, windowHeight : window.innerHeight});
  }

  componentDidUpdate(){
    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  render(){
    return(
      <div className= "d-flex p-0 m-0" style={{overflow : 'hide'}}>
        <div style={{width: "100%"}}>
          {this.state.statusText == "" ? 
          <div className = "row" style={{marginTop: 10}}>
            <div className="labelHeaderBar labelHeaderBarTight" style={{marginTop:0, padding:10}}>
              <div className="row">
                <div className="col-11">
                  Group Assignment
                  <div className="labelHeaderBarSubHeader">Assign users to groups to give access to group-secured app functionality</div>
                </div>
                {!this.props.dataGroups && <div className="col-1">
                  <div className="text-left" style={{marginTop  : 10 }}>
                    <Button onClick={this.props.dataGroups ? this.saveDataUserGroups : this.saveUserGroups} style={{ minWidth : 100, position : 'relative', right : (this.state.windowWidth > 1068 ? 40 : (this.state.windowWidth > 508 ? 80 : 0)) }} className ="buttonAction buttonEditUser">
                      <SaveSharpIcon className="buttonIcon"/>
                      <label className ="buttonText">SAVE</label>
                    </Button>
                  </div>
                </div>}
              </div>
            </div>
            <div className="w-100 licenseEditDivContent">
              <div className="row">
              {!this.props.dataGroups && <div className="col-5">
              <label className="labelHeaderBar" style={{width:"98%"}}>Group </label>
                <select className="form-control customFormRowControl" value ={this.state.currentGroup.GroupLabel} onChange={e =>{this.selectGroup(e)}} style={{maxWidth:284, height:42}}>
                  {this.state.Groups.length >0 && this.state.Groups.map((group, index) =>( !group.DeletedDate && 
                    <option value = {JSON.stringify(group)} key={index} disabled={group.GroupLabel =="<Select a group>" && this.state.groupSelected}>{group.GroupLabel}</option>
                  ))}
                </select>
              </div>}
              <div className="col-5">
              <label className="labelHeaderBar">Users </label>
                <div style={{marginTop : 10, maxWidth: 295}} className="d-flex flex-column">
                  <div className="text-right"></div>
                  <input placeholder ="Filter" type="text" value={this.state.filterValue} onChange={e =>{this.setState({filterValue : e.target.value},()=>{this.filterUsers()})}} className="form-control searchInput" style={{maxWidth :200, marginLeft : 95 }}/>
                </div>

                <div className="groupAssignmentUserList">
                  <div style={{minHeight : 10}}/>
                  {this.state.filteredUsers && this.state.filteredUsers.length > 0 && this.state.filteredUsers.map((user,index) =>(
                    user.DisplayNameProper && <div key={index}className="" style={{marginLeft : 10}}><input type="checkbox" id={user.UserId} checked={this.state.selectedUsers.find(x=> x.UserId === user.UserId) || false} onChange={()=>{this.selectUsers(user)}} />  <label htmlFor={user.UserId}> {user.DisplayNameProper}</label></div>
                  ))}
                </div>
                <div style={{marginLeft : 10, marginTop : 10}}>
                  <Button onClick={()=>{this.selectUsers("ADDALL")}}className="buttonAction btn btn-primary " style={{marginRight : 10, display: 'inline-block'}}>
                    <label className ="buttonText" style={{marginBottom:8}}>Add All Users</label>
                  </Button>
                  <Button onClick={()=>{this.selectUsers("REMOVEALL")}}className="buttonAction btn btn-primary" style={{display: 'inline-block'}}>
                    <label className ="buttonText"  style={{marginBottom:8}}>Remove All Users</label>
                  </Button>
                </div>
              </div>
            </div>
            {this.state.updateStatusText != "" &&
                <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={6000} onClose={this.handleClose} 
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                  <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
                    {this.state.updateStatusText}
                  </Alert>
                </Snackbar>}
        </div>
        </div>
         : <div className="row">
            <div className="spinnerDivUserPage">
              <div className="text-center authSpinnerDiv">
                <div className="authSpinnerDivMessage">{this.state.statusText}</div>
                <div className = "barLoaderPortal">
                  <BarLoader sizeUnit={"px"} size={150} color={"#095399"}
                    loading={ this.state.statusText == "" || this.state.statusText == 'Not authorized to view this page' || this.state.statusText == 'Error Retrieving Users' ? false : this.state.statusText == "No Data Returned" ? false : true }/>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    )
  }
}

GroupsPage.propTypes = {
  forceRefresh : PropTypes.func,
  dataGroups : PropTypes.array,
  UserGroups : PropTypes.array,
  updateUserGroups : PropTypes.func
};

export default GroupsPage;