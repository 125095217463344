const Functions = {
  
  toLocaleTimeString(date){
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let timeOfDay = hours < 12 ? 'AM' : 'PM';
    if (minutes < 10) { minutes = '0' + minutes; }
    if (hours > 12){ hours %=12; }
    return hours + ":" + minutes + " " + timeOfDay;
  },

  findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value)
        return i;
    }
    return -1;
  },

  isValidHttpsUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "https:";
  },

  formatDateString(dateAsEpoch, longMode){
    var dateStr = JSON.parse(dateAsEpoch);
    var date = new Date(dateStr);
    const yearAdj = longMode ? 0 : 2000;
    let ampm = 'AM';
    const zeropad = (num) => { if (num < 10) return '0' + num; else  return num.toString(); }
    let hoursformatted = date.getHours();
    
    if (hoursformatted > 11) ampm = 'PM';
    if (hoursformatted > 12) hoursformatted = hoursformatted - 12;
    if (date.getHours() == 0) hoursformatted = 12;
        
    const formattedDate = (date.getMonth() + 1) 
        + '/' + date.getDate() 
        + '/' + (date.getFullYear()  - yearAdj)
        + ' ' + hoursformatted
        + ':' + zeropad(date.getMinutes())
        + (longMode ? (':' + zeropad(date.getSeconds())) : '')
        + ' ' + ampm;
    
    return formattedDate;
  },

  sortOn (arr, prop) {
    arr.sort (
        function (a, b) {
            if (a[prop] < b[prop]){
                return -1;
            } else if (a[prop] > b[prop]){
                return 1;
            } else {
                return 0;   
            }
        }
    );
}


}

export default Functions