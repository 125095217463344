/* eslint react/prop-types: 0 */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const AtFaultWorkPerformed = (props) => {
  const [graphData, setGraphData] = React.useState([]);
  const [graphCategories, setGraphCategories] = React.useState([]);
  const [chart, setChart] = React.useState(null);

  const chartCallback = c => setChart(c);

  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  React.useEffect(() => { // Check this function for categories
    if(props.data){
      let keylist = {};
      let workPerformedList = [];
      props.data.forEach(item => {
        if(item.WorkPerformed && !workPerformedList.includes(item.WorkPerformed)) {
          workPerformedList.push(item.WorkPerformed);
        }
        // console.log(item.WorkPerformed, item.AtFault, keylist[item.WorkPerformed]);
        if(item.WorkPerformed && item.AtFault && keylist[item.AtFault]){
          keylist[item.AtFault][item.WorkPerformed] ?
          keylist[item.AtFault][item.WorkPerformed] += 1 :
          keylist[item.AtFault][item.WorkPerformed] = 1;
        }
        else if(item.AtFault && item.WorkPerformed){
          keylist[item.AtFault] = {};
          keylist[item.AtFault][item.WorkPerformed] = 1;
        }
      });
      let cats = [];
      let setup = {};
      Object.keys(keylist).forEach(key => {
        let item = keylist[key];
        cats.push(key);
        workPerformedList.forEach(subCat => {
          if(setup[subCat]){
            setup[subCat].push(item[subCat] ? item[subCat] : 0);
          }else{
            setup[subCat] = [];
            setup[subCat].push(item[subCat] ? item[subCat] : 0);
          }
        })
      });
      let finalData = [];
      Object.keys(setup).forEach(series => {
        finalData.push({ name: series, data: setup[series] });
      });
      console.log("Final Data for At Fault : ", finalData);
      setGraphCategories(cats);
      // setGraphCategories(workPerformedList);
      setGraphData(finalData);
    }
  }, [props.data])

  // React.useEffect(() => { // Check this function for categories
  //   if(props.data){
  //     let keylist = {};
  //     let workPerformedList = [];
  //     props.data.forEach(item => {
  //       if(item.WorkPerformed && item.AtFault && !workPerformedList.includes(item.WorkPerformed)) {
  //         workPerformedList.push(item.WorkPerformed);
  //       }
  //       // console.log(item.WorkPerformed, item.AtFault, keylist[item.WorkPerformed]);
  //       if(item.WorkPerformed && item.AtFault && keylist[item.AtFault]){
  //         keylist[item.AtFault][item.WorkPerformed] ?
  //         keylist[item.AtFault][item.WorkPerformed] += 1 :
  //         keylist[item.AtFault][item.WorkPerformed] = 1;
  //       }
  //       else if(item.AtFault && item.WorkPerformed){
  //         keylist[item.AtFault] = {};
  //         keylist[item.AtFault][item.WorkPerformed] = 1;
  //       }
  //     });
  //     let cats = [];
  //     let setup = {};
  //     Object.keys(keylist).forEach(key => {
  //       let item = keylist[key];
  //       cats.push(key);
  //       workPerformedList.forEach(subCat => {
  //         if(setup[subCat]){
  //           setup[subCat].push(item[subCat] ? item[subCat] : 0);
  //         }else{
  //           setup[subCat] = [];
  //           setup[subCat].push(item[subCat] ? item[subCat] : 0);
  //         }
  //       })
  //     });
  //     let finalData = [];
  //     Object.keys(setup).forEach(series => {
  //       finalData.push({ name: series, data: setup[series] });
  //     });
  //     // console.log("Final Data for At Fault : ", finalData);
  //     // setGraphCategories(cats);
  //     setGraphCategories(workPerformedList);
  //     setGraphData(finalData);
  //   }
  // }, [props.data])

  return (
    <HighchartsReact 
      containerProps={{ className: "w-100 h-100 border border-dark" }} 
      highcharts={Highcharts} 
      allowChartUpdate={true} 
      callback={chartCallback}
      constructorType="chart"
      options={{
        chart: { type: "column", backgroundColor: "#E9E9EF" },
        title: { text: "At Fault Count by Work Performed" },
        plotOptions: {
          series: {
            stacking: "normal",
            cursor: "pointer",
            point: {
              events: {
                click: function(event){
                  props.filterCallback({ AtFault: event.point.category, WorkPerformed: event.point.series.name }, 
                    `Showing Investigations with At Fault: ${event.point.category}, and Work Performed: ${event.point.series.name}`);
                }
              }
            }
          }
        },
        xAxis: {
          categories: graphCategories
        },
        yAxis: {
          allowDecimals: false,
          title: { text: "Damages" },
        },
        series: graphData || [],
        credits: { enabled: false },
        legend: { enabled: true }
      }}
    />
  );
}

export default AtFaultWorkPerformed;