import React from "react";
import SurveyStrip from "./SurveyStrip";
import SurveyBody from "./SurveyBody";
import PropTypes from "prop-types";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import auth from '../common/Auth';
import { Prompt } from 'react-router-dom';
import { get, set } from 'idb-keyval';
import { BarLoader } from 'react-spinners'
import { MDBDataTable } from "mdbreact";
import OrgUtility from '../../Utility/Utility';

class SurveyPage extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      pictureCount : 0,
      updateStatusText : "",
      alertSeverity : "success",
      editing : false,
      loadingUpdate : "Save"
    }
    this.setPictureCount = this.setPictureCount.bind(this);
    this.setUpEditing = this.setUpEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.editStreetAddress = this.editStreetAddress.bind(this);
    this.editCity = this.editCity.bind(this);
    this.editStateAddress = this.editStateAddress.bind(this);
    this.editTicketNumber = this.editTicketNumber.bind(this);
    this.editJobName = this.editJobName.bind(this);
    this.editComments = this.editComments.bind(this);
    this.saveSurvey = this.saveSurvey.bind(this);
    this.goToMap = this.goToMap.bind(this);
    this.fetchHistory = this.fetchHistory.bind(this);
    this.viewHistoryRecord = this.viewHistoryRecord.bind(this);
    this.closeHistoryList = this.closeHistoryList.bind(this);
    this.closeHistoryRecord = this.closeHistoryRecord.bind(this);
  }

  componentWillMount() {
    let survey = {};
    if (this.props.location.survey != null){
      survey = this.props.location.survey;
      let su = this.props.location.survey;
      su.__proto__ = null;
      su.Details = null;
      localStorage.setItem('currentSurvey', JSON.stringify(su));
    }
    else{
      let su = localStorage.getItem('currentSurvey');
      if(su && su != ''){
        survey = JSON.parse(su);
      }
    }

    // EMPTY OBJECT CHECK
    if (Object.entries(survey).length === 0)
    survey = null;
    this.setState({streetAddress : survey ? survey.Address : '',
    city : survey ? survey.City : '',
    stateAddress : survey ? survey.State : '',
    ticketNumber : survey ? survey.TicketNumber : '',
    jobName : survey ? survey.JobName : '',
    comments : survey ? survey.Comments : '', survey, upToDateSurveyId : survey ? survey.SiteSurveyId : 0})
  }

  handleClose(){

  }
  
  componentDidMount() {
    if(this.props.forceRefresh){
      this.props.forceRefresh();
    }
  }

  componentDidUpdate(prevProps){

    if (this.state.editing) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined;
    }

    // change entity and close editor
    if (prevProps.location.survey && this.props.location.survey && (prevProps.location.survey.Id != this.props.location.survey.Id)){
      this.setState({survey: this.props.location.survey, 
        editing: false, ticketNumber : this.props.location.survey.TicketNumber, 
        jobName : this.props.location.survey.JobName, comments : this.props.location.survey.Comments, 
        streetAddress : this.props.location.survey.Address, city : this.props.location.survey.City, 
        stateAddress : this.props.location.survey.State, showingHistoryRecord : false, showHistoryList : false, loadingHistory : false, upToDateSurveyId : this.props.location.survey ? this.props.location.survey.SiteSurveyId : 0});
    }

    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  componentWillUnmount(){
    window.onbeforeunload = undefined;
  }

  setUpEditing(){
    this.setState({editing: true});
  }

  cancelEditing(){
    this.setState({editing: false, ticketNumber : this.state.survey.TicketNumber, jobName : this.state.survey.JobName, comments : this.state.survey.Comments, streetAddress : this.state.survey.Address, city : this.state.survey.City, stateAddress : this.state.survey.State});
  }

  editStreetAddress(streetAddress){
    this.setState({streetAddress});
  }

  editCity(city){
    this.setState({city});
  }

  editStateAddress(stateAddress){
    this.setState({stateAddress});
  }

  editTicketNumber(ticketNumber){
    this.setState({ticketNumber});
  }

  editJobName(jobName){
    this.setState({jobName});
  }

  editComments(comments){
    this.setState({comments});
  }

  setPictureCount(pictureCount){
    this.setState({pictureCount});
  }

  fetchHistory(){
    this.setState({historyStatusText : "RETRIEVING DATA...", loadingHistory : true});
    let body ={
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      CreatedDate : this.state.survey.CreatedDate,
      CreatedBy : this.state.survey.CreatedBy
    }
    fetch(process.env.API_URL + "/api/survey/surveyHistory",{
      method: "POST",
      headers : {
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        let historyIdList = [];
        response.json().then(responseJson =>{
          let response = JSON.parse(responseJson);
          let surveyHistory = {columns:[{label: "",field:"ViewButton"},{label:"MODIFIED DATE", field:"ModifiedDateFormatted"},{label : "UPDATED BY", field:"ModifiedByDisplayName"}], rows:[]};
          if(response.SurveyHistoryCollection && response.SurveyHistoryCollection.length > 0){
            historyIdList.push(this.state.survey.SiteSurveyId);
            response.SurveyHistoryCollection.forEach(record =>{
              record.ViewButton = <button className="anchorButton submitButton survey padded" onClick={() =>{this.viewHistoryRecord(record)}}><div className="text-center full">VIEW</div></button>;
              record.ModifiedDateFormatted = new Date(record.ModifiedDate[record.ModifiedDate.length]-1 !="Z" ? record.ModifiedDate + "Z" : record.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
              surveyHistory.rows.push(record);
              historyIdList.push(record.SiteSurveyId);
            });
          }
          this.setState({historyStatusText : "", loadingHistory : false, surveyHistory, showHistoryList : true, historyIdList});
        });
      }
      else{
        this.setState({historyStatusText : "There was an error retrieving history. Try again.", loadingHistory : false, showHistoryList : true});
      }
    })
    .catch(e =>{
      console.log(e);
      this.setState({historyStatusText : "There was an error retrieving history. Try again.", loadingHistory : false, showHistoryList : true});
    });
  }

  viewHistoryRecord(record){
    let upToDateSurveyData = JSON.parse(JSON.stringify(this.state.survey));
    this.setState({upToDateSurveyData, survey : record,historyIdList : null, showHistoryList : false, showingHistoryRecord : true, historyRecordId : record.SiteSurveyId});
  }

  saveSurvey(){
    this.setState({loadingUpdate: "Saving..."});
    let now = new Date();
    let surveyModel = this.state.survey;
    surveyModel.Address  = this.state.streetAddress;
    surveyModel.City = this.state.city;
    surveyModel.State = this.state.stateAddress;
    surveyModel.TicketNumber = this.state.ticketNumber;
    surveyModel.JobName = this.state.jobName;
    surveyModel.Comments = this.state.comments;
    surveyModel.ModifiedBy = auth.userId();
    surveyModel.ModifiedDate = now;
    surveyModel.ModifiedDateUTCOffset = -now.getTimezoneOffset()/60;
    let body={
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      SiteSurvey : surveyModel,
      StatusType : 0,
      EntityType : 0,
      RequestId : now.valueOf()
    };
    fetch(process.env.API_URL + "/api/survey/save",{
      method : "POST",
      headers : {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status ==200){
        r.json().then(async responseJson =>{
          let response = JSON.parse(responseJson);
          if(response.ResponseType == 0){
            let survey = response.SiteSurvey;
            localStorage.setItem('currentSurvey', JSON.stringify(survey));
            let results = await get('resultData');
            if(results){
              let parsedResults = JSON.parse(results);
              if(parsedResults && parsedResults.SiteSurveyCollection){
                let index = parsedResults.SiteSurveyCollection.findIndex(x => x.SiteSurveyId == survey.SiteSurveyId);
                if(index != -1){
                  parsedResults.SiteSurveyCollection[index] = survey;
                  set('resultData', JSON.stringify(parsedResults));
                }
              }
            }
            this.setState({survey,updateStatusText : 'Updated Survey.', loadingUpdate : "Save", alertSeverity : 'success', editing : false, ticketNumber : survey.TicketNumber, jobName : survey.JobName, comments : survey.Comments, streetAddress : survey.Address , city : survey.City, stateAddress : survey.State  });
          }
          else{
            this.setState({updateStatusText : "Something went wrong. Try again.", loadingUpdate : "Save", alertSeverity : "error"});
          }
        });
      }
      else{
        this.setState({updateStatusText : "Something went wrong. Try again.", loadingUpdate : "Save", alertSeverity : "error"});
      }
    }).catch(error =>{
      console.log(error);
      this.setState({updateStatusText : "Something went wrong. Try again.",  loadingUpdate : "Save", alertSeverity : "error"});
    })
  }

  goToMap(){
    let survey = this.state.survey;
    survey.Type = "Survey";
    localStorage.setItem("mapSurvey", JSON.stringify(survey));
    window.location.href = "/surveymap?fromDetails=true&detailsType=survey";
  }

  closeHistoryList(){
    this.setState({loadingHistory : false, showHistoryList : false, historyIdList : null});
  }

  closeHistoryRecord(){
    let historyIdList = [];
    if(this.state.surveyHistory && this.state.surveyHistory.rows && this.state.surveyHistory.rows.length > 0){
      historyIdList.push(this.state.upToDateSurveyData.SiteSurveyId);
      this.state.surveyHistory.rows.forEach(record =>{
        historyIdList.push(record.SiteSurveyId);
      })
    }
    this.setState({showingHistoryRecord : false, survey : this.state.upToDateSurveyData, showHistoryList : true, historyIdList});
  }

  render() {
    return (
      <div className="col-10 d-flex justify-content-center p-0 m-0" style={{ backgroundColor: "#E9E9EF" }}> 
        <Prompt when={this.state.editing} message='You have unsaved changes, are you sure you want to leave?' />
        { this.state.survey ? 
          <div className="container-fluid">
          <SurveyStrip
            entityTypeId={this.state.survey.Type == "Site" || this.state.survey.Type == "Survey" ? 0 : 13} //EntityType: Survey or GPS Survey (13)
            type={this.state.survey.Type}
            city={this.state.survey.City}
            state={this.state.survey.State}
            id={this.state.upToDateSurveyId} // Use Survey Id or CustomForm DataId
            address={this.state.survey.Address}
            entity={this.state.survey}
            pictureCount={this.state.pictureCount}
            editing={this.state.editing}
            setUpEditing={this.setUpEditing}
            cancelEditing={this.cancelEditing}
            saveSurvey={this.saveSurvey}
            loadingUpdate={this.state.loadingUpdate}
            showingHistoryList={this.state.showHistoryList}
            closeEntity={(this.state.showHistoryList ? this.closeHistoryList : this.state.showingHistoryRecord ? this.closeHistoryRecord : this.props.closeEntity) || this.props.hideClose}
            goToMap={this.goToMap}
            hideReport={this.state.loadingHistory || this.state.showHistoryList}
            hideEdit={this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord}
            hideArchive={this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord}
            hideMap={this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord}
            showingHistoryRecord={this.state.showingHistoryRecord}
            modifiedDate={this.state.showingHistoryRecord ? this.state.survey.ModifiedDate : null}
            historyRecordId={this.state.historyRecordId}
            historyIdList={this.state.historyIdList}
            fetchHistory={this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord ? null : this.fetchHistory}
          />
          {!this.state.loadingHistory && !this.state.showHistoryList && <SurveyBody
            fromDashboard={this.props.fromDashboard}
            upToDateSurveyId={this.state.upToDateSurveyId}
            showingHistoryRecord={this.state.showingHistoryRecord}
            survey={this.state.survey}
            editing={this.state.editing} 
            setPictureCount={this.setPictureCount}
            streetAddress={this.state.streetAddress}
            city={this.state.city}
            stateAddress={this.state.stateAddress}
            ticketNumber={this.state.ticketNumber}
            jobName={this.state.jobName}
            comments={this.state.comments}
            editStreetAddress={this.editStreetAddress}
            editCity={this.editCity}
            editStateAddress={this.editStateAddress}
            editTicketNumber={this.editTicketNumber}
            editJobName={this.editJobName}
            editComments={this.editComments}
            />}
            {this.state.loadingHistory && 
              <div className="row">
                <div className="spinnerDivUserPage">
                  <div className="text-center authSpinnerDiv" style={{padding:10}}>
                    {this.state.historyStatusText != ""? <div className="authSpinnerDivMessage">
                      {this.state.historyStatusText}
                    </div> : ''}
                    <div className="barLoaderPortal">
                      <BarLoader sizeUnit={"px"} size={150} color={"#095399"}
                        loading={this.state.historyStatusText == "" ? false : this.state.historyStatusText == "No Data Returned" || this.state.historyStatusText == "There was an error retrieving history. Try again." ? false : true}/>
                    </div>
                  </div>
                </div>
              </div>}
            {this.state.showHistoryList && 
              <div className="row">
                {!this.state.surveyHistory || !this.state.surveyHistory.rows || this.state.surveyHistory.rows.length == 0 ? <div className="spinnerDivUserPage">
                  <div className="text-center authSpinnerDiv" style={{padding:10}}>
                    <div className="authSpinnerDivMessage">
                      {this.state.historyStatusText ? this.state.historyStatusText : "No History Returned"}
                    </div>
                  </div>
                </div>
                : <div className="searchResultsColumn" style={{marginLeft:10, marginRight:10}}>
                    <MDBDataTable id="history-datatable" searching={false} className="mdbDataTabel" btn Button striped bordered hover data={this.state.surveyHistory} paging={false}/>
                  </div>}  
                
              </div>}
            {this.state.updateStatusText != "" &&
          <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={6000} onClose={this.handleClose} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
            {this.state.updateStatusText}
            </Alert>
          </Snackbar>}
        </div>
        :
          <div className = "container-fluid">
            <div className ="text-center detailsErrorMessage">Something went wrong loading the data. Go back to Reporting page and try again.</div>
          </div>
        }
      </div>
    );
  }
}

SurveyPage.propTypes = {
  location: PropTypes.object,
  survey: PropTypes.object,
  forceRefresh: PropTypes.func,
  closeEntity: PropTypes.func,
};

export default SurveyPage;