/* eslint react/prop-types: 0 */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more'; 
import moment from 'moment';

highchartsMore(Highcharts);

const DamagesByDate = (props) => {
  const [callSeries, setCallSeries] = React.useState([]);
  const [whiteLineSeries, setWhiteLineSeries] = React.useState([]);
  const [chart, setChart] = React.useState(null);
  const [categories, setCategories] = React.useState([]);

  const chartCallback = c => setChart(c);

  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  React.useEffect(() => {
    console.group("DamagesByDateWidget");
    if(props.data){
      // console.log("Damages By Date Data : ", props.data);
      let callSeries = [];
      let whiteLineSeries = [];
      let range = getDateRange(props.dateFrom, props.dateTo);
      props.data.forEach(item => {
        let dateValue = item.CreatedDate;
        let compare = moment(dateValue).format("MM/DD/YYYY");
        let formattedEventDate = moment(dateValue).format("YYYY-MM-DD")//.utc().valueOf();
        if(dateValue){
          // console.log(item.NotificationOneCallNotified, item.WorkAreaWhiteLined);
          if(item.NotificationOneCallNotified === null){
            let foundIndex = callSeries.findIndex(i => moment(i[0]).format("MM/DD/YYYY") === compare);
            if(foundIndex >= 0){
              let origVal = callSeries[foundIndex][1] || 0;
              callSeries[foundIndex] = [formattedEventDate, origVal + 1]
            }else{ callSeries.push([formattedEventDate, 1]) }
          }
          if(item.WorkAreaWhiteLined === null){
            let foundIndex = whiteLineSeries.findIndex(i => moment(i[0]).format("MM/DD/YYYY") === compare);
            if(foundIndex >= 0){ 
              // console.log(foundIndex, formattedEventDate, item.EventDate);
              let origVal = whiteLineSeries[foundIndex][1];
              whiteLineSeries[foundIndex] = [formattedEventDate, origVal + 1];
            }else{ whiteLineSeries.push([formattedEventDate, 1]) }
          }
        }
      });
      range.forEach(date => {
        // let utcDate = moment(date, "YYYY-MM-DD").format("MM/DD/YYYY")//.valueOf();
        let formattedDate = moment(date).format("MM/DD/YYYY");
        if(callSeries.findIndex(item => moment(item[0]).format("MM/DD/YYYY") === formattedDate) === -1){
          callSeries.push([date, 0]);
        }
        if(whiteLineSeries.findIndex(item => moment(item[0]).format("MM/DD/YYYY") === formattedDate) === -1){
          whiteLineSeries.push([date, 0]);
        }
      })
      callSeries.sort((a, b) => moment(a[0]).valueOf() - moment(b[0]).valueOf());
      whiteLineSeries.sort((a, b) => moment(a[0]).valueOf() - moment(b[0]).valueOf())
      // console.log("White Line : ", whiteLineSeries, "Call Series : ", callSeries);
      setCategories(range);
      setCallSeries(callSeries);
      setWhiteLineSeries(whiteLineSeries);
    }
    console.groupEnd("DamagesByDateWidget");
  }, [props.data])

  const getDateRange = (startDate, stopDate) => {
    let dateArray = [];
    let fromDate = moment(startDate).toDate();
    let toDate = moment(stopDate).toDate();
    while (fromDate <= toDate) {
        dateArray.push( moment(fromDate).format('YYYY-MM-DD') )
        fromDate = moment(fromDate).add(1, 'days');
    }
    // console.log("Date Range : ", dateArray);
    return dateArray;
  }

  return (
    <HighchartsReact 
      containerProps={{ className: "w-100 h-100 border border-dark" }} 
      highcharts={Highcharts} 
      allowChartUpdate={true} 
      callback={chartCallback}
      constructorType="chart"
      options={{
        chart: { type: "area", backgroundColor: "#E9E9EF" },
        title: { text: "Damages By Date" },
        plotOptions: {
          series: {
            stacking: "normal",
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true
                }
              }
            },
            point: {
              events: {
                click: function(event){
                  let formattedDate = moment(event.point.category).format("MM/DD/YYYY");
                  props.filterCallback({ CallOrWhiteLined: false/* Custom filter */, FormattedCreatedDate: formattedDate },
                  `Showing Damages not whitelined or notified created on ${formattedDate}`); // Need date in here too
                }
              }
            }
          },
        },
        xAxis: { categories: categories || [] },
        yAxis: { title: { text: "Damages" } },
        tooltip: { shared: true, },
        series: [
          {
            name: "Center not Notified",
            type: "area",
            data: callSeries
          },
          {
            name: "Work area not Whitelined",
            type: "area",
            data: whiteLineSeries
          }
        ],
        credits: { enabled: false },
        legend: { enabled: true }
      }}
    />
  );
}

export default DamagesByDate;
