import React from "react";
import Info from "@material-ui/icons/InfoOutlined";

const AboutPage = () => (
  <div
    className="col-8 d-flex justify-content-center p-0 m-0"
    style={{ backgroundColor: "#E9E9EF" }}
  >
    <div>
      <h1>
        <Info
          style={{
            fontSize: 36,
            marginBottom: 7,
            marginRight: 5,
            color: "blue"
          }}
        />
        About
      </h1>
      <p></p>
    </div>
  </div>
);

export default AboutPage;
