import React from 'react';
import LeakSurveyBody from './LeakSurveyBody';
import PropTypes from 'prop-types';
import SurveyStrip from './SurveyStrip';
import auth from '../common/Auth';
import { Prompt } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { get, set } from 'idb-keyval';
import { BarLoader } from 'react-spinners';
import { MDBDataTable } from 'mdbreact';

class LeakSurveyPage extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      resultSet : [],
      pictureCount : 0,
      editing : false,
      loadingUpdate : "Save",
      updateStatusText : "",
      alertSeverity : "success"
    };
    this.setFields = this.setFields.bind(this);
    this.setPictureCount = this.setPictureCount.bind(this);
    this.setUpEditing = this.setUpEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.saveSurvey = this.saveSurvey.bind(this);
    this.editStreetAddress = this.editStreetAddress.bind(this);
    this.editCity = this.editCity.bind(this);
    this.editStateAddress = this.editStateAddress.bind(this);
    this.editLELValue = this.editLELValue.bind(this);
    this.editGasValue = this.editGasValue.bind(this);
    this.editLeakClass = this.editLeakClass.bind(this);
    this.editServiceOrderNumber = this.editServiceOrderNumber.bind(this);
    this.editResponder = this.editResponder.bind(this);
    this.editMeterManufacturer = this.editMeterManufacturer.bind(this);
    this.editMeterNumber = this.editMeterNumber.bind(this);
    this.editObject = this.editObject.bind(this);
    this.editGasLeakBelowGround = this.editGasLeakBelowGround.bind(this);
    this.editReasonInaccessible = this.editReasonInaccessible.bind(this);
    this.editAOC = this.editAOC.bind(this);
    this.editCorrosionGrade = this.editCorrosionGrade.bind(this);
    this.editMeterInside = this.editMeterInside.bind(this);
    this.editComments = this.editComments.bind(this);
    this.editStatus = this.editStatus.bind(this);
    this.editRegulatorMake = this.editRegulatorMake.bind(this);
    this.editRegulatorModel = this.editRegulatorModel.bind(this);
    this.editMeterLocation = this.editMeterLocation.bind(this);
    this.goToMap = this.goToMap.bind(this);
    this.fetchHistory = this.fetchHistory.bind(this);
    this.closeHistoryList = this.closeHistoryList.bind(this);
    this.closeHistoryRecord = this.closeHistoryRecord.bind(this);
  }

  componentWillMount(){
    let leakSurvey = {};
    if (this.props.location.leakSurvey != null) {
      leakSurvey = this.props.location.leakSurvey;
      this.setState({leakSurvey: this.props.location.leakSurvey});
      let ls = leakSurvey;
      ls.Details = null;
      ls.__proto__ = null;
      localStorage.setItem("leakSurvey", JSON.stringify(ls))
    }

    else{
      leakSurvey = localStorage.getItem("leakSurvey");
      if (leakSurvey != null){
        this.setState({leakSurvey : JSON.parse(leakSurvey)});
        leakSurvey = JSON.parse(leakSurvey);
      }
    }
    this.setState({upToDateLeakSurveyId : leakSurvey ? leakSurvey.LeakSurveyId : 0});
    this.setFields(leakSurvey);
  }

  componentDidMount(){
    if(this.props.forceRefresh){
      this.props.forceRefresh();
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.location.leakSurvey && this.props.location.leakSurvey && (prevProps.location.leakSurvey.Id != this.props.location.leakSurvey.Id)){
      this.setFields(this.props.location.leakSurvey);
      this.setState({leakSurvey : this.props.location.leakSurvey,editing : false, upToDateLeakSurveyId : this.props.location.leakSurvey ? this.props.location.leakSurvey.LeakSurveyId : 0});
    }
    if(this.state.editing){
      window.onbeforeunload = () => true
    }
    else{
      window.onbeforeunload = undefined;
    }
  }

  setUpEditing(){
    this.setState({editing : true});
  }

  cancelEditing(){
    this.setState({editing : false});
    this.setFields(this.state.leakSurvey);
  }

  saveSurvey(){
    this.setState({loadingUpdate : "Saving..."});
    let now = new Date();
    let leakSurveyModel = this.state.leakSurvey;
    leakSurveyModel.Details = null;
    leakSurveyModel.__proto__ = null;
    leakSurveyModel.Address = this.state.streetAddress;
    leakSurveyModel.City = this.state.city;
    leakSurveyModel.State = this.state.stateAddress;
    leakSurveyModel.LELValue = this.state.lelValue;
    leakSurveyModel.GasValue = this.state.gasValue;
    leakSurveyModel.LeakClass = this.state.leakClass;
    leakSurveyModel.ServiceOrderNumber = this.state.serviceOrderNumber;
    leakSurveyModel.Responder = this.state.responder;
    leakSurveyModel.MeterManufacturer = this.state.meterManufacturer;
    leakSurveyModel.MeterNumber = this.state.meterNumber;
    leakSurveyModel.Object = this.state.object;
    leakSurveyModel.GasLeakBelowGround = this.state.gasLeakBelowGround;
    leakSurveyModel.ReasonInaccessible = this.state.reasonInaccessible;
    leakSurveyModel.AbnormalOperatingCondition = this.state.aoc;
    leakSurveyModel.CorrosionGrade = this.state.corrosionGrade;
    leakSurveyModel.MeterInside = this.state.meterInside;
    leakSurveyModel.RegulatorMake = this.state.regulatorMake;
    leakSurveyModel.RegulatorModel = this.state.regulatorModel;
    leakSurveyModel.MeterLocation = this.state.meterLocation;
    leakSurveyModel.Status = this.state.status;
    leakSurveyModel.Comments = this.state.comments;
    leakSurveyModel.ModifiedBy = auth.userId(),
    leakSurveyModel.ModifiedDate = now;
    leakSurveyModel.ModifiedDateUTCOffset = -now.getTimezoneOffset()/60;
    let body = {
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      LeakSurvey : leakSurveyModel
    }
    fetch(process.env.API_URL + "/api/LeakSurvey/save", {
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(async responseJson =>{
          let response = JSON.parse(responseJson);
          if(response.ResponseType == 0){
            let leakSurvey = response.LeakSurvey;
            localStorage.setItem('leakSurvey', JSON.stringify(leakSurvey));
            let results = await get('resultData');
            if(results){
              let parsedResults = JSON.parse(results);
              if(parsedResults && parsedResults.LeakSurveyCollection){
                let index = parsedResults.LeakSurveyCollection.findIndex(x => x.LeakSurveyId == leakSurvey.LeakSurveyId);
                if(index != -1){
                  parsedResults.LeakSurveyCollection[index] = leakSurvey;
                  set('resultData', JSON.stringify(parsedResults));
                }
              }
            }
            this.setState({updateStatusText : 'Updated ' + leakSurvey.Type, loadingUpdate : "Save", alertSeverity : "success", editing : false,leakSurvey});
            this.setFields(leakSurvey);
          }
          else{
            this.setState({updateStatusText : "Something went wrong. Try again.", loadingUpdate: "Save", alertSeverity : "error"});
          }
        });
      }
      else{
        this.setState({updateStatusText : "Something went wrong. Try again.", loadingUpdate: "Save", alertSeverity : "error"});
      }
    }).catch(error =>{
      console.log(error);
      this.setState({updateStatusText : "Something went wrong. Try again.", loadingUpdate: "Save", alertSeverity : "error"});
    })
  }

  setFields(leakSurvey){
    this.setState({
      streetAddress : leakSurvey ? leakSurvey.Address : '',
      city : leakSurvey ? leakSurvey.City : '',
      stateAddress : leakSurvey ? leakSurvey.State : '',
      lelValue : leakSurvey ? leakSurvey.LELValue : '',
      gasValue : leakSurvey ? leakSurvey.GasValue : '',
      leakClass : leakSurvey ? leakSurvey.LeakClass : '',
      serviceOrderNumber : leakSurvey ? leakSurvey.ServiceOrderNumber : '',
      responder : leakSurvey ? leakSurvey.Responder : '',
      meterManufacturer : leakSurvey ? leakSurvey.MeterManufacturer : '',
      meterNumber : leakSurvey ? leakSurvey.MeterNumber : '',
      object : leakSurvey ? leakSurvey.Object : '',
      gasLeakBelowGround : leakSurvey ? leakSurvey.GasLeakBelowGround : '',
      reasonInaccessible : leakSurvey ? leakSurvey.ReasonInaccessible : '',
      aoc : leakSurvey ? leakSurvey.AbnormalOperatingCondition : '',
      corrosionGrade : leakSurvey ? leakSurvey.CorrosionGrade : '',
      meterInside : leakSurvey ? leakSurvey.MeterInside : '',
      regulatorMake : leakSurvey ? leakSurvey.RegulatorMake : '',
      regulatorModel : leakSurvey ? leakSurvey.RegulatorModel : '',
      meterLocation : leakSurvey ? leakSurvey.MeterLocation : '',
      status : leakSurvey ? leakSurvey.Status : '',
      comments : leakSurvey ? leakSurvey.Comments : '',
    });
  }

  componentWillUnmount(){
    localStorage.removeItem("leakSurvey");
    window.onbeforeunload = undefined;
  }

  setPictureCount(pictureCount){
    this.setState({pictureCount});
  }

  editStreetAddress(streetAddress){
    this.setState({streetAddress});
  }

  editCity(city){
    this.setState({city});
  }

  editStateAddress(stateAddress){
    this.setState({stateAddress});
  }

  editLELValue(lelValue){
    this.setState({lelValue});
  }

  editGasValue(gasValue){
    this.setState({gasValue});
  }

  editLeakClass(leakClass){
    this.setState({leakClass});
  }

  editServiceOrderNumber(serviceOrderNumber){
    this.setState({serviceOrderNumber});
  }

  editResponder(responder){
    this.setState({responder});
  }

  editMeterManufacturer(meterManufacturer){
    this.setState({meterManufacturer})
  }
  
  editMeterNumber(meterNumber){
    this.setState({meterNumber});
  }

  editObject(object){
    this.setState({object});
  }

  editGasLeakBelowGround(gasLeakBelowGround){
    this.setState({gasLeakBelowGround});
  }

  editReasonInaccessible(reasonInaccessible){
    this.setState({reasonInaccessible});
  }

  editAOC(aoc){
    this.setState({aoc});
  }

  editCorrosionGrade(corrosionGrade){
    this.setState({corrosionGrade});
  }

  editMeterInside(meterInside){
    this.setState({meterInside});
  }

  editStatus(status){
    this.setState({status})
  }

  editComments(comments){
    this.setState({comments});
  }

  editRegulatorMake(regulatorMake){
    this.setState({regulatorMake});
  }

  editRegulatorModel(regulatorModel){
    this.setState({regulatorModel});
  }

  editMeterLocation(meterLocation){
    this.setState({meterLocation});
  }

  goToMap(){
    let leakSurvey = this.state.leakSurvey;
    leakSurvey.Type = "Leak Survey";
    localStorage.setItem("mapLeakSurvey", JSON.stringify(leakSurvey));
    window.location.href = "/leaksurvey?fromDetails=true&detailsType=leakSurvey";
  }

  fetchHistory(){
    this.setState({historyStatusText : "RETRIEVING DATA...", loadingHistory : true});
    let body={
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      LeakSurveyId : this.state.upToDateLeakSurveyId
    }
    fetch(process.env.API_URL + "/api/leakSurvey/leakSurveyHistory",{
      method : "POST",
      headers : {
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        let historyIdList = [];
        response.json().then(responseJson =>{
          let response = JSON.parse(responseJson);
          let leakHistory = {columns:[{label: "", field:"ViewButton"},{label: "MODIFIED DATE", field:"ModifiedDateFormatted"}, {label : "UPDATED BY", field: "ModifiedByDisplayName"}], rows:[]};
          if(response.LeakSurveyHistoryCollection && response.LeakSurveyHistoryCollection.length > 0){
            historyIdList.push(this.state.leakSurvey.LeakSurveyId);
            response.LeakSurveyHistoryCollection.forEach(record =>{
              record.ViewButton = <button className="anchorButton submitButton leakSurvey padded" onClick={() =>{this.viewHistoryRecord(record)}}><div className="text-center full">VIEW</div></button>;
              record.ModifiedDateFormatted = new Date(record.ModifiedDate[record.ModifiedDate.length]-1 !="Z" ? record.ModifiedDate + "Z" : record.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
              leakHistory.rows.push(record);
              historyIdList.push(record.LeakSurveyId);
            });
          }
          this.setState({historyStatusText : "", loadingHistory : false, leakHistory, showHistoryList : true, historyIdList});
        });
      }
      else{
        this.setState({historyStatusText : "There was an error retrieving history. Try again.", loadingHistory : false, showHistoryList : true});
      }
    })
    .catch(e =>{
      console.log(e);
      this.setState({historyStatusText : "There was an error retrieving history. Try again.", loadingHistory : false, showHistoryList : true});
    });
  }

  viewHistoryRecord(record){
    let upToDateLeakData = JSON.parse(JSON.stringify(this.state.leakSurvey));
    this.setState({upToDateLeakData, leakSurvey: record, historyIdList : null, showHistoryList : false, showingHistoryRecord : true, historyRecordId : record.LeakSurveyId});
  }

  closeHistoryList(){
    this.setState({loadingHistory : false, showHistoryList : false, historyIdList : null});
  }

  closeHistoryRecord(){
    let historyIdList = [];
    if(this.state.leakHistory && this.state.leakHistory.rows && this.state.leakHistory.rows.length > 0){
      historyIdList.push(this.state.upToDateLeakData.LeakSurveyId);
      this.state.leakHistory.rows.forEach(record =>{
        historyIdList.push(record.LeakSurveyId);
      });
    }
    this.setState({showingHistoryRecord : false, leakSurvey : this.state.upToDateLeakData, showHistoryList : true, historyIdList});
  }

  render(){
    let type;
    if(this.state.leakSurvey != null){
      switch(this.state.leakSurvey.LeakSurveyTypeId){
        case 14:
          type = "INACCESSIBLE SURVEY";
        break;
        case 15:
          type = "AOC SURVEY";
        break;
        case 16:
          type = "LEAK SURVEY";
        break;
        case 22:
          type = "METER INSPECTION";
        break;
        default:
          type = "LEAK SURVEY";
        break;
      }
    }
    return(
      <div className = "col-10 d-flex justify-content-center p-0 m-0" style={{ backgroundColor : "#E9E9EF"}}>
        <Prompt when={this.state.editing} message='You have unsaved changes, are you sure you want to leave?' />
        { this.state.leakSurvey ? 
          <div className="container-fluid"> 
            <SurveyStrip
              type={type}
              entityTypeId={this.state.leakSurvey.LeakSurveyTypeId}
              city={this.state.leakSurvey.City}
              state={this.state.leakSurvey.State}
              id={this.state.upToDateLeakSurveyId}
              address={this.state.leakSurvey.Address}
              entity={this.state.leakSurvey}
              pictureCount={this.state.pictureCount}
              loadingUpdate={this.state.loadingUpdate}
              editing={this.state.editing}
              setUpEditing={this.setUpEditing}
              cancelEditing={this.cancelEditing}
              saveSurvey={this.saveSurvey}
              closeEntity={(this.state.showHistoryList ? this.closeHistoryList : this.state.showingHistoryRecord ? this.closeHistoryRecord : this.props.closeEntity) || this.props.hideClose}
              goToMap={this.goToMap}
              showingHistoryList={this.state.showHistoryList}
              hideReport={this.state.loadingHistory || this.state.showHistoryList}
              hideEdit={this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord} 
              hideMap={this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord}
              showingHistoryRecord={this.state.showingHistoryRecord}
              modifiedDate={this.state.showingHistoryRecord ? this.state.leakSurvey.ModifiedDate : null}
              historyRecordId={this.state.historyRecordId}
              historyIdList={this.state.historyIdList}
              fetchHistory={this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord ? null : this.fetchHistory}
            />
            {!this.state.loadingHistory && !this.state.showHistoryList && <LeakSurveyBody 
              fromDashboard={this.props.fromDashboard}
              upToDateLeakSurveyId={this.state.upToDateLeakSurveyId}
              showingHistoryRecord={this.state.showingHistoryRecord}
              leakSurvey = {this.state.leakSurvey}
              streetAddress={this.state.streetAddress}
              city={this.state.city}
              stateAddress={this.state.stateAddress}
              lelValue={this.state.lelValue}
              gasValue={this.state.gasValue}
              leakClass={this.state.leakClass}
              serviceOrderNumber={this.state.serviceOrderNumber}
              responder={this.state.responder}
              meterManufacturer={this.state.meterManufacturer}
              meterNumber={this.state.meterNumber}
              object={this.state.object}
              gasLeakBelowGround={this.state.gasLeakBelowGround}
              reasonInaccessible={this.state.reasonInaccessible}
              aoc={this.state.aoc}
              corrosionGrade={this.state.corrosionGrade}
              meterInside={this.state.meterInside}
              comments={this.state.comments}
              status={this.state.status}
              regulatorMake={this.state.regulatorMake}
              regulatorModel={this.state.regulatorModel}
              meterLocation={this.state.meterLocation}
              editMeterLocation={this.editMeterLocation}
              editRegulatorModel={this.editRegulatorModel}
              editRegulatorMake={this.editRegulatorMake}
              editStatus={this.editStatus}
              editComments={this.editComments}
              editMeterInside={this.editMeterInside}
              editCorrosionGrade={this.editCorrosionGrade}
              editAOC={this.editAOC}
              editReasonInaccessible={this.editReasonInaccessible}
              editGasLeakBelowGround={this.editGasLeakBelowGround}
              editObject={this.editObject}
              editMeterNumber={this.editMeterNumber}
              editMeterManufacturer={this.editMeterManufacturer}
              editResponder={this.editResponder}
              editServiceOrderNumber={this.editServiceOrderNumber}
              editLeakClass={this.editLeakClass}
              editLELValue={this.editLELValue}
              editGasValue={this.editGasValue}
              editStateAddress={this.editStateAddress}
              editCity={this.editCity}
              editStreetAddress={this.editStreetAddress}
              setPictureCount={this.setPictureCount} 
              editing={this.state.editing}/>}
            
            {this.state.loadingHistory && 
            <div className="row">
              <div className="spinnerDivUserPage">
                <div className="text-center authSpinnerDiv" style={{padding:10}}>
                  {this.state.historyStatusText != "" ? <div className="authSpinnerDivMessage">
                    {this.state.historyStatusText}
                  </div> : ""}
                  <div className="barLoaderPortal">
                    <BarLoader sizeUnit={"px"} size={150} color={"#095399"}
                      loading={this.state.historyStatusText == "" ? false : this.state.historyStatusText == "No Data Returned" || this.state.historyStatusText == "There was an error retrieving history. Try again." ? false : true}/>
                  </div>
                </div>
              </div>
            </div>}
            {this.state.showHistoryList && 
              <div className="row">
                {!this.state.leakHistory || !this.state.leakHistory.rows || this.state.leakHistory.rows.length == 0 ? <div className="spinnerDivUserPage">
                  <div className="text-center authSpinnerDiv" style={{padding:10}}>
                    <div className="authSpinnerDivMessage">
                      {this.state.historyStatusText ? this.state.historyStatusText : "No History Returned"}
                    </div>
                  </div>
                </div>
                :
                <div className="searchResultsColumn" style={{marginLeft:10, marginRight : 10}}>
                  <MDBDataTable id="history-datatable" searching={false} className="mbdDataTabel" btn Button striped bordered hover data={this.state.leakHistory} paging={false}/>
                </div>}  
              </div>}
            {this.state.updateStatusText != "" &&
          <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={6000} onClose={this.handleClose} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
            {this.state.updateStatusText}
            </Alert>
          </Snackbar>}
          </div>
          :
          <div className = "container-fluid">
            <div className ="text-center detailsErrorMessage">Something went wrong loading the data. Go back to Reporting page and try again.</div>
          </div>
        }
      </div>
    )
  }

}

LeakSurveyPage.propTypes = {
  location: PropTypes.object,
  forceRefresh: PropTypes.func,
  closeEntity: PropTypes.func,
};

export default LeakSurveyPage;