import React from 'react';
import auth from '../../common/Auth';
import ReactGridLayout, { WidthProvider } from 'react-grid-layout';
import Button from 'react-bootstrap/Button';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import DateFnsUtils from '@date-io/date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { GLOBAL } from "../../../Global";
import FormDataPage from '../../portal/FormDataPage';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText'
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateRange from '@material-ui/icons/DateRange';
import GetApp from "@material-ui/icons/GetApp";
import { BarLoader } from 'react-spinners';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListIcon from '@material-ui/icons/List';
import CountBox from './CountBox';
import EditChart from './EditChart';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// Widget Imports
import CountByDate from './CountByDate';
import CountByTechDetailed from './CountByTechDetailed';
import CountByTech from './CountByTech';
import PropTypes from "prop-types";
const GridLayout = WidthProvider(ReactGridLayout);

const defaultLayout = [
  {i: "countByTech", x:0, y:1, w:6, h:5, static: false, minW: 2, maxW: 12, minH:4, maxH: 8},
  {i: "countByTechDetailed", x:0, y:6, w:6, h:5, static: false, minW: 2, maxW: 12, minH:4, maxH: 8},
  {i: "countByDate", x:6, y:1, w:6, h:5, static: false, minW: 5, maxW: 12, minH:4, maxH: 8},
  
  {i: "count-pass", x:0, y: 0, w: 2, h: 1, static: false, minW: 2, maxW: 5, minH: 1, maxH: 1},
  {i: "count-pass1", x:2, y: 0, w: 2, h: 1, static: false, minW: 2, maxW: 5, minH: 1, maxH: 1},
  {i: "count-pass2", x:4, y: 0, w: 2, h: 1, static: false, minW: 2, maxW: 5, minH: 1, maxH: 1},
  {i: "count-pass3", x:6, y: 0, w: 2, h: 1, static: false, minW: 2, maxW: 5, minH: 1, maxH: 1},
  {i: "count-pass-total", x:8, y: 0, w: 2, h: 1, static: false, minW: 2, maxW: 5, minH: 1, maxH: 1}
]

class FormsTab extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      loadingFilter: false,
      layout: defaultLayout,
      data: [],
      siteData: [],
      gpsData: [],
      invData: [],
      leakData: [],
      dateFrom: null,
      dateTo: null,
      fromPickerOpen: false,
      toPickerOpen: false,
      searchVal: "",
      refreshKeys: {
        countByDate: 0,
        countByTech: 0,
        countByTechDetailed: 0,
        countByState: 0
      },
      expandedCustomList:[],
      editMode:false,
      originalLayout: [],
      loadingLayout: false,
      isTableOpen: false,
      allTableData: [],
      allTableDataCount: 0,
      originalData: [],
      useModifiedDate: false,
      filterMessage: "",
      SurveyType: ["Site Survey", "Investigation", "GIS Survey", "GPS Survey"],
      CountByType: [0,0,0,0],
      viewingDetails: false,
      entityToView: null,
      buttonText: "View"
    };
    this.viewEntity = this.viewEntity.bind(this);
  }

  componentDidMount(){
    let today = new Date;
    let d = new Date(); 
    today.setDate(today.getDate());
    d.setDate(d.getDate() -1); // default to 1 day
    this.setState({ dateFrom: d.toLocaleDateString(), dateTo: today.toLocaleDateString()} , () =>{
      let isSavedValue = this.props.getLocallySavedDashboard("forms");
      if(isSavedValue.data && isSavedValue.dateFrom && isSavedValue.dateTo){
        let finalData = this.setupTableRows(isSavedValue.data);
        this.setState({ data: finalData, tableData: finalData, originalData: finalData, dateFrom: isSavedValue.dateFrom, 
          dateTo: isSavedValue.dateTo, searchVal: isSavedValue.searchVal})
      }
      else{
        this.searchData();
      }
    });
    
    // GET USERS LAYOUT
    this.setState({loadingLayout: true});
    let requestBody = {
      UserId: auth.userId(),
      DashboardLayoutUpdate: false,
      User: {
        DashboardLayout: null,
        UserId: auth.userId()
      }
    };

    //console.log('fetch_dashboardLayout', requestBody);

    fetch(process.env.API_URL + "/api/account/dashboardlayout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(requestBody)
    })
    .then(res => res.json())
    .then(response =>{
      let r = JSON.parse(response);
      //console.log('fetch_dashboardLayout_response', r);

      if(r.DashboardLayout){
        let layouts = JSON.parse(r.DashboardLayout);
        this.setState({ originalLayouts: layouts });
        if(layouts.forms){
          let finalLayout = defaultLayout.map(item =>{
            let userItem = layouts.forms.find(userItem => userItem.i === item.i);
            if(userItem){
              return { ...item, x: userItem.x, y: userItem.y, w: userItem.w, h: userItem.h};
            } else{ return item }
          });
          this.setState({ layout: finalLayout });
        }else{
          this.setState({ layout: defaultLayout });  
        }
      }else{
        this.setState({ layout: defaultLayout });
      }
    })
    .catch(error => console.log("error " + error))
    .finally(
      () => {this.setState({ loadingLayout: false}); console.log('fetch_dashboardLayout_response_finally', this.state.loadingLayout, this.state.loading);}
    );
    window.resize();
  }

  componentDidUpdate(){
    window.resize();
  }

  openFromDate = e => {
    e.preventDefault();
    this.setState({ fromPickerOpen: true });
  }

  openToDate = e => {
    e.preventDefault();
    this.setState({ toPickerOpen: true });
  }

  setupTableRows = data => data.map(item =>({
    ...item,
    FormattedModifiedDate: new Date(item.ModifiedDate + "Z").toLocaleString("en-us", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}),
    FormattedCreatedDate: new Date(item.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}),
    //DetailsBtn: <Link to={{pathname: "/survey", survey: item}} className="anchorButton padded">DETAILS</Link>,
    DetailsBtn: <Link to={{ pathname: '/formdata', leakSurvey: item, formData : item}} className="anchorButton padded">DETAILS</Link>,
  }))

  addChart = () => {
    let formChartCollection = this.state.formChartCollection;
    formChartCollection.push({Name:"New Form", FormId:0, Fields:[], FormTriggerCollection:[], Color : '#095399'});
    this.setState({formChartCollection});
  }

  viewEntity(element){

    //console.log('viewEntity', element);

    // copy entity the entity with parse and stringify trick, fixes by-reference use bugs
    this.setState({viewingDetails: true, entityToView: {FormData: JSON.parse(JSON.stringify(element))} });
  }

  getForms(){

    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    this.setState({loadingForms:true});

    fetch(process.env.API_URL +"/api/account/form",{
      method: "POST",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if (json.ResponseType == 0){
            if (json.FormCollection) {
              this.setState({FormCollection: json.FormCollection, loadingForms: false, formGroups: json.FormGroupCollection, formProjects : json.FormProjectCollection});
              //console.log('getForms_response', json.FormCollection);
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error.message);
      this.setState({loadingForms:false});
    })
  }

  saveLayout = (newLayout) => {
    //console.log('saveLayout()', newLayout);

    this.setState({ layout: newLayout });
    let requestBody = {
      UserId: auth.userId(),
      DashboardLayoutUpdate: true,
      User: {
        DashboardLayout: JSON.stringify({
          ...this.state.originalLayouts,
          forms: newLayout
        }),
        UserId: auth.userId()
      }
    };

    fetch(process.env.API_URL + "/api/account/dashboardlayout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(requestBody)
    })
    .then(res => res.json())
    .then(()=>{})
    .catch(error => console.log('error' + error));
  }

  //eslint-disable-next-line
  handleResizeChange = (layout, oldItem, newItem, placeholder, e, element) => {
    e.preventDefault();
    
    // changes refresh keys of widgets based on their id
    let item = newItem.i;
    this.setState({
      refreshKeys: {...this.state.refreshKeys, [item]: this.state.refreshKeys[item] + 1}
    });
    this.saveLayout(layout);
  }

  handleSearchChange = e => {
    e.preventDefault();
    this.setState({ searchVal: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.searchData();
  }

  // MAIN SEARCH FUNCTION
  searchData = () => {
    const { searchVal, dateFrom, dateTo } = this.state;
    this.setState({ loading: true});

    let rb = {
      CompanyId : auth.companyId(),
      SearchParam : searchVal,
      UserId : auth.employeeId(),
      DateFrom : dateFrom ? dateFrom : null,
      DateTo : dateTo ? dateTo : null,
      WebSearch : true,
      Offset: - new Date().getTimezoneOffset() /60,
      UseModifiedDate: this.state.useModifiedDate
    }
    console.time('search');
    fetch(process.env.API_URL + "/api/searchmin", {
      method: "POST",
      headers : {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(rb)
    })
    .then(res => res.json())
    .then(r => {
      if(r.length > 0){
        let response = JSON.parse(r);
        console.timeEnd('search');
        console.log('searchData()::', response);

        let allData = response.FormDataSearchCollection;
        let gpsData = response.FormDataSearchCollection.filter(function(x){ return x.FormTypeId == 3});
        let leakData = response.FormDataSearchCollection.filter(function(x){ return x.FormTypeId == 2});
        let invData = response.FormDataSearchCollection.filter(function(x){ return x.FormTypeId == 1});
        let siteData = response.FormDataSearchCollection.filter(function(x){ return x.FormTypeId == 0});
        let forms = response.FormCollection;

        let countByType = [siteData.length, invData.length, leakData.length, gpsData.length];
        let finalData = this.setupTableRows(allData);
        
        this.setState({data: allData, originalData: finalData, tableData: finalData, CountByType: countByType, forms, isTableOpen:false, filterMessage:"", allTableData:[], allTableDataCount:0});
        this.setState({entityToView: allData[0]});

        const { dateTo, dateFrom, searchVal } = this.state;
        this.props.saveDataLocally({dateTo, dateFrom, searchVal, data: siteData}, "formdata");
      }
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() =>{
      this.setState({ loading : false});
    })
  }

  editMode = () => {
    this.setState({editMode:!this.state.editMode});
  }

  toggleToDatePicker = () => {
    this.setState({ toPickerOpen: !this.state.toPickerOpen });
  };
  
  toggleFromDatePicker = () => {
    this.setState({ fromPickerOpen: !this.state.fromPickerOpen });
  };

  toHandleChange = selectedDate => {
    selectedDate.setHours(new Date().getHours())
    selectedDate.setTime(selectedDate.getTime());
    this.setState({ dateTo: selectedDate.toLocaleDateString()});
  };

  fromHandleChange = selectedDate => {
    selectedDate.setHours(new Date().getHours())
    selectedDate.setTime(selectedDate.getTime());
    this.setState({ dateFrom: selectedDate.toLocaleDateString()});
  };

  filterTable = (filter, message) => {
    console.log('filter', filter);
    var filterLookupValue;
    if (filter.filterType == "ByTechLookup"){
      filterLookupValue = filter.lookupTable[filter.filterValue];
      this.subQuery("ByTech", filterLookupValue);
    }
    else{
      this.subQuery(filter.filterType, filter.filterValue);
    }
    this.setState({isTableOpen: true, filterMessage: message});
  }

  async subQuery(filterType, filterValue){

    this.setState({ loadingFilter: true});

    let rb = {
      CompanyId : auth.companyId(),
      SearchParam : this.state.searchVal,
      DataFilterType: filterType,
      DataFilterValue: filterValue,
      UserId : auth.employeeId(),
      DateFrom : this.state.dateFrom ? this.state.dateFrom : null,
      DateTo : this.state.dateTo ? this.state.dateTo : null,
      UseModifiedDate: this.state.useModifiedDate,
      WebSearch : true,
      Offset: - new Date().getTimezoneOffset() /60,
    }

    console.time('searchDataFilter');
    fetch(process.env.API_URL + "/api/searchDataFilter", {
      method: "POST",
      headers : {
        Accept : "application/json", 
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(rb)
    })
    .then(res => res.json())
    .then(r => {
      if(r.length > 0){
        let response = JSON.parse(r);
        console.timeEnd('searchDataFilter');
        console.log('getDataFilter()::', response);

        var formDataCollection = response.FormDataCollection;
        this.setupData(formDataCollection).then(r => {this.setState({loadingFilter: false, allTableData:r});});
      }
    });
  }

  setupData = async (formDataCollection) => {
    
    // PARSE ROW DATA
    var data, rows, cols, newRow, newRowClean, colsAll;
    var allTableData = [];
    var tableData = {};

    // SEPARATE DATA BY FORMID
    formDataCollection.forEach(e => {

      // FORMAT ROW DATA and push to collection
      tableData = allTableData.filter(obj => {return obj.name === e.Name})[0];
      if (tableData == undefined) { tableData = {name: e.Name, rows: [], cols:[{field:"Details", label:""}], colsAll:[], }; allTableData.push(tableData); }

      rows = tableData.rows;
      cols = tableData.cols;
      colsAll = tableData.colsAll;

      newRow = {Details: <button className="anchorButton submitButton survey padded small" onClick={() => 
        {this.viewEntity(e);}}><div className="text-center full">{this.state.buttonText}</div></button>};

      data = JSON.parse(e.FieldsValues);
      data.forEach(field => {
        if (field.Type != "Section" && field.Type != "Signature" && field.Type != "Scanner"){
          let fieldName = field.Name;
          let fieldValue = field.Value;
          if (cols.length < 10 && cols.filter(obj => {return obj.field === fieldName}).length == 0) cols.push({field: fieldName, label: fieldName});
          if (colsAll.filter(obj => {return obj.field === fieldName}).length == 0) colsAll.push({field: fieldName, label: fieldName});
          newRow[fieldName] = fieldValue;
        }
      });

      rows.push(newRow);
    });

    // ALL TABLES row count
    var allTableDataCount = 0;
    allTableData.forEach(tab => {
      allTableDataCount += tab.rows.length;
    });
    this.setState({allTableDataCount});

    return allTableData;
  }

  toggleTableOpen = e => {
    e.preventDefault();
    this.setState({ isTableOpen: !this.state.isTableOpen});
  }

  cancelFilters = e => {
    e.preventDefault();
    this.setState({ tableData: this.setupTableRows(this.state.originalData), filterMessage: ""})
  }

  openDetailsView = e => {
    this.setState({viewingDetails:!this.state.viewingDetails});
  }

  downloadData = e => {
    e.preventDefault();

    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    var JSZip = require("jszip");
    var zip = new JSZip();
    
    var fileName, data, fileType;
    let today = new Date;
    var zipFileName = today.getFullYear() + " " + month[today.getMonth()] + " " + today.getDay() + "__" + this.state.filterMessage + ".zip";

    this.state.allTableData.forEach(table => {
      fileName = table.name.replace(/\/+$/, '');
      fileType = 'text/csv';
      data = this.arrayToCSV(table.rows, table.colsAll);
      console.log('data', data);
      const blobdata = new Blob([data], { type: fileType });
      zip.file(table.name + '.csv', blobdata);      
    });
    

    // DOWNLOAD ZIP FILE
    zip.generateAsync({type: "blob"}).then(content => {
      const a = document.createElement('a');
      a.download = zipFileName;
      a.href = window.URL.createObjectURL(content);
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
      a.remove();
    });
  }

  downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }

  arrayToCSV(objArray, colsAll) {

    var headersStr = colsAll.map(value => value.label).join(",") + '\r\n';    
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;    
    objArray.forEach(row => {
      headersStr += colsAll.map(col => `"${row[col.label] || ""}"`).join(",") + '\r\n';
    });

    return headersStr;
  }


  closeEntity = () => {
    this.openDetailsView();
  }

  saveCharts = () => {
    console.log('saveCharts');
  }

  render(){

    return(
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>

        {/* DETAILS DIALOG */}
        <Dialog open={this.state.viewingDetails} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth='lg' fullWidth='true' scroll='paper'>
          {/* <DialogTitle id="alert-dialog-title">{""}</DialogTitle> */}
          <DialogContent>
            <FormDataPage location={{formData: this.state.entityToView}} closeEntity={() =>{this.closeEntity()}} fromDashboard={true} hideClose={true}/>
          </DialogContent>
          <DialogActions>
            <Button className="buttonAction btn btn-primary" onClick={this.openDetailsView} color="primary">
              <label className="buttonText small" style={{ margin: 0, cursor: 'pointer'}}>Close</label>
            </Button>
          </DialogActions>
        </Dialog>

        {/* SEARCH BAR */}
        <div className="w-100 d-flex flex-row justify-content-center align-items-center counters-container">
          {!this.state.editMode ? 
            <div className="d-flex align-items-center flex-row" style={{paddingRight:10, width:500}}>
              <input onChange={this.handleSearchChange} value={this.state.searchVal} className="form-control searchInput" type="search" placeholder="Search"/>
              <Button onClick={this.handleSubmit} className="buttonAction btn btn-primary buttonSearch">
                <SearchSharpIcon className="buttonIcon light large"/>
              </Button>
            </div> : <label className="labelHeader" style={{ marginTop: 0, marginLeft: 0, justifyContent:'flex-start', flex:1 }}>EDIT charts</label>
          }

          {/* DATETIME EDIT */}
          {!this.state.editMode ? 
            
            <div className="d-flex flex-row ">

              {/* FROM PICKER */}
              <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.toggleFromDatePicker}  >
                <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
                <label className="buttonText withIcon small" id="FromDate" style={{ margin: 0, cursor: "pointer" }}>
                  {this.state.dateFrom}
                </label>
              </Button>
              <label className="labelSubHeader4 labelSearchBar" >-</label>
              {this.state.fromPickerOpen && ( <DatePicker value={this.state.dateFrom} onChange={this.fromHandleChange} onClose={ () => {this.setState({fromPickerOpen: false})}} open={this.state.fromPickerOpen} />)}
              
              {/* TO PICKER */}
              <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.toggleToDatePicker}  >
                <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
                <label className="buttonText withIcon small" id="ToDate" style={{ margin: 0, cursor: "pointer" }}>
                  {this.state.dateTo}
                </label>
              </Button>
              { this.state.toPickerOpen && ( <DatePicker value={this.state.dateTo} onChange={this.toHandleChange} onClose={ () => {this.setState({toPickerOpen : false})}} open={this.state.toPickerOpen} /> )}
              
              <div className="dateOptionDiv">
                <input id="useCreatedDateButton" className='dateOptionRadio' type = "radio"checked={!this.state.useModifiedDate} onChange={() => {this.setState({useModifiedDate: false})}}/>
                <label htmlFor="useCreatedDateButton" className='dateOptionLabel'>Created Date</label>
                <br/>
                <input id="useModifiedDateButton" className='dateOptionRadio'type = "radio"  checked={this.state.useModifiedDate} onChange={() => {this.setState({useModifiedDate: true})}}/>
                <label htmlFor="useModifiedDateButton" className='dateOptionLabel'>Modified Date</label>
              </div>

            </div> : null

           
          }

        {/* EDIT BUTTON */}
        {/* {!this.state.editMode ? 
          <Button onClick={this.editMode} className="buttonAction buttonAccount small btn btn-primary">
            <EditIcon className="buttonIcon light" />
            <label style={{margin:0, cursor: 'pointer'}} className="buttonText withIcon">EDIT charts</label>
          </Button> :
          <Button onClick={this.editMode} className="buttonAction buttonAccount small confirm btn btn-primary">
            <EditIcon className="buttonIcon light" />
            <label style={{margin:0, cursor: 'pointer'}} className="buttonText withIcon">SAVE charts</label>
          </Button>
        } */}

        </div>

        <div id="forms-tab-container" style={{ overflowY: "hidden"}}>

          {/* LOADING MSG */}
          {this.state.loading || this.state.loadingLayout ? 
            <div className="row mapspin">
              <div className="spinnerDiv">
                <div className="authSpinnerDiv">
                  <div className="authSpinnerDivMessage">{this.state.loading ? "Loading...": "Loading Layout..."}</div>
                  <div className="barLoaderPortal">
                    <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading={true}/>
                  </div>
                </div>
              </div>
            </div>: null}
          {
            this.state.loading || this.state.loadingLayout || this.state.editMode
            ? null
            :
            <div id="forms-content-area" className="h-100">
              <div className="d-flex flex-column justify-content-center align-items-center" 
                style={{ height: this.state.isTableOpen ? "45%": 'calc(100% - 40px)'}}>
                
                {/* Draggable Area */}
                <GridLayout
                  cols={12}
                  id="dashboard-forms-grid"
                  className="w-100 h-100 d-flex justify-content-between hide-vertical-scrollbar"
                  layout={this.state.layout}
                  onLayoutChange={this.saveLayout}
                  rowHeight={65}
                  margin={[10, 10]}
                  autoSize={false}
                  onResizeStop={this.handleResizeChange}
                >
                  <div key="countByTech">
                    <CountByTech filterCallback={this.filterTable} 
                    refreshKey={this.state.refreshKeys.countByTech} 
                    data={this.state.data} 
                    formData={this.state.forms} 
                    dateTo={this.state.dateTo} 
                    dateFrom={this.state.dateFrom}
                    useModified={this.state.useModifiedDate}/>
                  </div>
                  
                  <div key="countByTechDetailed">
                    <CountByTechDetailed filterCallback={this.filterTable} 
                    refreshKey={this.state.refreshKeys.countByTechDetailed} 
                    data={this.state.data}
                    formData={this.state.forms} 
                    dateTo={this.state.dateTo}
                    dateFrom={this.state.dateFrom}
                    useModified={this.state.useModifiedDate}/>
                  </div>
                  
                  <div key="countByDate">
                    <CountByDate filterCallback={this.filterTable} 
                    refreshKey={this.state.refreshKeys.countByDate} 
                    data={this.state.data} 
                    formData={this.state.forms} 
                    dateTo={this.state.dateTo} 
                    dateFrom={this.state.dateFrom}
                    useModified={this.state.useModifiedDate}/>
                  </div>

                  <div key="count-pass-total" className="border border-dark count-pass">
                    <CountBox filterCallback={this.filterTable} id={0} label={'Total'} count={this.state.data.length} 
                      styleclass="border-right text-white border-dark h-100 m-0 d-flex justify-content-center align-items-center surveyBlock"/>
                  </div>

                  <div key="count-pass" className="border border-dark count-pass">
                    <CountBox filterCallback={this.filterTable} id={0} label={this.state.SurveyType[3]} count={this.state.CountByType[3]} 
                      styleclass="border-right text-white border-dark h-100 m-0 d-flex justify-content-center align-items-center gpsCountBlock" />
                  </div>

                  <div key="count-pass1" className="border border-dark count-pass">
                    <CountBox filterCallback={this.filterTable} id={0} label={this.state.SurveyType[2]} count={this.state.CountByType[2]} 
                      styleclass="border-right text-white border-dark h-100 m-0 d-flex justify-content-center align-items-center leakCountBlock"/>
                  </div>

                  <div key="count-pass2" className="border border-dark count-pass ">
                    <CountBox filterCallback={this.filterTable} id={0} label={this.state.SurveyType[1]} count={this.state.CountByType[1]} 
                      styleclass="border-right text-white border-dark h-100 m-0 d-flex justify-content-center align-items-center incidentCountBlock"/>
                  </div>

                  <div key="count-pass3" className="border border-dark count-pass">
                    <CountBox filterCallback={this.filterTable} id={0} label={this.state.SurveyType[0]} count={this.state.CountByType[0]} 
                      styleclass="border-right text-white border-dark h-100 m-0 d-flex justify-content-center align-items-center surveyCountBlock"/>
                  </div>

                </GridLayout>
              </div>
              
              {/*Toggle Button */}
              <button onClick={this.toggleTableOpen} className="toggleButton text-center border d-flex align-items-center justify-content-center w-100">
               
                
                {this.state.isTableOpen ? <ExpandMoreIcon title="Open" style={{color:'#797979'}}/> : <ExpandLessIcon title="Close" style={{color:'#797979'}}/>} 
                <label style={{margin:0, cursor: 'pointer'}} className="labelHeader barHeaderDiv pr-5 org1baseText org1basetextHover"> TABLE DATA </label>
                
              
                <div className="datatable-filter-message">
                  <span className='filterMessage'>{this.state.filterMessage ? "Filter: " + this.state.filterMessage : null}</span>
                  {this.state.isTableOpen ? <ExpandMoreIcon title="Open" style={{color:'#797979'}}/> : <ExpandLessIcon title="Close" style={{color:'#797979'}}/>} 
                </div>

              </button>

              {!this.state.filterMessage ? null : <div className="dashboardDownloadDiv">
                <Button onClick={this.downloadData} className="buttonAction buttonAccount vsmall btn btn-primary" style={{background:'#406a91'}}>
                <GetApp className="buttonIcon light"/>
                  <label style={{margin:0, cursor: 'pointer'}} className="buttonText withIcon">DOWNLOAD DATA</label>
                </Button></div>}

              {/*Datatable Wrapper */}
              {this.state.isTableOpen && <div className="chartTableMainDiv">
                <div style={{ height: "calc(55% - 40px)", overflowY: "scroll"}}>
                  {/* LOADING FILTER MSG */}
                  {this.state.loadingFilter ? 
                    <div className="row mapspin">
                      <div className="spinnerDiv">
                        <div className="authSpinnerDiv">
                          <div className="authSpinnerDivMessage">{this.state.loading ? "Loading...": "Loading Filtered Data..."}</div>
                          <div className="barLoaderPortal">
                            <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading={true}/>
                          </div>
                        </div>
                      </div>
                    </div>: null}

                  {this.state.allTableData && this.state.allTableData.map((td, f_index) => (
                    <div key={"triggerGroup"+f_index} className="dashboardFormTriggerGroup">

                      
                      <div className='formsTableHeader'
                        onClick ={() =>{
                          let expanded = this.state.expandedCustomList;
                          if(expanded.includes(td.name)){
                            let index = expanded.indexOf(td.name);
                            expanded.splice(index, 1);
                          }
                          else{
                            expanded.push(td.name)
                          }
                          this.setState({expandedCustomList: expanded});
                          }}>
                        <div className="formsTableHeaderCount">
                          {td.rows.length}
                        </div>
                        {td.name}
                        {this.state.expandedCustomList && this.state.expandedCustomList.includes(td.name) ? <KeyboardArrowUpIcon className="buttonIcon padded"/>  :
                              <KeyboardArrowDownIcon className="buttonIcon padded"/>}
                      </div>

                      <MDBDataTable
                          id="forms-dashboard-table"
                          className="w-100 formdata-dashboard-table"
                          data={{ columns: td.cols, rows: td.rows || []}}
                          style={{display: this.state.expandedCustomList && this.state.expandedCustomList.includes(td.name) ? '' : 'none' }}
                          small responsiveMd searching={true} btn striped
                          bordered hover sortable paging={false} searchLabel="⁪"
                        />

                    </div>
                  ))}

                  {/* {this.state.allTableData && this.state.allTableData.map((td, i) => {
                      <div key={"triggerGroup"+i} className="accountFormTriggerGroup">
                        <div className="accountFormTriggerGroupHeader">  
                          <label htmlFor={"pickTrigger" + i} className="accountHeader triggerFormLabel">{td.Name}</label>
                        </div>
                        <MDBDataTable
                          id="forms-dashboard-table"
                          className="w-100 formdata-dashboard-table"
                          data={{ columns: td.cols, rows: td.rows || []}}
                          small responsiveMd searching={true} btn striped
                          bordered hover sortable paging={false} searchLabel=""
                        />
                      </div>
                  })} */}
                  
                </div>
              </div>}
            </div>
          }
          {
            this.state.loading || this.state.loadingLayout ? null :
            <div id='forms-edit' className="h-100">
              {/* <EditChart save={this.saveCharts()} formCollection={this.state.FormCollection}/> */}
            </div>
          }
        </div>

      </div>
      </MuiPickersUtilsProvider>
    )
  }

}

FormsTab.propTypes = {
  getLocallySavedDashboard: PropTypes.func,
  saveDataLocally: PropTypes.func
}

export default FormsTab;