import React from "react";
import auth from "../common/Auth";
import SearchStrip from "./SearchStrip";
import PropTypes from "prop-types";
import { BarLoader } from "react-spinners";
import { WebMapView } from "./WebMapViewGPSSurvey";
import Snackbar  from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import DataGroupHelpers from '../../Utility/DataGroupHelpers';
import { get, set } from 'idb-keyval';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from "react-bootstrap";
import CreateTaskButton from './CreateTaskButton';
import OrgUtility from '../../Utility/Utility';
import Functions from '../../Functions/Functions';

class MapPageGPSSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultSet: [],
      apiUrl: process.env.API_URL,
      status: "SEARCH",
      filterUserValue : -1,
      filterStatusValue : -1,
      filterVisitValue : -1,
      filterAssignedValue : -1,
      statusText: "",
      resultsCount: -1,
      surveyCount:0,
      incidentCount:0,
      showProgressBar : false,
      updateStatusText : "",
      dateRangeDays : 1,
      dataGroups: [],
      entityCollections : [],
      multimediaAssets : [],
      arcGisExportErrorList: [],
      showAssignedList : false,
      creatingNewTask : false,
      useModifiedDate : false,
      userAssignedList : [],
      openCount : 0,
      closedCount : 0,
      entityList: []
    };

    this.strip = React.createRef();
    this.employeeIdValue = "";
    this.searchRequest = this.searchRequest.bind(this);
    this.downloadRequest = this.downloadRequest.bind(this);
    this.pushToArcGISRequest = this.pushToArcGISRequest.bind(this);
    this.showProgressBar = this.showProgressBar.bind(this);
    this.setAlert = this.setAlert.bind(this);
    this.updateResultsFromAssignment = this.updateResultsFromAssignment.bind(this);
    this.updateResultsFromDeletedPoint = this.updateResultsFromDeletedPoint.bind(this);
    this.updateResultsFromTaskUnassign = this.updateResultsFromTaskUnassign.bind(this);
    this.doFiltering = this.doFiltering.bind(this);
    this.toggleDataGroup = this.toggleDataGroup.bind(this);
    this.toggleProject = this.toggleProject.bind(this);
    this.expandGroup = this.expandGroup.bind(this);
    this.getMultimediaAssets = this.getMultimediaAssets.bind(this);
    this.setCreatingNewTask = this.setCreatingNewTask.bind(this);
    this.statusTextUpdate = this.statusTextUpdate.bind(this);
    this.pushToArcGISInProgress = false;
    this.getCompanyLayers = this.getCompanyLayers.bind(this);
    this.updateAssignedCount = this.updateAssignedCount.bind(this);
    this.filterFromUserList = this.filterFromUserList.bind(this);
    this.filterUpdate = this.filterUpdate.bind(this);
    this.updateResultsFromGPSSurveyAssignment = this.updateResultsFromGPSSurveyAssignment.bind(this);
    this.updateFromArchive = this.updateFromArchive.bind(this);
  }
  componentDidUpdate(){
    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  async getCompanyLayers(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      GPS : true,
      Web : true
    }
    fetch(process.env.API_URL + "/api/account/getCompanyKMLShapeLayers",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(async r =>{
      if(r.status ==200){
        r.json().then(async responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.CompanyKMLSHAPELayers && json.CompanyKMLSHAPELayers.length > 0){
              await json.CompanyKMLSHAPELayers.forEach(async (layer,index) =>{
                if(layer.FileType == "shape"){
                  let response = await fetch(process.env.API_URL+"/api/GetKMLShapeLayerFile/" + layer.CompanyKMLSHAPELayerId + "/" + layer.SecureCode,{method : "GET"});
                  let layerJson = await response.json();
                  layer.shapeJSON = layerJson;
                }
                if(index == json.CompanyKMLSHAPELayers.length - 1){
                  this.setState({CompanyKMLSHAPELayers : json.CompanyKMLSHAPELayers});
                }
              });
            }
            else{
              this.setState({CompanyKMLSHAPELayers : []});
            }
          }
          else{
            this.setState({CompanyKMLSHAPELayers : []});
          }
        });
      }
      else{
        this.setState({CompanyKMLSHAPELayers : []});
      }
    })
    .catch(error =>{
      console.log(error);
      this.setState({CompanyKMLSHAPELayers : []});
    });

    fetch(process.env.API_URL + "/api/account/getCompanyArcGisLayers",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status ==200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.CompanyArcGisLayers){
              this.setState({CompanyArcGisLayers : json.CompanyArcGisLayers, ARCGISClientSecret : json.ARCGISClientSecret,  ARCGISClientId : json.ARCGISClientId, EnterpriseConnection : json.EnterpriseConnection});
            }
            else{
              this.setState({CompanyArcGisLayers : []});
            }
          }
          else{
            this.setState({CompanyArcGisLayers : []});
          }
        });
      }
      else{
        this.setState({CompanyArcGisLayers : []});
      }
    })
    .catch(error =>{
      console.log(error);
      this.setState({CompanyArcGisLayers : []});
    });
  }
  componentDidMount() {
    this.getCompanyLayers();
    // DEFAULT SEARCH | if no existing results
    if (this.state.resultSet != undefined && this.state.resultSet != null && this.state.resultSet.length == 0){
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let fromDetails = params.get("fromDetails");

      get("resultSet").then((result)=>{
        if (result != undefined && result != null){ 
          const resultSet = JSON.parse(result);
          if (resultSet.FormDataCollection){
            let entityCollections = localStorage.getItem("entityCollections");
            let multimediaAssets = localStorage.getItem("multimediaAssetsGPS");
            let formProjects = [{ProjectLabel : "No Project", showData : true}];
            resultSet.FormDataCollection.forEach(result =>{
              let selectedProject = result.Fields && result.Fields.length > 0 && result.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? result.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
              if(selectedProject && selectedProject != "" && !formProjects.find(x => x.ProjectLabel == selectedProject)){
                formProjects.push({ProjectLabel : selectedProject, showData : true});
              }
            });
            var surveyCount = resultSet.FormDataCollection.length;
            this.setState({resultSet, unfilteredResultSet : resultSet, resultsCount: surveyCount, surveyCount: surveyCount, entityCollections : ((entityCollections && entityCollections != '') ? JSON.parse(entityCollections) : []),formProjects,multimediaAssets : ((multimediaAssets && multimediaAssets != '') ? JSON.parse(multimediaAssets) : [])});
          }
        }
        if(fromDetails == "true"){
          let mapSurvey = localStorage.getItem("mapForm");
          if(mapSurvey && mapSurvey != ""){
            let surv = JSON.parse(mapSurvey);
            let resultSet = this.state.resultSet;
            let pushSurvey = false;
            if(!resultSet.FormDataCollection || resultSet.FormDataCollection.length == 0){
              pushSurvey = true;
              resultSet = [];
            }
            else{
              if(!resultSet.FormDataCollection.find(x => x.FormData && x.FormData.FormDataId == surv.FormData.FormDataId)){
                pushSurvey = true;
              }
            }
            if(pushSurvey){
              resultSet.FormDataCollection.push(surv);
              this.setState({resultSet, unfilteredResultSet : resultSet});
            }
          }
          
        }

        //remove default search
        // if((!this.state.resultSet || this.state.resultSet.length == 0) && !this.checkSuper())
        // {
        //   this.searchRequest(this.props);
        // }
        setTimeout(()=>{this.setState({refresh : true})},1000);
        this.updateAssignedCount(true);
      });
    }
    DataGroupHelpers.getDataGroups(auth.employeeId(), auth.companyId(), auth.token()).then(dataGroups =>{
      this.setState({dataGroups});
    });

  }

  showProgressBar(text){
    this.setState({statusText : text});
  }

  setAlert(alertSeverity, updateStatusText){
    this.setState({alertSeverity, updateStatusText});
  }

  updateResultsFromDeletedPoint(formData){
    let results = this.state.resultSet;
    results.FormDataCollection.forEach(element =>{
      if(element.FormDataId == formData.FormDataId){
        element.GeometryData = formData.GeometryData;
        element.GeometryDataMin = formData.GeometryDataMin;
      }
    });
    set('resultSet', JSON.stringify(results));
    this.setState({resultSet : results});
  }

  updateFromArchive(entity){
    let results = this.state.resultSet;
    let delIndex = -1;
    results.FormDataCollection.forEach((element,index) =>{
      if(element.FormDataId == entity.FormDataId){
        delIndex = index;
      }
    });
    if(delIndex != -1){
      results.FormDataCollection.splice(delIndex, 1);
    }
    this.setState({resultSet : results});
    try { set('resultSet', JSON.stringify(results)); }
    catch(e){
      console.log('error caching resultSet object');
    }
  }

  updateResultsFromAssignment(formTask){
    let results = this.state.unfilteredResultSet;
    results.FormDataCollection.forEach(element =>{
      if(element.FormDataId == formTask.FormDataId){
        if(!formTask.DeletedDate){
          if(element.FormTaskList){
            element.FormTaskList.push(formTask);
          }
          else{
            element.FormTaskList = [formTask];
          }
        }
        else{
          if(element.FormTaskList && element.FormTaskList.length > 0){
            element.FormTaskList = element.FormTaskList.filter(x => x.FormTaskId != formTask.FormTaskId)
          }
        }
      }
    });
    this.setState({resultSet : results, unfilteredResultSet : results}, () =>{this.updateAssignedCount()});
  }

  updateResultsFromTaskUnassign(formTaskList){
    let results = this.state.unfilteredResultSet;
    formTaskList.forEach((formTask)=>{
      results.FormDataCollection.forEach(element =>{
        if(element.FormDataId == formTask.FormDataId){
          if(element.FormTaskList && element.FormTaskList.length > 0){
            element.FormTaskList = element.FormTaskList.filter(x => x.FormTaskId != formTask.FormTaskId)
          }
        }
      });
    });
    this.setState({resultSet : results,unfilteredResultSet : results}, () =>{this.updateAssignedCount(true)});
  }

  dataGroupFilterUpdate(){
    this.doFiltering();
    this.setState({refresh : true});
  }

  addNewEntityToResults(formData){
    let newEntity = formData
    let results = this.state.unfilteredResultSet;
    results.FormDataCollection.push(newEntity);
    this.setState({resultSet: results, unfilteredResultSet : results});
    return newEntity;
  }

  setCreatingNewTask(creatingNewTask){
    this.setState({creatingNewTask});
  }
  
  statusTextUpdate(text){
    this.setState({statusText : text});
  }

  downloadRequest(props){
    let DataGroups;
    let Projects;
    if(this.state.dataGroups && this.state.dataGroups.length > 0){
      DataGroups = [];
      this.state.dataGroups.forEach(group =>{
        if(group.showData){
          DataGroups.push(group.DataGroupId);
        }
      });
    }
    if(this.state.formProjects && this.state.formProjects.length > 0 && this.state.formProjects.filter(x => !x.showData).length > 0){
      Projects = [];
      this.state.formProjects.forEach(project =>{
        if(project.showData){
          Projects.push(project.ProjectLabel);
        }
      });
    }

    let me = this;
    me.setState({ statusText: "Collecting Data..." });

    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      FormDataCollection: this.state.resultSet.FormDataCollection,
      BatchDownload: true,
      EntityType: 13, //GPSSurvey
      DownloadType: props.type,
      ToDate : props.dateTo,
      FromDate : props.dateFrom,
      Offset: -new Date().getTimezoneOffset() / 60,
      DataExportUnit: props.exportUnit,
      UseModifiedDate : this.state.useModifiedDate,
      DataExportTransformation: props.transformOption,
      DataExportTransformWkt: props.exportWkt,
      DataGroups,
      Projects,
      ReturnGPSSurveyData: true,
      ReturnPictures : true,
      EmailData : props.type == "csv",
      DataExportTransformWkid: props.exportWkid,
      SearchParam: props.searchCriteriaValue == undefined ? "" : props.searchCriteriaValue,
    };

    fetch(process.env.API_URL + "/api/data/download/", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r => {
      if (r.status == 200) {
        if(props.type == "csv"){
          this.setState({ statusText :"", updateStatusText : "Your download request has been received. An email will be sent to you shortly.", alertSeverity : "success"}); 
        }
        else{
          r.blob().then(blob => {
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display:none";
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "SiteRight_MapData.zip";
            a.click();
            window.URL.revokeObjectURL(url);
            this.setState({ statusText: ""});
          }).catch(error => {
            console.log(error.message);
            me.setState({ statusText: "" });
          });
        }
      }
      else{
        console.log('/api/data/download/' + r.status)
        me.setState({ statusText: "" });
      }
    }).catch(error => {
      console.log(error.message);
      me.setState({ statusText: "" });
    });
  }

  checkSuper(){
    let isSuper = false;
    let _SuperAdminRoleId = 0;
    let _SuperViewerRoleId = 11;
    var roles = localStorage.getItem("roles");
    if(roles != null) { roles.split(',').forEach(element => { if (element == _SuperAdminRoleId || element == _SuperViewerRoleId) { isSuper = true;}});}
    return isSuper;
  }

  checkMultiCompany(){
    var userAccessCompanyIds = localStorage.getItem("userAccessCompanyIds");
    if(userAccessCompanyIds != null) return true;
    return false;
  }

  pushToArcGISRequest(props){
    if(this.pushToArcGISInProgress) return;
    this.pushToArcGISInProgress = true;
    let companyId = this.checkSuper() || this.checkMultiCompany() ? auth.companyId() : -9999;
    
    let me = this;
    me.setState({ statusText: "Collecting Data..." });

    let today = new Date(); today.setDate(today.getDate());
    let d = new Date(); d.setDate(d.getDate() - 31);

    let body = {
      UserId : auth.employeeId(),
      ArcGisUsername: props.token.username,
      CompanyId: companyId,
      ArcGisToken: props.token.access_token,
      FormDataCollection: this.state.resultSet.FormDataCollection,
      EntityType: 13, //GPSSurvey
      Offset: -new Date().getTimezoneOffset() / 60,
      DataExportUnit: props.dataExportUnit,
      SearchParam: props.searchCriteriaValue == undefined ? "" : props.searchCriteriaValue,
      DateFrom: props.dateFrom == undefined ? d.toLocaleDateString() : props.dateFrom,
      DateTo: props.dateTo == undefined ? today.toLocaleDateString() : props.dateTo,
      UseModifiedDate : this.state.useModifiedDate
    };

    fetch(process.env.API_URL + "/api/arcgis/pushDataToArcGis", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r => {
      if (r.status == 200) {
        me.setState({ statusText: "" });
        this.pushToArcGISInProgress = false;

        r.json().then((errorList)=>{
          if(errorList.length == 0){
            this.setAlert('success','Push to ArcGIS Successful');  
          } else{
            this.setState({arcGisExportErrorList: errorList});
          }
        });
      }
      else{
        if(r.status == 500){
          r.json().then((error)=>{
            this.setAlert('error','Error pushing to ArcGIS: ' + error.exceptionMessage);  
          });
        }
        console.log('/api/arcgis/pushDataToCloudLayers ' + r.status);
        me.setState({ statusText: "" });
        this.pushToArcGISInProgress = false;
      }
    }).catch(error => {
      console.log(error.message);
      this.setAlert('error','Error pushing to ArcGIS: ' + error.message);  
      me.setState({ statusText: "" });
      this.pushToArcGISInProgress = false;
    });
  }

  expandGroup(group){
    let dataGroups = DataGroupHelpers.expandGroup(group.expanded,group.DataGroupId, this.state.dataGroups);
    this.setState({dataGroups});
  }

  toggleDataGroup(id, checked){
    let dataGroups = DataGroupHelpers.toggleDataGroup(id,checked,this.state.dataGroups);
    this.setState({dataGroups}, () =>{this.doFiltering()});
  }

  toggleProject(name,checked){
    let formProjects = this.state.formProjects;
    formProjects.find(x => x.ProjectLabel == name).showData = checked;
    this.setState({formProjects}, () => {this.doFiltering()});
  }

  doFiltering(){
    let dataGroups = this.state.dataGroups;
    let resultSet = {};
    let excludeDataGroupsList = dataGroups.filter(x => !x.showData);
    let excludeProjectList = this.state.formProjects.filter(x => !x.showData);
    let entityCollections = this.state.entityCollections;
    let unfilteredResultSet = this.state.unfilteredResultSet;
    resultSet.GPSSurveyAssignmentCollection = unfilteredResultSet.GPSSurveyAssignmentCollection;
    resultSet.FormDataCollection = [];
    resultSet.FormCollection = unfilteredResultSet.FormCollection;
    resultSet.UsersCollection = unfilteredResultSet.UsersCollection;
    if(unfilteredResultSet.FormDataCollection && unfilteredResultSet.FormDataCollection.length > 0){
      unfilteredResultSet.FormDataCollection.forEach(result =>{
        let push = true;
        if(excludeProjectList && excludeProjectList.length > 0){
          let selectedProject = result.Fields && result.Fields.length > 0 && result.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? result.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
          if(excludeProjectList.find(x => x.ProjectLabel == "No Project") && (!selectedProject || selectedProject == "")){
            push = false;
          }
          if(selectedProject && selectedProject != "" && excludeProjectList.find(x => x.ProjectLabel == selectedProject)){
            push = false;
          }
        }
        if(excludeDataGroupsList && excludeDataGroupsList.length > 0){
          let collectionRecords = entityCollections.filter(x => x.EntityId == result.FormDataId && x.EntityTypeId == 93);
          if(collectionRecords && collectionRecords.length > 0){
            collectionRecords.forEach(collection =>{
              if(excludeDataGroupsList.findIndex(x => x.DataGroupId == collection.DataGroupId) > -1){
                push = false;
              }
            });
          }
        }
        if(push){
          resultSet.FormDataCollection.push(result);
        }
      });
    }
    this.setState({resultSet, updateMapExtent : false});
  }

  filterUpdate(value, type, updateMapExtent, zoomToExtent){
    if (type == 'user') this.setState({filterUserValue: value, zoomToExtent: zoomToExtent, updateMapExtent: updateMapExtent, refresh:true},() =>{this.updateAssignedCount(true);});
    if (type == 'status') this.setState({filterStatusValue: value, zoomToExtent: zoomToExtent, updateMapExtent: updateMapExtent, refresh:true},() =>{this.updateAssignedCount(true);});
    if (type == 'visit') this.setState({filterVisitValue : value, zoomToExtent : zoomToExtent, updateMapExtent : updateMapExtent, refresh : true},() =>{this.updateAssignedCount(true);});
    if (type == 'assigned') this.setState({filterAssignedValue : value, zoomToExtent : zoomToExtent, updateMapExtent : updateMapExtent, refresh : true},() =>{this.updateAssignedCount(true);});
    //For some reason the select statements in the survey strip won't be updated if this is gone.
    setTimeout(() =>{this.setState({updatedFilters : !this.state.updatedFilters})},250);
  }

  filterFromUserList(user){
    let event = {
      target: {
        value: user.UserId
      }
    }
    let otherEvent = {
      target: {
        value: "Assigned"
      }
    }
    this.strip.current.filterUserUpdate(event);
    this.strip.current.filterAssignedUpdate(otherEvent);
  }

  updateResultsFromGPSSurveyAssignment(gpsSurveyAssignment){
    let results = this.state.unfilteredResultSet;
    if(gpsSurveyAssignment.DeletedDate){
      let assignmentIndex = results.GPSSurveyAssignmentCollection.findIndex(x => x.GPSSurveyAssignmentId == gpsSurveyAssignment.GPSSurveyAssignmentId);
      if(assignmentIndex != -1){
        results.GPSSurveyAssignmentCollection.splice(assignmentIndex, 1);
      }
    }
    else{
      //Need to remove current assignment for user if it exists because it was erased when new one was made. 
      let assignmentIndex = results.GPSSurveyAssignmentCollection.findIndex(x => x.AssignedUserId == gpsSurveyAssignment.AssignedUserId);
      if(assignmentIndex != -1){
        results.GPSSurveyAssignmentCollection.splice(assignmentIndex,1);
      }
      results.GPSSurveyAssignmentCollection.push(gpsSurveyAssignment);
    }
    this.setState({resultSet : results, unfilteredResultSet : results}, () =>{this.updateAssignedCount()});
  }

  updateAssignedCount(updateMapExtent){
    if(this.state.unfilteredResultSet){
      let unfilteredResultSet = this.state.unfilteredResultSet;
      let GPSSurveyAssignmentCollection = [];
      let resultSet = {FormDataCollection : [],FormCollection : unfilteredResultSet.FormCollection, FormDataIdSearchResultCollection : unfilteredResultSet.FormDataIdSearchResultCollection};
      
      let openCount = 0;
      let closedCount = 0;
      let assignedCount = 0;
      let userAssignedList = [];
      unfilteredResultSet.UsersCollection.forEach(element =>{
        element.assignedCount = 0;
        element.assignedCountClosed = 0;
        userAssignedList.push(element);
      });
      let filterUserValue = this.state.filterUserValue;
      let filterStatusValue = this.state.filterStatusValue;
      let filterVisitValue = this.state.filterVisitValue;
      let filterAssignedValue = this.state.filterAssignedValue;
      if(filterUserValue == "-1" || filterUserValue == -1){
        GPSSurveyAssignmentCollection = unfilteredResultSet.GPSSurveyAssignmentCollection;
      }
      else{
        GPSSurveyAssignmentCollection = unfilteredResultSet.GPSSurveyAssignmentCollection.filter(x => x.AssignedUserId == filterUserValue);
      }
      unfilteredResultSet.FormDataCollection.forEach(element =>{
        if((!element.FormTaskList || element.FormTaskList.length == 0) && (filterUserValue == -1) && (filterAssignedValue == -1 || filterAssignedValue == "Unassigned") && (filterStatusValue == -1))resultSet.FormDataCollection.push(element);
        else if(element.FormTaskList && element.FormTaskList.length > 0)element.FormTaskList.forEach(formTask =>{
          if(((filterUserValue == -1 || formTask.AssignedUserId == filterUserValue))
          &&
          ((filterAssignedValue == "Unassigned" && (formTask.AssignedUserId == null || formTask.AssignedUserId == "-1")) || 
          (filterAssignedValue == "Assigned" && formTask.AssignedUserId != null && formTask.AssignedUserId != "-1") || 
          (filterAssignedValue == -1)) &&
          (filterStatusValue == -1 || (filterStatusValue == "Open" && (formTask.FormTaskStatus == null || formTask.FormTaskStatus == 0)) || (filterStatusValue == "Closed" && formTask.FormTaskStatus == 1))
          ){
            if(!resultSet.FormDataCollection.find(x => x.FormDataId == element.FormDataID))resultSet.FormDataCollection.push(element);
          }
          if(formTask.FormTaskStatus == 1){
            let user = userAssignedList.find(x => x.UserId == formTask.AssignedUserId);
            if(user){
              closedCount++;
              user.assignedCountClosed++;
              assignedCount++;
            }
          }
          else if(formTask.FormTaskStatus == 0){
            let userClosed = userAssignedList.find(x => x.UserId == formTask.AssignedUserId);
            if(userClosed){
              openCount++;
              userClosed.assignedCount++;
              assignedCount++;
            }
          }
        });
      });
      resultSet.UsersCollection = unfilteredResultSet.UsersCollection;
      resultSet.FormDataCount = resultSet.FormDataCollection.length;
      resultSet.GPSSurveyAssignmentCollection = GPSSurveyAssignmentCollection;
      this.setState({resultSet,userAssignedList, openCount, assignedCount, closedCount, updateMapExtent, refresh: true});
    }
  }

  getMultimediaAssets(EntityList){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      EntityList
    }
    fetch(process.env.API_URL + "/api/entityCollectionMultimedia",{
      method : "POST",
      headers : {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(bodyJson =>{
          let response = JSON.parse(bodyJson);
          response.MultimediaAssets = response.MultimediaAssets.filter(x => x.MediaTypeId != 1);
          response.MultimediaAssets.forEach(asset =>{
            asset.AttachedEntityTypeId = asset.EntityTypeId;
            if(asset.EntityTypeId == 93){
              let entity = this.state.resultSet.find(x => x.FormData != null && x.FormData.FormDataId == asset.EntityId);
              if(entity){
                asset.FormData = entity.FormData;
                asset.EntityTypeId = entity.FormData.FormId;
              }
            }
          });
          this.setState({multimediaAssets : response.MultimediaAssets, refresh : true});
          localStorage.setItem("multimediaAssetsGPS", JSON.stringify(response.MultimediaAssets))
        });
      }
    });
  }

  searchRequest(props) {

    let me = this;
    me.setState({ statusText: "Searching Data..." });

    let today = new Date(); today.setDate(today.getDate());
    let d = new Date(); d.setDate(d.getDate() - 31);

    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      SearchParam: props.searchCriteriaValue == undefined ? "" : props.searchCriteriaValue,
      
      DateFrom: props.dateFrom == undefined ? d.toLocaleDateString() : props.dateFrom,
      DateTo: props.dateTo == undefined ? today.toLocaleDateString() : props.dateTo,

      ReturnSurveyData: false,
      ReturnIncidentData: false,
      ReturnGPSSurveyData: true,
      WebSearch: true,
      MapSearch: true,
      Offset: -new Date().getTimezoneOffset() /60,
      UseModifiedDate : this.state.useModifiedDate
    };

    fetch(process.env.API_URL + "/api/search/", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r => {
      if (r.status == 200) {
        r.json().then(bodyJson => {
          
          if (bodyJson.length > 0) {
            let response = JSON.parse(bodyJson);
            let openCount = 0;
            let closedCount = 0;
            let assignedCount = 0;
            let userAssignedList = [];
            if(response.UserAssignmentCollection){
              Functions.sortOn(response.UserAssignmentCollection, 'LastName');
              response.UserAssignmentCollection.forEach((element) =>{
                element.assignedCount = 0;
                element.assignedCountClosed = 0;
                userAssignedList.push(element);
              })
            }

            if (response.GPSSurveyCollection.FormDataCount > 0){
              me.setState({ updateStatusText: response.GPSSurveyCollection.FormDataCount + " Results"});
            }

            let entityList = [];
            let formProjects = [{ProjectLabel : "No Project", showData : true}];
            
            if(response.GPSSurveyCollection && response.GPSSurveyCollection.FormDataCollection){
              response.GPSSurveyCollection.FormDataCollection.forEach(survey =>{
                entityList.push({EntityId : survey.FormDataId, EntityTypeId : 93});
                let task = response.FormTaskCollection.filter(x => x.FormDataId == survey.FormDataId)
                if(task){
                  survey.FormTaskList  = task;
                  survey.FormTaskList.forEach(formTask =>{
                    if(formTask.FormTaskStatus == 1){
                      let user = userAssignedList.find(x => x.UserId == formTask.AssignedUserId);
                      if(user){
                        closedCount++;
                        user.assignedCount++;
                        assignedCount++;
                      }
                    }
                    else if(formTask.FormTaskStatus == 0){
                      let userClosed = userAssignedList.find(x => x.UserId == formTask.AssignedUserId);
                      if(userClosed){
                        openCount++;
                        userClosed.assignedCountClosed++;
                        assignedCount++;
                      }
                    }
                  })
                }
                let selectedProject = survey.Fields && survey.Fields.length > 0 && survey.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? survey.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
                  if(selectedProject && selectedProject != "" && !formProjects.find(x => x.ProjectLabel == selectedProject)){
                    formProjects.push({ProjectLabel : selectedProject, showData : true});
                  }
              });
            }

            if(response.EntityCollections){
              this.setState({entityCollections : response.EntityCollections});
              localStorage.setItem("entityCollections", JSON.stringify(response.EntityCollections));
            }
            let surveyCollection = response.GPSSurveyCollection;
            Functions.sortOn(response.UserAssignmentCollection, 'LastName');
            surveyCollection.UsersCollection = response.UserAssignmentCollection;
            let totalCount = surveyCollection ? response.GPSSurveyCollection.FormDataCollection.length : 0;
            if (response.GPSSurveyCollection.FormDataCount > 0) totalCount = response.GPSSurveyCollection.FormDataCount;
            surveyCollection.GPSSurveyAssignmentCollection = response.GPSSurveyAssignmentCollection;
            me.setState({ assignedCount,userAssignedList, statusText: "", formProjects, resultsCount: totalCount, surveyCount: totalCount , 
            resultSet: surveyCollection, unfilteredResultSet : surveyCollection, closedCount,openCount,
            detailsSurveyLat : null, detailsSurveyLon : null, totalCount: response.GPSSurveyCollection.FormDataCount});

            this.dataGroupFilterUpdate();
            me.filterUpdate(me.state.filterUserValue, "user", true, true)
            me.filterUpdate(me.state.filterStatusValue, "status", true, true);
            me.filterUpdate(me.state.filterVisitValue, "visit", true, true);
            me.filterUpdate(me.state.filterAssignedValue, "assigned", true, true);
            
            set('resultSet', JSON.stringify(surveyCollection));
            localStorage.setItem("searchStripToDateString", props.dateTo);
            localStorage.setItem("searchStripFromDateString", props.dateFrom);
            localStorage.setItem("searchStripSearchTerm", props.searchCriteriaValue == undefined ? "": props.searchCriteriaValue);
            localStorage.setItem("formProjects", JSON.stringify(formProjects));

            // AGGREGATE SEARCHES
            if (response.GPSSurveyCollection.FormDataIdSearchResultCollection != null)
            {
              for (let index = 0; index < response.GPSSurveyCollection.FormDataIdSearchResultCollection.length-1; index++) {
                
                var indexFrom = response.GPSSurveyCollection.FormDataIdSearchResultCollection[index];
                var indexTo = response.GPSSurveyCollection.FormDataIdSearchResultCollection[index+1];
                this.searchRequestAggregate(props, indexFrom, indexTo);
              }
            }
            
          }
        });
      }
      else{
        me.setState({ statusText: "" });
      }
    })
    .catch(error => {
      console.log(error.message);
      me.setState({ statusText: "" });
    });
  }

  searchRequestAggregate(props, indexFrom, indexTo) {

    let me = this;
    me.setState({ statusText: "Searching Data..." });

    let today = new Date(); today.setDate(today.getDate());
    let d = new Date(); d.setDate(d.getDate() - 31);

    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      SearchParam: props.searchCriteriaValue == undefined ? "" : props.searchCriteriaValue,

      DateFrom: props.dateFrom == undefined ? d.toLocaleDateString() : props.dateFrom,
      DateTo: props.dateTo == undefined ? today.toLocaleDateString() : props.dateTo,
      ReturnSurveyData: false,
      ReturnIncidentData: false,
      ReturnGPSSurveyData: true,
      IndexRangeFrom: indexFrom,
      IndexRangeTo: indexTo,
      WebSearch: true,
      MapSearch: true,
      Offset: -new Date().getTimezoneOffset() /60,
      UseModifiedDate : this.state.useModifiedDate
    };

    fetch(process.env.API_URL + "/api/search/", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r => {
      if (r.status == 200) {
        r.json().then(bodyJson => {
          
          if (bodyJson.length > 0) {
            let response = JSON.parse(bodyJson);
            var resultSet = this.state.resultSet;
            resultSet.FormDataCollection = resultSet.FormDataCollection.concat(response.GPSSurveyCollection.FormDataCollection);

            let loadedCount = resultSet.FormDataCollection.length+1;
            if (loadedCount >= this.state.totalCount){
              me.setState({ updateStatusText: "" });
            }
            else{            
              me.setState({ updateStatusText: "Loading... " + loadedCount + " of " + this.state.totalCount});
            }


            let entityList = this.state.entityList;
            let formProjects = [{ProjectLabel : "No Project", showData : true}];
            let userAssignedList = [];
            let openCount = 0;
            let closedCount = 0;
            let assignedCount = 0;

            if(response.GPSSurveyCollection && response.GPSSurveyCollection.FormDataCollection){
              response.GPSSurveyCollection.FormDataCollection.forEach(survey =>{
                entityList.push({EntityId : survey.FormDataId, EntityTypeId : 93});
                let task = response.FormTaskCollection.filter(x => x.FormDataId == survey.FormDataId)
                if(task.length > 0){

                  survey.FormTaskList  = task;
                  survey.FormTaskList.forEach(formTask =>{
                    if(formTask.FormTaskStatus == 1){
                      let user = userAssignedList.find(x => x.UserId == formTask.AssignedUserId);
                      if(user){
                        closedCount++;
                        user.assignedCount++;
                        assignedCount++;
                      }
                    }
                    else if(formTask.FormTaskStatus == 0){
                      let userClosed = userAssignedList.find(x => x.UserId == formTask.AssignedUserId);
                      if(userClosed){
                        openCount++;
                        userClosed.assignedCountClosed++;
                        assignedCount++;
                      }
                    }
                  })
                }
                let selectedProject = survey.Fields && survey.Fields.length > 0 && survey.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? survey.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
                  if(selectedProject && selectedProject != "" && !formProjects.find(x => x.ProjectLabel == selectedProject)){
                    formProjects.push({ProjectLabel : selectedProject, showData : true});
                  }
              });

              
            me.setState({ statusText: "", resultSet: resultSet,assignedCount,userAssignedList,openCount,closedCount, formProjects, unfilteredResultSet : resultSet, detailsSurveyLat : null, detailsSurveyLon : null, entityList});
            this.dataGroupFilterUpdate();
            
            set('resultSet', JSON.stringify(resultSet));
            localStorage.setItem("searchStripToDateString", props.dateTo);
            localStorage.setItem("searchStripFromDateString", props.dateFrom);
            localStorage.setItem("searchStripSearchTerm", props.searchCriteriaValue == undefined ? "": props.searchCriteriaValue);
            }
          }
        });
      }
    })
    .catch(error => {
      console.log(error.message);
      me.setState({ statusText: "" });
    });
  }

  updateSearchValue(){
    // not implemented
  }
  disableType(){
    // not implemented
  }

  handleClose(){

  }

  expandFormDataMarker(formDataId){
    let resultSet = JSON.parse(JSON.stringify(this.state.resultSet));
    let formData = resultSet.FormDataCollection.find(( formData ) => formData.FormDataId === formDataId );
    if(formData){
      formData.isExpanded = true;
    }
    this.setState({resultSet: resultSet});
  }
  render() {
    return (
      <div className="col-10 d-flex justify-content-center p-0 m-0" style={{ backgroundColor: "#E9E9EF" }} >
        <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText : ""})}} autoHideDuration={6000} onClose={this.handleClose}
          anchorOrigin={{ vertical : 'top', horizontal: 'center'}}>
            <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
              {this.state.updateStatusText}
            </Alert>
        </Snackbar>
        <div className="container-fluid">
          <SearchStrip ref={this.strip} gpsMap={true} searchRequest={this.searchRequest} pushToArcGISRequest={this.pushToArcGISRequest} downloadRequest={this.downloadRequest} resultsCount={this.state.resultsCount} 
            gpsCount={this.state.resultsCount} incidentCount={-1} mapDownload={true} updateSearchValue={this.updateSearchValue.bind(this)} filterUpdate={this.filterUpdate}
            dataGroups={this.state.dataGroups} toggleDataGroup={this.toggleDataGroup} expandGroup={this.expandGroup} searching={this.state.statusText == "Searching Data..."}
            disableType={this.disableType.bind(this)} toggleLayerList={this.toggleLayerList} defaultDateRangeDays={this.state.dateRangeDays}
            useModifiedDate={this.state.useModifiedDate} updateUseModifiedDate={(value) =>{this.setState({useModifiedDate : value})}}
            formProjects={this.state.formProjects} toggleProject={this.toggleProject}/> 
          {this.state.statusText == "" && <CreateTaskButton fromGPS={true} createTask={()=>{document.getElementById("createTaskDiv").style.display = "block";}}/>}
          <div id="mapContainerDiv" className="mapContainerDiv">

            {this.state.statusText != "" ? <div className="row mapspin">
              <div className="spinnerDiv">
                <div className="authSpinnerDiv">
                  {this.state.statusText != "" ? <div className="authSpinnerDivMessage">
                    {this.state.statusText}
                  </div>: ''}
                  <div className="barLoaderPortal">
                    <BarLoader sizeUnit={"px"} size={150} color={"#095399"}
                      loading={ this.state.statusText == "" ? false : this.state.statusText == "No Data Returned" || this.state.statusText.indexOf("ArcGIS") != -1 ? false : true }/>
                  </div>
                </div>
              </div>
            </div> : ''}

            {this.state.showProgressBar ? 
              <div className="row mapspin">
                <div className="spinnerDiv">
                  <div className="authSpinnerDiv">
                    <div className="barLoaderPortal">
                      <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading={this.state.showProgressBar}/>
                    </div>
                  </div>
                </div>
              </div>
               : null}

            <div  className="assignViewDropdown" >
              <div ref={node => this.node = node} onClick={()=>{this.setState({showAssignedList: !this.state.showAssignedList, refresh:false})}} className="assignViewDropdownHeader">
                <div className="leakCountBlock countBlock openBlock" >{this.state.openCount}</div>
                <span className="assignViewHeaderText">ASSIGNED</span>
                <div className="leakCountBlock closedCountBlock countBlock closedBlock">{this.state.closedCount}</div>
              </div>
              {this.state.showAssignedList && <div className="assignViewDropdownContent">
                <div className="assignViewOpenClosed">
                  <div className="openBlockText">open</div>
                  <div className="closeBlockText">closed</div>
                </div>
                {this.state.userAssignedList.map((user, index) =>
                <div key={index} style={{marginTop: 5, marginBotom:5}}>
                  <div className="assignViewDropDownItem" onClick={()=>{this.filterFromUserList(user)}}>
                    <div className="leakCountBlock countBlock openAssignedBlock">{user.assignedCount}</div>
                    <span className="assignViewUserName">{user.DisplayName}</span>
                    <div className="leakCountBlock closedCountBlock countBlock closedAssignedBlock">{user.assignedCountClosed}</div>
                  </div>
                </div>
                )}  
                <div style={{minHeight: 35}}/>
              </div>}
            </div>
            
            {this.state.CompanyArcGisLayers && this.state.CompanyKMLSHAPELayers && <WebMapView updateFromArchive ={ this.updateFromArchive} EnterpriseConnection={this.state.EnterpriseConnection} CompanyArcGisLayers ={this.state.CompanyArcGisLayers} CompanyKMLSHAPELayers={this.state.CompanyKMLSHAPELayers} ARCGISClientSecret ={this.state.ARCGISClientSecret} ARCGISClientId={this.state.ARCGISClientId} 
            updateResultsFromDeletedPoint={this.updateResultsFromDeletedPoint} addNewEntityToResults={this.addNewEntityToResults.bind(this)} statusTextUpdate={this.statusTextUpdate} 
            creatingNewTask={this.state.creatingNewTask} setCreatingNewTask={this.setCreatingNewTask} expandFormDataMarker={this.expandFormDataMarker.bind(this)} 
            updateResultsFromTaskUnassign={this.updateResultsFromTaskUnassign} updateResultsFromAssignment={this.updateResultsFromAssignment} showProgressBar={this.showProgressBar} 
            setAlert={this.setAlert} resultSet={this.state.resultSet} searchRequest={this.searchRequest} multimediaAssets={this.state.multimediaAssets} detailsSurveyLat={this.state.detailsSurveyLat}
            detailsSurveyLon={this.state.detailsSurveyLon} updateResultsFromGPSSurveyAssignment={this.updateResultsFromGPSSurveyAssignment}/>}

          </div>

          <Dialog open={this.state.arcGisExportErrorList.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">ArcGIS Data Export Error</DialogTitle>
          <DialogContent>
            <DialogContentText >
              The following errors occurred while exporting. Any form and/or layer not listed here was successfully exported to ArcGIS.
            </DialogContentText>

            {this.state.arcGisExportErrorList.map((error, error_index) => (
              <DialogContentText  key={'arcGisErrorText_'+ error_index} >
                <strong>{error.source}</strong><br/>
                {"Error Code: " + error.code}<br/>
                {error.description}
              </DialogContentText>
            ))}

          </DialogContent>
          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={() => this.setState({arcGisExportErrorList: []})}>
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>OK</label>
            </Button>
          </DialogActions>
        </Dialog>

        </div>
      </div>
    );
  }
}

MapPageGPSSurvey.propTypes = {
  location: PropTypes.object,
  forceRefresh: PropTypes.func
};

export default MapPageGPSSurvey;