class OrgUtility {
  constructor(){
    
    // Determine OrgId from host
    var orgId = _OrgID.LaunchPoint;
    //var orgId = _OrgID.TicketAccess;
    

    if (window.location.host.includes('vivax')) orgId = _OrgID.Vivax;
    if (window.location.href.includes('fieldsource') || window.location.href.includes('opsource')) orgId = _OrgID.Utilisource;
    if (window.location.href.includes('pcges')) orgId = _OrgID.TicketAccess;
    this.state = {orgId};

    document.title = this.getAppName();
  }

  getOrgId(){
    return this.state.orgId;
  }

  getAppName(){
    if (this.state.orgId == _OrgID.LaunchPoint) return 'SiteRight';
    if (this.state.orgId == _OrgID.Vivax) return 'VMGoMap';
    if (this.state.orgId == _OrgID.Utilisource) return 'FieldSource';
    if (this.state.orgId == _OrgID.TicketAccess) return 'TicketAccess';
  }

  getOrgName(){
    if (this.state.orgId == _OrgID.LaunchPoint) return 'LaunchPoint';
    if (this.state.orgId == _OrgID.Vivax) return 'Vivax';
    if (this.state.orgId == _OrgID.Utilisource) return 'Utilisource';
    if (this.state.orgId == _OrgID.TicketAccess) return 'TicketAccess';
  }

  displayFooter(){
    if (this.state.orgId == _OrgID.LaunchPoint) return true;
    return false;
  }

  displayInternalLinks(){
    if (this.state.orgId == _OrgID.LaunchPoint || this.state.orgId == _OrgID.TicketAccess) return true;
    return false;
  }

  displayInvestigationData(){
    // Do not show investigation for Vivax
    if (this.state.orgId == _OrgID.Vivax) return false;
    return true;
  }

  displaySiteSafeContent(){
    // Do not show investigation for Vivax
    if (this.state.orgId == _OrgID.LaunchPoint) return true;
    return false;
  }

  updateOrgCSS(){

    var updateElementConfig = [];

    // CLASSES BY ORGID
    var baseColor = ' org'+this.state.orgId+'baseColor';
    var baseBg = ' org'+this.state.orgId+'baseBg';
    var baseText = ' org'+this.state.orgId+'baseText';
    var mainText = ' org'+this.state.orgId+'mainText';
    var baseActive = ' org'+this.state.orgId+'baseActive';
    var baseIcon = ' org'+this.state.orgId+'buttonIcon';
    var basetextHover =  ' org'+this.state.orgId+'basetextHover';
    var basebuttonHover =  ' org'+this.state.orgId+'basebuttonHover';
    var contentMain = ' org'+this.state.orgId+'contentMain';

    // ELEMENT UPDATE CONFIGURATION
    updateElementConfig.push({ classAdd:baseBg, queryNames:['.homePageTopBannerBackground', '.backgroundBase']});
    updateElementConfig.push({ classAdd:baseColor, queryNames:['.buttonAction','.buttonAccount']});
    updateElementConfig.push({ classAdd:baseText, queryNames:['.labelHeaderBar','.barHeaderDiv', '.labelHeader']});
    updateElementConfig.push({ classAdd:mainText, queryNames:['.mainHeader']});
    updateElementConfig.push({ classAdd:contentMain, queryNames:['.contentMain']});
    updateElementConfig.push({ classAdd:baseActive, queryNames:['.iconButton.active']});
    updateElementConfig.push({ classAdd:baseIcon, queryNames:['.iconButton']});
    updateElementConfig.push({ classAdd:basetextHover, queryNames:['.barHeaderDiv','.iconButton']});
    updateElementConfig.push({ classAdd:basebuttonHover, queryNames:['.buttonAction']});

    // UPDATE EACH ELEMENT WITH CLASSES SPECIFIED IN CONFIG
    Array.from(updateElementConfig).forEach((elementUpdateConfig) => {
      Array.from(elementUpdateConfig.queryNames).forEach((elementName) => {
        document.querySelectorAll(elementName).forEach(function(element) {
          //console.log(element);
          if (element && element.className && !element.className.includes(elementUpdateConfig.classAdd))
            element.className += elementUpdateConfig.classAdd;
        });
      });
    });


    Array.from(document.getElementsByClassName('buttonCreateTask')).forEach((element) => {
      //console.log(element.style, baseIcon.color)
      element.style.color = '#DDD !important';
    });

    Array.from(document.getElementsByClassName('buttonLayerLegend ')).forEach((element) => {
      //console.log(element.style, baseIcon.color)
      element.style.color = '#DDD !important';
    });

    if (this.state.orgId != _OrgID.TicketAccess) return;

    // SVG ELEMENTS (only apply to External)
    Array.from(document.getElementsByClassName('buttonIcon light')).forEach((element) => {
      //console.log(element.style, baseIcon.color)
      element.style.color = '#333';
    });

    Array.from(document.getElementsByClassName('sideBarIcon active')).forEach((element) => {
      //console.log(element.style, baseIcon.color)
      element.style.color = '#009ddc';
    });

    Array.from(document.getElementsByClassName('barHeaderDiv selected')).forEach((element) => {
      //console.log(element.style, baseIcon.color)
      element.style.borderBottomColor = '#333';
    });
  }

}

const _OrgID = {
  LaunchPoint: 1,
  Vivax: 2,
  Utilisource: 3,
  TicketAccess: 4
}

export default OrgUtility;