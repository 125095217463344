import React from 'react';
import { Link } from "react-router-dom";

class PasswordResetPage extends React.Component{
    constructor(){
        super();
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let token = params.get('t');
        let email = params.get('e');
        this.state ={token, email, status : "loading"};
    }
    componentDidMount(){
        let req = new XMLHttpRequest();
        req.open("POST", process.env.API_URL + "/api/account/reset",true);
        req.setRequestHeader("Accept","application/json");
        req.setRequestHeader("Content-Type", "application/json");
        let body = {
            Token : this.state.token,
            User :{
                EmailAddress : this.state.email
            }
        }
        req.onload = () =>{
            if(req.status == 200){
                //let response = JSON.parse(req.responseText);
                this.setState({status : "success"});
            }
            else{
                //let response = JSON.parse(req.responseText);
                this.setState({status : "fail"});
            }
        }
        req.onerror = () =>{
            this.setState({status : "fail"});
        }
        req.send(JSON.stringify(body));
    }

    render(){
        return <div className = "col-8 d-flex justify-content-center p-0 mb-5">
                <div className = "">
                    <div className = "col-12 d-flex justify-content-center mt-5 loginTitle fullBorder text-center" style ={{maxWidth : 900, minWidth: 900}}>
                        {this.state.status == "loading" ? 
                        <div>
                          <h4 style = {{ marginBottom : 25, marginTop : 25}}>Loading...</h4>
                        </div> 
                        : this.state.status == "success"?
                        <div>
                        <h5 style ={{margin : 40}}>Account password has been reset</h5>
                        <h5 style = {{  marginBottom : 25, marginTop : 25}}>New temporary password sent to email address</h5>
                        </div> :
                        <div>
                          <h5 style ={{margin : 40}}>Something went wrong trying to reset your password</h5>
                          <h5 style = {{  marginBottom : 25, marginTop : 25}}>Go back and try again</h5>
                        </div>}
                        <div className ="row d-flex align-items-center justify-content-center">
                        <Link to ="/login">
                            <div 
                            className ="col-12 d-flex align-items-center justify-content-center buttonAction"
                            style ={{borderLeft: "2px solid #ffffff", borderRight: "2px solid #ffffff", borderBottom: "2px solid #ffffff", marginBottom : 10, height:40}}>
                                <label className ="buttonText">Return to Sign In Page</label>
                            </div>
                        </Link>
                    </div>
                    </div>
                </div>
            
        </div>
    }
}

export default PasswordResetPage;