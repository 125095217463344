/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const AuditByPerson = (props) => {
  const [chart, setChart] = React.useState(null);
  const [categories, setCategories] = useState([]);
  const [data, setData] = React.useState([]);

  const chartCallback = chart => {
    setChart(chart);
  }

  React.useEffect(() => {
    let { auditData } = props;
    let countsByPerson = {};
    let auditCounts = {};
    // Getting individual counts per user for each audit type
    auditData.forEach(audit => {
      if(!auditCounts[audit.FriendlyAuditName]){
        auditCounts[audit.FriendlyAuditName] = [];
      }
      if(countsByPerson[audit.TechnicianDisplayName]){
        countsByPerson[audit.TechnicianDisplayName][audit.FriendlyAuditName] 
        ? countsByPerson[audit.TechnicianDisplayName][audit.FriendlyAuditName] += 1 
        : countsByPerson[audit.TechnicianDisplayName][audit.FriendlyAuditName] = 1;
      }else{
        countsByPerson[audit.TechnicianDisplayName] = {};
        countsByPerson[audit.TechnicianDisplayName][audit.FriendlyAuditName] = 1;
      }
    })
    let finalSeries = [];
    // Creating ordered key value pairs for each audit type
    Object.values(countsByPerson).forEach(counts => {
      Object.keys(auditCounts).forEach(auditType => {
        if(counts[auditType]){
          auditCounts[auditType].push(counts[auditType]);
        }else{
          auditCounts[auditType].push(null);
        }
      })
    })
    // Setting up final series data for chart, creating name, data object
    Object.keys(auditCounts).forEach(key => finalSeries.push({ name: key, data: auditCounts[key] }))
    setData(finalSeries);
    // Get ordered people
    let c = Object.keys(countsByPerson);
    setCategories(c);
  }, [])

  React.useEffect(() => {
    if(chart){
      chart.reflow()
    }
  }, [props.refreshKey])

  return (
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark" }}
      highcharts={Highcharts}
      options={
        {
          title : { text: "Audits By Person" },
          xAxis: {
            categories: categories,
            crosshair: true
          },
          plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: "Audits"
            }
          },
          colors: ["#16558F", "#2D7952", "#DE8C26", "#8A4A4A"],
          chart: {
            type: 'column',
            backgroundColor: "#E9E9EF",
          },   
          series : data,
          credits : {
            enabled: false
          },
          legend : {
            enabled: true
          }
      } }
      allowChartUpdate
      constructorType="chart"
      callback={chartCallback}
    />
  );
}

export default AuditByPerson;