/* eslint react/prop-types: 0 */
import React from 'react';
//eslint-disable-next-line
import { Link } from "react-router-dom";
//eslint-disable-next-line
import { BarLoader } from "react-spinners"; 
//eslint-disable-next-line
import { Button } from "react-bootstrap";
//eslint-disable-next-line
import AssignmentTwoToneIcon from '@material-ui/icons/AssignmentSharp';
//eslint-disable-next-line
import Dialog from '@material-ui/core/Dialog';
//eslint-disable-next-line
import DialogActions from '@material-ui/core/DialogActions';
//eslint-disable-next-line
import DialogTitle from '@material-ui/core/DialogTitle';
//eslint-disable-next-line
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
//eslint-disable-next-line
import PropTypes from 'prop-types';

class Privacy extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {width:0, height:0}
  }

  componentDidMount(){
    this.updateWindowDimensions();
    window.addEventListener('resize',this.updateWindowDimensions);
    this.props.swap();
  }

  componentWillUnmount(){
    window.removeEventListener('resize',this.updateWindowDimensions);
  }

  updateWindowDimensions(){
    this.setState({width: window.innerWidth, height: window.innerHeight});
  }
   
  render(){
    return <div id='privacyDiv' className='privacyDiv'>
      <Typography gutterBottom align="center" variant="h5" fontWeight="bold">LAUNCHPOINT&#39;S Privacy Policy</Typography>
      <br/><Typography gutterBottom align="left" variant="h5">Privacy and Sensitive Information</Typography>

      <Typography gutterBottom align="left">This Privacy Notice describes how LAUNCHPOINT uses your personal information and respects your privacy rights.</Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT limits its access, collection, use, and sharing of personal or sensitive data acquired through the app to purposes 
        directly related to providing and improving the features of the app (e.g. location services providing verification of jobsite location). LAUNCHPOINT does not extend usage of this data for serving advertising.</Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT accesses and collects user data from Location Services, Camera, Background Location, Fingerprints, and Face ID.</Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT uses Finger Print and Face ID data for authentication and authorization for app access.  LAUNCHPOINT accesses camera services to provide on-site photo documentation. LAUNCHPOINT accesses location services and uses the collected data to provide verification of jobsite location.  Access to location services must be authorized by the user of the application but is not required.</Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT accesses and collects user data from background location specifically in the GAS LEAK SURVEY module.  A prominent disclose & consent requirement is displayed prior to the user’s authorization of the service.</Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT handles all personal or sensitive user data securely, including transmitting it using modern cryptography (for example, over HTTPS).</Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT uses a runtime permissions request whenever available.</Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT does not sell personal or sensitive user data.</Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT collects information as part of its business operations, to provide services, to respond to requests and offer customer support, to fulfill legal and contractual obligations and to build and enhance its products and services.  Users provide some of this data directly, such as when a LaunchPoint service is ordered, there is interaction with LAUNCHPOINT, customer support is utilized, or when logging into web sites (including mobile websites and apps) owned and controlled by LAUNCHPOINT (“LAUNCHPOINT’s Sites”).</Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT also collects information through the use of Cookies and similar technologies (hereafter “Cookies”) during interaction with LAUNCHPOINT’s Sites.</Typography>

      <br/><Typography gutterBottom align="left" variant="h5">Information Provided to LaunchPoint Voluntarily</Typography>
      <Typography gutterBottom align="left">When an account is created, a purchase is made or information requested from LAUNCHPOINT, LAUNCHPOINT will ask for things like contact, billing, shipping and communication information, payment card or financial account data, and account ID or credentials in order to fulfill the request.</Typography>
      <Typography gutterBottom align="left">If personal information is submitted about other people to LAUNCHPOINT or to our service providers, the user is responsible for making sure they have the authority to do so and to allow us to use their personal information in accordance with this Privacy Notice (for example, by asking for their consent).</Typography>
      
      <br/><Typography gutterBottom align="left" variant="h5">Information Obtained Through LaunchPoint&#39;s Sites & Services</Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT collects information about how visitors interact with LAUNCHPOINT’s Sites through the use of Cookies.</Typography>

      <br/><Typography gutterBottom align="left" variant="h5">How LaunchPoint Shares Information </Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT does not share personal information so that it can fulfill orders, process payments, prepare and send advertising, or as otherwise necessary to complete any transaction or provide any product or service users have requested or authorized. When sharing takes place, LAUNCHPOINT uses controls to establish protections for the personal information that is shared. Sharing may take place with affiliates and subsidiaries; companies working on our behalf; law enforcement or government officials and for purposes consistent with this Notice including to protect LAUNCHPOINT’s customers; to protect lives; to maintain the security of LAUNCHPOINT’s products and services; and to protect LAUNCHPOINT’s rights and property.</Typography>
      <Typography gutterBottom align="left">Retention periods for this personal information can vary significantly based on the type of information and how it is used.  LAUNCHPOINT’s retention periods are based on criteria that include legally mandated retention periods, pending or potential litigation, LAUNCHPOINT’s intellectual property or ownership rights, contract requirements, operational directives or needs, and historical archiving.  When personal information is removed from LAUNCHPOINT’s systems it will be deleted or destroyed.</Typography>

      <br/><Typography gutterBottom align="left" variant="h5">Third Party Vendors</Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT utilizes the services and products of various third parties, including but not limited to Microsoft Corporation.  To the extent that such third-party vendors have access to users’ personal information, how they treat that information is governed by their own privacy policies which may be obtained through their respective websites.</Typography>
      
      <br/><Typography gutterBottom align="left" variant="h5">Your Rights</Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT respects users’ rights in how personal information is used and shared. Users may request access or corrections to personal data and make choices about the kinds of marketing materials received or choose not to receive.</Typography>

      <br/><Typography gutterBottom align="left" variant="h5">Changes to This Policy</Typography>
      <Typography gutterBottom align="left">LAUNCHPOINT may change this Privacy Notice from time to time.  Please review this Privacy Notice periodically to stay informed about how LAUNCHPOINT collects, uses, and shares personal information.</Typography>              

      </div>
  }
}

export default Privacy;