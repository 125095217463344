import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import moment from 'moment';
import PropTypes from "prop-types";

const CountByTech = (props) => {
  const [graphCategories, setGraphCategories] = React.useState([]);
  const [graphData, setGraphData] = React.useState([]);
  const [chart, setChart] = React.useState(null);

  React.useEffect(() => {
    
    console.time('CountByTech');
    let parsed = {};
    props.data.forEach(survey => {

      if(survey.ModifiedByDisplayName && props.useModified){
        parsed[survey.ModifiedByDisplayName]
        ? parsed[survey.ModifiedByDisplayName] = { name: survey.ModifiedByDisplayName, survey: survey, y: parsed[survey.ModifiedByDisplayName].y + 1}
        : parsed[survey.ModifiedByDisplayName] = { name: survey.ModifiedByDisplayName, survey: survey, y : 1}
      }

      if(survey.CreatedByDisplayName && !props.useModified){
        parsed[survey.CreatedByDisplayName]
        ? parsed[survey.CreatedByDisplayName] = { name: survey.CreatedByDisplayName, survey: survey, y: parsed[survey.CreatedByDisplayName].y + 1}
        : parsed[survey.CreatedByDisplayName] = { name: survey.CreatedByDisplayName, survey: survey, y : 1}
      }
    
    });
    
    setGraphCategories(Object.keys(parsed));
    setGraphData(Object.values(parsed));

    console.timeEnd('CountByTech');
  }, [props.data])

  const chartCallback = c => setChart(c);

  return(
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark"}}
      highcharts={Highcharts}
      options={{
        title: {text: "Form Count By Tech"},
        xAxis: {
          categories: graphCategories,
        },
        plotOptions: {
          series: {
            cursor : "pointer",
            point: {
              events: {
                click: function(event){
                  props.filterCallback({ 
                    filterType: 'ByTech',
                    filterValue: props.useModified ? event.point.survey.ModifiedBy : event.point.survey.CreatedBy,
                  },
                  `${event.point.category}`)
                }
              }
            },
            dataLabels: {
              enabled: true,
              color: 'black',
              align: 'right',
              x: -3,
              y: 3,
              style: {
                  color: '#333',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  fontFamily: 'Trebuchet MS, Verdana, sans-serif'
              }
          },
          }
        },
        yAxis : {
          min: 0,
          title: { text: "Surveys" }
        },
        colors :["#16558F", "#2D7952", "#DE8C26"],
        chart:{
          type: 'bar',
          backgroundColor : "#E9E9EF",
        },
        column: {
          dataLabels: {
              verticalAlign: 'bottom',
              allowOverlap: true,
              enabled: true,
              inside: true
        }},
        series: [
          {
            name: "Surveys",
            data : graphData
          }
        ],
        credits: { enabled : false },
        legend : { enabled: false },
      }}
      allowChartUpdate
      callback={chartCallback}
      constructorType="chart"
    />
  )
}

CountByTech.propTypes = {
  data : PropTypes.array,
  refreshKey : PropTypes.number,
  filterCallback : PropTypes.func,
  useModified: PropTypes.bool
}

export default CountByTech;