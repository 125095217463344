import React from 'react';
import PropTypes from 'prop-types';
import AddBoxSharpIcon from '@material-ui/icons/LibraryAddTwoTone';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import SaveSharpIcon from '@material-ui/icons/SaveTwoTone';
import { Button } from "react-bootstrap";
import { BarLoader } from 'react-spinners';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PeopleIcon from '@material-ui/icons/People';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import auth from '../common/Auth';
import Functions from '../../Functions/Functions';

class DataGroupTab extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      selectedGroupId : -1,
      showWarning : false,
      filterValue : "",
      users : [],
      filteredUsers : [],
      selectedUsers : [],
      usersInChildGroup:[],
      userSelected : {
        UserId : -1,
        DisplayNameProper : ""
      },
      dataGroupsForUser : []
    }
    this.assignmentRef = React.createRef();
    this.renderDataGroup = this.renderDataGroup.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.filterUsers = this.filterUsers.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.selectUsers = this.selectUsers.bind(this);
    this.saveDataUserGroups = this.saveDataUserGroups.bind(this);
    this.selectUserToSeeGroups = this.selectUserToSeeGroups.bind(this);
    this.selectGroupForUser = this.selectGroupForUser.bind(this);
  }

  handleClose(){
    this.setState({showWarning : false});
  }

  componentDidMount(){
    window.onclick = e =>{
      let modal = document.getElementById("usersListDiv");
      if(e.target == modal){
        this.setState({filterValue : ''},()=>{this.filterUsers()});
        modal.style.display = "none";
      }
    }
    this.getUsers();
  }

  selectGroupForUser(group){
    let dataGroupsForUser = this.state.dataGroupsForUser;
    let dataGroupsForUserIndex = this.state.dataGroupsForUser.findIndex(x => x.DataGroupId == group.DataGroupId && x.UserId == this.state.userSelected.UserId);
    let assigning =  dataGroupsForUserIndex == -1;
    if(assigning){
      let canAssign = true;
      //Trying to assign a data group that it is already assigned to through the child group.
      if(group.children && group.children.length > 0){
        let children = this.getAllOffspring(group);
        children.forEach(childId=>{
          if(childId != group.DataGroupId && (this.state.dataGroupsForUser.findIndex(x=> x.DataGroupId == childId && x.UserId == this.state.userSelected.UserId) != -1)){
            canAssign = false;
          }
        });
      }
      if(!canAssign){
        this.props.updateStatusText("Cannot assign user to group. They are already assigned to one of that group's subgroups.","warning");
        return;
      }
      else{
        dataGroupsForUser.push({Add : true, DataGroupId : group.DataGroupId, UserId : this.state.userSelected.UserId});
      }
    }
    else{
      dataGroupsForUser.splice(dataGroupsForUserIndex,1);
    }
    this.setState({dataGroupsForUser});
  }

  selectUserToSeeGroups(e){
    let user = this.state.users.find(x => x.UserId == e.target.value);
    let dataGroupsForUser = this.props.UserGroups.filter(x => x.UserId == e.target.value);
    this.props.groups.forEach(group =>{
      if(group.children && dataGroupsForUser.find(x=> x.DataGroupId == group.DataGroupId)){
        group.children.forEach(child =>{
          if(dataGroupsForUser.find(x => x.DataGroupId == child.DataGroupId)){
            let groupIndex = dataGroupsForUser.findIndex(x => x.DataGroupId == group.DataGroupId);
            if(groupIndex != -1){
              dataGroupsForUser.splice(groupIndex,1);
            }
          }
        });
      }
    });
    this.setState({userSelected : user, dataGroupsForUser});
  }

  selectGroup(id){
    let selectedUsers = [];
    let group = this.props.groups.find(x => x.DataGroupId == id);
    let usersInChildGroup = [];
    this.props.UserGroups.forEach(userGroup =>{
      if(userGroup.DataGroupId == group.DataGroupId){
        //Need to see if user is in a child group and not display if that is the case. 
        //Apparently it's too confusing to people if it shows them in a parent group. 
        let user = this.state.users.find(x => x.UserId == userGroup.UserId);
        if(group.children){
          group.children.forEach(child =>{
            if(this.props.UserGroups.findIndex(x => x.UserId == userGroup.UserId && x.DataGroupId == child.DataGroupId) != -1){
              if(usersInChildGroup.findIndex(x => x.UserId == userGroup.UserId) == -1){
                usersInChildGroup.push(user);
              }
            }
          });
        }
        if(user){
          selectedUsers.push(user);
        }
      }
    })
    this.setState({selectedGroupId : id, currentGroup : group, selectedUsers, groupSelected : true,usersInChildGroup});
  }

  selectUsers(user){
    let selectedUsers = [];
    if(typeof(user) == "string"){
      if(user == "ADDALL"){
        let usersInChildGroup = this.state.usersInChildGroup;
        this.state.filteredUsers.forEach(user =>{
          let childIndex = this.state.usersInChildGroup.findIndex(x => x.UserId == user.UserId);
          if(childIndex != -1){
            usersInChildGroup.splice(childIndex, 1);
          }
          selectedUsers.push(user);
        });
        this.setState({usersInChildGroup});
      }
      else if(user == "REMOVEALL"){
        selectedUsers = this.state.selectedUsers;
        this.state.filteredUsers.forEach(user =>{
          let index = selectedUsers.findIndex(x => x.UserId === user.UserId);
          if(index != -1){
            selectedUsers.splice(index,1);
          }
        });
      }
    }
    else{
      selectedUsers = this.state.selectedUsers;
      if(selectedUsers.find(x => x.UserId === user.UserId)){
        //Need to make it look like you are assigning user to group if they already are but are also in child group. 
        let childIndex = this.state.usersInChildGroup.findIndex(x => x.UserId == user.UserId);
        if(childIndex != -1){
          let usersInChildGroup = this.state.usersInChildGroup;
          usersInChildGroup.splice(childIndex, 1);
          this.setState({usersInChildGroup});
          return;
        }
        let index = selectedUsers.indexOf(x => x.UserId == user.UserId);
        if(index == -1){
          selectedUsers.forEach(selectedUser =>{
            index++;
            if(user.UserId == selectedUser.UserId){
              selectedUsers.splice(index,1);
            }
          });
        }
        else{
          selectedUsers.splice(index,1);
        }
      }else{
        selectedUsers.push(user);
      }
    }

    this.setState({selectedUsers});
  }

  getUsers(){
    let body = {CompanyId: auth.companyId()};
    fetch(process.env.API_URL + "/api/account/users",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0 && json.UserCollection.length > 0){
            let i = json.UserCollection.length;
            while(i--){
              if(json.UserCollection[i].DeletedBy != null || json.UserCollection[i].DeletedDate != null || json.UserCollection[i].DeletedDateUTCOffset != null){
                json.UserCollection.splice(i, 1);
              }
            }
            Functions.sortOn(json.UserCollection, 'LastName');
            this.setState({users : json.UserCollection, filteredUsers : json.UserCollection});
          }
        })
      }
    })
  }

  filterUsers(){
    if(this.state.users != null && this.state.users.length > 0){
      let newFilteredUsers = [];
      this.state.users.forEach(user =>{
        if(this.state.filterValue == ""){
          newFilteredUsers.push(user);
        }
        else if(user.DisplayNameProper && (user.DisplayNameProper.replace(",","").toUpperCase().indexOf(this.state.filterValue.toUpperCase()) > -1)){
          newFilteredUsers.push(user);
        }
      });
      this.setState({ filteredUsers : newFilteredUsers });
    }
  }

  getAllOffspring(dataGroup){
    let idList = [];
    idList.push(dataGroup.DataGroupId);
    if(dataGroup.children){
      dataGroup.children.forEach(child =>{
        idList = idList.concat(this.getAllOffspring(child));
      });
    }
    return idList;
  }

  getAllAncestors(dataGroup){
    let idList = [];
    idList.push(dataGroup.DataGroupId);
    if(dataGroup.ParentId){
      let parent = this.props.groups.find(x => x.DataGroupId === dataGroup.ParentId);
      idList = idList.concat(this.getAllAncestors(parent));
    }
    return idList;
  }

  saveDataUserGroups(savedFrom){
    if(auth.isSuperViewer()){
      return;
    }
    let newUserGroups = [];
    let selectedUsers = this.state.selectedUsers;
    let dataGroupsForUser = this.state.dataGroupsForUser;
    let userGroups = this.props.UserGroups;
    let currentGroup = typeof(this.state.currentGroup) == 'string' ? JSON.parse(this.state.currentGroup) : this.state.currentGroup;
    if(savedFrom != 'userAssignment' && currentGroup.DataGroupId == -1){
      this.props.updateStatusText("Select a group before saving.","warning");
      return;
    }
    if(savedFrom == "userAssignment"){
      let userId = this.state.userSelected.UserId;
      dataGroupsForUser.forEach(groupId =>{
        let group = this.props.groups.find(x => x.DataGroupId == groupId.DataGroupId);
        //Need to add groups that were added and assign all ancestors of that group as well.
        let ancestors = this.getAllAncestors(group);
        ancestors.forEach(ancestor =>{
          if(!newUserGroups.find(x => x.UserId == userId && x.DataGroupId == ancestor) && !(userGroups.find(x => x.UserId == userId && x.DataGroupId == ancestor))){
            newUserGroups.push({UserId : userId, DataGroupId : ancestor, Add : true});
          }
        });
      });
      //Need to look through current user groups to determine what got unassigned and then also unassign all offspring\
      //And all ancestors where they are not assigned to another one of the ancestors offspring. 
      let shouldntUnassignList = [];
      let oldDataGroupsForUser = userGroups.filter(x => x.UserId == userId);
      oldDataGroupsForUser.forEach(group =>{
        
        if(!dataGroupsForUser.find(x => x.DataGroupId == group.DataGroupId)){
          let ancestors = this.getAllAncestors(group);
          let offspring = this.getAllOffspring(group);
          offspring.forEach(groupId =>{
            if(!newUserGroups.find(x => x.Add == false && x.UserId == userId && x.DataGroupId == groupId)){
              newUserGroups.push({UserId : userId, DataGroupId : groupId , Add : false});
            }
          });
          ancestors.forEach(ancestorId =>{
            let ancestor = this.props.groups.find(x => x.DataGroupId == ancestorId);
            let shouldUnassign = true;
            let children = this.getAllOffspring(ancestor);
            children.forEach( child =>{
              if(child != ancestorId && child != group.DataGroupId  && userGroups.find(x => x.UserId == userId && x.DataGroupId == child)){
                shouldUnassign = false;
                shouldntUnassignList.push(child);
              }
            });
            if(shouldUnassign && !newUserGroups.find(x => x.UserId == userId && x.DataGroupId == ancestorId && x.Add == false)){
              newUserGroups.push({UserId : userId, DataGroupId : ancestorId, Add : false});
            }
          });
        }
      });
      shouldntUnassignList.forEach(id =>{
        let groupIndex = newUserGroups.findIndex(x => x.UserId == userId && x.DataGroupId == id && x.Add == false);
        if(groupIndex != -1){
          newUserGroups.splice(groupIndex, 1);
        }
      })
    }
    else{
      selectedUsers.forEach(user =>{
        //Add new users to group but check to make sure they aren't already in the group.
        //Also need to add users to all ancestors of the group that is selected. 
        let ancestors = this.getAllAncestors(currentGroup);
        //Need to remove all users from offspring if it was assigned since now we are hiding everything but lowest level assignment. 
        let offspringList = this.getAllOffspring(currentGroup);
        offspringList.forEach(groupId =>{
          let offspringGroupList = userGroups.filter(x => x.DataGroupId == groupId);
          offspringGroupList.forEach(userGroup =>{
            if(currentGroup.DataGroupId != groupId && !this.state.usersInChildGroup.find(x => x.UserId == userGroup.UserId) && !newUserGroups.find(x => x.UserId == userGroup.UserId && x.DataGroupId == groupId && x.Add == false)){
              newUserGroups.push({UserId : userGroup.UserId, DataGroupId : groupId, Add : false});
            }
          });
        })
        ancestors.forEach(groupId =>{
          if(!userGroups.find(x => x.UserId == user.UserId && x.DataGroupId == groupId) && !newUserGroups.find(x => x.UserId == user.UserId && x.DataGroupId == groupId && x.Add == true)){
            newUserGroups.push({UserId : user.UserId, DataGroupId : groupId, Add : true});
          }
        });
      });
      userGroups.forEach(userGroup =>{
        //Add removed users so they will be deleted.
        if(!selectedUsers.find(x => x.UserId == userGroup.UserId)){
          //Need to Delete the entry for all the children if user is unassigned from parent. 
          let offspringList = this.getAllOffspring(currentGroup);
          offspringList.forEach(groupId =>{
            if(!newUserGroups.find(x => x.UserId == userGroup.UserId && x.DataGroupId == groupId && x.Add == false)){
              newUserGroups.push({UserId : userGroup.UserId, DataGroupId : groupId, Add : false});
            }
          });
        }
      });
      //Need to also unassign all users from ancestors if they are not in one of the users offspring
      let userGroupsInDataGroup = userGroups.filter(x => x.DataGroupId == currentGroup.DataGroupId);
      let ancestors = this.getAllAncestors(currentGroup);
      userGroupsInDataGroup.forEach(userGroup =>{
        if(!selectedUsers.find(x => x.UserId == userGroup.UserId)){
          ancestors.forEach(ancestorId =>{
            let ancestor = this.props.groups.find(x => x.DataGroupId == ancestorId);
            let shouldUnassign = true;
            let children = this.getAllOffspring(ancestor);
            children.forEach( child =>{
              //If child is not itself and not the group being edited and it found a user group with that user and child id then 
              //Thant means that the user was also assigned to a group that desended from this ancestorId so we should not unassign them
              //Other wise we should unassign from all ancestors. 
              if(child != ancestorId && child != currentGroup.DataGroupId && userGroups.find(x => x.UserId == userGroup.UserId && x.DataGroupId == child)){
                shouldUnassign = false;
              }
            });
            if(shouldUnassign && !newUserGroups.find(x => x.UserId == userGroup.UserId && x.DataGroupId == ancestorId && x.Add == false)){
              newUserGroups.push({UserId : userGroup.UserId, DataGroupId : ancestorId, Add : false});
            }
          });
        }
      });
    }
    this.setState({statusText : "SAVING GROUPS..."});
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      UserGroups : newUserGroups
    }
    fetch(process.env.API_URL + "/api/account/updateDataGroupUsers",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({statusText : "", selectedUsers : [], groupSelected : false});
            this.props.updateStatusText("User Group Assignment saved.", "success");
            this.props.updateUserGroups(json.UserGroups);
          }
          else{
            this.setState({statusText : ""});
            this.props.updateStatusText("Something went wrong. Try again.", "error");
          }
        });
      }
      else{
        this.setState({statusText : ""});
        this.props.updateStatusText("Something went wrong. Try again.", "error");
      }
    })
    .catch(error =>{
      console.log(error.message);
      this.setState({statusText : ""});
      this.props.updateStatusText("Something went wrong. Try again.", "error");
    })
  }

  renderDataGroup(children, isChild){
    return(
      <ul className={isChild ? "dataGroupUL childDataGroup" : "dataGroupUL parentDataGroup"}>
        {children.map((group, index) =>
        !group.DeletedDate && (group.isRoot || isChild) &&
        <li key={index} style={{marginTop : 15}}>
          <div className={ group.children ? "dataGroupDiv" : group.isRoot ? "row dataGroupDiv" : "dataGroupDiv"} style={{display : "flex", flexDirection : 'row'}}>
            {group.children ? (group.expanded ? <KeyboardArrowDownIcon className="arrowIcon  dark caret" onClick={()=>{this.props.expandGroup(group)}}/> : <KeyboardArrowRightIcon className="arrowIcon dark caret" onClick={()=>{this.props.expandGroup(group)}}/>) : <div style={{minWidth : 25}}/>}
            <label htmlFor={"groupName"+ group.DataGroupId} className="accountHeader dataGroupLabel notificationRowControl">Group Name:</label>
              <input id={"groupName"+ group.DataGroupId} value={group.GroupLabel} style={{display : 'inline'}} className="form-control notificationRowControl large"
                onChange={e => {this.props.editGroup(e.target.value, group.GroupLabel, group.DataGroupId)}}/>
            <Button onClick={() =>{
              if(group.DataGroupId != -1){
                this.setState({selectedGroup : group, showWarning : true});
              }
              else{
                this.props.deleteGroup(group.GroupLabel, group.DataGroupId);
              }
              }} className="buttonAction buttonAccount buttonFaded notificationRowControl notificationRowButton">
              <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
            </Button>
            <Button onClick={() =>{this.props.addDataGroup(group.DataGroupId)}} className="buttonAction buttonAccount notificationRowControl notificationRowButton" >
              <AddBoxSharpIcon  className="buttonIcon"/>
            </Button>
            {group.DataGroupId > 0 && <Button style={{height : 35}} className="buttonAction button Account notificationRowControl notificaitonRowButton" onClick={()=>{this.selectGroup(group.DataGroupId);document.getElementById("usersListDiv").style.display = 'block';}}>
              <PeopleIcon className="buttonIcon"/>
              <label style={{margin : 0, cursor:  'pointer'}} className="buttonText">View Users</label>
            </Button>}
          </div>
          {group.children && group.expanded && this.renderDataGroup(group.children, true)}
        </li>)}
      </ul>
    )
  }

  render(){
    return(
      <div>
        <div className="labelHeaderBar labelHeaderBarTight" style={{padding : 10}}>
          Data Groups
          <Button onClick={()=>{this.props.updateGroups();}} className="buttonAction dataGroupsSaveButton">
              <SaveSharpIcon className="buttonIcon"/>
              <label style={{ margin: 0, cursor : 'pointer'}} className="buttonText">Save</label>
          </Button>
        </div>
        <div className="licenseEditDivContent">
          <div className="row">
            <div className="col-12">
            {this.props.groups && this.renderDataGroup(this.props.groups, false)}
            </div>
          </div>
          <div className="row" style={{marginTop : 10}}>
            <div className="justify-content-left">
              {!this.props.loadingGroups && <div>
                <Button onClick={() =>{this.props.addDataGroup()}} className="buttonAction buttonAccount" >
                  <AddBoxSharpIcon  className="buttonIcon"/>
                  <label style={{margin : 0, cursor: 'pointer'}} className="buttonText">New Data Group</label>
                </Button>
              </div>}
              {this.props.loadingGroups && 
              <div className="row d-flex align-items-center justify-content-center" style={{width : 500, marginTop : 10}}>
                <div className="d-flex align-items-center justify-content-center authSpinnerDiv" style={{width : '100%'}}>
                  <span className="labelLoadingBar">UPDATING DATA GROUPS</span>
                  <div className="barLoaderPortal">
                    <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={true}/>
                  </div>
                </div>
              </div>
              
              }
            </div>
          </div>
        </div>
        
        <div className='labelHeaderBar labelHeaderBarTight' style={{marginTop : 10, paddign : 10}}>
          <div className="row">
            <div className="col-11">
              User Assignment
              <div className="labelHeaderBarSubHeader">Assign users to Data Groups</div>
            </div>
            <div className='col-1'>
              <div className="text-left" style={{marginTop  : 10 }}>
                <Button onClick={() =>{this.saveDataUserGroups("userAssignment")}} style={{ minWidth : 100, position : 'relative', right : (this.state.windowWidth > 1068 ? 55 : (this.state.windowWidth > 508 ? 95 : 15)) }} className ="buttonAction buttonEditUser">
                  <SaveSharpIcon className="buttonIcon"/>
                  <label className ="buttonText">SAVE</label>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="licenseEditDivContent">
          <div className='row'>
            {this.state.users && <div className='col-5'>
              <label className="labelHeaderBar" style={{width:'98%'}}>Users</label>
              <select className="form-control customFormRowControl" value={this.state.userSelected.UserId} onChange={e=>{this.selectUserToSeeGroups(e)}} style={{maxWidth:285, height:42}}>
                <option value={-1}></option>
                { this.state.users.map((user,index) => (!user.DeletedDate &&
                  <option key={index} value={user.UserId}>{user.DisplayNameProper}</option>
                  ))}
              </select>
            </div>}
            <div className="col-5">
              <label className="labelHeaderBar">Data Groups</label>
              <div className="groupAssignmentUserList">
                <div style={{minHeight:10}}/>
                {this.props.groups && this.props.groups.map((group, index) => (
                  !group.DeletedDate && <div key={index} style={{marginLeft : 10}}>
                    <input type="checkbox" id={'groupselector' + group.DataGroupId} checked={this.state.dataGroupsForUser.findIndex(x => x.DataGroupId == group.DataGroupId) != -1} onChange={()=>{this.selectGroupForUser(group)}}/>
                    <label htmlFor={'groupselector' + group.DataGroupId}>{group.GroupLabel}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <Dialog open={this.state.showWarning} onClose={() =>this.handleClose()}>
          <DialogTitle>{"Do you want to delete this Group?"}</DialogTitle>
          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={()=> this.handleClose()}>
              <label className="buttonText small" style={{margin : 0, cursor : 'pointer'}}>Cancel</label>
            </Button>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={()=> {this.handleClose(); this.props.deleteGroup(this.state.selectedGroup.GroupLabel, this.state.selectedGroup.DataGroupId)}}>
              <label className="buttonText small" style={{margin : 0, cursor : 'pointer'}}>Accept</label>
            </Button>
          </DialogActions>
        </Dialog>

        <div id = "usersListDiv" className="modal" style={{paddingTop : 0}}>
          <div className="modal-content" style={{width : '50%', margingTop : "25%"}}>
          <div className="labelHeaderBar"> <label className="labelHeaderName">Assign Group Users - {this.state.currentGroup && this.state.currentGroup.GroupLabel}</label></div>
            <div style={{flex : 1, margin : '0 auto'}}>
              <div style={{ maxWidth: 295}} className="d-flex flex-column">
                <div className="text-right"></div>
                  <input placeholder ="Search" type="text" value={this.state.filterValue} onChange={e =>{this.setState({filterValue : e.target.value},()=>{this.filterUsers()})}} className="form-control searchInput" style={{width: '45vw'}}/>
              </div>
              <div className="groupAssignmentUserList" style={{minWidth : '45vw'}}>
                <div style={{minHeight : 10}}/>
                {this.state.filteredUsers && this.state.filteredUsers.length > 0 && this.state.filteredUsers.map((user,index) =>(
                  user.DisplayNameProper && <div onClick={()=>{this.selectUsers(user)}} key={index} className="userCheckboxRow"><input type="checkbox" style ={{cursor : 'pointer'}}id={user.UserId} checked={this.state.selectedUsers.find(x=> x.UserId === user.UserId) && !this.state.usersInChildGroup.find(x => x.UserId  == user.UserId) || false}  />  <label onClick={()=>{this.selectUsers(user)}} htmlFor={user.UserId} style={{cursor : 'pointer'}}> {user.DisplayNameProper}</label></div>
                ))}
              </div>
            </div>
              <div style={{margin : 10, justifyContent : 'space-between', display : 'flex'}}>
                <div className="">
                  <Button onClick={()=>{this.selectUsers("ADDALL")}}className="buttonAction btn btn-primary " style={{marginRight : 10, display: 'inline-block'}}>
                    <label className ="buttonText" style={{marginBottom:8}}>Add All Users</label>
                  </Button>
                  <Button onClick={()=>{this.selectUsers("REMOVEALL")}}className="buttonAction btn btn-primary" style={{display: 'inline-block'}}>
                    <label className ="buttonText"  style={{marginBottom:8}}>Remove All Users</label>
                  </Button>
                </div>
                <div className="">
                  <Button onClick={()=>{this.setState({filterValue : ''},()=>{this.filterUsers()});document.getElementById("usersListDiv").style.display = 'none'}}className="buttonAction btn btn-primary buttonFaded" style={{marginRight : 10, display: 'inline-block', minWidth : 110}}>
                    <label className ="buttonText" style={{marginBottom:8}}>Cancel</label>
                  </Button>
                  <Button onClick={()=>{this.saveDataUserGroups();document.getElementById("usersListDiv").style.display = 'none';}}className="buttonAction btn btn-primary confirm" style={{display: 'inline-block', minWidth : 110}}>
                    <label className ="buttonText"  style={{marginBottom:8}}>Save</label>
                  </Button>
                </div>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

DataGroupTab.propTypes ={
  groups : PropTypes.array,
  loadingGroups : PropTypes.bool,
  addDataGroup : PropTypes.func,
  updateGroups : PropTypes.func,
  editGroup : PropTypes.func,
  deleteGroup : PropTypes.func,
  expandGroup : PropTypes.func,
  forceRefresh : PropTypes.func,
  UserGroups : PropTypes.array,
  updateUserGroups : PropTypes.func,
  updateStatusText : PropTypes.func
}

export default DataGroupTab;