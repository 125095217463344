import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import AttachmentIcon from '@material-ui/icons/Attachment';
import auth from '../common/Auth';
import { BarLoader } from 'react-spinners';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


class AttachedDocumentPanel extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      loading : false,
      error : false,
      pendingDocs : 0,
      updateStatusText : ""
    }
    this.attachDocument = this.attachDocument.bind(this);
    this.readAndUpload = this.readAndUpload.bind(this);
  }

  openDocument(id,name){
    fetch(process.env.API_URL + "/api/document/" + id,{
      method: "GET",
      headers:{
        "Content-Type": "application/json",
        Authorization : "Bearer " + auth.token()
      }
    })
    .then(r => r.blob())
    .then(blob =>{
      let doc = URL.createObjectURL(blob);
      let downloadLink = document.createElement("a");
      downloadLink.href = doc;
      downloadLink.download = name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }).catch(error => {console.log(error.message)});
  }

  attachDocument(){
    let files = document.getElementById("attachDocument").files;
    if(files.length > 0){
      this.setState({loading : !this.props.fromTaskMap, error : false,pendingDocs : files.length},()=>{
        for(let i =0; i< files.length; i++){
          this.readAndUpload(files[i]);
        }
      });
    }
  }
  
  readAndUpload(doc){
    if(this.props.fromTaskMap){
      this.props.addToDocs(doc);
      return;
    }

    let date = new Date();
    if(doc.size > 50000000){
      let pendingDocs = this.state.pendingDocs;
      pendingDocs--;
      this.setState({pendingDocs, error : true});
      if(pendingDocs == 0){
        if(this.props.finishUploading)this.props.finishUploading(this.state.error);
        this.setState({error : false, pendingDocs : 0, loading : false, updateStatusText : "There was a problem uploading one or more of you documents. File too big.", alertSeverity : 'error'});
      }
      return;
    }
    let body = {
      MultimediaAsset : {
        EntityId : this.props.entityId,
        EntityTypeId : this.props.entityTypeId,
        Companyid : auth.companyId(),
        ModifiedBy : auth.employeeId(),
        CreatedBy : auth.employeeId(),
        CreatedDate : date,
        ModifiedDate : date,
        UTCOffset : date.getTimezoneOffset(),
        ModifiedDateUTCOffset : -date.getTimezoneOffset() / 60,
        CreatedDateUTCOffset : -date.getTimezoneOffset() / 60,
        Timestamp : date.getTime(),
        Latitude : 0,
        Heading : 0,
        Label : doc.name,
        Longitude : 0,
        MediaTypeId : 3
      },
      Latitude : 0,
      Longitude : 0,
      Heading : 0,
      CreatedDate : date,
      UTCOffset : date.getTimezoneOffset(),
      Label : doc.name,
      FileType : doc.type
    }
    let fd = new FormData();
    fd.append('Document', doc);
    fd.append('DataObject', JSON.stringify(body));
    //This has to be an XMLHttpRequest to work.
    //If a fetch is used the data will not show up on the server.
    let req = new XMLHttpRequest();
    req.open("POST", process.env.API_URL + "/api/document",true);
    req.setRequestHeader("Authorization" , "Bearer " + auth.token());
    req.onload = () =>{
      let pendingDocs = this.state.pendingDocs;
      pendingDocs--;
      this.setState({pendingDocs})
      if(req.status == 200 && req.response){
        let response = JSON.parse(req.response);
        this.props.addToDocs(response.multimediaAsset ? JSON.parse(response.multimediaAsset) : body.MultimediaAsset);
      }
      else{
        this.setState({error :true});
      }
      if(pendingDocs == 0){
        if(this.props.finishUploading)this.props.finishUploading(this.state.error);
        let updateStatusText = "All documents were uploaded.";
        let alertSeverity = "success"
        if(this.state.error){
          updateStatusText = "There was a problem uploading one or more of your documents."
          alertSeverity = "error"
        }
        this.setState({error : false, pendingDocs : 0, loading : false, updateStatusText, alertSeverity})
      }
    }
    req.onerror = () =>{
      let pendingDocs = this.state.pendingDocs;
      pendingDocs--;
      this.setState({pendingDocs, error : true});
      if(pendingDocs == 0){
        if(this.props.finishUploading)this.props.finishUploading(this.state.error);
        this.setState({error : false, pendingDocs : 0, loading : false, updateStatusText : "There was a problem uploading one or more of you documents", alertSeverity : 'error'});
      }
    }
    req.send(fd);
  }

  render(){
    return(
      <div>
        <Snackbar open={this.state.updateStatusText !=""} onClick={() =>{this.setState({updateStatusText : ''})}} autoHideDuration={6000} onClose={()=>{}}
          anchorOrigin={{ vertical : 'top', horizontal : 'center'}}>
            <Alert onClose={()=>{}} severity={this.state.alertSeverity} variant="filled">
              {this.state.updateStatusText}
            </Alert>
          </Snackbar>
        <div className="labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Documents ({this.props.Documents && this.props.Documents.length})</label></div>
        <div className="detailContentPanel" style={{textAlign : "center"}}>
            {!this.props.showingHistoryRecord && <div className ="video-galleryDeleteButtonDiv" style={{bottom: 54}}>
              <Button className="buttonAction buttonAccount" style={{position : 'relative', left : 166}}>
                <AttachmentIcon className="buttonIcon"/>
                <label style={{ margin: 0, cursor : 'pointer'}} className="buttonText">Attach Document</label>
              </Button>
              <input type="file" onChange={this.attachDocument} accept=".csv,.xls,.xlsx,.doc,.docx,.pdf,.txt,.ppt,.pptx,.msg" className="form-control-file attachDocumentButton" id="attachDocument" multiple/>
            </div>}
          {(this.props.loadingDocuments || this.state.loading) ? <div className="barLoaderPortal" style={{position : 'relative', bottom : 20}}><BarLoader sizeUnit={"px"} size={200} color={"#095399"} loading={this.props.loadingDocuments || this.state.loading}/></div> : 
            <div>{this.props.Documents && this.props.Documents.length > 0 && this.props.Documents.map((document, index) => (
              <div key={index} className="underline" style={{position : 'relative', bottom : 35, cursor : 'pointer'}} onClick={() => {this.openDocument(document.MultimediaAssetId,document.Label)}}>{document.Label}</div>
            ))}
          </div>}
        </div>
      </div>
    ) 
  }
}

AttachedDocumentPanel.propTypes =  {
  documentCount : PropTypes.number,
  attachDocument : PropTypes.func,
  loadingDocuments : PropTypes.bool,
  Documents : PropTypes.array,
  entityId : PropTypes.number,
  entityTypeId : PropTypes.number,
  addToDocs : PropTypes.func,
  finishUploading : PropTypes.func,
  showingHistoryRecord : PropTypes.bool,
  fromTaskMap : PropTypes.bool
}

export default AttachedDocumentPanel;