import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { BarLoader } from "react-spinners"; 
import PaymentSharpIcon from '@material-ui/icons/PaymentSharp';

class CreditCardModal extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        cardName : this.props.cardName,
        cardNumber : this.props.cardNumber,
        cardMonth : this.props.cardMonth,
        cardYear : this.props.cardYear,
      }
    }

    render(){
        return (
            <div id = "addCard" className = "modal" style={{paddingTop: 0}}>
                <div className = "modal-content">
                    <div className = "row">
                        <div className ="col-11"></div>
                        <div className = "col-1">
                            <span className = "close text-right" onClick = {()=>{document.getElementById("addCard").style.display ="none";this.props.clearCardInfo()}}>
                                &times;
                            </span>
                        </div>
                    </div>
                    <div className = "text-center labelSubHeader4" style={{padding:10,cursor:'default'}}>Update Payment Method - Credit or Debit</div>
                    <div className="formInputDivRow">
                      <label htmlFor = "cardNumber" className="formLabel" style={{color : this.props.cardNumberColor}}>CARD NUMBER:</label>
                      <input
                          id = "cardNumber"
                          onChange = {(e) =>{this.props.changeCardNumber(e.target.value)}}
                          value = {this.props.cardNumber}
                          className = "form-control ccInput"
                          maxLength = {19}
                      />
                    </div>
                    <div className="formInputDivRow">
                      <label htmlFor = "cardMonth" className="formLabel" style={{color : this.props.cardMonthColor}}>MONTH:</label>
                      <input 
                          id = "cardMonth"
                          onChange = {(e) =>{this.props.changeCardMonth(e.target.value)}}
                          value = {this.props.cardMonth}
                          className = "form-control ccInputSmall"
                          type = "number"
                          placeholder="MM"
                          min = "1"
                          max = "12"
                      />
                      <label htmlFor = "cardYear" className="formLabel" style={{color : this.props.cardYearColor}}>YEAR:</label>
                      <input 
                          id = "cardYear"
                          onChange = {(e) =>{this.props.changeCardYear(e.target.value)}}
                          value = {this.props.cardYear}
                          className = "form-control ccInputSmall"
                          placeholder="YYYY"
                          type = "number"
                          min = "2021"
                          max = "2099"
                      />
                      <label htmlFor = "cvc" className="formLabel" style={{color : this.props.cvcColor}}>CVC/CVV:</label>
                      <input
                          id = "cvc"
                          onChange ={(e) =>{this.props.changeCVC(e.target.value)}}
                          value = {this.props.CVC}
                          className = "form-control ccInputSmall"
                          type ="number"
                          placeholder="1234"
                          maxLength = {4}
                          min="0"
                          max="9999"
                      />
                    </div>
                    <div className="billingAddressInputDivRow">
                        <label htmlFor = "cvc" className="formLabelBillingAddressHeader" >BILLING ADDRESS</label>
                    </div>
                    <div className="billingAddressInputDivRow">

                      <div className={"billingAddressInputLabelDivColumn"}>
                        <label htmlFor = "cvc" className="formLabelBillingAddress" style={{color : this.props.billingAddressLine1Color}}>ADDRESS LINE 1:</label>
                      </div>
                      <div className={"billingAddressInputDivColumn"}>
                      <input
                          id = "cardNumberAdd1"
                          onChange = {(e) =>{this.props.changeBillingAddressLine1(e.target.value)}}
                          value = {this.props.billingAddressLine1}
                          className = "form-control addressInput"
                          maxLength = {50}
                      />
                       </div>
                    </div>
                    <div className="billingAddressInputDivRow">

                      <div className={"billingAddressInputLabelDivColumn"}>
                        <label htmlFor = "cvc" className="formLabelBillingAddress" style={{color : "black"}}>ADDRESS LINE 2:</label>
                      </div>
                      <div className={"billingAddressInputDivColumn"}>
                      <input
                          id = "cardNumberAdd2"
                          onChange = {(e) =>{this.props.changeBillingAddressLine2(e.target.value)}}
                          value = {this.props.billingAddressLine2}
                          className = "form-control addressInput"
                          maxLength = {50}
                      />
                       </div>
                    </div>
                    <div className="billingAddressInputDivRow">

                      <div className={"billingAddressInputLabelDivColumn"}>
                        <label htmlFor = "cvc" className="formLabelBillingAddress" style={{color : this.props.billingAddressCityColor}}>CITY:</label>
                      </div>
                      <div className={"billingAddressInputDivColumn"}>
                      <input
                          id = "cardNumberAddCity"
                          onChange = {(e) =>{this.props.changeBillingAddressCity(e.target.value)}}
                          value = {this.props.billingAddressCity}
                          className = "form-control cityInput"
                          maxLength = {50}
                      />
                       </div>
                    </div>
                    <div className="billingAddressInputDivRow">

                    <div className={"billingAddressInputLabelDivColumn"}>
                        <label htmlFor = "cvc" className="formLabelBillingAddress" style={{color : this.props.billingAddressStateColor}}>STATE:</label>
                    </div>
                    <div className={"billingAddressInputDivColumn"}>
                    <select name="state" className = "form-control stateInput" id="billingAddressState" value={this.props.billingAddressState} onChange = {(e) =>{this.props.changeBillingAddressState(e.target.value)}}>
                      <option value="0">-Select a state-</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AS">American Samoa</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="GU">Guam</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="MP">Northern Mariana Islands</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="PR">Puerto Rico</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UM">United States Minor Outlying Islands</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VI">Virgin Islands</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                    </div>
                    </div>
                    <div className="billingAddressInputDivRow">

                      <div className={"billingAddressInputZipLabelDivColumn"}>
                        <label htmlFor = "cvc" className="formLabelBillingAddress" style={{color : this.props.billingAddressZipColor}}>ZIP:</label>
                      </div>
                      <div className={"billingAddressInputZipDivRightColumn"}>
                        <div className={"billingAddressInputZipDivRightColumnInnerContainer"}>
                        <div className={"billingAddressInputZipDivColumn"}>
                        <input
                            id = "cardNumberZip"
                            onChange = {(e) =>{this.props.changeBillingAddressZip(e.target.value)}}
                            value = {this.props.billingAddressZip}
                            className = "form-control zipInput"
                            maxLength = {5}
                        />
                        </div>

                        <div className={"billingAddressInputPhoneLabelDivColumn"}>
                          <label htmlFor = "cvc" className="formLabelBillingAddress" style={{color : this.props.billingAddressPhoneColor}}>PHONE:</label>
                        </div>
                        <div className={"billingAddressInputPhoneDivColumn"}>
                        <input
                            id = "cardNumberPhone"
                            onChange = {(e) =>{
                              this.props.changeBillingAddressPhone(e.target.value)
                            }}
                            value = {this.props.billingAddressPhone}
                            className = "form-control phoneInput"
                            maxLength = {12}
                        />
                        </div>
                        </div>

                      </div>


                    </div>

                    <div className ="text-right" style={{marginTop:20}}>
                      <div className="horizontalLine"></div>
                      <Button onClick = {this.props.addCard} className ="btn buttonAction ccSave" style={{paddingRight:14}}> 
                        <PaymentSharpIcon className="buttonIcon"/>
                        <label  style = {{margin : 0, cursor : "pointer"}} className ="buttonText" > SAVE </label>
                      </Button>
                    </div>
                      
                    { this.props.statusBarVisible && 
                    <div className="row d-flex align-items-center justify-content-center buffer">
                        <div className="col-12 d-flex align-items-center justify-content-center authSpinnerDivClean text-center">
                            {this.props.statusText}
                            <div className="barLoaderPortal">
                            <BarLoader
                            sizeUnit={"px"}
                            size={1}
                            color={"#095399"}
                            loading={ this.props.loading }
                            /></div>
                        </div>
                    </div> }
                </div>
            </div>
        )
    }
}

CreditCardModal.propTypes = {
    cardName : PropTypes.string,
    cardNumber : PropTypes.string,
    cardMonth : PropTypes.string,
    cardYear : PropTypes.string,
    statusBarVisible: PropTypes.bool,
    CVC : PropTypes.number,
    clearCardInfo : PropTypes.func,
    changeCardMonth : PropTypes.func,
    changeCardNumber : PropTypes.func,
    changeCardName : PropTypes.func,
    changeCardYear : PropTypes.func,
    changeCVC : PropTypes.func,
    billingAddressLine1 : PropTypes.string,
    billingAddressLine2 : PropTypes.string,
    billingAddressCity : PropTypes.string,
    billingAddressState : PropTypes.string,
    billingAddressZip : PropTypes.string,
    billingAddressPhone : PropTypes.string,
    
    billingAddressLine1Color : PropTypes.string,
    billingAddressCityColor : PropTypes.string,
    billingAddressStateColor : PropTypes.string,
    billingAddressZipColor : PropTypes.string,
    billingAddressPhoneColor : PropTypes.string,

    changeBillingAddressLine1 : PropTypes.func,
    changeBillingAddressLine2 : PropTypes.func,
    changeBillingAddressCity : PropTypes.func,
    changeBillingAddressState : PropTypes.func,
    changeBillingAddressZip : PropTypes.func,
    changeBillingAddressPhone : PropTypes.func,
    addCard : PropTypes.func,
    cvcColor : PropTypes.string,
    cardYearColor : PropTypes.string,
    cardMonthColor : PropTypes.string,
    cardNumberColor : PropTypes.string,
    cardNameColor : PropTypes.string,
    loading : PropTypes.bool,
    statusText : PropTypes.string,
}

export default CreditCardModal;