import auth from "../common/Auth";
import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

class LogoutPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    auth.logout();
    this.props.swap();
    return <Redirect to="/login" />;
  }
}
LogoutPage.propTypes = {
  swap: PropTypes.func
};
export default LogoutPage;
