import React from "react";
import SurveyStrip from "./SurveyStrip";
import FormDataBody from "./FormDataBody";
import PropTypes from "prop-types";
import { MDBDataTable } from 'mdbreact';
import { BarLoader } from "react-spinners";
import auth from '../common/Auth';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { get, set } from 'idb-keyval';
import OrgUtility from '../../Utility/Utility';

class FormDataPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultSet: [],
      pictureCount : 0,
      editing : this.props.fromTaskMap,
      unsaved : false,
      loadingUpdate : "Save",
      updateStatusText : "",
      historyStatusText : "",
      changeLogStatusText : "",
      alertSeverity : 'success',
      fetchingFormData : false,
      loadingChangeLog : false,
      showChangeLog : false,
      dataLoadSuccess : true
    };
    this.setPictureCount = this.setPictureCount.bind(this);
    this.setUpEditing = this.setUpEditing.bind(this);
    this.fetchChangeLog = this.fetchChangeLog.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.saveSurvey = this.saveSurvey.bind(this);
    this.editValue = this.editValue.bind(this);
    this.saveFormTask = this.saveFormTask.bind(this);
    this.editFormTaskStatus = this.editFormTaskStatus.bind(this);
    this.editAssignedUser = this.editAssignedUser.bind(this);
    this.goToMap = this.goToMap.bind(this);
    this.fetchHistory = this.fetchHistory.bind(this);
    this.viewHistoryRecord = this.viewHistoryRecord.bind(this);
    this.closeHistoryList = this.closeHistoryList.bind(this);
    this.closeHistoryRecord = this.closeHistoryRecord.bind(this);
    this.closeTicketLog = this.closeTicketLog.bind(this);
    this.fetchFormData = this.fetchFormData.bind(this);
    this.controller = new AbortController();
  }

  componentWillMount() {
    let formData = {};
    if (this.props.location.formData != null){
      if(this.props.location.formData.FormData.FormTypeId == 4){
        this.fetchFormData();
      }
      formData = this.props.location.formData;
      let d = this.props.location.formData;
      d.__proto__ = null;
      d.Details = null; // some react weirdness that causes the object to not be able to be stringified.
      try{localStorage.setItem('currentFormData', JSON.stringify(d));}
      catch(e){console.log(e);}
    }
    else{
      let d = localStorage.getItem("currentFormData");
      if (d && d != ''){
        formData = JSON.parse(d);
      }
    }

    // EMPTY OBJECT CHECK
    if (formData != undefined && Object.entries(formData).length === 0)
      formData = null;
    this.setState({formData});
  }

  fetchFormData(){
    const id = this.props.location.formData.FormData.FormDataId;
    const formTask = this.props.location.formData.FormData.FormTask;
    this.setState({fetchingFormData : true, dataLoadSuccess : false});
    let body ={
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      FormDataId : id
    }

    fetch(process.env.API_URL + "/api/FormData/get",{
      method: "POST",
      headers:{
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      signal : this.controller.signal,
      body: JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        response.json().then(responseJson =>{
          let response = JSON.parse(responseJson);
          
          var formData = {}
          formData.FormData = response.FormData;
          if(response.FormTask){
            formData.FormTask = response.FormTask;
          }
          else{
            formData.FormTask = formTask;
          }
          this.setState({formData, fetchingFormData : false,dataLoadSuccess : true });
        });
      }
      else{
        this.setState({updateStatusText : "There was an error retrieveing FormData. Try again.",alertSeverity : "error", fetchingFormData : false});
      }
    })
    .catch(e=>{
      console.log(e.message);
      if(! e.message.includes("The user aborted a request."))this.setState({updateStatusText : "There was an error retrieveing FormData. Try again.",alertSeverity : "error", fetchingFormData : false});
    });
  }

  componentDidMount() {
    if(this.props.forceRefresh){
      this.props.forceRefresh();
    }
  }

  componentWillUnmount(){
    localStorage.removeItem('currentFormData');
  }

  componentDidUpdate(prevProps){
    
    if(prevProps.location.formData && this.props.location.formData && (prevProps.location.formData.FormData.FormDataId != this.props.location.formData.FormData.FormDataId)){
      this.controller.abort();
      this.controller = new AbortController();
      this.fetchFormData();
      this.setState({formData: this.props.location.formData,editing : false, showHistoryList : false, showingHistoryRecord : false});
    }
    if(this.state.unsaved){
      window.onbeforeunload = () => true
    } else{
      window.onbeforeunload = undefined;
    }
    // DATA MASSAGE
    // cleanup, and serialization as expected, for updating FormDataMin objects
    let formData = this.state.formData;
    let fieldsValues = formData.FormData.FieldsValues ? JSON.parse(formData.FormData.FieldsValues) : null;
    if (formData.FormData.Fields.length == 0 && fieldsValues && fieldsValues.length != 0)
    {
      formData.FormData.Fields = fieldsValues;
      this.setState({formData});
    }

    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  fetchChangeLog(){
    this.setState({changeLogStatusText : "RETRIEVING DATA...", loadingChangeLog : true});
    let body ={
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      FormDataId : this.state.formData.FormData.FormDataId
    }
    fetch(process.env.API_URL + "/api/form/changelog",{
      method: "POST",
      headers: {
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        response.json().then(responseJson =>{
          let response = JSON.parse(responseJson);
          console.log(response);
          if(response.ChangeLogCollection && response.ChangeLogCollection.length > 0){
            this.setState({changelogCollection : response.ChangeLogCollection, changeLogStatusText : "", showChangeLog : true, loadingChangeLog : false});
          }
          else{
            this.setState({changeLogStatusText : "No Data Returned", showChangeLog : true});
          }
        });
      }
      else{
        this.setState({changeLogStatusText : "There was an error retrieving Ticket Log. Try again.", loadingChangeLog : false, showChangeLog : true});
      }
      
    })
    .catch(e=>{
      console.log(e);
      this.setState({changeLogStatusText : "There was an error retrieving Ticket Log. Try again.", loadingChangeLog : false, showChangeLog : true});
    });
  }

  fetchHistory(){
    this.setState({historyStatusText : "RETRIEVING DATA...", loadingHistory : true});
    let body ={
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      FormDataId : this.state.formData.FormData.FormDataId
    }
    fetch(process.env.API_URL + "/api/form/formHistory",{
      method: "POST",
      headers:{
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        response.json().then(responseJson =>{
          let response = JSON.parse(responseJson);
          let columns = [{label: "",field:"ViewButton"}];
          if(this.state.formData && this.state.formData.FormData && this.state.formData.FormData.FormTypeId == 4){
            columns.push({label : "Ticket Number",field: "ticketNumber"});
            columns.push({label : "Revision",field: "revision"});
            columns.push({label : "Status",field: "status"});
          }
          columns.push({label:"MODIFIED DATE", field:"ModifiedDateFormatted"});
          columns.push({label : "UPDATED BY", field:"ModifiedByDisplayName"});
          let formDataHistory = {columns:columns,rows:[]};
          if(response.FormDataHistoryCollection && response.FormDataHistoryCollection.length > 0){
            response.FormDataHistoryCollection.forEach(record =>{
              if(this.state.formData && this.state.formData.FormData && this.state.formData.FormData.FormTypeId == 4){
                let ticketNumberField = record.Fields.find(x => x.Name == "Ticket Number");
                record.ticketNumber = ticketNumberField != null ? ticketNumberField.Value : "";
                let revisionField = record.Fields.find(x => x.Name == "Rev");
                record.revision = revisionField != null ? revisionField.Value : "";
                let statusField = record.Fields.find(x => x.Name == "Ticket Status");
                record.status = statusField != null ? statusField.Value : "";
              }
              record.ViewButton = <button className="anchorButton submitButton incident padded" onClick={() =>{this.viewHistoryRecord(record)}}><div className="text-center full">VIEW</div></button>;
              record.ModifiedDateFormatted = new Date(record.ModifiedDate[record.ModifiedDate.length]-1 !="Z" ? record.ModifiedDate + "Z" : record.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
              formDataHistory.rows.push(record);
            });
          }
          this.setState({historyStatusText : "", loadingHistory : false, formDataHistory, showHistoryList : true, formTaskHistory : response.FormTaskHistoryCollection});
        });
      }
      else{
        this.setState({historyStatusText : "There was an error retrieveing history. Try again.", loadingHistory : false, showHistoryList : true});
      }
    })
    .catch(e=>{
      console.log(e);
      this.setState({historyStatusText : "There was an error retrieveing history. Try again.", loadingHistory : false, showHistoryList : true});
    });
  }

  viewHistoryRecord(record){
    let upToDateFormData = JSON.parse(JSON.stringify(this.state.formData));
    let historyRecord = this.state.formData;
    historyRecord.FormData = record;
    this.setState({upToDateFormData, formData: historyRecord, showHistoryList : false,showingHistoryRecord : true,historyRecordId: record.FormDataHistoryId});
  }

  setPictureCount(pictureCount){
    this.setState({pictureCount});
  }

  setUpEditing(){
    console.log(this.state.formData);
    this.setState({editing: true, originalForm: JSON.parse(JSON.stringify(this.state.formData))});
  }

  cancelEditing(){
    console.log(this.state.formData);
    this.setState({editing: false, unsaved: false, formData: this.state.originalForm});
  }

  async saveFormTask(formTask){
    let body ={
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      FormTask : formTask
    }
    return fetch(process.env.API_URL +"/api/formTask/save",{
      method: "POST",
      headers:{
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        return response.json().then(responseJson =>{
          let response = JSON.parse(responseJson);
          if(response.ResponseType == 0){
            return response.FormTask;
          }
        });
      }
    })
    .catch(error =>{
      console.log(error);
      return formTask;
    });
  }

  async saveSurvey(){
    this.setState({loadingUpdate : "Saving..."});
    let formTask = this.state.formData.FormTask
    if(formTask){
      formTask = await this.saveFormTask(this.state.formData.FormTask);
    }
    let now = new Date();
    let FormData = this.state.formData.FormData;
    FormData.FieldsToSave = this.state.formData.FormData.Fields;
    FormData.ModifiedBy = auth.userId();
    FormData.ModifiedDate = now;
    FormData.ModifiedDateUTCOffset = -now.getTimezoneOffset()/60;
    let body = {
      FormData,
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      StatusType : 0,
      EntityType : 93,
      RequestId : now.valueOf()
    };
    fetch(process.env.API_URL +"/api/form/save",{
      method : "POST",
      headers : {
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        response.json().then(async responseJson =>{
          let response = JSON.parse(responseJson);
          
          if (response.ResponseType ==0){
            let formData = response;
            formData.FormTask = formTask;
            if(this.props.updatefromEditMap){
              this.props.updatefromEditMap(formData);
            }
            else{
              localStorage.setItem('currentFormData', JSON.stringify(this.state.formData));
              let results = await get('resultData');
              
              if (results){
                let parsedResults = JSON.parse(results);
                if (parsedResults && parsedResults.InvestigationCollection){
                  let index = parsedResults.InvestigationCollection.findIndex(x => x.FormData && x.FormData.FormDataId == formData.FormDataId);
                  
                  if (index != -1){
                    parsedResults.InvestigationCollection[index].FormData = formData;
                    set('resultData', JSON.stringify(parsedResults));
                  }
                }
              }
            }
            this.setState({formData, updateStatusText: 'Updated Form Data.', loadingUpdate: "Save", alertSeverity: "success", editing: false, unsaved: false});
          }
          else{
            this.setState({updateStatusText: "Something went wrong. Try again.", loadingUpdate: "Save", alertSeverity: "error"});
          }
        })
      }
      else{
        this.setState({updateStatusText: "Something went wrong. Try again.", loadingUpdate: "Save", alertSeverity: "error"});
      }
    }).catch(error =>{
      console.log(error);
      this.setState({updateStatusText: "Something went wrong. Try again.", loadingUpdate: "Save", alertSeverity: "error"});
    })
  }

  editValue(newValue, field,index){
    let formData = this.state.formData;
    if(field.Type != "Time" && field.Type != "Date" && field.Type != "Yes or No"){
      formData.FormData.Fields[index].Value = newValue;
    }
    if(field.Type == "Time"){
      let date = newValue
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let timeOfDay = hours < 12 ? 'AM' : 'PM';
      if (minutes < 10) { minutes = '0' + minutes; }
      if (hours > 12){ hours %=12; }
      formData.FormData.Fields[index].Label = date.toLocaleTimeString('en-US', {hour12: false, hour: 'numeric', minute: 'numeric'}); 
      formData.FormData.Fields[index].Value = hours + ":" + minutes + " " + timeOfDay;
    }
    if(field.Type == "Date"){
      let date = new Date(newValue);
      formData.FormData.Fields[index].Value = date;
      formData.FormData.Fields[index].Label = moment(date).format('MMM DD, YYYY');
    }
    if(field.Type == "Yes or No"){
      if(newValue == 'true'){
        formData.FormData.Fields[index].Value = 'false';
      }
      else{
        formData.FormData.Fields[index].Value = 'true';
      }
    }
    this.setState({unsaved: true, formData})
  }

  editFormTaskStatus(value){
    console.log(value)
    let formData = this.state.formData;
    if(formData.FormTask){
      formData.FormTask.FormTaskStatus = parseInt(value);
    }
    this.setState({unsaved: true, formData});
  }

  editAssignedUser(userId){
    let formData = this.state.formData;
    if(formData.FormTask){
      formData.FormTask.AssignedUserId = userId;
    }
    this.setState({unsaved: true, formData});
  }

  goToMap(){
    
    let form = this.state.formData;
    if(form.FormData){
      form.EntityTypeId = form.FormData.FormTypeId;
    }
    if(form.EntityTypeId == 0 || form.EntityTypeId == 1 || form.EntityTypeId == 4){
      form.Type = form.EntityTypeId == 0 ? "Survey" : "Investigation";
      localStorage.setItem("mapForm", JSON.stringify(form));
      window.location.href = "/surveymap?fromDetails=true&detailsType=form";
    }
    else if(form.EntityTypeId == 23){
      form.Type = "Leak Survey";
      localStorage.setItem("mapForm", JSON.stringify(form));
      window.location.href ="/leaksurvey?fromDetails=true&detailsType=form";
    }
    else{
      localStorage.setItem("mapForm", JSON.stringify(form));
      window.location.href = "/map?fromDetails=true&detailsType=form";
    }
  }

  closeHistoryList(){
    this.setState({loadingHistory : false, showHistoryList : false});
  }

  closeHistoryRecord(){
    this.setState({showingHistoryRecord : false, formData : this.state.upToDateFormData, showHistoryList : true});
  }

  closeTicketLog(){
    this.setState({loadingChangeLog : false, showChangeLog : false});
  }

  render() {
    return (
      <div className="col-10 d-flex justify-content-center p-0 m-0" style={{ backgroundColor: "#E9E9EF" }}>
        {(this.state.formData && !this.state.fetchingFormData) ? 
        <div className={this.props.fromTaskMap ? "" : "container-fluid"} style={{width : !this.props.fromTaskMap ? "" : '65vh'}}>
          {!this.props.fromTaskMap && <SurveyStrip
            type={this.state.formData.FormData.Name}
            id={this.state.formData.FormData.FormDataId}
            entityTypeId={this.state.showHistoryList ? 26 : this.state.showingHistoryRecord ? 25 : 93} //HistoryCollection, History record,  CustomFrom
            submitToDirt={false}
            entity={this.state.formData}
            pictureCount={this.state.pictureCount}
            loadingUpdate={this.state.loadingUpdate}
            ticket={this.state.formData.FormData.FormTypeId == 4}

            fetchChangeLog={this.state.loadingChangeLog || this.state.showChangeLog ? null :this.fetchChangeLog}
            editing={this.state.editing}
            setUpEditing={this.setUpEditing}
            cancelEditing={this.cancelEditing}
            saveSurvey={this.saveSurvey}
            goToMap={this.goToMap}
            Secured={this.state.formData.FormData.Secured}
            showingHistoryList={this.state.showHistoryList}
            closeEntity={(this.state.showHistoryList ? this.closeHistoryList : this.state.showingHistoryRecord ? this.closeHistoryRecord : this.state.showChangeLog ? this.closeTicketLog:  this.props.closeEntity ) || this.props.hideClose}
            historyRecordId={this.state.historyRecordId}
            fetchHistory={this.state.formData.FormData.FormTypeId == 5 || this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord || this.state.loadingChangeLog || this.state.showChangeLog ? null : this.fetchHistory}
            hideDataDownload={this.state.formData.FormData.FormTypeId == 5 || this.state.loadingChangeLog || this.state.showChangeLog}
            hideReport={this.state.formData.FormData.FormTypeId == 5 || this.state.loadingHistory || this.state.showHistoryList || this.state.loadingChangeLog || this.state.showChangeLog}
            hideEdit={!this.state.dataLoadSuccess || this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord|| this.state.loadingChangeLog || this.state.showChangeLog}
            hideArchive={this.state.formData.FormData.FormTypeId == 5 || this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord|| this.state.loadingChangeLog || this.state.showChangeLog}
            hideMap={this.state.formData.FormData.FormTypeId == 5 || this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord|| this.state.loadingChangeLog || this.state.showChangeLog}
            showingHistoryRecord={this.state.showingHistoryRecord}
            modifiedDate={this.state.showingHistoryRecord ? this.state.formData.FormData.ModifiedDate : null}
          />}
          {!this.state.loadingHistory && !this.state.showHistoryList && !this.state.loadingChangeLog && !this.state.showChangeLog && <FormDataBody refreshFormData={this.fetchFormData.bind(this)} userCollection={this.props.userCollection} updateTaskDocuments={this.props.updateTaskDocuments} updateTaskImages={this.props.updateTaskImages} fromTaskMap={this.props.fromTaskMap} fromDashboard={this.props.fromDashboard} showingHistoryRecord={this.state.showingHistoryRecord} formData={this.state.formData} editing={this.state.editing} setPictureCount={this.setPictureCount} editValue={this.editValue} editFormTaskStatus={this.editFormTaskStatus} editAssignedUser={this.editAssignedUser}/>}
          {this.state.loadingHistory && 
            <div className="row">
            <div className="spinnerDivUserPage">
              <div className="text-center authSpinnerDiv" style={{padding:10}}>
                {this.state.historyStatusText != ""?  <div className="authSpinnerDivMessage">
                  {this.state.historyStatusText}
                </div> : ''}  
              <div className="barLoaderPortal">
                <BarLoader sizeUnit={"px"} size={150} color={"#095399"}
                  loading={ this.state.historyStatusText == "" ? false : this.state.historyStatusText == "No History Returned" || this.state.historyStatusText == "There was an error retrieving history. Try again." ? false : true } />
              </div>
              </div>
            </div>
          </div>}
            {this.state.loadingChangeLog && 
              <div className="row">
              <div className="spinnerDivUserPage">
                <div className="text-center authSpinnerDiv" style={{padding:10}}>
                  {this.state.changeLogStatusText != ""?  <div className="authSpinnerDivMessage">
                    {this.state.changeLogStatusText}
                  </div> : ''}  
                <div className="barLoaderPortal">
                  <BarLoader sizeUnit={"px"} size={150} color={"#095399"}
                    loading={ this.state.changeLogStatusText == "" ? false : this.state.changeLogStatusText == "No Data Returned" || this.state.changeLogStatusText == "There was an error retrieving Ticket Log. Try again." ? false : true } />
                </div>
                </div>
              </div>
            </div>
            }
            {this.state.showChangeLog && 
            <div className="row">
              {!this.state.changelogCollection || this.state.changelogCollection.length == 0 ? <div className="spinnerDivUserPage">
                <div className="text-center authSpinnerDiv" style={{padding:10}}>
                  <div className="authSpinnerDivMessage">
                  {this.state.changeLogStatusText ? this.state.changeLogStatusText : "No Data Returned"}
                  </div>
                </div>
              </div>
              : <div className=""  style={{marginLeft: 10, marginRight: 10, marginTop : 15,  overflowY : 'scroll', overflowX :'hidden', height : '80vh'}} > 
                  {this.state.changelogCollection.map((log, index) => <div key ={index} style={{width : "50vw", marginBottom : index == this.state.changelogCollection.length -1 ? 250 : 0}}>
                    <div style={{fontWeight : "bold", display : "inline"}}>{new Date(log.DateOfChange + "z").toLocaleDateString("en-US")} {new Date(log.DateOfChange + "z").toLocaleTimeString("en-US")}: </div>
                    <div style={{display : "inline"}}>{log.ChangeHeader}</div>
                    <div style={{display : "block", marginLeft : 10, width : '95%'}}>DETAILS: {log.ChangeDescription}</div>
                    <div style={{display : "block", marginLeft : 10}}>CHANGE MADE BY: {log.ChangeUserDisplayName}</div>
                  </div>)}
                </div>
              }
            </div>}
          {this.state.showHistoryList && 
            <div className="row">
              {(!this.state.formDataHistory || !this.state.formDataHistory.rows || this.state.formDataHistory.rows.length == 0) && (!this.state.formTaskHistory || this.state.formTaskHistory.length == 0)  ? <div className="spinnerDivUserPage">
                <div className="text-center authSpinnerDiv" style={{padding:10}}>
                  <div className="authSpinnerDivMessage">
                  {this.state.historyStatusText ? this.state.historyStatusText : "No History Returned"}
                  </div>
                </div>
              </div>
              : <div className="searchResultsColumn"  style={{marginLeft: 10, marginRight: 10, overflowY : 'scroll', height : 725}} > 
                  {this.state.formDataHistory && this.state.formDataHistory.rows &&  <MDBDataTable id="history-datatable" searching={false} className="mdbDataTabel"btn Button striped bordered hover data={this.state.formDataHistory} paging={false}/>}
                  {
                    (this.state.formTaskHistory && this.state.formTaskHistory.length > 0) && 
                    <div style={{overflowY : "scroll", marginBottom : 100 * this.state.formTaskHistory.length}}>
                      <h3 style={{fontWeight : "bold"}}>Task History:</h3>
                      {this.state.formTaskHistory.map((task, index) => <div key= {index}>
                      <div style={{fontWeight : "bold", display : "inline"}}>{new Date(task.ModifiedDate + "z").toLocaleDateString("en-US")} {new Date(task.ModifiedDate + "z").toLocaleTimeString("en-US")}: </div>
                    <div style={{display : "block", marginLeft : 10}}>Task was Assigned To: {task.AssignedUserDisplayName}</div> 
                    <div style={{display : "block", marginLeft : 10}}>Task Status was: {task.FormTaskStatus == 0 ? "Open" : task.FormTaskStatus == 1 ? "Closed" : task.FormTaskStatus == 2 ? "Project" : "Canceled"}</div> 
                    <div style={{display : "block", marginLeft : 10}}>CHANGE MADE BY: {task.ModifiedByDisplayName}</div>
                      </div>)}
                    </div>
                  }
                </div>
              }
              
            </div>
          }
          {this.state.updateStatusText !="" &&
            <Snackbar open={this.state.updateStatusText != ""} onClick={() =>this.setState({updateStatusText: ''})} autoHideDuration={6000} onClose={this.handleClose}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
              <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
                {this.state.updateStatusText}
              </Alert>
            </Snackbar>
          }
        </div>
        : (!this.state.fetchingFormData) ? <div className = "container-fluid">
          <div className ="text-center detailsErrorMessage">Something went wrong loading the data. Go back to Reporting page and try again.</div>
           
        </div> :
        <div className = "container-fluid">
          <div className ="text-center detailsErrorMessage">Loading Form Data
            <div className="barLoaderPortal">
              <BarLoader sizeUnit={"px"} size={150} color={"#095399"}
                loading={ this.state.fetchingFormData} />
            </div>
          </div>
        </div>}
      </div>
      
    );
  }
}

FormDataPage.propTypes = {
  location: PropTypes.object,
  forceRefresh: PropTypes.func,
  closeEntity: PropTypes.func,
  fromTaskMap : PropTypes.bool,
  fromDashboard : PropTypes.bool,
  updateTaskImages : PropTypes.func,
  updateTaskDocuments : PropTypes.func,
  hideClose: PropTypes.bool
};
export default FormDataPage;