// source: https://www.youtube.com/watch?v=Y0-qdp-XBJg  approx 6:00

import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import auth from "./Auth";
import PageUnauthorized from "../PageUnauthorized";

export const ProtectedRoute = ({
  component: Component,
  forceRefresh,
  ...rest
}) => {
  return (
    // video approx 11:30 is where auth check and Redirect is explained
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated()) {
          return <Component {...props} forceRefresh={forceRefresh} />; //  {...props}  addBreadcrumb={addBreadcrumb}
        } else {
          // (kwt 03/31/2019) Returning component will display the "Unauthorized" message but
          // doesn't leverage the routing.
          return <PageUnauthorized {...props} />;
          // (kwt) Returning a Redirect will take the user to the login (or whatever is specified) page, but page (ie route) must
          // be known to the router.
          // This Redirect approach is the recommended one from all the examples.
          // return <Redirect
          //     to = {
          //         {
          //         pathname: "/login",
          //         state: {
          //             from: props.location
          //         }
          //     }}
          // />
        }
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  forceValidation: PropTypes.func,
  forceRefresh: PropTypes.func
};
