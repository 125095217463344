import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import moment from 'moment';
import PropTypes from "prop-types";

highchartsMore(Highcharts);

const CountByDate = (props) =>{
  const [chart, setChart] = React.useState(null);
  const [sComplete, setData] = React.useState(null);
  const [categories, setCategories] = React.useState([]);

  const chartCallback = c => setChart(c);

  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  React.useEffect(() => {
    
    console.time('CountByDate');
    let sComplete = [];

    if (props.data){

      let range = getDateRange(props.dateFrom, props.dateTo);
      
      props.data.forEach(item =>{
        let formTypeId = -1;
        let dateValue = null;
        let formId = -1;
        let dateIndex = -1;

        dateValue = props.useModified ? item.ModifiedDate : item.CreatedDate;
        formTypeId = item.FormTypeId;
        
        try{
          if (props.formData != undefined) formId = props.formData.find(function (element) {return element.FormId == item.FormId;}).Name;
        }
        catch(eat){
          //eat
        }

        setCategories(range);
        
        if (formId != -1){
            
          // DETERMINE DATE INDEX
          let dateFormatted = moment(dateValue).format("YYYY-MM-DD");
          dateIndex = range.findIndex(i => i === dateFormatted);

          // SERIES BY DATE AND GROUP
          let formIndex = sComplete.findIndex(i => i.name === formId);
          if (formIndex >= 0)
          {
            sComplete[formIndex].data[dateIndex] = sComplete[formIndex].data[dateIndex] + 1;
          }
          else
          {
            let dataArray = new Array(range.length).fill(0);
            sComplete.push({name: formId, data: dataArray});
            formIndex = sComplete.findIndex(i => i.name === formId);
            sComplete[formIndex].data[dateIndex] = 1;
          }
        }

      });

      setData(sComplete);
    }

    console.timeEnd('CountByDate');

  }, [props.data])

  const getDateRange = (startDate, stopDate) => {
    let dateArray = [];
    let fromDate = moment(startDate).toDate();
    let toDate = moment(stopDate).toDate();
    while(fromDate <= toDate) {
      dateArray.push(moment(fromDate).format("YYYY-MM-DD"))
      fromDate = moment(fromDate).add(1, 'days');
    }
    return dateArray;
  }

  return(
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark"}}
      highcharts={Highcharts}
      allowChartUpdate={true}
      callback={chartCallback}
      constructorType="chart"
      options={{
        chart : { type: "column", backgroundColor : "#E1E9EF"},
        title: { text: "Forms By Date"},
        plotOptions:{
          column:{
          },
          series: {
            stacking: "normal",
            marker: {
              enabled: false,
              symbol : "circle",
              radius: 4, 
              states :{
                hover: {
                  enabled: true
                }
              }
            },
            point: {
              events: {
                click : function(event){
                  let formattedDate = moment(event.point.category).format("MM/DD/YYYY");
                  props.filterCallback({ 
                    filterType: 'ByDate',
                    filterValue: event.point.category,
                  },
                  `${formattedDate}`)
                }
              }
            },
          }
        },
        xAxis: { categories: categories || []},
        yAxis: { title: { text : "Forms by Date"}},
        tooltip: { shared: true },
        series: sComplete,
        credits: {enabled: false},
        legend: {enabled: true}
      }}
    />
  );
}

CountByDate.propTypes = {
  refreshKey : PropTypes.number,
  dateFrom : PropTypes.string,
  dateTo : PropTypes.string,
  data : PropTypes.array,
  formData : PropTypes.array,
  filterCallback : PropTypes.func,
  useModified: PropTypes.bool
}

export default CountByDate;