import React from 'react';
import ReactDOMServer from 'react-dom/server';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsNetwork from "highcharts/modules/networkgraph";
import PropTypes from "prop-types";
//eslint-disable-next-line
import moment from 'moment';
// Highcharts.setOptions({lang: {noData: "No Data to Display"}});
highchartsNetwork(Highcharts);

let component = null;

class ContactGraph extends React.Component {
  constructor(props){
      super(props);
      this.state = {
          selectedNode: null,
          show: false,
          keyedNames: {},
          keyed: {},
          nodes: [],
          data : [],
          dates: {},
          names: {},
          showingOnlyFailed: false,
      }
      this.internalChart = null;
      component = this;
  }

  options = {
      title : {
          text: "Contact Graph"
      },
      chart: {
          type: 'networkgraph',
          backgroundColor: "#E9E9EF",
          panning: true,
          // panKey : "shift",
          // zoomType : "x"
      },        
      plotOptions: {
          series: {
              cursor: 'pointer',
              marker: {
                radius: 12,
              },
              point: {
                  events: {
                      click: (e) => this.nodeClickHandler(e),
                  }
              },
          },
          networkgraph: {
  
          },
      },
      tooltip: {
          useHTML: true,
          formatter: function() {
              return component.tooltipFormatter(this.key);
          },
          outside: true
      },
      series : [],
      credits : {
        enabled: false
      },
      legend : {
        enabled: false
      }
  }

  nodeClickHandler = e => {
    let key = e.point.id;
    let scan = this.state.keyedNames[key];
    if(scan) {
      this.props.nodeClick(scan.Id);
    }else{
      this.props.nodeClick(null, null, null);
    }
  }

  removeOlderValues = (data) => {
      // console.time("Remove Older Values");
      let newest = {};
      data.forEach(scan => {
          if(newest[scan.UserScanned]){
              if(newest[scan.UserScanned].CreatedDate < scan.CreatedDate){
                  newest[scan.UserScanned] = scan;
              }
          }else{
              newest[scan.UserScanned] = scan;
          }
      });
      // console.timeEnd("Remove Older Values");
      return Object.values(newest);
  }

  setupData = data => {
      let final = [];
      let keyed = {};
      let dates = {};
      let keyedNames = {};
      let name = {};
      let newestNodes = this.removeOlderValues(data);
      newestNodes.forEach(c => {
        name[c.CreatedBy] = c.ScannerName;
        name[c.UserScanned] = c.UserScannedName;
        final.push({from: c.CreatedBy, to: c.UserScanned});
        keyedNames[c.UserScanned] = c;
      })
      data.forEach(c => {
          keyed[c.Id] = c;
          let dateWithOffset = new Date(c.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
          if(!dates[c.UserScanned]){
            dates[c.UserScanned] = [dateWithOffset];
          }else{
            dates[c.UserScanned] = [...dates[c.UserScanned], dateWithOffset];
          }
      });
      // Leak Data
      // this.props.leakData.forEach(survey => {
      //   final.push({ from: survey.CreatedBy, to: survey.LeakSurveyDataId.toString() });
      // })
      // console.log("Setup Data", final, keyed, dates, keyedNames);
      this.setState({ data: final, keyed, dates, keyedNames, names: name });
      this.forceUpdate();
  }

  getColor = (status) => status === "Passed" ? "#2D7952" : status === "Failed" ? "#8A4A4A" : "#DE8C26";

  // setupColors = (data) => {
  //     // console.time("Setup Colors");
  //     let final = [];
  //     data.forEach(c => {
  //       let color = this.getColor(c.ScanResult);
  //       final.push({
  //         color,
  //         id: c.UserScanned,
  //         marker: {
  //             radius: 12
  //         }
  //       })
  //     });
  //     // console.timeEnd("Setup Colors");
  //     this.setState({ nodes: final });
  // }

  tooltipFormatter = (id) => {
      // console.time("Tooltip Formatter", id);
      const { keyedNames, names } = this.state;
      const { toShow } = this.props;
      let tt = null;
      let sr = keyedNames[id];
      // console.log("Scan Result = ", sr);
      if(!sr){
        tt = <h6>{names[id]}</h6>
      }
      else if((sr && toShow[sr.ScanResult]) || (sr.ScanResult === "No Temperature" && toShow["Not Completed"])){
        let dates = this.state.dates[id];
        let sorted = dates.sort((a, b) => b - a).reverse();
        let top5 = sorted.slice(0, 5);
        tt = dates ? <div className="d-flex flex-column">
            <div>
              <h6>Scan Dates</h6> {/*{keyedNames[id] && `for ${keyedNames[id].UserScannedName}`}*/}
              <span className="font-weight-bold">{sorted.length} Total</span>
            </div>
            <div className="d-flex flex-wrap">
            {top5 ? top5.map((date, index) => <span key={index}>{date}</span>) : null}
            </div>
        </div> : <h6>{names[id]}</h6>
      }else{
        tt = null;
      }
      // console.timeEnd("Tooltip Formatter");
      return tt ? ReactDOMServer.renderToStaticMarkup(tt) : null;
  }

  formatter = (id) => {
    let {names, keyedNames} = this.state;
    let datalabel;
    if(keyedNames[id]){
      let sr = keyedNames[id];
      if(sr) {
        sr = sr.ScanResult;
        if(this.props.toShow[sr]) {datalabel = <span>{names[id]}</span>}
        else if(this.props.toShow["Not Completed"] && sr === "No Temperature"){ datalabel = <span>{names[id]}</span>}
        else {datalabel = null;}
      }
    }else{
      datalabel = <span>{names[id]}</span>
    }
    let final = ReactDOMServer.renderToStaticMarkup(datalabel);
    return final;
  }

  toggleStatus = (customData = null) => {
    console.time("Toggle Status");
    const {toShow} = this.props;
    let final = [];
    let newData = [];
    let data = this.removeOlderValues(customData ? customData : this.props.data);
    data.forEach(scan => {
      if(toShow[scan.ScanResult] || scan.ScanResult === "No Temperature"){ // || scan.ScanResult === "No Temperature" if 
        let result = scan.SearchResult;
        newData.push({ from: scan.CreatedBy, to: scan.UserScanned, color: "black", dataLabels: {enabled: true} });
        final.push({ color: this.getColor(scan.ScanResult), id: scan.UserScanned, marker: {radius: result ? 12 : 6, symbol: "circle"} });
      }else{
        newData.push({ from: scan.CreatedBy, to: scan.UserScanned, color: "transparent", dataLabels : {enabled: false} })
        final.push({ color: "transparent", id: scan.UserScanned, opacity: 0, marker: {radius: 12} });
      }
    });
    if(this.props.selectedId){
      // Highlight node with blue if its selected
      final = final.filter(item => {return item.id !== this.props.selectedId});
      final.push({id: this.props.selectedId, color: "#16548E", marker: {radius: 16}});
    }
    this.setState({ nodes: final, data: newData });
    console.timeEnd("Toggle Status");
  }

  componentDidUpdate(prevProps){
    let strData = JSON.stringify(prevProps.data);
    let curStrData = JSON.stringify(this.props.data);
    if(strData !== curStrData){
      this.setupData(this.props.data);
      // this.setupColors(this.props.data);
      this.toggleStatus(this.props.data);
    }
    else if(prevProps.toShow !== this.props.toShow || prevProps.selectedId !== this.props.selectedId){
      this.toggleStatus();
    }
    else if(prevProps.open !== this.props.open){
      this.internalChart&&this.internalChart.reflow();
      this.forceUpdate();
    }
  }

  afterGraphCreated = (graph) => {
    this.internalChart = graph;
  }

  render() {
    const {data, nodes} = this.state;
    return (
        <HighchartsReact
            containerProps={{ className: "w-100 h-100 net-graph-container d-flex justify-content-center align-items-center",}}
            highcharts={Highcharts}
            options={ {
              ...this.options,
              series: [
                  {
                      type: "networkgraph",
                      name: "Contact Graph",
                      draggable: false,
                      plotBorderWidth: 0,
                      dataLabels: {
                          enabled: true,
                          linkFormat: "",
                          formatter: function(){
                            // console.log(this.key);
                            return component.formatter(this.key);
                          }
                      },
                      nodes: nodes,
                      data : data,
                  },
              ]
          } }
          allowChartUpdate
          constructorType="chart"
          callback={this.afterGraphCreated}
        />
      );
  }
}

ContactGraph.propTypes = {
  nodeClick : PropTypes.func,
  toShow : PropTypes.object,
  data : PropTypes.array,
  selectedId : PropTypes.string,
  open : PropTypes.bool
}

export default ContactGraph;