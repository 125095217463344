/* eslint react/prop-types: 0 */
import React from 'react';
import Highcharts from 'highcharts';
// import moment from 'moment';
// import ReactDOMServer from 'react-dom/server';
import PropTypes from "prop-types";
import HighchartsReact from 'highcharts-react-official';

const SurveyType = {
  0 : "Survey",
  1 : "Incident",
  10: "External",
  11: "LPDamage",
  12: "Logo",
  13: "GPSSurvey",
  14: "Inaccessible",
  15: "Aoc",
  16: "Leak Survey",
  17: "Assignment Area",
  18: "Initial Audit",
  19: "100 Day Audit",
  20: "Documentation Audit",
  21: "Field Audit",
  22: "Meter Inspection - Outside",
  24: "Meter Inspection - Inside",
  23: "Custom GIS",
  93: "Custom Form",
  Survey : 0,
  Incident : 1,
  External : 10,
  LPDamage : 11,
  Logo : 12,
  GPSSurvey : 13,
  LeakSurveyInaccessible : 14,
  "Inaccessible": 14,
  LeakSurveyAoc : 15,
  "Aoc": 15,
  LeakSurveyLeak : 16,
  "Leak Survey" : 16,
  LeakSurveyAssignmentArea : 17,
  LeakSurveyInitialAudit : 18,
  LeakSurvey100Audit : 19,
  LeakSurveyDocumentationAudit : 20,
  LeakSurveyFieldAudit : 21,
  LeakSurveyMeterInspection : 22,
  "Meter Inspection - Outside": 22,
  "Meter Inspection - Inside": 24,
  "Custom GIS" : 23,
  CustomForm : 93
}

const StackedSurveysByPerson = (props) => {
  const [chart, setChart] = React.useState(null);
  const [graphData, setGraphData] = React.useState([]);
  const [xAxisLabels, setXAxisLabels] = React.useState([]);

  const chartCallback = chart => {
    setChart(chart);
  }

  const setupSurveysByPerson = async() => {
    // New logic for surveys by person
    let { data } = props;
    let newSurveyByPerson = {};
    data.forEach(survey => {
      let key = SurveyType[survey.LeakSurveyTypeId];
      if(newSurveyByPerson[survey.ModifiedByDisplayName]){
        newSurveyByPerson[survey.ModifiedByDisplayName][key] ? 
        newSurveyByPerson[survey.ModifiedByDisplayName][key] += 1 :
        newSurveyByPerson[survey.ModifiedByDisplayName][key] = 1
      }else{
        newSurveyByPerson[survey.ModifiedByDisplayName] = {};
        newSurveyByPerson[survey.ModifiedByDisplayName][key] = 1;
      }
    });
    let finalCategories = [];
    let finalDataSetup = {
      "Leak Survey": [],
      "Meter Inspection - Outside": [],
      "Meter Inspection - Inside": [],
      "Aoc": [],
      "Inaccessible": [],
      "Custom GIS":[] 
    };
    let finalData = [];
    Object.keys(newSurveyByPerson).forEach(key => {
      finalCategories.push(key);
      let item = newSurveyByPerson[key];
      Object.keys(finalDataSetup).forEach(category => {
        if(item[category]){
          finalDataSetup[category].push({name: category, y: item[category]});
        }else{
          finalDataSetup[category].push(null);
        }
      })
    });
    Object.keys(finalDataSetup).forEach(series => {
      finalData.push({ name: series, data: finalDataSetup[series] });
    })
    // Setting up Series data
    setGraphData(finalData);
    setXAxisLabels(finalCategories);
  }

  React.useEffect(() => {
    setupSurveysByPerson();
  }, [props.data])

  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  return(
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark"}}
      highcharts={Highcharts}
      options={
        {
          title : { text: props.graphTitle || "" },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                  enabled: true
              }
            },
            series: {
              cursor: "pointer",
              point: {
                events: {
                  click: function(event){
                    let surveyTypeId = SurveyType[event.point.name];
                    props.filterCallback({ ModifiedByDisplayName: event.point.category, LeakSurveyTypeId: surveyTypeId }, `Showing ${event.point.category}'s ${event.point.name} Surveys`);
                  }
                }
              }
            }
        },
        xAxis: {
          categories: xAxisLabels || [],
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: "Surveys"
          }
        },
        colors: ["#16558F", "#2D7952", "#DE8C26", "#8A4A4A"],
        chart: {
          type: 'column',
          backgroundColor: "#E9E9EF",
        },        
        series : graphData || [],
        credits : {
          enabled: false
        },
        legend : {
          enabled: true
        }
      } }
      allowChartUpdate
      callback={chartCallback}
      constructorType="chart"
    />
  );
}

StackedSurveysByPerson.propTypes = {
    data : PropTypes.array,
    categories : PropTypes.array,
    graphTitle : PropTypes.string,
}

export default StackedSurveysByPerson;