import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import moment from 'moment';
import PropTypes from "prop-types";

highchartsMore(Highcharts);

const CountByMemberCode = (props) =>{
  const [graphCategories, setGraphCategories] = React.useState([]);
  const [graphData, setGraphData] = React.useState([]);
  const [chart, setChart] = React.useState(null);

  React.useEffect(() => {
    
    console.time('CountByMemberCode');
    let parsed = {};
    props.data.forEach(survey => {
      if(survey.TicketDisplayValues && survey.TicketDisplayValues != ""){
        let split = survey.TicketDisplayValues.split("|");
        if(split.length >= 9){
          var codes = split[8];
          codes.split(' ').forEach(code =>{
            if(props.companyResponseCodes.indexOf(code) != -1){
              var cleansedCode = code.replace("\t","");
              parsed[cleansedCode] 
              ? parsed[cleansedCode] = {name: cleansedCode, survey:cleansedCode, y: parsed[cleansedCode].y +1}
              : parsed[cleansedCode] = {name: cleansedCode, survey: cleansedCode,  y: 1}
            }
          })
        }
      }
    });
    
    setGraphCategories(Object.keys(parsed));
    setGraphData(Object.values(parsed));

    console.timeEnd('CountByMemberCode');
  }, [props.data])

  const chartCallback = c => setChart(c);
  return(
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark"}}
      highcharts={Highcharts}
      options={{
        title: {text: "Tickets By MemberCode"},
        xAxis: {
          categories: graphCategories,
        },
        plotOptions: {
          series: {
            cursor : "pointer",
            point: {
              events: {
                click: function(event){
                  props.filterCallback({ 
                    filterType: 'ByMemberCode',
                    filterValue: event.point.category,
                  },
                  `${event.point.category}`)
                }
              }
            },
            dataLabels: {
              enabled: true,
              color: 'black',
              align: 'right',
              x: -3,
              y: 0,
              style: {
                  color: '#333',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  fontFamily: 'Trebuchet MS, Verdana, sans-serif'
              }
          },
          }
        },
        yAxis : {
          min: 0,
          title: { text: "Tickets" }
        },
        colors :["#16558F", "#2D7952", "#DE8C26"],
        chart:{
          type: 'bar',
          backgroundColor : "#E9E9EF",
        },
        column: {
          dataLabels: {
              verticalAlign: 'bottom',
              allowOverlap: true,
              enabled: true,
              inside: true
        }},
        series: [
          {
            name: "Tickets",
            data : graphData
          }
        ],
        credits: { enabled : false },
        legend : { enabled: false },
      }}
      allowChartUpdate={true}
      callback={chartCallback}
      constructorType="chart"
    />
  );

}

CountByMemberCode.propTypes = {
  refreshKey : PropTypes.number,
  dateFrom : PropTypes.string,
  dateTo : PropTypes.string,
  data : PropTypes.array,
  formData : PropTypes.array,
  filterCallback : PropTypes.func,
  useModified: PropTypes.bool
}
    
export default CountByMemberCode;