import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from 'react-bootstrap/Button';
import DateRange from "@material-ui/icons/DateRange";
import moment from 'moment';
import PropTypes from "prop-types";

const DatetimePicker = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = e => {
    e.preventDefault();
    setOpen(true);
  }
  const handleChange = val => {
    let name = props.name || "";
    props.onChange(val, name);
  }
  return(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Button name="dateFromOpen" onClick={handleClick} className={`buttonAction btn btn-primary dehighlighted d-flex flex-row align-items-center ${props.buttonClasses || ""}`}>
        <DateRange className={`buttonIcon light d-inline-block d-md-none d-lg-inline-block ${props.iconClassName || ""}`} style={{marginRight:4, marginBottom:2}}/>
        <label className="buttonText withIcon date" id="FromDate" style={{ margin: 0, cursor: "pointer" }}>
        {props.value ? (props.showTime && props.showDate) ? moment(props.value).format("MM/DD/YYYY hh:mm a") : props.showTime ? moment(props.value).format("hh:mm a") : moment(props.value).format("MM/DD/YYYY") : "NO DATE SELECTED"}
        </label>
      </Button>
      {open && <DateTimePicker open={open} onClose={() => setOpen(false)} onChange={handleChange}/>}
      {/* <Dialog open={open}>
        <div style={{width: 400, height: 420}}>
          <div className="DatetimePicker" style={{ height: 385, width: 400 }}>
            <Datetime 
              value={props.value}
              open={true}
              renderInput={() => null}
              onChange={handleChange}
            />
          </div>
        </div>
        <DialogActions>
          <Button onClick={() => setOpen(false)} className="buttonAction buttonText">
            Confirm
          </Button>
        </DialogActions>
      </Dialog> */}
    </MuiPickersUtilsProvider>
  )
}

DatetimePicker.propTypes = {
  onChange : PropTypes.func,
  value : PropTypes.object,
  name: PropTypes.string,
  iconClassName: PropTypes.string,
  buttonClasses: PropTypes.string,
  showTime: PropTypes.bool,
  showDate: PropTypes.bool,
}

export default DatetimePicker;