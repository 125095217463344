import React from "react";
import auth from "../common/Auth";
import UserSearchStrip from "./UserSearchStrip";
import AddEditUser from "./AddEditUser";
import UsersTable from './UsersTable';
import { Button } from "react-bootstrap";
import { BarLoader } from "react-spinners";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OrgUtility from '../../Utility/Utility';

class UsersPage extends React.Component {
  constructor() {
    super();

    // this.siteSurveyLicenseId = 10010;
    // this.incidentLicenseId = 10020;
    // this.gpsSurveyLicenseId = 10030;
    // this.leakSurveyLicenseId = 10040;
    // this.sitesafeLicenseId = 10050;
    
    this.state = {
      users: [],
      viewedUsers: [],
      statusText: "",
      selectedFirstName: "",
      selectedTwoFactorEnabled : false,
      selectedLastName: "",
      selectedPhoneNumber : "",
      selectedExternalId : "",
      selectedEmail: "",
      selectedRole: "",
      managerUserId : "",
      editOption: "",
      selectedRoleCollection : [],
      userOptions: [],
      searchCriteria: "",
      editUserIndex: "",
      disablingUser: false,
      updatingLicense: false,
      unsavedLicenseChanges: false,
      nameColor: "black",
      emailColor: "black",
      phoneNumberColor : 'black',
      invalidInput: false,
      showButtons: false,
      resetPassword: false,
      updateStatusText : "",
      lincenseStatusText:"",
      showLicenseEdit: false,
      trialMode: false,
      templateLicenseCollection: [
        {"LicenseId": "gps", "Used": 0, "Total": 0, "Edit": 0},
        {"LicenseId": "site", "Used": 0, "Total": 0, "Edit": 0},
        {"LicenseId": "leak", "Used": 0, "Total": 0, "Edit": 0},
        {"LicenseId": "investigation", "Used": 0, "Total": 0, "Edit": 0},
        {"LicenseId": "sitesafe", "Used": 0, "Total": 0, "Edit": 0},
        {"LicenseId": "sitesafescan", "Used": 0, "Total": 0, "Edit": 0},
      ],
      alertSeverity: "success",
      duplicateUser : false,
      totalActive: 0,
      licensesAssigned: 0,
      licensesTotal: 0,
      updatingStatus: false,
      loadingQRCodes : false,
      // column Filter Variables
      licenseFilter: "",
      statusFilter: "",
      dialogOpen : false
    };

    this.getUsers = this.getUsers.bind(this);
    this.addUser = this.addUser.bind(this);
    this.editFirstName = this.editFirstName.bind(this);
    this.editTwoFactor = this.editTwoFactor.bind(this);
    this.editLastName = this.editLastName.bind(this);
    this.editEmail = this.editEmail.bind(this);
    this.editPhoneNumber = this.editPhoneNumber.bind(this);
    this.editExternalid = this.editExternalid.bind(this);
    this.editRole = this.editRole.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.search = this.search.bind(this);
    this.changeSearchCriteria = this.changeSearchCriteria.bind(this);
    this.cancelSave = this.cancelSave.bind(this);
    this.updateLicenseSave = this.updateLicenseSave.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.editRoleCollection = this.editRoleCollection.bind(this);
    this.duplicateUser = this.duplicateUser.bind(this);
    this.toggleUserStatus = this.toggleUserStatus.bind(this);
    this.downloadQRCodes = this.downloadQRCodes.bind(this);
    this.clickQRDownload = this.clickQRDownload.bind(this);
    this.handleQRClose = this.handleQRClose.bind(this);
    this.lockUnlockUser = this.lockUnlockUser.bind(this);
    this.licenseClickListener = null;
    this.disabledClickListener = null;
    this.editManagerUserId = this.editManagerUserId.bind(this);
  }

  componentDidMount() {
    if (this.state.users.length == 0 || this.state.viewedUsers.length == 0) { this.getUsers(); }
    if(this.props.match.params.mode === "edit"){
      this.props.history.push("/usergroup/users");
      this.setState({ showUserEdit: false });
    }
    this.props.forceRefresh();
  }

  componentDidUpdate(prevProps) { 
    window.resize();
    if (this.state.refreshLicenseUI){
      this.refreshLicenseUI();
    }
    if(this.props.match.params.mode === undefined && prevProps.match.params.mode !== this.props.match.params.mode){
      this.setState({ showUserEdit: false, userLicenseCollection: [] });
    }

    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.disabledClickListener);
    document.removeEventListener("click", this.licenseClickListener);
  }

  clickQRDownload(){
    this.setState({dialogOpen : true});
  }

  handleQRClose(cancel){
    this.setState({dialogOpen : false});
    if(!cancel){
      this.downloadQRCodes();
    }
  }

  cancelSave() {

    let userOptions = [];
    for (let i = 0; i < this.state.userOptions.length; i++) { userOptions.push(""); }

    // RESET LICENSE EDITS
    if (this.state.licenseCollection != null) this.state.licenseCollection.forEach(element => {element.Edit = 0;});
    if (this.state.userLicenseCollection != null) this.state.userLicenseCollection.forEach(element => {element.Edit = 0;});

    this.setState({
      selectedEmail: "",
      selectedPhoneNumber : "",
      selectedExternalId : "",
      selectedFirstName: "",
      selectedTwoFactorEnabled : false,
      selectedLastName: "",
      showButtons: false,
      selectedRole: "",
      selectedRoleCollection : [],
      managerUserId : "",
      addingUser: false,
      selectedUserId: "",
      userOptions,
      showUserEdit: false,
      disablingUser: false,
      updatingLicense: false,
      emailColor: "black",
      nameColor: "black",
      resetPassword: false,
      showLicenseEdit: false,
      updateStatusText: "",
      phoneNumberColor : 'black'
    });

  }

  changeStatus = async(disable, shouldRenderButtons = true) => {
    let activeLicenses = this.state.userLicenseCollection;
    let licenseCollectionUpdated = this.state.licenseCollection;
    await licenseCollectionUpdated.forEach(lic => {
      let found = activeLicenses ? activeLicenses.find(item => item.LicenseId === lic.LicenseId) : "";
      if(found){
        console.log("Toggling off : ", lic.LicenseId);
        this.updateLicense(lic.LicenseId, shouldRenderButtons);
      }
    })

    if (disable){ this.setState({disablingUser : true, selectedInactive: true,updatingLicense : true},() =>{ this.saveStatus();}); }
    else { this.setState({disablinguser : true, selectedInactive: false,updatingLicense : true}, () =>{ this.saveStatus(); }); }
    console.log("Change Status License Collection", this.state.licenseCollection);
  }

  handleClose(){
    
  }

  search() {
    
    let newViewedUsers = [];
    let userOptions = [];
    this.state.users.forEach(user => {
      userOptions.push("");
      if(this.state.searchCriteria == ""){
        newViewedUsers.push(user);
      }
      else if (
        user.DisplayNameProper && user.EmailAddress && (
        user.DisplayNameProper.toUpperCase().indexOf( this.state.searchCriteria.toUpperCase()) > -1 ||
        user.EmailAddress.toUpperCase().indexOf(this.state.searchCriteria.toUpperCase()) > -1)
      ) {
        newViewedUsers.push(user);
      }
    });
    this.setState({ viewedUsers: this.setupTableRows(newViewedUsers), userOptions });
  }

  changeSearchCriteria(e) {
    this.setState({ searchCriteria: e });
  }

  validUser() {
    let validUser = true;
    if (this.state.selectedEmail == undefined || this.state.selectedEmail == "") {
      this.setState({ emailColor: "red", invalidInput: true });
      validUser = false;
    }
    if (this.state.selectedFirstName == undefined || this.state.selectedFirstName == "") {
      this.setState({ nameColor: "red", invalidInput: true });
      validUser = false;
    }
    return validUser;
  }
  
  showLicense()
  {
    this.setState({
      //showLicenseEdit: true, 
      updateStatusText: "", 
      statusText:"", 
      refreshLicenseUI:true
    });
  }

  refreshLicenseUI(){
    
    this.state.userLicenseCollection.forEach(element => {
      var license = document.getElementById("license" + element.LicenseId);
      var licenseLabel = document.getElementById("licenseLabel" + element.LicenseId);
      if (license != undefined && element.UserId != undefined) {
        if (!license.classList.contains('active')){
          license.classList.add('active');
          licenseLabel.innerHTML = "Licensed";
        }
      }
    });

    this.setState({refreshLicenseUI:false});
  }

  updateLicenseSave()
  {
    // Only initiate a save if the licenses have been updated
    if (this.state.unsavedLicenseChanges){
      this.saveUser();
    }

    this.setState({showLicenseEdit: false, updateStatusText:""});
  }
  
  duplicateUser(){
    var button = document.getElementById("dupUser");
    var add = ! button.classList.contains('active');

    if(add){
      button.classList.add('active');
      this.setState({duplicateUser : true});
    }
    else{
      button.classList.remove('active');
      this.setState({duplicateUser : false});
    }
  }

  downloadQRCodes(){
    console.log(auth.userName());
    let body = {
      CompanyId : auth.companyId(),
      UserId : auth.userId(),
    }
    this.setState({loadingQRCodes : true});
    fetch(process.env.API_URL + "/api/account/users/qrCodes",{
      method : 'POST',
      headers:{
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        
        this.setState({loadingQRCodes : false, downloadFinished:true});
      }
      else{
        this.setState({loadingQRCodes : false, updateStatusText : "Data Download Failed - Try again or contact support", alertSeverity:"error"});
      }
    }).catch(error =>{console.log(error);this.setState({loadingQRCodes : false, updateStatusText : "Data Download Failed - Try again or contact support", alertSeverity:"error"});});
  }

  updateLicense(licenseId, shouldUpdateButtons = true){
    var licenseCollectionUpdated = this.state.licenseCollection;
    var licenseToUpdate = licenseCollectionUpdated.find(x => x.LicenseId === licenseId || x.LicenseId === licenseId+'12' || x.LicenseId === licenseId+'22');
    
    if(shouldUpdateButtons){
      var licenseButton = document.getElementById("license"+licenseId);
      var licenseLabel = document.getElementById("licenseLabel"+licenseId)
      var licenseAdd = !licenseButton.classList.contains('active');

      // VERIFY LICENSE ALLOCATION

      if (licenseAdd) {
        if (licenseToUpdate.Used + 1 > licenseToUpdate.Total) {
          this.setState({updateStatusText: "No unallocated licenses available. Revoke a user license or update subscription plan.", alertSeverity:"warning"});
          return;
        }
      }

      // UPDATE LICENSE UI
      if (licenseAdd) {
        licenseButton.classList.add('active');
        licenseLabel.classList.add('active');
        licenseLabel.innerHTML = "Licensed";
      }
      else {
        licenseButton.classList.remove('active');
        licenseLabel.classList.remove('active');
        licenseLabel.innerHTML = "Not Licensed";
      }
    }
    // UPDATE LICENSE MODEL
    licenseToUpdate.Edit += (licenseAdd ? 1 : -1)
    this.setState({licenseCollection: licenseCollectionUpdated, updateStatusText: "", updatingLicense:true, unsavedLicenseChanges:true})

    if (this.state.userLicenseCollection == undefined) 
      this.setState({userLicenseCollection: []});

    var license = this.state.userLicenseCollection.find(x => x.LicenseId === licenseId);
    if (license != undefined)
      license.Edit += (licenseAdd ? 1 : -1)
    else
      this.state.userLicenseCollection.push({LicenseId: licenseId, Edit: (licenseAdd ? 1 : 0)})
  }

  resetPassword(){

    let req = new XMLHttpRequest();
    req.open("POST", process.env.API_URL + "/api/account/resettoken", true);
    req.setRequestHeader("Accept","application/json");
    req.setRequestHeader("Content-Type", "application/json");
    let body = { User : { EmailAddress : this.state.selectedEmail } };
    req.onload = () =>{
      if(req.status == 200){
        this.setState({
          statusText: "",
          searchCriteria: "",
          showButtons: false,
          showUserEdit: false,
          disablingUser: false,
          updatingLicense: false,
          addingUser: false,
          emailColor: "black",
          nameColor: "black",
          invalidInput: false,
          resetPassword: false
        });
        this.setState({updateStatusText :"Password Reset. An email was sent to the user with a link to confirm the reset.", alertSeverity:"success"});
      }
      else if(req.status == 400){
        let response = JSON.parse(req.responseText).modelState;
        let failReason = "";
        for (let key in response) {
          failReason = response[key][0];
        }
        this.setState({statusText: "", showButtons: true, updateStatusText : "Error. " + failReason +". Try again.", alertSeverity:"error"});
      }
      else{
        this.setState({statusText: "", showButtons: true, updateStatusText : "Something went wrong. Try again.",alertSeverity:"error"});
      }
    }
    req.onerror = ()=>{
      this.setState({statusText: "", showButtons: true, updateStatusText : "Something went wrong. Try again.",alertSeverity:"error"});
    }
    req.send(JSON.stringify(body));
    this.setState({ statusText: "Reseting Password...", showButtons: false  });
  }

  formatPhoneNumber(phoneNumber){
    var match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    if(match){
      return match[1] + '-' + match[2] + '-' + match[3]
    }
    match = phoneNumber.match(/\d+/g);
    if(match){
      return match[0] + '-' + match[1] + '-' + match[2]
    }
    return null;
  }

  saveUser() {
    
    // INVALID USER REQUIREMENTS
    if (!this.state.disablingUser && !this.validUser()) { return; }

    // RESET PASSWORD
    if (this.state.resetPassword){ this.resetPassword(); return; }
    if(!this.state.selectedPhoneNumber || this.state.selectedPhoneNumber == ""){
      this.setState({
        updateStatusText : "Phone number is required.",
        alertSeverity : 'error',
        phoneNumberColor : 'red'
      });
      return;
    }
    let password = this.formatPhoneNumber(this.state.selectedPhoneNumber ? this.state.selectedPhoneNumber : "");
    if(!password || password.includes('undefined')){
      this.setState({
        updateStatusText : "Phone Number is in an unsupported format.",
        alertSeverity : 'error',
        phoneNumberColor : 'red'
      });
      return;
    }
    this.setState({ statusText: "Saving User..." });
    this.setState({ showButtons: false });
    
    let req = new XMLHttpRequest();
    req.open( "POST", process.env.API_URL + (this.state.resetPassword ? "/api/account/resettoken" : "/api/account/edit"), true );
    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader("Authorization", "Bearer " + auth.token());

    let DeletedDate = null;
    let DeletedDateUTCOffset = null;
    let DeletedBy = null;
    let date = new Date();

    if (this.state.selectedInactive){
      DeletedDate = date;
      DeletedDateUTCOffset =(-1) *( date.getTimezoneOffset() /60);
      DeletedBy = auth.employeeId();
    }

    var licenseCollectionUpdate = this.state.userLicenseCollection;

    console.log("License Collection Update : ", licenseCollectionUpdate);

    let body = {
      UserId: auth.employeeId(),
      User: {
        EmailAddress: this.state.selectedEmail, 
        PhoneNumber : password,
        ExternalId : this.state.selectedExternalId,
        FirstName: this.state.selectedFirstName,
        TwoFactorEnabled : this.state.selectedTwoFactorEnabled,
        LastName: this.state.selectedLastName,
        Role: this.state.selectedRole,
        ManagerUserId : this.state.managerUserId,
        UserId: this.state.addingUser ? -1 : this.state.selectedUserId,
        Inactive: this.state.selectedInactive,
        Licensed: this.state.licensed,
        CompanyId: auth.companyId(),
        DeletedBy,
        DeletedDate,
        DeletedDateUTCOffset
      },
      DuplicateEmailRequest : this.state.duplicateUser,
      UpdatingLicense: this.state.updatingLicense,
      LicenseCollection: licenseCollectionUpdate,
      CompanyId: auth.companyId(),
    };
    if(this.state.addingUser){
      body.User.DisplayNameProper = this.state.selectedFirstName + ', ' + this.state.selectedLastName;
    }

    req.onload = () => {
      if (req.status == 200) {
        let response = JSON.parse(JSON.parse(req.responseText));
        let oldUsers = this.state.users;
        let newUserOptions = [];
        if (!this.state.addingUser) {
          for (let i = 0; i < oldUsers.length; i++) {
            newUserOptions.push("");
            if (oldUsers[i].UserId == response.User.UserId) {
              if (response.User.Role == "Admin") {
                response.User.RoleIdCollection.push(1);
              }
              oldUsers[i] = response.User;
            }
          }
        } else {
          if (response.User.Role == "Admin") {
            response.User.RoleIdCollection.push(1);
          }
          oldUsers.push(response.User);
          for (let i = 0; i < oldUsers.length; i++) {
            newUserOptions.push("");
          }
        }
        let i = oldUsers.length;
        while (i--) {
          if (oldUsers[i].DeletedBy != null || oldUsers[i].DeletedDate != null || oldUsers[i].DeletedDateUTCOffset != null) {
            oldUsers.push(oldUsers[i]);
            oldUsers.splice(i, 1);
          }
        }

        this.setState({
          statusText: "",
          users: oldUsers,
          viewedUsers: this.setupTableRows(oldUsers),
          searchCriteria: "",
          userOptions: newUserOptions,
          showButtons: false,
          showUserEdit: false,
          disablingUser: false,
          updatingLicense: false,
          addingUser: false,
          emailColor: "black",
          nameColor: "black",
          selectedRoleCollection : [],
          managerUserId : "",
          invalidInput: false,
          resetPassword: false,
          showLicenseEdit: false,
          updateStatusText : this.state.addingUser ? "User will be emailed with a temporary password" : "User updated",
          alertSeverity: "success",
          duplicateUser : false,
          // Resetting userLicenceCollection after save
          userLicenseCollection: []
        });

        this.getUsers();

      } else if (req.status == 400) {
        let addEdit = this.state.addingUser ? "creating " : "updating ";
        let responseText = JSON.parse(req.responseText);
        let response = responseText.modelState;
        let failReason = "";
        for (let key in response) {
          failReason = response[key][0];
        }
        this.setState({ statusText: "", updateStatusText : "Error " + addEdit + "user: " + failReason, showButtons: true, alertSeverity: "error", duplicateUser : false });
      } else {
        this.setState({ statusText: "", showButtons: true, updateStatusText : "Error. Try again", alertSeverity: "error", duplicateUser : false });
      }
    };

    req.onerror = () => {
      let addEdit = this.state.addUser ? "creating " : "updating ";
      this.setState({ statusText: "", showButtons: true, updateStatusText : "Error " + addEdit + " user. Try again.", alertSeverity: "error", duplicateUser : false });
    };

    req.send(JSON.stringify(body));
  }

  editFirstName(name) {
    this.setState({ selectedFirstName: name, nameColor: "black" });
  }

  editTwoFactor(){
    this.setState({ selectedTwoFactorEnabled : !this.state.selectedTwoFactorEnabled, phoneNumberColor : 'black'});
  }

  editLastName(name) {
    this.setState({ selectedLastName: name, nameColor: "black" });
  }

  editEmail(email) {
    this.setState({ selectedEmail: email, emailColor: "black" });
  }

  editPhoneNumber(phoneNumber){
    this.setState(prevState => ({ selectedPhoneNumber : this.normalizeInput(phoneNumber, prevState.selectedPhoneNumber), phoneNumberColor: 'black'}));
  }

  normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    
    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  }

  editExternalid(externalId){
    this.setState({ selectedExternalId : externalId});
  }

  editRole(role) {
    this.setState({ selectedRole: role });
  }

  editManagerUserId(userId){
    this.setState({ managerUserId : userId });
  }

  editRoleCollection(collection){
    let roleCollection = this.state.selectedRoleCollection;
    if(roleCollection.includes(collection)){
      roleCollection.splice(roleCollection.indexOf(collection),1);
    }
    else{
      roleCollection.push(collection);
    }
    this.setState({selectedRoleCollection : roleCollection});
  }

  addUser() {
    let newUserOptions = [];
    for (let i = 0; i < this.state.userOptions.length; i++) {
      newUserOptions.push("");
    }

    this.setState({
      showUserEdit: true,
      selectedEmail: "",
      selectedPhoneNumber : "",
      selectedExternalId : "",
      selectedFirstName: "",
      selectedTwoFactorEnabled : false,
      selectedLastName: "",
      userOptions: newUserOptions,
      updateStatusText: "",
      lincenseStatusText:"",
      addingUser: true,
      showButtons: true,
      selectedRole: "User",
      managerUserId : "",
      selectedInactive: false,
      licensed: false
    });
  }

  getUsers() {
    this.setState({ statusText: "Retrieving Data..." });
    let req = new XMLHttpRequest();
    req.open("POST", process.env.API_URL + "/api/account/users", true);
    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader("Authorization", "Bearer " + auth.token());
    req.onload = () => {
      if (req.status == 200) {
        let response = JSON.parse(JSON.parse(req.responseText));
        if (response.ResponseType == 0 && response.UserCollection.length >= 0) {
          let editOptions = [];
          let i = response.UserCollection.length;
          
          while (i--) {
            if (response.UserCollection[i].DeletedBy != null ||response.UserCollection[i].DeletedDate != null || response.UserCollection[i].DeletedDateUTCOffset != null) {
              response.UserCollection.push(response.UserCollection[i]);
              response.UserCollection.splice(i, 1);
            }
          }

          // SITESAFE - Licenses are 10 per Company Seat
          if (response.Company != null && response.Company.LicenseCollection.length > 0) {
            if (response.Company.LicenseCollection.find(x => x.LicenseId === 'sitesafe') != undefined && response.Company.LicenseCollection.find(x => x.LicenseId === 'sitesafe') != null)
              response.Company.LicenseCollection.find(x => x.LicenseId === 'sitesafe').Total = response.Company.LicenseCollection.find(x => x.LicenseId === 'sitesafe').Total * 10;
          }
          
          this.setState({
            statusText: "",
            trialMode : response.Company != null && response.Company.SubscriptionPlanId == "screeningtrial",
            users: response.UserCollection,
            company: response.Company,
            licenseCollection: response.Company.LicenseCollection.length == 0 ? this.state.templateLicenseCollection : response.Company.LicenseCollection,
            userOptions: editOptions,
            viewedUsers: this.setupTableRows(response.UserCollection),
            showButtons: false
          });

          this.setupTableRows(response.UserCollection);

          window.resize();
        }
      }else if (req.status == 403) {
        this.setState({ statusText: "Not authorized to view this page" });
      } else {
        this.setState({ statusText: "Error Retrieving Users" });
      }
    };
    req.onerror = () => {
      this.setState({ statusText: "Error Retrieving Users" });
    };
    req.send(JSON.stringify({ CompanyId: auth.companyId() }));
  }

  setupTableRows = data => {
    let finalActive = 0;
    let currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + (currentDate.getTimezoneOffset() /60));
    let finalTableData = data.map(user => {
      let disabled = user.DeletedBy != null  || user.DeletedDate != null || user.DeletedDateUTCOffset != null;
      let lockOut = user.LockoutEndDateUtc;
      let license = user.HasLicense != undefined && user.HasLicence ? true : false;
      if(!disabled) finalActive += 1;
      return({
        ...user,
        Disabled: <div key={disabled.toString()}>
          <select onChange={(e) => {this.toggleUserStatus(e, user)}} style={{ fontWeight: 600, fontSize: 13, background: "transparent" }} className="form-control border-none" value={lockOut && new Date(lockOut) > currentDate ? "locked" :   disabled.toString()}>
          <option style={{fontWeight: 600, fontSize: 13}} value="false">Active</option>
          <option style={{fontWeight: 600, fontSize: 13}} value="true">Disabled</option>
          <option style={{fontWeight: 600, fontSize: 13}} value="locked">Locked</option>
        </select>
        </div>,
        DisabledValue: disabled.toString(),
        LicenseString:<div style={{color : user.LicenseCollection.length == 0 ? 'grey' : 'green', textTransform : 'uppercase', justifyContent : 'center', maxWidth:100, minWidth: 100}}>
          {user.LicenseIdCollection.length == 0 ? 'Not Licensed' : user.LicenseIdCollection.map((id,index)=>{return (id == "leak" ? "gis" : id ) + (index+1 != user.LicenseIdCollection.length ?  ', ' : ''); })}
          </div>,
        LicenseStringValue : user.LicenseIdCollection.length == 0 ? 'Not Licensed' : user.LicenseIdCollection.map((id,index)=>{return id + (index+1 != user.LicenseIdCollection.length ?  ', ' : ''); }),
        Button: <Button as={Link} to={`/usergroup/users/edit`} className = "buttonAction buttonSwitch alignText" style={{width: 100}} onClick ={() =>{
          if(auth.isSuperViewer()){
            return;
          }
          this.setState({
            selectedInactive : disabled,
            selectedEmail: user.EmailAddress,
            selectedPhoneNumber : user.PhoneNumber,
            selectedFirstName: user.FirstName, 
            selectedTwoFactorEnabled : user.TwoFactorEnabled,
            selectedExternalId : user.ExternalId,
            phoneNumberColor : 'black',
            showButtons: true, 
            selectedLastName : user.LastName,
            showUserEdit: true, 
            invalidInput: false, 
            selectedRole : 
              user.RoleIdCollection.includes(1) ? "Admin" : 
              user.RoleIdCollection.includes(5) ? "Manager" : 
              user.RoleIdCollection.includes(13) ? "SecuredViewer" :
              user.RoleIdCollection.includes(9) ? "Viewer" : "User", 
            selectedRoleCollection : user.RoleIdCollection,
            managerUserId : user.ManagerUserId,
            addingUser: false,
            selectedUserId : user.UserId, 
            disablingUser: false, 
            updatingLicense: false,
            emailColor: "black",
            nameColor:"black",
            resetPassword: false,
            roles: user.RoleIdCollection,
            userLicenseCollection: user.LicenseCollection,
            company: this.state.company,
            displayName: user.DisplayNameProper,
            licensed: license ? false : true,
            }, ()=>{this.showLicense()})}}>
          <AccountCircleTwoToneIcon className="buttonIcon"/>
          <label style = {{margin: 0, cursor: "pointer"}} className = "buttonText withIcon">Edit</label>
        </Button>
      })
    });
    let licensesAssigned = 0;
    let totalLicenses = 0;
    this.state.licenseCollection && this.state.licenseCollection.forEach(license => {
      if(license.LicenseId != 'customforms'){
        licensesAssigned += license.Used;
        totalLicenses += license.Total;
      }
    });
    this.setState({ totalActive: finalActive, licensesTotal: totalLicenses, licensesAssigned });
    return finalTableData;
  }

  lockUnlockUser(Lock, user){
    let body={
      UserId : user.UserId,
      Lock
    }
    fetch(process.env.API_URL + "/api/account/LockOrUnlockUser",{
      method : "POST",
      headers : {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if (r.status == 200){
        r.json().then(responseJson => {
          if(responseJson.responseType == 0){
            let oldUsers = this.state.users;
            oldUsers.forEach(oldUser =>{
              if(oldUser.UserId == responseJson.user.userId){
                oldUser.LockoutEndDateUtc = responseJson.user.lockoutEndDateUtc;
              }
            });
            this.setState({
              users: oldUsers,
              viewedUsers: this.setupTableRows(oldUsers),
              updatingStatus : false, 
              statusText : "",
              alertSeverity : "success", 
              updateStatusText : "User Updated."
            });
          }
        })
      }
    })
    .catch(() =>{
      this.setState({statusText : "", alertSeverity : 'error', updateStatusText : "There was an error updating this user", updatingStatus : false});
    })
  }

  toggleUserStatus = (e, user) => {
    if(auth.isSuperViewer()){
      return;
    }
    e.preventDefault();
    this.setState({ updatingStatus: true });
    if((e.target.value == "false" && (user.LockoutEndDateUtc && (new Date(user.LockoutEndDateUtc) >= new Date()))) || e.target.value == "locked"){
      this.lockUnlockUser(e.target.value == "locked", user);
      return;
    }
    let disable = e.target.value === "true";
    let license = user.HasLicense != undefined && user.HasLicence ? true : false;
    this.setState({
      selectedInactive : e.target.value === "true",
      selectedEmail: user.EmailAddress,
      selectedPhoneNumber : user.PhoneNumber,
      selectedFirstName: user.FirstName, 
      selectedTwoFactorEnabled : user.TwoFactorEnabled,
      selectedExternalId : user.ExternalId,
      selectedLastName : user.LastName,
      selectedRole : 
        user.RoleIdCollection.includes(1) ? "Admin" : 
        user.RoleIdCollection.includes(5) ? "Manager" : 
        user.RoleIdCollection.includes(9) ? "Viewer" : "User", 
      selectedRoleCollection : user.RoleIdCollection,
      addingUser: false,
      selectedUserId : user.UserId, 
      disablingUser: false, 
      updatingLicense: false,
      roles: user.RoleIdCollection,
      userLicenseCollection: user.LicenseCollection,
      company: this.state.company,
      displayName: user.DisplayNameProper,
      licensed: license ? false : true,
    }, () => {
      this.changeStatus(disable, false);
    })
  }

  saveStatus() {
    // INVALID USER REQUIREMENTS
    if (!this.state.disablingUser && !this.validUser()) { return; }

    // RESET PASSWORD
    if (this.state.resetPassword){ this.resetPassword(); return; }

    let req = new XMLHttpRequest();
    req.open( "POST", process.env.API_URL + (this.state.resetPassword ? "/api/account/resettoken" : "/api/account/edit"), true );
    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader("Authorization", "Bearer " + auth.token());

    let DeletedDate = null;
    let DeletedDateUTCOffset = null;
    let DeletedBy = null;
    let date = new Date();

    if (this.state.selectedInactive){
      DeletedDate = date;
      DeletedDateUTCOffset =(-1) *( date.getTimezoneOffset() /60);
      DeletedBy = auth.employeeId();
    }

    var licenseCollectionUpdate = this.state.userLicenseCollection;

    let body = {
      UserId: auth.employeeId(),
      User: {
        EmailAddress: this.state.selectedEmail, 
        PhoneNumber : this.state.selectedPhoneNumber,
        ExternalId : this.state.selectedExternalId,
        FirstName: this.state.selectedFirstName,
        LastName: this.state.selectedLastName,
        Role: this.state.selectedRole,
        ManagerUserId : this.state.managerUserId,
        UserId: this.state.addingUser ? -1 : this.state.selectedUserId,
        Inactive: this.state.selectedInactive,
        Licensed: this.state.licensed,
        CompanyId: auth.companyId(),
        DeletedBy,
        DeletedDate,
        DeletedDateUTCOffset
      },
      DuplicateEmailRequest : this.state.duplicateUser,
      UpdatingLicense: this.state.updatingLicense,
      LicenseCollection: licenseCollectionUpdate,
      CompanyId: auth.companyId(),
    };
    if(this.state.addingUser){
      body.User.DisplayNameProper = this.state.selectedFirstName + ', ' + this.state.selectedLastName;
    }

    req.onload = () => {
      if (req.status == 200) {
        let response = JSON.parse(JSON.parse(req.responseText));
        let oldUsers = this.state.users;
        let newUserOptions = [];
        if (!this.state.addingUser) {
          for (let i = 0; i < oldUsers.length; i++) {
            newUserOptions.push("");
            if (oldUsers[i].UserId == response.User.UserId) {
              if (response.User.Role == "Admin") {
                response.User.RoleIdCollection.push(1);
              }
              oldUsers[i] = response.User;
            }
          }
        } else {
          if (response.User.Role == "Admin") {
            response.User.RoleIdCollection.push(1);
          }
          oldUsers.push(response.User);
          for (let i = 0; i < oldUsers.length; i++) {
            newUserOptions.push("");
          }
        }
        let i = oldUsers.length;
        while (i--) {
          if (oldUsers[i].DeletedBy != null || oldUsers[i].DeletedDate != null || oldUsers[i].DeletedDateUTCOffset != null) {
            oldUsers.push(oldUsers[i]);
            oldUsers.splice(i, 1);
          }
        }

        this.setState({
          statusText: "",
          users: oldUsers,
          viewedUsers: this.setupTableRows(oldUsers),
          searchCriteria: "",
          userOptions: newUserOptions,
          showButtons: false,
          showUserEdit: false,
          disablingUser: false,
          updatingLicense: false,
          addingUser: false,
          emailColor: "black",
          nameColor: "black",
          selectedRoleCollection : [],
          managerUserId : "",
          invalidInput: false,
          resetPassword: false,
          showLicenseEdit: false,
          updateStatusText : this.state.addingUser ? "User will be emailed with a temporary password" : "User updated",
          alertSeverity: "success",
          duplicateUser : false,

          // Resetting userLicenceCollection after save
          userLicenseCollection: [],
          updatingStatus: false
        });
      } else if (req.status == 400) {
        let addEdit = this.state.addingUser ? "creating " : "updating ";
        let responseText = JSON.parse(req.responseText);
        let response = responseText.modelState;
        let failReason = "";
        for (let key in response) {
          failReason = response[key][0];
        }
        this.setState({ statusText: "", updateStatusText : "Error " + addEdit + "user: " + failReason, showButtons: true, alertSeverity: "error", duplicateUser : false });
      } else {
        this.setState({ statusText: "", showButtons: true, updateStatusText : "Error. Try again", alertSeverity: "error", duplicateUser : false });
      }
    };

    req.onerror = () => {
      let addEdit = this.state.addUser ? "creating " : "updating ";
      this.setState({ statusText: "", showButtons: true, updateStatusText : "Error " + addEdit + " user. Try again.", alertSeverity: "error", duplicateUser : false });
    };

    req.send(JSON.stringify(body));
  }

  render() {
    return (
      <div className="col-10 d-flex justify-content-center p-0 m-0" style={{ backgroundColor: "#E9E9EF"}} >
        <div className="container-fluid">
          <Dialog open={this.state.dialogOpen} aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"QR CODE DOWNLOAD REQUEST"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                It could take several minutes to collect your data, you will be emailed a download link when it is read. (est. 2-5 mins)
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className="buttonAction btn btn-primary dehighlighted" onClick={() =>{this.handleQRClose(true)}}>
                <label className="buttonText small" style={{ margin: 0, cursor : 'pointer'}}>Cancel</label>
              </Button>
              <Button className="buttonAction btn btn-primaryy dehighlighted" onClick={() =>{this.handleQRClose(false)}}>
                <label className="buttonText small" style={{margin: 0, cursor :'pointer'}} >Accept</label>
              </Button>
            </DialogActions>
          </Dialog>
          {this.state.statusText == "" ? (
            (!this.state.showUserEdit && !this.state.showLicenseEdit) ? (
              <>
              <UserSearchStrip hasSiteSafeLicense={this.state.licenseCollection && this.state.licenseCollection.find(x => x.LicenseId === 'sitesafe') != null && this.state.licenseCollection.find(x =>x.LicenseId === 'sitesafe').Total > 0} search={this.search} loadingQRCodes = {this.state.loadingQRCodes} downloadQRCodes={this.clickQRDownload} changeSearchCriteria={this.changeSearchCriteria} searchCriteria={this.state.searchCriteria} addUser={this.addUser}/>
              <div id="userResultsDiv" className="userResultsDiv">
                {
                this.state.updatingStatus &&
                <div style={{ position: "absolute", zIndex: 2 }} className="w-100 justify-content-center">
                  <div className="spinnerDivUserPage">
                    <div className="text-center authSpinnerDiv">
                    <div className="authSpinnerDivMessage">Updating Status...</div>
                      <div className="barLoaderPortal">
                        <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading/>
                      </div>
                    </div>
                  </div>
                </div>
                }
                <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={6000} onClose={this.handleClose} 
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                  <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
                    {this.state.updateStatusText}
                  </Alert>
                </Snackbar>

                <div className="row" >
                  <div className="col-1" />
                  <div style={{padding: 0, width: "100%", margin:"30px", marginTop: "20px"}}>
                    <div className="d-flex flex-row align-items-center usergroup-counters" style={{marginLeft:121, paddingTop:12}}>
                      <div className="usergroup-user-table-counter">
                        <div className="mr-2 p-1 licenseCountBlock">
                          {this.state.totalActive} / {this.state.viewedUsers.length}
                        </div>
                        <div className="licenseCount"><label>Active Users</label></div>
                      </div>
                      <div className="usergroup-user-table-counter">
                        <div className="mr-2 p-1 licenseCountBlock" style={{background:'#46a240'}}>
                          {this.state.licensesAssigned} / {this.state.licensesTotal}
                        </div>
                        <div className="licenseCount"><label>Licenses Assigned</label></div>
                      </div>
                    </div>
                    <UsersTable data={this.state.viewedUsers} handleSort={this.handleUserTableSort} />
                  </div>
                </div>
              </div>
              </>
            ) : (
              
              <div id="userEditDiv" style={{overflowY: 'auto'}}>

                {this.state.updateStatusText != "" &&
                <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={6000} onClose={this.handleClose} 
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                  <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
                    {this.state.updateStatusText}
                  </Alert>
                </Snackbar>}

                {this.state.showLicenseEdit && 
                <div className="licenseEditDivMain">
                  <div className=" labelHeaderBar labelHeaderBarTight" style={{marginTop:40, padding:10}}>{this.state.displayName} - License Management</div>
                  <div className="licenseEditDivContent">

                    {this.state.licenseCollection.find(x => x.LicenseId === 'site') && this.state.licenseCollection.find(x => x.LicenseId === 'site').Total > 0 && <div className="licenseDiv">
                      <div>
                        <label className ="licenseLabel buttonText">SITE SURVEY
                        <label className ="labelText labelSmall2">{this.state.licenseCollection.find(x => x.LicenseId === 'site').Used + this.state.licenseCollection.find(x => x.LicenseId === 'site').Edit} of {this.state.licenseCollection.find(x => x.LicenseId === 'site').Total}</label>
                        </label>
                      </div>
                      <div className="licenseToggle">
                        <div className="buttonAction buttonLicense" id="licensesite" onClick={()=>this.updateLicense('site')}/>
                        <label className ="labelText" id="licenseLabelsite">Not Licensed</label>
                      </div>
                    </div>}

                    {/* {this.state.licenseCollection.find(x => x.LicenseId === 'site12') && this.state.licenseCollection.find(x => x.LicenseId === 'site12').Total > 0 && <div className="licenseDiv">
                      <div>
                        <label className ="licenseLabel buttonText">SITE SURVEY
                        <label className ="labelText labelSmall2">{this.state.licenseCollection.find(x => x.LicenseId === 'site12').Used + this.state.licenseCollection.find(x => x.LicenseId === 'site12').Edit} of {this.state.licenseCollection.find(x => x.LicenseId === 'site12').Total}</label>
                        </label>
                      </div>
                      <div className="licenseToggle">
                        <div className="buttonAction buttonLicense" id="licensesite" onClick={()=>this.updateLicense('site')}/>
                        <label className ="labelText" id="licenseLabelsite">Not Licensed</label>
                      </div>
                    </div>} */}

                    {this.state.licenseCollection.find(x => x.LicenseId === 'investigation') && this.state.licenseCollection.find(x => x.LicenseId === 'investigation').Total > 0 && <div className="licenseDiv">
                      <div>
                        <label className ="licenseLabel buttonText">INCIDENT INVESTIGATION
                          <label className ="labelText labelSmall2">{this.state.licenseCollection.find(x => x.LicenseId === 'investigation').Used + this.state.licenseCollection.find(x => x.LicenseId === 'investigation').Edit} of {this.state.licenseCollection.find(x => x.LicenseId === 'investigation').Total}</label>
                        </label>
                      </div>
                      <div className="licenseToggle">
                        <div className="buttonAction buttonLicense" id="licenseinvestigation" onClick={()=>this.updateLicense('investigation')}/>
                        <label className ="labelText" id="licenseLabelinvestigation">Not Licensed</label>
                      </div>
                    </div>}

                    {/* {this.state.licenseCollection.find(x => x.LicenseId === 'investigation22') && this.state.licenseCollection.find(x => x.LicenseId === 'investigation22').Total > 0 && <div className="licenseDiv">
                      <div>
                        <label className ="licenseLabel buttonText">INCIDENT INVESTIGATION
                          <label className ="labelText labelSmall2">{this.state.licenseCollection.find(x => x.LicenseId === 'investigation22').Used + this.state.licenseCollection.find(x => x.LicenseId === 'investigation22').Edit} of {this.state.licenseCollection.find(x => x.LicenseId === 'investigation22').Total}</label>
                        </label>
                      </div>
                      <div className="licenseToggle">
                        <div className="buttonAction buttonLicense" id="licenseinvestigation22" onClick={()=>this.updateLicense('investigation')}/>
                        <label className ="labelText" id="licenseLabelinvestigation">Not Licensed</label>
                      </div>
                    </div>} */}

                    {this.state.licenseCollection.find(x => x.LicenseId === 'gps') && this.state.licenseCollection.find(x => x.LicenseId === 'gps').Total > 0 && <div className="licenseDiv">
                      <div>
                        <label className ="licenseLabel buttonText">GPS SURVEY
                          <label className ="labelText labelSmall2">{this.state.licenseCollection.find(x => x.LicenseId === 'gps').Used + this.state.licenseCollection.find(x => x.LicenseId === 'gps').Edit} of {this.state.licenseCollection.find(x => x.LicenseId === 'gps').Total}</label>
                        </label>
                      </div>
                      <div className="licenseToggle">
                        <div className="buttonAction buttonLicense" id="licensegps" onClick={()=>this.updateLicense('gps')}/>
                        <label className ="labelText" id="licenseLabelgps">Not Licensed</label>
                      </div>
                    </div>}
                    
                    {this.state.licenseCollection.find(x => x.LicenseId === 'leak') && this.state.licenseCollection.find(x => x.LicenseId === 'leak').Total > 0 && <div className="licenseDiv">
                      <div>
                        <label className ="licenseLabel buttonText">GIS SURVEY: 
                          <label className ="labelText labelSmall2">{this.state.licenseCollection.find(x => x.LicenseId === 'leak').Used + this.state.licenseCollection.find(x => x.LicenseId === 'leak').Edit} of {this.state.licenseCollection.find(x => x.LicenseId === 'leak').Total}</label>
                        </label>
                      </div>
                      <div className="licenseToggle">
                        <div className="buttonAction buttonLicense" id="licenseleak" onClick={()=>this.updateLicense('leak')}/>
                        <label className ="labelText" id="licenseLabelleak">Not Licensed</label>
                      </div>
                    </div>}

                    {this.state.licenseCollection.find(x => x.LicenseId == 'sitesafe') && this.state.licenseCollection.find(x => x.LicenseId === 'sitesafe').Total > 0 && <div className="licenseDiv">
                      <div>
                        <label className ="licenseLabel buttonText">SITE SAFE: 
                          <label className ="labelText labelSmall2">{this.state.licenseCollection.find(x => x.LicenseId === 'sitesafe').Used + this.state.licenseCollection.find(x => x.LicenseId === 'sitesafe').Edit} of {this.state.licenseCollection.find(x => x.LicenseId === 'sitesafe').Total}</label>
                        </label> 
                      </div>
                      <div className="licenseToggle">
                        <div className="buttonAction buttonLicense" id="licensesitesafe" onClick={()=>this.updateLicense('sitesafe')}/>
                        <label className ="labelText" id="licenseLabelsitesafe">Not Licensed</label>
                      </div>
                    </div>}

                    {this.state.licenseCollection.find(x => x.LicenseId == 'sitesafescan') && this.state.licenseCollection.find(x => x.LicenseId === 'sitesafescan').Total > 0 && <div className="licenseDiv">
                      <div>
                        <label className ="licenseLabel buttonText">SITE SAFE GUEST SCREENING: 
                          <label className ="labelText labelSmall2">{this.state.licenseCollection.find(x => x.LicenseId === 'sitesafescan').Used + this.state.licenseCollection.find(x => x.LicenseId === 'sitesafescan').Edit} of {this.state.licenseCollection.find(x => x.LicenseId === 'sitesafescan').Total}</label>
                        </label> 
                      </div>
                      <div className="licenseToggle">
                        <div className="buttonAction buttonLicense" id="licensesitesafescan" onClick={()=>this.updateLicense('sitesafescan')}/>
                        <label className ="labelText" id="licenseLabelsitesafescan">Not Licensed</label>
                      </div>
                    </div>}

                    <div className="horizontalLineBuffer"></div>

                    <div className="licenseEditDivButtonBar">
                      <Button onClick={this.updateLicenseSave} style={{marginRight:10}} className ="buttonAction buttonEditUser">
                        <label className ="buttonText">SAVE</label>
                      </Button>
                      
                      <Button onClick={this.cancelSave} className ="buttonAction buttonEditUser">
                        <label className ="buttonText">CANCEL</label>
                      </Button>
                    </div>
                  </div>
                </div>
                }

                {!this.state.showLicenseEdit && 
                <AddEditUser
                  FirstName={this.state.selectedFirstName}
                  twoFactorEnabled={this.state.selectedTwoFactorEnabled}
                  updateTwoFactor={this.editTwoFactor}
                  EditFirstName={this.editFirstName}
                  PhoneColor={this.state.phoneNumberColor}
                  LastName={this.state.selectedLastName}
                  DisplayName={this.state.selectedFirstName + ', ' + this.state.selectedLastName}
                  EditLastName={this.editLastName}
                  NameColor={this.state.nameColor}
                  EmailColor={this.state.emailColor}
                  Email={this.state.selectedEmail}
                  EditEmail={this.editEmail}
                  PhoneNumber={this.state.selectedPhoneNumber}
                  EditPhoneNumber={this.editPhoneNumber}
                  InvalidInput={this.state.invalidInput}
                  Role={this.state.selectedRole}
                  EditRole={this.editRole}
                  ManagerUserId={this.state.managerUserId}
                  UsersList={this.state.users}
                  EditManagerUserId={this.editManagerUserId}
                  AddingUser={this.state.addingUser}
                  updateStatus = {this.state.updateStatusText}
                  licenseStatus = {this.state.lincenseStatusText}
                  disableUser = {this.changeStatus}
                  saveUser = {this.saveUser}
                  cancelSave = {this.cancelSave}
                  resetPassword = {this.resetPassword}
                  selectedInactive = {this.state.selectedInactive}
                  licensed = {this.state.licensed}
                  roleCollection={this.state.selectedRoleCollection}
                  editRoleCollection={this.editRoleCollection}
                  userId={this.state.selectedUserId}
                  updateStatusText={this.state.updateStatusText}
                  duplicateUser={this.duplicateUser}
                  displayName={this.state.displayName}
                  licenseCollection={this.state.licenseCollection}
                  updateLicense={this.updateLicense.bind(this)}
                  userLicenseCollection={this.state.userLicenseCollection}
                  trialMode={this.state.trialMode}
                  EditExternalId={this.editExternalid}
                  ExternalId={this.state.selectedExternalId}
                />}

              </div>
            )
          ) : (
            <div className="row">
              <div className="spinnerDivUserPage">
                <div className="text-center authSpinnerDiv">
                  <div className="authSpinnerDivMessage">{this.state.statusText}</div>
                  <div className="barLoaderPortal">
                    <BarLoader sizeUnit={"px"} size={150} color={"#095399"} 
                      loading={ this.state.statusText == "" || this.state.statusText == 'Not authorized to view this page' || this.state.statusText == 'Error Retrieving Users' ? false : this.state.statusText == "No Data Returned" ? false : true } />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

UsersPage.propTypes = {
  forceRefresh: PropTypes.func,
  match : PropTypes.object,
  history : PropTypes.object,
  prevState : PropTypes.object
};

export default UsersPage;