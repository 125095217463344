import React from "react";
import PropTypes from "prop-types";
import { RingLoader } from "react-spinners";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle"
import Auth from "../common/Auth";

class ArcGisAuthPage extends React.Component {
  constructor() {
    super();
    this.state = {
      authSuccess: false,
      authError: false
    }
  }

  componentDidMount() {
    //const search = useLocation().search;
    const code = new URLSearchParams(this.props.location.search).get("code");

    fetch(process.env.API_URL + "/api/arcgis/authorize/?code=" + code + "&id=" + Auth.userId(), {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        //Authorization : "Bearer " + auth.token()
      }
    })
      .then(r => {
        if (r.status == 200) {
          r.json().then(json => {
            if (json.access_token == null) {
              this.setState({ authError: true, authSuccess: false });
            }
            else {
              setTimeout(()=>{
                window.close();
              }, 1500);
              this.authSuccess(json);
            }
          });
        }
      })
      .catch(error => {
        this.setState({ authError: true, authSuccess: false });
        console.log(error.message);
      })
  }

  authSuccess(res) {
    let err = null;
    window.opener.onSuccess({ err, res });
    this.setState({ authSuccess: true, authError: false })
  }

  render() {
    return (
      <div style={{ width: '100%', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
        {!this.state.authSuccess && !this.state.authError ?
          <div className='arcGisConnectingIconContainer'>
            <RingLoader color={"black"} loading={true} size={40} />
            <label style={{ marginTop: '15px' }}>Connecting to ArcGIS Online</label>
          </div>
          : this.state.authSuccess ?
              <div className='arcGisConnectingIconContainer'>
                <CheckCircle
                  style={{
                    fontSize: 36,
                    color: "green"
                  }}
                />
                <label style={{ marginTop: '15px', color: 'black' }}>Connected to ArcGIS Online</label>
              </div>
            : this.state.authError ?
              <div className='arcGisConnectingIconContainer'>
                <Cancel
                  style={{
                    fontSize: 36,
                    color: "red"
                  }}
                />
                <label style={{ marginTop: '15px' }}>Error connecting to ArcGIS Online</label>
                <label>Please close this window and try again</label>
              </div>
              : null
        }

      </div>
    );
  } //render
} //class

ArcGisAuthPage.propTypes = {
  location: PropTypes.object
};

export default ArcGisAuthPage;
