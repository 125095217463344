import React from 'react';
import auth from '../common/Auth';
import SearchStrip from './SearchStrip';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import  Pagination  from '@material-ui/lab/Pagination';
import { BarLoader } from 'react-spinners';
import WebMapViewSurveyInvestigation from './WebMapViewSurveyInvestigation';
import DataGroupHelpers from '../../Utility/DataGroupHelpers';
import { get, set } from 'idb-keyval';
import CreateTaskButton from './CreateTaskButton';
//import BasemapToggleButton from './BasemapToggleButton';
import OrgUtility from '../../Utility/Utility';

class MapPageSurveyInvestigation extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      resultSet:  [],
      updateStatusText : '',
      alertSeverity : 'success',
      statusText : '', 
      includePhotos : false,
      dateRangeDays : 1,
      updateMapExtent: true,
      dataGroups : [],
      openCount : 0,
      closedCount : 0,
      entityCollections : [],
      userAssignedList : [],
      multimediaAssets : [],
      ticketAssignmentAreas : [],
      formDataAssignmentAreas : [],
      filterTicketAssignmentAreasValue : [],
      filterFeatureTicketAssignmentAreasValue : ['FirstLoad'],
      filterUserValue : -1,
      filterStatusValue : -1,
      filterDueDateValue : -1,
      filterTicketTypeValue : -1,
      showAssignedList : false,
      creatingNewTask : false,
      useModifiedDate : false,
      hasTicketData : false,
      CompanyArcGisLayerFeatures : [],
      formProjects :  [{ProjectLabel : "No Project", showData : true}],
      unfilteredResultSet : [],
      tagFormSearchOptions: null,
      loadingTagFormSearchOptions: false,
      showDataList : false,
      dataInExtent : [],
      extentDataPage : 1,
      userAssignmentAreas : [],
      ticketGroups : [],
      ticketAssignmentAreaGroups : []
    }
    this.handleDataListPageChange = this.handleDataListPageChange.bind(this);
    this.showDataList = this.showDataList.bind(this);
    this.updateDataInExtent = this.updateDataInExtent.bind(this);
    this.downloadRequest = this.downloadRequest.bind(this);
    this.disableType = this.disableType.bind(this);
    this.searchRequest = this.searchRequest.bind(this);
    this.setCounts = this.setCounts.bind(this);
    this.doFiltering = this.doFiltering.bind(this);
    this.toggleDataGroup = this.toggleDataGroup.bind(this);
    this.toggleProject = this.toggleProject.bind(this);
    this.expandGroup = this.expandGroup.bind(this);
    this.getMultimediaAssets = this.getMultimediaAssets.bind(this);
    this.showProgressBar = this.showProgressBar.bind(this);
    this.setAlert = this.setAlert.bind(this);
    this.updateResultsFromAssignment = this.updateResultsFromAssignment.bind(this);
    this.updateResultsFromTaskUnassign = this.updateResultsFromTaskUnassign.bind(this);
    this.filterFromUserList = this.filterFromUserList.bind(this);
    this.map = React.createRef();
    this.strip = React.createRef();
    this.filterUpdate = this.filterUpdate.bind(this);
    this.updateAssignedCount = this.updateAssignedCount.bind(this);
    this.updateResultsFromUserAssignment = this.updateResultsFromUserAssignment.bind(this);
    this.updateResultsFromAssignmentArea = this.updateResultsFromAssignmentArea.bind(this);
    this.updateResultsFromShapefileUpload = this.updateResultsFromShapefileUpload.bind(this);
    this.setCreatingNewTask = this.setCreatingNewTask.bind(this);
    this.statusTextUpdate = this.statusTextUpdate.bind(this);
    this.getCompanyLayers = this.getCompanyLayers.bind(this);
    this.updateFromArchive = this.updateFromArchive.bind(this);
    this.addGeoDataToFormData = this.addGeoDataToFormData.bind(this);
    this.updateIncludePhotos = this.updateIncludePhotos.bind(this);
    this.updateFormFromEdit = this.updateFormFromEdit.bind(this);
    this.setSurveyCount = this.setSurveyCount.bind(this);
    this.setIncidentCount = this.setIncidentCount.bind(this);
    this.getTicketGroups = this.getTicketGroups.bind(this);
    this.expandTicketGroup = this.expandTicketGroup.bind(this);
    this.getUserAssignmentAreas = this.getUserAssignmentAreas.bind(this);
  }

  componentDidUpdate(){
    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  getUserAssignmentAreas(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getUserAssignmentAreas",{
      method: 'POST',
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({userAssignmentAreas: json.UserAssignmentAreas});
          }
        });
      }
    })
    .catch(error =>{
      console.log(error.message);
    });

  }

  getTicketGroups(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getTicketGroups",{
      method: 'POST',
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.Groups){
              let ticketGroups = [];
              ticketGroups = ticketGroups.concat(json.Groups);
              let idMapping = ticketGroups.reduce((acc, el, i) =>{
                acc[el.TicketGroupId] = i;
                return acc;
              }, {});
              ticketGroups.forEach(group =>{
                group.expanded = true;
                if(!group.ParentId){
                  group.isRoot = true;
                  return;
                }
                let parent = ticketGroups[idMapping[group.ParentId]];
                if(parent)parent.children = [...(parent.children || []), group];
              });
              this.setState({ticketGroups,ticketAssignmentAreaGroups : (json.TicketAssignmentAreaGroups || [])});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error.message);
    });
  }

  expandTicketGroup(group){
    let ticketGroups = this.state.ticketGroups;
    let foundGroup  = ticketGroups.find(x => x.TicketGroupId == group.TicketGroupId);
    foundGroup.expanded = !group.expanded;
    this.setState({ticketGroups});
  }

  hasLicense(licenseId){
    const licensesString = auth.licenses();
    if(licensesString){
      const licenseArray = licensesString.split(',');
      if(licenseArray.indexOf(licenseId) > -1)
        return true;
      return false;
    }else{
      return false;
    }
  }

  updateDataInExtent(data){
    this.setState({dataInExtent : data, extentDataPage : 1});
  }

  showDataList(){
    this.setState({showDataList : !this.state.showDataList});
  }

  handleDataListPageChange(event,page){
    this.setState({extentDataPage : page});
  }

  getTagFormSearchOptions(){
    let body = {
      CompanyId : auth.companyId(),
      FormTypeId : 5 //Tag
    }
    this.setState({loadingTagFormSearchOptions:true});
    fetch(process.env.API_URL +"/api/account/formsInfo",{
      method: "POST",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    }).then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let tagFormSearchOptions = [];
          let json = JSON.parse(responseJson);
          if (json.ResponseType == 0){
            if (json.FormInfoCollection) {
              json.FormInfoCollection.forEach(form =>{  
                form.filterEnabled = false;
                tagFormSearchOptions.push(form);
              });
              tagFormSearchOptions.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
              this.setState({tagFormSearchOptions, loadingTagFormSearchOptions: false});
            }
          }
        });
      } else{
        console.warn("Non 200 response on TagFormSearchOptions fetch. Status: " + r.status);
        this.setState({loadingTagFormSearchOptions:false});
      }
    }).catch(error =>{
      console.log(error.message);
      this.setState({loadingTagFormSearchOptions:false});
    })
  }
  
  getCompanyLayers(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      Survey : true,
      Web : true,
      Analysis : true,
      TicketArea : true
    }
    fetch(process.env.API_URL + "/api/account/getCompanyArcGisLayers",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status ==200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.CompanyArcGisLayers){
              this.setState({CompanyArcGisLayers : json.CompanyArcGisLayers, ARCGISClientSecret : json.ARCGISClientSecret,  ARCGISClientId : json.ARCGISClientId, EnterpriseConnection : json.EnterpriseConnection});
            }
            else{
              this.setState({CompanyArcGisLayers : []});
            }
            if(json.FeatureCollection){
              this.setState({CompanyArcGisLayerFeatures : json.FeatureCollection});
            }
          }
          else{
            this.setState({CompanyArcGisLayers : []});
          }
        });
      }
      else{
        this.setState({CompanyArcGisLayers : []});
      }
    })
    .catch(error =>{
      console.log(error);
      this.setState({CompanyArcGisLayers : []});
    });
  }

  updateIncludePhotos(includePhotos){
    this.setState({includePhotos});
  }

  componentDidMount(){
    this.getCompanyLayers();
    this.getTagFormSearchOptions();
    this.getTicketGroups();
    this.getUserAssignmentAreas();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let fromDetails = params.get('fromDetails');
    let detailsType = params.get('detailsType');
    get('resultSet_Survey').then((results)=>{
      if(results){
        let entityCollections = localStorage.getItem("entityCollections");
        let multimediaAssets = localStorage.getItem("multimediaAssetsSurvey");
        let ticketAssignmentAreaString = localStorage.getItem("ticketAssignmentAreas");
        let hasTicketData = false;
        let ticketAssignmentAreas;
        let filterTicketAssignmentAreasValue;
        try{
          ticketAssignmentAreas = JSON.parse(ticketAssignmentAreaString);
          filterTicketAssignmentAreasValue = JSON.parse(ticketAssignmentAreaString);
        }
        catch(e){
          ticketAssignmentAreas = [];
          filterTicketAssignmentAreasValue = [];
        }
        let formDataAssignmentAreaString = localStorage.getItem("formDataAssignmentAreas");
        let formDataAssignmentAreas;
        try{
          formDataAssignmentAreas = JSON.parse(formDataAssignmentAreaString);
        }
        catch(e){
          formDataAssignmentAreas = [];
        }
        let formProjects = [{ProjectLabel : "No Project", showData : true}];
        JSON.parse(results).forEach(result =>{
          if(result.FormData){
            if(result.FormData.FormTypeId == 4) hasTicketData = true;
            let selectedProject = result.FormData.Fields && result.FormData.Fields.length > 0 && result.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? result.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
            if(selectedProject && selectedProject != "" && !formProjects.find(x => x.ProjectLabel == selectedProject)){
              formProjects.push({ProjectLabel : selectedProject, showData : true});
            }
          }
        });
        this.setState({resultSet: JSON.parse(results),filterTicketAssignmentAreasValue,hasTicketData, ticketAssignmentAreas, formDataAssignmentAreas, unfilteredResultSet : JSON.parse(results), entityCollections : ((entityCollections && entityCollections != '') ? JSON.parse(entityCollections) : []),formProjects, multimediaAssets : ((multimediaAssets && multimediaAssets != '') ? JSON.parse(multimediaAssets) : [])});
      }
      if(fromDetails == "true"){
        let mapSurvey = (detailsType == "survey" ? localStorage.getItem("mapSurvey") : detailsType == "investigation" ? localStorage.getItem("mapInvestigation") : localStorage.getItem("mapForm"));
        if(mapSurvey && mapSurvey != ""){
          let surv = JSON.parse(mapSurvey);
          let resultSet = this.state.resultSet;
          let pushSurvey = false;
          if(!resultSet || resultSet.length == 0){
            pushSurvey = true;
            resultSet = [];
          }
          else{
            if(detailsType == "survey" && !resultSet.find(x => x.EntityTypeId == 0 &&  x.SiteSurveyId == surv.SiteSurveyId)){
              pushSurvey = true;
            }
            if(detailsType == "investigation" && !resultSet.find(x => x.EntityTypeId == 1 && x.InvestigationId == surv.InvestigationId)){
              pushSurvey = true;
            }
            if(detailsType == "form" && !resultSet.find(x => x.FormData && x.FormData.FormDataId == surv.FormData.FormDataId)){
              surv.EntityTypeId = surv.FormData.FormId;
              pushSurvey = true;
            }
          }
          if(pushSurvey){
            resultSet.push(surv);
            this.setState({resultSet, unfilteredResultSet : resultSet});
          }
          this.setState({detailsSurveyLat : detailsType =="form" ? surv.FormData.Latitude : surv.Latitude, detailsSurveyLon : detailsType == "form" ? surv.FormData.Longitude : surv.Longitude});
        }
      }
          // remove default search
      if((!this.state.resultSet || this.state.resultSet.length == 0) && !this.checkSuper()){
        //this.searchRequest(this.props);
      }
      else{
        this.setCounts();
        setTimeout(() =>{this.setState({refresh : true})},1000);
        this.updateAssignedCount(true);
      }
      const mapZoom = sessionStorage.getItem("surveyMapZoom");
      const mapExtent = sessionStorage.getItem("surveyMapExtent");

      //default date settings
      let today = new Date();
      today.setDate(today.getDate());
      let d = new Date();
      d.setDate(d.getDate() -31);
      this.setState({toDate: today, fromDate: d, zoomToExtent: mapZoom == null || mapExtent == null || mapZoom == "null" || mapExtent == "null" || mapZoom == "undefined" || mapExtent == "undefined"});
    });
    DataGroupHelpers.getDataGroups(auth.employeeId(), auth.companyId(),auth.token()).then(dataGroups =>{
      this.setState({dataGroups});
    });
  }

  checkSuper(){
    let isSuper = false;
    let _SuperAdminRoleId = 0;
    let _SuperViewerRoleId = 11;
    var roles = localStorage.getItem("roles");
    if(roles != null) { roles.split(',').forEach(element => { if (element == _SuperAdminRoleId || element == _SuperViewerRoleId) { isSuper = true;}});}
    return isSuper;
  }

  showProgressBar(text){
    this.setState({statusText : text});
  }

  setAlert(alertSeverity, updateStatusText){
    this.setState({alertSeverity, updateStatusText});
  }

  updateResultsFromUserAssignment(userAssignment){
    let results = this.state.resultSet;
    if(userAssignment.DeletedDate){
      let assignmentIndex = results.findIndex(x => x.Type == "UserAssignment" && x.UserAssignmentId == userAssignment.UserAssignmentId);
      if(assignmentIndex != -1){
        results.splice(assignmentIndex, 1);
      }
    }
    else{
      //Need to remove current assignment for user if it exists because it was erased when new one was made. 
      let assignmentIndex = results.findIndex(x => x.Type == "UserAssignment" && x.AssignedUserId == userAssignment.AssignedUserId);
      if(assignmentIndex != -1){
        results.splice(assignmentIndex, 1);
      }
      results.push(userAssignment);
    }
    this.setState({resultSet : results}, () =>{this.updateAssignedCount(false)});
    try { set('resultSet_Survey', JSON.stringify(results));}
    catch(e){console.log('error caching resultSet_Survey object')}
  }

  updateResultsFromShapefileUpload(response){
    let results = this.state.resultSet;
    let ticketAssignmentAreas = this.state.ticketAssignmentAreas;
    response.AssignmentAreaCollection.forEach(area =>{
      results.push(area);
      if(area.GroupId){
        if(ticketAssignmentAreas.find(x => x.GroupId == area.GroupId)){
          area.HideFromDropDown = true;
        }
      }
      ticketAssignmentAreas.push(area);
    });
    //Map will not update and draw assignment areas without stringify and parse. 
    this.setState({resultSet : JSON.parse(JSON.stringify(results)),ticketAssignmentAreas}, () =>{this.updateAssignedCount(false)});
    localStorage.setItem("ticketAssignmentAreas", JSON.stringify(ticketAssignmentAreas));
    try { set('resultSet_Survey', JSON.stringify(results));}
    catch(e){console.log('error caching resultSet_Survey object')}
  }

  updateResultsFromAssignmentArea(assignmentArea){
    let results = this.state.resultSet;
    let ticketAssignmentAreas = this.state.ticketAssignmentAreas;
    let assignmentIndex = results.findIndex(x => x.Type == "AssignmentArea" && x.AssignmentAreaId == assignmentArea.AssignmentAreaId);
    if(assignmentIndex != -1){
      results.splice(assignmentIndex,1);
    }
    let ticketAssignmentIndex = ticketAssignmentAreas.findIndex(x => x.AssignmentAreaId == assignmentArea.AssignmentAreaId);
    if(ticketAssignmentIndex != -1){
      ticketAssignmentAreas.splice(ticketAssignmentIndex, 1);
    }
    if(!assignmentArea.DeletedDate){
      results.push(assignmentArea);
      if(assignmentArea.GroupId){
        if(ticketAssignmentAreas.find(x => x.GroupId == assignmentArea.GroupId && x.Label == assignmentArea.Label)){
          assignmentArea.HideFromDropDown = true;
        }
      }
      ticketAssignmentAreas.push(assignmentArea);
    }
    this.setState({resultSet : results,ticketAssignmentAreas}, () =>{this.updateAssignedCount(false)});
    localStorage.setItem("ticketAssignmentAreas", JSON.stringify(ticketAssignmentAreas));
    try { set('resultSet_Survey', JSON.stringify(results));}
    catch(e){console.log('error caching resultSet_Survey object')}
  }

  addGeoDataToFormData(formDataId, geometryData){
    let results = this.state.resultSet;
    results.forEach((element) =>{
      if(element.FormData && element.FormData.FormDataId == formDataId){
        element.FormData.GeometryData = geometryData;
      }
    });
    this.setState({resultSet : results});
  }

  updateFromArchive(entity, isInvestigation, isForm){
    let results = this.state.resultSet;
    let deletedIndex = -1;
    results.forEach((element,index) =>{
      if(isForm && element.FormData && element.FormData.FormDataId == entity.FormData.FormDataId){
        deletedIndex = index;
      }
      else if(!isForm && isInvestigation && element.InvestigationId == entity.InvestigationId){
        deletedIndex = index;
      }
      else if(isForm && !isInvestigation && element.SiteSurveyId == entity.SiteSurveyId){
        deletedIndex = index;
      }
    });
    if(deletedIndex != -1){
      results.splice(deletedIndex,1);
    }
    this.setState({resultSet : results});
    // cache data 
    try { set('resultSet_Survey', JSON.stringify(results));}
    catch(e){console.log('error caching resultSet_Survey object')}
  }

  updateResultsFromAssignment(formTask){
    let results = this.state.resultSet;
    results.forEach(element =>{
      if(element.FormData && element.FormData.FormDataId == formTask.FormDataId){
        if(!formTask.DeletedDate){
          element.FormData.FormTask = formTask;
        }
        else{
          element.FormData.FormTask = null;
        }
      }
      else if(formTask.SiteSurveyId && element.Type == "Survey" && element.SiteSurveyId && element.SiteSurveyId == formTask.SiteSurveyId){
        if(!formTask.DeletedDate){
          element.FormTask = formTask;
        }
        else{
          element.FormTask = null;
        }
      }
      else if(element.Type == "Investigation" && formTask.InvestigationId && element.InvestigationId == formTask.InvestigationId){
        if(!formTask.DeletedDate){
          element.FormTask = formTask;
        }
        else{
          element.FormTask = null;
        }
      }
    });
    this.setState({resultSet : results}, () =>{this.updateAssignedCount(false);});
    // cache data 
    try { set('resultSet_Survey', JSON.stringify(results));}
    catch(e){console.log('error caching resultSet_Survey object')}
  }

  addNewEntityToResults(formData){
    let newEntity = {
      EntityTypeId : formData.FormId,
      FormData : formData,
      Latitude : formData.Latitude,
      Longitude : formData.Longitude,
      ModifiedByDisplayName : formData.ModifiedByDisplayName,
      ModifiedDate : formData.ModifiedDate,
      Type : formData.FormTypeId == 0 ? "Survey" : "Investigation"
    }
    let results = this.state.resultSet;
    results.push(newEntity);
    this.setState({resultSet : results}, () =>{this.updateAssignedCount(false)});
    // cache data 
    try { set('resultSet_Survey', JSON.stringify(results));}
    catch(e){console.log('error caching resultSet_Survey object')}
    return newEntity;
  }

  setCreatingNewTask(creatingNewTask){
    this.setState({creatingNewTask});
  }

  updateResultsFromTaskUnassign(formTaskList){
    let results = this.state.resultSet;
    formTaskList.forEach((formTask) =>{
      results.forEach(element =>{
        if(element.FormData && element.FormData.FormDataId == formTask.FormDataId ){
          if(formTask.DeletedDate)element.FormData.FormTask = null;
          else{ 
            if(formTask.AssignedUserId == null) formTask.AssignedUserDisplayName = null;
            element.FormData.FormTask = formTask;
          }
        }
        else if((element.Type == "Survey" && formTask.SiteSurveyId == element.SiteSurveyId) || (element.Type == "Investigation" && element.InvestigationId == formTask.InvestigationId)){
          element.FormTask = null;
        }
      });
    });
    this.setState({resultSet : results}, () =>{this.updateAssignedCount(false)});
    // cache data 
    try { set('resultSet_Survey', JSON.stringify(results));}
    catch(e){console.log('error caching resultSet_Survey object')}
  }

  updateFormFromEdit(survey){
    let results = this.state.resultSet;
    let formData = survey.FormData;
    formData.FormTask = survey.FormTask;
    results.forEach(element =>{
      if(element.FormData && element.FormData.FormDataId == formData.FormDataId){
        element.FormData = formData;
      }
    });
    this.setState({resultSet : results}, () =>{this.updateAssignedCount(false)});
    // cache data 
    try { set('resultSet_Survey', JSON.stringify(results));}
    catch(e){console.log('error caching resultSet_Survey object')}
  }

  setCounts(){
    if(this.state.resultSet && this.state.resultSet.length > 0){
      let surveyCount = 0;
      let incidentCount = 0;
      this.state.resultSet.forEach((result) =>{
        if(result.Type == "Survey"){
          surveyCount++;
        }
        else if(result.Type == "Investigation"){
          incidentCount++;
        }
        else if(result.Type != "Survey User" && result.Type != "UserAssignment" && result.Type !="AssignmentArea"){console.log(result.Type)}
      });
      this.setState({surveyCount,incidentCount});
    }
  }


  expandGroup(group){
    let dataGroups = DataGroupHelpers.expandGroup(group.expanded,group.DataGroupId,this.state.dataGroups);
    this.setState({dataGroups});
  }

  toggleDataGroup(id, checked){
    let dataGroups = DataGroupHelpers.toggleDataGroup(id,checked,this.state.dataGroups);
    this.setState({dataGroups}, () =>{this.doFiltering()});
  }

  toggleProject(name,checked){
    let formProjects = this.state.formProjects;
    formProjects.find(x => x.ProjectLabel == name).showData = checked;
    this.setState({formProjects},() =>{this.doFiltering()});
  }

  setSurveyCount(surveyCount){
    if(this.state.surveyCount != surveyCount){
      this.setState({surveyCount});
    }
  }

  setIncidentCount(incidentCount){
    if(this.state.incidentCount != incidentCount){
      this.setState({incidentCount});
    }
  }

  doFiltering(){

    let dataGroups = this.state.dataGroups;
    let resultSet = [];
    let excludeDataGroupsList = dataGroups.filter(x => !x.showData);
    let excludeProjectList = this.state.formProjects.filter(x => !x.showData);
    let entityCollections = this.state.entityCollections;
    let unfilteredResultSet = this.state.unfilteredResultSet;
    
    unfilteredResultSet.forEach(result => {
      let push = true;
      let type = result.FormData ? 93 : (result.Type == "Survey" ? 0 : 11);
      if(excludeProjectList && excludeProjectList.length > 0 && result.FormData){
        let selectedProject = result.FormData.Fields && result.FormData.Fields.length > 0 && result.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? result.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
        if(excludeProjectList.find(x => x.ProjectLabel == "No Project") && (!selectedProject || selectedProject == "")){
          push = false;
        }
        if(selectedProject && selectedProject != "" && excludeProjectList.find(x => x.ProjectLabel == selectedProject)){
          push = false;
        }
      }
      if(excludeDataGroupsList && excludeDataGroupsList.length > 0){
        let collectionRecords = entityCollections.filter(x => x.EntityId == result.Id && x.EntityTypeId == type);
        if(collectionRecords && collectionRecords.length > 0){
          collectionRecords.forEach(collection =>{
            if(excludeDataGroupsList.findIndex(x => x.DataGroupId == collection.DataGroupId) > -1){
              push = false;
            }
          });
        }
      }
      if(push){
        resultSet.push(result);
      }
    });

    this.setState({resultSet, updateMapExtent: false});
  }

  getMultimediaAssets(EntityList){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      EntityList
    }
    fetch(process.env.API_URL + "/api/entityCollectionMultimedia",{
      method: "POST",
      headers: {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(bodyJson =>{
          let response = JSON.parse(bodyJson);
          response.MultimediaAssets = response.MultimediaAssets.filter(x => x.MediaTypeId != 1);
          response.MultimediaAssets.forEach(asset =>{
            asset.AttachedEntityTypeId = asset.EntityTypeId;
            if(asset.EntityTypeId == 93){
              let entity = this.state.resultSet.find(x => x.FormData != null && x.FormData.FormDataId == asset.EntityId);
              if(entity){
                asset.FormData = entity.FormData;
                asset.EntityTypeId = entity.FormData.FormId;
              }
            }
            //asset.EntityTypeId = '-1';
          });
          this.setState({multimediaAssets : response.MultimediaAssets, refresh : true});
          localStorage.setItem("multimediaAssetsSurvey", JSON.stringify(response.MultimediaAssets));
        });
      }
    });
  }

  calcCrow(lat1, lon1, lat2, lon2) 
  {
    var R = 6371; // km
    var dLat = this.toRad(lat2-lat1);
    var dLon = this.toRad(lon2-lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    return d;
  }

  toRad(Value) 
  {
    return Value * Math.PI / 180;
  }

  searchRequest(props){
    
    console.log('searchRequest' , props);
    this.setState({statusText : "refreshing Data...", resultSet : null, zoomToExtent : false, updateMapExtent : false, refresh : true});

    let today = new Date(); today.setDate(today.getDate());
    let d = new Date(); d.setDate(d.getDate() -31);
    let withAttachedTagFormIds = props.tagFilterOptions ? props.tagFilterOptions.filter(x=>x.filterEnabled).map(x=>x.FormId) : [];
    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      SearchParam: props.searchCriteriaValue == undefined ? "" : props.searchCriteriaValue,
      DateFrom : props.dateFrom == undefined ? d.toLocaleDateString() : props.dateFrom,
      DateTo : props.dateTo == undefined ? today.toLocaleDateString() : props.dateTo,
      WebSearch : true,
      ReturnSurveyData : true,
      ReturnIncidentData : true,
      Offset : - new Date().getTimezoneOffset()/60,
      DashboardSearch : true,
      UseModifiedDate : this.state.useModifiedDate,
      SiteSurveyMapSearch : true,
      WithAttachedTagFormIds: withAttachedTagFormIds
    };
    fetch(process.env.API_URL + "/api/search/",{
      method: "POST",
      headers: {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r =>{ 
      if(r.status == 200){
        r.json().then(bodyJson =>{
          if(bodyJson.length > 0){
            if(bodyJson.length > 0){
              var colorSurveys = '#16548e';
              var colorInvestigations = '#8a4a4a';
              var colorE0C = '#176782';
              var colorE1C = '#5e2828';
              this.map.current.legendStyle = {
                'Surveys':colorSurveys,
                'Investigations': colorInvestigations,
                0 : colorSurveys,
                1:  colorInvestigations,
                '0C' : colorE0C,
                '1C' : colorE1C
              }

              let response = JSON.parse(bodyJson);
              if(response.EntityCollections){
                this.setState({entityCollections : response.EntityCollections});
                try{localStorage.setItem("entityCollections", JSON.stringify(response.EntityCollections));}
                catch(e){console.log(e);}
              }

              let hasTicketData = false;
              let formProjects = [{ProjectLabel : "No Project", showData : true}];
              let entityList = [];
              var surveySet = [];
              let userAssignedList = [];
              let openCount = 0;
              let closedCount = 0;
              let assignedCount = 0;
              let surveyCount = response.SiteSurveyCollection.length;
              let incidentCount = response.InvestigationCollection.length;
              var filterUser = document.getElementById("filterUser");
              
              if(filterUser != null){
                var i, L = filterUser.options.length -1;
                for(i =L; i >=1; i--){ filterUser.remove(i); }
              }
              
              if(response.UserAssignmentCollection){
                response.UserAssignmentCollection.forEach((element) =>{
                  element.Type = "Survey User";
                  element.assignedCount = 0;
                  element.assignedCountClosed = 0;
                  userAssignedList.push(element);
                  surveySet.push(element)
                })
              }

              if(response.UserAssignmentPolygonsCollection){
                response.UserAssignmentPolygonsCollection.forEach(element =>{
                  surveySet.push(element);
                })
              }
              
              if(response.AssignmentAreaCollection){
                let areaIdsToCloseToLabel = [];
                response.AssignmentAreaCollection.forEach(element =>{
                  if(element.GroupId && !areaIdsToCloseToLabel.includes(element.AssignmentAreaId)){
                    element.AddLabel = true;
                    let group = response.AssignmentAreaCollection.filter(x => x.GroupId == element.GroupId);
                    var poly = JSON.parse(element.AssignmentPolygon);
                    group.forEach(area => {
                      if(!areaIdsToCloseToLabel.includes(area.AssignmentAreaId)){
                        let areaPoly = JSON.parse(area.AssignmentPolygon);
                        let distance = this.calcCrow(areaPoly[0][0],areaPoly[0][1],poly[0][0],poly[0][1]);
                        if(distance < 10){ //DISTANCE IN KM
                          areaIdsToCloseToLabel.push(area.AssignmentAreaId);
                        }
                      }
                    });
                  }
                  else if(!element.GroupId){
                    element.AddLabel = true;
                  }
                  surveySet.push(element);
                });
              }
              
              response.SiteSurveyCollection.forEach((survey) =>{
                if(survey.FormData){
                  if(survey.FormData.FormTypeId == 4) hasTicketData = true;
                  let task = response.FormTaskCollection.find(x => x.FormDataId == survey.FormData.FormDataId);
                  if(task){
                    survey.FormData.FormTask = task;
                  }
                  survey.EntityTypeId = survey.FormData.FormId;
                  let selectedProject = survey.FormData.Fields && survey.FormData.Fields.length > 0 && survey.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? survey.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
                  if(selectedProject && selectedProject != "" && !formProjects.find(x => x.ProjectLabel == selectedProject)){
                    formProjects.push({ProjectLabel : selectedProject, showData : true});
                  }
                  entityList.push({EntityId : survey.FormData.FormDataId, EntityTypeId : 93});
                }
                else{
                  let task = response.FormTaskCollection.find(x => x.SiteSurveyId == survey.SiteSurveyId);
                  if(task){
                    survey.FormTask = task;
                  }
                  entityList.push({EntityId : survey.SiteSurveyId, EntityTypeId : 0});
                }
              });
              
              response.InvestigationCollection.forEach((invest) =>{
                if(invest.FormData){
                  let task = response.FormTaskCollection.find(x => x.FormDataId == invest.FormData.FormDataId);
                  if(task){
                    invest.FormData.FormTask = task;
                  }
                  invest.EntityTypeId = invest.FormData.FormId;
                  let selectedProject = invest.FormData.Fields && invest.FormData.Fields.length > 0 && invest.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down")  ? invest.FormData.Fields.find(x => x.Name == "Project" && x.Type == "Drop Down").Value : null;
                  if(selectedProject && selectedProject != "" && !formProjects.find(x => x.ProjectLabel == selectedProject)){
                    formProjects.push({ProjectLabel : selectedProject, showData : true});
                  }
                  entityList.push({EntityId : invest.FormData.FormDataId, EntityTypeId : 93});
                }
                else{
                  let task = response.FormTaskCollection.find(x => x.InvestigationId == invest.InvestigationId);
                  if(task){
                    invest.FormTask = task;
                  }
                  entityList.push({EntityId : invest.InvestigationId, EntityTypeId : 1});
                }
              });
              if(surveyCount != 0){
                surveySet = surveySet.concat(response.SiteSurveyCollection);
              }
            
              if(incidentCount != 0){
                surveySet = surveySet.concat(response.InvestigationCollection);
              }

              surveySet.forEach(element =>{
                if(element.FormTask){
                  if(element.FormTask.AssignedUserId){
                    var user = userAssignedList.find(x => x.UserId == element.FormTask.AssignedUserId);
                    if(user){
                      if(element.FormTask.FormTaskStatus == 0){
                        openCount++;
                        user.assignedCount++;
                      }
                      else if(element.FormTask.FormTaskStatus == 1){
                        closedCount++;
                        user.assignedCountClosed++;
                      }
                      assignedCount++;
                    }
                  }
                }
                else if(element.FormData && element.FormData.FormTask){
                  if(element.FormData.FormTask.AssignedUserId){
                     user = userAssignedList.find(x => x.UserId == element.FormData.FormTask.AssignedUserId);
                    if(user){
                      if(element.FormData.FormTask.FormTaskStatus == 0){
                        openCount++;
                        user.assignedCount++;
                      }
                      else if(element.FormData.FormTask.FormTaskStatus == 1){
                        closedCount++;
                        user.assignedCountClosed++;
                      }
                      assignedCount++;
                    }
                  }
                }
              });
              let ticketAssignmentAreas = [];
              if(response.AssignmentAreaCollection){
                response.AssignmentAreaCollection.forEach(area => {
                  if(area.GroupId){
                    if(ticketAssignmentAreas.find(x => x.GroupId == area.GroupId)){
                      area.HideFromDropDown = true;
                    }
                  }
                  ticketAssignmentAreas.push(area);
                });
              }

              this.setState({statusText: "",hasTicketData, ticketAssignmentAreas, formDataAssignmentAreas : response.FormDataAssignmentAreaCollection, formProjects, 
              resultSet: surveySet, userAssignedList, unfilteredResultSet: surveySet, resultsCount : incidentCount + surveyCount, incidentCount, surveyCount, 
              zoomToExtent : true, updateMapExtent : true, refresh : true, detailsSurveyLat : null, detailsSurveyLon : null,
              openCount, closedCount, 
              assignedCount});

              this.filterUpdate(this.state.filterUserValue, "user", true, true);
              this.filterUpdate(this.state.filterStatusValue, "status", true, true);
              this.filterUpdate(this.state.filterDueDateValue, "dueDate", true, true);
              this.filterUpdate(this.state.filterTicketTypeValue, "ticketType", true, true);
              this.filterUpdate(response.AssignmentAreaCollection, "ticketArea", true, true);

              localStorage.setItem("searchStripToDateString", props.dateTo);
              localStorage.setItem("searchStripFromDateString", props.dateFrom);
              localStorage.setItem("searchStripSearchTerm", props.searchCriteriaValue);
              localStorage.setItem("formProjects", JSON.stringify(formProjects));
              localStorage.setItem("ticketAssignmentAreas", JSON.stringify(ticketAssignmentAreas));
              localStorage.setItem("formDataAssignmentAreas", JSON.stringify(response.FormDataAssignmentAreaCollection));
              sessionStorage.setItem("searchStripFilterTicketAssignmentAreaValue", response.AssignmentAreaCollection);

              // cache data 
              try { set('resultSet_Survey', JSON.stringify(surveySet));}
              catch(e){console.log('error caching resultSet_Survey object')}
            }
          }
        })
      }
      else{
        this.setState({statusText: ''});
      }
    })
    .catch(error =>{
      console.log(error.message);
      this.setState({ statusText : ''});
    });

  }



  handleClose(){

  }

  fromHandleChange(date){
    this.setState({fromDate: date, zoomToExtent : false, updateMapExtent: false});
  }

  setUpdateMapExtentProp(value){
    this.setState({updateMapExtent : value});
  }

  filterFromUserList(user){
    let event = {
      target: {
        value: user.UserId
      }
    }
    let otherEvent = {
      target:{
        value: -1
      }
    }
    this.strip.current.filterUserUpdate(event);
    //Set status to any status
    this.strip.current.filterStatusUpdate(otherEvent);
  }

  disableType(type,visibility){
    this.map.current.setLayerVisibility(type,visibility)
  }

  updateAssignedCount(updateMapExtent){
    let openCount = 0;
    let closedCount = 0;
    let assignedCount =0;
    let userAssignedList = [];
    if(this.state.resultSet && this.state.resultSet.length > 0){
      let resultSet = this.state.resultSet;
      resultSet.forEach(element =>{
        if(element.Type == "Survey User"){
          element.assignedCount = 0;
          element.assignedCountClosed = 0;
          userAssignedList.push(element);
        }
        else{
          //let filterUserValue = this.state.filterUserValue;
          //let filterStatusValue = this.state.filterStatusValue;
          if(element.FormTask){
            if(element.FormTask.AssignedUserId){
              var user = userAssignedList.find(x => x.UserId == element.FormTask.AssignedUserId);
              if(user){
                if(element.FormTask.FormTaskStatus == 0){
                  openCount++;
                  user.assignedCount++;
                }
                else if(element.FormTask.FormTaskStatus == 1){
                  closedCount++;
                  user.assignedCountClosed++;
                }
                assignedCount++;
              }
            }
          }
          else if(element.FormData && element.FormData.FormTask){
            if(element.FormData.FormTask.AssignedUserId){
               user = userAssignedList.find(x => x.UserId == element.FormData.FormTask.AssignedUserId);
              if(user){
                if(element.FormData.FormTask.FormTaskStatus == 0){
                  openCount++;
                  user.assignedCount++;
                }
                else if(element.FormData.FormTask.FormTaskStatus == 1){
                  closedCount++;
                  user.assignedCountClosed++;
                }
                assignedCount++;
              }
            }
          }
        }
      });
    }
    this.setState({userAssignedList, openCount, closedCount, assignedCount, updateMapExtent, refresh : true});
  }

  filterUpdate(value, type, updateMapExtent, zoomToExtent){

    if(type == "user") {
      this.setState({filterUserValue: value, zoomToExtent : zoomToExtent, updateMapExtent : updateMapExtent, refresh : true}, () =>{this.updateAssignedCount(true);});
    }
    if(type == "status"){
      this.setState({filterStatusValue : value, zoomToExtent : zoomToExtent, updateMapExtent : updateMapExtent, refresh : true}, () =>{this.updateAssignedCount(true);});
    }
    if(type == "dueDate"){
      this.setState({filterDueDateValue : value, zoomToExtent : zoomToExtent, updateMapExtent : updateMapExtent, refresh : true}, () =>{this.updateAssignedCount(true);});
    }
    if(type == "ticketType"){
      this.setState({filterTicketTypeValue: value, zoomToExtent : zoomToExtent, updateMapExtent : updateMapExtent, refresh : true}, () =>{this.updateAssignedCount(true);});
    }
    if(type == "ticketArea"){
      this.setState({filterTicketAssignmentAreasValue : value, zoomToExtent : zoomToExtent, updateMapExtent : updateMapExtent, refresh : false}, () =>{this.updateAssignedCount(true);});
    }
    if(type == "featureArea"){
      this.setState({filterFeatureTicketAssignmentAreasValue : value, zoomToExtent : zoomToExtent, updateMapExtent : updateMapExtent, refresh : true}, () =>{this.updateAssignedCount(true);});
    }
    this.doFiltering();
    this.setState({refresh : true});
  }

  downloadRequest(props){
    let FilterFeatureTicketAreas = [];
    let FilterTicketAssignmentAreas = [];
    //No areas just send null to be ignored
    if(!this.state.CompanyArcGisLayers || this.state.CompanyArcGisLayers.length == 0){
      FilterFeatureTicketAreas = null;
    }
    else{
      var ticketAreas = this.state.CompanyArcGisLayers.filter(x => x.LayerType == "TicketArea");
      if((!ticketAreas || ticketAreas.length == 0) && this.state.filterFeatureTicketAssignmentAreasValue.length  == 0){
        FilterFeatureTicketAreas = null;
      }
      else if(this.state.filterFeatureTicketAssignmentAreasValue && this.state.filterFeatureTicketAssignmentAreasValue.length > 0){
        //IF All selected Which will also be the case for First load send null up to indicate to api that all are selected to also include things outside of all areas..
        if(this.state.filterFeatureTicketAssignmentAreasValue[0] == 'FirstLoad' || ticketAreas.length == this.state.filterFeatureTicketAssignmentAreasValue.length){
          FilterFeatureTicketAreas = null;
        }
        else{
          FilterFeatureTicketAreas = this.state.filterFeatureTicketAssignmentAreasValue;
        }
      }
    }
    //IF No assignment areas send up null to ignore
    if(!this.state.ticketAssignmentAreas || this.state.ticketAssignmentAreas.length == 0){
      FilterTicketAssignmentAreas = null;
    }
    //else if = send up null to indicate everything is selected to also include things outside of all areas.
    else if(this.state.ticketAssignmentAreas.length == this.state.filterTicketAssignmentAreasValue.length){
      FilterTicketAssignmentAreas = null;
    }
    //else set to filter
    else{
      FilterTicketAssignmentAreas = this.state.filterTicketAssignmentAreasValue;
    }
    let DataGroups;
    let Projects;
    if(this.state.dataGroups && this.state.dataGroups.length > 0){
      DataGroups = [];
      this.state.dataGroups.forEach(group =>{
        if(group.showData){
          DataGroups.push(group.DataGroupId);
        }
      });
    }
    if(this.state.formProjects && this.state.formProjects.length > 0 && this.state.formProjects.filter(x => !x.showData).length > 0){ //Api won't filter if projects is null, no need to filter if none are hidden. 
      Projects = [];
      this.state.formProjects.forEach(project =>{
        if(project.showData){
          Projects.push(project.ProjectLabel);
        }
      })
    }
    this.setState({ statusText : "Collecting Data...", zoomToExtent : false, updateMapExtent : false});
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      SearchParam : props.searchCriteriaValue,
      DateTo: props.dateTo,
      DateFrom : props.dateFrom,
      ReturnSurveyData : true,
      ReturnIncidentData : true,
      ReturnGPSSurveyData : false,
      ReturnLeakSurveyData : false,
      ReturnSiteSafeData : false,
      ReturnPictures : this.state.includePhotos,
      Offset : -new Date().getTimezoneOffset() / 60,
      DataGroups,
      Projects,
      UseModifiedDate : this.state.useModifiedDate,
      FilterTaskStatus : this.state.filterStatusValue,
      FilterAssignment : "-1",
      FilterUser : this.state.filterUserValue, 
      FilterDueDate : this.state.filterDueDateValue,
      FilterTicketType : this.state.filterTicketTypeValue,
      FilterFeatureTicketAreas,
      FilterTicketAssignmentAreas
    };
    
    fetch(process.env.API_URL + "/api/data/batchDownload/",{
      method: "POST",
      headers: {
        Accept : "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body: JSON.stringify(body)
    })
    .then(r => {
      if (r.status ==200) { this.setState({ statusText : "", updateStatusText : "Your download request has been received. An email will be sent to you shortly.", alertSeverity : "success"});}
      else if (r.status == 400){
        this.setState({ statusText : "", updateStatusText : "Your download request exceeds the data limit.  Narrow the scope by date or keyword and try again.", alertSeverity : "error"});
      }
      else { console.log('/api/data/batchDownload/' + r.status)}
    }).catch(error =>{
      console.log(error.message);
      this.setState({ statusText : "" });
    });
  }

  statusTextUpdate(statusText){
    this.setState({statusText});
  }

  showUserInAssignedList(user){
    let inSelectedArea = false;
    let inSelectedFeature = false;
    if(this.state.userAssignmentAreas && this.state.userAssignmentAreas.length > 0){
      if(this.state.filterFeatureTicketAssignmentAreasValue && this.state.filterFeatureTicketAssignmentAreasValue.length > 0 && this.state.filterFeatureTicketAssignmentAreasValue[0] != 'FirstLoad'){
        let thisUsersFeatures = this.state.userAssignmentAreas.filter(x => x.UserId == user.UserId && x.CompanyArcGisLayerId);
        thisUsersFeatures.forEach(area => {
          if(this.state.filterFeatureTicketAssignmentAreasValue.findIndex(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId) != -1){
            inSelectedFeature = true;
          }
        });
      }
      else if(this.state.filterFeatureTicketAssignmentAreasValue && this.state.filterFeatureTicketAssignmentAreasValue.length > 0 && this.state.filterFeatureTicketAssignmentAreasValue[0] == 'FirstLoad'){
        if(this.state.CompanyArcGisLayerFeatures){
          let thisUsersFeatures = this.state.userAssignmentAreas.filter(x => x.UserId == user.UserId && x.CompanyArcGisLayerId);
          thisUsersFeatures.forEach(area => {
            if(this.state.CompanyArcGisLayerFeatures.findIndex(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId) != -1){
              inSelectedFeature = true;
            }
          });
        }
      }
     if(this.state.ticketAssignmentAreas && this.state.ticketAssignmentAreas.length > 0 && this.state.filterTicketAssignmentAreasValue && this.state.filterTicketAssignmentAreasValue.length > 0){
        let thisUsersFeatures = this.state.userAssignmentAreas.filter(x => x.UserId == user.UserId && x.AssignmentAreaId);
        thisUsersFeatures.forEach(area => {
          if(this.state.filterTicketAssignmentAreasValue.findIndex(x => x.AssignmentAreaId == area.AssignmentAreaId) != -1){
            inSelectedArea = true;
          }
        });
      }
    }
    return (inSelectedArea || inSelectedFeature) && (user.LicenseIdCollection && (user.LicenseIdCollection.includes("site") || user.LicenseIdCollection.includes("investigation") || user.LicenseIdCollection.includes("investigation")))
  }
  
  render(){
    return(
    <div className="col-10 d-flex justify-content-center p-0 m-0" style={{backgroundColor : '#E9E9EF'}}>
      <Snackbar open={this.state.updateStatusText != ""} onClick={() =>{this.setState({updateStatusText: ''})}} autoHideDuration={6000} onClose ={this.handleClose}
        anchorOrigin={{ vertical: 'top', horizontal : 'center'}} >
        <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
          {this.state.updateStatusText}
        </Alert>
      </Snackbar>
      <div className="container-fluid">
        <SearchStrip ref={this.strip} includePhotos={this.state.includePhotos} updateIncludePhotos={this.updateIncludePhotos}
          hasLicense ={this.hasLicense}
          includeDueDateFilter={this.state.hasTicketData}
          ticketAssignmentAreas={this.state.ticketAssignmentAreas}
          ticketGroups={this.state.ticketGroups}
          ticketAssignmentAreaGroups={this.state.ticketAssignmentAreaGroups}
          userAssignmentAreas={this.state.userAssignmentAreas}
          expandTicketGroup={this.expandTicketGroup}
          CompanyArcGisLayerFeatures={this.state.CompanyArcGisLayerFeatures}
          AnalysisLayers={this.state.CompanyArcGisLayers && this.state.CompanyArcGisLayers.length > 0 ? this.state.CompanyArcGisLayers.filter(x => x.LayerType == "Analysis"): []}
          includeTicketTypeFilter={this.state.hasTicketData}
          fromMap={true} searchRequest={this.searchRequest} downloadRequest={this.downloadRequest} dataMap={true}
          disableType={this.disableType} resultsCount={this.state.resultsCount} surveyCount={this.state.surveyCount}
          incidentCount={this.state.incidentCount} surveyMapDownload={true} defaultDateRangeDays={this.state.dateRangeDays}
          headerLabel="Site Survey &amp; Investigations" filterUpdate={this.filterUpdate} fromHandleChange={this.fromHandleChange.bind(this)}
          dataGroups={this.state.dataGroups} toggleDataGroup={this.toggleDataGroup} expandGroup={this.expandGroup}
          useModifiedDate={this.state.useModifiedDate} updateUseModifiedDate={(value) =>{this.setState({useModifiedDate : value})}}
          formProjects={this.state.formProjects} toggleProject={this.toggleProject} searching={this.state.statusText == "refreshing Data..."}
          tagFormSearchOptions={this.state.tagFormSearchOptions}
          loadingTagFormSearchOptions={this.state.loadingTagFormSearchOptions}
          showAdvancedSearchButton={true}
        />
          {/* {this.state.statusText == "" && <BasemapToggleButton />} */}
          {this.state.statusText == "" && <div className="assignTaskButtonBar">
            <CreateTaskButton createTask={()=>{document.getElementById("createTaskDiv").style.display = 'block';}}/>
          </div>}
          <div className = "row">
            {this.state.showDataList && 
            <div style={{minWidth: "20%", overflowY : 'scroll', maxHeight : '85vh', maxWidth : '20%'}}>
              {this.state.dataInExtent.slice((this.state.extentDataPage*10) - 10, this.state.extentDataPage*10).map((element,index) =>(
                <div key={index} style={{ marginBottom : 10, position : 'relative'}} className="cardMainDiv" onClick={() => {let data = this.state.dataInExtent; data[(this.state.extentDataPage*10) - 10 + index].DisplayOptions = !data[ (this.state.extentDataPage*10) - 10 + index].DisplayOptions; this.setState({dataInExtent : data});}}>
                  <div className="anchorButton padded fullWidth">
                  {(element.FormData && element.FormData.Fields && element.FormData.Fields.find(x => x.Name == "Ticket Number")) &&  <div className="text-left full large">Ticket Number: {element.FormData.Fields.find(x => x.Name == "Ticket Number").Value}</div>}
                  {(element.FormData && element.FormData.Fields && element.FormData.Fields.find(x => x.Name == "Ticket Revision")) && <div className="text-left full medium fadded">Ticket Revision: {element.FormData.Fields.find(x => x.Name == "Ticket Revision").Value}</div>}
                  {(element.FormData && element.FormData.Fields && element.FormData.Fields.find(x => x.Name == "Ticket Type")) && <div className="text-left full medium fadded">Ticket Type: {element.FormData.Fields.find(x => x.Name == "Ticket Type").Value}</div>}
                  {(element.FormData && element.FormData.FormTask)  && <div className="text-left full medium fadded">Ticket Status: {element.FormData.FormTask.FormTaskStatus == 0 ? "Open" : element.FormData.FormTask.FormTaskStatus == 1 ? "Closed" : element.FormData.FormTask.FormTaskStatus == 2 ? "Project" : element.FormData.FormTask.FormTaskStatus == 3 ? "Canceled" : ""  }</div>}
                  {(element.FormData && element.FormData.Fields && element.FormData.Fields.find(x => x.Name == "Due Date")) && <div className="text-left full medium fadded">Due Date: {element.FormData.Fields.find(x => x.Name == "Due Date").Value}</div>}
                  {(element.FormData && element.FormData.FormTask) && <div className="text-left full medium fadded">Closed Date: {element.FormData.FormTask.CloseDate ? element.FormData.FormTask.CloseDate : 'Ticket Not Closed'}</div>}
                  {(element.FormData && element.FormData.Fields && element.FormData.Fields.find(x => x.Name == "Address")) && <div className="text-left full medium fadded"> Address: {element.FormData.Fields.find(x => x.Name == "Address").Value}</div>}
                  </div>
                  
                  <div style ={{position : 'absolute', right : 30, backgroundColor : '#305f8a', bottom : 0, display : element.DisplayOptions ? 'block' : 'none', width: 150, height: 130, alignContent : "center"}} >
                    <div className='cardMainDiv' style={{margin : 10, color : "white", cursor : "pointer", width : 'auto'}} onClick={()=>{this.map.current.viewEntity(element.FormData, "form")}}>Details</div>
                    <div className='cardMainDiv' style={{margin : 10, color : "white", cursor : "pointer", width : 'auto'}} onClick={()=>{this.map.current.setTicketToAssign(element);document.getElementById("assignTaskDiv").style.display = 'block';}}>Assign</div>
                    <div className='cardMainDiv' style={{margin : 10, color : "white", cursor : "pointer", width : 'auto'}} onClick={()=>{this.map.current.zoomToLatLon(element.Latitude,element.Longitude);}}>Zoom To</div>
                  </div>
              </div>))}
              {this.state.dataInExtent && this.state.dataInExtent.length > 10 && <Pagination count={Math.floor(this.state.dataInExtent.length  / 10) + 1} page={this.state.extentDataPage} onChange={this.handleDataListPageChange}/>}
            </div>}
          <div id='mapContainerDiv' className="mapContainerDiv" style={{minWidth: this.state.showDataList ? "80%" : "100%"}}>
            {<div className="row mapspin" id="loading" style={{display:(this.state.statusText != "" ? 'unset' : 'none')}}>
              <div className="spinnerDiv">
                <div className="text-center authSpinnerDiv">
                  {this.state.statusText != "" ? <div className="authSpinnerDivMessage">
                    {this.state.statusText}
                  </div> : ''}
                  <div className="barLoaderPortal">
                    <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading={ true }/>
                  </div>
                </div>
              </div>
            </div>}

            <div className ="assignViewDropdown" style={{left : this.state.showDataList ? '18vw' : 8, minWidth : 115}}>
              <div className="assignViewDropdownHeader" style={{width : 115}} onClick={() => {this.showDataList()}}>
                {this.state.showDataList && <span className ="assignViewHeaderTextSurvey" style={{marginLeft : 10}}>Hide Data List</span>}
                {!this.state.showDataList && <span className ="assignViewHeaderTextSurvey"style={{marginLeft : 10}}>Show Data List</span>}
              </div>
            </div>
            <div className="assignViewDropdown" style={{left : this.state.showDataList ? '25vw' : 140}}>
              <div ref={node => this.node = node} onClick={() =>{this.setState({showAssignedList : !this.state.showAssignedList,refresh : false})}} className="assignViewDropdownHeader">
                <span className ="assignViewHeaderTextSurvey">ASSIGNED</span>
                <div className = "leakCountBlock countBlock headerBlockSurvey">{this.state.openCount}</div>
                <div className = "leakCountBlock closedCountBlock countBlock headerBlockSurvey">{this.state.closedCount}</div>
              </div>
              {this.state.showAssignedList && <div className="assignViewDropdownContent">
                <div className="assignViewOpenClosed"  >
                  <div className="surveyBlockText">open</div>
                  <div className="surveyBlockText" style={{marginRight: 5}}>closed</div>
                </div>
                <div style={{minHeight: 20}}/>
                {this.state.userAssignedList.map((user, index) =>
                  this.showUserInAssignedList(user) && <div key={index} style={{marginTop : 5, marginBottom : 5}}>
                    <div className="assignViewDropDownItem" onClick={()=>{this.filterFromUserList(user)}}>
                      <div className="leakCountBlock countBlock openAssignedBlock" style={{float : "right", marginRight : 5}}>{user.assignedCount}</div>  
                      <span className="assignViewUserName">{user.DisplayName}</span>
                      <div className="leakCountBlock closedCountBlock countBlock closedAssignedBlock">{user.assignedCountClosed}</div>
                    </div>
                  </div>
                )}
              </div>}
            </div>
            {this.state.CompanyArcGisLayers && <WebMapViewSurveyInvestigation updateFormFromEdit={this.updateFormFromEdit} CompanyArcGisLayers ={this.state.CompanyArcGisLayers} ARCGISClientSecret ={this.state.ARCGISClientSecret} ARCGISClientId={this.state.ARCGISClientId} EnterpriseConnection={this.state.EnterpriseConnection}
               ref={this.map} resultSet={this.state.resultSet} zoomToExtent={this.state.zoomToExtent}
               updateDataInExtent={this.updateDataInExtent}
               updateFromArchive ={ this.updateFromArchive}
               addGeoDataToFormData={this.addGeoDataToFormData} addNewEntityToResults={this.addNewEntityToResults.bind(this)}
                creatingNewTask={this.state.creatingNewTask} setCreatingNewTask={this.setCreatingNewTask}
                updateMapExtent={this.state.updateMapExtent} refresh={this.state.refresh} searchRequest={this.searchRequest}
                statusTextUpdate={this.statusTextUpdate} setUpdateMapExtentProp={this.setUpdateMapExtentProp.bind(this)}
                formDataAssignmentAreas={this.state.formDataAssignmentAreas}
                updateResultsFromShapefileUpload={this.updateResultsFromShapefileUpload}
                ticketAssignmentAreas={this.state.ticketAssignmentAreas}
                filterTicketAssignmentAreasValue={this.state.filterTicketAssignmentAreasValue}
                setIncidentCount={this.setIncidentCount}
                userAssignmentAreas={this.state.userAssignmentAreas}
                selectedTicketAreaFeatures={this.state.filterFeatureTicketAssignmentAreasValue}
                filterUserValue={this.state.filterUserValue}
                CompanyArcGisLayerFeatures={this.state.CompanyArcGisLayerFeatures}
                setSurveyCount={this.setSurveyCount}
                detailsSurveyLat={this.state.detailsSurveyLat} detailsSurveyLon = {this.state.detailsSurveyLon} updateResultsFromTaskUnassign={this.updateResultsFromTaskUnassign} updateResultsFromAssignment={this.updateResultsFromAssignment} showProgressBar={this.showProgressBar} setAlert={this.setAlert}
                updateAssignedCount={this.updateAssignedCount} displayToaster={this.displayToaster} multimediaAssets={this.state.multimediaAssets} updateResultsFromAssignmentArea={this.updateResultsFromAssignmentArea} updateResultsFromUserAssignment={this.updateResultsFromUserAssignment}/> }
          </div>
          </div>
      </div>
    </div>)
  }
}

MapPageSurveyInvestigation.propTypes = {

}

export default MapPageSurveyInvestigation;