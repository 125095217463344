import React from "react";
import PropTypes from "prop-types";
import auth from "../common/Auth";
import ImageGallery from 'react-image-gallery';
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { Button } from 'react-bootstrap';
import PhotoUpload from './PhotoUpload';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BarLoader } from "react-spinners";
import Popup from "reactjs-popup";
import "../app.css";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import VideoPanel from './VideoPanel';
import AttachedDocumentPanel from './AttachedDocumentPanel';

class SurveyBody extends React.Component {
  constructor(props) {
    super(props);
    let stateList = localStorage.getItem("surveyStateList");
    this.state = {
      imgUrl: "",
      galleryItems: [],
      images: [],
      videos:[],
      blobs: [],
      documents: [],
      thumbnails : [],
      loading: false,
      picUrl: null,
      modalOpen: false,
      currentIndex :0,
      stateList : stateList && stateList != '' ? JSON.parse(stateList) : [],
      updateStatusText : "",
      alertSeverity : "success",
      pictureCount: 0,
      videoCount: 0,
      loadingVideos:false,
      videoIndex : 0
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.formatHorizontal = this.formatHorizontal.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
    this.getMultimedia = this.getMultimedia.bind(this);
    this.clickThumbNail = this.clickThumbNail.bind(this);
    this.setVideoIndex = this.setVideoIndex.bind(this);
    this.removeVideo = this.removeVideo.bind(this);
    this.controller = new AbortController();
  }

  componentDidMount() {
    this.getMultimedia();
  }

  getMultimedia(){
    if (this.props.survey != undefined && this.props.survey.MultimediaAssets != undefined){
      this.props.survey.MultimediaAssets.forEach((element, index) => {
        if(element.MediaTypeId != 1){
          this.getPic(element.MultimediaAssetId, element.Timestamp, index);
        }
        else if(element.MediaTypeId == 1){
          this.getVideo(element.MultimediaAssetId, element.Timestamp, index);
        }
    });
  }
  else if(this.props.survey != undefined){ 
    fetch(process.env.API_URL + "/api/entityImages/" +( this.props.upToDateSurveyId ? this.props.upToDateSurveyId : this.props.survey.Id )+ "/" + this.props.survey.EntityTypeId + "/web",{
      method: "POST",
      headers: {"Content-Type" : "application/json", Authorization: "Bearer " + auth.token()},
    })
    .then(r => {
      if(r.status==200){
        r.json().then(bodyJson =>{
          if(bodyJson.length >0){
            let pictureCount = 0;
            let videoCount = 0;
            let response = JSON.parse(bodyJson);
            this.setState({MultimediaAssets : response.MultimediaAssets.filter(x => x.MediaTypeId == 0 || x.MediaTypeId == null)});
            response.MultimediaAssets.forEach((element)=>{
              if(!this.props.survey.HistoryDate || (element.CreatedDate < this.props.survey.ModifiedDate)){
                if(element.MediaTypeId == 0 || element.MediaTypeId == null) {
                  pictureCount++;
                  this.getPic(element.MultimediaAssetId, element.Timestamp, false); 
                }
                if(element.MediaTypeId == 2){
                  videoCount++;
                  this.getPic(element.MultimediaAssetId,element.Timestamp, true, element.CompareMultimediaAssetId);
                }
                if(element.MediaTypeId == 3){
                  let documents = this.state.documents;
                  documents.push(element);
                  this.setState({documents});
                }
              }
            });
            this.props.setPictureCount(pictureCount);
            this.setState({pictureCount,videoCount});
          }
        });
      }
    }).catch((e) =>{console.log(e)});
  }
  }

  handleClose(){}

  componentDidUpdate(previousProps){ 
    // Check to see if the survey has changed to reload state data, but if the survey has been edited, it creates a new ID and thinks its changed, 
    // so were checking to see if its been incremented, this might need to be changed.
    if(previousProps.survey && this.props.survey && (previousProps.survey.Id != this.props.survey.Id) && (previousProps.survey.Id + 1 != this.props.survey.Id)){
      this.controller.abort();
      this.controller = new AbortController();
      this.setState({images: [],videos:[],blobs: [],galleryItems : [],thumbnails : [], documents: [],pictureCount: 0,videoCount: 0},() =>{this.getMultimedia()});
    }
    window.resize(); 
  }

  getVideo(assetId){
    this.setState({ loadingVideos : true });
    fetch(process.env.API_URL + "/api/video/" +assetId,{
      method : "GET",
      headers:{
        "Content-Type" : "application/json",
        Authorization : 'Bearer ' + auth.token()
      },
      signal : this.controller.signal
    })
    .then(r => r.blob())
    .then(blob =>{
      let videos = this.state.videos;
      let vid = URL.createObjectURL(blob);
      videos.push({uri : vid, assetId});
      this.setState({videos, videoIndex : videos.length -1});
      this.setState({loadingVideos : false});
    }).catch(error => { console.log(error.message); this.setState({ loadingVideos: false });  });
  }

  setVideoIndex(direction){
    let index = this.state.videoIndex + direction;
    if(index == -1){
      index = this.state.videos.length -1;
    }
    else if(index == this.state.videos.length){
      index = 0;
    }
    this.setState({videoIndex : index});
  }

  getPic(assetId, timeStamp, thumbnail, videoId) {
    if(!thumbnail){
      this.setState({ loading: true });
    }
    fetch(process.env.API_URL + "/api/image/" + assetId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token()
      },
      signal : this.controller.signal
    })
    .then(r => r.blob())
    .then(blob => {
      let images = this.state.images;
      let thumbnails = this.state.thumbnails;
      let pic = URL.createObjectURL(blob);
      if(thumbnail){
        thumbnails.push({original : pic, thumbnail : pic, id : assetId, videoId, timeStamp});
        thumbnails.sort((a,b) => (a.timeStamp > b.timeStamp) ? 1 : -1);
        this.setState({thumbnails});
      }
      else{
        images.push({original: pic,thumbnail:pic, id : assetId,timeStamp});
        images.sort((a,b) => (a.timeStamp > b.timeStamp) ? 1 : -1);
        this.setState({images});
        let newCount = this.state.imgCount + 1;
        this.setState({
          imgUrl: pic,
          imgCount: newCount,
        });

        let tempArr = this.state.galleryItems;
        tempArr.push(blob);
        this.setState({ blobs: tempArr });
        this.formatHorizontal();
        if (this.state.galleryItems.length == this.state.pictureCount){
          this.setState({ loading: false });
        }
      }
    }).catch(error => { console.log(error.message); this.setState({ loading: false });});
  }

  clickThumbNail(index){
    let id = this.state.thumbnails[index].videoId;
    let videoIndex = this.state.videos.findIndex(x => x.assetId == id);
    if(this.state.videos.length == 0 || videoIndex == -1 ){
      this.getVideo(id);
    }
    else{
      this.setState({videoIndex});
    }
  }

  formatHorizontal(){
    let pictures = document.querySelectorAll('.image-gallery-slide img');
    for (let i = 0; i< pictures.length; i++){
      let pic = pictures[i];
      let img = new Image;
      img.onload = function(){
        if(img.width > img.height){
          let parentDiv = pic.parentElement;
          parentDiv.classList.remove('image-gallery-slide');
          parentDiv.classList.add('image-gallery-slide-horizontal');
        }
      }
      img.src = pic.src;
    }
  }

  removeVideo(){
    let thumbnail = this.state.thumbnails[this.state.videoIndex];
    let body = {
      ThumbnailId : thumbnail.id,
      VideoId : thumbnail.videoId,
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      UTCOffset : - (new Date().getTimezoneOffset()/60)
    }
    fetch(process.env.API_URL + "/api/video/delete",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(req =>{
      if(req.status == 200){
        
        req.json().then(responseJson =>{
          let response = JSON.parse(responseJson);
          if(response.MultimediaAsset == null){
            this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity :'error'});
          }
          else{
            let index = this.state.videos.findIndex(x => x.assetId == response.MultimediaAsset.MultimediaAssetId);
            if(index != -1){
              let videos = JSON.parse(JSON.stringify(this.state.videos));
              let thumbnails = JSON.parse(JSON.stringify(this.state.thumbnails));
              videos.splice(index,1);
              if(response.MultimediaAsset.CompareMultimediaAssetId != -1){
                index = this.state.thumbnails.findIndex(x => x.id == response.MultimediaAsset.CompareMultimediaAssetId);
                if(index != -1){
                  
                  thumbnails.splice(index, 1);
                }
              }
              this.setState({thumbnails, videos, updateStatusText : 'Video was removed.', alertSeverity : 'success'});
            }
            else{
              this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity : 'error'});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error);
      this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity : "error"});
    });
  }

  removePhoto(){
    let index = this._imageGallery.getCurrentIndex();
    let MultimediaAsset = this.state.MultimediaAssets[index];
    let date = new Date();
    MultimediaAsset.DeletedBy = auth.userId();
    MultimediaAsset.DeletedDate = date; 
    MultimediaAsset.DeletedDateUTCOffset = -date.getTimezoneOffset()/60;
    MultimediaAsset.ModifiedBy = auth.userId();
    MultimediaAsset.ModifiedDate = date; 
    MultimediaAsset.ModifiedDateUTCOffset = -date.getTimezoneOffset()/60;
    let body = {
      MultimediaAsset
    }
    fetch(process.env.API_URL + "/api/image/update",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "appliction/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(req =>{
      if(req.status == 200){
        req.json().then(responseJson => {
          let response = JSON.parse(responseJson);
          if(response.MultimediaAsset == null){
            this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity : "error"});
          }
          else{
            let index = this.state.MultimediaAssets.findIndex(x => x.MultimediaAssetId == response.MultimediaAsset.MultimediaAssetId);
            if(index != -1){
              let images = JSON.parse(JSON.stringify(this.state.images));
              images.splice(index,1);
              let MultimediaAssets = JSON.parse(JSON.stringify(this.state.MultimediaAssets));
              MultimediaAssets.splice(index,1);
              this.setState({images, MultimediaAssets, updateStatusText : 'Photo was removed.', alertSeverity : 'success'});
            }
            else{
              this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity : "error"});
            }
          }
        });
        
      }
    })
    .catch(error =>{
      console.log(error);
      this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity : "error"});
    })
  }

  openModal() {
    this.setState({ modalOpen: true }, () =>{ 
      setTimeout(() =>{
        let pic = document.getElementById("popupPic");
        if(pic.width > pic.height){
          let modal = document.getElementsByClassName("popup-content")[0];
          modal.classList.add("popup-horizontal");
        }
      }, 100);
    });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  clickPic() {
    this.setState({ picUrl: this.state.images[this._imageGallery.getCurrentIndex()].original });
    this.openModal();
  }

  render() {
    this.formatHorizontal();
    let timeZone = " " + Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
      <div id={this.props.fromDashboard ? "" : "SurveyBodyMainDiv"} name={this.props.fromDashboard ? "" : "SurveyBodyMainDiv"} className="detailMain" style={this.props.fromDashboard ? {overflowY : 'auto'} : {}}>
        <div className="detailContent">
          <div className=" labelHeaderBar labelHeaderBarTight">
            <label className="labelHeaderName">Survey Details</label>
          </div>
          <div className="detailContentPanel">
            {!this.props.editing && <div>
              <label className="labelDetailName" style={{marginRight:8}}>Address: </label>
              {this.props.survey.Address}, {this.props.survey.City}, {this.props.survey.State}
              <br />
              <label className="labelDetailName">Survey Date: </label>{" "}
              {/**The "Z" is to tell the Date object that the survey.date is in UTC time. So then it will convert it to your time zone. */}
              {new Date(this.props.survey.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}) + timeZone}
            </div>}
            {this.props.editing &&
            <div>
              <label className="labelDetailName">Address: </label> <input type="text" className="form-control entity-edit-text" value={this.props.streetAddress} onChange={(e) => {this.props.editStreetAddress(e.target.value)}}/>
              <br/>
              <label className="labelDetailName">City: </label> <input type="text" className="form-control entity-edit-text" value={this.props.city} onChange={(e) =>{this.props.editCity(e.target.value)}} />
              <br/>
              <label className="labelDetailName">State: </label> 
              <select className ="form-control entity-edit-text select" value={this.props.stateAddress} onChange={(e) =>{this.props.editStateAddress(e.target.value)}}>
                {this.state.stateList.map((state, index) =>( <option key ={index} value={state}>{state}</option>))}
              </select>
            </div>}
            <label className="labelDetailName">Ticket Number: </label> {this.props.editing ?<input type="text" className="form-control entity-edit-text"  value={this.props.ticketNumber} onChange={(e) => {this.props.editTicketNumber(e.target.value)}}/> :this.props.survey.TicketNumber} 
            <br />
            <label className="labelDetailName">Job Id/Name: </label> {this.props.editing ? <input type="text" className="form-control entity-edit-text" value={this.props.jobName} onChange={(e) =>{this.props.editJobName(e.target.value)}}/> : this.props.survey.JobName}
            <br />
            <label className="labelDetailName">Comments: </label> {this.props.editing ? <textarea type="text" className="form-control entity-edit-text-comment" value={this.props.comments} onChange={(e) => {this.props.editComments(e.target.value)}} /> :  this.props.survey.Comments}
            <br/>
          </div>
        </div>
        
        {this.props.survey.Type == "Survey" || this.props.survey.Type == "Site" ? 
          <div style={{margin:40}}>

          {this.props.survey.MapNotes && this.props.survey.MapNotes.length > 0 &&
            <div style={{marginBottom: 40}}>
              <div className=" labelHeaderBar labelHeaderBarTight"> <label className="labelHeaderName">Map Notes ({this.props.survey.MapNotes.length})</label></div>
              <div className="detailContentPanel">
              {this.props.survey.MapNotes.map((mapNote, index) => ( 
                <div style={{marginBottom: 10}} className="row" key={index}>
                  <div className="col-12"> 
                    <label className="labelDetailName"><b>Lat: </b>{mapNote.Latitude} <b>Lon: </b>{mapNote.Longitude}<br/><b>Comment: </b>{mapNote.Comment}</label>
                  </div>
                </div>
              ))}
              </div>
            </div>
          }

            <div className=" labelHeaderBar labelHeaderBarTight"> <label className="labelHeaderName">Photos ({this.state.pictureCount})</label></div>
            <div className="detailContentPanel">
            {this.state.loading ? <div className="barLoaderPortal"><BarLoader sizeUnit={"px"} size={200} color={"#095399"} loading={this.state.loading}/></div> : null } 
            {this.state.MultimediaAssets != null && this.state.MultimediaAssets.length > 0 ? 
              <div className = "row carouselRow">
                <div className="carouselGalleryDiv">
                  <ImageGallery items={this.state.images} 
                  onClick={() =>{ this.clickPic(); }}
                  showFullscreenButton={this.state.images.length > 0}
                  showPlayButton={this.state.images.length > 0}
                  ref={i => this._imageGallery = i}
                  />
                </div>
                <div className="image-galleryDeleteButtonDiv">
                  {this.state.images.length > 0 && !this.props.showingHistoryRecord && 
                  <Button className ="buttonAction buttonAccount" onClick={this.removePhoto}>
                    <HighlightOffIcon className="buttonIcon"/>
                    <label style={{ margin: 0, cursor: 'pointer'}} className='buttonText'>Delete</label>
                  </Button>
                  }
                </div>
              </div> : null }
            </div>
          </div> : null }
          {this.props.survey.Type == "Survey" || this.props.survey.Type == "Site" ?
            <div style={{margin:40}}>
              <VideoPanel 
                setVideoIndex={this.setVideoIndex}
                videoIndex={this.state.videoIndex} 
                clickThumbNail={this.clickThumbNail} 
                thumbnails={this.state.thumbnails} 
                loadingVideos={this.state.loadingVideos} 
                videoCount={this.state.videoCount} 
                videos={this.state.videos}
                removeVideo={this.removeVideo}
              />
            </div> : null} 
          {this.props.survey.Type == "Survey" || this.props.survey.Type == "Site" ? 
            <div style={{margin:40}}>
              <AttachedDocumentPanel showingHistoryRecord={this.props.showingHistoryRecord} addToDocs={(doc) =>{let docs = this.state.documents; docs.push(doc); this.setState({documents : docs})}} loadingDocuments={this.state.loading} entityId={this.props.survey.SiteSurveyId} entityTypeId={0} Documents ={this.state.documents}/>
            </div>
          : null}
          {!this.props.showingHistoryRecord && <div className="detailContent">
            <PhotoUpload entityId={this.props.survey.SiteSurveyId} entityTypeId={0} addToGallery={(image) =>{let ma = this.state.MultimediaAssets; ma.length++;let pic = URL.createObjectURL(image); let pictures = this.state.images; pictures.push({original : pic, thumbnail : pic}); let tempArr = this.state.galleryItems; tempArr.push(image); this.setState({ images : pictures, blobs : tempArr, MultimediaAssets : ma})}} />
          </div>}
        <Popup modal={true} lockScroll={false} open={this.state.modalOpen} onClose={this.closeModal} closeOnDocumentClick closeOnEscape position='top left'>
          <div style={{height:"100%"}} className ="justify-content-center text-center galleryPopup">
            <img className = "justify-content-center text-center" id = "popupPic" src={this.state.picUrl}/>
          </div>
        </Popup>
        {this.state.updateStatusText != "" &&
          <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={6000} onClose={this.handleClose} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
            {this.state.updateStatusText}
            </Alert>
          </Snackbar>}
      </div>
    );
  }
}

SurveyBody.propTypes = {
  survey: PropTypes.object,
  setPictureCount : PropTypes.func,
  editing : PropTypes.bool,
  streetAddress : PropTypes.string,
  editStreetAddress : PropTypes.func,
  editCity : PropTypes.func,
  city : PropTypes.string,
  stateAddress : PropTypes.string,
  editStateAddress : PropTypes.func,
  editTicketNumber : PropTypes.func,
  ticketNumber : PropTypes.string,
  jobName : PropTypes.string,
  editJobName : PropTypes.func,
  editComments : PropTypes.func,
  comments : PropTypes.string,
  showingHistoryRecord : PropTypes.bool,
  upToDateSurveyId : PropTypes.number,
  fromDashboard : PropTypes.bool
};

export default SurveyBody;
