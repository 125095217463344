import React from 'react';
import SurveyStrip from "./SurveyStrip";
import SiteSafeBody from './SiteSafeBody';
import PropTypes from "prop-types";

class SiteSafePage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      pictureCount : 0
    }
    this.setPictureCount = this.setPictureCount.bind(this);
  }

  componentWillMount(){
    let siteSafe = {};
    if(this.props.location.siteSafe != null){
      siteSafe = this.props.location.siteSafe;
      let sc = this.props.location.siteSafe;
      sc.__proto__ = null;
      sc.Details = null;
      localStorage.setItem('currentSiteSafe', JSON.stringify(sc));
    }
    else{
      let sc = localStorage.getItem('currentSiteSafe');
      if(sc && sc != ''){
        siteSafe = JSON.parse(sc);
      }
    }

    if(Object.entries(siteSafe).length === 0){
      siteSafe = null;
    }
    this.setState({siteSafe});
  }

  componentDidMount(){
    if(this.props.forceRefresh){
      this.props.forceRefresh();
    }
  }

  setPictureCount(pictureCount){
    this.setState({pictureCount});
  }

  componentDidUpdate(prevProps){
    if(prevProps.location.siteSafe && this.props.location.siteSafe && (prevProps.location.siteSafe.Id != this.props.location.siteSafe.Id)){
      this.setState({siteSafe : this.props.location.siteSafe});
    }
  }

  render(){
    return(
      <div className ="col-10 d-flex justify-content-center p-0 m-0" style={{backgroundColor : "#E9E9EF"}}>
        {
          this.state.siteSafe ? 
          <div className="container-fluid">
            <SurveyStrip  
              entityTypeId = {23}
              type={this.state.siteSafe.Type}
              id={this.state.siteSafe.Id}
              entity={this.state.siteSafe}
              pictureCount={this.state.pictureCount}
              loadingUpdate="Save"
              closeEntity={this.props.closeEntity}
            />
            <SiteSafeBody siteSafe = {this.state.siteSafe}  setPictureCount={this.setPictureCount} />
          </div>
          :
          <div className="container-fluid">
            <div className = "text-center detailsErrorMessage">Something went wrong loading the data. Go back to Reporting page and try again.</div>
          </div>
        }
      </div>
    )
  }
}

SiteSafePage.propTypes = {
  location: PropTypes.object,
  forceRefresh: PropTypes.func,
  closeEntity: PropTypes.func,
}

export default SiteSafePage;