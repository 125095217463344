import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-datepicker/dist/react-datepicker.css";
import DateRange from "@material-ui/icons/DateRange";
import SettingsTwoTone from "@material-ui/icons/SettingsTwoTone"
import ToggleOffTwoTone from "@material-ui/icons/ToggleOffTwoTone";
import ToggleOnTwoTone from "@material-ui/icons/ToggleOnTwoTone";
import GetApp from "@material-ui/icons/GetApp";
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import { GLOBAL } from "../../Global";
import auth from '../common/Auth';
import { FormControlLabel, Checkbox } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import WindowOpener from "react-window-opener";
import { get, set, del } from 'idb-keyval';
import Autocomplete from "react-autocomplete";
import OrgUtility from '../../Utility/Utility'
import { BarLoader, RingLoader } from "react-spinners";

class SearchStrip extends React.Component {
  constructor(props) {
    super(props);
    let licenseString = auth.licenses();
    let hasLeakSurveyLicense = false;
    if(licenseString){
      let licenseArray = licenseString.split(',');
      hasLeakSurveyLicense = (licenseArray.indexOf('leak') > -1);
    }
    this.state = {
      dateTo: null,
      dateFrom: null,
      dateFromDownload : null,
      dateToDownload : null,
      toPickerOpen: false,
      fromPickerOpen: false,
      toPickerOpenDownload: false,
      fromPickerOpenDownload: false,
      selectedDateNew: null,
      resultLabel: "Results:",
      searchCriteria: "",
      headerLabel:"Data & Reporting",
      dialogOpen: false,
      exportDialogOpen: false,
      filterUserValue: "-1",
      filterDueDateValue : "-1",
      filterStatusValue: "-1",
      includePhotos : true,
      hasLeakSurveyLicense,
      showCustomFormsFilter : false,
      showTreeFilter : false,
      arcGisToken: null,
      arcGisConnected: false,
      arcGisAuthenticationUri: null,
      dataExportUnit: 0,
      height : 0,
      width : 0,
      dataExportTransformationOption: 0,
      selectedWKID: "", 
      coordinateSystemsLoading: false,
      coordinateSystemsLoadSuccess: false,
      selectedAreas : [],
      selectedFeatures : [],
      showAdvancedSearchDialog:false,
      tagFormSearchOptions: null,
      advancedSearchTagsFilterApplied: false
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.coordinateSystems = null;
    this.selectTicketArea = this.selectTicketArea.bind(this);
    this.renderTicketGroup = this.renderTicketGroup.bind(this);
  }

  clickSubmit() {
    let newDateTo = this.state.dateTo;
    let newDateFrom = this.state.dateFrom;
    GLOBAL.SEARCH.toDate = newDateTo;
    GLOBAL.SEARCH.fromDate = newDateFrom;
    GLOBAL.SEARCH.criteria = this.searchCriteriaValue.value;
    this.props.searchRequest({ searchCriteriaValue: this.searchCriteriaValue.value, dateFrom: newDateFrom, dateTo: newDateTo, tagFilterOptions: this.state.tagFormSearchOptions});
  }

  clickDownload(type){
    // Message user about leak download process, or initiate other download directly
    if (type == 'leak' || type == 'surveyMap') this.setState({dialogOpen: true});
    else if(this.props.reportingPage) this.setState({dialogOpen : true});
    else if (this.props.downloadRequest != undefined){ 
      var wkt = "";
      if(this.state.dataExportTransformationOption == 2){
        const selectedGCS = this.coordinateSystems.filter((x) => x.WKID == parseInt(this.state.selectedWKID));
        if(selectedGCS.length > 0){
          wkt = selectedGCS[0].WKT;
        }
        else{
          alert("Please enter a valid WKID.");
          return;
        }
      }
      this.props.downloadRequest({searchCriteriaValue: this.searchCriteriaValue.value, type: type, exportUnit: this.state.dataExportUnit, transformOption: this.state.dataExportTransformationOption, exportWkt: wkt, exportWkid: this.state.selectedWKID, dateFrom : this.state.dateFromDownload, dateTo : this.state.dateToDownload});
      this.setState({exportDialogOpen: false});
    }
  }

  clickPushToArcGIS(){
    if (this.props.pushToArcGISRequest != undefined && confirm("Push data to ArcGIS Online?")) {
      this.props.pushToArcGISRequest({token: this.state.arcGisToken, dataExportUnit: this.state.dataExportUnit, searchCriteriaValue: this.searchCriteriaValue.value, dateFrom: this.state.dateFromDownload, dateTo: this.state.dateToDownload});
      this.setState({exportDialogOpen: false});
    }
  }

  updateDataExportUnit(e){
    this.setState({dataExportUnit: parseInt(e.target.value)});
    set("dataExportUnit", parseInt(e.target.value));
  }

  updateDataExportTransformation(e){
    this.setState({dataExportTransformationOption: parseInt(e.target.value), coordinateSystemsLoading: e.target.value == 2 && this.coordinateSystems == null});
    set("dataExportTransformationOption", parseInt(e.target.value));
    if(e.target.value == 2 && this.coordinateSystems == null){
      this.getCoordinateSystems();
    } else if(e.target.value == 0) {
      this.coordinateSystems = null;
      this.setState({selectedWKID: "", coordinateSystemsLoading: false, coordinateSystemsLoadSuccess: false});
      del("dataExportCoordinateSystems");
    }
  }
  
  clickExport(){
    this.setState({exportDialogOpen: true});
  }

  handleKeyPress = event => {
    if (event.key == "Enter") {
      this.clickSubmit();
    }
  };

  filterVisitUpdate = event =>{
    this.props.filterUpdate(event.target.value, 'visit', true, true);
    this.setState({filterVisitValue: event.target.value});
    if(event.target.value == "-1"){
      sessionStorage.removeItem("searchStripFilterVisitValue");
      return;
    }
    sessionStorage.setItem("searchStripFilterVisitValue", event.target.value);
  }

  filterAssignedUpdate = event =>{
    this.props.filterUpdate(event.target.value, 'assigned', true, true);
    this.setState({filterAssignedValue : event.target.value});
    if(event.target.value == "-1"){
      sessionStorage.removeItem("searchStripFilterAssignedValue");
      return;
    }
    sessionStorage.setItem("searchStripFilterAssignedValue", event.target.value);
  }

  filterStatusUpdate = event => {
    this.props.filterUpdate(event.target.value, 'status', true, true);
    this.setState({filterStatusValue: event.target.value});
    if(event.target.value == "-1"){
      sessionStorage.removeItem("searchStripFilterStatusValue");
      return;
    }
    sessionStorage.setItem("searchStripFilterStatusValue", event.target.value);
  }

  filterUserUpdate = event => {
    this.props.filterUpdate(event.target.value, 'user', true, true);
    this.setState({filterUserValue: event.target.value});
    if(event.target.value == "-1"){
      sessionStorage.removeItem("searchStripFilterUserValue");
      return;
    }
    sessionStorage.setItem("searchStripFilterUserValue", event.target.value);
  }

  filterDueDateUpdate = event =>{
    this.props.filterUpdate(event.target.value, 'dueDate', true, true);
    this.setState({filterDueDateValue: event.target.value});
    if(event.target.value == "-1"){
      sessionStorage.removeItem("searchStripFilterDueDateValue");
      return;
    }
    sessionStorage.setItem("searchStripFilterDueDateValue", event.target.value);
  }

  filterTicketTypeUpdate = event =>{
    this.props.filterUpdate(event.target.value, 'ticketType', true, true);
    this.setState({filterTicketTypeValue: event.target.value});
    if(event.target.value == "-1"){
      sessionStorage.removeItem("searchStripFilterTicketTypeValue");
      return;
    }
    sessionStorage.setItem("searchStripFilterTicketTypeValue", event.target.value);
  }

  filterTicketAssignmentAreaUpdate = event =>{
    this.props.filterUpdate(event.target.value, 'ticketArea', true, true);
    this.setState({filterTicketAssignmentAreaValue: event.target.value});
    if(event.target.value == "-1"){
      sessionStorage.removeItem("searchStripFilterTicketAssignmentAreaValue");
      return;
    }
    sessionStorage.setItem("searchStripFilterTicketAssignmentAreaValue", event.target.value);
  }

  toggleToDatePicker = () => {
    this.setState({ toPickerOpen: !this.state.toPickerOpen });
  };
  
  toggleFromDatePicker = () => {
    this.setState({ fromPickerOpen: !this.state.fromPickerOpen });
  };

  toggleFromDatePickerDownload = () => {
    this.setState({ fromPickerOpenDownload: !this.state.fromPickerOpenDownload });
  };

  toggleToDatePickerDownload = () => {
    this.setState({ toPickerOpenDownload: !this.state.toPickerOpenDownload });
  };

  toHandleChange = selectedDate => {
    selectedDate.setHours(new Date().getHours())
    selectedDate.setTime(selectedDate.getTime());
    GLOBAL.SEARCH.toDate = selectedDate.toLocaleDateString();
    this.setState({ dateTo: selectedDate.toLocaleDateString(), dateToDownload : selectedDate.toLocaleDateString() });
    this.toggleToDatePicker();
    if(this.props.toHandleChange){
      this.props.toHandleChange(selectedDate);
    }
  };

  toHandleChangeDownload = selectedDate => {
    selectedDate.setHours(new Date().getHours())
    selectedDate.setTime(selectedDate.getTime());
    this.setState({ dateToDownload: selectedDate.toLocaleDateString() });
    this.toggleToDatePickerDownload();
  };

  fromHandleChange = selectedDate => {
    selectedDate.setHours(new Date().getHours())
    selectedDate.setTime(selectedDate.getTime());
    GLOBAL.SEARCH.fromDate = selectedDate.toLocaleDateString();
    this.setState({ dateFrom: selectedDate.toLocaleDateString(), dateFromDownload : selectedDate.toLocaleDateString() });
    this.toggleFromDatePicker();
    if(this.props.fromHandleChange){
      this.props.fromHandleChange(selectedDate);
    }
  };

  fromHandleChangeDownload = selectedDate => {
    selectedDate.setHours(new Date().getHours())
    selectedDate.setTime(selectedDate.getTime());
    this.setState({ dateFromDownload: selectedDate.toLocaleDateString() });
    this.toggleFromDatePickerDownload();
  };

  updateWindowDimensions(){
    this.setState({width : window.innerWidth, height: window.innerHeight});
  }

  componentDidUpdate(previousProps){
    if( previousProps.ticketAssignmentAreas && this.props.ticketAssignmentAreas && (previousProps.ticketAssignmentAreas != this.props.ticketAssignmentAreas)){
      //Copy data instead of just creating reference
      var selectedAreas = JSON.parse(JSON.stringify(this.props.ticketAssignmentAreas));
      this.setState({selectedAreas});
    }
    if( previousProps.CompanyArcGisLayerFeatures && this.props.CompanyArcGisLayerFeatures && (previousProps.CompanyArcGisLayerFeatures != this.props.CompanyArcGisLayerFeatures)){
      //Copy data instead of just creating reference
      var selectedFeatures = JSON.parse(JSON.stringify(this.props.CompanyArcGisLayerFeatures));
      this.setState({selectedFeatures});
    }
    selectedFeatures
  }

   selectTicketArea(area, ticketGroup){

    let selectedAreas = this.state.selectedAreas;
    let selectedFeatures = this.state.selectedFeatures;
    let allAreas = JSON.parse(JSON.stringify(this.props.ticketAssignmentAreas));
    let allFeatures = JSON.parse(JSON.stringify(this.props.CompanyArcGisLayerFeatures));
    if(area == "ticketGroup"){
      //Need to either enable or disable all layers in this group
      //REMOVE
      var ticketAssignmentAreaGroups = this.props.ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == ticketGroup.TicketGroupId);
      if(this.isTicketGroupChecked(ticketGroup)){
        ticketAssignmentAreaGroups.forEach(assignmentAreaGroup =>{
          if(assignmentAreaGroup.AssignmentAreaId){
            var selectedAssignmentIndex = selectedAreas.findIndex(x => x.AssignmentAreaId == assignmentAreaGroup.AssignmentAreaId);
            if(selectedAssignmentIndex != -1) selectedAreas.splice(selectedAssignmentIndex,1)
          }
          else if(assignmentAreaGroup.CompanyArcGisLayerId && assignmentAreaGroup.FeatureId){
            var selectedFeatureIndex = selectedFeatures.findIndex(x => x.CompanyArcGISLayerId == assignmentAreaGroup.CompanyArcGisLayerId && x.FeatureId == assignmentAreaGroup.FeatureId);
            if(selectedFeatureIndex != -1) selectedFeatures.splice(selectedFeatureIndex,1);
          }
        });
      }
      //ADD ALL 
      else{
        ticketAssignmentAreaGroups.forEach(assignmentAreaGroup =>{
          if(assignmentAreaGroup.AssignmentAreaId){
            var selectedAssignmentIndex = selectedAreas.findIndex(x => x.AssignmentAreaId == assignmentAreaGroup.AssignmentAreaId);
            if(selectedAssignmentIndex == -1){
              var foundArea = allAreas.find(x => x.AssignmentAreaId == assignmentAreaGroup.AssignmentAreaId);
              if(foundArea) selectedAreas.push(foundArea);
            }
          }
          else if(assignmentAreaGroup.CompanyArcGisLayerId && assignmentAreaGroup.FeatureId){
            var selectedFeatureIndex = selectedFeatures.findIndex(x => x.CompanyArcGISLayerId == assignmentAreaGroup.CompanyArcGisLayerId && x.FeatureId == assignmentAreaGroup.FeatureId);
            if(selectedFeatureIndex == -1){
              var foundFeature = allFeatures.find(x => x.CompanyArcGISLayerId == assignmentAreaGroup.CompanyArcGisLayerId && x.FeatureId == assignmentAreaGroup.FeatureId);
              if(foundFeature) selectedFeatures.push(foundFeature);
            }
          }
        });
      }
    }
    else if(area == "ANY"){
      //REMOVE
      if(selectedAreas.length == this.props.ticketAssignmentAreas.length){
        selectedAreas = [];
      }
      //ADD ALL
      else{
        selectedAreas = allAreas;
      }

      //REMOVE
      if(selectedFeatures.length == this.props.CompanyArcGisLayerFeatures.length){
        selectedFeatures = [];
      }
      //ADD ALL
      else{
        selectedFeatures = allFeatures;
      }
    }
    else if(area.AssignmentAreaId){
      let foundIndex = selectedAreas.findIndex(x => x.AssignmentAreaId == area.AssignmentAreaId);
      //REMOVE
      if(foundIndex != -1){
        if(area.GroupId){
          let groupAreas = allAreas.filter(x => x.GroupId == area.GroupId);
          groupAreas.forEach(groupArea =>{
            let groupIndex = selectedAreas.findIndex(x => x.AssignmentAreaId == groupArea.AssignmentAreaId);
            if(groupIndex != -1){
              selectedAreas.splice(groupIndex,1);
            }
          });
        }
        else{
          selectedAreas.splice(foundIndex,1);
        }
      }
      //ADD
      else{
        if(area.GroupId){
          let groupAreas = allAreas.filter(x => x.GroupId == area.GroupId);
          selectedAreas = selectedAreas.concat(groupAreas);
        }
        else{
          selectedAreas.push(area);
        }
      }
    }
    else if(area.CompanyArcGISLayerId && area.FeatureId){
      let foundIndex = selectedFeatures.findIndex(x => x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId);
      //remove
      if(foundIndex != -1){
        selectedFeatures.splice(foundIndex, 1);
      }
      //ADD
      else{
        selectedFeatures.push(area);
      }
    }
    this.setState({selectedAreas, selectedFeatures},()=>{
      try{sessionStorage.setItem("searchStripFilterTicketAssignmentAreaValue", JSON.stringify(selectedAreas));}
      catch(e){
        console.log(e);
      }
      this.props.filterUpdate(selectedAreas, 'ticketArea', true, true);
      this.props.filterUpdate(selectedFeatures, 'featureArea', true, true);
    });
  }

  componentDidMount() {
    let selectedAreas = [];
    let selectedFeatures = [];
    if(this.props.ticketAssignmentAreas){
      selectedAreas = this.props.ticketAssignmentAreas;
    }
    if(this.props.CompanyArcGisLayerFeatures){
      selectedFeatures = this.props.CompanyArcGisLayerFeatures;
    }
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions)
    this.getArcGisParameters();
    get("arcGisOnlineToken").then((token)=>{
      if(token){
        let tokenObj = JSON.parse(token);
        if(!tokenObj.expirationDateTimeUTC || tokenObj.refresh_token || tokenObj.userId != auth.userId()){
          del("arcGisOnlineToken");
        } else{
          this.setState({arcGisToken: tokenObj});
          this.validateArcGisToken(tokenObj);
        }
      }
    });

    //del("dataExportCoordinateSystems"); //FOR DEVELOPMENT

    get("dataExportUnit").then((unit=>{
      if (typeof unit !== "undefined") {
        this.setState({dataExportUnit: unit});
      }
    }));

    get("dataExportWkid").then((wkid=>{
      if (typeof wkid !== "undefined") {
        this.setState({selectedWKID: wkid});
      }
    }));

    get("dataExportTransformationOption").then((option=>{
      if (typeof option !== "undefined") {
        this.setState({dataExportTransformationOption: option});
      }
      get("dataExportCoordinateSystems").then((coordinateSystems=>{
        if (typeof coordinateSystems !== "undefined") {
          this.coordinateSystems = JSON.parse(coordinateSystems);
          this.setState({coordinateSystemsLoadSuccess: true});
        }
        else if(option == 2){
          this.setState({dataExportTransformationOption: 0});
          set("dataExportTransformationOption", 0);
        }
      }));
    }));

    get("dataExportTransformationWKIDOption").then((wkidOption=>{
      if (typeof wkidOption !== "undefined") {
        this.setState({dataExportTransformationWKIDOption: wkidOption});
      }
    }));


    let today = new Date();
    today.setDate(today.getDate());
    let d = new Date();
    d.setDate(d.getDate() - this.props.defaultDateRangeDays);
    
    d.setTime(d.getTime() + d.getTimezoneOffset()*60*1000);


    let fromToUse = "";
    let toToUse = "";
    let searchCriteriaToUse = "";
    
    const filterUserValueFromStorage = sessionStorage.getItem("searchStripFilterUserValue");
    const filterDueDateValueFromStorage = sessionStorage.getItem("searchStripFilterDueDateValue");
    const filterTicketTypeValueFromStorage = sessionStorage.getItem("searchStripFilterTicketTypeValue");
    const filterTicketAssignmentAreaValueFromStorage = sessionStorage.getItem("searchStripFilterTicketAssignmentAreaValue")
    const filterStatusValueFromStorage = sessionStorage.getItem("searchStripFilterStatusValue");
    const searchCriteriaValueFromStorage = localStorage.getItem("searchStripSearchTerm");
    const fromDateFromStorage = localStorage.getItem("searchStripFromDateString");
    const toDateFromStorage = localStorage.getItem("searchStripToDateString");
    const filterVisitValueFromStorage = sessionStorage.getItem("searchStripFilterVisitValue");
    const filterAssignedValueFromStorage = sessionStorage.getItem("searchStripFilterAssignedValue")

    toToUse = today.toLocaleDateString();
    fromToUse = today.toLocaleDateString();

    if(searchCriteriaValueFromStorage && searchCriteriaValueFromStorage != "null" && toDateFromStorage != "undefined") searchCriteriaToUse = searchCriteriaValueFromStorage;
    else searchCriteriaToUse = GLOBAL.SEARCH.criteria;

    let filterStatusValue = "-1";
    let filterUserValue = "-1";
    let filterDueDateValue = "-1";
    let filterTicketTypeValue = "-1";
    let filterTicketAssignmentAreaValue = "-1";
    let filterVisitValue = "-1";
    let filterAssignedValue = "-1";

    if(this.props.leakSurveyDownload && filterUserValueFromStorage != null && filterUserValueFromStorage != "null" && toDateFromStorage != "undefined")
    filterUserValue = filterUserValueFromStorage;

    if(this.props.includeDueDateFilter && filterDueDateValueFromStorage != null && filterDueDateValueFromStorage != "null" && filterDueDateValueFromStorage != "undefined")
    filterDueDateValue = filterDueDateValueFromStorage;

    if(this.props.includeTicketTypeFilter && filterTicketTypeValueFromStorage != null && filterTicketTypeValueFromStorage !="null" && filterTicketTypeValueFromStorage != "undefined")
    filterTicketTypeValue = filterTicketTypeValueFromStorage;

    if(this.props.ticketAssignmentAreas && filterTicketAssignmentAreaValueFromStorage != null && filterTicketAssignmentAreaValueFromStorage != "null" && filterTicketAssignmentAreaValueFromStorage != "undefined")
    filterTicketAssignmentAreaValue = filterTicketAssignmentAreaValueFromStorage;

    if(this.props.leakSurveyDownload && filterStatusValueFromStorage != null && filterStatusValueFromStorage != "null" && toDateFromStorage != "undefined")
    filterStatusValue = filterStatusValueFromStorage;

    if(this.props.leakSurveyDownload && filterVisitValueFromStorage!= null && filterVisitValueFromStorage != "null" && toDateFromStorage != "undefined")
    filterVisitValue = filterVisitValueFromStorage;

    if(this.props.leakSurveyDownload && filterAssignedValueFromStorage != null && filterAssignedValueFromStorage != "null" && toDateFromStorage != "undefined")
    filterAssignedValue = filterAssignedValueFromStorage;
    
    this.setState({selectedAreas, selectedFeatures, dateTo: toToUse, dateFrom: fromToUse,dateToDownload: toToUse, dateFromDownload: fromToUse, searchCriteria: searchCriteriaToUse, filterStatusValue: filterStatusValue, filterUserValue: filterUserValue,filterVisitValue, filterAssignedValue, filterDueDateValue, filterTicketTypeValue,filterTicketAssignmentAreaValue});
  }

  componentWillUnmount(){
    if(this.refreshTokenTimeout) clearTimeout(this.refreshTokenTimeout);
  }

  getCoordinateSystems(){
    fetch(process.env.API_URL + "/api/data/GetCoordinateSystemsJson",{
      method : "Get",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      //body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
       r.json().then((coordSystemsJson)=>{
        this.coordinateSystems = JSON.parse(coordSystemsJson);
        this.setState({coordinateSystemsLoading:false, coordinateSystemsLoadSuccess: true});
        set("dataExportCoordinateSystems", coordSystemsJson);
      });
      }
      else{
        this.setState({coordinateSystemsLoading:false, coordinateSystemsLoadSuccess: false, dataExportTransformationOption: 0});
        set("dataExportTransformationOption", 0);
      }
    })
    .catch(error =>{
      this.setState({coordinateSystemsLoading:false, coordinateSystemsLoadSuccess: false, dataExportTransformationOption: 0});
      set("dataExportTransformationOption", 0);
      console.log(error);
    });
  }

  getArcGisParameters(){
    fetch(process.env.API_URL + "/api/arcgis/getArcGisConnectParameters",{
      method : "Post",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify({orgId: new OrgUtility().getOrgId()})
    })
    .then(r =>{
      if(r.status == 200){
       r.json().then((parameters)=>{
        this.setState({arcGisAuthenticationUri: parameters.arcGisRestApiPrefix + "oauth2/authorize/?client_id=" + parameters.clientId + "&redirect_uri=" + parameters.redirectUri + "&expiration=-1&grant_type=code&response_type=code"})
      });
      }
    })
    .catch(error =>{
      console.log(error);
    });
  }

  arcGisPopupWindowResponse(err, res){
    let me = this;
    let currentDateTimeUTC = Date.now();
    res.res.expirationDateTimeUTC = currentDateTimeUTC + (res.res.expires_in * 1000);
    res.res.userId = auth.userId();
    this.setState({arcGisConnected: true, arcGisToken: res.res});
    set('arcGisOnlineToken', JSON.stringify(res.res));
    this.refreshTokenTimeout = setTimeout(()=>{
      me.refreshArcGisToken();
    }, (res.res.expires_in - 120) * 1000);
  }

  validateArcGisToken(token){
    let me = this;
    let body = {
      CompanyId : auth.companyId(),
      Token : token.access_token
    }
    fetch(process.env.API_URL + "/api/arcgis/validateToken",{
      method : "POST",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
      r.json().then((isValid)=>{
        if(isValid){
          this.setState({arcGisConnected: true});
          
          let currentDateTimeUTC = Date.now();
          let msUntilExpiration = token.expirationDateTimeUTC - currentDateTimeUTC;

          if(msUntilExpiration < 300000){
            this.refreshArcGisToken();
          } else{
            this.refreshTokenTimeout = setTimeout(()=>{
              me.refreshArcGisToken();
            }, msUntilExpiration - 120000);
          }
        } else{
          this.refreshArcGisToken();
        }
      });
      }
    })
    .catch(error =>{
      console.log(error);
    });
  }

  refreshArcGisToken(){
    let me = this;
    let body = {
      arcGisUsername : this.state.arcGisToken.username
    }
    fetch(process.env.API_URL + "/api/arcgis/refreshtoken" ,{
      method : "Post",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then((token)=>{
          let currentDateTimeUTC = Date.now();
          token.expirationDateTimeUTC = currentDateTimeUTC + (token.expires_in * 1000);
          token.userId = auth.userId();
          this.setState({arcGisConnected: true, arcGisToken: token});
          this.refreshTokenTimeout = setTimeout(()=>{
            me.refreshArcGisToken();
          }, (token.expires_in - 120) * 1000);
          set('arcGisOnlineToken', JSON.stringify(token));
        });
      } else{
        this.setState({arcGisConnected: false, arcGisToken: null});
        del('arcGisOnlineToken');
      }
    })
    .catch(error =>{
      this.setState({arcGisConnected: false, arcGisToken: null});
      del('arcGisOnlineToken');
      console.log(error);
    });
  }

  handleClose(cancel){
    this.setState({dialogOpen: false});
    // Begin Download
    if(this.props.reportingPage && cancel == false){
      this.props.downloadRequest({searchCriteriaValue: this.searchCriteriaValue.value, dateFrom: this.state.dateFromDownload, dateTo: this.state.dateToDownload});
    }
    else if (this.props.downloadRequest != undefined && cancel == false) this.props.downloadRequest({type: 'leak',searchCriteriaValue: this.searchCriteriaValue.value, dateFrom: this.state.dateFromDownload, dateTo: this.state.dateToDownload});
  }

  checkSuper(){
    let isSuper = false;
    let _SuperAdminRoleId = 0;
    let _SuperViewerRoleId = 11;
    var roles = localStorage.getItem("roles");
    if(roles != null) { roles.split(',').forEach(element => { if (element == _SuperAdminRoleId || element == _SuperViewerRoleId) { isSuper = true;}});}
    return isSuper;
  }

  disableClick(e, type){
    let  visibility = false;
    if (e.target.classList.contains('countBlockDisabled')){
      visibility = true;
      if(!this.props.fromMap || type == "Inaccessible" || type =="Meter" || type == "survey" || type == "invest" || type =="MeterInside"){
        e.target.classList.remove('countBlockDisabled');
      }
    }
    else{
      visibility = false;
      if(!this.props.fromMap || type == "Inaccessible" || type =="Meter" || type == "survey" || type == "invest" || type =="MeterInside"){
        e.target.classList.add('countBlockDisabled');
      }
    }
    this.props.disableType(type, visibility);
  }

  showAdvancedSearchDialog(){
    let tagFormSearchOptions = this.state.tagFormSearchOptions ? this.state.tagFormSearchOptions : this.props.tagFormSearchOptions;
    this.setState({showAdvancedSearchDialog: true, tagFormSearchOptions});
  }

  closeAdvancedSearchDialog(){
    this.setState({showAdvancedSearchDialog: false});
  }

  resetAdvancedSearchSettings(){
    let tagFormSearchOptions = this.state.tagFormSearchOptions;
    for(var i = 0; i < tagFormSearchOptions.length; i++) tagFormSearchOptions[i].filterEnabled = false;
    this.setState({tagFormSearchOptions, advancedSearchTagsFilterApplied: false});
  }

  toggleTagFilterOption(index){
    let tagFormSearchOptions = this.state.tagFormSearchOptions;
    tagFormSearchOptions[index].filterEnabled = !tagFormSearchOptions[index].filterEnabled;
    let advancedSearchTagsFilterApplied = tagFormSearchOptions.findIndex(x=>x.filterEnabled) != -1;
    this.setState({tagFormSearchOptions, advancedSearchTagsFilterApplied});
  }

  isTicketGroupChecked(group){
    var isChecked = false;
    var ticketAssignmentAreaGroups = this.props.ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == group.TicketGroupId);
    let foundAreas = [];
    let foundFeatures = [];
    var selectedGroupAssignments = [];
    var selectedGroupFeatures = [];
    ticketAssignmentAreaGroups.forEach(assignmentAreaGroup =>{
      if(assignmentAreaGroup.AssignmentAreaId){
        if(this.props.ticketAssignmentAreas.findIndex(x => x.AssignmentAreaId == assignmentAreaGroup.AssignmentAreaId) != -1){
          foundAreas.push(assignmentAreaGroup);
        }
        var selectedAssignment = this.state.selectedAreas.find(x => x.AssignmentAreaId == assignmentAreaGroup.AssignmentAreaId);
        if(selectedAssignment) selectedGroupAssignments.push(selectedAssignment);
      }
      else if(assignmentAreaGroup.CompanyArcGisLayerId && assignmentAreaGroup.FeatureId){
        if(this.props.CompanyArcGisLayerFeatures.findIndex(x => x.CompanyArcGISLayerId == assignmentAreaGroup.CompanyArcGisLayerId && x.FeatureId == assignmentAreaGroup.FeatureId) != -1){
          foundFeatures.push(assignmentAreaGroup);
        }
        var selectedFeature = this.state.selectedFeatures.find(x => x.CompanyArcGISLayerId == assignmentAreaGroup.CompanyArcGisLayerId && x.FeatureId == assignmentAreaGroup.FeatureId);
        if(selectedFeature) selectedGroupFeatures.push(selectedFeature);
      }
    });
    isChecked = (foundAreas.length + foundFeatures.length) == (selectedGroupAssignments.length + selectedGroupFeatures.length);
    return isChecked;
  }

  getAllOffspring(ticketGroup){
    let idList = [];
    idList.push(ticketGroup.TicketGroupId);
    if(ticketGroup.children){
      ticketGroup.children.forEach(child =>{
        idList = idList.concat(this.getAllOffspring(child));
      });
    }
    return idList;
  }

  areaInOffspring(group, area){
    let areaIsInOffspring = false;
    let offspring = this.getAllOffspring(group);
    offspring.forEach(oId =>{
      if(oId != group.TicketGroupId && this.props.ticketAssignmentAreaGroups.findIndex(x => x.TicketGroupId == oId && (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : (x.CompanyArcGisLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId))) != -1){
        areaIsInOffspring = true;
      }
    })
    return areaIsInOffspring;
  }

  areaInUsersAreas(area){
    if(area.AssignmentAreaId){
      return this.props.ticketAssignmentAreas.findIndex(x => x.AssignmentAreaId == area.AssignmentAreaId) != -1
    }
    else if(this.props.CompanyArcGisLayerFeatures){
      return this.props.CompanyArcGisLayerFeatures.findIndex(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId) != -1
    }
    return false;
  }

  hasArea(ticketGroup){
    let hasArea = false;
    if(this.props.ticketAssignmentAreaGroups){
      let ticketGroups = this.props.ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == ticketGroup.TicketGroupId);
      ticketGroups.forEach(group =>{
        if(this.props.ticketAssignmentAreas && group.AssignmentAreaId){
          if(this.props.ticketAssignmentAreas.findIndex(x => x.AssignmentAreaId == group.AssignmentAreaId) != -1){
            hasArea = true;
          }
        }
        else if(this.props.CompanyArcGisLayerFeatures){
          if(this.props.CompanyArcGisLayerFeatures.findIndex(x => x.CompanyArcGISLayerId == group.CompanyArcGisLayerId && x.FeatureId == group.FeatureId) != -1){
            hasArea = true;
          }
        }
      });
    }
    return hasArea;
  }

  

  renderTicketGroup(groups, isChild){
    return (
      <ul className={isChild ? "" : ""} style={{paddingInlineStart : 15, marginLeft : isChild ? 0 : 15}}>
        {groups.map((group, index) =>
          (!group.DeletedDate && (group.isRoot || isChild) && this.hasArea(group)) &&
          <li  key={index} style={{marginTop : 0}}>
            <div  className={ group.children ? "itemLine" : group.isRoot ? "row itemLine" : "itemLine"} style={{display : "flex", flexDirection : 'row'}}>
              <input checked={this.isTicketGroupChecked(group)} type="checkbox" onChange={()=>{this.selectTicketArea("ticketGroup",group);}}/>
              <label style={{marginLeft : 5, cursor : "pointer", position : "relative", top : 3, fontWeight:"bold"}} onClick={() =>{this.selectTicketArea("ticketGroup",group); }} className="">{group.GroupLabel}</label>
              {(group.children || (this.props.ticketAssignmentAreaGroups && this.props.ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == group.TicketGroupId).length > 0))? (group.expanded ? <KeyboardArrowDownIcon className="arrowIcon  dark caret" onClick={()=>{this.props.expandTicketGroup(group)}}/> : <KeyboardArrowRightIcon className="arrowIcon dark caret" onClick={()=>{this.props.expandTicketGroup(group)}}/>) : <div style={{minWidth : 25}}/>}
            </div>
            {((this.props.ticketAssignmentAreaGroups && this.props.ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == group.TicketGroupId).length > 0) && group.expanded)&& 
              <ul style={{paddingInlineStart : 15}}> {this.props.ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == group.TicketGroupId).map((area, ticketIndex) => (
                (!this.areaInOffspring(group, area) && this.areaInUsersAreas(area)) && <li className="itemLine" key={ticketIndex}>
                  <input checked={area.AssignmentAreaId ? this.state.selectedAreas.findIndex(x => x.AssignmentAreaId == area.AssignmentAreaId) != -1 : this.state.selectedFeatures.find(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId) } onChange={() =>{this.selectTicketArea(area.AssignmentAreaId ? this.props.ticketAssignmentAreas.find(x => x.AssignmentAreaId == area.AssignmentAreaId) : this.props.CompanyArcGisLayerFeatures.find(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId));}} type="checkbox"/>
                  <label style={{marginLeft : 5, cursor : "pointer"}} onClick={() =>{this.selectTicketArea(area.AssignmentAreaId ? this.props.ticketAssignmentAreas.find(x => x.AssignmentAreaId == area.AssignmentAreaId) : this.props.CompanyArcGisLayerFeatures.find(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId));}} className="">
                    {area.AssignmentAreaId ? 
                    (this.props.ticketAssignmentAreas && this.props.ticketAssignmentAreas.find(x => x.AssignmentAreaId == area.AssignmentAreaId)) ? this.props.ticketAssignmentAreas.find( x => x.AssignmentAreaId == area.AssignmentAreaId).Label : ""
                    :
                    (this.props.CompanyArcGisLayerFeatures && this.props.CompanyArcGisLayerFeatures.find(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId)) ? this.props.CompanyArcGisLayerFeatures.find( x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId).FeatureName : ""}</label>
                </li>
              ))}</ul>
            }
            {group.children && group.expanded && this.renderTicketGroup(group.children, true)}
          </li>
        )}
      </ul>
    )
  }

  renderTreeFilter(groups, isChild){
    return (
      <ul className={isChild ? "dataGroupUL childDataGroupFilter" : "dataGroupUL parentDataGroup"}>
        {groups.map((group, index) =>
          (!group.DeletedDate && (group.isRoot || isChild)) &&
          <li key={index} style={{marginTop : 15}}>
            <div className={ group.children ? "" : group.isRoot ? "row " : ""} style={{display : "flex", flexDirection : 'row'}}>
              {group.children ? (group.expanded ?<KeyboardArrowDownIcon className="buttonIcon dark large caret" onClick={()=>{this.props.expandGroup(group)}}/> : <KeyboardArrowRightIcon className="buttonIcon dark large caret" onClick={()=>{this.props.expandGroup(group)}}/>) 
              : <div style={{minWidth : 25}}/> }
              <label onClick={() =>{ this.props.toggleDataGroup(group.DataGroupId,!group.showData)}} className="accountHeader dataGroupLabel notificationRowControl dataGroupFilterFormName">{group.GroupLabel}</label>
              <div className="formFilterButtonsDiv" onClick={() =>{this.props.toggleDataGroup(group.DataGroupId,!group.showData)}}>
                <Button className={!group.showData ? "buttonAction btn btn-primary dehighlighted formFilterButton" : 'formFilterButton buttonAction btn btn-primary'}>
                  Show
                </Button>
                <Button className={!group.showData ? "buttonAction btn btn-primary formFilterButton" : 'formFilterButton buttonAction btn btn-primary dehighlighted'}>
                  Hide
                </Button>
              </div>
            </div>
            {group.children && group.expanded && this.renderTreeFilter(group.children, true)}
          </li>
        )}
      </ul>
    )
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="p-0 pageBar" >
      {this.state.toPickerOpen || this.state.fromPickerOpen && <div  style={{zIndex : 9999999999, position : 'absolute', left : 0,height:58, right:this.state.width >=1400 ? 415:160, top: this.state.width >=1400 ?((this.state.height/2) + 96) : ((this.state.height/2) + 49), marginRight : 'auto',marginLeft : 'auto', width : 150,cursor : 'pointer'}}>
              <input id="useCreatedDateButton" style={{marginLeft: 5, height:10}} type = "radio"checked={!this.props.useModifiedDate} onChange={() =>{this.props.updateUseModifiedDate(false)}}/>
              <label htmlFor="useCreatedDateButton" style={{marginLeft: 5, height:10}}>Created Date</label>
              <br/>
              <input id="useModifiedDateButton" style={{marginLeft: 5, height:10}} type = "radio"  checked={this.props.useModifiedDate }onChange={() =>{this.props.updateUseModifiedDate(true)}}/>
              <label htmlFor="useModifiedDateButton" style={{marginLeft: 5, height:10}}>Modified Date</label>
              </div>}
        <div className=" m-0 p-0 barHeaderDiv">
          {this.props.resultsCount == -1 ? 
            (<label className="labelHeader" style={{ marginTop: 0, marginLeft: 0 }}>{this.props.headerLabel != undefined ? this.props.headerLabel : this.state.headerLabel}</label> ) : this.props.resultsCount == 0 ? 
            ( <label className="labelHeader"  style={{ marginTop: 0, marginLeft: 0 }}> No Results </label> ) : 
            ( <div className="searchResultHeader">
                
                {(this.props.surveyCount != undefined && this.props.surveyCount > 0) && 
                  <div className="searchResultHeaderCountBlock">
                {this.props.surveyCount > -1 && <div id="surveyCount" className="surveyCountBlock countBlock" onClick={(e) => {this.disableClick(e,'survey')}} >{this.props.surveyCount}</div>}
                {this.props.surveyCount > -1 && <label className="labelHeaderSm labelCountBlock" > {this.props.hasLicense && this.props.hasLicense("ticket") ? "ticket" : "survey"} </label> }
                </div>}
                
                {(this.props.incidentCount != undefined && this.props.incidentCount > 0) && 
                  <div className="searchResultHeaderCountBlock">
                {this.props.incidentCount > -1 && <div id="investigationCount" className="incidentCountBlock countBlock" onClick={(e) => {this.disableClick(e,'invest')}}>{this.props.incidentCount}</div>}
                {this.props.incidentCount > -1 && <label className="labelHeaderSm labelCountBlock" > {"invest"}  </label>}
                </div>}

                {(this.props.gpsCount != undefined && this.props.gpsCount > 0) && 
                  <div className="searchResultHeaderCountBlock">
                {this.props.gpsCount > -1 && <div className="gpsCountBlock countBlock" onClick={(e) => {this.disableClick(e,'gps')}}> {this.props.gpsCount}</div>}
                {this.props.gpsCount > -1 && <label className="labelHeaderSm labelCountBlock" > {"GPS"} </label>}
                </div>}

                {(this.props.leakCount != undefined && this.props.leakCount > 0) && 
                  <div className="searchResultHeaderCountBlock">
                {this.props.leakCount > -1 && <div className="leakCountBlock countBlock" id="leakBlock" onClick={(e) => {this.disableClick(e,'Leak')}} >{this.props.leakCount}</div>}
                {this.props.leakCount > -1 && <label className="labelHeaderSm labelCountBlock" > {"GIS"}  </label>}
                </div>}
                {(this.props.aocCount != undefined && this.props.aocCount > 0) && 
                  <div className="searchResultHeaderCountBlock">
                {this.props.aocCount > -1 && <div className="aocCountBlock countBlock" id="aocBlock" onClick={(e) => {this.disableClick(e,'AOC')}}>{this.props.aocCount}</div>}
                {this.props.aocCount > -1 && <label className="labelHeaderSm labelCountBlock" > {"AOC"}  </label>}
                </div>}
                {(this.props.pipelineCount != undefined && this.props.pipelineCount > 0) && 
                  <div className="searchResultHeaderCountBlock">
                {this.props.pipelineCount > -1 && <div className="pipelineCountBlock countBlock" id="aocBlock" onClick={(e) => {this.disableClick(e,'Survey')}}>{this.props.pipelineCount}</div>}
                {this.props.pipelineCount > -1 && <label className="labelHeaderSm labelCountBlock" > {"Survey"}  </label>}
                </div>}
                {(this.props.inaccessibleCount != undefined && this.props.inaccessibleCount > 0) && 
                  <div className="searchResultHeaderCountBlock">
                {this.props.inaccessibleCount > -1 && <div className="inaccessibleCountBlock countBlock" id="inaccessBlock" onClick={(e)=>{this.disableClick(e,'Inaccessible')}}>{this.props.inaccessibleCount}</div>}
                {this.props.inaccessibleCount > -1 && <label className="labelHeaderSm labelCountBlock" > {"Inaccess"}  </label>}
                </div>}
                {(this.props.meterCount != undefined && this.props.meterCount > 0) && 
                  <div className="searchResultHeaderCountBlock">
                {this.props.meterCount  > -1 && <div className="meterCountBlock countBlock" id="meterBlock" onClick={(e) => {this.disableClick(e,'Meter')}}>{this.props.meterCount }</div>}
                {this.props.meterCount  > -1 && <label className="labelHeaderSm labelCountBlock" > {"Outside"}  </label>}
                </div>}
                {(this.props.insideMeterCount != undefined && this.props.insideMeterCount > 0) &&
                  <div className="searchResultHeaderCountBlock">
                    {this.props.insideMeterCount > -1 && <div className="insideMeterCountBlock countBlock" id="meterInsideBlock" onClick={(e) =>{this.disableClick(e,'MeterInside')}}>{this.props.insideMeterCount}</div>}
                    {this.props.insideMeterCount > -1 && <label className="labelHeaderSm labelCountBlock">{"Inside"}</label>}
                </div>}

                {(this.props.safetyCount != undefined && this.props.safetyCount > 0) && 
                  <div className="searchResultHeaderCountBlock">
                {this.props.safetyCount > -1 && <div className="safetyCountBlock countBlock" onClick={(e) => {this.disableClick(e,'SiteSafe')}}>{this.props.safetyCount}</div>}
                {this.props.safetyCount > -1 && <label className="labelHeaderSm labelCountBlock" > {"SiteSafe"}  </label>}
                </div>}
                {(this.props.customForms || this.props.dataGroups || this.props.formProjects)  &&
                  <div className="searchResultHeaderCountBlock">
                    <div className="filterCountBlock countBlock" onClick={() =>{this.setState({showCustomFormsFilter : !this.state.showCustomFormsFilter})}}>
                      <svg className="filterIcon"><path style={{fill: 'white', stroke : '#747373'}} d="m 18 0 h 0 a 0 0 0 0 0 -5 8 l 0 10 h -6 l 0 -10 a 0 0 0 0 0 -5 -8 z "/></svg></div> 
                    <label className="labelHeaderSm labelCountBlock">{this.props.customForms || this.props.formProjects ? "FILTER" : "GROUPS"}</label>
                  </div>
                }
                {/* { this.props.dataGroups && 
                  <div className="searchResultHeaderCountBlock">
                    <div className="filterCountBlock countBlock" onClick={() =>{this.setState({showTreeFilter : !this.state.showTreeFilter})}}>
                      <svg className="filterIcon"><path style={{fill: 'white', stroke : '#747373'}} d="m 18 0 h 0 a 0 0 0 0 0 -5 8 l 0 10 h -6 l 0 -10 a 0 0 0 0 0 -5 -8 z "/></svg></div> 
                    <label className="labelHeaderSm labelCountBlock">{"GROUPS"}</label>
                  </div>
                } */}
              </div>
            )
          }
        </div>

        {this.state.showCustomFormsFilter && this.props.dataGroups && this.props.dataGroups.length > 0 &&
          <div className="formFilterDiv" style={{position : 'absolute', top : 55, left : (this.props.customForms && this.props.customForms.length > 0) ? 440 : 20, zIndex : 99999}}>
            <div className="row formFilterRow" style={{position : "relative", maxHeight : 32, top : 0}}>
              <div className="filterHeaderName"><div style={{paddingTop:5, paddingRight:5, paddingLeft:2}}>Data Groups</div>
                <Button onClick={()=>{this.setState({showCustomFormsFilter : !this.state.showCustomFormsFilter})}} title="Close" className="buttonAction buttonFilter formClose" style={{height:'41px !important'}}>
                  <HighlightOffTwoToneIcon className="buttonIcon noLabel" style={{color:'#efeff6'}}/>
                </Button>
              </div>
            </div>
            <div className="container formFilterContainer dataGroupContainer">
              {this.renderTreeFilter(this.props.dataGroups, false)}
            </div>
          </div>
        }

        {(this.state.showCustomFormsFilter && this.props.customForms && this.props.customForms.length > 0) && 
          <div className="formFilterDiv">
            <div className="row formFilterRow">
              <div className="filterHeaderName"><div style={{paddingTop:5, paddingLeft:2}}>Forms</div>
                <Button onClick={()=>{this.setState({showCustomFormsFilter : !this.state.showCustomFormsFilter})}} title="Close" className="buttonAction buttonFilter formClose" style={{height:'41px !important'}}>
                  <HighlightOffTwoToneIcon className="buttonIcon noLabel" style={{color:'#efeff6'}}/>
                </Button>
              </div>
            </div>
            <div className="formFilterDivInside">
              <div className ="container formFilterContainer">
                {this.props.customForms.map((form,index) =>(
                  <div key={index} className="row formFilterRow">
                    <div className="formFilterCountBlock">
                      <div className="countBlock filterRecordCount" style={{borderColor: form.color && form.color != '' ? form.color : '#3b5165'}}>
                        {form.count}
                      </div>
                    </div>
                    <div className ="formFilterFormName" onClick={() =>{this.props.disableType( form.FormId )}}>
                      {form.formName}
                    </div>
                    
                    <div className="formFilterButtonsDiv" onClick={() =>{this.props.disableType( form.FormId )}}>
                      <Button className={form.off ? "buttonAction btn btn-primary dehighlighted formFilterButton" : 'formFilterButton buttonAction btn btn-primary'}>
                        Show
                      </Button>
                      <Button className={form.off ? "buttonAction btn btn-primary formFilterButton" : 'formFilterButton buttonAction btn btn-primary dehighlighted'}>
                        Hide
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>}
        {(this.state.showCustomFormsFilter && this.props.formProjects && this.props.formProjects.length > 0) && 
          <div className="formFilterDiv" style={{position : 'absolute', top : 55, left : ((this.props.dataGroups && this.props.dataGroups.length > 0) && (this.props.customForms && this.props.customForms.length > 0)) ? 790 : ((!this.props.dataGroups || this.props.dataGroups.length == 0) && (this.props.customForms && this.props.customForms.length > 0) )? 440 : ((this.props.dataGroups && this.props.dataGroups.length > 0) && (!this.props.customForms || this.props.customForms.length == 0) ) ? 390 : 20, zIndex : 99999}}>
            <div className="row formFilterRow">
              <div className="filterHeaderName"><div style={{paddingTop:5, paddingLeft: 2}}>Projects</div>
                <Button onClick={() =>{this.setState({showCustomFormsFilter : !this.state.showCustomFormsFilter})}} title="Close" className="buttonAction buttonFilter formClose" style={{height: '41px !important'}}>
                  <HighlightOffTwoToneIcon className="buttonIcon noLabel" style={{color: '#efeff6'}}/>
                </Button>
              </div>
            </div>
            <div className="formFilterDivInside">
              <div className="container formFilterContainer">
                {this.props.formProjects.map((project, index) =>(
                  <div key={index} className="row formFilterRow">
                    <div className="formFilterFormName" onClick={() => {this.props.toggleProject(project.ProjectLabel, !project.showData)}}> 
                      {project.ProjectLabel}
                    </div>

                    <div className="formFilterButtonsDiv" onClick={() => {this.props.toggleProject(project.ProjectLabel, !project.showData)}}>
                      <Button className={project.showData ? "buttonAction btn btn-primary formFilterButton" : "formFilterButton buttonAction btn btn-primary dehighlighted"}>
                        show
                      </Button>
                      <Button className={project.showData ? "buttonAction btn btn-primary dehighlighted formFilterButton" : "formFilterButton buttonAction btn btn-primary"}>
                        Hide
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>}
        <div className="searchInputDiv" style={{display:"flex", alignItems:"center", maxWidth : (this.props.leakSurveyDownload ||( this.props.reportingPage && this.props.leakCount > 0))? 300 : 400}}>
          <input
            id="searchCriteria" placeholder="Search"
            type="text"
            ref={x => (this.searchCriteriaValue = x)}
            onKeyPress={this.handleKeyPress}
            defaultValue={this.state.searchCriteria}
            onChange={(e) => {this.props.updateSearchValue ? this.props.updateSearchValue(e) : null}}
            className="form-control searchInput"
          />
            {!this.props.searching && <Button className="buttonAction btn btn-primary buttonSearch" onClick={() => this.clickSubmit()}  >
              <SearchSharpIcon className="buttonIcon light large"/>
              {/* <label className="buttonText withIcon" style={{ margin: 0, cursor: "pointer" }}>SEARCH</label> */}
            </Button>}
        </div>
        {(this.props.showAdvancedSearchButton && ((this.props.tagFormSearchOptions != null && this.props.tagFormSearchOptions.length > 0) || this.props.loadingTagFormSearchOptions)) &&
          <Button disabled={this.props.loadingTagFormSearchOptions} className="buttonAction btn-primary buttonAdvancedSearchOptions" style={{marginLeft: -4}} onClick={() => this.showAdvancedSearchDialog()}>
            {this.props.loadingTagFormSearchOptions ?
              <div style={{paddingBottom: 5, paddingLeft: 3}}>
                <RingLoader color={"white"} loading={true} size={20}/>
              </div>
            :
              <div style={{paddingBottom: 5, paddingLeft: 3}}>
                <SettingsTwoTone fontSize="medium" style={{color: "#b3bec8e0", marginBottom: -5}}/>
                {this.state.advancedSearchTagsFilterApplied ?
                  <ToggleOnTwoTone fontSize="medium" style={{color: "#15BF15", marginTop: -9}}/>
                :
                  <ToggleOffTwoTone fontSize="medium" style={{color: "#b3bec8e0", marginTop: -9}}/>
                }
              </div>
            }
          </Button>
        }

        <div className="searchDivButtons">
            {(this.props.leakSurveyDownload ||( this.props.reportingPage) || this.props.dataMap || this.props.gpsMap) && <select id="filterStatus" type="text" ref={x => (this.filterStatusValue = x)}
            onChange={this.filterStatusUpdate} className="form-control filterInput short" value={this.state.filterStatusValue}>
              <option value='-1' defaultValue>Any Status</option>
              <option value='Open'>Open</option>
              <option value='Closed'>Closed</option>
              {this.props.includeTicketTypeFilter && <option value='Project'>Project</option>}
              {this.props.includeTicketTypeFilter && <option value='Canceled'>Canceled</option>}
            </select> } 

            {(this.props.leakSurveyDownload || (this.props.reportingPage || this.props.gpsMap)) &&
              <select id="filterAssigned" type="text" ref={x => this.filterAssignedValue = x}
                onChange={this.filterAssignedUpdate} className="form-control filterInput" value={this.state.filterAssignedValue} style={{maxWidth:150}}>
                  <option value="-1" defaultValue>Any Assignment</option>
                  <option value="Assigned">Assigned</option>
                  <option value="Unassigned">Unassigned</option>
              </select>
            }

            {(this.props.leakSurveyDownload ||( this.props.reportingPage && this.props.leakCount > 0)) && 
            <select id="filterVisit" type="text" ref={x => (this.filterVisitValue = x)}
              onChange={this.filterVisitUpdate} className="form-control filterInput" value={this.state.filterVisitValue}>
              <option value='-1' defaultValue>Any Visit</option>
              <option value='Not Visited'>Not Visited</option>
              <option value='Visited 1 time'>Visited 1 time</option>
              <option value='Visited 2 times'>Visited 2 times</option>
              <option value='Visited 3+ times'>Visited 3+ times</option>
            </select> } 

            {(this.props.leakSurveyDownload || this.props.dataMap || this.props.reportingPage || this.props.gpsMap) && <select id="filterUser" type="text" ref={x => (this.filterUserValue = x)}
            onChange={this.filterUserUpdate} className="form-control filterInput" value={this.state.filterUserValue}><option value='-1' defaultValue>* Any User</option>
            {this.props.reportingPage && this.props.userOptions.map((option,index) =>(<option key={index} value={option.UserId}>{option.DisplayNameProper}</option>))}</select> } 

            {this.props.includeDueDateFilter && <select id="filterDueDate" type="text" style={{width:125}} ref={ x=> (this.filterDueDateValue = x)}
            onChange={this.filterDueDateUpdate} className="form-control filterInput" value={this.state.filterDueDateValue}>
              <option value="-1" defaultValue>Any Due Date</option>
              <option value="PastDue">Past Due</option>
              <option value="Today">Today</option>
              <option value="1-Day">1-Day</option>
              <option value="2-Days">2-Days</option>
            </select>}

            {this.props.includeTicketTypeFilter && <select id="filterTicketType" type="text" ref={ x=> (this.filterTicketTypeValue = x)}
            onChange={this.filterTicketTypeUpdate} className="form-control filterInput" value={this.state.filterTicketTypeValue}>
              <option value="-1" defaultValue>Any Type</option>
              <option value="Normal">Normal</option>
              <option value="Emergency">Emergency</option>
            </select>}
            {((this.props.ticketAssignmentAreas && this.props.ticketAssignmentAreas.length > 0) || (this.props.CompanyArcGisLayerFeatures && this.props.CompanyArcGisLayerFeatures.length > 0)) && 
            
              <div id={"assignmentAreasList"} className="dropdown-check-list-searchstrip">
                <span className="anchor itemsFont" style={{maxWidth:150, minHeight: 41}} onClick={() =>{
                  let checkList = document.getElementById("assignmentAreasList");
                  if(checkList.classList.contains("visible"))checkList.classList.remove("visible");
                  else checkList.classList.add("visible");
                  }}><span style={{top: 11, position: 'absolute', color:'#7b7b7b'}}>Ticket Areas</span>
                </span>
                <ul className="items itemsFont" style={{width:277, maxHeight:500}}>
                  <li style={{fontWeight: 700, color: '#16548e'}} className="itemLine">
                  <input checked={(this.props.ticketAssignmentAreas) && (this.props.ticketAssignmentAreas.length == this.state.selectedAreas.length && this.props.CompanyArcGisLayerFeatures.length == this.state.selectedFeatures.length)} onChange={() =>{this.selectTicketArea("ANY");}} type="checkbox"/>
                  <div style={{maringLeft : 5, display: "inline", cursor : "pointer"}} onClick={() =>{this.selectTicketArea("ANY");}}> All Areas</div>
                  </li>

                  {(this.props.ticketGroups) && <li>{this.renderTicketGroup(this.props.ticketGroups, false)}</li>}
                  {/**Show areas not in a group as normal. */}
                  {(this.props.ticketAssignmentAreas) &&this.props.ticketAssignmentAreas.map((area, i) => (!area.HideFromDropDown && (!this.props.ticketGroups || (!this.props.ticketAssignmentAreaGroups.find(x => x.AssignmentAreaId == area.AssignmentAreaId)))) && 
                   <li key={i} style={{}} className="itemLine">
                    <input checked={this.state.selectedAreas.find(x => x.AssignmentAreaId == area.AssignmentAreaId)} onChange={() =>{this.selectTicketArea(area);}} type="checkbox"/>
                    <div style={{maringLeft : 5, display: "inline", cursor : "pointer"}} onClick={() =>{this.selectTicketArea(area);}}> {area.Label}</div>
                  </li>)}
                  {(this.props.CompanyArcGisLayerFeatures) && this.props.CompanyArcGisLayerFeatures.map((area , i) => ( ((!this.props.ticketGroups) || (!this.props.ticketAssignmentAreaGroups.find(x => x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId))) &&
                  <li key={i}>
                    <input checked={this.state.selectedFeatures.find(x => x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId)} onChange={() =>{this.selectTicketArea(area);}} type="checkbox"/>
                    <div style={{maringLeft : 5, display: "inline", cursor : "pointer"}} onClick={() =>{this.selectTicketArea(area);}}> {area.FeatureName}</div>
                  </li>))}
                </ul>
              </div>

             }
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.toggleFromDatePicker}  >
              <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
              <label className="buttonText withIcon small" id="FromDate" style={{ margin: 0, cursor: "pointer" }}>
                {this.state.dateFrom}
              </label>
            </Button>
            <label className="labelSubHeader4 labelSearchBar" style={{height:40}} >-</label>
            {this.state.fromPickerOpen && ( <DatePicker value={this.state.dateFrom} onChange={this.fromHandleChange} onClose={ () => {this.setState({fromPickerOpen : false})}} open={this.state.fromPickerOpen} />)}
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.toggleToDatePicker}  >
              <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
              <label className="buttonText withIcon small" id="ToDate" style={{ margin: 0, cursor: "pointer" }}>
                {this.state.dateTo}
              </label>
            </Button>
            
            { this.state.toPickerOpen && ( <DatePicker value={this.state.dateTo} onChange={this.toHandleChange} onClose={ () => {this.setState({toPickerOpen : false})}} open={this.state.toPickerOpen} /> )}
            
            
            { this.props.mapDownload &&
              <Button style={{width: 150}} className="buttonAction btn btn-primary btn-small" onClick={() => this.clickExport()}>
                <label className="buttonText withIcon small" style={{ margin: 0, cursor: "pointer" }}>Export</label> 
              </Button>
            }

            { this.props.leakSurveyDownload &&
              <Button data-toggle="tooltip" title="Download Data" className="buttonAction noLabel btn btn-primary btn-small" onClick={() => this.clickDownload('leak')}>
                <GetApp className="buttonIcon light"/>
              </Button>
            }

            { this.props.surveyMapDownload &&
              <Button data-toggle="tooltip" title="Download Data" className="buttonAction btn btn-primary btn-small" onClick={() => this.clickDownload('surveyMap')}>
                <GetApp className="buttonIcon light" style={{paddingLeft:2}}/>
              </Button>
            }

            {
              this.props.reportingPage && 
              <Button data-toggle="tooltip" title="Download Data" className="buttonAction noLabel btn btn-primary btn-small" onClick={() =>{this.clickDownload('batch')}}>
                <GetApp className="buttonIcon light"/>
              </Button>
            }
          </div>
      
        <Dialog open={this.state.dialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"Data Export Request"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              It could take several minutes to collect your data, you will be emailed a download link when it is ready.  (est. 2-5 mins)
            </DialogContentText>
            <FormControlLabel
              control={<Checkbox checked={this.props.includePhotos} color="primary" onChange={(event)=>{this.props.updateIncludePhotos(event.target.checked)}}/>}
              label="Include Photos"
            />
            { !this.props.surveyMapDownload && this.state.hasLeakSurveyLicense && <FormControlLabel
              control={<Checkbox checked={this.props.includeOpen} color="primary" onChange={(event)=>{this.props.updateIncludeOpen(event.target.checked)}}/>}
              label="Open GIS Surveys"
            />}
            { !this.props.surveyMapDownload && this.state.hasLeakSurveyLicense && <FormControlLabel
              control={<Checkbox checked={this.props.includeClosed} color="primary" onChange={(event)=>{this.props.updateIncludeClosed(event.target.checked)}}/>}
              label="Closed GIS Surveys"
            />}
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.toggleFromDatePickerDownload}  style={{display : 'inline-block'}}>
              <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
              <label className="buttonText withIcon small" id="FromDate" style={{ margin: 0, cursor: "pointer" }}>
                {this.state.dateFromDownload}
              </label>
            </Button>
            <label className="labelSubHeader4 labelSearchBar" style={{height:40}}>-</label>
            {this.state.fromPickerOpenDownload && ( <DatePicker value={this.state.dateFromDownload} onChange={this.fromHandleChangeDownload} onClose={ () => {this.setState({fromPickerOpenDownload : false})}} open={this.state.fromPickerOpenDownload} />)}
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.toggleToDatePickerDownload}  style={{display : 'inline-block'}}>
              <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
              <label className="buttonText withIcon small" id="ToDate" style={{ margin: 0, cursor: "pointer" }}>
                {this.state.dateToDownload}
              </label>
            </Button>
            
            { this.state.toPickerOpenDownload && ( <DatePicker value={this.state.dateToDownload} onChange={this.toHandleChangeDownload} onClose={ () => {this.setState({toPickerOpenDownload : false})}} open={this.state.toPickerOpenDownload} /> )}
          </DialogContent>
          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={() => this.handleClose(true)}>
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Cancel</label>
            </Button>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={() => this.handleClose(false)}>
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Accept</label>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.exportDialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"Export"}</DialogTitle>
          <DialogContent  style={{backgroundColor: "#e9e9e9", width: 400}} >
            <div className="row">
              <div className="col-sm-6">
                <label className="dataExportSelectLabel">Export Unit:</label>
            
                <select id={"DataExportUnitSelect"} className = "form-control dataExportSelectControl" 
                    value = {this.state.dataExportUnit} 
                    onChange ={ e => { this.updateDataExportUnit(e) }} 
                    style={{width: 150}}
                    >
                      <option value={0}>System (Default)</option>
                      <option value={1}>Imperial (Feet)</option>
                      <option value={2}>Metric (Meters)</option>
                </select>
                { this.props.mapDownload &&
                  <Button className="buttonAction btn btn-primary" style={{width: 150, marginBottom: 5}} onClick={() => this.clickDownload('csv')}>
                    <label className="buttonText withIcon small" style={{ margin: 0, cursor: "pointer" }}>Excel - CSV</label> 
                  </Button>
                }

                { this.props.mapDownload &&
                  <Button className="buttonAction btn btn-primary" style={{width: 150, marginBottom: 5}} onClick={() => this.clickDownload('kml')}>
                    <label className="buttonText withIcon small" style={{ margin: 0, cursor: "pointer" }}>Google Earth - KML</label>
                  </Button>
                }

                { this.props.mapDownload &&
                  <Button className="buttonAction btn btn-primary" style={{width: 150, marginBottom: 5}} onClick={() => this.clickDownload('shp')}>
                    <label className="buttonText withIcon small" style={{ margin: 0, cursor: "pointer" }}>ArcGIS - SHP</label>
                  </Button>
                }

                { this.props.mapDownload &&
                  <Button className="buttonAction btn btn-primary" style={{width: 150, marginBottom: 5}} onClick={() => this.clickDownload('dxf')}>
                    <label className="buttonText withIcon small" style={{ margin: 0, cursor: "pointer" }}>CAD - DXF</label>
                  </Button>
                }

                {this.state.arcGisConnected && this.props.mapDownload ? 
                  <Button className="buttonAction btn btn-primary" style={{width: 150, marginBottom: 5}} onClick={() => this.clickPushToArcGIS()}>
                    <label className="buttonText withIcon small" style={{ margin: 0, cursor: "pointer" }}>Push to ArcGIS</label>
                  </Button>
                  : this.props.mapDownload &&
                  <WindowOpener url={this.state.arcGisAuthenticationUri} width={350} height={450} bridge={this.arcGisPopupWindowResponse.bind(this)}>
                    <Button className="buttonAction btn btn-primary" style={{width: 150, marginBottom: 5}} >
                      <label className="buttonText withIcon small">Connect to ArcGIS</label>
                    </Button>
                  </WindowOpener>
                }
              </div>
              <div className="col-sm-6">
                <label className="dataExportSelectLabel">Transformation:</label>
                <select id={"DataExportTransformSelect"} className = "form-control dataExportSelectControl" 
                      value = {this.state.dataExportTransformationOption} 
                      onChange ={ e => { this.updateDataExportTransformation(e) }} 
                      style={{width: 150, color: "#034f8e",}}
                      >
                        <option value={0}>None</option>
                        <option value={1}>UTM (CSV only)</option>
                        <option value={2}>WKID</option>
                </select>
                {this.state.dataExportTransformationOption == 2 &&
                  <div>
                    <label className="dataExportSelectLabel">WKID:</label>
                    {this.state.coordinateSystemsLoadSuccess ?
                      <Autocomplete
                        getItemValue={(item) => item.WKID.toString()}
                        items={this.coordinateSystems}
                        renderItem={(item, isHighlighted) =>
                          <div style={{ background: isHighlighted ? '#1E90FF' : 'white', color: isHighlighted ? 'white' : '#034F8E', paddingRight: 13, paddingLeft: 13 }}>
                            {item.WKID + ": " + item.Name}
                          </div>
                        }
                        shouldItemRender={(item, value) => item.WKID.toString().toLowerCase().indexOf(value.toLowerCase()) > -1 || item.Name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                        value={this.state.selectedWKID}
                        onChange={(e) => {this.setState({selectedWKID: e.target.value.trim()}); set("dataExportWkid", e.target.value.trim());}}
                        onSelect={(val) => {this.setState({selectedWKID: val}); set("dataExportWkid", val);}}
                        inputProps={{
                          className: "form-control dataExportSelectWkidControl",
                          style: {width: 150, paddingLeft: 13}
                        }}
                      />
                    : this.state.coordinateSystemsLoading ?
                        <div className="barLoaderPortal">
                          <BarLoader sizeUnit={"px"} size={200} color={"#095399"} loading={ true} />
                        </div>
                    :
                      null
                    }
                  </div>  
                }
              </div>
            </div>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.toggleFromDatePickerDownload}  style={{display : 'inline-block'}}>
              <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
              <label className="buttonText withIcon small" id="FromDate" style={{ margin: 0, cursor: "pointer" }}>
                {this.state.dateFromDownload}
              </label>
            </Button>
            <label className="labelSubHeader4 labelSearchBar" style={{height:40}}>-</label>
            {this.state.fromPickerOpenDownload && ( <DatePicker value={this.state.dateFromDownload} onChange={this.fromHandleChangeDownload} onClose={ () => {this.setState({fromPickerOpenDownload : false})}} open={this.state.fromPickerOpenDownload} />)}
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.toggleToDatePickerDownload}  style={{display : 'inline-block'}}>
              <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
              <label className="buttonText withIcon small" id="ToDate" style={{ margin: 0, cursor: "pointer" }}>
                {this.state.dateToDownload}
              </label>
            </Button>
            
            { this.state.toPickerOpenDownload && ( <DatePicker value={this.state.dateToDownload} onChange={this.toHandleChangeDownload} onClose={ () => {this.setState({toPickerOpenDownload : false})}} open={this.state.toPickerOpenDownload} /> )}
          </DialogContent>
          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={() => this.setState({exportDialogOpen: false})}>
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Cancel</label>
            </Button>
          </DialogActions>
        </Dialog>
        {(this.props.showAdvancedSearchButton && this.state.tagFormSearchOptions) && 
          <Dialog open={this.state.showAdvancedSearchDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth='xs' fullWidth='false' scroll='paper'>
            <DialogTitle id="alert-dialog-title">{"Advanced Search Settings"}</DialogTitle>
            <DialogContent>
              <div style={{display:'flex', flexDirection:'column', alignItems:'center', textAlign:'center', paddingBottom: 5}}>
                <span style={{fontWeight: 'bolder', textDecoration:'underline', fontSize: 20, paddingTop: 5}}>TAGS</span>
                <span>If tags selected below, search will only return tickets<br/>with one or more of the selected tags attached</span>
              </div>
              {this.state.tagFormSearchOptions.map((tagFormOption, index) => ( 
                <div style={{marginTop: 5, marginBottom: 5, display: 'flex', justifyContent:'center', alignItems: 'center'}} className="col-12" key={index}>
                  <Button style={{verticalAlign:'middle', marginTop: 5, marginBottom: 5, width: 250, right: 0, left: 0,minHeight : 40, height : 'auto'}} className="buttonAction" onClick={()=>{this.toggleTagFilterOption(index)}}>
                    <div style={{width: 20}}>
                      {tagFormOption.filterEnabled ?
                        <RadioButtonCheckedIcon className="tagFilterRadioButton"/>
                        :
                        <RadioButtonUncheckedIcon className="tagFilterRadioButton"/>
                      }
                    </div>
                    <div style={{flex: 1}}>
                      <label className="buttonText withIcon" style={{margin:0, cursor: 'pointer'}}>{tagFormOption.Name}</label>
                    </div>
                  </Button>
                </div>
              ))}
              {this.state.savingNewTagData &&
                  <div style={{marginTop: 15, marginBottom: 15, display: 'flex', justifyContent:'center', alignItems: 'center'}} className="col-12">
                  <BarLoader sizeUnit={"px"} width={250} size={250} color={"#095399"} loading={true}/>
                </div>
              }
              {this.state.savingNewTagDataErrorMessage &&
                <div style={{marginTop: 5, marginBottom: 5, display: 'flex', justifyContent:'center', alignItems: 'center', textAlign:'center'}} className="col-12">
                  <span>{this.state.savingNewTagDataErrorMessage}</span>
                </div>
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={this.resetAdvancedSearchSettings.bind(this)} className="buttonAction btn btn-primary" color="primary">
                <label className="buttonText small" style={{ margin: 0, cursor: 'pointer'}}>Reset</label>
              </Button>
              <Button onClick={this.closeAdvancedSearchDialog.bind(this)} className="buttonAction btn btn-primary" color="primary">
                <label className="buttonText small" style={{ margin: 0, cursor: 'pointer'}}>Close</label>
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
      </MuiPickersUtilsProvider>
    );
  }
}

SearchStrip.propTypes = {
  searchRequest: PropTypes.func,
  downloadRequest: PropTypes.func,
  pushToArcGISRequest: PropTypes.func,
  resultsCount: PropTypes.number,
  surveyCount:  PropTypes.number,
  incidentCount: PropTypes.number,
  mapDownload: PropTypes.bool,
  leakSurveyDownload: PropTypes.bool,
  headerLabel: PropTypes.string,
  filterUpdate: PropTypes.func,
  reportingPage : PropTypes.bool,
  toHandleChange : PropTypes.func,
  fromHandleChange : PropTypes.func,
  gpsCount : PropTypes.number,
  leakCount : PropTypes.number,
  aocCount : PropTypes.number,
  pipelineCount : PropTypes.number,
  inaccessibleCount : PropTypes.number,
  insideMeterCount : PropTypes.number,
  meterCount : PropTypes.number,
  safetyCount : PropTypes.number,
  includePhotos : PropTypes.bool,
  updateIncludePhotos : PropTypes.func,
  fromMap : PropTypes.bool,
  disableType : PropTypes.func,
  closeFilter : PropTypes.func,
  surveyMapDownload : PropTypes.bool,
  includeOpen : PropTypes.bool,
  updateIncludeOpen : PropTypes.func,
  updateIncludeClosed : PropTypes.func,
  includeClosed : PropTypes.bool,
  updateSearchValue : PropTypes.func,
  defaultDateRangeDays : PropTypes.number,
  customForms : PropTypes.array,
  dataGroups : PropTypes.array,
  expandGroup : PropTypes.func,
  toggleDataGroup : PropTypes.func,
  dataMap : PropTypes.bool,
  userOptions : PropTypes.array,
  useModifiedDate : PropTypes.bool,
  updateUseModifiedDate : PropTypes.func,
  formProjects : PropTypes.array,
  toggleProject : PropTypes.func,
  searching : PropTypes.bool,
  showAdvancedSearchButton: PropTypes.bool,
  tagFormSearchOptions: PropTypes.array,
  loadingTagFormSearchOptions: PropTypes.bool
};

export default SearchStrip;