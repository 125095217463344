import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from "prop-types";

const CountByTech = (props) => {
  const [graphCategories, setGraphCategories] = React.useState([]);
  const [graphData, setGraphData] = React.useState([]);
  const [chart, setChart] = React.useState(null);
  React.useEffect(() => {
    let parsed = {};
    props.data.forEach(survey => {
      if(survey.ModifiedByDisplayName){
        parsed[survey.ModifiedByDisplayName]
        ? parsed[survey.ModifiedByDisplayName] = { name: survey.ModifiedByDisplayName, y: parsed[survey.ModifiedByDisplayName].y + 1}
        : parsed[survey.ModifiedByDisplayName] = { name: survey.ModifiedByDisplayName, y : 1}
      }
    });
    setGraphCategories(Object.keys(parsed));
    setGraphData(Object.values(parsed));
  }, [props.data])
  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  const chartCallback = c => setChart(c);

  return(
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark"}}
      highcharts={Highcharts}
      options={{
        title: {text: "Survey Count By Tech"},
        xAxis: {
          categories: graphCategories,
        },
        plotOptions: {
          series: {
            cursor : "pointer",
            point: {
              events: {
                click: function(event){
                  console.log("Event : " + event);
                  props.filterCallback({ ModifiedByDisplayName: event.point.category}, `Showing ${event.point.category}'s Surveys.`)
                }
              }
            }
          }
        },
        yAxis : {
          min: 0,
          title: { text: "Surveys" }
        },
        colors :["#16558F", "#2D7952", "#DE8C26"],
        chart:{
          type: 'bar',
          backgroundColor : "#E9E9EF",
        },
        series: [
          {
            name: "Surveys",
            data : graphData
          }
        ],
        credits: { enabled : false },
        legend : { enabled: false },
      }}
      allowChartUpdate
      callback={chartCallback}
      constructorType="chart"
    />
  )
}

CountByTech.propTypes = {
  data : PropTypes.array,
  refreshKey : PropTypes.number,
  filterCallback : PropTypes.func
}

export default CountByTech;