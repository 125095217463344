import React from "react";
import PropTypes from "prop-types";
import auth from "../common/Auth";
import ImageGallery from 'react-image-gallery';
import PhotoUpload from './PhotoUpload';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BarLoader, RingLoader } from "react-spinners";
import Popup from "reactjs-popup";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PhotoIcon from '@material-ui/icons/Photo';
import { Button } from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import DateRange from '@material-ui/icons/DateRange' ;
import LocalOfferTwoToneIcon from '@material-ui/icons/LocalOfferTwoTone' ;
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone'
import AccessTime from '@material-ui/icons/AccessTime'
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import VideoPanel from './VideoPanel';
import AttachedDocumentPanel from './AttachedDocumentPanel';
import { MultiSelect } from 'react-multi-select-component';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormDataPage from "./FormDataPage";

class FormDataBody extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      galleryItems: [],
      comparisonGalleryItems: [],
      images: [],
      videos:[],
      thumbnails:[],
      documents:[],
      videoIndex : 0,
      videoCount : 0,
      pictureCount : 0,
      loadingVideos : false,
      comparisonGalleryItemsSurvey: [],
      loading: false,
      loadingComparisons: false,
      picUrl: null,
      modalOpen: false,
      currentIndex : 0,
      updateStatusText : "",
      alertSeverity : "success",
      datePickerToggles : {},
      timePickerToggles : {},
      viewingTag: false, 
      tagFormDataToView: null,
      showAddTagDialog: false,
      tagForms: [],
      loadingTagForms: true,
      savingNewTagDataErrorMessage: null,
      showDeleteTagConfirmationDialog: false,
      tagToDelete: null,
      tagDeleteInProgress: false,
      deleteTagDataErrorMessage: null
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
    this.toggleDatePicker = this.toggleDatePicker.bind(this);
    this.toggleTimePicker = this.toggleTimePicker.bind(this);
    this.getMultimedia = this.getMultimedia.bind(this);
    this.clickThumbNail = this.clickThumbNail.bind(this);
    this.setVideoIndex = this.setVideoIndex.bind(this);
    this.removeVideo = this.removeVideo.bind(this);
    this.controller = new AbortController();
  }

  componentDidMount() {

    if (this.props.formData && this.props.formData.FormData && this.props.formData.FormData.Fields ){
      let fields = this.props.formData.FormData.Fields;
      let datePickerToggles = this.state.datePickerToggles;
      let timePickerToggles = this.state.timePickerToggles;
      for(let i = 0; i< fields.length; i++){
        if(fields[i].Type == "Date"){
          datePickerToggles[i] = false;
        }
        else if(fields[i].Type == "Time"){
          timePickerToggles[i] = false;
        }
      }
      this.setState({datePickerToggles,timePickerToggles});
    }
    if(this.props.formData && this.props.formData.FormData && this.props.formData.FormData.FormTypeId == 4) this.getTagForms();
    if(this.props.fromTaskMap && this.props.formData && this.props.formData.FormData && this.props.formData.FormData.images){
      this.setState({images : this.props.formData.FormData.images, thumbnails : this.props.formData.FormData.images});
    }
    else{
      this.getMultimedia();
    }
    
    //console.log('FormDataBody_componentDidMount', this.props.formData.FormData);
  }

  getTagForms(){
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }
    this.setState({loadingTagForms:true});
    fetch(process.env.API_URL +"/api/account/form",{
      method: "POST",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    }).then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let tagForms = [];
          let json = JSON.parse(responseJson);
          if (json.ResponseType == 0){
            if (json.FormCollection) {
              json.FormCollection.forEach(form =>{
                if(form.Type == "Tag"){
                  form.Fields.forEach(field =>{
                    if(!field.Type){
                      field.Type = "Text";
                    }
                  });
                  tagForms.push(form);
                }
              });
              tagForms.sort((a,b) => (a.FormOrder > b.FormOrder) ? 1 : -1);
              this.setState({tagForms, loadingTagForms: false});
            }
          }
        });
      }
    }).catch(error =>{
      console.log(error.message);
      this.setState({loadingTagForms:false});
    })
  }

  getMultimedia(){ 
    this.setState({loadingDocuments : true});
    if (this.props.formData != undefined && this.props.formData.MultimediaAssets != null){
      this.setState({MultimediaAssets : this.props.formData.MultimediaAssets});
      this.props.formData.MultimediaAssets.forEach((element, index) =>{
        this.getPic(element.MultimediaAssetId,element.Timestamp, index);
      });
    }
    else if(this.props.formData != undefined){
      fetch(process.env.API_URL + "/api/entityImages/" + this.props.formData.FormData.FormDataId + "/93" + "/web",{ 
        method: "POST",
        headers: {"Content-Type" : "application/json", Authorization: "Bearer " + auth.token()}
      })
      .then(r => {
        if(r.status==200){
          r.json().then(bodyJson =>{
            if(bodyJson.length>0){
              let response = JSON.parse(bodyJson);
              let compareArr = [];
              let videoCount = 0;
              let pictureCount = 0;
              response.MultimediaAssets.forEach((element)=>{
                if(!this.props.formData.FormData.HistoryDate || (element.CreatedDate < this.props.formData.FormData.ModifiedDate)){
                  if(element.MediaTypeId == 0 || element.MediaTypeId == null){
                    pictureCount++;
                    this.getPic(element.MultimediaAssetId,element.Timestamp, false);
                    var elementDateSurvey = new Date(element.CompareMultimediaCreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
                    var elementDateformData = new Date(element.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
  
                    if (element.CompareMultimediaAssetId) { 
                      compareArr.push({
                        surveyMultimediaAssetId: element.CompareMultimediaAssetId,
                        investigationMultimediaAssetId: element.MultimediaAssetId, ExternalURL: element.ExternalURL,
                        CreatedDateSurv: elementDateSurvey.toString() == 'Invalid Date' ? '' : elementDateSurvey,
                        CreatedDateInv: elementDateformData
                      }); 
                    }
                  }
                  else if(element.MediaTypeId ==2){
                    videoCount++;
                    this.getPic(element.MultimediaAssetId,element.Timestamp, true, element.CompareMultimediaAssetId);
                  }
                  else if(element.MediaTypeId == 3){
                    let documents = this.state.documents;
                    documents.push(element);
                    this.setState({documents});
                  }
                } 
              });
              
              compareArr.forEach(element => { this.getComparePics( element.investigationMultimediaAssetId,
                element.surveyMultimediaAssetId, compareArr.length, element.ExternalURL)});

              this.setState({MultimediaAssets : response.MultimediaAssets, compareArr: compareArr, pictureCount, videoCount,loadingDocuments :false});
              this.props.setPictureCount(response.MultimediaAssets.length + compareArr.length);
            }
          });
        }
      });
    }
  }
  
  componentDidUpdate(previousProps){
    if( previousProps.formData && this.props.formData && (previousProps.formData.FormData && this.props.formData.FormData) && ((previousProps.formData.FormData.FormDataId != this.props.formData.FormData.FormDataId) || this.props.formData.FormData.HistoryDate != previousProps.formData.FormData.HistoryDate)){
      this.controller.abort();
      this.controller = new AbortController();
      this.setState({images: [],videos:[],blobs: [], thumbnails : [],documents:[],pictureCount: 0,galleryItems : [],videoCount: 0},() =>{this.getMultimedia()});
    }
    window.resize(); 
  }

  handleClose(){}

  getPic(assetId, timeStamp, thumbnail, videoId) {
    if(!thumbnail){
      this.setState({ loading: true });
    }
    fetch(process.env.API_URL + "/api/image/" + assetId, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + auth.token() },
      signal : this.controller.signal
    })
      .then(r => r.blob())
      .then(blob => {
        let images = this.state.images;
        let pic = URL.createObjectURL(blob);
        let thumbnails = this.state.thumbnails;
        if(thumbnail){
          thumbnails.push({original : pic, thumbnail : pic, id : assetId, videoId, timeStamp});
          thumbnails.sort((a,b) => (a.timeStamp > b.timeStamp) ? 1 : -1);
          this.setState({thumbnails});
        }
        else{
          images.push({original: pic, thumbnail: pic, MultimediaAssetId: assetId,timeStamp});
          images.sort((a,b) => (a.timeStamp > b.timeStamp) ? 1 : -1);
          this.setState({images});
          this.state.galleryItems.push(blob);
  
          if (this.state.MultimediaAssets != undefined && this.state.galleryItems.length == this.state.MultimediaAssets.length ) 
            this.setState({ loading: false });
        }
      })
      .catch(error => {
        console.log(error.message);
      });
  }

  getVideo(assetId){
    this.setState({loadingVideos : true});
    fetch(process.env.API_URL + '/api/video/' + assetId,{
      method : "GET",
      headers : {
        "Content-Type" : "application/json",
        Authorization : 'Bearer ' + auth.token()
      },
      signal : this.controller.signal
    })
    .then(r => r.blob())
    .then(blob =>{
      let videos = this.state.videos;
      let vid = URL.createObjectURL(blob);
      videos.push({uri : vid, assetId});
      this.setState({videos, videoIndex : videos.length -1});
      this.setState({loadingVideos : false});
    }).catch(error => { console.log(error.message); this.setState({ loadingVideos: false });  });
  }

  setVideoIndex(direction){
    let index = this.state.videoIndex + direction;
    if(index == -1){
      index = this.state.videos.length -1;
    }
    else if(index == this.state.videos.length){
      index = 0;
    }
    this.setState({videoIndex : index});
  }

  clickThumbNail(index){
    let id = this.state.thumbnails[index].videoId;
    let videoIndex = this.state.videos.findIndex(x => x.assetId == id);
    if(this.state.videos.length == 0 || videoIndex == -1){
      this.getVideo(id);
    }
    else{
      this.setState({videoIndex});
    }
  }

  //eslint-disable-next-line
  async getComparePics(investigationAssetId, surveyAssetId, arrCount, externalURL) {
    
    this.setState({ loadingComparisons: true });

    fetch(process.env.API_URL + "/api/image/" + investigationAssetId, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + auth.token()}
    }).then(async r => {
      let finishedBlob = await r.blob();
      let blobObj = { Id: r.url.substring(r.url.lastIndexOf("/") + 1), Blob: finishedBlob};

      fetch(process.env.API_URL + "/api/image/" + surveyAssetId , {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token()
        }
      }).then(async r => {
          let finishedSurveyBlob = await r.blob();
          let surveyBlobObj = { SurveyId: r.url.substring(r.url.lastIndexOf("/") + 1), SurveyBlob: finishedSurveyBlob};
          this.state.comparisonGalleryItemsSurvey.push(surveyBlobObj);
          this.state.comparisonGalleryItems.push(blobObj);
        })
        .then(() => {
          this.setState({ loadingComparisons: false });
        })
        .catch(error => {
          console.log(error.message);
        });
    })
    .catch(error => {
      console.log(error.message);
    });
  }

  openModal() {
    this.setState({ modalOpen: true }, () =>{ 
      setTimeout(() =>{
        let pic = document.getElementById("popupPic");
        if(pic.width > pic.height){
          let modal = document.getElementsByClassName("popup-content")[0];
          modal.classList.add("popup-horizontal");
        }
      }, 100);
    });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  clickPic() {
    this.setState({
      picUrl: this.state.images[this._imageGallery.getCurrentIndex()].original
    });
    this.openModal();
  }

  clickSurveyPic(index) {
    this.setState({
      picUrl: URL.createObjectURL(
        this.state.comparisonGalleryItemsSurvey[index]
      )
    });
    this.openModal();
  }
  
  clickIncidentPic(index) {
    this.setState({
      picUrl: URL.createObjectURL(this.state.comparisonGalleryItems[index])
    });
    this.openModal();
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  removePhoto(){
    let index = this._imageGallery.getCurrentIndex();
    let MultimediaAsset = this.state.MultimediaAssets[index];
    let date = new Date();
    MultimediaAsset.DeletedBy = auth.userId();
    MultimediaAsset.DeletedDate = date;
    MultimediaAsset.DeletedDateUTCOffset = -date.getTimezoneOffset()/60;
    MultimediaAsset.ModifiedBy = auth.userId();
    MultimediaAsset.ModifiedDate = date;
    MultimediaAsset.ModifiedDateUTCOffset = -date.getTimezoneOffset()/60;
    let body = {
      MultimediaAsset
    }
    fetch(process.env.API_URL + "/api/image/update",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(req =>{
      if(req.status == 200){
        req.json().then(responseJson => {
          let response = JSON.parse(responseJson);
          if(response.MultimediaAsset == null){
            this.setState({updateStatusText : "Something went wrong. Try again.", alertSeverity : "error"});
          }
          else{
            let index = this.state.MultimediaAssets.findIndex(x => x.MultimediaAssetId == response.MultimediaAsset.MultimediaAssetId);
            if(index != -1){
              let images = JSON.parse(JSON.stringify(this.state.images));
              images.splice(index,1);
              this.setState({images, updateStatusText : "Photo was removed", alertSeverity : "success"});
              if(index > images.length-1){
                this._imageGallery.slideToIndex(images.length-1);
              }
            }
            else{
              this.setState({updateStatusText : "Something went wrong. Try again.", alertSeverity : "error"});
            }
          }
        });
      }
    })
    //eslint-disable-next-line
    .catch(error =>{
      this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity : "error"})
    })

  }

  removeVideo(){
    let thumbnail = this.state.thumbnails[this.state.videoIndex];
    let body = {
      ThumbnailId : thumbnail.id,
      VideoId : thumbnail.videoId,
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      UTCOffset : - (new Date().getTimezoneOffset()/60)
    }
    fetch(process.env.API_URL + "/api/video/delete",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(req =>{
      if(req.status == 200){
        
        req.json().then(responseJson =>{
          let response = JSON.parse(responseJson);
          if(response.MultimediaAsset == null){
            this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity :'error'});
          }
          else{
            let index = this.state.videos.findIndex(x => x.assetId == response.MultimediaAsset.MultimediaAssetId);
            if(index != -1){
              let videos = JSON.parse(JSON.stringify(this.state.videos));
              let thumbnails = JSON.parse(JSON.stringify(this.state.thumbnails));
              videos.splice(index,1);
              if(response.MultimediaAsset.CompareMultimediaAssetId != -1){
                index = this.state.thumbnails.findIndex(x => x.id == response.MultimediaAsset.CompareMultimediaAssetId);
                if(index != -1){
                  
                  thumbnails.splice(index, 1);
                }
              }
              this.setState({thumbnails, videos, updateStatusText : 'Video was removed.', alertSeverity : 'success'});
            }
            else{
              this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity : 'error'});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error);
      this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity : "error"});
    });
  }

  toggleDatePicker(index){
    let datePickerToggles = this.state.datePickerToggles;
    datePickerToggles[index] = !datePickerToggles[index];
    this.setState({datePickerToggles});
  }

  toggleTimePicker(index){
    let timePickerToggles = this.state.timePickerToggles;
    timePickerToggles[index] = !timePickerToggles[index];
    this.setState({timePickerToggles});
  }

  slideToIndex(requiredPhotoMultimediaAssetId){
    let index = this.state.images.findIndex(x => x.MultimediaAssetId == requiredPhotoMultimediaAssetId);
    if(index != -1){
      this._imageGallery.slideToIndex(index);
      window.scrollTo(0, this._imageGallery.offsetTop)
    }
  }

  buildTimeString(fieldValue,modifiedutcoffset){
    let date = new Date(new Date().toLocaleDateString().concat(" ",fieldValue));
    date.setHours(date.getHours() +((-date.getTimezoneOffset()/60) - modifiedutcoffset));
    return date.toLocaleTimeString();
  }

  showDeleteTagConfirmation(tagData){
    this.setState({showDeleteTagConfirmationDialog: true, tagToDelete: tagData});
  }

  closeDeleteTagConfirmation(){
    if(this.state.tagDeleteInProgress) return;
    this.setState({showDeleteTagConfirmationDialog : false, tagToDelete: null, deleteTagDataErrorMessage: null})
  }

  deleteTag(){
    this.setState({tagDeleteInProgress: true});
    let body = {
      FormDataId: this.state.tagToDelete.FormDataId,
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      BypassValidation: true
    };
    fetch(process.env.API_URL +"/api/FormData/DeleteTagFormData",{
      method : "POST",
      headers : {
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        response.json().then(async responseJson =>{
          let response = JSON.parse(responseJson);
          if (response.ResponseType ==0){
            this.props.refreshFormData();
          }
          else{
            this.setState({tagDeleteInProgress: false, deleteTagDataErrorMessage: 'Error deleting tag. Try again.'});
          }
        })
      }
      else{
        this.setState({tagDeleteInProgress: false, deleteTagDataErrorMessage: 'Error deleting tag. Try again.'});
      }
    }).catch(error =>{
      console.log(error);
      this.setState({tagDeleteInProgress: false, deleteTagDataErrorMessage: 'Error deleting tag. Try again.'});
    })
  }

  openTag(tagData){
    this.setState({viewingTag: true, tagFormDataToView: {FormData: tagData}});
  }

  closeTag(){
    this.setState({viewingTag: false, tagFormDataToView: null});
  }

  openAddTagDialog(){
    this.setState({showAddTagDialog: true});
  }
  closeAddTagDialog(){
    this.setState({showAddTagDialog: false, savingNewTagDataErrorMessage: null});
  }

  async saveNewTagFormData(tagForm){
    if(this.state.savingNewTagData) return;
    this.setState({savingNewTagData: true, savingNewTagDataErrorMessage: null});
    const now = new Date();
    let FormData = JSON.parse(JSON.stringify(tagForm));
    FormData.MapNotes = [];
    FormData.CreatedBy = auth.userId;
    FormData.CreatedDateUTCOffset = - now.getTimezoneOffset() / 60;
    FormData.CreatedDate = now;
    FormData.Latitude = this.props.formData.FormData.Latitude;
    FormData.Longitude = this.props.formData.FormData.Longitude;
    FormData.ParentFormDataId = this.props.formData.FormData.FormDataId;
    FormData.FormDataId = -1;
    FormData.FieldsToSave = FormData.Fields;
    FormData.ModifiedDate = now;
    FormData.ModifiedDateUTCOffset = -now.getTimezoneOffset() / 60;
    FormData.ModifiedBy = auth.userId;
    let body = {
      FormData,
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      StatusType : 0,
      EntityType : 93,
      RequestId : now.valueOf(),
      BypassValidation: true
    };
    fetch(process.env.API_URL +"/api/form/save",{
      method : "POST",
      headers : {
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        response.json().then(async responseJson =>{
          let response = JSON.parse(responseJson);
          if (response.ResponseType ==0){
            this.props.refreshFormData();
          }
          else{
            this.setState({savingNewTagData: false, savingNewTagDataErrorMessage: 'Something went wrong. Try again.'});
          }
        })
      }
      else{
        this.setState({savingNewTagData: false, savingNewTagDataErrorMessage: 'Something went wrong. Try again.'});
      }
    }).catch(error =>{
      console.log(error);
      this.setState({savingNewTagData: false, savingNewTagDataErrorMessage: 'Something went wrong. Try again.'});
    })
  }

  getTimerValue(fieldValue){
    let value = 0;
    if(fieldValue && fieldValue != ""){
      value = parseInt(fieldValue);
      if(isNaN(value)){
        value = 0;
      }
    }

    let seconds = Math.floor((value / 1000) % 60);
    let minutes = Math.floor((value / 1000 / 60) % 60);
    let hours = Math.floor((value  / 1000 / 3600 ) % 24);

    let hoursStr = ("00" + hours).slice(-2);
    let minutesStr = ("00" + minutes).slice(-2);
    let secondsStr = ("00" + seconds).slice(-2);
 
    
    let finalDisplayValue = hoursStr + ": " + minutesStr +": " + secondsStr;
    return finalDisplayValue;
  }

  render() {
    let timeZone = " " + Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {/*CONFIRM DELETE TAG DIALOG*/}
        <Dialog open={this.state.showDeleteTagConfirmationDialog} onClose={()=>{}}>
          <DialogTitle>{"Are you sure you want to delete tag '" + (this.state.tagToDelete ? this.state.tagToDelete.Name : "") + "'?"}</DialogTitle>
          {this.state.deleteTagDataErrorMessage &&
            <DialogContent>
                <div style={{marginTop: 5, marginBottom: 5, display: 'flex', justifyContent:'center', alignItems: 'center', textAlign:'center'}} className="col-12">
                  <span>{this.state.deleteTagDataErrorMessage}</span>
                </div>
            </DialogContent>
          }
          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.closeDeleteTagConfirmation.bind(this)} color="primary">
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Cancel</label>
            </Button>
            <Button className="buttonAction btn btn-primary" style={{width:80}} onClick={this.deleteTag.bind(this)} color="primary" autoFocus>
              {this.state.tagDeleteInProgress ?
                <RingLoader color={"white"} loading={true} size={20}/>
              :
                <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Delete</label>
              }
            </Button>
          </DialogActions>
        </Dialog>
        {/* DETAILS DIALOG */}
        <Dialog open={this.state.viewingTag} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth='lg' fullWidth='true' scroll='paper'>
          {/* <DialogTitle id="alert-dialog-title">{""}</DialogTitle> */}
          <DialogContent>
            <FormDataPage location={{formData: this.state.tagFormDataToView}} closeEntity={() =>{this.closeTag()}} fromDashboard={true} hideClose={true}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeTag.bind(this)} className="buttonAction btn btn-primary" color="primary">
              <label className="buttonText small" style={{ margin: 0, cursor: 'pointer'}}>Close</label>
            </Button>
          </DialogActions>
        </Dialog>
        {(this.state.tagForms && this.props.formData.FormData.AssociatedTagFormData) && 
          <Dialog open={this.state.showAddTagDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth='xs' fullWidth='false' scroll='paper'>
            <DialogTitle id="alert-dialog-title">{"ADD TAG"}</DialogTitle>
            <DialogContent>
              {this.state.tagForms.filter(x=> this.props.formData.FormData.AssociatedTagFormData.findIndex(y=> y.FormId == x.FormId) == -1).map((tagForm, index) => ( 
                <div style={{marginTop: 5, marginBottom: 5, display: 'flex', justifyContent:'center', alignItems: 'center'}} className="col-12" key={index}>
                  <Button style={{verticalAlign:'middle', marginTop: 5, marginBottom: 5, width: 250, right: 0, left: 0}} className="buttonAction" onClick={()=>{this.saveNewTagFormData(tagForm)}}>
                    <div style={{width: 20}}>
                      <LocalOfferTwoToneIcon className="buttonIcon light"/>
                    </div>
                    <div style={{flex: 1}}>
                      <label className="buttonText withIcon" style={{margin:0, cursor: 'pointer'}}>{tagForm.Name}</label>
                    </div>
                  </Button>
                </div>
              ))}
              {this.state.savingNewTagData &&
                  <div style={{marginTop: 15, marginBottom: 15, display: 'flex', justifyContent:'center', alignItems: 'center'}} className="col-12">
                  <BarLoader sizeUnit={"px"} width={250} size={250} color={"#095399"} loading={true}/>
                </div>
              }
              {this.state.savingNewTagDataErrorMessage &&
                <div style={{marginTop: 5, marginBottom: 5, display: 'flex', justifyContent:'center', alignItems: 'center', textAlign:'center'}} className="col-12">
                  <span>{this.state.savingNewTagDataErrorMessage}</span>
                </div>
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeAddTagDialog.bind(this)} className="buttonAction btn btn-primary" color="primary">
                <label className="buttonText small" style={{ margin: 0, cursor: 'pointer'}}>Cancel</label>
              </Button>
            </DialogActions>
          </Dialog>
        }
      <div className={this.props.fromTaskMap ? "" : "container-fluid"} style={this.props.fromTaskMap ? {height : '50vh'} :{} }>
        <div id={this.props.fromTaskMap || this.props.fromDashboard ? "" : "InvestigationBodyMainDiv"} name={this.props.fromTaskMap || this.props.fromDashboard ? "" : "InvestigationBodyMainDiv"} className="detailMain" style={this.props.fromTaskMap ? {height : '50vh'} : this.props.fromDashboard ? {overflowY:'auto'} : {} }>
          <div className = "detailContent">
          <div className="labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Details</label></div>
          {!this.props.editing  ? <div className="detailContentPanel">
            {this.props.formData.FormData.Fields.map((field, index) => ( !field.Hide && 
              <span key={"field"+index} style={{wordBreak : "break-word"}}>
                

                {/* FIELD NAME */}
                {(field.Type != "Section" && field.Name != "FormTaskStatus" && field.Name != "FormTaskAssignedUserDisplayName" )&& <label className="labelDetailName">{this.props.formData.FormData.FormTypeId == 2 && field.Name =="AssignedUserId" ? "Assigned User" : field.Name }:</label>}
                {field.Name == "FormTaskStatus" && <label className="labelDetailName">Status:</label>}
                {field.Name == "FormTaskAssignedUserDisplayName" && <label className="labelDetailName">Assigned User:</label>}
                {field.Type == "Section" && <label className="customFormSectionDelimiter" style={{marginTop:10}}>{field.Name}</label>}
                {(field.Type == "Date" && field.Value) && new Date(field.Value).toLocaleDateString("en-US")}
                
                {/* DEFAULT FIELD VALUES */}
                {field.Type != "Date" && field.Name != "FormTaskStatus" && field.Name != "FormTaskAssignedUserDisplayName" && field.Type != "Time" && field.Type != "Timer" && field.Type != "Section" && field.Type != "Signature" && field.Type != "Yes or No" && (field.Name == "AssignedUserId" ? this.props.formData.AssignedUserDisplayName : 
                (field.PhoneNumber || field.URL || field.EmailAddress ? <a style={{color : '#095399'}} href = {(field.PhoneNumber ? "tel:" : field.EmailAddress ? "mailto:" : "") + field.Value}>{field.Value}</a> :field.Value))}
                {field.Name == "FormTaskAssignedUserDisplayName" && field.Value}
                {field.Name == "FormTaskStatus" && (field.Value == 0 ? "Open" : field.Value == "1" ? "Closed" : field.Value == "2" ? "Project" : "Canceled")}
                {field.Type == "Yes or No" && (field.Value == 'true' ? "Yes" : field.Value == "false" ? "No" :"")}
                {field.Type == "Time" && (field.Value && field.Value != "" ? this.buildTimeString(field.Value,this.props.formData.FormData.ModifiedDateUTCOffset) + timeZone : "")}
                {field.Type == "Signature" && <img style={{maxHeight:100, maxWidth:200}} src={field.Value}/>}
                {field.Type == "Timer" && this.getTimerValue(field.Value)}
                {field.RequiredPhotoMultimediaAssetId != null && 
                  <Button className="buttonAction btn btn-primary small details requiredPhotoButton" onClick={()=>{this.slideToIndex(field.RequiredPhotoMultimediaAssetId)}}>
                    <PhotoIcon className="buttonIcon light" style={{marginRight:4,marginBottom:2}}/>
                  </Button>
                }
                <br/>
              </span>
            ))}
            {(!this.props.formData.FormData.HistoryDate && this.props.formData.FormTask) && 
              <span>
                <label className="labelDetailName">Assigned User:</label>
                {this.props.formData.FormTask.AssignedUserDisplayName}
                <br/>
              </span>}
              {(!this.props.formData.HistoryDate && this.props.formData.FormTask) && 
              <span>
                <label className="labelDetailName">Status:</label>
                {this.props.formData.FormTask.FormTaskStatus == 0 ? 'Open' : this.props.formData.FormTask.FormTaskStatus == 1 ?'Closed' : this.props.formData.FormTask.FormTaskStatus == 2 ? "Project" : "Canceled"}
              </span>}
              {(!this.props.formData.HistoryDate && this.props.formData.FormTask && this.props.formData.FormTask.CloseDate) &&
              <span>
                <br/>
              <label className="labelDetailName">Close Date:</label>
              {new Date(this.props.formData.FormTask.CloseDate[this.props.formData.FormTask.CloseDate.length]-1 !="Z" ? this.props.formData.FormTask.CloseDate + "Z" : this.props.formData.FormTask.CloseDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"})}
              <br/>
            </span>}
          </div> :
            <div className="detailContentPanel">
              {this.props.formData.FormData.Fields.map((field, index) => ( field.Type != "Signature" && field.Type != "Scanner" && field.Type != "Section" && !field.Hide ?
                <div className="row" key={index}>
                <div className="col-2"> 
                  <label className="labelDetailName">{field.Name}:</label>
                </div>
                <div className="col">
                  {(field.Type == "Text" || field.Type == null) && <input readOnly={field.ReadOnly || field.Name == "AssignedUserId"} type="text" className="form-control entity-edit-text" style={{marginTop : 10, position: 'relative', bottom : 5}} value={field.Value ? field.Value : ''} onChange={(e) =>{this.props.editValue(e.target.value, field,index)}}/>}
                  {field.Type == "Number" && <input readOnly={field.ReadOnly || field.Name == "TaskCounter"} type="number" className="form-control entity-edit-text" style={{marginTop : 10, position: 'relative', bottom : 5}} value={field.Value ? field.Value : ''} onChange={(e) =>{this.props.editValue(e.target.value,field,index)}} /> }
                  {field.Type == "Date" && 
                  <div style={{marginTop : 10, position: 'relative', bottom : 5}}>
                    <Button className="buttonAction btn  btn-primary" onClick={()=>{if(!field.ReadOnly)this.toggleDatePicker(index)}}>
                      <DateRange className="buttonIcon light" style={{margRight:4,marginBottom:2}}/>
                        <label className="buttonText withIcon date" style={{margin:0, cursor: 'pointer'}}>{field.Value ? new Date(field.Value).toLocaleDateString("en-US") : new Date().toLocaleDateString("en-US")}</label>
                    </Button>
                    {this.state.datePickerToggles[index] && (<DatePicker onChange={(date)=>{this.props.editValue(date,field,index);}} onClose={() =>{this.toggleDatePicker(index)}} open={this.state.datePickerToggles[index]} /> )}
                  </div>}
                  {field.Type == "Drop Down" && 
                  <select disabled={field.ReadOnly} className="form-control entity-edit-text" style={{marginTop : 10, position: 'relative', bottom : 5}} value={field.Value ? field.Value : ''} onChange={(e) =>{this.props.editValue(e.target.value, field,index)}}>
                    {field.CustomValues && ['',...field.CustomValues].map((val, ind) => (<option key={ind} value={val}>{val}</option>))}
                  </select>}
                  {(field.Type == "Yes or No" && !field.ReadOnly) && <input type="checkbox" style={{top : 5}} checked={field.Value ? field.Value =='true' : false} value={field.Value ? field.Value == 'true' : false} onChange={(e) =>{this.props.editValue(e.target.value, field,index)}} className="form-control customFormRowControl xsmall entity-edit-checkbox"/>}
                  {(field.Type == "Yes or No" && field.ReadOnly && field.Value && field.Value == "true") && 'Yes'}
                  {(field.Type == "Yes or No" && field.ReadOnly && (!field.Value || field.Value == "false" || field.Value == "")) && 'No'}
                  {field.Type == "Comments" && <textarea readOnly={field.ReadOnly} type="text" style={{marginTop : 10, position: 'relative', bottom : 5, resize:'none'}} className="form-control entity-edit-text-comment" value={field.Value ? field.Value : ''} onChange={(e) =>{this.props.editValue(e.target.value, field,index)}} />}
                  {field.Type == "Time" &&  <div style={{marginTop : 10, position: 'relative', bottom : 5}}>
                    <Button className="buttonAction btn  btn-primary" onClick={()=>{if(!field.ReadOnly)this.toggleTimePicker(index)}}>
                      <AccessTime className="buttonIcon light" style={{margRight:4,marginBottom:2}}/>
                        <label className="buttonText withIcon date" style={{margin:0, cursor: 'pointer'}}>{field.Value}</label>
                    </Button>
                    {this.state.timePickerToggles[index] && (<TimePicker onChange={(time)=>{this.props.editValue(time,field,index);}} onClose={() =>{this.toggleTimePicker(index)}} open={this.state.timePickerToggles[index]} /> )}
                  </div>}
                  {field.Type == "MultiSelect" && 
                    <MultiSelect
                     disabled={field.ReadOnly}
                     value={field.Value && field.Value != "" && field.Value.split(",") && field.Value.split(",").length > 0  ? field.Value.split(",").map(value => {return {label: value, value: value}}) : []}
                     options ={field.CustomValues ? field.CustomValues.map(value => {return {label: value, value : value}}) : []}
                     onChange={(value) =>{let selected = ""; value.forEach(val =>{if(val.value != "")selected += (val.value + ",")}); if(selected.at(selected.length -1) == ",")selected = selected.slice(0,selected.length-1); this.props.editValue(selected,field,index);}}  />}
                </div> 
              </div> : null
              ))}
              {this.props.formData.FormTask && 
              <div className="row">
                <div className="col-2">
                  <label className="labelDetailName">Assigned User:</label>
                </div>
                <div className="col">
                  {this.props.userCollection && <select className="form-control entity-edit-text" style={{marginTop:10, potition:'relative',bottom:5}} value = {this.props.formData.FormTask.AssignedUserId} onChange={(e) =>{this.props.editAssignedUser(e.target.value)}}>
                    <option value={null}></option>
                    {this.props.userCollection.map((user,index) =>(
                      <option key={index} value={user.UserId}>{user.DisplayNameProper}</option>
                    ))}
                  </select>}
                  {!this.props.userCollection && <input readOnly={true} type="text" className="form-control entity-edit-text" style={{marginTop : 10, position: 'relative', bottom : 5}} value={this.props.formData.FormTask.AssignedUserDisplayName}/>}
                </div>
              </div>}
              {this.props.formData.FormTask && 
              <div className="row">
                <div className="col-2">
                  <label className="labelDetailName">Status:</label>
                </div>
                <div className="col">
                  <select className="form-control entity-edit-text" style={{marginTop:10, potition:'relative',bottom:5}} value = {this.props.formData.FormTask.FormTaskStatus} onChange={(e) =>{this.props.editFormTaskStatus(e.target.value)}}>
                    <option value={0}>Open</option>
                    <option value={1}>Closed</option>
                    {this.props.formData.FormData.FormTypeId == 4 && <option value={2}>Project</option>}
                    {this.props.formData.FormData.FormTypeId == 4 && <option value={3}>Canceled</option>}
                  </select>
                </div>
                
              </div>}
            </div>
          }

          {this.props.formData.FormData.MapNotes && this.props.formData.FormData.MapNotes.length > 0 &&
            <div>
              <div className=" labelHeaderBar labelHeaderBarTight"> <label className="labelHeaderName">Map Notes ({this.props.formData.FormData.MapNotes.length})</label></div>
              <div className="detailContentPanel">
                {this.props.formData.FormData.MapNotes.map((mapNote, index) => ( 
                  <div style={{marginBottom: 10}} className="row" key={index}>
                    <div className="col-12"> 
                      <label className="labelDetailName"><b>Lat: </b>{mapNote.Latitude} <b>Lon: </b>{mapNote.Longitude}<br/><b>Comment: </b>{mapNote.Comment}</label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
          {(this.props.formData.FormData.FormTypeId == 4 && this.props.formData.FormData.AssociatedTagFormData) &&
            <div>
              <div className=" labelHeaderBar labelHeaderBarTight row"> 
                <label className="labelHeaderName">Tags ({this.props.formData.FormData.AssociatedTagFormData.length})</label>
                <div style={{flex: 1, justifyContent: 'flex-end'}}>
                  <Button onClick={()=>{if(!this.state.loadingTagForms) this.openAddTagDialog()}} className="buttonAction" style={{width: 100, marginLeft: "auto"}}>
                    {this.state.loadingTagForms ?
                      <RingLoader color={"white"} loading={true} size={20}/>
                    :
                      <LocalOfferTwoToneIcon className="buttonIcon light"/>
                    }
                    <label style={{ paddingLeft: this.state.loadingTagForms ? 5 : 0, margin: 0, cursor : 'pointer'}} className="buttonText">Add Tag</label>
                  </Button>
                </div>
              </div>
              <div className="detailContentPanel">
                {this.props.formData.FormData.AssociatedTagFormData.map((tagData, index) => ( 
                  <div style={{marginTop: 10, MarginBottom: 10}} className="col-12 row" key={index}> 
                    <div style={{ width: 200, marginRight: 20}}>
                      <label className="labelDetailTag" style={{cursor: 'pointer'}}><b>{tagData.Name}</b><br/>Added: {new Date(tagData.CreatedDate).toLocaleDateString("en-US")}</label>
                    </div>
                    <div style={{justifyContent:'center'}}>
                      <Button style={{verticalAlign:'middle', marginTop: 5, width: 100}} className="buttonAction" onClick={()=>{this.openTag(tagData)}}>
                        <LocalOfferTwoToneIcon className="buttonIcon light"/>
                        <label className="buttonText withIcon date" style={{margin:0, cursor: 'pointer'}}>VIEW</label>
                      </Button>
                    </div>
                    {(auth.isAdmin() || auth.isSuperAdmin() || auth.isManager()) && 
                      <div style={{justifyContent:'center'}}>
                        <Button style={{verticalAlign:'middle', marginTop: 5, marginLeft: 10, width: 100}} className="buttonAction red" onClick={()=>{this.showDeleteTagConfirmation(tagData)}}>
                          <HighlightOffTwoToneIcon className="buttonIcon light"/>
                          <label className="buttonText withIcon date" style={{margin:0, cursor: 'pointer'}}>DELETE</label>
                        </Button>
                      </div>
                    }
                  </div>
                ))}
                {this.props.formData.FormData.AssociatedTagFormData.length == 0 &&
                  <div style={{marginTop: 10, marginBottom: 10}} className="col-12 row"> 
                    <div style={{ width: 200}}>
                      <label className="labelDetailTag" style={{cursor: 'pointer'}}><b>No tags attached to ticket</b></label>
                    </div>
                  </div>
                }
              </div>
            </div>
          }

          <div className=" labelHeaderBar labelHeaderBarTight"> <label className="labelHeaderName">Photos ({this.state.pictureCount})</label></div>
          <div className="detailContentPanel">
          
            <div className = "row carouselRow">
              <div className="carouselGalleryDiv">
                <ImageGallery
                  items={this.state.images}
                  width={400}
                  onClick={()=>{ this.clickPic(); }}
                  ref={i => this._imageGallery = i}
                  showFullscreenButton={this.state.images.length > 0}
                  showPlayButton={this.state.images.length > 0}
                />
              </div>  
              <div className="image-galleryDeleteButtonDiv">
                {this.state.images.length > 0 && !this.props.showingHistoryRecord &&
                  <Button className="buttonAction buttonAccount" onClick={this.removePhoto}>
                    <HighlightOffIcon className="buttonIcon"/>
                    <label style={{margin:0, cursor: 'pointer'}} className="buttonText">Delete</label>
                  </Button>
                }
              </div>
            </div>
          </div>
          <VideoPanel
            setVideoIndex={this.setVideoIndex}
            videoIndex={this.state.videoIndex}
            clickThumbNail={this.clickThumbNail}
            thumbnails={this.state.thumbnails}
            loadingVideos={this.state.loadingVideos} 
            videoCount={this.state.videoCount} 
            videos={this.state.videos}
            removeVideo={this.removeVideo}
          />

          {this.props.formData.FormData.FormTypeId == 1 && <div>
            <div className=" labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Survey Comparison Photos</label></div>
            <div className="detailContentPanel">
            <div id="comparisonPhotoDiv" />
              {((this.state.compareArr && this.state.compareArr.length > 0) && (this.state.compareArr && this.state.comparisonGalleryItems && this.state.comparisonGalleryItems.length == this.state.compareArr.length )) &&
              <div>
                <div className="row">
                  <div className="compareLabel">Survey Photo</div>
                  <div className="compareLabel">Comparison Photo</div>
                </div>
                {this.state.compareArr.map((pic, index) =>{
                  return(
                    <div key={index} className="comparisonImgRowDiv">
                      <div className="comparisonImgRowInsideDiv"><img id ={pic.surveyMultimediaAssetId} height={pic.CreatedDateSurv != '' ? 400 : 420} src={!pic.ExternalURL ? URL.createObjectURL(this.state.comparisonGalleryItemsSurvey.filter(e => e.SurveyId == pic.surveyMultimediaAssetId)[0].SurveyBlob) : pic.ExternalURL}/> {pic.CreatedDateSurv != '' ? <div className="comparisonImgLabelDiv">{pic.CreatedDateSurv}</div> : null}</div>
                      <div className="comparisonImgRowInsideDiv"><img id ={pic.investigationMultimediaAssetId} height={400} src={URL.createObjectURL(this.state.comparisonGalleryItems.filter(e => e.Id == pic.investigationMultimediaAssetId)[0].Blob)}/><div className="comparisonImgLabelDiv">{pic.CreatedDateInv}</div></div>
                    </div>
                  )
                })}
              </div>}
            
              <div className="barLoaderPortal">
                <BarLoader sizeUnit={"px"} size={200} color={"#095399"} loading={this.state.loadingComparisons}/>
              </div>

            </div>
          </div>}
          {this.props.formData.FormData.PointComments &&
            <div className=" labelHeaderBar labelHeaderBarTight"> <label className="labelHeaderName">Comments ({this.props.formData.FormData.PointComments.length})</label></div>
          }
          
          {this.props.formData.FormData.PointComments &&
            <div className="detailContentPanel">
            {this.props.formData.FormData.PointComments.map((pointComment, index) => ( 
              <div style={{marginBottom: 10}} className="row" key={index}>
                <div className="col-12"> 
                  <label className="labelDetailName">{new Date(Date.parse(pointComment.ModifiedDate)).toLocaleString()} UTC</label>
                  <label className="customFormPointCommentFormDetailText">{pointComment.Comment}</label>
                </div>
              </div>
            ))}
          </div>
          }
          <AttachedDocumentPanel fromTaskMap={this.props.fromTaskMap} showingHistoryRecord={this.props.showingHistoryRecord} addToDocs={(doc) =>{let docs = this.state.documents; docs.push(doc); this.setState({documents : docs}); if(this.props.fromTaskMap){this.props.updateTaskDocuments(docs)}}} loadingDocuments={this.state.loadingDocuments} entityId={this.props.formData.FormData.FormDataId} entityTypeId={93} Documents ={this.state.documents}/>
          
          {!this.props.showingHistoryRecord && <PhotoUpload fromTaskMap={this.props.fromTaskMap} entityId={this.props.formData.FormData.FormDataId} addToGallery={(image)=>{let images = this.state.images; images.push({original : URL.createObjectURL(image), thumbnail : URL.createObjectURL(image), file : image }); this.setState({images}); this.state.galleryItems.push(image); if(this.props.fromTaskMap){this.props.updateTaskImages(images)}}} entityTypeId={93}/>}
        </div>
      </div>

      <Popup modal={true} lockScroll={false} open={this.state.modalOpen} onClose={this.closeModal} closeOnDocumentClick closeOnEscape position='top left' >
          <div style={{height:"100%"}} className="justify-content-center text-center">
            <img className = "justify-content-center text-center popupPic" id ="popupPic" src={this.state.picUrl}  />
          </div>
        </Popup>

        {this.state.updateStatusText != "" && 
          <Snackbar open={this.state.updateStatusText !=""} onClick={() => {this.setState({updateStatusText : ''})}} autoHideDuration={6000} onClose={this.handleClose}
            anchorOrigin={{ vertical : 'top', horizontal : 'center'}}>
              <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
                {this.state.updateStatusText}
              </Alert>
          </Snackbar>}
        
      {this.state.loading ? <div className="barLoaderPortal"><BarLoader sizeUnit={"px"} size={200} color={"#095399"} loading={this.state.loading}/></div> : null }
    </div>
    </MuiPickersUtilsProvider>
    );
  }
}

FormDataBody.propTypes = {
  formData: PropTypes.object,
  setPictureCount : PropTypes.func,
  editing : PropTypes.bool,
  editValue : PropTypes.func,
  editFormTaskStatus : PropTypes.func,
  showingHistoryRecord : PropTypes.bool,
  fromTaskMap : PropTypes.bool,
  fromDashboard : PropTypes.bool,
  updateTaskImages : PropTypes.func,
  updateTaskDocuments : PropTypes.func
};

export default FormDataBody;