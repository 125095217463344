import React from "react";
import Security from '@material-ui/icons/Security';

class PageUnauthorized extends React.Component {
    render() {
      return (
          <div className="signInRequired">            
              <h4><Security className="signInRequiredBadge"/>Sign in required</h4>
          </div>
      );
    }
  }


export default PageUnauthorized;