import React from "react";

class PageNotLicensed extends React.Component {
  render() {
    return (
      <div id="pageNotLicensedContainer" className="col-10 d-flex justify-content-center p-0 m-0" style={{ backgroundColor: "#E9E9EF" }} >
        <div style={{
          margin: 10,
          alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1
        }}>
          <div className="text-center authSpinnerDiv">
            Not licensed to view this page
            </div>
        </div>
      </div>
    );
  }
}


export default PageNotLicensed;