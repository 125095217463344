import React from 'react';
import auth from "../common/Auth";
import { Button } from "react-bootstrap";
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import AddBoxSharpIcon from '@material-ui/icons/LibraryAddTwoTone';
import SaveSharpIcon from '@material-ui/icons/SaveTwoTone';
import { BarLoader } from "react-spinners";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

class GroupManagementPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      companyGroups : [{
        GroupLabel : "All",
        CompanyGroupId : -1,
        DeletedDate : new Date()
      }],
      loadingText: "",
      alertSeverity: "success",
      updateStatusText: "",
      showWarning : false,
      indexToDelete : -1
    }
    this.handleCloseWarning = this.handleCloseWarning.bind(this);
  }

  handleCloseWarning(){
    this.setState({showWarning : false});
  }

  //addGroup()
  addGroup(){
    if(auth.isSuperViewer()){
      return;
    }
    let companyGroups = this.state.companyGroups;
    companyGroups.push({
      GroupLabel : "",
      CompanyGroupId : -1
    });
    this.setState({companyGroups});
  }

  //updateGroups()
  updateGroups(){
    if(auth.isSuperViewer()){
      return;
    }
    let companyId = auth.companyId();
    let realCompany = localStorage.getItem("actualCompanyId");
    if(companyId != realCompany){
      return;
    }
    let update = true;
    for(let i=0; i<this.state.companyGroups.length; i++){
      if(this.state.companyGroups[i].GroupLabel.length == 0 && this.state.companyGroups[i].DeletedBy == null){
        update = false;
      }
    }
    if(update){
      this.setState({loadingText : "UPDATING GROUPS..."});
      let spliceIndex = 0;
      let Groups = JSON.parse(JSON.stringify(this.state.companyGroups));
      if(Groups.find(x => x.GroupLabel == "All")){
        spliceIndex = Groups.indexOf(x =>x.GroupLabel == "All");
        if(spliceIndex == -1){
          for(let i = 0; i<Groups.length; i++){
            if(Groups[i].GroupLabel == "All"){
              spliceIndex = i;
            }
          }
          Groups.splice(spliceIndex,1);
        }
        else{
          Groups.splice(spliceIndex, 1)
        }
      }
      let body = {
        UserId: auth.employeeId(),
        CompanyId : auth.companyId(),
        Groups,
        UTCOffset : - (new Date().getTimezoneOffset()/60)
      };
      fetch(process.env.API_URL + "/api/account/updateGroups",{
        method: "POST",
        headers:{
          "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization : "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          r.json().then(responseJson =>{
            let json = JSON.parse(responseJson);
            if(json.ResponseType == 0){
              let companyGroups = [{
                GroupLabel : "All",
                CompanyGroupId : -1,
                DeletedDate : new Date()
              }];
              companyGroups = companyGroups.concat(json.Groups);
              this.setState({companyGroups, updateStatusText : 'Updated groups', loadingText : "", alertSeverity: "success"});
            }
            else{
              this.setState({updateStatusText : "There was a problem updating groups. Try again.", loadingText : "", alertSeverity : "error"});
            }
          });
        }
      })
      .catch(error =>{
        this.setState({updateStatusText : "There was a problem updating groups. Try again." + error.message, loadingGroups : false, alertSeverity:"error"});
      })
    }
    else{
      this.setState({updateStatusText: "Enter in a name for the group.", alertSeverity:"warning"});
    }
  }

  //getGroups
  getGroups(){
    this.setState({ loadingText: "LOADING GROUPS..." })
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }

    fetch(process.env.API_URL + "/api/account/getGroups",{
      method: "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.Groups){
              let companyGroups = [{
                GroupLabel : "All",
                CompanyGroupId : -1,
                DeletedDate : new Date()
              }];
              companyGroups = companyGroups.concat(json.Groups);
              this.setState({companyGroups});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error.message);
    })
    .finally(() => {
      this.setState({ loadingText: "" });
    })
  }

  deleteGroup(index){
    if(auth.isSuperViewer()){
      return;
    }
    let companyGroups = this.state.companyGroups;
    companyGroups[index].DeletedDate = new Date();
    companyGroups[index].DeletedBy = auth.employeeId();
    companyGroups[index].DeletedDateUTCOffset = - new Date().getTimezoneOffset() / 60;
    this.setState({companyGroups});
  }

  handleClose = () => {
    this.setState({ updateStatusText: "" })
  }

  componentDidMount(){
    this.getGroups();
  }

  render(){
    return (
      <div className="d-flex justify-content-center flex-row" >
        <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={6000} onClose={this.handleClose} 
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
              {this.state.updateStatusText}
            </Alert>
        </Snackbar>
        <div style={{ width: "100%", marginTop: 20 }}>
          <div className="labelHeaderBar labelHeaderBarTight" style={{marginTop:0, padding:10}}>Group Management
          <div className="labelHeaderBarSubHeader">Create and remove User Groups, and assign to users in the Group Assignment tab</div>
          </div>
          { this.state.loadingText&& <div className="row d-flex align-items-center justify-content-center">
            <div style={{position: "absolute"}} className="d-flex align-items-center justify-content-center authSpinnerDiv">
              <div className="authSpinnerDivMessage">{this.state.loadingText}</div>
              <div className="barLoaderPortal">
                <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={true}/>
              </div>
            </div>
          </div>}
          <div className="licenseEditDivContent">
            { this.state.companyGroups &&<div> {this.state.companyGroups.map((group, index) =>(!group.DeletedDate && 
              <div key={index} style={{display : "flex", flexDirection:"column"}}>
                <div className="row notificationRowDiv">
                  <label htmlFor={"groupName"+index} className="accountHeader notificationLabel notificationRowControl">Group Name:</label>
                  <input id={"groupName"+index} value={group.GroupLabel} className="form-control notificationRowControl large"
                  onChange={e => { let companyGroups = this.state.companyGroups; companyGroups[index].GroupLabel = e.target.value; this.setState({ companyGroups })}}/>
                  <Button onClick={()=>{if(group.CompanyGroupId != -1){this.setState({indexToDelete : index, showWarning : true})} else{this.deleteGroup(index);}}} className="buttonAction buttonAccount notificationRowControl notificationRowButton">
                    <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
                  </Button>
                </div>
              </div>))}</div> }
            <div className="horizontalLine"></div>
            <div className="row"style={{ marginTop : 10 }}>
              <div className="justify-content-left">
                { ! this.state.loadingGroups && <div>
                    <Button onClick={()=>{this.addGroup();}} className="buttonAction buttonAccount">
                      <AddBoxSharpIcon className="buttonIcon"/>
                      <label style={{margin: 0, cursor: 'pointer'}} className="buttonText">New Group</label>
                    </Button>
                    <Button onClick={()=>{this.updateGroups();}} className="buttonAction buttonAccount">
                      <SaveSharpIcon className="buttonIcon"/>
                      <label style={{ margin: 0, cursor: 'pointer'}} className ="buttonText">Save</label>
                    </Button>
                  </div>}
              </div>
            </div>
          </div>
        </div>
        <Dialog open={this.state.showWarning} onClose={() =>this.handleCloseWarning()}>
          <DialogTitle>{"Do you want to delete this Group?"}</DialogTitle>
          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={() =>this.handleCloseWarning()}>
              <label className="buttonText small" style={{margin : 0, cursor : 'pointer'}}>Cancel</label>
            </Button>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={() =>{this.handleCloseWarning();this.deleteGroup(this.state.indexToDelete); }}>
              <label className="buttonText small" style={{margin: 0, cursor : 'pointer'}}>Accept</label>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default GroupManagementPage;