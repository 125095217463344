import React from "react";
import PropTypes from "prop-types";
import auth from "../common/Auth";
import ImageGallery from 'react-image-gallery';
import VideoPanel from './VideoPanel';
import PhotoUpload from './PhotoUpload';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BarLoader } from "react-spinners";
import Popup from "reactjs-popup";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import DatetimePicker from '../dashboard/sitesafe/DatetimePicker';
import { Button } from 'react-bootstrap';
import AttachedDocumentPanel from './AttachedDocumentPanel';

class InvestigationBody extends React.Component {
  constructor(props) {
    super(props);
    let investigationOptions = localStorage.getItem("investigationOptions");
    let options = {};
    if(investigationOptions && investigationOptions != ''){
      options = JSON.parse(investigationOptions);
    }
    this.LineSizeDic = options.LineSizeList ? options.LineSizeList : null;
    this.state = {
      galleryItems: [],
      comparisonGalleryItems: [],
      images: [],
      videos : [],
      thumbnails : [],
      documents : [],
      videoIndex : 0,
      videoCount : 0,
      pictureCount : 0,
      comparisonGalleryItemsSurvey: [],
      loading: false,
      loadingVideos : false,
      loadingComparisons: false,
      picUrl: null,
      modalOpen: false,
      currentIndex : 0,
      updateStatusText : "",
      alertSeverity : "success",
      elmCompany : auth.isElmCompany(),
      stateList : options && options.StateList  ? options.StateList : [],
      AtFaultList : options && options.AtFaultList ? options.AtFaultList : [],
      DepthGradeList : options && options.DepthGradeList ? options.DepthGradeList : [],
      ExcavatorEquipmentList : options && options.ExcavatorEquipmentList ? options.ExcavatorEquipmentList : [],
      ExcavatorTypeList : options && options.ExcavatorTypeList ? options.ExcavatorTypeList : [],
      FacilityList : options && options.FacilityList ? options.FacilityList : [],
      FacilityTypeList : options && options.FacilityTypeList ? options.FacilityTypeList : [],
      LineMaterialList : options && options.LineMaterialList ? options.LineMaterialList : [],
      LineSizeList : this.setLineSizeOptions(props.operationType),
      LocatorTypeList : options && options.LocatorTypeList ? options.LocatorTypeList : [],
      OccurredDuringList : options && options.OccurredDuringList ? options.OccurredDuringList : [],
      OperationTypeList : options && options.OperationTypeList ? options.OperationTypeList : [],
      EventTypeList : options && options.EventTypeList ? options.EventTypeList : [],
      ReplacedOrRepairedList : options && options.ReplacedOrRepairedList ? options.ReplacedOrRepairedList : [],
      ReplacementTypeList : options && options.ReplacementTypeList ? options.ReplacementTypeList : [],
      RowList : options && options.RowList ? options.RowList : [],
      SurfaceTypeList : options && options.SurfaceTypeList ? options.SurfaceTypeList : [],
      WhoPerformedReportsList : options && options.WhoPerformedReportsList ? options.WhoPerformedReportsList : [],
      WorkPerformedList : options && options.WorkPerformedList ? options.WorkPerformedList : [],
      RootCauseList : options && options.RootCauseList ? options.RootCauseList : [],
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
    this.getMultimedia = this.getMultimedia.bind(this);
    this.clickThumbNail = this.clickThumbNail.bind(this);
    this.setVideoIndex = this.setVideoIndex.bind(this);
    this.removeVideo = this.removeVideo.bind(this);
    this.controller = new AbortController();
  }

  setLineSizeOptions(operationType){
    if(!this.LineSizeDic){
      return [];
    }
    else{
      switch(operationType){
        case "Telecommunications":
          return this.LineSizeDic.Telecommunications;
        case "Electric":
          return this.LineSizeDic.Electric;
        case "Telephone":
          return this.LineSizeDic.Telephone;
        case "Electricity":
          return this.LineSizeDic.Electricity;
        case "Cable Television":
          return this.LineSizeDic["Cable Television"];
        default:
          return this.LineSizeDic.default;
      }
    }
  }


  
  handleClose(){}

  componentDidMount() {
    this.getMultimedia();
  }

  getMultimedia(){
    if(this.props.incident != undefined && this.props.incident.MultimediaAssets != null){
      this.setState({MultimediaAssets : this.props.incident.MultimediaAssets});
      this.props.incident.MultimediaAssets.forEach((element, index) =>{
        if(element.MediaTypeId != 1){
          this.getPic(element.MultimediaAssetId,element.Timestamp, index);
        }
        else{
          this.getVideo(element.MultimediaAssetId,element.Timestamp, index);
        }
      });
    }
    else if(this.props.incident != undefined){
      fetch(process.env.API_URL + "/api/entityImages/" + (this.props.upToDateInvestigationId ? this.props.upToDateInvestigationId : this.props.incident.InvestigationId) + "/" + this.props.incident.EntityTypeId + "/web",{ 
        method: "POST",
        headers: {"Content-Type" : "application/json", Authorization: "Bearer " + auth.token()}
      })
      .then(r => {
        if(r.status==200){
          r.json().then(bodyJson =>{
            if(bodyJson.length>0){
              let response = JSON.parse(bodyJson);
              let compareArr = [];
              let videoCount = 0;
              let pictureCount = 0;
              response.MultimediaAssets.forEach((element)=>{  
                if(element.MediaTypeId == 0 || element.MediaTypeId == null){
                  pictureCount++;
                  this.getPic(element.MultimediaAssetId,element.Timestamp, false);
                  var elementDateSurvey = new Date(element.CompareMultimediaCreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
                  var elementDateIncident = new Date(element.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});

                  if (element.CompareMultimediaAssetId) { 
                    compareArr.push({
                      surveyMultimediaAssetId: element.CompareMultimediaAssetId,
                      investigationMultimediaAssetId: element.MultimediaAssetId, ExternalURL: element.ExternalURL,
                      CreatedDateSurv: elementDateSurvey.toString() == 'Invalid Date' ? '' : elementDateSurvey,
                      CreatedDateInv: elementDateIncident
                    }); 
                  }
                }
                else if(element.MediaTypeId == 2){
                  videoCount++;
                  this.getPic(element.MultimediaAssetId,element.Timestamp, true, element.CompareMultimediaAssetId);
                }
                else if(element.MediaTypeId == 3){
                  let documents = this.state.documents;
                  documents.push(element);
                  this.setState({documents});
                }
              });
              
              compareArr.forEach(element => { this.getComparePics( element.investigationMultimediaAssetId,
                element.surveyMultimediaAssetId, compareArr.length, element.ExternalURL)});

              this.setState({MultimediaAssets : response.MultimediaAssets, compareArr: compareArr, pictureCount,videoCount});
              this.props.setPictureCount(response.MultimediaAssets.length + compareArr.length);
            }
          });
        }
      });
    }
  }
  
  componentDidUpdate(previousProps){
    if( previousProps.incident && this.props.incident && (previousProps.incident.Id != this.props.incident.Id)){
      this.controller.abort();
      this.controller = new AbortController();
      this.setState({images: [],videos:[],blobs: [], thumbnails: [],documents: [],pictureCount: 0,galleryItems : [],videoCount: 0},() =>{this.getMultimedia()});
    }
    window.resize(); 
  }

  getPic(assetId, timeStamp, thumbnail, videoId) {
    if(!thumbnail){
      this.setState({ loading: true });
    }
    fetch(process.env.API_URL + "/api/image/" + assetId, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + auth.token() },
      signal : this.controller.signal
    })
      .then(r => r.blob())
      .then(blob => {
        let images = this.state.images;
        let pic = URL.createObjectURL(blob);
        let thumbnails = this.state.thumbnails;
        if(thumbnail){
          thumbnails.push({original : pic, thumbnail : pic, id : assetId, videoId,timeStamp});
          thumbnails.sort((a,b) => (a.timeStamp > b.timeStamp) ? 1 : -1);
          this.setState({thumbnails});
        }
        else{
          images.push({original: pic, thumbnail: pic, assetId, timeStamp});
          images.sort((a,b) => (a.timeStamp > b.timeStamp) ? 1 : -1);
          this.setState({images});
          this.state.galleryItems.push(blob);
          if (this.state.galleryItems.length == this.state.pictureCount ) 
            this.setState({ loading: false });
        }
      })
      .catch(error => {
        console.log(error.message);
      });
  }

  getVideo(assetId){
    this.setState({loadingVideos : true});
    fetch(process.env.API_URL + "/api/video/"+assetId,{
      method : "GET",
      headers : {
        "Content-Type" : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      signal : this.controller.signal
    })
    .then(r => r.blob())
    .then(blob =>{
      let videos = this.state.videos;
      let vid = URL.createObjectURL(blob);
      videos.push({uri : vid, assetId});
      this.setState({videos, videoIndex : videos.length -1});
      this.setState({loadingVideos : false});
    }).catch(error => { console.log(error.message); this.setState({ loadingVideos: false });  });
  }

  setVideoIndex(direction){
    let index = this.state.videoIndex + direction;
    if(index == -1){
      index = this.state.videos.length -1;
    }
    else if(index == this.state.videos.length){
      index = 0;
    }
    this.setState({videoIndex : index});
  }

  clickThumbNail(index){
    let id = this.state.thumbnails[index].videoId;
    let videoIndex = this.state.videos.findIndex(x => x.assetId == id);
    if(this.state.videos.length == 0 || videoIndex == -1){
      this.getVideo(id);
    }
    else{
      this.setState({videoIndex});
    }
  }

  //eslint-disable-next-line
  async getComparePics(investigationAssetId, surveyAssetId, arrCount, externalURL) {
    
    this.setState({ loadingComparisons: true });

    fetch(process.env.API_URL + "/api/image/" + investigationAssetId, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + auth.token()}
    }).then(async r => {
      let finishedBlob = await r.blob();
      let blobObj = { Id: r.url.substring(r.url.lastIndexOf("/") + 1), Blob: finishedBlob};

      fetch(process.env.API_URL + "/api/image/" + surveyAssetId , {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token()
        }
      }).then(async r => {
          let finishedSurveyBlob = await r.blob();
          let surveyBlobObj = { SurveyId: r.url.substring(r.url.lastIndexOf("/") + 1), SurveyBlob: finishedSurveyBlob};
          this.state.comparisonGalleryItemsSurvey.push(surveyBlobObj);
          this.state.comparisonGalleryItems.push(blobObj);
        })
        .then(() => {
          this.setState({ loadingComparisons: false });
        })
        .catch(error => {
          console.log(error.message);
        });
    })
    .catch(error => {
      console.log(error.message);
    });
  }

  openModal() {
    this.setState({ modalOpen: true }, () =>{ 
      setTimeout(() =>{
        let pic = document.getElementById("popupPic");
        if(pic.width > pic.height){
          let modal = document.getElementsByClassName("popup-content")[0];
          modal.classList.add("popup-horizontal");
        }
      }, 100);
    });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  clickPic() {
    this.setState({
      picUrl: this.state.images[this._imageGallery.getCurrentIndex()].original
    });
    this.openModal();
  }

  clickSurveyPic(index) {
    this.setState({
      picUrl: URL.createObjectURL(
        this.state.comparisonGalleryItemsSurvey[index]
      )
    });
    this.openModal();
  }
  
  clickIncidentPic(index) {
    this.setState({
      picUrl: URL.createObjectURL(this.state.comparisonGalleryItems[index])
    });
    this.openModal();
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  removeVideo(){
    let thumbnail = this.state.thumbnails[this.state.videoIndex];
    let body = {
      ThumbnailId : thumbnail.id,
      VideoId : thumbnail.videoId,
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      UTCOffset : - (new Date().getTimezoneOffset()/60)
    }
    fetch(process.env.API_URL + "/api/video/delete",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(req =>{
      if(req.status == 200){
        
        req.json().then(responseJson =>{
          let response = JSON.parse(responseJson);
          if(response.MultimediaAsset == null){
            this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity :'error'});
          }
          else{
            let index = this.state.videos.findIndex(x => x.assetId == response.MultimediaAsset.MultimediaAssetId);
            if(index != -1){
              let videos = JSON.parse(JSON.stringify(this.state.videos));
              let thumbnails = JSON.parse(JSON.stringify(this.state.thumbnails));
              videos.splice(index,1);
              if(response.MultimediaAsset.CompareMultimediaAssetId != -1){
                index = this.state.thumbnails.findIndex(x => x.id == response.MultimediaAsset.CompareMultimediaAssetId);
                if(index != -1){
                  
                  thumbnails.splice(index, 1);
                }
              }
              this.setState({thumbnails, videos, updateStatusText : 'Video was removed.', alertSeverity : 'success'});
            }
            else{
              this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity : 'error'});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error);
      this.setState({updateStatusText : 'Something went wrong. Try again.', alertSeverity : "error"});
    });
  }

  removePhoto(){
    let index = this._imageGallery.getCurrentIndex();
    let MultimediaAsset = this.state.MultimediaAssets[index];
    let date = new Date();
    MultimediaAsset.DeletedBy = auth.userId();
    MultimediaAsset.DeletedDate = date;
    MultimediaAsset.DeletedDateUTCOffset = -date.getTimezoneOffset()/60;
    MultimediaAsset.ModifiedBy = auth.userId();
    MultimediaAsset.ModifiedDate = date;
    MultimediaAsset.ModifiedDateUTCOffset = -date.getTimezoneOffset()/60;
    let body = {
      MultimediaAsset
    }
    fetch(process.env.API_URL + "/api/image/update",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(req =>{
      if(req.status == 200){
        req.json().then(responseJson =>{
          let response = JSON.parse(responseJson);
          if(response.MultimediaAsset == null){
            this.setState({updateStatusText : "Something went wrong. Try again.", alertSeverity : "error"});
          }
          else{
            let index = this.state.MultimediaAssets.findIndex(x => x.MultimediaAssetId == response.MultimediaAsset.MultimediaAssetId);
            if(index != -1){
              let images = JSON.parse(JSON.stringify(this.state.images));
              images.splice(index,1);
              let MultimediaAssets = JSON.parse(JSON.stringify(this.state.MultimediaAssets));
              MultimediaAssets.splice(index,1);
              this.setState({images, MultimediaAssets, updateStatusText : "Photo was removed", alertSeverity : "success"});
            }
            else{
              this.setState({updateStatusText : "Something went wrong. Try again.", alertSeverity : "error"});
            }
          }
        });
      }
    })
    .catch(error =>{
      console.log(error);
      this.setState({updateStatusText : "Something went wrong. Try again.", alertSeverity : "error"});
    })
  }

  render() {
    let timeZone = " " + Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
      <div className="container-fluid">
        <div id={this.props.fromDashboard ? "" : "InvestigationBodyMainDiv"} name={this.props.fromDashboard ? "" : "InvestigationBodyMainDiv"} className="detailMain" style={this.props.fromDashboard ? {overflowY : 'auto'} : {}}>
          <div className = "detailContent">
          
          <div className="labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Location Details</label></div>
          <div className="detailContentPanel">
            {!this.props.editing && <div><label className="labelDetailName">Address: </label> {this.props.incident.Address},{"  "}
            {this.props.incident.City},{"  "}
            {this.props.incident.State}
            <br />
            <label className="labelDetailName">Investigation Date: </label>{" "}
            {new Date(this.props.incident.CreatedDate + "Z").toLocaleString("en-us", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}) + timeZone}
            <br/>
            <label className="labelDetailName">Arrival Date and Time: </label>{" "}  {this.props.incident.ArrivalDateTime != null && this.props.incident.ArrivalDateTime != undefined ? new Date(this.props.incident.ArrivalDateTime[this.props.incident.ArrivalDateTime.length -1] != "Z" ? this.props.incident.ArrivalDateTime+ "Z" : this.props.incident.ArrivalDateTime).toLocaleString("en-us", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}) + timeZone : ""}
            <br/>
            <label className="labelDetailName">Right-of-way: </label> {this.props.incident.Rightofway}
            </div>}
            {this.props.editing && 
              <div>
                <label className="labelDetailName">Street Address: </label> <input type="text" className="form-control entity-edit-text"
                  value={this.props.streetAddress} 
                  onChange={(e) => {this.props.editStreetAddress(e.target.value)}}/>
                <br/>
                <label className="labelDetailName">City: </label> <input type="text" className="form-control entity-edit-text" value={this.props.city} onChange={(e) =>{this.props.editCity(e.target.value)}} />
                <br/>
                <label className="labelDetailName">State: </label> 
                <select className ="form-control entity-edit-text select" value={this.props.stateAddress} onChange={(e) =>{this.props.editStateAddress(e.target.value)}}>
                  {this.state.stateList.map((state, index) =>( <option key ={index} value={state}>{state}</option>))}
                </select>
                <br/>
                <label className="labelDetailName">County: </label>
                <select className="form-control entity-edit-text select" value={this.props.county ? this.props.county.label : null} onChange={(e) =>{this.props.editCounty(e.target.value)}}>
                  {this.props.countyList.map((county, index) =>(<option key={index} data-value={JSON.stringify(county)}>{county.label}</option>))}
                </select>
                <br/>
                <div className="datelabeldiv">
                  <label className="labelDetailName">Event Date and Time: </label>
                  <div style={{marginLeft:4}}>
                    <DatetimePicker iconClassName={this.state.width < 1480 && !(this.state.width < 700) ? "d-none" : "d-block"} name="dateFrom" showTime={true} showDate={true} value={this.props.eventDateTime} onChange={this.props.editEventDateTime} />
                  </div>
                  <Button className='buttonAction d-flex' onClick={() =>{this.props.editEventDateTime(null)}}>
                      <HighlightOffIcon style={{color : '#b3bec8e0', transform : 'scale(0.9)'}}/>
                  </Button>
                </div>
                <div className="datelabeldiv">
                  <label className="labelDetailName">Arrival Date and Time: </label>
                  <div style={{marginLeft:4}}>
                    <DatetimePicker iconClassName={this.state.width < 1480 && !(this.state.width < 700)? "d-none" : "d-block"} name="dateFrom" showTime={true} showDate={true} value={this.props.arrivalDateTime} onChange={this.props.editArrivalDateTime} />
                  </div>
                  <Button className='buttonAction d-flex' onClick={() =>{this.props.editArrivalDateTime(null)}}>
                      <HighlightOffIcon style={{color : '#b3bec8e0', transform : 'scale(0.9)'}}/>
                  </Button>
                </div>
                <label className="labelDetailName">Right-of-way: </label>
                <select className="form-control entity-edit-text select" value={this.props.row} onChange={(e) => {this.props.editRow(e.target.value)}}>
                  {this.state.RowList.map((row, index) => ( <option key={index} value={row}>{row}</option>))}
                </select>
              </div>}
          </div>

          <div className=" labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Affected Facility</label></div>
          <div className="detailContentPanel">
            {!this.props.editing ? <div>
              <label className="labelDetailName">Event Type: </label>{" "}
              {this.props.incident.EventType}
              <br />
              <label className="labelDetailName">Type Of Operation Affected: </label>{" "}
              {this.props.incident.FacilityOperationType}
              <br />
              <label className="labelDetailName">Type Of Facility Affected: </label>{" "}
              {this.props.incident.FacilityType}
              <br />
              <label className="labelDetailName">Line Size And Type: </label> {this.props.incident.LineSize}
              <br />
              <label className="labelDetailName">Facility Part Of Joint Trench: </label>{" "}
              {this.props.incident.FacilityJointTrench == true ? "Yes" : "No"}
              <br />
              <label className="labelDetailName">Asphalt Or Concrete Removed: </label>{" "}
              {this.props.incident.AsphaltRemoved == true ? "Yes" : "No"}
              <br />
              <label className="labelDetailName">Damage Surface Type And Depth: </label>{" "}
              {this.props.incident.DamageSurfaceType}
              {"  "} {this.props.incident.MeasuredDepth}
              <br />
              <label className="labelDetailName">Distance from Ped to Ped: </label>{" "}
              {this.props.incident.PedToPedDistance}
              <br />
              <label className="labelDetailName">Responsible Party: </label>{" "}
              {this.props.incident.ResponsibleParty}
              <br />
              <label className="labelDetailName">Dimensions of Asphalt or Concrete Removed: </label>{" "}
              {this.props.incident.AsphaltConcreteDimensions}
              <br />
              <label className="labelDetailName">Repairs Taking Place Upon Arrival: </label>{" "}
              {this.props.incident.ArrivalRepairs}
              <br />
              <label className="labelDetailName">Who Performed Repairs: </label>{" "}
              {this.props.incident.ReportPerformer}
              <br />
              <label className="labelDetailName">Contractor Representative: </label> {" "}
              {this.props.incident.ContractorRepresentative}
              <br />
              <label className="labelDetailName">Contractor Representative Phone Number: </label>{" "}
              {this.formatPhoneNumber(this.props.incident.ContractorRepresentativePhone)}
              <br />
              <label className="labelDetailName">Personnel On Site: </label>{" "}
              {this.props.incident.PersonnelOnSite}
              <br />
              <label className="labelDetailName">Vehicles/Equipment Being Used For Repairs: </label>{" "}
              {this.props.incident.VehiclesUsedForRepairs}
              <br />
              <label className="labelDetailName">Guards or Barricades On Site: </label>{" "}
              {this.props.incident.GuardOnSite}
              <br />
              <label className="labelDetailName">Facilities Replaced or Repaired: </label>{" "}
              {this.props.incident.FacilitiesReplacedOrRepaired}
              <br />
              <label className="labelDetailName">Replacement Type: </label>{" "}
              {this.props.incident.ReplacementType}
              <br />
              <label className="labelDetailName">Occurred During: </label>{" "}
              {this.props.incident.OccurredDuring}
              <br />
              <label className="labelDetailName">Time Occurred: </label>{" "}
              {this.props.incident.OccurredTime != null ? new Date(this.props.incident.OccurredTime[this.props.incident.OccurredTime.length -1] != "Z" ? this.props.incident.OccurredTime + "Z" : this.props.incident.OccurredTime).toLocaleString("en-us", { hour: "numeric", minute: "numeric"}) + timeZone : ""}
            </div> :
            <div>
              <label className="labelDetailName">Event Type: </label> 
              <select className="form-control entity-edit-text select" value={this.props.eventType} onChange={(e) => {this.props.editEventType(e.target.value)}}>
                {this.state.EventTypeList.map((eventType, index) => ( <option key={index} value={eventType}>{eventType}</option>))}
              </select>
              <br/>
              <label className="labelDetailName">Type Of Operation Affected: </label>
              <select className="form-control entity-edit-text select" value={this.props.operationType} onChange={(e) => {this.setState({LineSizeList : this.setLineSizeOptions(e.target.value)});this.props.editOperation(e.target.value)}}>
                {this.state.OperationTypeList.map((operation, index) => ( <option key={index} value={operation}>{operation}</option>))}
              </select>
              <br/>
              <label className="labelDetailName">Type Of Facility Affected: </label>
              <select className="form-control entity-edit-text select" value={this.props.facilityType} onChange ={(e) => {this.props.editFacilityType(e.target.value)}}>
                {this.state.FacilityTypeList.map((facility, index) => ( <option key={index} value={facility}>{facility}</option>))}
              </select>
              <br/>
              <label className="labelDetailName">Line Size: </label>
              <select className="form-control entity-edit-text select" value={this.props.lineSize} onChange={(e) => {this.props.editLineSize(e.target.value)}} >
                {this.state.LineSizeList.map((size, index) => (<option key={index} value={size}>{size}</option>))}
              </select>
              <br/>
              <label className="labelDetailName">Line Material: </label>
              <select className="form-control entity-edit-text select" value={this.props.lineMaterial} onChange={(e) =>{this.props.editLineMaterial(e.target.value)}}>
                {this.state.LineMaterialList.map((material, index) => (<option key={index} value={material}>{material}</option>))}
              </select>
              <br/>
              <label className="labelDetailName">Facility part of joint trench: </label> <input type="checkbox" checked={this.props.jointTrench ? this.props.jointTrench : false}  value={this.props.jointTrench ? this.props.jointTrench : false} className="form-control customFormRowControl xsmall entity-edit-checkbox" onChange={e =>{this.props.editJointTrench(e.target.value != 'true')}} />
              <br/>
              <label className="labelDetailName">Did event involve a cross bore: </label> <input type="checkbox" checked={this.props.crossBore ? this.props.crossBore : false} value ={this.props.crossBore ? this.props.crossBore : false} className="form-control customFormRowControl xsmall entity-edit-checkbox" onChange={e =>{this.props.editCrossBore(e.target.value != 'true')}} />
              <br/>
              <label className="labelDetailName">Facility owner One Call Center member: </label> <input type="checkbox" checked={this.props.centerMember ? this.props.centerMember : false} value ={this.props.centerMember ? this.props.centerMember : false} className="form-control customFormRowControl xsmall entity-edit-checkbox" onChange={(e) => {this.props.editCenterMember(e.target.value != 'true')}} />
              <br/>
              <label className="labelDetailName">Asphalt or concrete removed to repair damage: </label> <input type="checkbox" checked={this.props.asphaltRemoved ? this.props.asphaltRemoved : false} value={this.props.asphaltRemoved ? this.props.asphaltRemoved : false} className="form-control customFormRowControl xsmall entity-edit-checkbox" onChange={(e) =>{this.props.editAsphaltRemoved(e.target.value != 'true')}} />
              <br/>
              <label className="labelDetailName">Damage Surface Type: </label>
              <select className="form-control entity-edit-text select" value={this.props.damageSurfaceType} onChange={(e) => {this.props.editDamageSurfaceType(e.target.value)}}>
                {this.state.SurfaceTypeList.map((type, index) => (<option key={index} value={type}>{type}</option>))}
              </select>
              <br/>
              <label className="labelDetailName">Measured depth from grade: </label>
              <input type="number" maxLength={200} className="form-control entity-edit-text" value={this.props.measuredDepth} onChange={(e) =>{this.props.editMeasuredDepth(e.target.value)}} />
              <br/>
              <label className="labelDetailName">Distance from Ped to Ped (ft): </label> <input type="number" maxLength={200} className="form-control entity-edit-text" value={this.props.pedDistance} onChange={(e) =>{this.props.editPedDistance(e.target.value)}} />
              <br/>
              <label className="labelDetailName">Responsible Party: </label> <input type="text" maxLength={50} className="form-control entity-edit-text"  value={this.props.responsibleParty} onChange={(e) => {this.props.editResponsibleParty(e.target.value)}} />
              <br/>
              <label className="labelDetailName">Dimensions of asphalt removed: </label> <input type="text" className="form-control entity-edit-text"value={this.props.asphaltDimensions} onChange={(e) =>{this.props.editAsphaltDimensions(e.target.value)}} />
              <br/>
              <label className="labelDetailName">Repairs taking place upon arrival: </label> <input type="checkbox" checked={this.props.arrivalRepairs ? this.props.arrivalRepairs : false} value={this.props.arrivalRepairs ? this.props.arrivalRepairs : false} className="form-control customFormRowControl xsmall entity-edit-checkbox" onChange={(e) => {this.props.editArrivalRepairs(e.target.value != 'true')}} />
              <br/>
              <label className="labelDetailName">Who performed reports: </label>
              <select className="form-control entity-edit-text select" value={this.props.reportPerformer} onChange={(e) =>{this.props.editReportPerformer(e.target.value)}}>
                {this.state.WhoPerformedReportsList.map((who, index) => (<option key={index} value={who}>{who}</option>))}
              </select>
              <br/>
              <label className="labelDetailName">Contractor Representative: </label><input type="text" className="form-control entity-edit-text" value={this.props.contractorRep} onChange={(e) =>{this.props.editContractorRep(e.target.value)}} />
              <br/>
              <label className="labelDetailName">Contractor Representative Phone Number: </label> <input type="text" maxLength={18} className="form-control entity-edit-text" value={this.props.contractorRepPhone} onChange={(e) =>{this.props.editContractorRepPhone(e.target.value)}} />
              <br/>
              <label className="labelDetailName">Personnel on site: </label> <input type="text" className="form-control entity-edit-text" value={this.props.personnelOnSite} onChange={(e) =>{this.props.editPersonnelOnSite(e.target.value)}} />
              <br/>
              <label className="labelDetailName">Vehicles being used for repairs: </label> <input type="text" className="form-control entity-edit-text" value={this.props.vehiclesUsedForRepairs} onChange={(e) =>{this.props.editVehiclesUsedForRepairs(e.target.value)}} />
              <br/>
              <label className="labelDetailName">Guards or Barricades On Site: </label> <input type="text" className="form-control entity-edit-text" value={this.props.guardsOnSite} onChange={(e) =>{this.props.editGuardsOnSite(e.target.value)}} />
              <br/>
              <label className="labelDetailName">Facilities Replaced or Repaired</label>
              <select className="form-control entity-edit-text select" value={this.props.replacedOrRepaired} onChange={(e) =>{this.props.editReplacedOrRepaired(e.target.value)}}>  
                {this.state.ReplacedOrRepairedList.map((re, index) => (<option key={index} value={re}>{re}</option>))}
              </select>
              <br/>
              <label className="labelDetailName">Replacement Type: </label>
              <select className="form-control entity-edit-text select" value={this.props.replacementType} onChange={(e) =>{this.props.editReplacementType(e.target.value)}}>
                {this.state.ReplacementTypeList.map((type, index) =>(<option key={index} value={type}>{type}</option>))}
              </select>
              <br/>
              <label className="labelDetailName">Occured during: </label>
              <select className="form-control entity-edit-text select" value={this.props.occurredDuring} onChange={(e) =>{this.props.editOccurredDuring(e.target.value)}}>
                {this.state.OccurredDuringList.map((occurred, index) =>(<option key={index} value={occurred}>{occurred}</option>))}
              </select>
              <br/>
              <div className="datelabeldiv">
                <label className="labelDetailName">Time Occurred: </label> 
                <div style={{marginLeft:4}}>
                  <DatetimePicker iconClassName={this.state.width < 1480 && !(this.state.width < 700)? "d-none" : "d-block"} name="dateFrom" 
                  value={this.props.occurredTime} showTime={true} showDate={false} onChange={this.props.editOccurredTime} />
                </div>
                <Button className='buttonAction d-flex' onClick={() =>{this.props.editOccurredTime(null)}}>
                  <HighlightOffIcon style={{color : '#b3bec8e0', transform : 'scale(0.9)'}}/>
                </Button>
              </div>
            </div>}
          </div>

          <div className ="labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Damager</label></div>
          {!this.props.editing ? 
          <div className="detailContentPanel">
            <label className="labelDetailName">Company Name: </label>{" "}
            {this.props.incident.DamageCompanyName}
            <br />
            <label className="labelDetailName">Contact: </label>{" "}
            {this.props.incident.DamageContact}
            <br />
            <label className="labelDetailName">Contact Phone: </label>{" "}
            {this.formatPhoneNumber(this.props.incident.DamageContactPhone)}
            <br />
            <label className="labelDetailName">Type of Work: </label>{" "}
            {this.props.incident.DamageWorkType}
          </div>
          : 
          <div className="detailContentPanel">
            <label className="labelDetailName">Company Name: </label> <input type="text" className="form-control entity-edit-text" value={this.props.damageCompanyName} onChange={e =>{this.props.editDamageCompanyName(e.target.value)}} />
            <br/>
            <label className="labelDetailName">Contact: </label> <input type="text" className="form-control entity-edit-text" value={this.props.damageContact} onChange={e =>{this.props.editDamageContact(e.target.value)}} />
            <br/>
            <label className="labelDetailName">Contact Phone: </label> <input type="number" maxLength={18} className="form-control entity-edit-text" value={this.props.damageContactPhone} onChange={e => {this.props.editDamageContactPhone(e.target.value)}} />
            <br/>
            <label className="labelDetailName">Type of Work: </label> <input type="text" className="form-control entity-edit-text" value={this.props.damageWorkType} onChange={e => {this.props.editDamageWorkType(e.target.value)}} />
          </div>}

          <div className=" labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Utility Information</label></div>
          {!this.props.editing ? 
          <div className="detailContentPanel">
            <label className="labelDetailName">Utility Company Name: </label>{" "}
            {this.props.incident.UtilityCompanyName}
            <br />
            <label className="labelDetailName">Utility Contact Name: </label>{" "}
            {this.props.incident.UtilityContactName}
            <br />
            <label className="labelDetailName">Utility Contact Phone: </label>{" "}
            {this.formatPhoneNumber(this.props.incident.UtilityContactPhone)}
            <br />
            <label className="labelDetailName">Utility Representative: </label> {this.props.incident.UtilityRep}
            <br />
            <label className="labelDetailName">Utility Rep. Tech#: </label> {this.props.incident.UtilityRepTech}
            <br />
            <label className="labelDetailName">Utility Rep. Present On Arrival:</label>{" "}
            {this.props.incident.UtilityRepOnSite == true ? "Yes" : "No"}
          </div>
          : 
          <div className="detailContentPanel">
            <label className="labelDetailName">Utility Company Name: </label> <input type="text" className="form-control entity-edit-text" value={this.props.utilityCompanyName} onChange={e => {this.props.editUtilityCompanyName(e.target.value)}} />
            <br/>
            <label className="labelDetailName">Utility Contact Name:  </label> <input type="text" className="form-control entity-edit-text" value={this.props.utilityContactName} onChange={e =>{this.props.editUtilityContactName(e.target.value)}} />
            <br/>
            <label className="labelDetailName">Utility Contact Phone: </label> <input type="text" maxLength={18} className="form-control entity-edit-text" value={this.props.utilityContactPhone} onChange={e =>{this.props.editUtilityContactPhone(e.target.value)}} />
            <br/>
            <label className="labelDetailName">Utility Representative: </label> <input type="text" className="form-control entity-edit-text" value={this.props.utilityRep} onChange={e =>{this.props.editUtilityRep(e.target.value)}} />
            <br/> 
            <label className="labelDetailName">Utility Rep. Tech#: </label> <input type="text" className="form-control entity-edit-text" value={this.props.utilityRepTech} onChange={e =>{this.props.editUtilityRepTech(e.target.value)}} />
            <br/>
            <label className="labelDetailName">Utility Rep. Present On Arrrival: </label> <input type="checkbox" checked={this.props.utilityRepOnSite ? this.props.utilityRepOnSite : false} value={this.props.utilityRepOnSite ? this.props.utilityRepOnSite : false} onChange={(e) =>{this.props.editUtilityRepOnSite(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/> 
            <div className="datelabeldiv">
              <label className="labelDetailName">If not, Arrival Date: </label> <div style={{marginLeft:4}}>
                <DatetimePicker iconClassName={this.state.width < 1480 && !(this.state.width < 700)? "d-none" : "d-block"} name="dateFrom" showDate={true} showTime={false}
                value={this.props.utilityRepArrivalDatetime} onChange={this.props.editUtilityRepArrivalDatetime} /></div>
                <Button className='buttonAction d-flex' onClick={() =>{this.props.editUtilityRepArrivalDatetime(null)}}>
                  <HighlightOffIcon style={{color : '#b3bec8e0', transform : 'scale(0.9)'}}/>
                </Button>
            </div>
          </div>}

          <div className=" labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Excavation Information</label></div>
          {!this.props.editing ? 
          <div className="detailContentPanel">
            <label className="labelDetailName">Excavator Type: </label> {this.props.incident.ExcavatorType}
            <br />
            <label className="labelDetailName">Excavation Equipment: </label>{" "}
            {this.props.incident.ExcavationEquipment}
            <br />
            <label className="labelDetailName">Work Performed: </label> {this.props.incident.WorkPerformed}
            <br />
            <label className="labelDetailName">Excavator Downtime:</label>{" "}
            {this.props.incident.ExcavatorDowntime == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Downtime Hours: </label> {this.props.incident.ExcavatorDowntimeHours}
            <br />
            <label className="labelDetailName">Downtime Cost: </label>{" "}
            {this.props.incident.ExcavatorDowntimeCost > 0 ? "$" + this.props.incident.ExcavatorDowntimeCost : null}
          </div>: 
          <div className="detailContentPanel">
            <label className="labelDetailName">Excavator Type: </label>
            <select className="form-control entity-edit-text select" value={this.props.excavatorType} onChange={e => {this.props.editExcavatorType(e.target.value)}}>
              {this.state.ExcavatorTypeList.map((type,index) => (<option key={index} value={type}>{type}</option>))}
            </select>
            <br/>
            <label className="labelDetailName">Excavation Equipment: </label>
            <select className="form-control entity-edit-text select" value={this.props.excavationEquipment} onChange={e => {this.props.editExcavationEquipment(e.target.value)}}>
              {this.state.ExcavatorEquipmentList.map((equip,index) =>(<option key={index} value={equip}>{equip}</option>))}
            </select>
            <br/>
            <label className="labelDetailName">Work Performed: </label>
            <select className="form-control entity-edit-text select" value={this.props.workPerformed} onChange={e => {this.props.editWorkPerformed(e.target.value)}}>
              {this.state.WorkPerformedList.map((work,index) =>(<option key={index} value={work}>{work}</option>))}
            </select>
            <br/>
            <label className="labelDetailName">Excavator Downtime: </label> <input type="checkbox" checked={this.props.excavatorDowntime ? this.props.excavatorDowntime : false} value={this.props.excavatorDowntime ? this.props.excavatorDowntime : false} onChange={(e) =>{this.props.editExcavatorDowntime(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox"/>
            <br/>
            <label className="labelDetailName">Downtime Hours: </label> <input type="number" className="form-control entity-edit-text" value={this.props.excavatorDowntimeHours} onChange={e =>{this.props.editExcavatorDowntimeHours(e.target.value)}} />
            <br/>
            <label className="labelDetailName">Downtime Cost: </label> <input type="number" className="form-control entity-edit-text" value={this.props.excavatorDowntimeCost} onChange={e =>{this.props.editExcavatorDowntimeCost(e.target.value)}} />
          </div>}

          <div className=" labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Notification And Locating</label></div>
          {!this.props.editing ?
          <div className="detailContentPanel">
            <label className="labelDetailName">One Call Center Notified: </label>{" "}
            {this.props.incident.NotificationOneCallNotified == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Locator Type: </label> {this.props.incident.LocatorType}
            <br />
            <label className="labelDetailName">Work Area White-Lined: </label>{" "}
            {this.props.incident.WorkAreaWhiteLined == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Ticket Number: </label> {this.props.incident.TicketNumber}
            <br />
            <label className="labelDetailName">Damage Ticket Number: </label> {this.props.incident.damageTicketNumber}
            <br />
            <label className="labelDetailName">Date Located: </label>{" "}
            {this.props.incident.DateLocated != null && this.props.incident.DateLocated != undefined ? new Date(this.props.incident.DateLocated[this.props.incident.DateLocated.length -1] != "Z" ? this.props.incident.DateLocated+ "Z" : this.props.incident.DateLocated).toLocaleString("en-us", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}) + timeZone : ""}
            <br />
            <label className="labelDetailName">Located By: </label> {this.props.incident.LocatedBy}
            <br/>
            <label className="labelDetailName">Root Cause: </label> {this.props.incident.RootCause}
          </div>
           : 
          <div className="detailContentPanel">
            <label className="labelDetailName">One Call Center notified: </label> <input type="checkbox" checked={this.props.notificationOneCallNotified ? this.props.notificationOneCallNotified : false} value={this.props.notificationOneCallNotified ? this.props.notificationOneCallNotified : false} onChange={(e) =>{this.props.editNotificationOneCallNotified(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" /> 
            <br/>
            <label className="labelDetailName">Locator Type: </label>
            <select className="form-control entity-edit-text select" value={this.props.locatorType} onChange={(e) =>{this.props.editLocatorType(e.target.value)}}>
              {this.state.LocatorTypeList.map((type,index) =>(<option key={index} value={type}>{type}</option>))}
            </select>
            <br/> 
            <label className="labelDetailName">Excavation exempt from notification: </label> <input type="checkbox" checked={this.props.excavationExempt ? this.props.excavationExempt : false} value={this.props.excavationExempt ? this.props.excavationExempt : false} onChange={(e) =>{this.props.editExcavationExempt(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">Work area white-lined: </label> <input type="checkbox" checked={this.props.workAreaWhiteLined ? this.props.workAreaWhiteLined : false} value={this.props.workAreaWhiteLined ? this.props.workAreaWhiteLined : false} onChange={(e) =>{this.props.editWorkAreaWhiteLined(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">Ticket Number: </label> <input type="text" className="form-control entity-edit-text" value={this.props.ticketNumber} onChange={e =>{this.props.editTicketNumber(e.target.value)}} />
            <br/>
            <label className="labelDetailName">Damage Ticket Number: </label> <input type="text" className="form-control entity-edit-text" value={this.props.damageTicketNumber} onChange={e =>{this.props.editDamageTicketNumber(e.target.value)}}/>
            <br/>
            
            <div className="datelabeldiv">
              <label className="labelDetailName">Date and Time Located: </label> <div style={{marginLeft:4}}>
                <DatetimePicker iconClassName={this.state.width < 1480 && !(this.state.width < 700)? "d-none" : "d-block"} name="dateFrom" showDate={true} showTime={true}
                value={this.props.dateLocated} onChange={this.props.editDateLocated} /></div>
                <Button className='buttonAction d-flex' onClick={() =>{this.props.editDateLocated(null)}}>
                  <HighlightOffIcon style={{color : '#b3bec8e0', transform : 'scale(0.9)'}}/>
                </Button>
            </div>
            <label className="labelDetailName">Located By: </label> <input type="text" className="form-control entity-edit-text" value={this.props.locatedBy} onChange={(e) => {this.props.editLocatedBy(e.target.value)}} />
            <br/>
            <div><label className="labelDetailName">Root Cause: </label>
              <select className="form-control entity-edit-text select" value={this.props.rootCause} onChange={e => {this.props.editRootCause(e.target.value)}}>
                {this.state.RootCauseList.map((cause,index) =>(<option key={index} value={cause}>{cause}</option>))}
              </select>
            </div>
          </div>}

          <div className=" labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Interruption and Restoration</label></div>
          {!this.props.editing ? 
          <div className="detailContentPanel">
            <label className="labelDetailName">Event Caused Interruption: </label>{" "}
            {this.props.incident.EventInterruption == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Interruption Hours: </label> {this.props.incident.InterruptionHours}
            <br />
            <label className="labelDetailName">Customers Affected (approx.): </label>{" "}
            {this.props.incident.CustomersAffected}
          </div> : 
          <div className="detailContentPanel">
            <label className="labelDetailName">Event Caused Interruption: </label> <input type="checkbox" checked={this.props.eventInterruption ? this.props.eventInterruption : false} value={this.props.eventInterruption ? this.props.eventInterruption : false} onChange={(e) =>{this.props.editEventInterruption(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox"/>
            <br/>
            <label className="labelDetailName">Interruption hours: </label> <input type="number" className="form-control entity-edit-text" value={this.props.interruptionHours} onChange={(e) =>{this.props.editInterruptionHours(e.target.value)}} />
            <br/>
            <label className="labelDetailName">Customers Affected: </label> <input type="number" className="form-control entity-edit-text" value={this.props.customersAffected} onChange={(e) =>{this.props.editCustomersAffected(e.target.value)}} />
          </div>}

          {this.state.elmCompany && <div className=" labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Root Cause</label></div>}
          {!this.props.editing && this.state.elmCompany ? 
          <div className="detailContentPanel">
            <label className="labelDetailName">Damage Within Described Dig Area: </label>{" "}
            {this.props.incident.CauseDamageWithinDigArea == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Damage Within Legal Time Of Request Limit: </label>{" "}
            {this.props.incident.CauseDamageWithinTime == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Marks Clearly Accurate: </label>{" "}
            {this.props.incident.CauseMarksAccurate == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Marks Destroyed By Construction: </label>{" "}
            {this.props.incident.CauseMarksDestroyedConstruction == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Marks Destroyed By Weather: </label>{" "}
            {this.props.incident.CauseMarksDestroyedWeather == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Whiskers Or Flags Used: </label>{" "}
            {this.props.incident.CauseWiskersUsed == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Offsets Used On Hard Surfaces: </label>{" "}
            {this.props.incident.CauseOffsetsUsed == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Damaged Line Unlocatable: </label>{" "}
            {this.props.incident.CauseDamagedLineUnlocatable == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Line Was On Prints: </label>{" "}
            {this.props.incident.CauseLineOnPrints == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Line Came Up In A Closure In The Locate Area: </label>{" "}
            {this.props.incident.CauseLineInClosureArea == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">At Fault: </label>{" "}
            {this.props.incident.AtFault}
            <br />
            <label className="labelDetailName">Documentation Issue:</label>{" "}
            {this.props.incident.DocumentationIssue == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Documentation Issue Comments:</label>{" "}
            {this.props.incident.DocumentationIssueComments}
            <br />
            <label className="labelDetailName">Tolerance Zone:</label>{" "}
            {this.props.incident.ToleranceZone == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Marking Standards:</label>{" "}
            {this.props.incident.MarkingStandards == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Marking Standards Comments:</label>{" "}
            {this.props.incident.MarkingStandardsComments}
            <br />
            <label className="labelDetailName">Locate Requested:</label>{" "}
            {this.props.incident.LocateRequested == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Damage with Tolerance Zone:</label>{" "}
            {this.props.incident.DamageWithToleranceZone == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">No Locates on Facility:</label>{" "}
            {this.props.incident.NoLocatesOnFacility == true ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Damage Exposed Facility:</label>{" "}
            {this.props.incident.DamageExposedFacility == true ? "Yes" : "No"}
          </div>
          : this.state.elmCompany ?
          <div className="detailContentPanel">
            <label className="labelDetailName">Damage within described dig area: </label> <input type="checkbox" checked={this.props.causeDamageWithinDigArea ? this.props.causeDamageWithinDigArea : false} value={this.props.causeDamageWithinDigArea ? this.props.causeDamageWithinDigArea : false} onChange={(e) =>{this.props.editCauseDamageWithinDigArea(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">Damage within legal time of request limit: </label> <input type="checkbox" checked={this.props.causeDamageWithinTime ? this.props.causeDamageWithinTime : false} value={this.props.causeDamageWithinTime ? this.props.causeDamageWithinTime : false} onChange={(e) =>{this.props.editCauseDamageWithinTime(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">Marks clearly accurate: </label> <input type="checkbox" checked={this.props.causeMarksAccurate ? this.props.causeMarksAccurate : false} value={this.props.causeMarksAccurate ? this.props.causeMarksAccurate : false} onChange={(e) =>{this.props.editCauseMarksAccurate(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">Marks destroyed by construction: </label> <input type="checkbox" checked={this.props.causeMarksDestroyedConstruction ? this.props.causeMarksDestroyedConstruction : false} value={this.props.causeMarksDestroyedConstruction ? this.props.causeMarksDestroyedConstruction : false} onChange={(e) =>{this.props.editCauseMarksDestroyedConstruction(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">Marks destroyed by weather: </label> <input type="checkbox" checked={this.props.causeMarksDestroyedWeather ? this.props.causeMarksDestroyedWeather : false} value={this.props.causeMarksDestroyedWeather ? this.props.causeMarksDestroyedWeather : false} onChange={(e) =>{this.props.editCauseMarksDestroyedWeather(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">Whiskers or flags used: </label> <input type="checkbox" checked={this.props.causeWiskersUsed ? this.props.causeWiskersUsed : false} value={this.props.causeWiskersUsed ? this.props.causeWiskersUsed : false} onChange={(e) =>{this.props.editCauseWiskersUsed(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">Offsets were used on hard surfaces: </label> <input type="checkbox" checked={this.props.causeOffsetsUsed ? this.props.causeOffsetsUsed : false} value={this.props.causeOffsetsUsed ? this.props.causeOffsetsUsed : false} onChange={(e) =>{this.props.editCauseOffsetsUsed(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">Damaged Line Unlocatable: </label> <input type="checkbox" checked={this.props.causeDamagedLineUnlocatable ? this.props.causeDamagedLineUnlocatable : false} value={this.props.causeDamagedLineUnlocatable ? this.props.causeDamagedLineUnlocatable : false} onChange={(e) =>{this.props.editCauseDamagedLineUnlocatable(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">Line was on prints: </label> <input type="checkbox" checked={this.props.causeLineOnPrints ? this.props.causeLineOnPrints : false} value={this.props.causeLineOnPrints ? this.props.causeLineOnPrints : false} onChange={(e) =>{this.props.editCauseLineOnPrints(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">Line came up in a closure in the locate area: </label> <input type="checkbox" checked={this.props.causeLineInClosureArea ? this.props.causeLineInClosureArea : false} value={this.props.causeLineInClosureArea ? this.props.causeLineInClosureArea : false} onChange={(e) =>{this.props.editCauseLineInClosureArea(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">At Fault: </label>
            <select className="form-control entity-edit-text select" value={this.props.atFault} onChange={(e) =>{this.props.editAtFault(e.target.value)}}>
              {this.state.AtFaultList.map((fault, index) => (<option key={index} value={fault}>{fault}</option>))}
            </select>
            <br/>
            <label className="labelDetailName">Documentation Issue: </label> <input type="checkbox" checked={this.props.documentationIssue ? this.props.documentationIssue : false} value={this.props.documentationIssue ? this.props.documentationIssue  : false} onChange={(e) => {this.props.editDocumentationIssue(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox"/>
            <br/>
            <label className="labelDetailName">Documentation Issue Comments: </label> <textarea type="text" className="form-control entity-edit-text-comment" value={this.props.documentationIssueComments} onChange={e => { this.props.editDocumentationIssueComments(e.target.value)}} />
            <br/>
            <label className="labelDetailName">Tolerance Zone: </label> <input type="checkbox" checked={this.props.toleranceZone ? this.props.toleranceZone : false} value={this.props.toleranceZone ? this.props.toleranceZone : false} onChange={(e) =>{this.props.editToleranceZone(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox"/>
            <br/>
            <label className="labelDetailName">Marking Standards: </label> <input type="checkbox" checked={this.props.markingStandards ? this.props.markingStandards : false} value={this.props.markingStandards ? this.props.markingStandards : false} onChange={(e) =>{this.props.editMarkingStandards(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">Marking Standards Comments: </label> <textarea type="text" className="form-control entity-edit-text-comment" value={this.props.markingStandardsComments} onChange={(e)=>{this.props.editMarkingStandardsComments(e.target.value)}} />
            <br/>
            <label className="labelDetailName">Locate Requested: </label> <input type="checkbox"  checked={this.props.locateRequested ? this.props.locateRequested : false} value={this.props.locateRequested ? this.props.locateRequested  : false} onChange={(e) => {this.props.editLocateRequested(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox"/>
            <br/>
            <label className="labelDetailName">Damage within Tolerance Zone: </label> <input type="checkbox" checked={this.props.damageWithToleranceZone  ? this.props.damageWithToleranceZone : false} value={this.props.damageWithToleranceZone ? this.props.damageWithToleranceZone : false} onChange={(e) => {this.props.editDamageWithToleranceZone(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">No Locates on Facility: </label> <input type="checkbox" checked={this.props.noLocatesOnFacility ? this.props.noLocatesOnFacility : false} value={this.props.noLocatesOnFacility ? this.props.noLocatesOnFacility : false} onChange={(e) =>{this.props.editNoLocatesOnFacility(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
            <br/>
            <label className="labelDetailName">Damage Exposed Facility: </label> <input type="checkbox" checked={this.props.damageExposedFacility ? this.props.damageExposedFacility : false} value={this.props.damageExposedFacility ? this.props.damageExposedFacility : false} onChange={(e)=>{this.props.editDamageExposedFacility(e.target.value != 'true')}} className="form-control customFormRowControl xsmall entity-edit-checkbox" />
          </div> : <div></div>}

          <div className=" labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Comments</label></div>
          {!this.props.editing ? 
          <div className="detailContentPanel">
            <label className="labelDetailName">Comments: </label> {this.props.incident.Comments} 
          </div>
          : 
          <div className="detailContentPanel">
            <label className="labelDetailName">Comments: </label> <textarea type="text" className="form-control entity-edit-text-comment" value={this.props.comments} onChange={e =>{this.props.editComments(e.target.value)}} />
          </div>}

          <div className=" labelHeaderBar labelHeaderBarTight"> <label className="labelHeaderName">Investigation Photos ({this.state.pictureCount})</label></div>
          <div className="detailContentPanel">
          
            <div className = "row carouselRow">
              <div className="carouselGalleryDiv">
                <ImageGallery
                  items={this.state.images}
                  width={400}
                  onClick={()=>{ this.clickPic(); }}
                  ref={i => this._imageGallery =i}
                  showFullscreenButton={this.state.images.length > 0}
                  showPlayButton={this.state.images.length > 0}
                />
              </div> 
              <div className="image-galleryDeleteButtonDiv">
              {this.state.images.length > 0 && !this.props.showingHistoryRecord &&
                <Button className="buttonAction buttonAccount" onClick={this.removePhoto}>
                  <HighlightOffIcon className="buttonIcon"/>
                  <label style={{ margin: 0, cursor: 'pointer'}} className="buttonText">Delete</label>
                </Button>
              }
              </div>
            </div>
          </div>
          <VideoPanel
            setVideoIndex={this.setVideoIndex}
            videoIndex={this.state.videoIndex}
            clickThumbNail={this.clickThumbNail}
            thumbnails={this.state.thumbnails} 
            loadingVideos={this.state.loadingVideos} 
            videoCount={this.state.videoCount} 
            videos={this.state.videos}
            remvoeVideo={this.removeVideo}
          />
          <div className=" labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Survey Comparison Photos</label></div>
          <div className="detailContentPanel">
          <div id="comparisonPhotoDiv" />
          {((this.state.compareArr && this.state.compareArr.length > 0) && (this.state.compareArr && this.state.comparisonGalleryItems && this.state.comparisonGalleryItems.length == this.state.compareArr.length )) &&
            <div>
              <div className="row">
                <div className="compareLabel">Survey Photo</div>
                <div className="compareLabel">Comparison Photo</div>
              </div>
              {this.state.compareArr.map((pic, index) =>{
                return(
                  <div key={index} className="comparisonImgRowDiv">
                    <div className="comparisonImgRowInsideDiv"><img id ={pic.surveyMultimediaAssetId} height={pic.CreatedDateSurv != '' ? 400 : 420} src={!pic.ExternalURL ? URL.createObjectURL(this.state.comparisonGalleryItemsSurvey.filter(e => e.SurveyId == pic.surveyMultimediaAssetId)[0].SurveyBlob) : pic.ExternalURL}/> {pic.CreatedDateSurv != '' ? <div className="comparisonImgLabelDiv">{pic.CreatedDateSurv}</div> : null}</div>
                    <div className="comparisonImgRowInsideDiv"><img id ={pic.investigationMultimediaAssetId} height={400} src={URL.createObjectURL(this.state.comparisonGalleryItems.filter(e => e.Id == pic.investigationMultimediaAssetId)[0].Blob)}/><div className="comparisonImgLabelDiv">{pic.CreatedDateInv}</div></div>
                  </div>
                )
              })}
            </div>}
            
            <div className="barLoaderPortal">
              <BarLoader sizeUnit={"px"} size={200} color={"#095399"} loading={this.state.loadingComparisons}/>
            </div>
            
          </div>
              <AttachedDocumentPanel showingHistoryRecord={this.props.showingHistoryRecord} addToDocs={(doc) =>{let docs = this.state.documents; docs.push(doc); this.setState({documents : docs})}} loadingDocuments={this.state.loading} entityId={this.props.incident.InvestigationId} entityTypeId={1} Documents ={this.state.documents}/>
          {!this.props.showingHistoryRecord && <PhotoUpload entityId={this.props.incident.InvestigationId} addToGallery={(image)=>{let images = this.state.images; images.push({original : URL.createObjectURL(image), thumbnail : URL.createObjectURL(image)}); this.setState({images}); this.state.galleryItems.push(image)}} entityTypeId={1}/>}
        </div>
        </div>

        <Popup modal={true} lockScroll={false} open={this.state.modalOpen} onClose={this.closeModal} closeOnDocumentClick closeOnEscape position='top left' >
          <div style={{height:"100%"}} className="justify-content-center text-center">
            <img className = "justify-content-center text-center popupPic" id ="popupPic" src={this.state.picUrl}  />
          </div>
        </Popup>
        {this.state.updateStatusText != "" && 
        <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText: ""})}} autoHideDuration={6000} onClose={this.handleClose}
          anchorOrigin={{ vertical: 'top', horizontal : 'center'}}>
          <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant ="filled">
            {this.state.updateStatusText}
          </Alert>
        </Snackbar>}
        
      {this.state.loading ? <div className="barLoaderPortal"><BarLoader sizeUnit={"px"} size={200} color={"#095399"} loading={this.state.loading}/></div> : null }
    </div>
    );
  }
}

InvestigationBody.propTypes = {
  incident: PropTypes.object,
  setPictureCount : PropTypes.func,
  city : PropTypes.string,
  stateAddress: PropTypes.string,
  streetAddress: PropTypes.string,
  row: PropTypes.string,
  eventType: PropTypes.string,
  operationType: PropTypes.string,
  facilityType: PropTypes.string,
  lineSize: PropTypes.string,
  lineMaterial: PropTypes.string,
  jointTrench: PropTypes.bool,
  crossBore: PropTypes.bool,
  centerMember: PropTypes.bool,
  damageSurfaceType: PropTypes.string,
  asphaltRemoved: PropTypes.bool,
  measuredDepth: PropTypes.string,
  pedDistance: PropTypes.number,
  responsibleParty: PropTypes.string,
  asphaltDimensions: PropTypes.string,
  arrivalRepairs: PropTypes.bool,
  reportPerformer: PropTypes.string,
  contractorRep: PropTypes.string,
  contractorRepPhone: PropTypes.string,
  personnelOnSite: PropTypes.string,
  guardsOnSite: PropTypes.string,
  vehiclesUsedForRepairs: PropTypes.string,
  replacedOrRepaired: PropTypes.string,
  replacementType: PropTypes.string,
  occurredDuring: PropTypes.string,
  occurredTime: PropTypes.instanceOf(Date),
  damageCompanyName: PropTypes.string,
  damageContact: PropTypes.string,
  damageContactPhone: PropTypes.string,
  damageWorkType: PropTypes.string,
  utilityCompanyName: PropTypes.string,
  utilityContactName: PropTypes.string,
  utilityContactPhone: PropTypes.string,
  utilityRep: PropTypes.string,
  utilityRepTech: PropTypes.string,
  utilityRepOnSite: PropTypes.string,
  utilityRepArrivalDatetime: PropTypes.instanceOf(Date),
  eventDateTime: PropTypes.instanceOf(Date),
  editEventDateTime : PropTypes.func,
  arrivalDateTime : PropTypes.instanceOf(Date),
  editArrivalDateTime : PropTypes.func,
  rootCause : PropTypes.string,
  editRootCause : PropTypes.func,
  excavatorType: PropTypes.string,
  excavationEquipment: PropTypes.string,
  workPerformed: PropTypes.string,
  excavatorDowntime: PropTypes.bool,
  excavatorDowntimeHours: PropTypes.number,
  excavatorDowntimeCost: PropTypes.number,
  notificationOneCallNotified: PropTypes.bool,
  locatorType: PropTypes.string,
  excavationExempt: PropTypes.bool,
  workAreaWhiteLined: PropTypes.bool,
  ticketNumber: PropTypes.string,
  damageTicketNumber: PropTypes.string,
  dateLocated: PropTypes.instanceOf(Date),
  locatedBy: PropTypes.string,
  eventInterruption: PropTypes.bool,
  interruptionHours: PropTypes.string,
  customersAffected: PropTypes.number,
  causeDamageWithinDigArea: PropTypes.string,
  causeDamageWithinTime: PropTypes.string,
  causeMarksAccurate: PropTypes.string,
  causeMarksDestroyedConstruction: PropTypes.string,
  causeMarksDestroyedWeather: PropTypes.string,
  causeWiskersUsed: PropTypes.string,
  causeOffsetsUsed: PropTypes.string,
  causeDamagedLineUnlocatable: PropTypes.string,
  causeLineOnPrints: PropTypes.string,
  causeLineInClosureArea: PropTypes.string,
  atFault: PropTypes.string,
  documentationIssue: PropTypes.string,
  documentationIssueComments: PropTypes.string,
  locateRequested: PropTypes.string,
  damageWithToleranceZone: PropTypes.string,
  noLocatesOnFacility: PropTypes.string,
  damageExposedFacility: PropTypes.string,
  toleranceZone: PropTypes.string,
  markingStandards: PropTypes.string,
  markingStandardsComments: PropTypes.string,
  comments: PropTypes.string,
  editComments: PropTypes.func,
  editMarkingStandardsComments: PropTypes.func,
  editMarkingStandards: PropTypes.func,
  editToleranceZone: PropTypes.func,
  editDamageExposedFacility: PropTypes.func,
  editNoLocatesOnFacility: PropTypes.func,
  editDamageWithToleranceZone: PropTypes.func,
  editLocateRequested: PropTypes.func,
  editDocumentationIssueComments: PropTypes.func,
  editDocumentationIssue: PropTypes.func,
  editAtFault: PropTypes.func,
  editCauseLineInClosureArea: PropTypes.func,
  editCauseLineOnPrints: PropTypes.func,
  editCauseDamagedLineUnlocatable: PropTypes.func,
  editCauseOffsetsUsed: PropTypes.func,
  editCauseWiskersUsed: PropTypes.func,
  editCauseMarksDestroyedWeather: PropTypes.func,
  editCauseMarksDestroyedConstruction: PropTypes.func,
  editCauseMarksAccurate: PropTypes.func,
  editCauseDamageWithinTime: PropTypes.func,
  editCauseDamageWithinDigArea: PropTypes.func,
  editCustomersAffected: PropTypes.func,
  editInterruptionHours: PropTypes.func,
  editEventInterruption: PropTypes.func,
  editLocatedBy: PropTypes.func,
  editDateLocated: PropTypes.func,
  editDamageTicketNumber: PropTypes.func,
  editTicketNumber: PropTypes.func,
  editWorkAreaWhiteLined: PropTypes.func,
  editExcavationExempt: PropTypes.func,
  editLocatorType: PropTypes.func,
  editNotificationOneCallNotified: PropTypes.func,
  editExcavatorDowntimeCost: PropTypes.func,
  editExcavatorDowntimeHours: PropTypes.func,
  editExcavatorDowntime: PropTypes.func,
  editWorkPerformed: PropTypes.func,
  editExcavationEquipment: PropTypes.func,
  editExcavatorType: PropTypes.func,
  editUtilityRepArrivalDatetime: PropTypes.func,
  editUtilityRepOnSite: PropTypes.func,
  editUtilityRepTech: PropTypes.func,
  editUtilityRep: PropTypes.func,
  editUtilityContactPhone: PropTypes.func,
  editUtilityContactName: PropTypes.func,
  editUtilityCompanyName: PropTypes.func,
  editDamageWorkType: PropTypes.func,
  editDamageContactPhone: PropTypes.func,
  editDamageContact: PropTypes.func,
  editDamageCompanyName: PropTypes.func,
  editOccurredTime: PropTypes.func,
  editOccurredDuring: PropTypes.func,
  editReplacementType: PropTypes.func,
  editReplacedOrRepaired: PropTypes.func,
  editVehiclesUsedForRepairs: PropTypes.func,
  editGuardsOnSite: PropTypes.func,
  editPersonnelOnSite: PropTypes.func,
  editContractorRepPhone: PropTypes.func,
  editContractorRep: PropTypes.func,
  editReportPerformer: PropTypes.func,
  editArrivalRepairs: PropTypes.func,
  editAsphaltDimensions: PropTypes.func,
  editResponsibleParty: PropTypes.func,
  editPedDistance: PropTypes.func,
  editMeasuredDepth: PropTypes.func,
  editDamageSurfaceType: PropTypes.func,
  editAsphaltRemoved: PropTypes.func,
  editCenterMember: PropTypes.func,
  editCrossBore: PropTypes.func,
  editJointTrench: PropTypes.func,
  editLineMaterial: PropTypes.func,
  editLineSize: PropTypes.func,
  editFacilityType: PropTypes.func,
  editOperation: PropTypes.func,
  editEventType: PropTypes.func,
  editRow: PropTypes.func,
  editStreetAddress: PropTypes.func,
  editCity: PropTypes.func,
  editStateAddress: PropTypes.func,
  editing: PropTypes.bool,
  county: PropTypes.object,
  editCounty : PropTypes.func,
  countyList : PropTypes.array,
  showingHistoryRecord : PropTypes.bool,
  upToDateInvestigationId : PropTypes.number,
  fromDashboard : PropTypes.bool
};

export default InvestigationBody;
