import React from 'react';
import auth from "../common/Auth";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Redirect } from "react-router-dom";
import { BarLoader } from "react-spinners";

class DataDownloadPage extends React.Component{
    constructor(){
      super();
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let downloadBlock = params.get('i');
      let folders = params.get('n');
      let folderNum = 0;
      if(folders && folders != ''){
        folderNum = parseInt(folders);
      }
      this.state = {downloadBlock, updateStatusText: "Initiating Download...", alertSeverity:"info", folderNum, fileDownloadStarted : false};
      this.downloadMultipleZips = this.downloadMultipleZips.bind(this);
    }

    handleClose(){
    }
    

    sleep(ms){
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    async downloadMultipleZips(){
      for(let i = 0; i < this.state.folderNum; i++){
        this.setState({fileDownloadStarted : false});
        //let body = { DownloadAzureBlock : this.state.downloadBlock, UserId : auth.employeeId()}
        let body = { DownloadAzureBlock : this.state.downloadBlock + i, UserId : auth.employeeId()}
        await fetch(process.env.API_URL + "/api/data/getblobdatasecurecode",{
          method: "POST",
          headers: {
            Accept : "application/json",
            "Content-Type" : "application/json; charset=utf-8",
            Authorization : "Bearer " + auth.token()
          },
          body: JSON.stringify(body)
        })
        .then(async r =>{
          if(r.status == 200){
            await r.json().then(async secureCode =>{
              window.open(process.env.API_URL + "/api/data/downloadFile/" +this.state.downloadBlock+i + "/" + secureCode + "/" + auth.employeeId(), '_self');
              while(!this.state.fileDownloadStarted && this.state.fileDownloadStarted != null){
                this.checkStatus(this.state.downloadBlock+i);
                //Wait two seconds before checking again. 
                await this.sleep(2000);
              }
              this.setState({updateStatusText : "Data Download folder " + (i + 1) + " of " + this.state.folderNum + " has begun.", alertSeverity : "success", downloadFinished : i == this.state.folderNum -1, d : i +1});
            });
          }
          else if(r.status == 401){
            this.setState({updateStatusText : "Data folder " + (i + 1) + " of " + this.state.folderNum + " failed to download. Unauthorized", alertSeverity : "error"});
          }
          else{
            this.setState({updateStatusText : "Data folder " + (i + 1) + " of " + this.state.folderNum + " failed to download.", alertSeverity : "error"});
          }
        });
      }
    }

    async checkStatus(downloadBlock){
      let body = { DownloadAzureBlock : downloadBlock, UserId : auth.employeeId()}
      fetch(process.env.API_URL + "/api/data/checkBlobProgress",{
        method : "POST",
        headers : {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          r.json().then(initDownload =>{
            this.setState({fileDownloadStarted : initDownload});
          });
        }
        else{
          this.setState({fileDownloadStarted : null});
        }
      });
    }

    async componentDidMount(){
      if(this.state.downloadBlock.includes(".pdf")){
        let body = { DownloadAzureBlock : this.state.downloadBlock, UserId : auth.employeeId()}
        fetch(process.env.API_URL + "/api/data/download",{
          method : "POST",
          headers : {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + auth.token()
          },
          body : JSON.stringify(body)
        }).then(r =>{
          if (r.status == 200){
            r.blob().then(blob =>{
              let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display:none";
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = blob.type == "application/pdf" ? "SiteRight_Data.pdf" : "SiteRight_Data.zip";
                a.click();
                window.URL.revokeObjectURL(url);
                this.setState({updateStatusText : "Data Download Started", alertSeverity:"success", downloadFinished:true, d : 1});
            }).catch(error => { console.log(error.message);this.setState({updateStatusText : "Data Download Failed - Try again or contact support", alertSeverity:"error"});});
          }
          else{
            this.setState({updateStatusText : "Data Download Failed - Try again or contact support", alertSeverity:"error"});
          }
        })
      }
      else if(this.state.folderNum == 0){
        let body = { DownloadAzureBlock : this.state.downloadBlock, UserId : auth.employeeId()}
        fetch(process.env.API_URL + "/api/data/getblobdatasecurecode",{
          method : "POST",
          headers : {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + auth.token()
          },
          body : JSON.stringify(body)
        })
        .then(async r => {
          if(r.status == 200){
            r.json().then(async secureCode =>{
              window.open(process.env.API_URL + "/api/data/downloadFile/"+this.state.downloadBlock + "/" + secureCode + "/" + auth.employeeId(),"_self");
              let interval = setInterval(() =>{
                if(this.state.fileDownloadStarted){
                  clearInterval(interval);
                  this.setState({updateStatusText : "Data Download Started", alertSeverity:"success", downloadFinished:true, d : 1});
                }
                else if(this.state.fileDownloadStarted == null){
                  clearInterval(interval);
                  this.setState({updateStatusText : "Data Download Failed - Try again or contact support", alertSeverity:"error"});
                }
                else{
                  this.checkStatus(this.state.downloadBlock);
                }
              },500)
            });
          }
          else if(r.status == 401){
            this.setState({updateStatusText : "Data Download Failed - Unauthorized", alertSeverity:"error"});
          }
          else {
            this.setState({updateStatusText : "Data Download Failed - Try again or contact support", alertSeverity:"error"});
          }
        });
      }
      else{
        this.downloadMultipleZips();
      }
    }
    

    render(){
      if (this.state.downloadFinished) return <Redirect to={"/reporting?d=" + this.state.d} />;
      else
      return <div className = "col-10 d-flex justify-content-center p-0 mb-5">
        <div className="container-fluid">
          <div className="barLoaderPortal" style={{margin: '0px auto', width : 250, position : 'relative', top : 25, right: 105}}>
            <BarLoader sizeUnit={"px"} size={150} color={"#095399"}
              loading={ !this.state.downloadFinished && this.state.alertSeverity != "error" } />
          </div>
        </div>
        <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={6000} onClose={this.handleClose} 
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
            {this.state.updateStatusText}
          </Alert>
        </Snackbar>
    </div>
    }
}

export default DataDownloadPage;