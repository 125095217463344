import React from "react";
import PropTypes from "prop-types";

class SignUpPage extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    this.props.forceRefresh();
  }

  render() {
    return (
      <div
        className="col-8 d-flex justify-content-center p-0 m-0"
        style={{ backgroundColor: "#E9E9EF" }}>
        <label>Sign Up Page Stub</label>
      </div>
    );
  } //render
} //class

SignUpPage.propTypes = {
  forceRefresh: PropTypes.func
};

export default SignUpPage;
