import React from "react";
import SurveyStrip from "./SurveyStrip";
import InvestigationBody from "./InvestigationBody";
import PropTypes from "prop-types";
import auth from '../common/Auth';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Prompt } from 'react-router-dom';
import CountyUtility from '../../Utility/CountyUtility';
import { get, set } from 'idb-keyval';
import { BarLoader } from 'react-spinners'
import { MDBDataTable } from "mdbreact";
import OrgUtility from '../../Utility/Utility';

class InvestigationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultSet: [],
      pictureCount : 0,
      updateStatusText : "",
      alertSeverity : "success",
      editing : false,
      loadingUpdate : "Save",
    };
    this.CountyUtil = new CountyUtility();
    this.setFields = this.setFields.bind(this);
    this.setPictureCount = this.setPictureCount.bind(this);
    this.setUpEditing = this.setUpEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.saveSurvey = this.saveSurvey.bind(this);
    this.editStateAddress = this.editStateAddress.bind(this);
    this.editCity = this.editCity.bind(this);
    this.editStreetAddress = this.editStreetAddress.bind(this);
    this.editNearMiss = this.editNearMiss.bind(this);
    this.editRow = this.editRow.bind(this);
    this.editOperation = this.editOperation.bind(this);
    this.editFacilityType = this.editFacilityType.bind(this);
    this.editEventType = this.editEventType.bind(this);
    this.editLineSize = this.editLineSize.bind(this);
    this.editLineMaterial = this.editLineMaterial.bind(this);
    this.editJointTrench = this.editJointTrench.bind(this);
    this.editCrossBore = this.editCrossBore.bind(this);
    this.editCenterMember = this.editCenterMember.bind(this);
    this.editAsphaltRemoved = this.editAsphaltRemoved.bind(this);
    this.editDamageSurfaceType = this.editDamageSurfaceType.bind(this);
    this.editMeasuredDepth = this.editMeasuredDepth.bind(this);
    this.editPedDistance = this.editPedDistance.bind(this);
    this.editResponsibleParty = this.editResponsibleParty.bind(this);
    this.editAsphaltDimensions = this.editAsphaltDimensions.bind(this);
    this.editArrivalRepairs = this.editArrivalRepairs.bind(this);
    this.editReportPerformer = this.editReportPerformer.bind(this);
    this.editContractorRep = this.editContractorRep.bind(this);
    this.editContractorRepPhone = this.editContractorRepPhone.bind(this);
    this.editPersonnelOnSite = this.editPersonnelOnSite.bind(this);
    this.editGuardsOnSite = this.editGuardsOnSite.bind(this);
    this.editVehiclesUsedForRepairs = this.editVehiclesUsedForRepairs.bind(this);
    this.editReplacedOrRepaired = this.editReplacedOrRepaired.bind(this);
    this.editReplacementType = this.editReplacementType.bind(this);
    this.editOccurredDuring = this.editOccurredDuring.bind(this);
    this.editOccurredTime = this.editOccurredTime.bind(this);
    this.editDamageCompanyName = this.editDamageCompanyName.bind(this);
    this.editDamageContact = this.editDamageContact.bind(this);
    this.editDamageContactPhone = this.editDamageContactPhone.bind(this);
    this.editDamageWorkType = this.editDamageWorkType.bind(this);
    this.editUtilityCompanyName = this.editUtilityCompanyName.bind(this);
    this.editUtilityContactName = this.editUtilityContactName.bind(this);
    this.editUtilityContactPhone = this.editUtilityContactPhone.bind(this);
    this.editUtilityRep = this.editUtilityRep.bind(this);
    this.editUtilityRepTech = this.editUtilityRepTech.bind(this);
    this.editUtilityRepOnSite = this.editUtilityRepOnSite.bind(this);
    this.editUtilityRepArrivalDatetime = this.editUtilityRepArrivalDatetime.bind(this);
    this.editEventDateTime = this.editEventDateTime.bind(this);
    this.editExcavatorType = this.editExcavatorType.bind(this);
    this.editExcavationEquipment = this.editExcavationEquipment.bind(this);
    this.editWorkPerformed = this.editWorkPerformed.bind(this);
    this.editExcavatorDowntime = this.editExcavatorDowntime.bind(this);
    this.editExcavatorDowntimeHours = this.editExcavatorDowntimeHours.bind(this);
    this.editExcavatorDowntimeCost = this.editExcavatorDowntimeCost.bind(this);
    this.editNotificationOneCallNotified = this.editNotificationOneCallNotified.bind(this);
    this.editLocatorType = this.editLocatorType.bind(this);
    this.editExcavationExempt = this.editExcavationExempt.bind(this);
    this.editWorkAreaWhiteLined = this.editWorkAreaWhiteLined.bind(this);
    this.editTicketNumber = this.editTicketNumber.bind(this);
    this.editDamageTicketNumber = this.editDamageTicketNumber.bind(this);
    this.editDateLocated = this.editDateLocated.bind(this);
    this.editLocatedBy = this.editLocatedBy.bind(this);
    this.editEventInterruption = this.editEventInterruption.bind(this);
    this.editInterruptionHours = this.editInterruptionHours.bind(this);
    this.editCustomersAffected = this.editCustomersAffected.bind(this);
    this.editCauseDamageWithinDigArea = this.editCauseDamageWithinDigArea.bind(this);
    this.editCauseDamageWithinTime = this.editCauseDamageWithinTime.bind(this);
    this.editCauseMarksAccurate = this.editCauseMarksAccurate.bind(this);
    this.editCauseMarksDestroyedConstruction = this.editCauseMarksDestroyedConstruction.bind(this);
    this.editCauseMarksDestroyedWeather = this.editCauseMarksDestroyedWeather.bind(this);
    this.editCauseWiskersUsed = this.editCauseWiskersUsed.bind(this);
    this.editCauseOffsetsUsed = this.editCauseOffsetsUsed.bind(this);
    this.editCauseDamagedLineUnlocatable = this.editCauseDamagedLineUnlocatable.bind(this);
    this.editCauseLineOnPrints = this.editCauseLineOnPrints.bind(this);
    this.editCauseLineInClosureArea = this.editCauseLineInClosureArea.bind(this);
    this.editAtFault = this.editAtFault.bind(this);
    this.editDocumentationIssue = this.editDocumentationIssue.bind(this);
    this.editDocumentationIssueComments = this.editDocumentationIssueComments.bind(this);
    this.editLocateRequested = this.editLocateRequested.bind(this);
    this.editDamageWithToleranceZone = this.editDamageWithToleranceZone.bind(this);
    this.editNoLocatesOnFacility = this.editNoLocatesOnFacility.bind(this);
    this.editDamageExposedFacility = this.editDamageExposedFacility.bind(this);
    this.editToleranceZone = this.editToleranceZone.bind(this);
    this.editMarkingStandards = this.editMarkingStandards.bind(this);
    this.editMarkingStandardsComments = this.editMarkingStandardsComments.bind(this);
    this.editComments = this.editComments.bind(this);
    this.editCounty = this.editCounty.bind(this);
    this.editRootCause = this.editRootCause.bind(this);
    this.editArrivalDateTime = this.editArrivalDateTime.bind(this);
    this.goToMap = this.goToMap.bind(this);
    this.fetchHistory = this.fetchHistory.bind(this);
    this.closeHistoryList = this.closeHistoryList.bind(this);
    this.closeHistoryRecord = this.closeHistoryRecord.bind(this);
  }

  componentWillMount() {
    let investigation = {};
    if (this.props.location.incident != null){
      investigation = this.props.location.incident;
      let inves = this.props.location.incident;
      inves.__proto__ = null;
      inves.Details = null; //Some react weirdness that causes the object to not be able to be stringified.
      localStorage.setItem('currentInvestigation', JSON.stringify(inves));
    }
    else{
      let inv = localStorage.getItem("currentInvestigation");
      if(inv && inv != ''){
        investigation = JSON.parse(inv);
      }
    }

    // EMPTY OBJECT CHECK
    if (Object.entries(investigation).length === 0)
      investigation = null;

    this.setState({investigation, upToDateInvestigationId : investigation ? investigation.InvestigationId : 0});
    this.setFields(investigation);
  }

  goToMap(){
    let investigation = this.state.investigation;
    localStorage.setItem("mapInvestigation", JSON.stringify(investigation));
    window.location.href = "/surveymap?fromDetails=true&detailsType=investigation";
  }

  setFields(investigation){
    if(investigation && investigation.County && typeof(investigation.County) == "string"){
      investigation.County = this.CountyUtil.getObjectFromAbbrev(investigation.State, investigation.County);
    }
    let lineSizeMeasure = investigation ? investigation.LineSize : null;
    let lineMaterial = "";
    let lineSize = "";
    if(lineSizeMeasure){
      lineSizeMeasure = lineSizeMeasure.split(" ");
      lineSize = lineSizeMeasure[0];
      for(let i = 1; i< lineSizeMeasure.length -1;i++){
        lineSize += (" " + lineSizeMeasure[i]);
      }
      lineMaterial = lineSizeMeasure[lineSizeMeasure.length -1];
    }
    this.setState({streetAddress  :investigation ? investigation.Address : '',
    city : investigation ? investigation.City : '',
    stateAddress : investigation ? investigation.State : '',
    editRow : investigation ? investigation.Rightofway : '',
    nearMiss : investigation ? investigation.FacilityNearMiss : '',
    operationType : investigation ? investigation.FacilityOperationType : '',
    facilityType : investigation ? investigation.FacilityType : '',
    eventType : investigation ? investigation.EventType : '',
    lineSize,
    lineMaterial,
    jointTrench : investigation ? investigation.FacilityJointTrench : '',
    crossBore : investigation ? investigation.Crossbore : '',
    centerMember : investigation ? investigation.OneCallMember : '',
    asphaltRemoved : investigation ? investigation.AsphaltRemoved : '',
    damageSurfaceType : investigation ? investigation.DamageSurfaceType : '',
    measuredDepth : investigation ? investigation.MeasuredDepth : '',
    pedDistance : investigation ? investigation.PedToPedDistance != null ? investigation.PedToPedDistance : '' : '',
    responsibleParty : investigation ? investigation.ResponsibleParty != null ? investigation.ResponsibleParty : '' : '',
    asphaltDimensions : investigation ? investigation.AsphaltConcreteDimensions != null ? investigation.AsphaltConcreteDimensions : '' : '',
    arrivalRepairs : investigation ? investigation.ArrivalRepairs : '',
    reportPerformer : investigation ? investigation.ReportPerformer != null ? investigation.ReportPerformer : '' : '',
    contractorRep : investigation ? investigation.ContractorRepresentative != null ? investigation.ContractorRepresentative : '' : '',
    contractorRepPhone : investigation ? investigation.ContractorRepresentativePhone != null ? investigation.ContractorRepresentativePhone : '' : '',
    personnelOnSite : investigation ? investigation.PersonnelOnSite != null ? investigation.PersonnelOnSite : '' : '',
    guardsOnSite : investigation ? investigation.GuardsOnSite != null ? investigation.GuardsOnSite : '' : '',
    vehiclesUsedForRepairs : investigation ? investigation.VehiclesUsedForRepairs != null ? investigation.VehiclesUsedForRepairs : '' : '',
    replacedOrRepaired :  investigation ? investigation.FacilitiesReplacedOrRepaired != null ? investigation.FacilitiesReplacedOrRepaired : '' : '',
    replacementType :  investigation ? investigation.ReplacementType != null ? investigation.ReplacementType : '' : '',
    occurredDuring :  investigation ? investigation.OccurredDuring != null ? investigation.OccurredDuring : '' : '',
    occurredTime :  investigation ? investigation.OccurredTime != null ? new Date(investigation.OccurredTime[investigation.OccurredTime.length -1] !="Z" ? investigation.OccurredTime +"Z" : investigation.OccurredTime ) : null : null,
    damageCompanyName :  investigation ? investigation.DamageCompanyName != null ? investigation.DamageCompanyName : '' : '',
    damageContact :  investigation ? investigation.DamageContact != null ? investigation.DamageContact : '' : '',
    damageContactPhone :  investigation ? investigation.DamageContactPhone != null ? investigation.DamageContactPhone : '' : '',
    damageWorkType :  investigation ? investigation.DamageWorkType != null ? investigation.DamageWorkType : '' : '',
    utilityCompanyName :  investigation ? investigation.UtilityCompanyName != null ? investigation.UtilityCompanyName : '' : '',
    utilityContactName :  investigation ? investigation.UtilityContactName != null ? investigation.UtilityContactName : '' : '',
    utilityContactPhone :  investigation ? investigation.UtilityContactPhone != null ? investigation.UtilityContactPhone : '' : '',
    utilityRep :  investigation ? investigation.UtilityRep != null ? investigation.UtilityRep : '' : '',
    utilityRepTech :  investigation ? investigation.UtilityRepTech != null ? investigation.UtilityRepTech : '' : '',
    utilityRepOnSite :  investigation ? investigation.UtilityRepOnSite != null ? investigation.UtilityRepOnSite : '' : '',
    utilityRepArrivalDatetime :  investigation ? investigation.UtilityRepArrivalDatetime != null ? new Date(investigation.UtilityRepArrivalDatetime[investigation.UtilityRepArrivalDatetime.length-1] != "Z" ? investigation.UtilityRepArrivalDatetime+"Z" : investigation.UtilityRepArrivalDatetime) : null : null,
    dateLocated :  investigation ? investigation.DateLocated != null ? new Date(investigation.DateLocated[investigation.DateLocated.length-1] != "Z" ? investigation.DateLocated +"Z" : investigation.DateLocated) : null : null,
    excavatorType :  investigation ? investigation.ExcavatorType != null ? investigation.ExcavatorType : '' : '',
    excavationEquipment :  investigation ? investigation.ExcavationEquipment != null ? investigation.ExcavationEquipment : '' : '',
    workPerformed :  investigation ? investigation.WorkPerformed != null ? investigation.WorkPerformed : '' : '',
    excavatorDowntime :  investigation ? investigation.ExcavatorDowntime != null ? investigation.ExcavatorDowntime : '' : '',
    excavatorDowntimeHours :  investigation ? investigation.ExcavatorDowntimeHours != null ? investigation.ExcavatorDowntimeHours : '' : '',
    excavatorDowntimeCost :  investigation ? investigation.ExcavatorDowntimeCost != null ? investigation.ExcavatorDowntimeCost : '' : '',
    notificationOneCallNotified :  investigation ? investigation.NotificationOneCallNotified != null ? investigation.NotificationOneCallNotified : '' : '',
    locatorType :  investigation ? investigation.LocatorType != null ? investigation.LocatorType : '' : '',
    excavationExempt :  investigation ? investigation.ExcavationExempt != null ? investigation.ExcavationExempt : '' : '',
    workAreaWhiteLined :  investigation ? investigation.WorkAreaWhiteLined != null ? investigation.WorkAreaWhiteLined : '' : '',
    ticketNumber :  investigation ? investigation.TicketNumber != null ? investigation.TicketNumber : '' : '',
    damageTicketNumber :  investigation ? investigation.DamageTicketNumber != null ? investigation.DamageTicketNumber : '' : '',
    locatedBy :  investigation ? investigation.LocatedBy != null ? investigation.LocatedBy : '' : '',
    eventInterruption :  investigation ? investigation.EventInterruption != null ? investigation.EventInterruption : '' : '',
    interruptionHours :  investigation ? investigation.InterruptionHours != null ? investigation.InterruptionHours : '' : '',
    customersAffected :  investigation ? investigation.CustomersAffected != null ? investigation.CustomersAffected : '' : '',
    causeDamageWithinDigArea :  investigation ? investigation.CauseDamageWithinDigArea != null ? investigation.CauseDamageWithinDigArea : '' : '',
    causeDamageWithinTime :  investigation ? investigation.CauseDamageWithinTime != null ? investigation.CauseDamageWithinTime : '' : '',
    causeMarksAccurate :  investigation ? investigation.CauseMarksAccurate != null ? investigation.CauseMarksAccurate : '' : '',
    causeMarksDestroyedConstruction :  investigation ? investigation.CauseMarksDestroyedConstruction != null ? investigation.CauseMarksDestroyedConstruction : '' : '',
    causeMarksDestroyedWeather :  investigation ? investigation.CauseMarksDestroyedWeather != null ? investigation.CauseMarksDestroyedWeather : '' : '',
    causeWiskersUsed :  investigation ? investigation.CauseWiskersUsed != null ? investigation.CauseWiskersUsed : '' : '',
    causeOffsetsUsed :  investigation ? investigation.CauseOffsetsUsed != null ? investigation.CauseOffsetsUsed : '' : '',
    causeDamagedLineUnlocatable :  investigation ? investigation.CauseDamagedLineUnlocatable != null ? investigation.CauseDamagedLineUnlocatable : '' : '',
    causeLineOnPrints :  investigation ? investigation.CauseLineOnPrints != null ? investigation.CauseLineOnPrints : '' : '',
    causeLineInClosureArea :  investigation ? investigation.CauseLineInClosureArea != null ? investigation.CauseLineInClosureArea : '' : '',
    atFault :  investigation ? investigation.AtFault != null ? investigation.AtFault : '' : '',
    documentationIssue :  investigation ? investigation.DocumentationIssue != null ? investigation.DocumentationIssue : '' : '',
    documentationIssueComments :  investigation ? investigation.DocumentationIssueComments != null ? investigation.DocumentationIssueComments : '' : '',
    locateRequested :  investigation ? investigation.LocateRequested != null ? investigation.LocateRequested : '' : '',
    damageWithToleranceZone :  investigation ? investigation.DamageWithToleranceZone != null ? investigation.DamageWithToleranceZone : '' : '',
    noLocatesOnFacility :  investigation ? investigation.NoLocatesOnFacility != null ? investigation.NoLocatesOnFacility : '' : '',
    damageExposedFacility :  investigation ? investigation.DamageExposedFacility != null ? investigation.DamageExposedFacility : '' : '',
    toleranceZone :  investigation ? investigation.ToleranceZone != null ? investigation.ToleranceZone : '' : '',
    markingStandards :  investigation ? investigation.MarkingStandards != null ? investigation.MarkingStandards : '' : '',
    markingStandardsComments :  investigation ? investigation.MarkingStandardsComments != null ? investigation.MarkingStandardsComments : '' : '',
    comments :  investigation ? investigation.Comments != null ? investigation.Comments : '' : '',
    county : investigation ? investigation.County : '',
    countyList : investigation ? this.CountyUtil.getStateList(investigation.State) : {label : "",abbrev : ""},
    rootCause : investigation ? investigation.RootCause != null ? investigation.RootCause : '' : '',
    arrivalDateTime : investigation ? investigation.ArrivalDateTime != null ? new Date(investigation.ArrivalDateTime[investigation.ArrivalDateTime.length-1] != "Z" ? investigation.ArrivalDateTime +"Z" : investigation.ArrivalDateTime) : null : null,
    eventDateTime : investigation ? investigation.EventDate != null ? new Date(investigation.EventDate[investigation.EventDate.length -1] != "Z" ? investigation.EventDate + "Z" : investigation.EventDate) : null : null,
  });
  }

  componentDidMount() {
    if(this.props.forceRefresh){
      this.props.forceRefresh();
    }
  }


  componentWillUnmount(){
    localStorage.removeItem('currentInvestigation');
    window.onbeforeunload = undefined;
  }

  componentDidUpdate(prevProps){
    if(prevProps.location.incident && this.props.location.incident && (prevProps.location.incident.Id != this.props.location.incident.Id)){
      this.setState({investigation : this.props.location.incident,editing: false, upToDateInvestigationId : this.props.location.incident ? this.props.location.incident.InvestigationId : 0,showingHistoryRecord : false, showHistoryList : false, loadingHistory : false});
      this.setFields(this.props.location.incident);
    }
    if(this.state.editing){
      window.onbeforeunload = () => true
    } else{
      window.onbeforeunload = undefined;
    }

    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  setPictureCount(pictureCount){
    this.setState({pictureCount});
  }

  setUpEditing(){
    this.setState({editing : true});
  }

  cancelEditing(){
    this.setState({editing : false});
    this.setFields(this.state.investigation);
  }

  editCounty(county){
    this.setState({county : this.CountyUtil.getObjectFromDisplay(this.state.stateAddress,county)});
  }

  editStateAddress(stateAddress){
    this.setState({stateAddress, county: stateAddress != this.state.stateAddress ? {label : "", abbrev : ""} : this.state.county, countyList : this.CountyUtil.getStateList(stateAddress)});
  }

  editStreetAddress(streetAddress){
    this.setState({streetAddress});
  }
  editCity(city){
    this.setState({city});
  }

  editRow(row){
    this.setState({row});
  }

  editNearMiss(nearMiss){
    this.setState({nearMiss});
  }

  editOperation(operationType){
    this.setState({operationType});
  }

  editFacilityType(facilityType){
    this.setState({facilityType});
  }

  editEventType(eventType){
    this.setState({eventType});
  }

  editLineSize(lineSize){
    this.setState({lineSize});
  }

  editLineMaterial(lineMaterial){
    this.setState({lineMaterial});
  }

  editJointTrench(jointTrench){
    this.setState({jointTrench});
  }

  editCrossBore(crossBore){
    this.setState({crossBore});
  }

  editCenterMember(centerMember){
    this.setState({centerMember});
  }

  editAsphaltRemoved(asphaltRemoved){
    this.setState({asphaltRemoved});
  }

  editDamageSurfaceType(damageSurfaceType){
    this.setState({damageSurfaceType}); 
  }

  editMeasuredDepth(measuredDepth){
    this.setState({measuredDepth});
  }

  editPedDistance(pedDistance){
    this.setState({pedDistance});
  }

  editResponsibleParty(responsibleParty){
    this.setState({responsibleParty});
  }

  editAsphaltDimensions(asphaltDimensions){
    this.setState({asphaltDimensions});
  }

  editArrivalRepairs(arrivalRepairs){
    this.setState({arrivalRepairs});
  }

  editReportPerformer(reportPerformer){
    this.setState({reportPerformer});
  }

  editContractorRep(contractorRep){
    this.setState({contractorRep});
  }

  editContractorRepPhone(contractorRepPhone){
    this.setState({contractorRepPhone});
  }

  editPersonnelOnSite(personnelOnSite){
    this.setState({personnelOnSite});
  }

  editGuardsOnSite(guardsOnSite){
    this.setState({guardsOnSite});
  }

  editVehiclesUsedForRepairs(vehiclesUsedForRepairs){
    this.setState({vehiclesUsedForRepairs});
  }

  editReplacedOrRepaired(replacedOrRepaired){
    this.setState({replacedOrRepaired});
  }

  editReplacementType(replacementType){
    this.setState({replacementType});
  }

  editOccurredDuring(occurredDuring){
    this.setState({occurredDuring});
  }

  editOccurredTime(occurredTime){
    this.setState({occurredTime, changingOccurredTime : true});
  }

  editDamageCompanyName(damageCompanyName){
    this.setState({damageCompanyName});
  }

  editDamageContact(damageContact){
    this.setState({damageContact});
  }

  editDamageContactPhone(damageContactPhone){
    this.setState({damageContactPhone});
  }

  editDamageWorkType(damageWorkType){
    this.setState({damageWorkType});
  }

  editUtilityCompanyName(utilityCompanyName){
    this.setState({utilityCompanyName});
  }

  editUtilityContactName(utilityContactName){
    this.setState({utilityContactName});
  }

  editUtilityContactPhone(utilityContactPhone){
    this.setState({utilityContactPhone});
  }

  editUtilityRep(utilityRep){
    this.setState({utilityRep});
  }

  editUtilityRepTech(utilityRepTech){
    this.setState({utilityRepTech});
  }

  editUtilityRepOnSite(utilityRepOnSite){
    this.setState({utilityRepOnSite});
  }

  editUtilityRepArrivalDatetime(utilityRepArrivalDatetime){
    this.setState({utilityRepArrivalDatetime});
  }

  editExcavatorType(excavatorType){
    this.setState({excavatorType});
  }

  editExcavationEquipment(excavationEquipment){
    this.setState({excavationEquipment});
  }

  editWorkPerformed(workPerformed){
    this.setState({workPerformed});
  }

  editExcavatorDowntime(excavatorDowntime){
    this.setState({excavatorDowntime});
  }

  editExcavatorDowntimeHours(excavatorDowntimeHours){
    this.setState({excavatorDowntimeHours});
  }

  editExcavatorDowntimeCost(excavatorDowntimeCost){
    this.setState({excavatorDowntimeCost});
  }

  editNotificationOneCallNotified(notificationOneCallNotified){
    this.setState({notificationOneCallNotified});
  }

  editLocatorType(locatorType){
    this.setState({locatorType});
  }

  editExcavationExempt(excavationExempt){
    this.setState({excavationExempt});
  }

  editWorkAreaWhiteLined(workAreaWhiteLined){
    this.setState({workAreaWhiteLined});
  }

  editTicketNumber(ticketNumber){
    this.setState({ticketNumber});
  }

  editDamageTicketNumber(damageTicketNumber){
    this.setState({damageTicketNumber});
  }

  editDateLocated(dateLocated){
    this.setState({dateLocated});
  }

  editLocatedBy(locatedBy){
    this.setState({locatedBy});
  }

  editEventInterruption(eventInterruption){
    this.setState({eventInterruption});
  }

  editInterruptionHours(interruptionHours){
    this.setState({interruptionHours});
  }

  editCustomersAffected(customersAffected){
    this.setState({customersAffected});
  }

  editCauseDamageWithinDigArea(causeDamageWithinDigArea){
    this.setState({causeDamageWithinDigArea});
  }

  editCauseDamageWithinTime(causeDamageWithinTime){
    this.setState({causeDamageWithinTime});
  }

  editCauseMarksAccurate(causeMarksAccurate){
    this.setState({causeMarksAccurate});
  }

  editCauseMarksDestroyedConstruction(causeMarksDestroyedConstruction){
    this.setState({causeMarksDestroyedConstruction});
  }

  editCauseMarksDestroyedWeather(causeMarksDestroyedWeather){
    this.setState({causeMarksDestroyedWeather});
  }

  editCauseWiskersUsed(causeWiskersUsed){
    this.setState({causeWiskersUsed});
  }

  editCauseOffsetsUsed(causeOffsetsUsed){
    this.setState({causeOffsetsUsed});
  }

  editCauseDamagedLineUnlocatable(causeDamagedLineUnlocatable){
    this.setState({causeDamagedLineUnlocatable});
  }

  editCauseLineOnPrints(causeLineOnPrints){
    this.setState({causeLineOnPrints});
  }

  editCauseLineInClosureArea(causeLineInClosureArea){
    this.setState({causeLineInClosureArea});
  }

  editAtFault(atFault){
    this.setState({atFault});
  }

  editDocumentationIssue(documentationIssue){
    this.setState({documentationIssue});
  }

  editDocumentationIssueComments(documentationIssueComments){
    this.setState({documentationIssueComments});
  }

  editLocateRequested(locateRequested){
    this.setState({locateRequested});
  }

  editDamageWithToleranceZone(damageWithToleranceZone){
    this.setState({damageWithToleranceZone});
  }

  editNoLocatesOnFacility(noLocatesOnFacility){
    this.setState({noLocatesOnFacility});
  }

  editDamageExposedFacility(damageExposedFacility){
    this.setState({damageExposedFacility});
  }

  editToleranceZone(toleranceZone){
    this.setState({toleranceZone});
  }

  editMarkingStandards(markingStandards){
    this.setState({markingStandards});
  }

  editMarkingStandardsComments(markingStandardsComments){
    this.setState({markingStandardsComments});
  }

  editComments(comments){
    this.setState({comments})
  }
  
  editRootCause(rootCause){
    this.setState({rootCause});
  }

  editArrivalDateTime(arrivalDateTime){
    this.setState({arrivalDateTime});
  }

  editEventDateTime(eventDateTime){
    this.setState({eventDateTime});
  }

  fetchHistory(){
    this.setState({historyStatusText : "RETRIEVING DATA...", loadingHistory : true});
    let body={
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      CreatedDate : this.state.investigation.CreatedDate,
      CreatedBy : this.state.investigation.CreatedBy
    }
    fetch(process.env.API_URL + "/api/investigation/investigationHistory",{
      method : "POST",
      headers : {
        Accept : "application/json;",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(response => response)
    .then((response) =>{
      if(response.status == 200){
        let historyIdList = [];
        response.json().then(responseJson =>{
          let response = JSON.parse(responseJson);
          let investigationHistory = {columns:[{label: "",field:"ViewButton"},{label:"MODIFIED DATE", field:"ModifiedDateFormatted"},{label : "UPDATED BY", field:"ModifiedByDisplayName"}], rows:[]};
          if(response.InvestigationHistoryCollection && response.InvestigationHistoryCollection.length > 0){
            historyIdList.push(this.state.investigation.InvestigationId);
            response.InvestigationHistoryCollection.forEach(record =>{
              record.ViewButton = <button className="anchorButton submitButton incident padded" onClick={() =>{this.viewHistoryRecord(record)}}><div className="text-center full">VIEW</div></button>;
              record.ModifiedDateFormatted = new Date(record.ModifiedDate[record.ModifiedDate.length]-1 !="Z" ? record.ModifiedDate + "Z" : record.ModifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
              investigationHistory.rows.push(record);
              historyIdList.push(record.InvestigationId);
            })
          }
          this.setState({historyStatusText : "", loadingHistory : false, investigationHistory, showHistoryList : true, historyIdList});
        });
      }
      else{
        this.setState({historyStatusText : "There was an error retrieving history. Try again.", loadingHistory : false, showHistoryList : true});
      }
    })
    .catch(e =>{
      console.log(e);
      this.setState({historyStatusText : "There was an error retrieving history. Try again.", loadingHistory : false, showHistoryList : true});
    });
  }

  viewHistoryRecord(record){
    let upToDateInvestigationData = JSON.parse(JSON.stringify(this.state.investigation));
    this.setState({upToDateInvestigationData, investigation : record, historyIdList : null, showHistoryList : false, showingHistoryRecord : true, historyRecordId : record.InvestigationId});
  }

  saveSurvey(){
    this.setState({loadingUpdate : "Saving..."});
    let now = new Date();
    let investigationModel = this.state.investigation;
    investigationModel.DirtSubmitted = null;
    investigationModel.__proto__ = null;
    investigationModel.Address = this.state.streetAddress;
    investigationModel.City = this.state.city;
    investigationModel.State = this.state.stateAddress;
    investigationModel.Rightofway = this.state.row ? this.state.row : 'Unknown/Other';
    investigationModel.FacilityNearMiss = this.state.nearMiss;
    investigationModel.FacilityOperationType = this.state.operationType;
    investigationModel.FacilityType = this.state.facilityType;
    investigationModel.EventType = this.state.eventType;
    investigationModel.LineSize = this.state.lineSize + ' ' + this.state.lineMaterial;
    investigationModel.LineMaterial = this.state.lineMaterial;
    investigationModel.FacilityJointTrench = this.state.jointTrench;
    investigationModel.Crossbore = this.state.crossBore;
    investigationModel.OneCallMember = this.state.centerMember;
    investigationModel.AsphaltRemoved = this.state.asphaltRemoved;
    investigationModel.DamageSurfaceType = this.state.damageSurfaceType;
    investigationModel.MeasuredDepth = this.state.measuredDepth;
    investigationModel.PedToPedDistance =this.state.pedDistance;
    investigationModel.ResponsibleParty = this.state.responsibleParty;
    investigationModel.AsphaltConcreteDimensions = this.state.asphaltDimensions;
    investigationModel.ArrivalRepairs = this.state.arrivalRepairs;
    investigationModel.ReportPerformer = this.state.reportPerformer;
    investigationModel.ContractorRepresentative = this.state.contractorRep;
    investigationModel.ContractorRepresentativePhone = this.state.contractorRepPhone;
    investigationModel.PersonnelOnSite = this.state.personnelOnSite;
    investigationModel.GuardsOnSite = this.state.guardsOnSite;
    investigationModel.VehiclesUsedForRepairs = this.state.vehiclesUsedForRepairs;
    investigationModel.FacilitiesReplacedOrRepaired = this.state.replacedOrRepaired;
    investigationModel.ReplacementType = this.state.replacementType;
    investigationModel.OccurredDuring = this.state.occurredDuring;
    investigationModel.OccurredTime = this.state.occurredTime;
    if(this.state.changingOccurredTime){
      investigationModel.OccurredTimeUTCOffset = -now.getTimezoneOffset()/60;
    }
    investigationModel.DamageCompanyName = this.state.damageCompanyName;
    investigationModel.DamageContact = this.state.damageContact;
    investigationModel.DamageContactPhone = this.state.damageContactPhone;
    investigationModel.DamageWorkType = this.state.damageWorkType;
    investigationModel.UtilityCompanyName = this.state.utilityCompanyName;
    investigationModel.UtilityContactName = this.state.utilityContactName;
    investigationModel.UtilityContactPhone = this.state.utilityContactPhone;
    investigationModel.UtilityRep = this.state.utilityRep;
    investigationModel.UtilityRepTech = this.state.utilityRepTech;
    investigationModel.UtilityRepOnSite = this.state.utilityRepOnSite;
    investigationModel.UtilityRepArrivalDatetime = this.state.utilityRepArrivalDatetime;
    investigationModel.ExcavatorType = this.state.excavatorType;
    investigationModel.ExcavationEquipment = this.state.excavationEquipment;
    investigationModel.WorkPerformed = this.state.workPerformed;
    investigationModel.ExcavatorDowntime = this.state.excavatorDowntime;
    investigationModel.ExcavatorDowntimeHours = this.state.excavatorDowntimeHours;
    investigationModel.ExcavatorDowntimeCost = this.state.excavatorDowntimeCost;
    investigationModel.NotificationOneCallNotified = this.state.notificationOneCallNotified;
    investigationModel.LocatorType = this.state.locatorType;
    investigationModel.excavationExempt = this.state.excavationExempt;
    investigationModel.WorkAreaWhiteLined = this.state.workAreaWhiteLined;
    investigationModel.TicketNumber = this.state.ticketNumber;
    investigationModel.DamageTicketNumber = this.state.damageTicketNumber;
    investigationModel.DateLocated = this.state.dateLocated;
    investigationModel.LocatedBy = this.state.locatedBy;
    investigationModel.EventInterruption = this.state.eventInterruption;
    investigationModel.InterruptionHours = this.state.interruptionHours;
    investigationModel.CustomersAffected = this.state.customersAffected;
    investigationModel.CauseDamageWithinDigArea = this.state.causeDamageWithinDigArea;
    investigationModel.CauseDamageWithinTime = this.state.causeDamageWithinTime;
    investigationModel.CauseMarksAccurate = this.state.causeMarksAccurate;
    investigationModel.CauseMarksDestroyedConstruction = this.state.causeMarksDestroyedConstruction;
    investigationModel.CauseMarksDestroyedWeather = this.state.causeMarksDestroyedWeather;
    investigationModel.CauseWiskersUsed = this.state.causeWiskersUsed;
    investigationModel.CauseOffsetsUsed = this.state.causeOffsetsUsed;
    investigationModel.CauseDamagedLineUnlocatable = this.state.causeDamagedLineUnlocatable;
    investigationModel.CauseLineOnPrints = this.state.causeLineOnPrints;
    investigationModel.CauseLineInClosureArea = this.state.causeLineInClosureArea;
    investigationModel.AtFault = this.state.atFault;
    investigationModel.DocumentationIssue = this.state.documentationIssue;
    investigationModel.DocumentationIssueComments = this.state.documentationIssueComments;
    investigationModel.LocateRequested = this.state.locateRequested;
    investigationModel.DamageWithToleranceZone = this.state.damageWithToleranceZone;
    investigationModel.NoLocatesOnFacility = this.state.noLocatesOnFacility;
    investigationModel.DamageExposedFacility = this.state.damageExposedFacility;
    investigationModel.ToleranceZone = this.state.toleranceZone;
    investigationModel.MarkingStandards = this.state.markingStandards;
    investigationModel.MarkingStandardsComments = this.state.markingStandardsComments;
    investigationModel.Comments = this.state.comments;
    investigationModel.ModifiedBy = auth.userId(),
    investigationModel.ModifiedDate = now;
    investigationModel.ModifiedDateUTCOffset = -now.getTimezoneOffset()/60;
    investigationModel.County = this.state.county.abbrev;
    investigationModel.RootCause = this.state.rootCause;
    investigationModel.ArrivalDateTime = this.state.arrivalDateTime;
    investigationModel.EventDate = this.state.eventDateTime;
    let body = {
      UserId : auth.userId(),
      CompanyId : auth.companyId(),
      Investigation : investigationModel,
      StatusType : 0,
      EntityType : 1,
      RequestId : now.valueOf()
    }
    fetch(process.env.API_URL + "/api/investigation/save",{
      method : "POST",
      headers : {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status ==200){
        r.json().then( async responseJson =>{
          let response = JSON.parse(responseJson);
          if(response.ResponseType == 0){
            let investigation = response.Investigation;
            localStorage.setItem('currentInvestigation', JSON.stringify(investigation));
            let results = await get('resultData');
            if(results){
              let parsedResults = JSON.parse(results);
              if(parsedResults && parsedResults.InvestigationCollection){
                let index = parsedResults.InvestigationCollection.findIndex(x => x.InvestigationId == investigation.InvestigationId);
                if(index != -1){
                  parsedResults.InvestigationCollection[index] = investigation;
                  set('resultData', JSON.stringify(parsedResults));
                }
              }
            }
            this.setState({investigation, updateStatusText : "Updated Investigation.", loadingUpdate : "Save", alertSeverity : "success", editing : false});
            this.setFields(investigation);
          }
          else{
            this.setState({updateStatusText : "Something went wrong. Try again.", loadingUpdate : "Save", alertSeverity : "error"})
          }
        });
      }
      else{
        this.setState({updateStatusText : "Something went wrong. Try again.", loadingUpdate : "Save", alertSeverity : "error"});
      }
    }).catch(error =>{
      console.log(error);
      this.setState({updateStatusText : "Something went wrong. Try again.", loadingUpdate : "Save", alertSeverity : "error"});
    })
  }

  closeHistoryList(){
    this.setState({loadingHistory : false, showHistoryList : false, historyIdList : null});
  }

  closeHistoryRecord(){
    let historyIdList = [];
    if(this.state.investigationHistory && this.state.investigationHistory.rows && this.state.investigationHistory.rows.length > 0){
      historyIdList.push(this.state.upToDateInvestigationData.InvestigationId);
      this.state.investigationHistory.rows.forEach(record =>{
        historyIdList.push(record.InvestigationId);
      });
    }
    this.setState({showingHistoryRecord : false, investigation : this.state.upToDateInvestigationData, showHistoryList : true, historyIdList});
  }

  render() {
    return (
      <div className="col-10 d-flex justify-content-center p-0 m-0" style={{ backgroundColor: "#E9E9EF" }}>
        <Prompt when={this.state.editing} message='You have unsaved changes, are you sure you want to leave?' />
        {this.state.investigation ? 
        <div className="container-fluid">

          <SurveyStrip
            type="Investigation"
            entityTypeId={1} //EntityId: Investigation
            city={this.state.investigation.City}
            state={this.state.investigation.State}
            id={this.state.upToDateInvestigationId}
            address={this.state.investigation.Address}
            OCLVerifyId={this.state.investigation.OCLVerifyID}
            submitToDirt={this.props.submitToDirt}
            DirtSubmitted={this.state.investigation.DirtSubmitted}
            dirtConfigured={this.state.investigation.dirtConfigured}
            entity={this.state.investigation}
            pictureCount={this.state.pictureCount}
            loadingUpdate={this.state.loadingUpdate}
            showingHistoryList={this.state.showHistoryList}
            editing={this.state.editing}
            setUpEditing={this.setUpEditing}
            cancelEditing={this.cancelEditing}
            saveSurvey={this.saveSurvey}
            closeEntity={(this.state.showHistoryList ? this.closeHistoryList : this.state.showingHistoryRecord ? this.closeHistoryRecord : this.props.closeEntity) || this.props.hideClose}
            goToMap={this.goToMap}
            hideReport={this.state.loadingHistory || this.state.showHistoryList}
            hideEdit={this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord}
            hideMap={this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord}
            showingHistoryRecord={this.state.showingHistoryRecord}
            modifiedDate={this.state.showingHistoryRecord ? this.state.investigation.ModifiedDate : null}
            historyRecordId={this.state.historyRecordId}
            historyIdList={this.state.historyIdList}
            fetchHistory={this.state.loadingHistory || this.state.showHistoryList || this.state.showingHistoryRecord ? null : this.fetchHistory}
          />
          {!this.state.loadingHistory && !this.state.showHistoryList && <InvestigationBody 
            fromDashboard={this.props.fromDashboard}
            upToDateInvestigationId={this.state.upToDateInvestigationId}
            showingHistoryRecord={this.state.showingHistoryRecord}
            city={this.state.city}
            stateAddress={this.state.stateAddress}
            streetAddress={this.state.streetAddress}
            row={this.state.row}
            nearMiss={this.state.nearMiss}
            operationType={this.state.operationType}
            facilityType={this.state.facilityType}
            eventType={this.state.eventType}
            lineSize={this.state.lineSize}
            lineMaterial={this.state.lineMaterial}
            jointTrench={this.state.jointTrench}
            crossBore={this.state.crossBore}
            centerMember={this.state.centerMember}
            damageSurfaceType={this.state.damageSurfaceType}
            asphaltRemoved={this.state.asphaltRemoved}
            measuredDepth={this.state.measuredDepth}
            pedDistance={this.state.pedDistance}
            responsibleParty={this.state.responsibleParty}
            asphaltDimensions={this.state.asphaltDimensions}
            arrivalRepairs={this.state.arrivalRepairs}
            reportPerformer={this.state.reportPerformer}
            contractorRep={this.state.contractorRep}
            contractorRepPhone={this.state.contractorRepPhone}
            personnelOnSite={this.state.personnelOnSite}
            guardsOnSite={this.state.guardsOnSite}
            vehiclesUsedForRepairs={this.state.vehiclesUsedForRepairs}
            replacedOrRepaired={this.state.replacedOrRepaired}
            replacementType={this.state.replacementType}
            occurredDuring={this.state.occurredDuring}
            occurredTime={this.state.occurredTime}
            damageCompanyName={this.state.damageCompanyName}
            damageContact={this.state.damageContact}
            damageContactPhone={this.state.damageContactPhone}
            damageWorkType={this.state.damageWorkType}
            utilityCompanyName={this.state.utilityCompanyName}
            utilityContactName={this.state.utilityContactName}
            utilityContactPhone={this.state.utilityContactPhone}
            utilityRep={this.state.utilityRep}
            utilityRepTech={this.state.utilityRepTech}
            utilityRepOnSite={this.state.utilityRepOnSite}
            utilityRepArrivalDatetime={this.state.utilityRepArrivalDatetime}
            excavatorType={this.state.excavatorType}
            excavationEquipment={this.state.excavationEquipment}
            workPerformed={this.state.workPerformed}
            excavatorDowntime={this.state.excavatorDowntime}
            excavatorDowntimeHours={this.state.excavatorDowntimeHours}
            excavatorDowntimeCost={this.state.excavatorDowntimeCost}
            notificationOneCallNotified={this.state.notificationOneCallNotified}
            locatorType={this.state.locatorType}
            excavationExempt={this.state.excavationExempt}
            workAreaWhiteLined={this.state.workAreaWhiteLined}
            ticketNumber={this.state.ticketNumber}
            damageTicketNumber={this.state.damageTicketNumber}
            dateLocated={this.state.dateLocated}
            locatedBy={this.state.locatedBy}
            eventInterruption={this.state.eventInterruption}
            interruptionHours={this.state.interruptionHours}
            customersAffected={this.state.customersAffected}
            causeDamageWithinDigArea={this.state.causeDamageWithinDigArea}
            causeDamageWithinTime={this.state.causeDamageWithinTime}
            causeMarksAccurate={this.state.causeMarksAccurate}
            causeMarksDestroyedConstruction={this.state.causeMarksDestroyedConstruction}
            causeMarksDestroyedWeather={this.state.causeMarksDestroyedWeather}
            causeWiskersUsed={this.state.causeWiskersUsed}
            causeOffsetsUsed={this.state.causeOffsetsUsed}
            causeDamagedLineUnlocatable={this.state.causeDamagedLineUnlocatable}
            causeLineOnPrints={this.state.causeLineOnPrints}
            causeLineInClosureArea={this.state.causeLineInClosureArea}
            atFault={this.state.atFault}
            documentationIssue={this.state.documentationIssue}
            documentationIssueComments={this.state.documentationIssueComments}
            locateRequested={this.state.locateRequested}
            damageWithToleranceZone={this.state.damageWithToleranceZone}
            noLocatesOnFacility={this.state.noLocatesOnFacility}
            damageExposedFacility={this.state.damageExposedFacility}
            toleranceZone={this.state.toleranceZone}
            markingStandards={this.state.markingStandards}
            markingStandardsComments={this.state.markingStandardsComments}
            comments={this.state.comments}
            county={this.state.county}
            countyList={this.state.countyList}
            rootCause={this.state.rootCause}
            eventDateTime={this.state.eventDateTime}
            editEventDateTime={this.editEventDateTime}
            arrivalDateTime={this.state.arrivalDateTime}
            editArrivalDateTime={this.editArrivalDateTime}
            editRootCause={this.editRootCause}
            editCounty={this.editCounty}
            editComments={this.editComments}
            editMarkingStandardsComments={this.editMarkingStandardsComments}
            editMarkingStandards={this.editMarkingStandards}
            editToleranceZone={this.editToleranceZone}
            editDamageExposedFacility={this.editDamageExposedFacility}
            editNoLocatesOnFacility={this.editNoLocatesOnFacility}
            editDamageWithToleranceZone={this.editDamageWithToleranceZone}
            editLocateRequested={this.editLocateRequested}
            editDocumentationIssueComments={this.editDocumentationIssueComments}
            editDocumentationIssue={this.editDocumentationIssue}
            editAtFault={this.editAtFault}
            editCauseLineInClosureArea={this.editCauseLineInClosureArea}
            editCauseLineOnPrints={this.editCauseLineOnPrints}
            editCauseDamagedLineUnlocatable={this.editCauseDamagedLineUnlocatable}
            editCauseOffsetsUsed={this.editCauseOffsetsUsed}
            editCauseWiskersUsed={this.editCauseWiskersUsed}
            editCauseMarksDestroyedWeather={this.editCauseMarksDestroyedWeather}
            editCauseMarksDestroyedConstruction={this.editCauseMarksDestroyedConstruction}
            editCauseMarksAccurate={this.editCauseMarksAccurate}
            editCauseDamageWithinTime={this.editCauseDamageWithinTime}
            editCauseDamageWithinDigArea={this.editCauseDamageWithinDigArea}
            editCustomersAffected={this.editCustomersAffected}
            editInterruptionHours={this.editInterruptionHours}
            editEventInterruption={this.editEventInterruption}
            editLocatedBy={this.editLocatedBy}
            editDateLocated={this.editDateLocated}
            editDamageTicketNumber={this.editDamageTicketNumber}
            editTicketNumber={this.editTicketNumber}
            editWorkAreaWhiteLined={this.editWorkAreaWhiteLined}
            editExcavationExempt={this.editExcavationExempt}
            editLocatorType={this.editLocatorType}
            editNotificationOneCallNotified={this.editNotificationOneCallNotified}
            editExcavatorDowntimeCost={this.editExcavatorDowntimeCost}
            editExcavatorDowntimeHours={this.editExcavatorDowntimeHours}
            editExcavatorDowntime={this.editExcavatorDowntime}
            editWorkPerformed={this.editWorkPerformed}
            editExcavationEquipment={this.editExcavationEquipment}
            editExcavatorType={this.editExcavatorType}
            editUtilityRepArrivalDatetime={this.editUtilityRepArrivalDatetime}
            editUtilityRepOnSite={this.editUtilityRepOnSite}
            editUtilityRepTech={this.editUtilityRepTech}
            editUtilityRep={this.editUtilityRep}
            editUtilityContactPhone={this.editUtilityContactPhone}
            editUtilityContactName={this.editUtilityContactName}
            editUtilityCompanyName={this.editUtilityCompanyName}
            editDamageWorkType={this.editDamageWorkType}
            editDamageContactPhone={this.editDamageContactPhone}
            editDamageContact={this.editDamageContact}
            editDamageCompanyName={this.editDamageCompanyName}
            editOccurredTime={this.editOccurredTime}
            editOccurredDuring={this.editOccurredDuring}
            editReplacementType={this.editReplacementType}
            editReplacedOrRepaired={this.editReplacedOrRepaired}
            editVehiclesUsedForRepairs={this.editVehiclesUsedForRepairs}
            editGuardsOnSite={this.editGuardsOnSite}
            editPersonnelOnSite={this.editPersonnelOnSite}
            editContractorRepPhone={this.editContractorRepPhone}
            editContractorRep={this.editContractorRep}
            editReportPerformer={this.editReportPerformer}
            editArrivalRepairs={this.editArrivalRepairs}
            editAsphaltDimensions={this.editAsphaltDimensions}
            editResponsibleParty={this.editResponsibleParty}
            editPedDistance={this.editPedDistance}
            editMeasuredDepth={this.editMeasuredDepth}
            editDamageSurfaceType={this.editDamageSurfaceType}
            editAsphaltRemoved={this.editAsphaltRemoved}
            editCenterMember={this.editCenterMember}
            editCrossBore={this.editCrossBore}
            editJointTrench={this.editJointTrench}
            editLineMaterial={this.editLineMaterial}
            editLineSize={this.editLineSize}
            editFacilityType={this.editFacilityType}
            editEventType={this.editEventType}
            editOperation={this.editOperation}
            editNearMiss={this.editNearMiss}
            editRow={this.editRow}
            editStreetAddress={this.editStreetAddress}
            editCity={this.editCity}
            editStateAddress={this.editStateAddress}
            editing={this.state.editing}  
            incident={this.state.investigation} 
            setPictureCount={this.setPictureCount} />}{" "}
          {this.state.loadingHistory && 
          <div className="row">
            <div className="spinnerDivUserPage">
              <div className="text-center authSpinnerDiv" style={{padding:10}}>
                {this.state.historyStatusText != "" ? <div className="authSpinnerDivMessage">
                  {this.state.historyStatusText}
                </div> : ''}
                <div className="barLoaderPortal">
                  <BarLoader sizeUnit={"px"} size={150} color={"#095399"}
                    loading={this.state.historyStatusText == "" ? false : this.state.historyStatusText == "No Data Returned" || this.state.historyStatusText == "There was an error retrieving history. Try again." ? false : true}/>
                </div>
              </div>
            </div>
          </div>}
          {this.state.showHistoryList && 
            <div className="row">
              {!this.state.investigationHistory || !this.state.investigationHistory.rows || this.state.investigationHistory.rows.length == 0 ? <div className="spinnerDivUserPage">
                <div className ="text-center authSpinnerDiv" style={{padding:10}}>
                  <div className="authSpinnerDivMessage">
                    {this.state.historyStatusText ? this.state.historyStatusText : "No History Returned"}
                  </div>
                </div>
              </div> 
              : 
              <div className="searchResultsColumn" style={{marginLeft:10, marginRight:10}}>
                <MDBDataTable id="history-datatable" searching={false}   className="mdbDataTabel" btn Button striped bordered hover data={this.state.investigationHistory} paging={false}/>
              </div>}  
            </div>}
          {this.state.updateStatusText != "" &&
          <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={6000} onClose={this.handleClose} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
            {this.state.updateStatusText}
            </Alert>
          </Snackbar>}
        </div>
        :<div className = "container-fluid">
          <div className ="text-center detailsErrorMessage">Something went wrong loading the data. Go back to Reporting page and try again.</div></div>}
      </div>
    );
  }
}

InvestigationPage.propTypes = {
  location: PropTypes.object,
  forceRefresh: PropTypes.func,
  submitToDirt : PropTypes.func,
  closeEntity : PropTypes.func
};
export default InvestigationPage;