/* eslint react/prop-types: 0 */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from "prop-types";

const BarGraphBox = (props) => {
  const [chart, setChart] = React.useState(null);
  const chartCallback = chart => {
    setChart(chart);
  }
  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  return(
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark"}}
      highcharts={Highcharts}
      options={
        {
          title : {
            text: props.graphTitle || ""
          },
          xAxis: {
            categories: props.categories ? props.categories : props.data ? props.data.map(item => item[0]) : [], // array of x value labels
            crosshair: true
          },
          plotOptions: {
            series: {
              cursor: "pointer",
              point: {
                events: {
                  click: function(event) {
                    props.filterCallback({ ModifiedByDisplayName: event.point.category }, `Showing ${event.point.category}'s Surveys`);
                  }
                }
              }
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: props.yAxisLabel || "Surveys"
            }
          },
          colors: ["#16558F", "#2D7952", "#DE8C26"],
          chart: {
            type: 'column',
            backgroundColor: "#E9E9EF",
          },        
          series : props.multipleSeries ? props.data :[
            {
              name: props.tooltipUnit || "Survey Count",
              data: props.data || []
            }
          ],
          credits : {
            enabled: false
          },
          legend : {
            enabled: false
          }
      } }
      allowChartUpdate
      callback={chartCallback}
      constructorType="chart"
    />
  );
}

BarGraphBox.propTypes ={
  graphTitle : PropTypes.string,
  categories : PropTypes.array,
  data : PropTypes.array,
  yAxisLabel :   PropTypes.string,
  multipleSeries : PropTypes.bool,
  tooltipUnit : PropTypes.string
}

export default BarGraphBox;