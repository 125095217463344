import React from 'react';
import PropTypes from 'prop-types'
import { MDBDataTable } from 'mdbreact';

const tableColumns = [
  // hidden fields for sort
  {
    field: "LicenseStringValue",
    label: "Licenses",
    attributes: { id: "licenseStringSortToggle" }
  },
  {
    field: "DisabledValue",
    label: "Disabled2",
    attributes: { id: "disabledSortToggle" }
  },
  {
    field: "Button",
    label: "",
    sort: "disabled",
  },
  {
    field: "DisplayNameProper",
    label: "NAME"
  },
  {
    field: "EmailAddress",
    label: "EMAIL"
  },
  {
    field: "Role",
    label: "ROLE"
  },
  {
    field: "LicenseString",
    label: "LICENSES",
    attributes: { id: "licenseStringToggle" },
    sort: "disabled"
  },
  {
    field: "Disabled",
    label: "STATUS",
    attributes: { id: "disabledToggle" },
    sort: "disabled"
  },
]

const UsersTable = (props) => {

  React.useEffect(() => {
    let licenseToggle = document.querySelector("#licenseStringToggle");
    let disabledToggle = document.querySelector("#disabledToggle");
    let licenseSortField = document.querySelector("#licenseStringSortToggle");
    let disabledSortField = document.querySelector("#disabledSortToggle");
    let licenseClickListener = licenseToggle.addEventListener("click", () => { 
      console.log("toggling click license");
      licenseSortField.click(); 
    });
    let disabledClickListener = disabledToggle.addEventListener("click", () => {
      console.log("toggling click disabled")
      disabledSortField.click() 
    });
    return () => {
      document.removeEventListener("click", disabledClickListener);
      document.removeEventListener("click", licenseClickListener);
    }
  }, [])

  const handleSort = (filters) => {
    let licenseToggle = document.querySelector("#licenseStringToggle");
    let disabledToggle = document.querySelector("#disabledToggle");
    if(filters.column === "LicenseStringValue"){
      licenseToggle.className = `sorting_${filters.direction}`;
      disabledToggle.className = "sorting";
    }
    else if(filters.column === "DisabledValue"){
      disabledToggle.className = `sorting_${filters.direction}`;
      licenseToggle.className = "sorting";
    }else{
      licenseToggle.className = "sorting";
      disabledToggle.className = "sorting";
    }
  }

  return (
    <MDBDataTable
      id="usergroup-user-table" 
      className="w-100 mt-3"
      onSort={val => handleSort(val)}
      data={{columns: tableColumns, rows: props.data || []}}
      small responsiveMd searching btn striped 
      bordered hover sortable
      paging={true}
      pagesAmount={10}
      entries={100}
    />
  );
}

UsersTable.propTypes = {
  data : PropTypes.array
}

export default UsersTable;