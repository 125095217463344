/* eslint react/prop-types: 0 */
import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
//eslint-disable-next-line
import auth from "../common/Auth";
import PersonAddSharpIcon from '@material-ui/icons/PersonAddTwoTone';
import GetApp from "@material-ui/icons/GetApp";
import { BarLoader } from "react-spinners";

class UserSearchStrip extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      isSuperViewer : auth.isSuperViewer()
    }
  }

  render(){
    return <div className = "p-0 pageBar pageBarSub" >
      <div className="searchInputDiv" style={{display:"flex", alignItems:"center"}}>
        <input id = "search" type="text" placeholder="Search"
          value = {this.props.searchCriteria} 
          onChange = {(e) => {this.props.changeSearchCriteria(e.target.value)}} 
          onKeyPress = {(e) => { if(e.key == 'Enter'){ this.props.search(); }}}
          className = "form-control searchInput"
        />
        <Button  onClick = {this.props.search} className ="buttonAction buttonSearch ">
          <SearchSharpIcon className="buttonIcon light large" /> 
        </Button>
      </div>
      <div className = "userSearchDivButtons">
        {!this.state.isSuperViewer && <Button onClick = {this.props.addUser} as={Link} to="/usergroup/users/edit" className ="buttonAction btn btn-primary btn btn-primary">
          <PersonAddSharpIcon className="buttonIcon" />
          <label style = {{margin: 0, cursor: "pointer"}} className = "buttonText withIcon small">NEW USER</label>
        </Button>}
        {this.props.hasSiteSafeLicense &&
          <Button onClick = {this.props.downloadQRCodes} className="buttonAction btn btn-primary dehighlighted btn btn-primary" style={{marginLeft:6}}>
            <GetApp className="buttonIcon light m-0" />
            <label style={{margin: 0 , cursor : 'pointer'}} className ="buttonText withIcon small">QR Codes</label>
          </Button>}
      </div>
      <div className="barLoaderPortalBar">
        <BarLoader sizeUnit={"px"} size={25} color={"#095399"} loading={this.props.loadingQRCodes}/>
      </div>
    </div>
  }
}

UserSearchStrip.propTypes = {
    search: PropTypes.func,
    searchCriteria : PropTypes.string,
    changeSearchCriteria : PropTypes.func,
    addUser : PropTypes.func
}

export default UserSearchStrip;