import React from "react";
import auth from './common/Auth';
import OrgUtility from '../Utility/Utility'

class UpdatesPage extends React.Component{
  constructor(props){
    super(props);
    this.state ={
      updateCollection : {UpdateEntriesNew:[], UpdateEntriesFixes:[], UpdateEntriesImprovements:[]}
    }

    this.fetchUpdates = this.fetchUpdates.bind(this);
  }

  componentDidMount(){
    // Fetch Updates
    this.fetchUpdates();
  }

  componentDidUpdate(){ 
    window.resize();
    new OrgUtility().updateOrgCSS(); // STYLE CSS by ORG
  }

  fetchUpdates(){
    if (localStorage.getItem("updateEntries") != null){
      this.setState({updateCollection: JSON.parse(localStorage.getItem("updateEntries")), latestAppVersion: localStorage.getItem("latestAppVersion")});
    }
    localStorage.setItem("updateCheckCode", localStorage.getItem("updateVersion"));
  }

  render(){
    return(
      <div className="col-10 d-flex justify-content-center p-0 m-0" style={{ backgroundColor : "#E9E9EF"}}>
        <div id="supportDetailContentPanel" style={{width : '70%', padding: '20px'}}>
          <div className="detailContentPanel">
            <div className="labelHeaderBar labelHeaderBarTight" style={{padding:10}}>NEW RELEASE - UPDATES</div>
            <div className="updateAppVersion"> Latest App Version: {this.state.latestAppVersion} </div>
            <div className="updateArea"> NEW FEATURES!
            {this.state.updateCollection.UpdateEntriesNew.map((update, index) => ( 
              <div style={{marginBottom: 0}} className="row" key={index}>
                <div className="updateDiv">
                  <ul className="updateUL">
                    <li>
                      <div className="updateRow">
                        <div className="updateCat">{update.Category}</div><div className="updateFeat">{update.Feature}</div><div className="updateDeat">{update.Details}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
            </div>
            <div className="updateArea"> FIXES
              {this.state.updateCollection.UpdateEntriesFixes.map((update, index) => ( 
                <div style={{marginBottom: 0}} className="row" key={index}>
                  <div className="updateDiv">
                    <ul className="updateUL">
                      <li>
                        <div className="updateRow">
                          <div className="updateCat">{update.Category}</div><div className="updateFeat">{update.Feature}</div><div className="updateDeat">{update.Details}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
            <div className="updateArea"> IMPROVEMENTS
              {this.state.updateCollection.UpdateEntriesImprovements.map((update, index) => ( 
                <div style={{marginBottom: 0}} className="row" key={index}>
                  <div className="updateDiv">
                    <ul className="updateUL">
                      <li>
                        <div className="updateRow">
                          <div className="updateCat">{update.Category}</div><div className="updateFeat">{update.Feature}</div><div className="updateDeat">{update.Details}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UpdatesPage;