import React from 'react';
import PropTypes from 'prop-types';
import { BarLoader } from 'react-spinners';
import ImageGallery from 'react-image-gallery'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Button } from 'react-bootstrap';

class VideoPanel extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    return(
      <div>
        <div className="labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">Videos ({this.props.videoCount})</label></div>
          <div className="detailContentPanel" style={{textAlign : "center"}}>
          <div className="video-galleryDeleteButtonDiv">
              {!this.props.hideDelete && this.props.videos.length > 0 && 
                <Button className="buttonAction buttonAccount" onClick={() => {this.props.removeVideo()}}>
                  <HighlightOffIcon className="buttonIcon"/>
                  <label style={{ margin: 0, cursor: 'pointer'}} className="buttonText">Delete</label>
                </Button>
                }
            </div>
            {this.props.loadingVideos ? <div className="barLoaderPortal"><BarLoader sizeUnit={"px"} size={200} color={"#095399"} loading={this.props.loadingVideos}/></div> : null } 
            {/* {this.props.videos.map((video,index) => (
            <div key={index}>
              <video style={{width : 350, height : 350}} controls src={video.uri} type="video/mp4">
                Your browser does not support html video.
              </video>
            </div>))} */}
            {this.props.videos.length > 0 &&
            <div style={{alignContent : "center"}}>
              {this.props.videos.length > 1 && <button type="button" style={{position : 'relative', bottom : 50}} className="image-gallery-left-nav" aria-label="Previous Slide" onClick={() =>{this.props.setVideoIndex(-1)}}></button>}
              <video style={{width : '33%'}} controls src={this.props.videos[this.props.videoIndex] ? this.props.videos[this.props.videoIndex].uri : ''} type="video/mp4">
                Your browser does not support html video.
              </video>
              {this.props.videos.length > 1 && <button type="button" style={{position : 'relative', bottom : 50}} className="image-gallery-right-nav" aria-label="Next Slide" onClick={() =>{this.props.setVideoIndex(1)}}></button>}
            </div>}
            
            <div className="row carouselRow">
              <div className="videoGallery">
                <ImageGallery
                  items={this.props.thumbnails}
                  disableThumbnailScroll={true}
                  width={400}
                  onThumbnailClick={(event,index) => {this.props.clickThumbNail(index)}}
                  ref={i => this._imageGallery = i}
                />
              </div>
            </div>
          </div>
      </div>
    )
  }
}

VideoPanel.propTypes = {
  videoCount : PropTypes.number,
  loadingVideos : PropTypes.bool,
  videos : PropTypes.array,
  thumbnails : PropTypes.array,
  setVideoIndex : PropTypes.func,
  videoIndex : PropTypes.number,
  clickThumbNail : PropTypes.func,
  removeVideo : PropTypes.func,
  hideDelete : PropTypes.bool
}

export default VideoPanel;