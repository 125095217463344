import { get, set } from 'idb-keyval';

class Auth {
   constructor() {
    let hasToken = localStorage.getItem("access_token") != null && localStorage.getItem("access_token") != '' && localStorage.getItem("access_token" != undefined);
    let expires = new Date(localStorage.getItem("expires"));
    if (hasToken && expires < new Date(Date.now())) { this.logout(); window.location.href = "/login" }
    this.authenticated = this.token();
  }

  login(authResponse) {
    this.authenticated = true;
    if(authResponse.userId != localStorage.getItem("userId")){
      this.clearCache();
    }
    localStorage.setItem("access_token", authResponse.access_token);
    localStorage.setItem("userId", authResponse.userId);
    localStorage.setItem("userName", authResponse.userName);
    localStorage.setItem("roles", authResponse.roles);
    localStorage.setItem("licenses", authResponse.licenses);
    localStorage.setItem("licensed", authResponse.licensed);
    localStorage.setItem("subscription", authResponse.subscription);
    localStorage.setItem("companyId", authResponse.companyId);
    localStorage.setItem("expires", authResponse[".expires"]);
    localStorage.setItem("actualCompanyId", authResponse.companyId);
    localStorage.setItem("secureCode", authResponse.companySecureCode);

    if(typeof authResponse.userAccessCompanyIds != 'undefined'){ localStorage.setItem("userAccessCompanyIds", authResponse.userAccessCompanyIds); }
    if(authResponse.roles.split(',').includes("0")){ this.getCompanyList(authResponse.access_token, authResponse.userId); }
    localStorage.setItem("elmCompany", authResponse.elmCompany);

    console.log('serverHost', authResponse.serverHost);
  }

  getOptions(accessToken, companyId, userId){
    let body = {
      UserId : userId,
      CompanyId : companyId
    }

    fetch(process.env.API_URL + "/api/fieldOptions", {
      method : "POST",
      headers : {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + accessToken
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let response = JSON.parse(responseJson);
          if(response.ResponseType == 0){
            console.log(response);
          }
        });
      }
    })
  }

  logout() {

    let { del } = require('idb-keyval');
    
    // CLEAR AUTH
    this.authenticated = false;
    localStorage.removeItem("access_token");
  }

  clearCache(){
    console.log("clearing cache");
    set('resultData','{}');
    set('resultSet','{}');
    set('resultSet_Survey','{}');
    set('resultSet_LeakSurvey','{}');
    localStorage.removeItem("access_token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("roles");
    localStorage.removeItem("companyId");
    localStorage.removeItem("expires");
    localStorage.removeItem("licenses");
    localStorage.removeItem("licensed");
    localStorage.removeItem("subscription");
    localStorage.removeItem("acualCompanyId");
    localStorage.removeItem("companyList");
    localStorage.removeItem("elmCompany");
    localStorage.removeItem("userAccessCompanyIds");
    

    // REMOVED -- CLEAR CACHED DATA
    //del("resultSet");
    //del("resultSet_LeakSurvey");
    
    localStorage.removeItem("secureCode");
    sessionStorage.removeItem("leakSurveyMapZoom");
    sessionStorage.removeItem("leakSurveyMapExtent");
    sessionStorage.removeItem("searchStripFilterUserValue");
    sessionStorage.removeItem("searchStripFilterStatusValue");

    sessionStorage.removeItem("AssignmentAreasLayerVisibility");
    sessionStorage.removeItem("GasLinesLayerVisibility");
    sessionStorage.removeItem("SurveyPointsLayerVisibility");
    sessionStorage.removeItem("SurveyLinesLayerVisibility");
    sessionStorage.removeItem("LeakSurveyLayerVisibility");
    sessionStorage.removeItem("InaccessibleSurveyLayerVisibility");
    sessionStorage.removeItem("AOCSurveyLayerVisibility");

    localStorage.removeItem("searchStripSearchTerm");
    localStorage.removeItem("searchStripFromDateString");
    localStorage.removeItem("searchStripToDateString");

    localStorage.removeItem("dashboard-investigation");
    localStorage.removeItem("dashboard-leaksurvey-auditcollection");
    localStorage.removeItem("dashboard-leaksurvey-collection");
    localStorage.removeItem("dashboard-leaksurvey-daterange");
    localStorage.removeItem("dashboard-leaksurvey-pointcollection");
    localStorage.removeItem("dashboard-leaksurvey-userStatsCollection");
    localStorage.removeItem("dashboard-sitesafe");
    localStorage.removeItem("surveyStateList");
    localStorage.removeItem("investigationOptions");
    localStorage.removeItem("leakSurveyOptions");
    localStorage.removeItem("dashboard-leaksurvey-filter");
  }

  getUserAccessCompanyList(token, refresh){

    fetch(process.env.API_URL + "/api/account/userAccessCompanies",{
      method: "GET",
      headers:{
        "Access-Control-Allow-Origin" : "*" ,
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + token
      }
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          responseJson = JSON.parse(responseJson);
          let dupCompanies = [];
          for(let i = 0; i < responseJson.length; i++){
            //Make sure that companies with the same name are different. 
            if(this.checkCompanyNames(dupCompanies, responseJson[i].CompanyName)){
              responseJson[i].CompanyName += " ";
            }
            dupCompanies.push(responseJson[i]);
          }
          responseJson.sort((a,b) =>{
            if(a.CompanyName < b.CompanyName) { return -1; }
            if(a.CompanyName > b.CompanyName) { return 1; } 
            return 0;
          })
          localStorage.setItem("companyList",JSON.stringify(responseJson));
          if(refresh){
            location.reload();
          }
        });
      }
    })
    .catch(error =>{
      console.log(error);
    });
  }

  getCompanyList(token, userId,refresh){
    let body = {
      UserId: userId
    }
    fetch(process.env.API_URL + "/api/account/companies",{
      method: "POST",
      headers:{
        "Access-Control-Allow-Origin" : "*" ,
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + token
      },
      body: JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          responseJson = JSON.parse(responseJson);
          let dupCompanies = [];
          for(let i = 0; i < responseJson.length; i++){
            //Make sure that companies with the same name are different. 
            if(this.checkCompanyNames(dupCompanies, responseJson[i].CompanyName)){
              responseJson[i].CompanyName += " ";
            }
            dupCompanies.push(responseJson[i]);
          }
          responseJson.sort((a,b) =>{
            if(a.CompanyName < b.CompanyName) { return -1; }
            if(a.CompanyName > b.CompanyName) { return 1; } 
            return 0;
          })
          localStorage.setItem("companyList",JSON.stringify(responseJson));
          if(refresh){
            location.reload();
          }
        });
      }
    })
    .catch(error =>{
      console.log(error);
    });
  }

  checkCompanyNames(arr, name){
    return arr.some(function(e){
      return e.CompanyName == name;
    });
  }

  isAuthenticated() {
    return this.authenticated;
  }

  token() {
    let hasToken = localStorage.getItem("access_token") != null && localStorage.getItem("access_token") != '' && localStorage.getItem("access_token") != undefined;
    let expires = new Date(localStorage.getItem("expires"));
    if(hasToken && expires < new Date(Date.now())){
      this.logout();
      window.location.href = "/login";
      return;
    }
    return localStorage.getItem("access_token");
  }
  employeeId() {
    return localStorage.getItem("userId");
  }
  userId() {
    return localStorage.getItem("userId");
  }
  userName() {
    return localStorage.getItem("userName");
  }
  companyId() {
    return localStorage.getItem("companyId");
  }
  roles() {
    return localStorage.getItem("roles");
  }
  licenses() {
    return localStorage.getItem("licenses");
  }
  licensed() {
    return localStorage.getItem("licensed");
  }
  expires() {
    return localStorage.getItem("expires");
  }
  isElmCompany(){
    let val = localStorage.getItem("elmCompany");
    if(!val || val == ''){
      return false;
    }
    return val == 'True';
  }
  isAdmin() {
    let retBool = false;
    let _ADMIN_ROLEID = "1";
    if (localStorage.getItem("roles") != null) {
      let arr = localStorage.getItem("roles").split(",");
      arr.forEach(element => { if (element == _ADMIN_ROLEID) { retBool = true; } });
    }
    return retBool;
  }
  isSuperAdmin() {
    let retBool = false;
    let _SUPERADMIN_ROLEID = "0";
    if (localStorage.getItem("roles") != null) {
      let arr = localStorage.getItem("roles").split(",");
      arr.forEach(element => { if (element == _SUPERADMIN_ROLEID) { retBool = true; } });
    }
    return retBool;
  }
  isSuperViewer(){
    let isSuper = false;
    let _SuperViewerRoleId = 11;
    var roles = localStorage.getItem("roles");
    if(roles != null) { roles.split(',').forEach(element => { if (element == _SuperViewerRoleId) { isSuper = true;}});}
    return isSuper;
  }

  isViewer(){
    let isViewer = false;
    let viewerRoleId = 9; 
    var roles = localStorage.getItem("roles");
    if(roles != null) { roles.split(',').forEach(element => { if (element == viewerRoleId) { isViewer = true;}});}
    return isViewer;
  }

  isManager(){
    let isManager = false;
    let managerRoleId = "5"; 
    var roles = localStorage.getItem("roles");
    if(roles != null) { roles.split(',').forEach(element => { if (element == managerRoleId) { isManager = true;}});}
    return isManager;
  }

  hasLicenseForPage(pageName){
    const licensesString = this.licenses();
    if(licensesString){
      const licenseArray = licensesString.split(',');
      if(pageName == 'map' && licenseArray.indexOf('gps') > -1)
        return true;
      if(pageName == 'leaksurvey' && licenseArray.indexOf('leak') > -1)
        return true;
      if(pageName == 'sitemap' && (licenseArray.indexOf('site') > -1 || licenseArray.indexOf('investigation') > -1 || licenseArray.indexOf('customforms') > -1))
        return true;
      return false;
    }else{
      return false;
    }

  }

  getSecureCode(){
    return localStorage.getItem("secureCode");
  }

}

export default new Auth(); // singleton pattern (per video)
