import auth from '../components/common/Auth'

const DirtUtility = {
  async uploadDirt(InvestigationId){
    let body = {
      InvestigationId,
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }
    let response = fetch(process.env.API_URL + "/api/investigation/submitToDirt",{
      method : "POST",
      headers : {
        "Accept" : "application/json",
        "Content-Type" :  "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .catch((error) =>{
      console.log(error);
    })
    return response;
  },
  async checkDirtScore(InvestigationId){
    let body ={
      InvestigationId,
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }
    let response = fetch(process.env.API_URL +"/api/investigation/getDirtScore",{
      method : "POST",
      headers : {
        "Accept" : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .catch((error) =>{
      console.log(error);
    });
    return response;
  }
}

export { DirtUtility }