/* eslint react/prop-types: 0 */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsDrilldown from 'highcharts/modules/drilldown';

HighchartsDrilldown(Highcharts);

const FacilityTypeSize = (props) => {
  const [chart, setChart] = React.useState(null);
  const [series, setSeries] = React.useState([]);
  const [drilldown, setDrilldown] = React.useState([]);

  const chartCallback = c => setChart(c);

  React.useEffect(() => {
    let finalSeries = [];
    let finalDrilldown = [];
    let lineSizeCount = {}; // For Drilldown data
    props.data.forEach((item) => {
      // Setting up Series Data FacilityType, LineSize
      let index = finalSeries.findIndex(type => type.name === item.FacilityType);
      if(item.FacilityType){ // && !finalSeries.includes(item.FacilityType)
        if(index !== -1) {
          let orig = finalSeries[index];
          finalSeries[index] = {name: orig.name, y: orig.y + 1, drilldown: orig.name }
        }else{
          finalSeries.push({ name: item.FacilityType, y: 1, drilldown: item.FacilityType });
        }
        // Setting up Drilldown data
        let ddIndex = finalDrilldown.findIndex(i => item.FacilityType === i.name);
        if(item.LineSize){
          if(lineSizeCount[item.FacilityType]){
            lineSizeCount[item.FacilityType][item.LineSize]
            ? lineSizeCount[item.FacilityType][item.LineSize] += 1 
            : lineSizeCount[item.FacilityType][item.LineSize] = 1
          }else{
            lineSizeCount[item.FacilityType] = {};
            lineSizeCount[item.FacilityType][item.LineSize] = 1;
          }
          let itms = Object.keys(lineSizeCount[item.FacilityType]).map(key => [key, lineSizeCount[item.FacilityType][key]])
          if(ddIndex !== -1){
            finalDrilldown[ddIndex] = {name: item.FacilityType, id: item.FacilityType, data: itms}
          }else{
            finalDrilldown.push({ name: item.FacilityType, id: item.FacilityType, data: itms });
          }
        }
      }      
    });
    // console.log("Final Drilldown : ", finalDrilldown);
    setDrilldown(finalDrilldown);
    setSeries(finalSeries);
    // console.log("Facility Type Size : ", finalSeries);
  }, [props.data])

  React.useEffect(() => {
    chart&&chart.reflow();
  }, [props.refreshKey])

  return (
    <HighchartsReact 
      containerProps={{ className: "w-100 h-100 border border-dark" }} 
      highcharts={Highcharts} 
      allowChartUpdate={true} 
      callback={chartCallback}
      constructorType="chart"
      options={{
        chart: { type: "pie", backgroundColor: "#E9E9EF" },
        title: { text: "Facility Type Size" },
        series: [
          {
            name: "Count",
            data: series.length > 0 ? series : [{name: "No Data", y: 1}]
          }
        ],
        accessibility: {
          announceNewData: {
              enabled: true
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y}'
            },
            cursor: "pointer",
            point: {
              events: {
                click: function(event){
                  event.preventDefault();
                  if(event.point.name && event.point.series.name && event.point.series.name !== "Count"){
                    props.filterCallback({ FacilityType: event.point.series.name, LineSize: event.point.name },
                    `Showing Investigations with Facility Type: ${event.point.series.name}, and Line Size: ${event.point.name}`);
                    chart&&chart.drillUp();
                  }
                },
              }
            }
          }
        },
        drilldown: {
          series: drilldown,
        },
        credits: { enabled: false },
        legend: { enabled: true }
      }}
    />
  );
}

export default FacilityTypeSize;