/* eslint react/prop-types: 0 */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const FacilityOperationType = (props) => {
  const [graphData, setGraphData] = React.useState([]); // {name: "test", data: [["Water", 50],["test", 20]] }
  const [chart, setChart] = React.useState(null);

  const chartCallback = c => setChart(c);

  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  React.useEffect(() => {
    if(props.data){
      let counts = {};
      props.data.forEach((item) => {
        if(item.FacilityOperationType) {
          counts[item.FacilityOperationType] ?
          counts[item.FacilityOperationType] += 1 :
          counts[item.FacilityOperationType] = 1;
        }
      });
      let final = [];
      Object.keys(counts).forEach(key => {
        final.push([key, counts[key]]);
      })
      // console.log("FacilityOperationType Final : ", final, {name: "test", data: final});
      setGraphData(final);
    }
  }, [props.data])

  return (
    graphData ?
    <HighchartsReact 
      containerProps={{ className: "w-100 h-100 border border-dark" }} 
      highcharts={Highcharts} 
      allowChartUpdate={true} 
      callback={chartCallback}
      constructorType="chart"
      options={{
        chart: { type: "pie", backgroundColor: "#E9E9EF" },
        title: { text: "Facility Operation Type" },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              distance: 10,
            },
            cursor: "pointer",
            point: {
              events: {
                click: function(event){
                  // console.log("Event : ", event);
                  props.filterCallback({ FacilityOperationType: event.point.name }, `Showing Investigations with Facility Operation Type: ${event.point.name}`);
                }
              }
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            size: '110%'
          }
        },
        series:[{
          name: "Facility Operation Type",
          type: "pie",
          innerSize: "50%",
          data: graphData.length > 0 ? graphData : [["No Data", 1]]
        }],
        credits: { enabled: false },
        legend: { enabled: true }
      }}
    />
    :<div></div>
  );
}

export default FacilityOperationType;