import React from 'react';
import PropTypes from 'prop-types';
import AddBoxSharpIcon from '@material-ui/icons/LibraryAddTwoTone';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import SaveSharpIcon from '@material-ui/icons/SaveTwoTone';
import { Button } from "react-bootstrap";
import { BarLoader } from 'react-spinners';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import LayersIcon from '@material-ui/icons/Layers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import auth from '../common/Auth';
import Functions from '../../Functions/Functions';

class TicketGroup extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      selectedGroupId : -1,
      filterValue : "",
      layerTicketGroups :[],
      selectedAreas : [],
      showWarning : false
    }
    this.renderTicketGroup = this.renderTicketGroup.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.selectAreas = this.selectAreas.bind(this);
    this.saveTicketLayerGroups = this.saveTicketLayerGroups.bind(this);
    this.layerIsInticketGroup = this.layerIsInticketGroup.bind(this);
  }

  handleClose(){
    this.setState({showWarning : false});
  }
  
  selectAreas(area){
    let selectedAreas = [];
    if(typeof(area) == "string"){
      if(area == "ADDALL"){
        let layersInChildGroup = this.state.layersInChildGroup;
        this.props.filteredAssignmentAreas.forEach(area =>{
          let childIndex = this.state.layersInChildGroup.findIndex(x => (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : (x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId) || 
          (x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId)));
          if(childIndex != -1){
            layersInChildGroup.splice(childIndex, 1);
          }
          selectedAreas.push(area);
        });
        this.setState({layersInChildGroup});
      }
      else if(area == "REMOVEALL"){
        selectedAreas = this.state.selectedAreas;
        this.props.filteredAssignmentAreas.forEach(area =>{
          let index = selectedAreas.findIndex(x => (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : (x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId) || 
          (x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId)));
          if(index != -1){
            selectedAreas.splice(index,1);
          }
        });
      }
    }
    else{
      selectedAreas = this.state.selectedAreas;
      if(selectedAreas.find(x => (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : (x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId) || 
      (x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId)))){
        //Need to make it look like you are assigning are to group if they already are but are also in child group. 
        let childIndex = this.state.layersInChildGroup.findIndex(x => (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : (x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId) || 
        (x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId)));
        if(childIndex != -1){
          let layersInChildGroup = this.state.layersInChildGroup;
          layersInChildGroup.splice(childIndex, 1);
          this.setState({layersInChildGroup});
          return;
        }
        let index = selectedAreas.indexOf(x => (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : (x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId) || 
        (x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId)));
        if(index == -1){
          selectedAreas.forEach(selectedArea =>{
            index++;
            if((area.AssignmentAreaId ? selectedArea.AssignmentAreaId == area.AssignmentAreaId : (selectedArea.CompanyArcGISLayerId == area.CompanyArcGISLayerId && selectedArea.FeatureId == area.FeatureId) || 
            (selectedArea.CompanyArcGisLayerId == area.CompanyArcGISLayerId && selectedArea.FeatureId == area.FeatureId))){
              selectedAreas.splice(index,1);
            }
          });
        }
        else{
          selectedAreas.splice(index,1);
        }
      }else{
        selectedAreas.push(area);
      }
    }
    this.setState({selectedAreas});
  }

  getAllAncestors(ticketGroup){
    let idList = [];
    idList.push(ticketGroup.TicketGroupId);
    if(ticketGroup.ParentId){
      let parent = this.props.groups.find(x => x.TicketGroupId === ticketGroup.ParentId);
      idList = idList.concat(this.getAllAncestors(parent));
    }
    return idList;
  }

  getAllOffspring(ticketGroup){
    let idList = [];
    idList.push(ticketGroup.TicketGroupId);
    if(ticketGroup.children){
      ticketGroup.children.forEach(child =>{
        idList = idList.concat(this.getAllOffspring(child));
      });
    }
    return idList;
  }

  saveTicketLayerGroups(savedFrom){
    if(auth.isSuperViewer()){
      return;
    }

    let newAssignmentAreaGroups = [];
    let selectedAreas = this.state.selectedAreas;
    let ticketGroupsForArea = this.state.ticketGroupsForArea;
    let ticketAssignmentAreaGroups = this.props.ticketAssignmentAreaGroups;
    let currentGroup = typeof(this.state.currentGroup) == 'string' ? JSON.parse(this.state.currentGroup) : this.state.currentGroup;
    if(savedFrom != 'areaAssignment' && currentGroup.TicketGroupId == -1){
      this.props.updateStatusText("Select a group before saving.","warning");
      return;
    }
    if(savedFrom == "areaAssignment"){
      console.log("Implement");
      //TODO: Update to work with ticket groups if need to add assigning groups to layers like how you can with usersgroups.
      // let userId = this.state.userSelected.UserId;
      // dataGroupsForUser.forEach(groupId =>{
      //   let group = this.props.groups.find(x => x.DataGroupId == groupId.DataGroupId);
      //   //Need to add groups that were added and assign all ancestors of that group as well.
      //   let ancestors = this.getAllAncestors(group);
      //   ancestors.forEach(ancestor =>{
      //     if(!newUserGroups.find(x => x.UserId == userId && x.DataGroupId == ancestor) && !(userGroups.find(x => x.UserId == userId && x.DataGroupId == ancestor))){
      //       newUserGroups.push({UserId : userId, DataGroupId : ancestor, Add : true});
      //     }
      //   });
      // });
      // //Need to look through current user groups to determine what got unassigned and then also unassign all offspring\
      // //And all ancestors where they are not assigned to another one of the ancestors offspring. 
      // let shouldntUnassignList = [];
      // let oldDataGroupsForUser = userGroups.filter(x => x.UserId == userId);
      // oldDataGroupsForUser.forEach(group =>{
        
      //   if(!dataGroupsForUser.find(x => x.DataGroupId == group.DataGroupId)){
      //     let ancestors = this.getAllAncestors(group);
      //     let offspring = this.getAllOffspring(group);
      //     offspring.forEach(groupId =>{
      //       if(!newUserGroups.find(x => x.Add == false && x.UserId == userId && x.DataGroupId == groupId)){
      //         newUserGroups.push({UserId : userId, DataGroupId : groupId , Add : false});
      //       }
      //     });
      //     ancestors.forEach(ancestorId =>{
      //       let ancestor = this.props.groups.find(x => x.DataGroupId == ancestorId);
      //       let shouldUnassign = true;
      //       let children = this.getAllOffspring(ancestor);
      //       children.forEach( child =>{
      //         if(child != ancestorId && child != group.DataGroupId  && userGroups.find(x => x.UserId == userId && x.DataGroupId == child)){
      //           shouldUnassign = false;
      //           shouldntUnassignList.push(child);
      //         }
      //       });
      //       if(shouldUnassign && !newUserGroups.find(x => x.UserId == userId && x.DataGroupId == ancestorId && x.Add == false)){
      //         newUserGroups.push({UserId : userId, DataGroupId : ancestorId, Add : false});
      //       }
      //     });
      //   }
      // });
      // shouldntUnassignList.forEach(id =>{
      //   let groupIndex = newUserGroups.findIndex(x => x.UserId == userId && x.DataGroupId == id && x.Add == false);
      //   if(groupIndex != -1){
      //     newUserGroups.splice(groupIndex, 1);
      //   }
      // })
    }
    else{
      selectedAreas.forEach(area =>{
        //Add new areas to group but check to make sure they aren't already in the group.
        //Also need to add areas to all ancestors of the group that is selected. 
        let ancestors = this.getAllAncestors(currentGroup);
        //Need to remove all areas from offspring if it was assigned since now we are hiding everything but lowest level assignment. 
        let offspringList = this.getAllOffspring(currentGroup);
        offspringList.forEach(groupId =>{
          let offspringGroupList = ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == groupId);
          offspringGroupList.forEach(areaGroup =>{
            if(currentGroup.TicketGroupId != groupId && !this.state.layersInChildGroup.find(x => (areaGroup.AssignmentAreaId ? x.AssignmentAreaId == areaGroup.AssignmentAreaId : (x.CompanyArcGISLayerId == areaGroup.CompanyArcGISLayerId && x.FeatureId == areaGroup.FeatureId) || 
            (x.CompanyArcGisLayerId == areaGroup.CompanyArcGISLayerId && x.FeatureId == areaGroup.FeatureId))) && !newAssignmentAreaGroups.find(x => (areaGroup.AssignmentAreaId ? x.AssignmentAreaId == areaGroup.AssignmentAreaId : (x.CompanyArcGISLayerId == areaGroup.CompanyArcGISLayerId && x.FeatureId == areaGroup.FeatureId) || 
            (x.CompanyArcGisLayerId == areaGroup.CompanyArcGISLayerId && x.FeatureId == areaGroup.FeatureId)) && x.TicketGroupId == groupId && x.Add == false)){
              newAssignmentAreaGroups.push({AssignmentAreaId  : areaGroup.AssignmentAreaId ? areaGroup.AssignmentAreaId : null, CompanyArcGISLayerId : areaGroup.CompanyArcGISLayerId ? areaGroup.CompanyArcGISLayerId : null,
                FeatureId : areaGroup.FeatureId ? areaGroup.FeatureId : null, TicketGroupId : groupId, Add : false});
            }
          });
        })
        ancestors.forEach(groupId =>{
          if(!ticketAssignmentAreaGroups.find(x => (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : (x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId) || 
          (x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId)) && x.TicketGroupId == groupId) && !newAssignmentAreaGroups.find(x => (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : (x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId) || 
          (x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId)) && x.TicketGroupId == groupId && x.Add == true)){
            newAssignmentAreaGroups.push({AssignmentAreaId  : area.AssignmentAreaId ? area.AssignmentAreaId : null, CompanyArcGISLayerId : area.CompanyArcGISLayerId ? area.CompanyArcGISLayerId : null,
              FeatureId : area.FeatureId ? area.FeatureId : null, TicketGroupId : groupId, Add : true});
          }
        });
      });
      ticketAssignmentAreaGroups.forEach(areaGroup =>{
        //Add removed areas so they will be deleted.
        if(areaGroup.TicketGroupId == currentGroup.TicketGroupId && !selectedAreas.findIndex(x => (areaGroup.AssignmentAreaId ? x.AssignmentAreaId == areaGroup.AssignmentAreaId : (x.CompanyArcGISLayerId == areaGroup.CompanyArcGISLayerId && x.FeatureId == areaGroup.FeatureId) || 
        (x.CompanyArcGisLayerId == areaGroup.CompanyArcGISLayerId && x.FeatureId == areaGroup.FeatureId))) == -1){
          //Need to Delete the entry for all the children if area is unassigned from parent. 
          let offspringList = this.getAllOffspring(currentGroup);
          offspringList.forEach(groupId =>{
            if(!newAssignmentAreaGroups.find(x => (areaGroup.AssignmentAreaId ? x.AssignmentAreaId == areaGroup.AssignmentAreaId : (x.CompanyArcGISLayerId == areaGroup.CompanyArcGISLayerId && x.FeatureId == areaGroup.FeatureId) || 
            (x.CompanyArcGisLayerId == areaGroup.CompanyArcGISLayerId && x.FeatureId == areaGroup.FeatureId)) && x.TicketGroupId == groupId && x.Add == false)){
              newAssignmentAreaGroups.push({AssignmentAreaId  : areaGroup.AssignmentAreaId ? areaGroup.AssignmentAreaId : null, CompanyArcGISLayerId : areaGroup.CompanyArcGISLayerId ? areaGroup.CompanyArcGISLayerId : null,
                FeatureId : areaGroup.FeatureId ? areaGroup.FeatureId : null, TicketGroupId : groupId, Add : false});
            }
          });
        }
      });
      //Need to also unassign all areas from ancestors if they are not in one of the areas offspring
      let ticketAssignmentAreaGroupsInTicketGroup = ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == currentGroup.TicketGroupId);
      let ancestors = this.getAllAncestors(currentGroup);
      ticketAssignmentAreaGroupsInTicketGroup.forEach(areaGroup =>{
        if(!selectedAreas.find(x => areaGroup.AssignmentAreaId ? x.AssignmentAreaId == areaGroup.AssignmentAreaId : (x.CompanyArcGISLayerId == areaGroup.CompanyArcGisLayerId && x.FeatureId == areaGroup.FeatureId) || 
        (x.CompanyArcGisLayerId == areaGroup.CompanyArcGisLayerId && x.FeatureId == areaGroup.FeatureId))){
          ancestors.forEach(ancestorId =>{
            let ancestor = this.props.groups.find(x => x.TicketGroupId == ancestorId);
            let shouldUnassign = true;
            let children = this.getAllOffspring(ancestor);
            children.forEach( child =>{
              //If child is not itself and not the group being edited and it found a area group with that area and child id then 
              //Thant means that the area was also assigned to a group that desended from this ancestorId so we should not unassign them
              //Other wise we should unassign from all ancestors. 
              if(child != ancestorId && child != currentGroup.TicketGroupId && ticketAssignmentAreaGroups.find(x => (areaGroup.AssignmentAreaId ? x.AssignmentAreaId == areaGroup.AssignmentAreaId : (x.CompanyArcGISLayerId == areaGroup.CompanyArcGisLayerId && x.FeatureId == areaGroup.FeatureId) || 
              (x.CompanyArcGisLayerId == areaGroup.CompanyArcGisLayerId && x.FeatureId == areaGroup.FeatureId)) && x.TicketGroupId == child)){
                shouldUnassign = false;
              }
            });
            if(shouldUnassign && !newAssignmentAreaGroups.find(x => (areaGroup.AssignmentAreaId ? x.AssignmentAreaId == areaGroup.AssignmentAreaId : (x.CompanyArcGISLayerId == areaGroup.CompanyArcGisLayerId && x.FeatureId == areaGroup.FeatureId) || 
            (x.CompanyArcGisLayerId == areaGroup.CompanyArcGisLayerId && x.FeatureId == areaGroup.FeatureId)) && x.TicketGroupId == ancestorId && x.Add == false)){
              newAssignmentAreaGroups.push({AssignmentAreaId  : areaGroup.AssignmentAreaId ? areaGroup.AssignmentAreaId : null, CompanyArcGISLayerId : areaGroup.CompanyArcGisLayerId ? areaGroup.CompanyArcGisLayerId : null,
                FeatureId : areaGroup.FeatureId ? areaGroup.FeatureId : null, TicketGroupId : ancestorId, Add : false});
            }
          });
        }
      });
    }

    this.setState({statusText : "SAVING GROUPS..."});
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      TicketAssignmentAreaGroups : newAssignmentAreaGroups
    }
    fetch(process.env.API_URL + "/api/account/updateTicketAssignmentAreaGroups",{
      method : "POST",
      headers : {
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            this.setState({statusText : "", selectedAreas : [], groupSelected : false});
            this.props.updateStatusText("Ticket Assignment Area Group saved.", "success");
            this.props.updateTicketAssignmentAreaGroups(json.TicketAssignmentAreaGroups);
          }
          else{
            this.setState({statusText : ""});
            this.props.updateStatusText("Something went wrong. Try again.", "error");
          }
        });
      }
      else{
        this.setState({statusText : ""});
        this.props.updateStatusText("Something went wrong. Try again.", "error");
      }
    })
    .catch(error =>{
      console.log(error.message);
      this.setState({statusText : ""});
      this.props.updateStatusText("Something went wrong. Try again.", "error");
    })
  }

  selectGroup(id){
    let selectedAreas = [];
    let group = this.props.groups.find(x => x.TicketGroupId == id);
    let layersInChildGroup = [];
    this.props.ticketAssignmentAreaGroups.forEach(layerGroup =>{
      if(layerGroup.TicketGroupId == group.TicketGroupId){
        //Need to see if layer is in a child group and not display if that is the case. 
        let area = this.props.assignmentAreas.find((x => 
          (layerGroup.AssignmentAreaId ? x.AssignmentAreaId == layerGroup.AssignmentAreaId : (x.CompanyArcGISLayerId == layerGroup.CompanyArcGisLayerId && x.FeatureId == layerGroup.FeatureId) || 
          (x.CompanyArcGisLayerId == layerGroup.CompanyArcGisLayerId && x.FeatureId == layerGroup.FeatureId))));
        if(group.children){
          group.children.forEach(child =>{
            if(area && this.props.ticketAssignmentAreaGroups.findIndex(x => (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : (x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId) || 
            (x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId)) && x.TicketGroupId == child.TicketGroupId) != -1){
              if(layersInChildGroup.findIndex(x => area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : (x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId) || 
              (x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId)) == -1){
                layersInChildGroup.push(area);
              }
            }
          });
        }
        if(area){
          selectedAreas.push(area);
        }
      }
    })
    this.setState({selectedGroupId : id, currentGroup : group, selectedAreas, groupSelected : true,layersInChildGroup});
  }

  renderTicketGroup(children, isChild){
    return(
      <ul className={isChild ? "dataGroupUL childDataGroup" : "dataGroupUL parentDataGroup"}>
        {children.map((group, index) =>
        !group.DeletedDate && (group.isRoot || isChild) &&
        <li key={index} style={{marginTop : 15}}>
          <div className={ group.children ? "dataGroupDiv" : group.isRoot ? "row dataGroupDiv" : "dataGroupDiv"} style={{display : "flex", flexDirection : 'row'}}>
            {group.children ? (group.expanded ? <KeyboardArrowDownIcon className="arrowIcon  dark caret" onClick={()=>{this.props.expandGroup(group)}}/> : <KeyboardArrowRightIcon className="arrowIcon dark caret" onClick={()=>{this.props.expandGroup(group)}}/>) : <div style={{minWidth : 25}}/>}
            <label htmlFor={"groupName"+ group.TicketGroupId} className="accountHeader dataGroupLabel notificationRowControl">Group Name:</label>
              <input id={"groupName"+ group.TicketGroupId} value={group.GroupLabel} style={{display : 'inline'}} className="form-control notificationRowControl large"
                onChange={e => {this.props.editGroup(e.target.value, group.GroupLabel, group.TicketGroupId)}}/>
            <Button onClick={() =>{
              if(group.TicketGroupId > 0){
                this.setState({selectedGroup : group, showWarning : true});
              }
              else{
                this.props.deleteGroup(group.GroupLabel, group.TicketGroupId);
              }
              }} className="buttonAction buttonAccount buttonFaded notificationRowControl notificationRowButton">
              <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
            </Button>
            <Button onClick={() =>{this.props.addTicketGroup(group.TicketGroupId)}} className="buttonAction buttonAccount notificationRowControl notificationRowButton" >
              <AddBoxSharpIcon  className="buttonIcon"/>
            </Button>
            {group.TicketGroupId > 0 && <Button style={{height : 35}} className="buttonAction button Account notificationRowControl notificaitonRowButton" onClick={()=>{this.selectGroup(group.TicketGroupId);document.getElementById("layersListDiv").style.display = 'block';}}>
              <LayersIcon className="buttonIcon"/>
              <label style={{margin : 0, cursor:  'pointer'}} className="buttonText">View Layers</label>
            </Button>}
          </div>
          {group.children && group.expanded && this.renderTicketGroup(group.children, true)}
        </li>)}
      </ul>
    )
  }

  layerIsInticketGroup(area){
    var index = this.state.selectedAreas.findIndex(x => (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : 
      (x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId)));
    var childIndex = -1;
    if(this.state.layersInChildGroup) childIndex =  this.state.layersInChildGroup.findIndex(x => (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : (x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId) || 
      (x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId)));
    return (index != -1) && (childIndex == -1);
  }

  render(){
    return(
      <div>
        <div className="labelHeaderBar labelHeaderBarTight" style={{padding : 10, marginTop : 10}}>
          Ticket Groups
          <Button onClick={()=>{this.props.updateGroups();}} className="buttonAction dataGroupsSaveButton">
              <SaveSharpIcon className="buttonIcon"/>
              <label style={{ margin: 0, cursor : 'pointer'}} className="buttonText">Save</label>
          </Button>
        </div>
        <div className="licenseEditDivContent">
          <div className="row">
            <div className="col-12">
            {this.props.groups && this.renderTicketGroup(this.props.groups, false)}
            </div>
          </div>
          <div className="row" style={{marginTop : 10}}>
            <div className="justify-content-left">
              {!this.props.loadingGroups && <div>
                <Button onClick={() =>{this.props.addTicketGroup()}} className="buttonAction buttonAccount" >
                  <AddBoxSharpIcon  className="buttonIcon"/>
                  <label style={{margin : 0, cursor: 'pointer'}} className="buttonText">New Ticket Group</label>
                </Button>
              </div>}
              {this.props.loadingGroups && 
              <div className="row d-flex align-items-center justify-content-center" style={{width : 500, marginTop : 10}}>
                <div className="d-flex align-items-center justify-content-center authSpinnerDiv" style={{width : '100%'}}>
                  <span className="labelLoadingBar">UPDATING TICKET GROUPS</span>
                  <div className="barLoaderPortal">
                    <BarLoader sizeUnit={"px"} size={1} color={"#095399"} loading={true}/>
                  </div>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
        <Dialog open={this.state.showWarning} onClose={() =>this.handleClose()}>
          <DialogTitle>{"Do you want to delete this Group?"}</DialogTitle>
          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={()=> this.handleClose()}>
              <label className="buttonText small" style={{margin : 0, cursor : 'pointer'}}>Cancel</label>
            </Button>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={()=> {this.handleClose(); this.props.deleteGroup(this.state.selectedGroup.GroupLabel, this.state.selectedGroup.TicketGroupId)}}>
              <label className="buttonText small" style={{margin : 0, cursor : 'pointer'}}>Accept</label>
            </Button>
          </DialogActions>
        </Dialog>
        <div id = "layersListDiv" className="modal" style={{paddingTop : 0}}>
          <div className="modal-content" style={{width : '50%', margingTop : "25%"}}>
          <div className="labelHeaderBar"> <label className="labelHeaderName">Assign Group Layers - {this.state.currentGroup && this.state.currentGroup.GroupLabel}</label></div>
            <div style={{flex : 1, margin : '0 auto'}}>
              <div style={{ maxWidth: 295}} className="d-flex flex-column">
                <div className="text-right"></div>
                  <input placeholder ="Search" type="text" value={this.state.filterValue} onChange={e =>{this.setState({filterValue : e.target.value},()=>{this.props.filterLayers(this.state.filterValue)})}} className="form-control searchInput" style={{width: '45vw'}}/>
              </div>
              <div className="groupAssignmentUserList" style={{minWidth : '45vw'}}>
                <div style={{minHeight : 10}}/>
                {this.props.assignmentAreas && this.props.assignmentAreas.length > 0 && this.props.filteredAssignmentAreas.map((area,index) =>(
                  !area.DeletedDate && <div key={index} onClick={()=>{this.selectAreas(area)}}>
                  <input style={{marginRight: 10, marginLeft : 10}} type="checkbox" 
                    checked={this.layerIsInticketGroup(area)}
                    
                  />
                  <label  >{area.FeatureName ? area.FeatureName : area.Label}</label>
                </div>
                ))}
              </div>
            </div>
              <div style={{margin : 10, justifyContent : 'space-between', display : 'flex'}}>
                <div className="">
                  <Button onClick={()=>{this.selectAreas("ADDALL")}}className="buttonAction btn btn-primary " style={{marginRight : 10, display: 'inline-block'}}>
                    <label className ="buttonText" style={{marginBottom:8}}>Add All Layers</label>
                  </Button>
                  <Button onClick={()=>{this.selectAreas("REMOVEALL")}}className="buttonAction btn btn-primary" style={{display: 'inline-block'}}>
                    <label className ="buttonText"  style={{marginBottom:8}}>Remove All Layers</label>
                  </Button>
                </div>
                <div className="">
                  <Button onClick={()=>{this.setState({filterValue : ''},()=>{this.props.filterLayers()});document.getElementById("layersListDiv").style.display = 'none'}}className="buttonAction btn btn-primary buttonFaded" style={{marginRight : 10, display: 'inline-block', minWidth : 110}}>
                    <label className ="buttonText" style={{marginBottom:8}}>Cancel</label>
                  </Button>
                  <Button onClick={()=>{this.saveTicketLayerGroups();document.getElementById("layersListDiv").style.display = 'none';}}className="buttonAction btn btn-primary confirm" style={{display: 'inline-block', minWidth : 110}}>
                    <label className ="buttonText"  style={{marginBottom:8}}>Save</label>
                  </Button>
                </div>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

TicketGroup.propTypes ={
  groups : PropTypes.array,
  loadingGroups : PropTypes.bool,
  addTicketGroup : PropTypes.func,
  updateGroups : PropTypes.func,
  editGroup : PropTypes.func,
  deleteGroup : PropTypes.func,
  expandGroup : PropTypes.func,
  forceRefresh : PropTypes.func,
}

export default TicketGroup;