import React from 'react';
import { Button } from 'react-bootstrap';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PropTypes from 'prop-types';

class CreateTaskButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      width : 0,
      height: 0
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount(){
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount(){
    window.removeEventListener('resize',this.updateWindowDimensions);
  }


  updateWindowDimensions(){
    this.setState({width: window.innerWidth, height: window.innerHeight});
  } 

  render(){
    return(
      <div className="createTaskButton" style = {this.props.fromGPS ? {top : this.state.width > 1512 ? 94 : 109} : {}}>
        <Button variant='secondary' className="buttonAction buttonSearch buttonCreateTask" onClick={() =>{this.props.createTask()}}
          style={{padding:'1px !important', margin:'0px !important', backgroundColor: '#DDD !important', display: 'block'}}>
            <AssignmentIndIcon className ="buttonIcon dark"/>
            <label className="buttonText extraSmall dark" style={{fontSize: '11 !important'}}>+ Task</label>
        </Button>
      </div>
    )
  }
}

CreateTaskButton.propTypes ={
  createTask : PropTypes.func,
  fromGPS : PropTypes.bool
}

export default CreateTaskButton;