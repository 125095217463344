import React from "react";
import DonutSmall from "@material-ui/icons/DonutSmall";
import PropTypes from "prop-types";

class ServicesPage extends React.Component {
  componentDidMount() {
    this.props.forceRefresh();
  }

  render() {
    return (
      <div
        className="col-8 d-flex justify-content-center p-0 m-0"
        style={{ backgroundColor: "#E9E9EF" }}
      >
        <div>
          <h1>
            <DonutSmall
              style={{
                fontSize: 36,
                marginBottom: 7,
                marginRight: 5,
                color: "green"
              }}
            />
            Services
          </h1>
        </div>
      </div>
    );
  }
}
ServicesPage.propTypes = {
  location: PropTypes.object,
  forceRefresh: PropTypes.func
};
export default ServicesPage;
