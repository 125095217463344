import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../Global/colors'
import { RingLoader } from "react-spinners";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import OrgUtility from '../../Utility/Utility'

class ProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoPlayVids: true,
      width: 0,
      height: 0,
      mobileWidth: 800,
      demoRequestFormSending: false,
      demoRequestFormSendSuccess: false,
      showDemoRequestForm: false,
      selectedDemoRequestForm: "",
    }
    this.inputRefs = {
      DemoForm: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
        business: null
      }
    };
    this.submitDemoRequest = this.submitDemoRequest.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
    this.props.forceRefresh();
    if (window.location.href.indexOf("?") != -1) {
      setTimeout(() => {
        document.getElementById(window.location.href.split("?")[1]).scrollIntoView({ block: 'start', behavior: 'smooth' });
      }, 1000);
    }
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  validateEmail(email) {
    const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return re.test(email);
  }

  submitDemoRequest(formFor) {
    let elementObj = this.inputRefs.DemoForm;

    let body = {
      product: formFor,
      email: elementObj.email.value,
      firstName: elementObj.firstName.value,
      lastName: elementObj.lastName.value,
      phone: elementObj.phone.value,
      business: elementObj.business.value,
      orgId: new OrgUtility().getOrgId()
    };

    const emailIsValid = this.validateEmail(body.email);

    let required = true;
    if (!body.email || body.email.trim() == "" || !emailIsValid) {
      required = false;
      elementObj.email.labels[0].style.color = 'red';
    }
    if (!body.firstName || body.firstName.trim() == "") {
      required = false;
      elementObj.firstName.labels[0].style.color = 'red';
    }
    if (!body.lastName || body.lastName.trim() == "") {
      required = false;
      elementObj.lastName.labels[0].style.color = 'red';
    }
    if (!body.phone || body.phone.trim() == "") {
      required = false;
      elementObj.phone.labels[0].style.color = 'red';
    }
    if (!body.business || body.business.trim() == "") {
      required = false;
      elementObj.business.labels[0].style.color = 'red';
    }

    if (required) {
      this.setFormSending(true);
      fetch(process.env.API_URL + "/api/CustomerDemo/request", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body)
      })
        .then(r => {
          this.setFormSending(false);
          if (r.status == 200) {
            this.setFormSendSuccess(true);
            setTimeout(() => {
              this.setState({ showDemoRequestForm: false, selectedDemoRequestForm: "" });
              this.setFormSendSuccess(false);
            }, 2000)
          }
          else {
            this.setFormSendSuccess(false);
            this.alertErrorSendingDemoForm();
          }
        }).
        catch((error) => {
          this.setFormSending(false);
          this.setFormSendSuccess(false);
          this.alertErrorSendingDemoForm(error);
        });
    }
  }

  cancelDemoRequest() {
    if (!this.state.demoRequestFormSending && !this.state.demoRequestFormSendSuccess) {
      this.setState({ showDemoRequestForm: false, selectedDemoRequestForm: "" });
    }
  }

  setFormSending(isSending) {
    this.setState({ demoRequestFormSending: isSending })
  }

  setFormSendSuccess(isSuccessful) {
    this.setState({ demoRequestFormSendSuccess: isSuccessful })
  }

  alertErrorSendingDemoForm() {
    alert("There was an error sending the demo request form. Please try agian.")
  }

  _handleKeyDown = (e, formFor) => {
    if (e.key === 'Enter') {
      if (this.state.demoRequestFormSending || this.state.demoRequestFormSendSuccess) return;
      this.submitDemoRequest(formFor);
    }
  }

  render() {
    let me = this;

    return (
      <div className="col-8  p-0 m-0" style={{ backgroundColor: "white", minWidth: "100%", overflowX: "hidden" }}>
        <div className="container" style={{ backgroundColor: 'white' }}>

          <div style={{ paddingRight: 15, paddingLeft: 15 }}>

            <div id="gpsSurveyContent">
                <div className="productPageTitle">
                  <a id="GPSSurvey">
                    <h4 className="box-title">GPS SURVEY</h4>
                  </a>
                </div>
                <div className="row">
                  <div className={this.state.width >= this.state.mobileWidth ? "col-8" : "col-12"}>
                  <iframe src="https://player.vimeo.com/video/630462719?h=748985a443" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                    <ul className="productPageUl">
                      <li className="productPageLi">Create <b>customized data dictionaries</b> specific to each project</li>
                      <li className="productPageLi">Capture precision mapping data attributes that utilize <b>GNSS and RTK correction</b> services</li>
                      <li className="productPageLi">Define geometry types and map symbology for advanced visualization</li>
                      <li className="productPageLi"><b>Customizable reporting</b> with survey and multimedia geolocation and timestamp data</li>
                      <li className="productPageLi">Use the Mobile Map Creator to edit survey points as they are collected</li>
                      <li className="productPageLi">Export data sets directly to your <b>ArcGIS Online Cloud</b> with custom field and data mapping </li>
                      <li className="productPageLi">Export data to file formats that include <b>Shapefiles, KML, and CSV</b></li>
                      <li className="productPageLi">Take <b>unlimited photos and record videos</b></li>
                      <li className="productPageLi">Add sketches and notes to any photo or map</li>
                      <li className="productPageLi">Visualize job site locations and <b>assign walk-back and location based tasks</b> to users from the GIS map</li>
                      <li className="productPageLi">Access cloud hosted data with real-time device capture</li>
                      <li className="productPageLi"><b>Customizable dashboards</b> for quick views of important data</li>
                    </ul>
                  </div>
                  <div className={this.state.width >= this.state.mobileWidth ? "col-4" : "col-12"} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: this.state.width < 1200 ? 275 : 370 }}>
                    <div className='productPageDemoRequestAndButtonsContainer' style={{ marginTop: this.state.width < this.state.mobileWidth ? '20px' : '0px' }}>

                      <div>
                        <div className="productPageMarketingButtonTop">
                          <a href="/learningcenter" className="marketAnchor">
                            <div className="productPageMarketingButtonText">
                              See How It Works
                          </div>
                          </a>
                        </div>
                        <div onClick={() => { this.setState({ showDemoRequestForm: true, selectedDemoRequestForm: 'GPS Survey' }) }} className="productPageMarketingButton">
                          <div className="productPageMarketingButtonText">
                            Request a Demo
                          </div>
                        </div>
                        <div className="productPageMarketingButton">
                          <a href="/register" className="marketAnchor">
                            <div className="productPageMarketingButtonText">
                              Start 30 Day Free Trial
                          </div>
                          </a>
                        </div>
                        <div className='productPageButtonPriceLabel'>
                          $50 per user license/month after trial
                      </div>
                      </div>

                      <div className="productsPageStoreButtonDiv">
                        <div className='productsPagePlayStoreButtonContainer' style={{ alignSelf: this.state.width >= this.state.mobileWidth ? 'flex-start' : 'flex-end' }}>
                          <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.lps.siteright">
                            <img className='productsPagePlayStoreButtonImg' src={require("../../media/googlePlay.png")} />
                          </a>
                        </div>
                        <div className='productsPageAppStoreButtonContainer' style={{ alignSelf: this.state.width >= this.state.mobileWidth ? 'flex-start' : 'flex-end' }}>
                          <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/app/launchpoint-siteright/id1459320312">
                            <img className='productsPageAppStoreButtonImg' src={require("../../media/appleStore.png")} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div style={{ height: '20px' }} />
                  </div>
                </div>
              </div>

            <div id="leakSurveyContent">
              <div className="productPageTitle">
                <a id="PipelineSurvey">
                  <h4 className={this.state.width >= this.state.mobileWidth ? "box-title productsPageBoxTitleAlignTextEnd" : "box-title"}>GIS SURVEY</h4>
                </a>
              </div>
              <div className="row">
                {this.state.width < this.state.mobileWidth &&
                  <div className={this.state.width >= this.state.mobileWidth ? "col-8" : "col-12"}>
                    {/* <video style={{ width: '100%', paddingRight: this.state.width >= this.state.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted 
                    src={"https://player.vimeo.com/external/554455216.sd.mp4?s=3e0d5aec22d718ca54e98122b98951b58a9f1f1d&profile_id=165"} type="video/mp4">
                      Your browser does not support html video
                    </video> */} 
                    
                    <iframe src="https://player.vimeo.com/video/554455216?h=718b391719" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                    <ul className="productPageUl">
                      <li className="productPageLi"><b>Digital pipeline inspections</b> including gas leak, atmospheric corrosion, cathodic protection, and more</li>
                      <li className="productPageLi">Optimized Technician routing</li>
                      <li className="productPageLi">Assign tasks for special projects or follow-up inspections</li>
                      <li className="productPageLi">Custom reporting capabilities with geolocation data and timestamps</li>
                      <li className="productPageLi">Validate and monitor work completion with digital trails</li>
                      <li className="productPageLi">Enhance quality control utilizing <b>auditing</b> features</li>
                      <li className="productPageLi">Customize business logic using the Workflow Creator</li>
                      <li className="productPageLi">Take <b>unlimited photos and record videos</b></li>
                      <li className="productPageLi">Add sketches and notes to any photo or map</li>
                      <li className="productPageLi">Access cloud hosted data in real-time</li>
                      <li className="productPageLi">Customized <b>real-time customized alerts & notifications</b></li>
                      <li className="productPageLi">Digitally record underground leak spread</li>
                      <li className="productPageLi"><b>Customizable dashboards</b> for quick views of important data</li>
                      <li className="productPageLi">Visualize job site locations using a GIS data map</li>
                    </ul>
                  </div>
                }
                <div className={this.state.width >= this.state.mobileWidth ? "col-4" : "col-12"} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: this.state.width < 1200 ? 275 : 370 }}>
                  <div className='productPageDemoRequestAndButtonsContainer' style={{ marginTop: this.state.width < this.state.mobileWidth ? '20px' : '0px' }}>

                    <div>
                      <div className="productPageMarketingButtonTop">
                        <a href="/learningcenter" className="marketAnchor">
                          <div className="productPageMarketingButtonText">
                            See How It Works
                        </div>
                        </a>
                      </div>
                      <div onClick={() => { this.setState({ showDemoRequestForm: true, selectedDemoRequestForm: 'Pipeline Survey' }) }} className="productPageMarketingButton">
                        <div className="productPageMarketingButtonText">
                          Request a Demo
                        </div>
                      </div>
                      <div className="productPageMarketingButton">
                        <a href="/register" className="marketAnchor">
                          <div className="productPageMarketingButtonText">
                            Start 30 Day Free Trial
                        </div>
                        </a>
                      </div>
                      <div className='productPageButtonPriceLabel'>
                        $65 per user license/month after trial
                    </div>
                    </div>

                    <div className="productsPageStoreButtonDiv">
                      <div className='productsPagePlayStoreButtonContainer' style={{ alignSelf: this.state.width >= this.state.mobileWidth ? 'flex-start' : 'flex-end' }}>
                        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.lps.siteright">
                          <img className='productsPagePlayStoreButtonImg' src={require("../../media/googlePlay.png")} />
                        </a>
                      </div>
                      <div className='productsPageAppStoreButtonContainer' style={{ alignSelf: this.state.width >= this.state.mobileWidth ? 'flex-start' : 'flex-end' }}>
                        <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/app/launchpoint-siteright/id1459320312">
                          <img className='productsPageAppStoreButtonImg' src={require("../../media/appleStore.png")} />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '20px' }} />
                </div>
                {this.state.width >= this.state.mobileWidth &&
                  <div className={this.state.width >= this.state.mobileWidth ? "col-8" : "col-12"}>
                    {/* <video style={{ width: '100%', paddingLeft: this.state.width >= this.state.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted 
                    src={"https://player.vimeo.com/external/554455216.sd.mp4?s=3e0d5aec22d718ca54e98122b98951b58a9f1f1d&profile_id=165"} type="video/mp4">
                      Your browser does not support html video
                    </video> */}
                    <iframe src="https://player.vimeo.com/video/554455216?h=718b391719" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                    <ul className="productPageUl productPageUlRight">
                    <li className="productPageLi"><b>Digital pipeline inspections</b> including gas leak, atmospheric corrosion, cathodic protection, and more</li>
                      <li className="productPageLi">Optimized Technician routing</li>
                      <li className="productPageLi">Assign tasks for special projects or follow-up inspections</li>
                      <li className="productPageLi"><b>Customizable reporting</b> with survey and multimedia geolocation and timestamp data</li>
                      <li className="productPageLi">Validate and monitor work completion with digital trails</li>
                      <li className="productPageLi">Enhance quality control utilizing auditing features</li>
                      <li className="productPageLi">Customize business logic using the Workflow Creator</li>
                      <li className="productPageLi">Take <b>unlimited photos and record videos</b></li>
                      <li className="productPageLi">Add sketches and notes to any photo or map</li>
                      <li className="productPageLi">Access cloud hosted data in real-time</li>
                      <li className="productPageLi">Customized <b>real-time customized alerts & notifications</b></li>
                      <li className="productPageLi">Digitally record underground leak spread</li>
                      <li className="productPageLi"><b>Customizable dashboards</b> for quick views of important data</li>
                      <li className="productPageLi">Visualize job site locations using a GIS data map</li>
                    </ul>
                  </div>
                }
              </div>
            </div>

            <div id="customFormsContent">
              <div className="productPageTitle">
                <a id="CustomForms">
                  <h4 className="box-title">CUSTOM FORMS</h4>
                </a>
              </div>
              <div className="row">
                <div className={this.state.width >= this.state.mobileWidth ? "col-8" : "col-12"}>
                  {/* <video style={{ width: '100%', paddingRight: this.state.width >= this.state.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/549357207.sd.mp4?s=9d8a6e32226405aa07c1b8e54abf959a98d10995&profile_id=165"} type="video/mp4">
                    Your browser does not support html video
                  </video> */}
                  <iframe src="https://player.vimeo.com/video/549357207?h=85b29be5fb" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                  <ul className="productPageUl">
                    <li className="productPageLi"><b>Create customized forms & workflows</b> with real-time alerts that eliminate paper processes</li>
                    <li className="productPageLi">Select from multiple unique field types that include photos, videos, signatures, QR/UPC and more</li>
                    <li className="productPageLi"><b>Customizable dashboards</b> for quick views of important data</li>
                    <li className="productPageLi">Add sketches and notes to any photo or map</li>
                    <li className="productPageLi">Take <b>unlimited photos and record videos</b></li>
                    <li className="productPageLi">Access cloud hosted data in real-time</li>
                    <li className="productPageLi"><b>Customizable reporting</b> with survey and multimedia geolocation and timestamp data</li>
                    <li className="productPageLi">Visualize job site locations using a GIS data map</li>
                    <li className="productPageLi">Validate data in real-time with custom business logic triggers and update events</li>
                  </ul>
                </div>

                <div className={this.state.width >= this.state.mobileWidth ? "col-4" : "col-12"} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: this.state.width < 1200 ? 275 : 370 }}>
                  <div className='productPageDemoRequestAndButtonsContainer' style={{ marginTop: this.state.width < this.state.mobileWidth ? '20px' : '0px' }}>

                    <div>
                      <div className="productPageMarketingButtonTop">
                        <a href="/learningcenter" className="marketAnchor">
                          <div className="productPageMarketingButtonText">
                            See How It Works
                        </div>
                        </a>
                      </div>
                      <div onClick={() => { this.setState({ showDemoRequestForm: true, selectedDemoRequestForm: 'Custom Forms' }) }} className="productPageMarketingButton">
                        <div className="productPageMarketingButtonText">
                          Request a Demo
                        </div>
                      </div>
                      <div className="productPageMarketingButton">
                        <a href="/register" className="marketAnchor">
                          <div className="productPageMarketingButtonText">
                            Start 30 Day Free Trial
                        </div>
                        </a>
                      </div>
                      <div className='productPageButtonPriceLabel'>
                        $50/month after trial
                    </div>
                    </div>

                    <div className="productsPageStoreButtonDiv">
                      <div className='productsPagePlayStoreButtonContainer' style={{ alignSelf: this.state.width >= this.state.mobileWidth ? 'flex-start' : 'flex-end' }}>
                        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.lps.siteright">
                          <img className='productsPagePlayStoreButtonImg' src={require("../../media/googlePlay.png")} />
                        </a>
                      </div>
                      <div className='productsPageAppStoreButtonContainer' style={{ alignSelf: this.state.width >= this.state.mobileWidth ? 'flex-start' : 'flex-end' }}>
                        <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/app/launchpoint-siteright/id1459320312">
                          <img className='productsPageAppStoreButtonImg' src={require("../../media/appleStore.png")} />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '20px' }} />
                </div>
              </div>
            </div>

            <div id="incidentInvestigationContent">
              <div className="productPageTitle">
                <a id="IncidentInvestigation">
                  <h4 className={this.state.width >= this.state.mobileWidth ? "box-title productsPageBoxTitleAlignTextEnd" : "box-title"}>INCIDENT INVESTIGATION</h4>
                </a>
              </div>
              <div className="row">
                {this.state.width < this.state.mobileWidth &&
                  <div className={this.state.width >= this.state.mobileWidth ? "col-8" : "col-12"}>
                    {/* <video style={{ width: '100%', paddingRight: this.state.width >= this.state.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/549356426.sd.mp4?s=1b62c86c9e962e2197f111f2dcc2319b624714a7&profile_id=165"} type="video/mp4">
                      Your browser does not support html video
                                </video> */}
                    <iframe src="https://player.vimeo.com/video/549356426?h=bd4e85894a" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                    
                    <ul className="productPageUl">
                      <li className="productPageLi">Manage liability by properly <b>documenting near misses and line strikes</b></li>
                      <li className="productPageLi"><b>Compare original jobsite photos</b> with Shadow Pics overlay tool</li>
                      <li className="productPageLi">Add sketches to any photo or map</li>
                      <li className="productPageLi"><b>Customizable reporting</b> with survey and multimedia geolocation and timestamp data</li>
                      <li className="productPageLi">Access cloud hosted data in real-time</li>
                      <li className="productPageLi">Take <b>unlimited photos and record videos</b></li>
                      <li className="productPageLi"><b>Customizable dashboards</b> for quick views of important data</li>
                      <li className="productPageLi">Comprehensive CGA DIRT reporting tool </li>
                      <li className="productPageLi">Upload reports to the CGA DIRT database quickly and easily</li>
                      <li className="productPageLi">Use default questionnaires or create your own by adding the Custom Forms module</li>
                      <li className="productPageLi"><b>Visualize job site locations</b> using a GIS data map</li>
                    </ul>
                  </div>
                }
                <div className={this.state.width >= this.state.mobileWidth ? "col-4" : "col-12"} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: this.state.width < 1200 ? 275 : 370 }}>
                  <div className='productPageDemoRequestAndButtonsContainer' style={{ marginTop: this.state.width < this.state.mobileWidth ? '20px' : '0px' }}>

                    <div>
                      <div className="productPageMarketingButtonTop">
                        <a href="/learningcenter" className="marketAnchor">
                          <div className="productPageMarketingButtonText">
                            See How It Works
                        </div>
                        </a>
                      </div>
                      <div onClick={() => { this.setState({ showDemoRequestForm: true, selectedDemoRequestForm: 'Incident Investigation' }) }} className="productPageMarketingButton">
                        <div className="productPageMarketingButtonText">
                          Request a Demo
                        </div>
                      </div>
                      <div className="productPageMarketingButton">
                        <a href="/register" className="marketAnchor">
                          <div className="productPageMarketingButtonText">
                            Start 30 Day Free Trial
                        </div>
                        </a>
                      </div>
                      <div className='productPageButtonPriceLabel'>
                        $22 per user license/month after trial
                    </div>
                    </div>

                    <div className="productsPageStoreButtonDiv">
                      <div className='productsPagePlayStoreButtonContainer' style={{ alignSelf: this.state.width >= this.state.mobileWidth ? 'flex-start' : 'flex-end' }}>
                        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.lps.siteright">
                          <img className='productsPagePlayStoreButtonImg' src={require("../../media/googlePlay.png")} />
                        </a>
                      </div>
                      <div className='productsPageAppStoreButtonContainer' style={{ alignSelf: this.state.width >= this.state.mobileWidth ? 'flex-start' : 'flex-end' }}>
                        <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/app/launchpoint-siteright/id1459320312">
                          <img className='productsPageAppStoreButtonImg' src={require("../../media/appleStore.png")} />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '20px' }} />
                </div>
                {this.state.width >= this.state.mobileWidth &&
                  <div className={this.state.width >= this.state.mobileWidth ? "col-8" : "col-12"}>
                    {/* <video style={{ width: '100%', paddingLeft: this.state.width >= this.state.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/549356426.sd.mp4?s=1b62c86c9e962e2197f111f2dcc2319b624714a7&profile_id=165"} type="video/mp4">
                      Your browser does not support html video
                                </video> */}
                    <iframe src="https://player.vimeo.com/video/549356426?h=bd4e85894a" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>  
                    <ul className="productPageUl productPageUlRight">
                      <li className="productPageLi">Manage liability by properly <b>documenting near misses and line strikes</b></li>
                      <li className="productPageLi"><b>Compare original jobsite photos</b> with Shadow Pics overlay tool</li>
                      <li className="productPageLi">Add sketches to any photo or map</li>
                      <li className="productPageLi"><b>Customizable reporting</b> with survey and multimedia geolocation and timestamp data</li>
                      <li className="productPageLi">Access cloud hosted data in real-time</li>
                      <li className="productPageLi">Take <b>unlimited photos and record videos</b></li>
                      <li className="productPageLi"><b>Customizable dashboards</b> for quick views of important data</li>
                      <li className="productPageLi">Comprehensive CGA DIRT reporting tool </li>
                      <li className="productPageLi">Upload reports to the CGA DIRT database quickly and easily</li>
                      <li className="productPageLi">Use default questionnaires or create your own by adding the Custom Forms module</li>
                      <li className="productPageLi"><b>Visualize job site locations</b> using a GIS data map</li>
                    </ul>
                  </div>
                }
              </div>
            </div>

            <div id="siteSurveyContent">
              <div className="productPageTitle">
                <a id="SiteSurvey">
                  <h4 className="box-title">SITE SURVEY</h4>
                </a>
              </div>
              <div className="row">
                <div className={this.state.width >= this.state.mobileWidth ? "col-8" : "col-12"}>
                  {/* <video style={{ width: '100%', paddingRight: this.state.width >= this.state.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/549353481.sd.mp4?s=d242cc45678c1777f265952e546d985e5e29fbc0&profile_id=165"} type="video/mp4">
                    Your browser does not support html video
                  </video> */}
                  <iframe src="https://player.vimeo.com/video/549353481?h=ecb550b8c4" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                  <ul className="productPageUl">
                    <li className="productPageLi">Manage liability by documenting pre-excavation job sites including white lines, potholing, and locate marks</li>
                    <li className="productPageLi"><b>Document work progress</b> to share with stakeholders</li>
                    <li className="productPageLi">Take unlimited photos and record videos</li>
                    <li className="productPageLi"><b>Customizable reporting</b> with survey and multimedia geolocation and timestamp data</li>
                    <li className="productPageLi">Add sketches to any photo or map</li>
                    <li className="productPageLi"><b>Customizable dashboards</b> for quick views of important data</li>
                    <li className="productPageLi">Access cloud hosted data in real-time</li>
                    <li className="productPageLi"><b>Visualize job site locations</b> using a GIS data map</li>
                  </ul>
                </div>

                <div className={this.state.width >= this.state.mobileWidth ? "col-4" : "col-12"} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: this.state.width < 1200 ? 275 : 370 }}>
                  <div className='productPageDemoRequestAndButtonsContainer' style={{ marginTop: this.state.width < this.state.mobileWidth ? '20px' : '0px' }}>

                    <div>
                      <div className="productPageMarketingButtonTop">
                        <a href="/learningcenter" className="marketAnchor">
                          <div className="productPageMarketingButtonText">
                            See How It Works
                        </div>
                        </a>
                      </div>
                      <div onClick={() => { this.setState({ showDemoRequestForm: true, selectedDemoRequestForm: 'Site Survey' }) }} className="productPageMarketingButton">
                        <div className="productPageMarketingButtonText">
                          Request a Demo
                        </div>
                      </div>
                      <div className="productPageMarketingButton">
                        <a href="/register" className="marketAnchor">
                          <div className="productPageMarketingButtonText">
                            Start 30 Day Free Trial
                        </div>
                        </a>
                      </div>
                      <div className='productPageButtonPriceLabel'>
                        $12 per user license/month after trial
                    </div>
                    </div>

                    <div className="productsPageStoreButtonDiv">
                      <div className='productsPagePlayStoreButtonContainer' style={{ alignSelf: this.state.width >= this.state.mobileWidth ? 'flex-start' : 'flex-end' }}>
                        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.lps.siteright">
                          <img className='productsPagePlayStoreButtonImg' src={require("../../media/googlePlay.png")} />
                        </a>
                      </div>
                      <div className='productsPageAppStoreButtonContainer' style={{ alignSelf: this.state.width >= this.state.mobileWidth ? 'flex-start' : 'flex-end' }}>
                        <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/app/launchpoint-siteright/id1459320312">
                          <img className='productsPageAppStoreButtonImg' src={require("../../media/appleStore.png")} />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '20px' }} />
                </div>
              </div>
            </div>

          </div>

          <div style={{ height: 50 }} />

        </div>

        <Dialog open={this.state.showDemoRequestForm}>
          <DialogContent style={{ padding: 0 }}>
            <div className="productsPageDemoRequestContainer">
              <div className="productsPageDemoRequestHeader">
                Request a Demo of<br />
                {this.state.selectedDemoRequestForm}
              </div>
              <div className="productsPageDemoRequestSubHeader">
                We will contact you shortly
              </div>
              <div className="productsDemoRequestFormFieldContainer">
                <div className="productsPageDemoRequestFormLabelContainer">
                  <label htmlFor="emailDemoForm" className="productsPageDemoRequestFormLabel" >Email:</label>
                </div>
                <input id="emailDemoForm"
                  onChange={(e) => {
                    e.target.labels[0].style.color = colors.officialDarkBlue
                  }}
                  ref={(input) => { me.inputRefs.DemoForm.email = input }}
                  placeholder=""
                  className="productsPageDemoRequest-form-control-small"
                  onKeyDown={(e) => { this._handleKeyDown(e, this.state.selectedDemoRequestForm) }}
                />
              </div>
              <div className="productsDemoRequestFormFieldContainer">
                <div className="productsPageDemoRequestFormLabelContainer">
                  <label htmlFor="firstNameDemoForm" className="productsPageDemoRequestFormLabel" >First Name:</label>
                </div>
                <input id="firstNameDemoForm"
                  onChange={(e) => {
                    e.target.labels[0].style.color = colors.officialDarkBlue
                  }}
                  ref={(input) => { me.inputRefs.DemoForm.firstName = input }}
                  placeholder=""
                  className="productsPageDemoRequest-form-control-small"
                  onKeyDown={(e) => { this._handleKeyDown(e, this.state.selectedDemoRequestForm) }}
                />
              </div>
              <div className="productsDemoRequestFormFieldContainer">
                <div className="productsPageDemoRequestFormLabelContainer">
                  <label htmlFor="lastNameDemoForm" className="productsPageDemoRequestFormLabel" >Last Name:</label>
                </div>
                <input id="lastNameDemoForm"
                  onChange={(e) => {
                    e.target.labels[0].style.color = colors.officialDarkBlue
                  }}
                  ref={(input) => { me.inputRefs.DemoForm.lastName = input }}
                  placeholder=""
                  className="productsPageDemoRequest-form-control-small"
                  onKeyDown={(e) => { this._handleKeyDown(e, this.state.selectedDemoRequestForm) }}
                />
              </div>
              <div className="productsDemoRequestFormFieldContainer">
                <div className="productsPageDemoRequestFormLabelContainer">
                  <label htmlFor="phoneDemoForm" className="productsPageDemoRequestFormLabel" >Phone:</label>
                </div>
                <input id="phoneDemoForm"
                  onChange={(e) => {
                    e.target.labels[0].style.color = colors.officialDarkBlue
                  }}
                  ref={(input) => { me.inputRefs.DemoForm.phone = input }}
                  placeholder=""
                  className="productsPageDemoRequest-form-control-small"
                  onKeyDown={(e) => { this._handleKeyDown(e, this.state.selectedDemoRequestForm) }}
                />
              </div>
              <div className="productsDemoRequestFormFieldContainer">
                <div className="productsPageDemoRequestFormLabelContainer">
                  <label htmlFor="businessDemoForm" className="productsPageDemoRequestFormLabel" >Business:</label>
                </div>
                <input id="businessDemoForm"
                  onChange={(e) => {
                    e.target.labels[0].style.color = colors.officialDarkBlue
                  }}
                  ref={(input) => { me.inputRefs.DemoForm.business = input }}
                  placeholder=""
                  className="productsPageDemoRequest-form-control-small"
                  onKeyDown={(e) => { this._handleKeyDown(e, this.state.selectedDemoRequestForm) }}
                />
              </div>
              {this.state.demoRequestFormSending ?
                <div className="productsPageDemoRequestSubmitButton">
                  <RingLoader color={colors.officialLightGray} loading={true} size={30} />
                </div>
                : this.state.demoRequestFormSendSuccess ?
                  <div className="productsPageDemoRequestSubmitButtonSuccess">
                    &#10003;
                          </div>
                  :
                  <div onClick={() => {
                    this.submitDemoRequest(this.state.selectedDemoRequestForm)
                  }} className="productsPageDemoRequestSubmitButton">
                    Submit
                    </div>
              }
              <div onClick={() => {
                this.cancelDemoRequest()
              }} className="productsPageDemoRequestCancelButton">
                Cancel
              </div>
            </div>
          </DialogContent>

        </Dialog>

      </div>
    )
  }
}

ProductsPage.propTypes = {
  width: PropTypes.number,
  mobileWidth: PropTypes.number,
  forceRefresh: PropTypes.func
}

export default ProductsPage;