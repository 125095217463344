import React from "react";
import { BarLoader } from 'react-spinners';
import { Button } from "react-bootstrap";
class PasswordModal extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      emailColor: "",
      statusText  : ""
    };
    this.submit = this.submit.bind(this);
  }

  submit() {
    let required = true;
    if (!this.state.email || this.state.email == "") {
      required = false;
      this.setState({ emailColor: "red" });
    }
    if (required) {
      let req = new XMLHttpRequest();
      req.open("POST", process.env.API_URL + "/api/account/resettoken", true);
      req.setRequestHeader("Accept", "application/json");
      req.setRequestHeader("Content-Type", "application/json");
      //req.setRequestHeader("Authorization","Bearer " + auth.token());
      let body = {
        User: {
          EmailAddress: this.state.email
        }
      };
      req.onload = () => {
        if (req.status == 200) {
          document.getElementById("forgotPassword").style.display = "none";
          this.setState({statusText : ""})
        } else {
          this.setState({statusText : "There was a problem reseting your password. Try again."});
        }
      };
      req.onerror = () => {
        this.setState({statusText : "There was a problem reseting your password. Try again."});
      };
      req.send(JSON.stringify(body));
      this.setState({statusText : "Processing..."})
    }
  }

  render() {
    return (
      <div id="forgotPassword" className="modal">
        <div className="modal-content passwordModal" style={{ paddingBottom: 5 }}>
          <div className="row">
            <div className="col-11" />
            <div className="col-1">
              <span className="close text-right" style={{top:0, right:6}}onClick={() => { document.getElementById("forgotPassword").style.display ="none";}}>
                &times;
              </span>
            </div>
          </div>
          <label className="mainHeader text-center" style={{ fontSize: "2.5rem", paddingTop: 0, paddingBottom:20 }}>
            Reset Password
          </label>
          <label htmlFor="email" style={{marginLeft:15, fontWeight: "bold", color: this.state.emailColor }} >
            Email Address
          </label>
          <input id="email" style={{ margin: "auto", width: 400 }} 
            onChange={e => { this.setState({ email: e.target.value, emailColor: "black" }); }} value={this.state.email}className="form-control"
          />
          <div style={{ margin: 20 }} className="text-center small">An email will be sent with instructions on how to reset your password.</div>
          {(this.state.statusText && this.state.statusText != "" )&&<div className = "row d-flex align-items-center justify-center-center">
            <div className = "col-12 d-flex align-items-center justify-content-center text-center authSpinnerDivClean">
              {this.state.statusText}
              <div className="barLoaderPortal">
              <BarLoader
                sizeUnit={"px"}
                size={1}
                color={"#095399"}
                loading={this.state.statusText == "Processing..."}
              /></div>
            </div>
          </div>}
          <Button onClick ={this.submit} className = "buttonAction" style={{ width: "99%"}}>
            <label style={{margin: 0, cursor: "pointer"}} className = "buttonText">Submit</label>
          </Button>
        </div>
      </div>
    );
  }
}

export default PasswordModal;
