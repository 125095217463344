import React from "react";
import Error from '@material-ui/icons/ErrorOutline';

class PageNotFound extends React.Component {
    render() {
      return (
          <div style={{padding:"50px"}}>
              <h4><Error style={{fontSize: 26, marginBottom: 7, marginRight: 5, color: "#828282"}}/>Page not found</h4>
          </div>
      );
    }
  }

export default PageNotFound;