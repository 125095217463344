import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import BasicFilm from 'react-film';

class LearningCenterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //TODO: set to true when publishing.
      width: 0,
      height: 0,
      selectedVideo: null
    }

    this.videos = {
      "1": {
        vimeoId: '549353481',
        vimeoTitle: 'SiteRight - Site Survey Module'
      },
      "2": {
        vimeoId: '543258905',
        vimeoTitle: 'SiteRight - Creating a Site Survey'
      },
      "3": {
        vimeoId: '549356426',
        vimeoTitle: 'SiteRight - Incident Investigation'
      },
      "4": {
        vimeoId: '543265080',
        vimeoTitle: 'SiteRight - Starting an Incident Investigation'
      },
      "5": {
        vimeoId: '549357207',
        vimeoTitle: 'SiteRight - Custom Forms'
      },
      "6": {
        vimeoId: '545699283',
        vimeoTitle: 'SiteRight Custom Forms - Creating a Form'
      },
      "7": {
        vimeoId: '545697334',
        vimeoTitle: 'Site Right - Field Types in Custom Forms'
      },
      "8": {
        vimeoId: '554455216',
        vimeoTitle: 'Site Right - Pipeline Survey'
      },
    };

  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
    this.props.forceRefresh();
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  openVideo(videoId) {
    this.setState({ selectedVideo: this.videos[videoId] });
  }

  render() {

    let videoWidth = this.state.width - 100;
    if (this.state.width <= 590) videoWidth = this.state.width - 100;
    if (videoWidth > 590) videoWidth = 590;
    //if(videoWidth < 300) videoWidth = 300;
    let videoHeight = (videoWidth * 0.5625);

    if (videoHeight > (this.state.height * 0.8)) {
      videoHeight = this.state.height * 0.8;
      videoWidth = videoHeight * 1.77;
    }

    return (
      <div className="col-8  p-0 m-0" style={{ backgroundColor: "transparent", minWidth: '100%', overflowX: "hidden" }}>
        <div className="container learningCenterContainer">
          <div className="row">
            <div className="col-12">
              <div style={{ marginTop: 20, marginBottom: 30 }}>
                <div style={{ marginLeft: this.state.width < 600 ? -30 : 0, marginRight: this.state.width < 600 ? -30 : 0 }}>
                  <div className="learningCenterCategoryLabel">
                    LEARNING CENTER
                  </div>
                  <div className="learningCenterIntroVideoContainer" >
                    <div style={{ height: videoHeight, width: videoWidth, position: 'relative' }}><iframe src={"https://player.vimeo.com/video/549353481?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&portrait=0&byline=0"} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} title="SiteRight - Site Survey Module"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                    {/* <div style={{ height: videoHeight, width: videoWidth, position: 'relative' }}><iframe src={"https://player.vimeo.com/video/536855615?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&portrait=0&byline=0"} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} title="SiteRight - Starting A Free Trial"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */}
                  </div>

                  <div className="learningCenterCategoryLabel">
                    Custom Forms
                  </div>
                  <div className="learningCenterVideoThumbStrip" >
                    <BasicFilm showDots={false} height={120}>
                      <div className="learningCenterThumbnail learningCenterTextThumbnail" onClick={() => { this.openVideo("5") }} >
                        Convert Any Paper Process to a Digital Form
                      </div>
                      <div className="learningCenterThumbnail learningCenterTextThumbnail" onClick={() => { this.openVideo("6") }} >
                        Creating a Custom Form
                      </div>
                      <div className="learningCenterThumbnail learningCenterTextThumbnail" onClick={() => { this.openVideo("7") }} >
                        SiteRight Custom Forms: Field Types
                      </div>
                    </BasicFilm>
                  </div>
                  
                  <div className="learningCenterCategoryLabel">
                    GIS Survey
                  </div>
                  <div className="learningCenterVideoThumbStrip" >
                    <BasicFilm showDots={false} height={120}>
                      <div className="learningCenterThumbnail learningCenterTextThumbnail" onClick={() => { this.openVideo("8") }} >
                        Optimize Routes and Track Technicians
                      </div>
                    </BasicFilm>
                  </div>
                
                  <div className="learningCenterCategoryLabel">
                    Incident Investigation
                  </div>
                  <div className="learningCenterVideoThumbStrip" >
                    <BasicFilm showDots={false} height={120}>
                      <div className="learningCenterThumbnail learningCenterTextThumbnail" onClick={() => { this.openVideo("3") }} >
                        Reduce Risk and Manage Liability
                      </div>
                      <div className="learningCenterThumbnail learningCenterTextThumbnail" onClick={() => { this.openVideo("4") }} >
                        Starting an Incident Investigation
                      </div>
                    </BasicFilm>
                  </div>
                
                  <div className="learningCenterCategoryLabel">
                    Site Survey
                  </div>
                  <div className="learningCenterVideoThumbStrip" >
                    <BasicFilm showDots={false} height={120}>
                      <div className="learningCenterThumbnail learningCenterTextThumbnail" onClick={() => { this.openVideo("1") }} >
                        Easily Document Job Sites
                      </div>
                      <div className="learningCenterThumbnail learningCenterTextThumbnail" onClick={() => { this.openVideo("2") }} >
                        Creating a Site Survey
                      </div>
                    </BasicFilm>
                  </div>
                
                </div>
              </div>
              <Dialog onClose={() => { this.setState({ selectedVideo: null }) }} open={this.state.selectedVideo}>
                <DialogContent style={{ padding: 0 }}>
                  {this.state.selectedVideo &&
                    <div className="learningCenterVideoDialogContainer">

                      <div style={{ height: videoHeight, width: videoWidth, position: 'relative' }}><iframe src={"https://player.vimeo.com/video/" + this.state.selectedVideo.vimeoId + "?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&portrait=0&byline=0"} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} title={this.state.selectedVideo.vimeoTitle}></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>

                    </div>
                  }
                </DialogContent>
              </Dialog>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

LearningCenterPage.propTypes = {
  width: PropTypes.number,
  mobileWidth: PropTypes.number,
  forceRefresh: PropTypes.func
}

export default LearningCenterPage;