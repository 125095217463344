/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from "prop-types";

const CountBox = (props) => {
  const { label, count, description, surveyTypes } = props;
  const handleClick = (e) => {
    if(props.draggingElement){
      return;
    } 
    e.preventDefault();
    e.stopPropagation();
    if(props.TicketTab){
      props.filterCallback({ 
        filterType: 'ByCountTicket',
        filterValue: label,
      },
      `Showing ${label} Tickets`);
    }
    else{
      props.filterCallback({ LeakSurveyTypeId: Number(props.id) }, `Showing ${surveyTypes[Number(props.id)]} Surveys.`);
    }
  }
  return (
    <div onClick={handleClick} style={{overflow: "hidden"}} className="d-flex flex-row align-items-center justify-content-between h-100 w-100">
      <h1 
        style={{minWidth: "20%", boxShadow: "inset 0px -6px 0px 0px rgba(220,220,220,0.5)"}} 
        className={props.styleclass}  
      >
        <span style={{marginBottom: 8, padding:12}}>{count}</span>
      </h1>
      <div style={{width: "80%"}} className="d-flex flex-column align-items-center justify-content-center">
        <h3 className="h-100 m-0 d-flex align-items-center justify-content-center">{label}</h3>
        {description && <small className="text-secondary">{description} </small>}
      </div>
    </div>
  );
}

CountBox.propTypes = {
  label : PropTypes.string,
  count : PropTypes.number,
  description : PropTypes.string,
  styleclass : PropTypes.string
}

export default CountBox;