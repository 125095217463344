/* eslint react/prop-types: 0 */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const CountByTech = (props) => {
  const [graphCategories, setGraphCategories] = React.useState([]);
  const [graphData, setGraphData] = React.useState([]);
  const [chart, setChart] = React.useState(null);
  React.useEffect(() => {
    let parsed = {};
    props.data.forEach(incident => {
      if(incident.ModifiedByDisplayName){
      parsed[incident.ModifiedByDisplayName]
      ? parsed[incident.ModifiedByDisplayName] = { name: incident.ModifiedByDisplayName, y: parsed[incident.ModifiedByDisplayName].y + 1}
      : parsed[incident.ModifiedByDisplayName] = { name: incident.ModifiedByDisplayName, y: 1 }
      }
    });
    //console.log(Object.keys(parsed), Object.values(parsed));
    setGraphCategories(Object.keys(parsed));
    // setGraphCategories(["test"])
    setGraphData(Object.values(parsed));
  }, [props.data])

  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  const chartCallback = c => setChart(c);

  return (
    <HighchartsReact 
      containerProps={{ className: "w-100 h-100 border border-dark" }}
      highcharts={Highcharts}
      options={{
        title: {text: "Investigation Count By Tech"},
        xAxis: {
          categories: graphCategories,
        },
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: function(event){
                  console.log("Event : ", event);
                  props.filterCallback({ ModifiedByDisplayName: event.point.category }, `Showing ${event.point.category}'s Investigations.`)
                }
              }
            }
          }
        },
        yAxis: {
          min: 0,
          title: { text: "Investigations" }
        },
        colors: ["#16558F", "#2D7952", "#DE8C26"],
        chart: {
          type: 'bar',
          backgroundColor: "#E9E9EF",
        },
        series: [
          {
            name: "Investigations",
            data: graphData
          }
        ],
        credits: { enabled: false },
        legend: { enabled: false }
      }}
      allowChartUpdate
      callback={chartCallback}
      constructorType="chart"
    />
  );
}

export default CountByTech;