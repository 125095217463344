import React from "react";
import PropTypes from "prop-types";
import auth from "../common/Auth";
import ImageGallery from 'react-image-gallery';
//eslint-disable-next-line
import PhotoUpload from './PhotoUpload';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BarLoader } from "react-spinners";
import Popup from "reactjs-popup";
import "../app.css";

class SiteSafeBody extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      imgUrl: "",
      galleryItems :[],
      images:[],
      blobs:[],
      loading: false,
      picUrl:null,
      modalOpen: false,
      currentIndex:0
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.formatHorizontal = this.formatHorizontal.bind(this);
    this.getMultimedia = this.getMultimedia.bind(this);
    this.controller = new AbortController();
  }

  componentDidMount(){
    this.getMultimedia();
  }

  getMultimedia(){
    if(this.props.siteSafe != undefined && this.props.siteSafe.MultimediaAssets != undefined){
      this.props.siteSafe.MultimediaAssets.forEach((element, index) =>{
        this.getPic(element.MultimediaAssetId, element.Timestamp, index);
      });
    }
    else if(this.props.siteSafe != undefined){
      fetch(process.env.API_URL + "/api/entityImages/" + this.props.siteSafe.Id + "/"+ this.props.siteSafe.EntityTypeId + "/web", {
        method: "POST",
        headers: {"Content-type" : "application/json", Authorization: "Bearer " + auth.token()}
      })
      .then(r => {
        if(r.status==200){
          r.json().then(bodyJson =>{
            if(bodyJson.length >0){
              let response = JSON.parse(bodyJson);
              this.setState({MultimediaAssets : response.MultimediaAssets});
              this.props.setPictureCount(response.MultimediaAssets.length);
              response.MultimediaAssets.forEach((element, index) =>{ this.getPic(element.MultimediaAssetId,element.Timestamp, index)});
            }
          })
        }
      })
    }
  }

  componentDidUpdate(previousProps){
    if( previousProps.siteSafe && this.props.siteSafe && (previousProps.siteSafe.Id != this.props.siteSafe.Id)){
      this.controller.abort();
      this.controller = new AbortController();
      this.setState({images: [],videos:[],blobs: [],galleryItems : [],pictureCount: 0,videoCount: 0},() =>{this.getMultimedia()});
    }
    window.resize();
  }

  getPic(assetId, timeStamp){
    this.setState({loading : true});
    fetch(process.env.API_URL + "/api/image/" + assetId,{
      method:"GET",
      header: {
        "Content-Type" : "application/json",
        Authorization: "Bearer " + auth.token()
      },
      signal : this.controller.signal
    })
    .then(r => r.blob())
    .then(blob =>{
      let images = this.state.images;
      let pic = URL.createObjectURL(blob);
      images.push({original: pic, thumbnail:pic, assetId, timeStamp});
      images.sort((a,b) => (a.timeStamp > b.timeStamp) ? 1 : -1);
      this.setState({images});
      let newCount = this.state.imagCount +1;
      this.setState({
        imgUrl :pic,
        imgCount: newCount,
      });
      let tempArr = this.state.galleryItems;
      tempArr.push(blob);
      this.setState({blobs: tempArr});
      this.formatHorizontal();
      if(this.state.MultimediaAssets != null && this.state.galleryItems.length == this.state.MultimediaAssets.length){
        this.setState({loading: false});
      }
    }).catch(error =>{console.log(error.message); this.setState({loading : false})});
  }

  formatHorizontal(){
    let pictures = document.querySelectorAll('.image-gallery-slide img');
    for(let i = 0; i< pictures.length; i++){
      let pic = pictures[i];
      let img = new Image;
      img.onload = function(){
        if(img.width > img.height){
          let parentDiv = pic.parentElement;
          parentDiv.classList.remove('image-gallery-slide');
          parentDiv.classList.add('image-gallery-slide-horizontal');
        }
      }
      img.src = pic.src;
    }
  }

  openModal(){
    this.setState({ modalOpen:true},()=>{
      setTimeout(() =>{
        let pic = document.getElementById('popupPic');
        if(pic.width >pic.height){
          let modal = document.getElementsByClassName("popup-content")[0];
          modal.classList.add("popup-horizontal");
        }
      }, 100);
    });
  }

  closeModal(){
    this.setState({modalOpen : false});
  }

  clickPic(){
    this.setState({picUrl: this.state.images[this._imageGallery.getCurrentIndex()].original});
    this.openModal();
  }

  render(){
    this.formatHorizontal();
    let timeZone = " "  + Intl.DateTimeFormat().resolvedOptions().timeZone;
    return(
      <div id="SurveyBodyMainDiv" name="SurveyBodyMainDiv"className="detailMain">
        <div className="detailContent">
          <div className="labelHeaderBar labelHeaderBarTight"><label className="labelHeaderName">SiteSafe Details</label></div>
          <div className ="detailContentPanel">
            <label className="labelDetailName">Id: </label>{this.props.siteSafe.EmployeeIdDisplayName != null ? this.props.siteSafe.EmployeeIdDisplayName :  this.props.siteSafe.EmployeeId}
            <br />
            <label className="labelDetailName">Submitted by: </label>{this.props.siteSafe.ModifiedByDisplayName}
            <br />
            <label className="labelDetailName">Survey Date: </label>{" "}
            {/**The "Z" is to tell the Date object that the survey.date is in UTC time. So then it will convert it to your time zone. */}
            {new Date(this.props.siteSafe.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}) + timeZone}
            <br />
            <label className="labelDetailName">Had a fever, cough, shortness of breath, sore throat, loss of taste or smell recently: </label>{this.props.siteSafe.FeverCough ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Had traveled outside of the country in the past 14 days: </label>{this.props.siteSafe.Traveled ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Had been in close contact with anyone that has been tested positive or is suspected of having Covid-19: </label>{this.props.siteSafe.CloseContact ? "Yes" : "No"}
            <br />
            <label className="labelDetailName">Temperature (°F): </label>{this.props.siteSafe.Temperature}
            <br />
            <label className="labelDetailName">Pass/Fail: </label>{this.props.siteSafe.Passed ? "Passed" : "Failed"}
          </div>

          
        </div>
        <div style={{margin:40}}>
            <div className="labelHeaderBar labelHeaderBarTight"> <label className="labelHeaderName">Photos ({this.state.MultimediaAssets != null ? this.state.MultimediaAssets.length : '0'})</label></div>
            <div className="detailContentPanel">
              {this.state.loading ? <div className="barLoaderProtal"><BarLoader sizeUnit={"px"} size={200} color={"#095399"} loading={this.state.loading}/></div> : null}
              {this.state.MultimediaAssets != null && this.state.MultimediaAssets.length > 0? 
                <div className="row carouselRow">
                  <div className="carouselGalleryDiv">
                    <ImageGallery items={this.state.images}
                      onClick={() =>{ this.clickPic();}}
                      showFullscreenButton={this.state.images.length > 0}
                      showPlayButton={this.state.images.length > 0}
                      ref={i => this._imageGallery = i}
                    />
                  </div>
                </div> : null}
            </div>
        </div>
        {/* <div className="detailContent"> DO WE WANT TO BE ABLE TO UPLOAD PHOTOS TO THIS? 
            <PhotoUpload entityId={this.props.siteSafe.siteSafe} entityTypeId={0} addToGallery={(image) =>{let ma = this.state.MultimediaAssets; ma.length++;let pic = URL.createObjectURL(image); let pictures = this.state.images; pictures.push({original : pic, thumbnail : pic}); let tempArr = this.state.galleryItems; tempArr.push(image); this.setState({ images : pictures, blobs : tempArr, MultimediaAssets : ma})}} />
        </div> */}
        <Popup modal={true} lockScroll={false} open={this.state.modalOpen} onClose={this.closeModal} closeOnDocumentClick closeOnEscape position='top left'>
          <div style={{height:"100%"}} className ="justify-content-center text-center galleryPopup">
            <img className = "justify-content-center text-center popupPic" id = "popupPic"src={this.state.picUrl}/>
          </div>
        </Popup>
      </div>
    )
  }
}

SiteSafeBody.propTypes = {
  siteSafe : PropTypes.object,
  setPictureCount : PropTypes.func,
  closeEntity : PropTypes.func
};

export default SiteSafeBody;