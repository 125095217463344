import React from 'react';
import PropTypes from 'prop-types';

class MarketingContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoPlayVids: true
    }
  }

  render() {

    return (
      <div className="container" style={{ marginTop: 50 }}>

        <div>

          <div id="gpsSurveyContent" className='marketingContentSection' />
          <div className="row">
            {this.props.width > this.props.mobileWidth &&
              <div className={ "col-6" }>
                <video style={{ width: '100%', paddingRight: this.props.width > this.props.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/630462719.sd.mp4?s=05a885bfb69c168829fbd97821c54abbd98a6d5f&profile_id=165"} type="video/mp4">
                  Your browser does not support html video
                </video>
              </div>
            }

            <div className={this.props.width > this.props.mobileWidth ? "col-6" : "col-12"}>
              <h4 className="box-title">GPS SURVEY</h4>
              <div className="subHeader3" style={{ lineHeight: "normal" }}>Precision mapping services that utilize GNSS and RTK correction services. Visualize and share GIS data across your organization.</div>
              
              {this.props.width <= this.props.mobileWidth &&
              <div style={{width: '100%'}}>
                <video style={{ width: '100%', paddingRight: this.props.width > this.props.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/630462719.sd.mp4?s=05a885bfb69c168829fbd97821c54abbd98a6d5f&profile_id=165"} type="video/mp4">
                  Your browser does not support html video
                </video>
              </div>
            }
              
              <div className='marketingButtonsContainer'>
                <div>
                  <div className="marketingButton">
                    <a href="/products?GPSSurvey" className="marketAnchor">
                      <div className="marketingButtonText">
                        Learn More
                        </div>
                    </a>
                  </div>

                  <div className="marketingButton">
                    <a href="/register" className="marketAnchor">
                      <div className="marketingButtonText">
                        Start 30 Day Free Trial
                        </div>
                    </a>
                  </div>
                </div>

                <div className="registrationStoreDiv" style={{marginLeft:  '15px'}}>
                  <div className='marketingAppStoreButtonContainer' style={{alignSelf: this.props.width > this.props.mobileWidth ? 'flex-start' : 'flex-end'}}>
                    <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.lps.siteright">
                      <img className='appStoreButtonImg' src={require("../../media/googlePlay.png")} />
                    </a>
                  </div>
                  <div className='marketingAppStoreButtonContainer' style={{alignSelf: this.props.width > this.props.mobileWidth ? 'flex-start' : 'flex-end'}}>
                    <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/app/launchpoint-siteright/id1459320312">
                      <img className='appStoreButtonImg' src={require("../../media/appleStore.png")} />
                    </a>
                  </div>
                </div>
              </div>
              <div className='marketingButtonPriceLabel'>
                $25 per user license/month after trial
                </div>
                <div style={{height: '20px'}}/>
            </div>
          </div>

          <div id="leakSurveyContent" className='marketingContentSection' />
          <div className="row">
            <div className={this.props.width > this.props.mobileWidth ? "col-6" : "col-12"}>
              <h4 className="box-title">GIS SURVEY</h4>
              <div className="subHeader3" style={{ lineHeight: "normal" }}>Track technician movement, report leaks &amp; AOCs and leverage optional system map integration utilizing fully electronic processes. Report system inspection milestones and associated billable items for easy invoicing.</div>
              
              {this.props.width <= this.props.mobileWidth &&
                <div style={{width: '100%'}}>
                  <video style={{ width: '100%', paddingLeft: this.props.width > this.props.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/554455216.sd.mp4?s=3e0d5aec22d718ca54e98122b98951b58a9f1f1d&profile_id=165"} type="video/mp4">
                    Your browser does not support html video
                  </video>
                </div>
              }
              
              <div className='marketingButtonsContainer'>
                <div>
                  <div className="marketingButton">
                    <a href="/products?PipelineSurvey" className="marketAnchor">
                      <div className="marketingButtonText">
                        Learn More
                    </div>
                    </a>
                  </div>

                  <div className="marketingButton">
                    <a href="/register" className="marketAnchor">
                      <div className="marketingButtonText">
                        Start 30 Day Free Trial
                    </div>
                    </a>
                  </div>
                </div>

                <div className="registrationStoreDiv" style={{marginLeft:  '15px'}}>
                  <div className='marketingAppStoreButtonContainer' style={{alignSelf: this.props.width > this.props.mobileWidth ? 'flex-start' : 'flex-end'}}>
                    <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.lps.siteright">
                      <img className='appStoreButtonImg' src={require("../../media/googlePlay.png")} />
                    </a>
                  </div>
                  <div className='marketingAppStoreButtonContainer' style={{alignSelf: this.props.width > this.props.mobileWidth ? 'flex-start' : 'flex-end'}}>
                    <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/app/launchpoint-siteright/id1459320312">
                      <img className='appStoreButtonImg' src={require("../../media/appleStore.png")} />
                    </a>
                  </div>
                </div>
              </div>
              <div className='marketingButtonPriceLabel'>
              $65 per user license/month after trial
            </div>
              <div style={{height: '20px'}}/>
            </div>
            {this.props.width > this.props.mobileWidth &&
            <div className={"col-6"}>
              <video style={{ width: '100%', paddingLeft: this.props.width > this.props.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/554455216.sd.mp4?s=3e0d5aec22d718ca54e98122b98951b58a9f1f1d&profile_id=165"} type="video/mp4">
                Your browser does not support html video
              </video>
            </div>
            }
          </div>

          <div id="customFormsContent" className='marketingContentSection' />
          <div className="row">
            {this.props.width > this.props.mobileWidth &&
              <div className={ "col-6" }>
                <video style={{ width: '100%', paddingRight: this.props.width > this.props.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/549357207.sd.mp4?s=9d8a6e32226405aa07c1b8e54abf959a98d10995&profile_id=165"} type="video/mp4">
                  Your browser does not support html video
                </video>
              </div>
            }

            <div className={this.props.width > this.props.mobileWidth ? "col-6" : "col-12"}>
              <h4 className="box-title">CUSTOM FORMS</h4>
              <div className="subHeader3" style={{ lineHeight: "normal" }}>Exchange an outdated paper process with (customizable) scalable digital forms. Administrators can create and allocate specific forms to specific users, drive specific action, gather responses, and report compliance.</div>
              
              {this.props.width <= this.props.mobileWidth &&
              <div style={{width: '100%'}}>
                <video style={{ width: '100%', paddingRight: this.props.width > this.props.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/549357207.sd.mp4?s=9d8a6e32226405aa07c1b8e54abf959a98d10995&profile_id=165"} type="video/mp4">
                  Your browser does not support html video
                </video>
              </div>
            }
              
              <div className='marketingButtonsContainer'>
                <div>
                  <div className="marketingButton">
                    <a href="/products?CustomForms" className="marketAnchor">
                      <div className="marketingButtonText">
                        Learn More
                      </div>
                    </a>
                  </div>

                  <div className="marketingButton">
                    <a href="/register" className="marketAnchor">
                      <div className="marketingButtonText">
                        Start 30 Day Free Trial
                      </div>
                    </a>
                  </div>
                </div>

                <div className="registrationStoreDiv" style={{marginLeft:  '15px'}}>
                  <div className='marketingAppStoreButtonContainer' style={{alignSelf: this.props.width > this.props.mobileWidth ? 'flex-start' : 'flex-end'}}>
                    <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.lps.siteright">
                      <img className='appStoreButtonImg' src={require("../../media/googlePlay.png")} />
                    </a>
                  </div>
                  <div className='marketingAppStoreButtonContainer' style={{alignSelf: this.props.width > this.props.mobileWidth ? 'flex-start' : 'flex-end'}}>
                    <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/app/launchpoint-siteright/id1459320312">
                      <img className='appStoreButtonImg' src={require("../../media/appleStore.png")} />
                    </a>
                  </div>
                </div>
              </div>
              <div className='marketingButtonPriceLabel'>
                  $50/month after trial
                </div>
                <div style={{height: '20px'}}/>
            </div>
          </div>

          <div id="incidentInvestigationContent" className='marketingContentSection' />
          <div className="row">
            <div className={this.props.width > this.props.mobileWidth ? "col-6" : "col-12"}>
              <h4 className="box-title">INCIDENT INVESTIGATION</h4>
              <div className="subHeader3" style={{ lineHeight: "normal" }}>Collect data, pictures, or video from construction near misses or line strikes. Compare information from original jobsite photos to manage liability and prove compliance. Generate reports and submit data to the DIRT database with the press of a button.</div>
              
              {this.props.width <= this.props.mobileWidth &&
                <div style={{width: '100%'}}>
                  <video style={{ width: '100%', paddingLeft: this.props.width > this.props.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/549356426.sd.mp4?s=1b62c86c9e962e2197f111f2dcc2319b624714a7&profile_id=165"} type="video/mp4">
                    Your browser does not support html video
                  </video>
                </div>
              }

              <div className='marketingButtonsContainer'>
                <div>
                  <div className="marketingButton">
                    <a href="/products?IncidentInvestigation" className="marketAnchor">
                      <div className="marketingButtonText">
                        Learn More
                        </div>
                    </a>
                  </div>

                  <div className="marketingButton">
                    <a href="/register" className="marketAnchor">
                      <div className="marketingButtonText">
                        Start 30 Day Free Trial
                        </div>
                    </a>
                  </div>
                </div>

                <div className="registrationStoreDiv" style={{marginLeft:  '15px'}}>
                  <div className='marketingAppStoreButtonContainer' style={{alignSelf: this.props.width > this.props.mobileWidth ? 'flex-start' : 'flex-end'}}>
                    <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.lps.siteright">
                      <img className='appStoreButtonImg' src={require("../../media/googlePlay.png")} />
                    </a>
                  </div>
                  <div className='marketingAppStoreButtonContainer' style={{alignSelf: this.props.width > this.props.mobileWidth ? 'flex-start' : 'flex-end'}}>
                    <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/app/launchpoint-siteright/id1459320312">
                      <img className='appStoreButtonImg' src={require("../../media/appleStore.png")} />
                    </a>
                  </div>
                </div>
              </div>
              <div className='marketingButtonPriceLabel'>
                $22 per user license/month after trial
                </div>
                <div style={{height: '20px'}}/>
            </div>
            {this.props.width > this.props.mobileWidth &&
              <div className={"col-6"}>
                <video style={{ width: '100%', paddingLeft: this.props.width > this.props.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/549356426.sd.mp4?s=1b62c86c9e962e2197f111f2dcc2319b624714a7&profile_id=165"} type="video/mp4">
                  Your browser does not support html video
              </video>
              </div>
            }
          </div>

          <div style={{ height: 50 }} />
            
          <div id="siteSurveyContent" style={{ paddingTop: '25px', marginTop: '-25px' }} className="row" >
            {this.props.width > this.props.mobileWidth &&
              <div className={"col-6"}>
                <video style={{ width: '100%', paddingRight: this.props.width > this.props.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/549353481.sd.mp4?s=d242cc45678c1777f265952e546d985e5e29fbc0&profile_id=165"} type="video/mp4">
                  Your browser does not support html video
            </video>
              </div>
            }
            <div className={this.props.width > this.props.mobileWidth ? "col-6" : "col-12"}>
              <h4 className="box-title">SITE SURVEY</h4>
              <div className="subHeader3" style={{ lineHeight: "normal" }}>Easily document and photograph construction jobsites before excavating to manage liability and prove compliance with dig laws. With real-time access any team member can share and update information at any time.</div>
              
              {this.props.width <= this.props.mobileWidth &&
              <div style={{width: '100%'}}>
                <video style={{ width: '100%', paddingRight: this.props.width > this.props.mobileWidth ? "30px" : "0px" }} autoPlay={this.state.autoPlayVids} loop controls muted src={"https://player.vimeo.com/external/549353481.sd.mp4?s=d242cc45678c1777f265952e546d985e5e29fbc0&profile_id=165"} type="video/mp4">
                  Your browser does not support html video
            </video>
              </div>
            }

              <div className='marketingButtonsContainer'>
                <div>
                  <div className="marketingButton">
                    <a href="/products?SiteSurvey" className="marketAnchor">
                      <div className="marketingButtonText">
                        Learn More
                        </div>
                    </a>
                  </div>

                  <div className="marketingButton">
                    <a href="/register" className="marketAnchor">
                      <div className="marketingButtonText">
                        Start 30 Day Free Trial
                        </div>
                    </a>
                  </div>
                </div>

                <div className="registrationStoreDiv" style={{marginLeft:  '15px'}}>
                  <div className='marketingAppStoreButtonContainer' style={{alignSelf: this.props.width > this.props.mobileWidth ? 'flex-start' : 'flex-end'}}>
                    <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.lps.siteright">
                      <img className='appStoreButtonImg' src={require("../../media/googlePlay.png")} />
                    </a>
                  </div>
                  <div className='marketingAppStoreButtonContainer' style={{alignSelf: this.props.width > this.props.mobileWidth ? 'flex-start' : 'flex-end'}}>
                    <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/app/launchpoint-siteright/id1459320312">
                      <img className='appStoreButtonImg' src={require("../../media/appleStore.png")} />
                    </a>
                  </div>
                </div>
              </div>
              <div className='marketingButtonPriceLabel'>
                $12 per user license/month after trial
                </div>
                <div style={{height: '20px'}}/>
            </div>

          </div>


        </div>

        <div style={{ height: 50 }} />

      </div>
    )
  }
}

MarketingContent.propTypes = {
  width: PropTypes.number,
  mobileWidth: PropTypes.number
}

export default MarketingContent;