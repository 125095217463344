import React from "react";
import PasswordModal from './PasswordModal';
import { Form, Button } from "react-bootstrap"; //,Image
import auth from "../common/Auth";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { BarLoader } from "react-spinners"; //, ClimbingBoxLoader, PacmanLoader, RingLoader
import OrgUtility from '../../Utility/Utility'
import "bootstrap/dist/css/bootstrap.min.css";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      apiBaseUrl: process.env.API_URL,
      username: "Username",
      password: "Password",
      clearBtnVisible: false,
      isAuthenticated: auth.isAuthenticated(),
      setAuth: false,
      showTwoFactor: false,
      authResponse: null,
      twoFactorCode: "",
      rememberDevice: true
    };
    this.GetToken = this.GetToken.bind(this);
    this.OnLogout = this.OnLogout.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.finishLogIn = this.finishLogIn.bind(this);
    this.checkTwoFactor = this.checkTwoFactor.bind(this);
    this.resendCode = this.resendCode.bind(this);
    this.updateRememberDevice = this.updateRememberDevice.bind(this);
  }

  componentDidMount() {
    window.onclick = (e) => {
      let modal = document.getElementById("forgotPassword");
      if (e.target == modal) { modal.style.display = "none"; }
    }
    this.setState({orgName: new OrgUtility().getOrgName(), appName: new OrgUtility().getAppName()});
    this.props.swap();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    // let sideBar = document.getElementById("sideBarContainer");
    // sideBar.style.display = "block";
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidUpdate() {
    //    let sideBar = document.getElementById("sideBarContainer");
    //HACK - Sometimes the sidebar gets created after this page is rendered. 
    //   if(!sideBar){
    window.resize();
    //    }
    //   else{
    //     if(this.state.width < 800 ){
    //       sideBar.style.display = "none";
    //     }
    //     else if(this.state.width >= 800){
    //         sideBar.style.display = "block";
    //     }
    //   }
  }

  OnLogout() {
    auth.logout();
    this.setState({
      token: "",
      responseText: "",
      isAuthenticated: false
    });
  }

  resendCode() {
    this.setState({ twoFactorMessage: "Resending code..." });
    let body = {
      UserId: this.state.authResponse.userId
    }
    fetch(process.env.API_URL + "/api/SendTwoFactorCode", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + this.state.authResponse.access_token
      },
      body: JSON.stringify(body)
    })
      .then(r => {
        if (r.status == 200) {
          r.json().then(responseJson => {
            console.log(responseJson);
            if (responseJson.responseType == 0) {
              this.setState({ twoFactorMessage: "Code Resent Successfully." });
            }
            else {
              this.setState({ twoFactorMessage: responseJson.responseMessageCollection[0].message ? responseJson.responseMessageCollection[0].message : "Error Resending Code. " })
            }
          })
        }
      })
      .catch(error => {
        this.setState({ twoFactorMessage: "Error resending Code. " + error.message });
      });
  }

  getGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16)
    });
  }

  updateRememberDevice(value) {
    this.setState({ rememberDevice: value });
  }

  GetToken(e) {
    e.preventDefault();
    auth.logout();
    this.setState({ isAuthenticated: false });
    let form = e.target;
    const un = form.elements.username.value;
    const pwd = form.elements.password.value;

    let params = {
      username: un,
      password: pwd,
      grant_type: "password",
      client_id: process.env.client_id
    };

    let requestBody = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      requestBody.push(encodedKey + "=" + encodedValue);
    }

    requestBody = requestBody.join("&");

    let deviceId = localStorage.getItem("deviceId");
    if (!deviceId || deviceId == "" || deviceId == "null") {
      deviceId = this.getGuid();
    }
    let p = new XMLHttpRequest();
    p.open("post", this.state.apiBaseUrl + "/token", true);
    p.setRequestHeader("Accept", "application/json");
    p.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    p.setRequestHeader("Request-Source", "website");
    p.setRequestHeader("DeviceId", deviceId);
    p.onload = async () => {
      if (p.status !== 200) {
        this.setState({
          token: p.response && JSON.parse(p.response).error_description ? JSON.parse(p.response).error_description : "Unable To Login - Try Again"
        });
      } else {
        let authResponse = JSON.parse(p.response);
        if (authResponse.requireOTP == "True") {
          localStorage.setItem("deviceId", deviceId);
          this.setState({ showTwoFactor: true, authResponse, tokenResponseText: p.responseText });
        }
        else {
          this.finishLogIn(authResponse);
        }
      }
    };

    p.onerror = () => {
      if (p.status === 0) {
        this.setState({
          token: "CORS Error!  Status: " + p.status + " " + p.responseText,
          clearBtnVisible: true
        });
      } else {
        this.setState({
          token: "ERROR! Status: " + p.status + " " + p.responseText,
          clearBtnVisible: true
        });
      }
    };

    p.send(requestBody);
    this.setState({
      token: "Authenticating...",
      clearBtnVisible: false
    });

    window.resize();
  }

  checkTwoFactor() {
    this.setState({ twoFactorMessage: "Checking code..." });
    let date = new Date();
    let body = {
      UserId: this.state.authResponse.userId,
      Code: this.state.twoFactorCode,
      DeviceId: localStorage.getItem("deviceId"),
      TimeStamp: date,
      UTCOffset: - (date.getTimezoneOffset() / 60),
      RememberDevice: this.state.rememberDevice
    }
    fetch(process.env.API_URL + "/api/VerifyPhoneTwoFactorCode", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + this.state.authResponse.access_token
      },
      body: JSON.stringify(body)
    })
      .then(r => {
        if (r.status == 200) {
          r.json().then(responseJson => {
            console.log(responseJson);
            if (responseJson.responseType == 0) {
              this.setState({ twoFactorMessage: "Success" });
              this.finishLogIn(this.state.authResponse);
            }
            else {
              this.setState({ twoFactorMessage: responseJson.responseMessageCollection[0].message ? responseJson.responseMessageCollection[0].message : "Error submitting Code. " });
            }
          });
        }
        else {
          this.setState({ twoFactorMessage: "Error submitting Code." });
        }
      }).
      catch(error => {
        this.setState({ twoFactorMessage: "Error submitting Code. " + error.message });
      });
  }

  finishLogIn(authResponse) {
    auth.login(authResponse);
    let body = {
      UserId: authResponse.userId,
      CompanyId: authResponse.companyId
    }
    fetch(process.env.API_URL + "/api/account/fieldOptions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + authResponse.access_token
      },
      body: JSON.stringify(body)
    })
      .then(r => {
        if (r.status == 200) {
          r.json().then(responseJson => {
            let response = JSON.parse(responseJson);
            if (response.ResponseType == 0) {
              if (response.SiteSurveyOptions) {
                localStorage.setItem("surveyStateList", JSON.stringify(response.SiteSurveyOptions.StateList))
              }
              if (response.InvestigationOptions) {
                localStorage.setItem("investigationOptions", JSON.stringify(response.InvestigationOptions));
              }
              if (response.LeakSurveyOptions) {
                localStorage.setItem("leakSurveyOptions", JSON.stringify(response.LeakSurveyOptions));
              }
            }
            this.setState({
              token: this.state.tokenResponseText,
              responseText: this.state.tokenResponseText,
              clearBtnVisible: true,
              isAuthenticated: true,
              setAuth: true
            });
            this.props.swap();
          });
        }
        else {
          this.setState({
            token: this.state.tokenResponseText,
            responseText: this.state.tokenResponseText,
            clearBtnVisible: true,
            isAuthenticated: true,
            setAuth: true
          });
          this.props.swap();
        }
      });
  }

  render() {
    if (this.state.setAuth == true) {
      return <Redirect to="/reporting" />;
    } else {
      return (
        <div style={{ width: '100%', display: 'flex', flexDirection:'column', alignContent:'center', alignItems: 'center'}}>
          <PasswordModal />
          <div className="loginColumn" style={{ width: this.state.width < 390 ? this.state.width - 20 : 390 }}>
            {!this.state.showTwoFactor ? <div>
              <div className="row ">
                <div className="col-12 d-flex justify-content-center mt-5 loginTitle">
                  <label className="mainHeader">{this.state.appName}</label>
                  <label className="subHeader">SIGN IN</label>
                </div>
              </div>
              <div className="row">
                <div className="col-12 loginDialogRow1">
                  <Form id="loginDialog" onSubmit={this.GetToken} style={{ backgroundColor: "argb(239, 239, 240, 0.5)" }}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        name="username"
                        type="text"
                        placeholder="Username"
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        name="password"
                        type="password"
                        placeholder="Password"
                      />
                    </Form.Group>
                    <Button id="loginDialogButton" variant="primary" type="submit" width="100%" style={{ backgroundColor: "#095399", display: "none" }}>
                      Submit
                    </Button>
                    <div className="text-right underlineHover labelSubHeader2" onClick={() => { document.getElementById("forgotPassword").style.display = "block" }}>Forgot password?</div>
                  </Form>
                </div>
              </div>

              <div className="row  d-flex align-items-center justify-content-center">
                <div onClick={() => { document.getElementById("loginDialogButton").click(); }}
                  className="col-12 d-flex align-items-center  justify-content-center buttonAction buttonLogin btn btn-primary">
                  <label className="buttonText large">SIGN IN</label>
                </div>
              </div>

              <div className="row  d-flex align-items-center justify-content-center" style={{ marginTop: 20, marginBottom: 20 }}>
                <div className="col-12 d-flex align-items-center justify-content-center authSpinnerDivClean">
                  {this.state.token}
                  <div className="barLoaderPortal">
                    <BarLoader
                      sizeUnit={"px"}
                      size={1}
                      color={"#095399"}
                      loading={this.state.token == "Authenticating..." ? true : false}
                    /></div>
                </div>
              </div>
            </div> :
              <div>
                <div className="row ">
                  <div className="col-12 d-flex justify-content-center mt-5 loginTitle">
                    <label className="mainHeader" style={{ textAlign: 'center', fontSize: '2.4rem' }}>Two-Factor Authentication</label>
                    <label className="subHeader" style={{ textAlign: 'center', fontSize: 16 }}>ENTER CODE SENT TO YOUR MOBILE DEVICE</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 loginDialogRow1" style={{ height: 100 }}>
                    <input type="number" className="form-control" value={this.state.twoFactorCode} onChange={(e) => { this.setState({ twoFactorCode: e.target.value }) }} style={{}} />
                    <div style={{ marginBottom: 0, position: 'relative', top: 15, zIndex: 999999, width: 90, left: 255 }} className="text-right underlineHover labelSubHeader2" onClick={() => { this.resendCode() }}>Resend Code</div>
                    <div style={{ position: 'relative', bottom: 15 }}>
                      <input style={{ width: "6%", position: 'relative', left: 5 }} type="checkbox" checked={this.state.rememberDevice ? 'checked' : ''} value={this.state.rememberDevice} className="form-control xsmall" onChange={(e) => { this.updateRememberDevice(e.target.checked) }} />
                      <label style={{ position: 'relative', bottom: 30, left: 35 }} className="labelSubHeader2">Remember this device</label>
                    </div>
                  </div>
                </div>

                <div className="row  d-flex align-items-center justify-content-center">
                  <div onClick={() => { this.checkTwoFactor(); }}
                    className="col-12 d-flex align-items-center  justify-content-center buttonAction buttonLogin btn btn-primary">
                    <label className="buttonText large">Submit Code</label>
                  </div>
                </div>

                <div className="row  d-flex align-items-center justify-content-center" style={{ marginTop: 20, marginBottom: 20 }}>
                  <div className="col-12 d-flex align-items-center justify-content-center authSpinnerDivClean">
                    {this.state.twoFactorMessage}
                    <div className="barLoaderPortal">
                      <BarLoader
                        sizeUnit={"px"}
                        size={1}
                        color={"#095399"}
                        loading={(this.state.twoFactorMessage == "Checking code..." || this.state.twoFactorMessage == "Resending code...")}
                      /></div>
                  </div>
                </div>
              </div>}
          </div>
        </div>
      );
    }
  }
}

LoginPage.propTypes = {
  swap: PropTypes.func
};

export default LoginPage;