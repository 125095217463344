import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import moment from 'moment';
import PropTypes from "prop-types";

highchartsMore(Highcharts);

const CountByDate = (props) =>{
  const [series, setSeries] = React.useState([]);
  const [chart, setChart] = React.useState(null);
  const [categories, setCategories] = React.useState([]);

  const chartCallback = c => setChart(c);

  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  React.useEffect(() =>{
    if(props.data){
      let series = [];
      let range = getDateRange(props.dateFrom, props.dateTo);
      props.data.forEach(item =>{
        let dateValue = item.CreatedDate;
        let compare = moment(dateValue).format("MM/DD/YYYY");
        let formattedEventDate = moment(dateValue).format("YYYY-MM-DD");
        if(dateValue){
          let foundIndex = series.findIndex(i => moment(i[0]).format("MM/DD/YYYY") === compare);
          if(foundIndex >= 0){
            let origVal = series[foundIndex][1] || 0;
            series[foundIndex] = [formattedEventDate, origVal + 1]
          }else{ series.push([formattedEventDate, 1])}
        }
      });
      range.forEach(date =>{
        let formattedDate = moment(date).format("MM/DD/YYYY");
        if(series.findIndex(item => moment(item[0]).format("MM/DD/YYYY") === formattedDate) === -1){
          series.push([date,0]);
        }
      });
      series.sort((a, b) => moment(a[0]).valueOf() - moment(b[0]).valueOf());
      setCategories(range);
      setSeries(series);

      console.log('series', series);
    }
  }, [props.data])

  const getDateRange = (startDate, stopDate) => {
    let dateArray = [];
    let fromDate = moment(startDate).toDate();
    let toDate = moment(stopDate).toDate();
    while(fromDate <= toDate) {
      dateArray.push(moment(fromDate).format("YYYY-MM-DD"))
      fromDate = moment(fromDate).add(1, 'days');
    }
    return dateArray;
  }

  return(
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark"}}
      highcharts={Highcharts}
      allowChartUpdate={true}
      callback={chartCallback}
      constructorType="chart"
      options={{
        chart : { type: "area", backgroundColor : "#E9E9EF"},
        title: { text: "Surveys By Date"},
        plotOptions:{
          series: {
            stacking: "normal",
            marker: {
              enabled: false,
              symbol : "circle",
              radius: 2, 
              states :{
                hover: {
                  enabled: true
                }
              }
            },
            point: {
              events: {
                click : function(event){
                  let formattedDate = moment(event.point.category).format("MM/DD/YYYY");
                  props.filterCallback({ FormattedCreatedDate : formattedDate}, `Showing Surveys completed on ${formattedDate}`);
                }
              }
            }
          }
        },
        xAxis: { categories: categories || []},
        yAxis: { title: { text : "Surveys "}},
        tooltip: { shared: true },
        series:[
          {
            name: "Surveys",
            type: "area",
            data: series
          }
        ],
        credits: {enabled: false},
        legend: { enabled: true}
      }}
    />
  );
}

CountByDate.propTypes = {
  refreshKey : PropTypes.number,
  dateFrom : PropTypes.string,
  dateTo : PropTypes.string,
  data : PropTypes.array,
  filterCallback : PropTypes.func
}

export default CountByDate;