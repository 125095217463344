import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import moment from 'moment';
import PropTypes from "prop-types";

highchartsMore(Highcharts);

const CountByTicketType = (props) =>{
  const [chart, setChart] = React.useState(null);
  const [sComplete, setData] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  
  const chartCallback = c => setChart(c);
  
  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])
  React.useEffect(() => {
   
    console.time('CountByTicketType');
    let sComplete = [];
    
    if (props.data){
  
      let range = getDateRange(props.dateFrom, props.dateTo);
      setCategories(range);
      let offset = (new Date().getTimezoneOffset()) *60*1000;
      props.data.forEach(item =>{
        //let formTypeId = -1;
        let dateValue = null;
        let ticketType = -1;
        let dateIndex = -1;
    
        dateValue = props.useModified ? item.ModifiedDate : item.CreatedDate;
        //formTypeId = item.FormTypeId;
        
        try{
          if(item.TicketDisplayValues && item.TicketDisplayValues != ''){
            let split = item.TicketDisplayValues.split("|");
            ticketType = split[2];
          }
          //if (props.formData != undefined) formId = props.formData.find(function (element) {return element.FormId == item.FormId;}).Name;
        }
        catch(eat){
          //eat
        }

        
        if (ticketType != -1){
            
          // DETERMINE DATE INDEX
          if(typeof(dateValue) == "string"){
            dateValue = new Date(dateValue);
          }
          let dateFormatted = new Date(dateValue.getTime()-offset).toISOString().split('T')[0];
          //dateIndex = range.findIndex(i => i === dateFormatted);
          dateIndex = range.indexOf(dateFormatted);

          // SERIES BY DATE AND GROUP
          let formIndex = sComplete.findIndex(i => i.name === ticketType);
          if (formIndex >= 0)
          {
            sComplete[formIndex].data[dateIndex] = sComplete[formIndex].data[dateIndex] + 1;
          }
          else
          {
            let dataArray = new Array(range.length).fill(0);
            
            sComplete.push({name: ticketType, data: dataArray, color : ticketType == "Emergency" ? "#fc7b03" : ticketType == "Normal" ?  "#7cb5ec" : "#434348"});
            formIndex = sComplete.findIndex(i => i.name === ticketType);
            sComplete[formIndex].data[dateIndex] = 1;
          }
        }
  
      });
      setData(sComplete);
    }

    console.timeEnd('CountByTicketType');

  }, [props.data])
   const getDateRange = (startDate, stopDate) => {
    let dateArray = [];
    let fromDate = moment(startDate).toDate();
    let toDate = moment(stopDate).toDate();
    while(fromDate <= toDate) {
      dateArray.push(moment(fromDate).format("YYYY-MM-DD"))
      fromDate = moment(fromDate).add(1, 'days');
    }
    return dateArray;
  }
  return(
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark"}}
      highcharts={Highcharts}
      allowChartUpdate={true}
      callback={chartCallback}
      constructorType="chart"
      options={{
        chart : { type: "column", backgroundColor : "#E1E9EF"},
        title: { text: "Ticket Type"},
        plotOptions:{
          column:{
            stacking : 'normal',
            dataLabels: {
              enabled: true,
            },
            point: {
              events: {
                click : function(event){
                  let formattedDate = moment(event.point.category).format("MM/DD/YYYY");
                  props.filterCallback({ 
                    filterType: 'ByDateTicket',
                    filterValue: event.point.category,
                    filterSubType : "type",
                    filterTicketType : event.point.color == "#fc7b03" ? "Emergency" : event.point.color == "#7cb5ec" ? "Normal" : "Short"
                  },
                  formattedDate //+ " - " + (event.point.color == "#fc7b03" ? "Emergency" : event.point.color == "#7cb5ec" ? "Normal" : "Short")
                )
                }
              }
            },
          }
    },
        xAxis: { categories: categories || []},
        yAxis: { title: { text : "Ticket Types by Date"}},
        tooltip: { shared: true },
        series: sComplete,
        credits: {enabled: false},
        legend: {enabled: true}
      }}
    />
  );
}

CountByTicketType.propTypes = {
  refreshKey : PropTypes.number,
  dateFrom : PropTypes.string,
  dateTo : PropTypes.string,
  data : PropTypes.array,
  formData : PropTypes.array,
  filterCallback : PropTypes.func,
  useModified: PropTypes.bool
}
  
export default CountByTicketType;