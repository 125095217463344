import React from 'react';
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import AddBoxSharpIcon from '@material-ui/icons/LibraryAddTwoTone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText'
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';

class EditChart extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      formChartCollection: [
        {
          DeletedDate: null,
          Enabled: true
        },
        {
          DeletedDate: null,
          Enabled: true
        }
      ],
      form:{Fields:[{}]},
      formCollection:[],
      deleteWarning: false
    }
  }

  componentDidMount(){
    this.setState({formCollection: this.props.formCollection});
  }

  componentDidUpdate(){
    console.log('this.props.formCollection', this.props.formCollection);
  }

  addChart = () => {
    let formChartCollection = this.state.formChartCollection;
    formChartCollection.push({Name:"New Form", FormId:0, Fields:[], FormChartCollection:[], Color : '#095399'});
    this.setState({formChartCollection});
  }

  deleteWarning(f_index, t_index){
    // if(auth.isSuperViewer()){
    //   return;
    // }
    this.setState({currentFormIndex: f_index, currentTriggerIndex: t_index, deleteWarning: true});
  }

  deleteChart(){
    
    // TODO: Add Auth
    // if(auth.isSuperViewer()){
    //   return;
    // }
    
    // TODO: Delete Chart
    // let FormCollection = this.state.FormCollection;
    // FormCollection[this.state.currentFormIndex].FormChartCollection[this.state.currentTriggerIndex].DeletedDate = new Date();
    // FormCollection[this.state.currentFormIndex].FormChartCollection[this.state.currentTriggerIndex].DeletedBy = auth.userId();
    // this.setState({FormCollection, deleteTriggerWarning:false});
    // this.saveTriggers(true, false, this.state.currentFormIndex, this.state.currentTriggerIndex);
  }

  updateTriggerFieldFrom(e, form, f_index, t_index){
    let formCollection = this.state.formCollection;
    formCollection[f_index].unsavedTriggers = true;
    formCollection[f_index].FormTriggerCollection[t_index].TriggerFieldFromName = form.Fields[e.target.value].Name;
    formCollection[f_index].FormTriggerCollection[t_index].TriggerFieldFromNameIndex = e.target.value;
    formCollection[f_index].FormTriggerCollection[t_index].TriggerField = form.Fields[e.target.value];
    this.setState({formCollection});
  }


  render(){
    const { formChartCollection, f_index } = this.state;
    return(
      <div className="col-10 d-flex flex-column justify-content-center p-0 m-0" style={{ backgroundColor: "#E9E9EF" }}>
        {formChartCollection && formChartCollection.map((chart, c_index) => (                         
        !chart.DeletedDate &&
        <div key={"triggerCollection"+c_index} className='accountFormTriggerGroupTriggers'>
          
          <div className="formTriggerGroupTriggerValues">
            
            <label htmlFor={"formChartFieldFromLabel" + c_index} className="accountHeader chartLabel">Chart:</label>
            <select id={"formChartFieldFromValue" + c_index} className = "form-control notificationRowControl trigger" 
                value = {chart.TriggerFieldFromValueUpdateIndex}
                //onChange ={ e => { this.updateTriggerFieldValueEventAction(e, form, f_index, c_index) }} 
                >
                <option value='countByValue'>Count by Form</option>
                <option value='countByValue'>Count by Field Value</option>
                <option value='formByDate'>Form by Date</option>
                <option value='countByUser'>Count by User</option>
                <option value='valueByDate'>Value by Date</option>
                <option value='pieByField'>Pie Chart by Field</option>
            </select>

            {/* <label htmlFor={"formChartFieldFromLabel" + c_index} className="accountHeader chartLabel">Data:</label>
            <select id={"formChartFieldFromValue" + c_index} className = "form-control notificationRowControl trigger" 
                value = {chart.TriggerFieldFromValueUpdateIndex}
                //onChange ={ e => { this.updateTriggerFieldValueEventAction(e, form, f_index, c_index) }} 
                >
                <option value='countByValue' selected="selected">All Forms</option>
                <option value='formByDate'>Survey</option>
                <option value='countByUser'>Investigation</option>
                <option value='valueByDate'>Pipeline Survey</option>
                <option value='pieByField'>GPS Survey</option>
            </select> */}

            <label htmlFor={"formChartFieldFromLabel" + c_index} className="accountHeader chartLabel">Form:</label>
            <select id={"formChartFieldFromValue" + c_index} className = "form-control notificationRowControl trigger" 
                value = {chart.TriggerFieldFromValueUpdateIndex}
                //</div>onChange ={ e => { this.updateTriggerFieldValueEventAction(e, form, f_index, c_index) }} 
                >

                {this.state.formCollection && this.state.formCollection.length > 0 && this.state.formCollection.map((form, formIndex) => (
                  <option key={'form'+formIndex} value={form.formId}>{form.Name}</option>
                ))}

            </select>

            <label htmlFor={"formChartFieldFromLabel" + c_index} className="accountHeader chartLabel">Field:</label>
            <select id={"formChartFieldFromValue" + c_index} className = "form-control notificationRowControl trigger" 
                value = {chart.TriggerFieldFromValueUpdateIndex}
                //</div>onChange ={ e => { this.updateTriggerFieldFrom(e, form, f_index, t_index) }} 
                >
                <option></option>
                {this.state.formCollection && this.state.formCollection.length > 0 && this.state.formCollection[11].Fields.map((form, formIndex) => (
                  <option key={'form'+formIndex} value={formIndex}>{form.Name}</option>
                ))}
                {/* {form.Fields.map((field, field_index) => (
                  <option key={'triggerFieldOption'+field_index} value={field_index}>{field.Name}</option>
                ))} */}
            </select>

            <label htmlFor={"formChartFieldFromLabel" + c_index} className="accountHeader chartLabel">Value:</label>
            <select id={"formChartFieldFromValue" + c_index} className = "form-control notificationRowControl trigger" 
                value = {chart.TriggerFieldFromValueUpdateIndex}
                //onChange ={ e => { this.updateTriggerFieldValueEventAction(e, form, f_index, c_index) }} 
                >
                <option></option>
                {this.state.formCollection && this.state.formCollection.length > 0 && this.state.formCollection[11].Fields[8].CustomValues.map((form, formIndex) => (
                  <option key={'form'+formIndex} value={formIndex}>{form}</option>
                ))}
            </select>

          </div>

          <div className="formChartGroupTriggerControls">

            {/* !chart.Enabled &&
            <Button onClick={()=>{this.enableTrigger(chart, f_index, c_index);}} className="buttonAction buttonFaded formsElementsmall xsmall">
              <label style ={{ margin: 0, cursor: 'pointer'}} className ="buttonText withIcon xsmall ">Off</label>
            </Button> */}
          
            <Button onClick={()=>{this.deleteWarning(f_index, c_index)}} title="Remove Trigger" 
              className="buttonAction buttonAccount formsElementsmall xsmall square">
              <HighlightOffTwoToneIcon className="buttonIcon noLabel"/>
            </Button>
          </div>
        </div>
        ))}

        <div className="row" style={{ marginTop: 10 }}>
          <div className="justify-content-left">
            <Button onClick={this.addChart} className="buttonAction buttonAccount small confirm btn btn-primary">
              <AddBoxSharpIcon className="buttonIcon light" />
              <label style={{margin:0, cursor: 'pointer'}} className="buttonText withIcon">ADD CHART</label>
            </Button>
          </div>
        </div>

        <Dialog open={this.state.deleteWarning} onClose={()=>{}}>
          <DialogTitle>{"Are you sure you want to delete this chart?"}</DialogTitle>
          <DialogActions>
            <Button className="buttonAction btn btn-primary" onClick={()=>{this.setState({deleteWarning:false})}} color="primary">
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Cancel</label>
            </Button>
            <Button className="buttonAction btn btn-primary" onClick={() =>{this.deleteChart();}} color="primary" autoFocus>
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Delete</label>
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    )
  }
}

EditChart.propTypes = {
  save: PropTypes.func,
  formCollection : PropTypes.object,
}

export default EditChart;