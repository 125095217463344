import React from "react";
import { Button } from 'react-bootstrap';
import { BarLoader } from "react-spinners";
import auth from "../common/Auth";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router';
import OrgUtility from "../../Utility/Utility";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

class EditPassword extends React.Component {
  constructor(props){
    super(props);
    this.state={
      currentPassword:"",
      newPassword : "",
      confirmNewPassword : "",
      showLoadingBar : false,
      updateStatusText : "",
      alertSeverity : "success",
      showPasswords : false,
      confirmPasswordMatch : false
    }
    this.editCurrentPassword = this.editCurrentPassword.bind(this);
    this.editNewPassword = this.editNewPassword.bind(this);
    this.editConfirmNewPassword = this.editConfirmNewPassword.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.isNullOrEmpty = this.isNullOrEmpty.bind(this);
  }

  componentDidMount(){
    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }
  
  componentDidUpdate(){ 
    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  editCurrentPassword(currentPassword){
    this.setState({currentPassword});
  }

  editNewPassword(text){
    let has8Chars = text.length > 8;
    let hasUpperCase = /[A-Z]/.test(text);
    let hasLowerCase = /[a-z]/.test(text);
    let hasDigit = /[0-9]/.test(text);
    let hasNonAlphaNumeric = !/^[a-z0-9]+$/i.test(text);
    let confirmPasswordMatch = text == this.state.confirmNewPassword;
    this.setState({newPassword: text,has8Chars,hasUpperCase,hasLowerCase,hasDigit,hasNonAlphaNumeric,confirmPasswordMatch});
  }

  editConfirmNewPassword(text){
    let confirmPasswordMatch = text == this.state.newPassword;
    this.setState({confirmNewPassword : text, confirmPasswordMatch});
  }

  isNullOrEmpty(str){
    return str === null || str.match(/^ *$/) !== null;
  }

  updatePassword(){
    let has8Chars = this.state.newPassword.length > 8;
    let hasUpperCase = /[A-Z]/.test(this.state.newPassword);
    let hasLowerCase = /[a-z]/.test(this.state.newPassword);
    let hasDigit = /[0-9]/.test(this.state.newPassword);
    let hasNonAlphaNumeric = !/^[a-z0-9]+$/i.test(this.state.newPassword);
    if(this.isNullOrEmpty(this.state.currentPassword) || this.isNullOrEmpty(this.state.newPassword)
      || this.isNullOrEmpty(this.state.confirmNewPassword)){
      let errorText = 'Fill in required field(s): ';
      if(this.isNullOrEmpty(this.state.currentPassword)){
        errorText += 'Current Password, '
      }
      if(this.isNullOrEmpty(this.state.newPassword)){
        errorText += 'New Password, '
      }
      if(this.isNullOrEmpty(this.state.confirmNewPassword)){
        errorText += 'Confirm New Password, '
      }
      errorText = errorText.slice(0,-2);
      errorText += '.';
      this.setState({ updateStatusText : errorText, alertSeverity : 'error'});
    }
    else if(this.state.newPassword != this.state.confirmNewPassword){
      this.setState({updateStatusText : "Confirm new password, they do not match.", alertSeverity : 'error'});
    }
    else if(!has8Chars || !hasUpperCase || !hasLowerCase || !hasDigit || !hasNonAlphaNumeric){
      let errorText = "Password does not meet requirements: ";
      if(!has8Chars){
        errorText += "Must have at least eight Characters. ";
      }
      if(!hasUpperCase){
        errorText += "Must have at least one Upper case character. ";
      }
      if(!hasLowerCase){
        errorText += "Must have at least one Lower case character. ";
      }
      if(!hasDigit){
        errorText += "Must have at least one number. ";
      }
      if(!hasNonAlphaNumeric){
        errorText += "Must have at least one special character.";
      }
      this.setState({updateStatusText : errorText,alertSeverity : "error"});
    }
    else{
      this.setState({ showLoadingBar : true});
      let date = new Date();
      let request = {
        Email : auth.userName(),
        currentPassword : this.state.currentPassword.trim(),
        NewPassword : this.state.newPassword.trim(),
        TimeStamp : date,
        UTCOffset : - (date.getTimezoneOffset()/60)
      }

      fetch(process.env.API_URL + "/api/account/updatePass",{
        method: "POST",
        headers: {
          "Content-Type" : "application/json",
          Authorization : "Bearer " + auth.token()
        },
        body: JSON.stringify(request)
      })
      .then(response =>{
        this.setState({showLoadingBar : false});
        if(response.ok){
          response.json().then(re =>{
            let json = JSON.parse(re);
            if(json.ResponseType == 0){
              this.setState({updateStatusText : "Success. Password has been changed.", alertSeverity : 'success', currentPassword :  "", newPassword : "", confirmNewPassword : ""});
            }
            else{
              this.setState({updateStatusText : "There was a problem changing your password. " + (json.ResponseMessageCollection.length >0 ? json.ResponseMessageCollection[0].Message : ''), alertSeverity : 'error'});
            }
          });
        }
        else{
          this.setState({updateStatusText : "There was an error changing your password." , alertSeverity : 'error'});
        }
      }).catch(error =>{
        this.setState({showLoadingBar : false,updateStatusText : "There was an error changing your password." + error.innerMessage, alertSeverity : 'error'});
      })
    }
  }

  handleClose(){
    this.setState({updateStatusText : ""});
  }

  render(){
    return(
      <div id='supportDetailContentPanel' className="col-10 d-flex justify-content-center p-0 m-0" style={{ backgroundColor: "#E9E9EF" }}>
        {!this.state.showLoadingBar ? 
          <div className="container">
            <div className="divCenter">
              <div className="labelHeaderBar labelHeaderBarTight" style={{marginTop:40, padding:10}}>Change Password</div>
              <div className="licenseEditDivContent">
                <div className="row form">
                  <label style={{position : 'relative', right : 14}} className="labelDetailName">Current Password </label> <input style={{position : 'relative', bottom : 10, right : 14}} type={this.state.showPasswords ? "text" : "password"} className="form-control entity-edit-text" value={this.state.currentPassword} onChange={(e) => {this.editCurrentPassword(e.target.value)}}/>
                </div>
                <div className="row form">
                  <label className="labelDetailName">New Password </label>
                  <input style={{position : 'relative', bottom : 11}} type={this.state.showPasswords ? "text" : "password"} className="form-control entity-edit-text" value={this.state.newPassword} onChange={(e) => {this.editNewPassword(e.target.value)}}/>
                  {(this.state.has8Chars && this.state.hasDigit && this.state.hasLowerCase && this.state.hasUpperCase && this.state.hasNonAlphaNumeric) && <CheckCircleIcon style={{fontSize: 24,color: "green"}}/>}
                  {(!this.state.has8Chars || !this.state.hasDigit || !this.state.hasLowerCase || !this.state.hasUpperCase || !this.state.hasNonAlphaNumeric) && <HighlightOffIcon style={{fontSize: 24,color: "red"}}/>}
                </div>
                <div className="row form">
                  <label className="labelDetailName">Confirm New Password </label>
                  <input style={{position : 'relative', bottom : 11}} type={this.state.showPasswords ? "text" : "password"} className="form-control entity-edit-text" value={this.state.confirmNewPassword} onChange={(e) => {this.editConfirmNewPassword(e.target.value)}}/>
                  {this.state.confirmPasswordMatch && <CheckCircleIcon style={{fontSize: 24,color: "green"}}/>}
                  {!this.state.confirmPasswordMatch && <HighlightOffIcon style={{fontSize: 24,color: "red"}}/>}
                </div>
                <div className="row">
                Password must have at least eight characters, at least one number, at least one lower case character, at least one upper case character, and at lease one special character.
                </div>
                <div className="row form">
                  <label className="showPasswords" style={{marginLeft: 312}} onClick={() =>{this.setState({showPasswords : !this.state.showPasswords})}}>{this.state.showPasswords ? "HIDE" : "SHOW"} PASSWORDS</label>
                </div>
                <div className="row form" style={{marginTop:20}}>
                  <Button className ="buttonAction buttonAccount" onClick={this.updatePassword}>
                    <label style={{ margin: 0, cursor: 'pointer'}} className='buttonText'>Change Password</label>
                  </Button>
                </div>
              </div>
            </div>
            {this.state.updateStatusText != "" &&
          <Snackbar open={this.state.updateStatusText != ""} onClick={() => {this.setState({updateStatusText:''})}} autoHideDuration={16000} onClose={this.handleClose} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={this.handleClose} severity={this.state.alertSeverity} variant="filled">
            {this.state.updateStatusText}
            </Alert>
          </Snackbar>}
        </div>
        :
          <div className="container-fluid">
            <div className="spinnerDivUserPage">
              <div className="text-center authSpinnerDiv">
                <div className="authSpinnerDivMessage">Updating Password...</div>
                <div className="barLoaderPortal">
                  <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading={ this.state.showLoadingBar} />
                </div>
              </div>
            </div>
          </div>}
      </div>
    )
  }
}

EditPassword.propTypes = {
  
}

export default withRouter(EditPassword);