/* eslint react/prop-types: 0 */
import React from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';
import PropTypes from "prop-types";
import HighchartsReact from 'highcharts-react-official';

const SurveysPerDay = (props) => {
  const [chart, setChart] = React.useState(null);
  const [graphData, setGraphData] = React.useState([]);
  const [tooltipHelper, setTooltipHelper] = React.useState({});
  const [users, setUsers] = React.useState({});

  const chartCallback = chart => {setChart(chart);}

  const setupSurveysPerDay = () => {
    // console.group("Surveys Per Day");
    // console.log("Initial Data : ", props.data);
    let data = props.data;
    let users = {};
    //eslint-disable-next-line
    let final = {};
    let surveysByDay = {};
    let dates = props.categories;
    // Goes data and creates a user key value list
    // and sets up for final graph data creation
    data.forEach(s => {
      if(s.ModifiedBy && s.Status && s.CreatedBy){
        let formatted = moment(s.CreatedDate).format('YYYY-MM-DD');
        users[s.ModifiedBy] = s.ModifiedByDisplayName;
        if(
          surveysByDay[s.ModifiedBy] 
          && surveysByDay[s.ModifiedBy]
          && surveysByDay[s.ModifiedBy][formatted] 
          && surveysByDay[s.ModifiedBy][formatted][s.Status]
        ){
          surveysByDay[s.ModifiedBy][formatted][s.Status] += 1;
          typeof surveysByDay[s.ModifiedBy][formatted]["total"] === "number"
            ? surveysByDay[s.ModifiedBy][formatted]["total"]+= 1
            : surveysByDay[s.ModifiedBy][formatted]["total"] = 1 
        }else{
          if(!surveysByDay[s.ModifiedBy]){ 
            surveysByDay[s.ModifiedBy] = {};
          }
          if(!surveysByDay[s.ModifiedBy][formatted]){
            surveysByDay[s.ModifiedBy][formatted] = {};
          }
          surveysByDay[s.ModifiedBy][formatted][s.Status] = 1;
          typeof surveysByDay[s.ModifiedBy][formatted]["total"] === "number"
            ? surveysByDay[s.ModifiedBy][formatted]["total"]+= 1
            : surveysByDay[s.ModifiedBy][formatted]["total"] = 1 
        }
      }
    });
    // console.log("Surveys By Day : ", surveysByDay);
    let finalSeries = [];
    Object.keys(surveysByDay).forEach(userid => {
      let dateSeries = [];
      dates.forEach(date => {
        if(surveysByDay[userid][date]){ // if a value exists for that date on surveys by day
          dateSeries.push({ name: userid, y: surveysByDay[userid][date].total })
        }else{ // Date is not present on users surveys by day field, place default value into array
          dateSeries.push({ name: userid, y: 0 });
        }
      });
      finalSeries.push({ name: users[userid], data: dateSeries });
    })
    // console.log("Surveys By Day FinalSeries : ", finalSeries);
    setGraphData(finalSeries);
    setTooltipHelper(surveysByDay);
    setUsers(users);
  }

  const tooltipFormatter = (userid, formattedDate) => {
    // console.log("Tooltip Helper : ", tooltipHelper);
    // console.log("Userid : ", userid);
    // console.log("formatted date : ", formattedDate)
    let counts = tooltipHelper[userid][formattedDate];
    let tt = <div className="d-flex flex-column align-items-center justify-content-center">
      <span>{users[userid]}</span>
      <span>{formattedDate}</span>
      {
        counts && 
        Object.keys(counts).map(key => key !== "total" ? <span key={key}>{key} : {counts[key]}</span> : null)
      }
    </div>
    return ReactDOMServer.renderToStaticMarkup(tt)
  }

  React.useEffect(() => {
    setupSurveysPerDay();
  }, [props.data])

  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  return(
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark"}}
      highcharts={Highcharts}
      options={
        {
          title : {
            text: props.graphTitle || ""
          },
          xAxis: {
            categories: props.categories || [],
            crosshair: true
          },
          plotOptions: {
            series: {
              cursor: "pointer",
              point: {
                events: {
                  click: function(event){
                    props.filterCallback(
                      {
                        CreatedDate: event.point.category, 
                        ModifiedBy: event.point.name, 
                      }, 
                      `Showing ${users[event.point.name]}'s Surveys created on ${event.point.category}.`
                    )
                  }
                }
              }
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: "Surveys"
            }
          },
          // colors: ["#16558F", "#2D7952", "#DE8C26"],
          chart: {
            type: 'column',
            backgroundColor: "#E9E9EF",
          },        
          series : graphData,
          tooltip: {
            useHTML: true,
            formatter: function(){
              // this.point.category = formattedDate (modifiedDate)
              // this.point.name = ModifiedBy (userid value)
              return tooltipFormatter(this.point.name, this.point.category)
            },
            outside: true,
          },
          credits : {
            enabled: false
          },
          legend : {
            enabled: false
          }
      } }
      allowChartUpdate
      callback={chartCallback}
      constructorType="chart"
    />
  );
}

SurveysPerDay.propTypes = {
  data : PropTypes.array,
  categories : PropTypes.array,
  graphTitle : PropTypes.string,
}

export default SurveysPerDay;