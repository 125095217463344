import React from 'react';
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import QRCode from 'qrcode.react';

class AddEditUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {hadSiteSafeToStart : false};
  }

  downloadQR() {
    document.getElementById("downloader").download = this.props.FirstName + this.props.LastName + "_qrCode.png";
    document.getElementById("downloader").href = document.getElementById("qr").toDataURL("image/png").replace(/^data:image\/[^;]/, 'data:application/octet-stream');
  }

  componentDidMount(){
    //console.log("License Collection AddEditUser : ", this.props.licenseCollection);
    this.setState({ hadSiteSafeToStart :  this.props.userId && this.props.userLicenseCollection.find(x => x.LicenseId === 'sitesafe')});
  }

  handleDisable = e => {
    e.preventDefault();
    this.props.disableUser(!this.props.selectedInactive);
  }

  render() {
    
    return <div id="addedituser-container" className="addedituser-container">
       <div className="w-100 d-flex labelHeaderBar saveBarTop">
        <div style={{overflow:'hidden'}}><label className="labelHeader dehighlighted">Editing User</label><label className="labelHeader">{this.props.DisplayName}</label></div>
        <div className="addedituserButtonBar">
          <Button onClick={this.props.saveUser}
            className="buttonAction buttonIcon confirm">
            <label style={{ margin: 0, cursor: "pointer" }} className="buttonText">SAVE</label>
          </Button>

          <Button onClick={this.props.cancelSave}
            className="buttonAction buttonIcon">
            <label style={{ margin: 0, cursor: "pointer" }} className="buttonText">CANCEL</label>
          </Button>

          <Button onClick={this.handleDisable}
            className="buttonAction buttonIcon buttonFaded">
            <label style={{ margin: 0, cursor: "pointer" }} className="buttonText">{this.props.selectedInactive ? "Enable User" : "Disable User"}</label>
          </Button>

          <Button onClick={this.props.resetPassword}
            className="buttonAction buttonIcon buttonFaded">
            <label style={{ margin: 0, cursor: "pointer" }} className="buttonText">Reset Password</label>
          </Button>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row">
        <div className="col-12 col-md-6" style={{ marginTop: 0 }}>
          <div className="col-12">
            <div style={{ maxWidth: 975, margin: "auto" }}>

            <div className="labelHeaderBar labelHeaderBarTight" style={{ marginTop:30, padding:10 }}>User</div>
              <div className="licenseEditDivContent">
                <div className="row">
                  <div className="col-6" style={{ color: this.props.NameColor, fontWeight: 'bold', marginTop: 10 }}>
                    FIRST NAME
                  </div>
                </div>

                <div className="row">
                  <div className="editUserInputContainer">
                    <input type="text" className="form-control userEditInput" value={this.props.FirstName} onChange={(e) => { this.props.EditFirstName(e.target.value) }} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-6" style={{ color: this.props.NameColor, fontWeight: 'bold', marginTop: 10 }}>
                    LAST NAME
            </div>
                </div>

                <div className="row">
                  <div className="editUserInputContainer">
                    <input type="text" className="form-control userEditInput" value={this.props.LastName} onChange={(e) => { this.props.EditLastName(e.target.value) }} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-6" style={{ color: this.props.EmailColor, fontWeight: 'bold', marginTop: 10 }}>
                    EMAIL
            </div>
                </div>

                <div className="row">
                  <div className="editUserInputContainer">
                    <input type="text" className="form-control userEditInput" value={this.props.Email} onChange={(e) => { this.props.EditEmail(e.target.value) }} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-6" style={{ color: this.props.PhoneColor, fontWeight: 'bold', marginTop: 10 }}>
                    PHONE NUMBER
                  </div>
                </div>

                
                <div className="row">
                  <div className="editUserInputContainer">
                    <input type="text" className="form-control userEditInput" value={this.props.PhoneNumber} onChange={(e) => { this.props.EditPhoneNumber(e.target.value) }} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-6" style={{ color: this.props.ExternalIdColor, fontWeight: 'bold', marginTop: 10 }}>
                    External Id
                  </div>
                </div>

                <div className="row">
                  <div className="editUserInputContainer">
                    <input type="text" className="form-control userEditInput" value={this.props.ExternalId} onChange={(e) => { this.props.EditExternalId(e.target.value) }} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-6" style={{ fontWeight: 'bold', marginTop: 10 }}>
                    ROLE
                  </div>
                </div>

                <div className="row">
                  <div className="editUserInputContainer">
                    <select value={this.props.Role} className="form-control userEditInput" onChange={(e) => { this.props.EditRole(e.target.value) }} >
                      <option value="User">User</option>
                      <option value="Admin">Admin</option>
                      <option value="Manager">Manager</option>
                      <option value="Viewer">Viewer</option>
                      <option value="SecuredViewer">Secured Viewer</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6" style={{ fontWeight: 'bold', marginTop: 10 }}>
                    MANAGER
                  </div>
                </div>

                <div className="row">
                  <div className="editUserInputContainer">
                    <select value={this.props.ManagerUserId} className="form-control userEditInput" onChange={(e) => { this.props.EditManagerUserId(e.target.value) }} >
                      <option value=""></option>
                      {this.props.UsersList && this.props.UsersList.map((user,index) => (!user.DeletedDate &&
                        <option key={index} value={user.UserId}>{user.DisplayNameProper}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6" style={{fontWeight : 'bold', marginTop: 10, marginBottom:10}}>
                    TWO FACTOR AUTHENTICATION
                  </div>
                </div>

                <div className="row">
                  <div className="editUserInputContainer">
                  <div className="licenseToggle">
                    <div className={this.props.twoFactorEnabled ? "buttonAction buttonLicense active" :"buttonAction buttonLicense"}  onClick={() => this.props.updateTwoFactor()} />
                    <label className="labelText" >{this.props.twoFactorEnabled ? "ENABLED" : "DISABLED"}</label>{this.props.twoFactorEnabled}
                  </div>
                  </div>
                </div>

                <div className="row">
                  <div className="" style={{ marginLeft: -14 }}>
                    <div className="descriptionBox">
                      <span><b>User</b> can use and access data through mobile app modules.</span>
                      <span><b>Viewer</b> can access data and maps, but not use Management tools.</span>
                      <span><b>Secured Viewer</b> Secured Viewer can access secured forms and assign as tasks</span>
                      <span><b>Manager</b> can access and use GPS Survey & GIS Survey Management tools.</span>
                      <span><b>Admin</b> can access Account Payment, Licensing, and all Management tools.</span></div>
                  </div>
                </div>

                {this.props.InvalidInput ? <div className="row">
                  <div className="col-12">
                    <div style={{ marginLeft: -14, paddingTop: 20 }}>
                      Fill out all the fields before saving
                    </div>
                  </div>
                </div> : null}
                {this.props.updateStatusText == "Error creating user: Email address already exists" && <div className="row">
                  <div className="col-12">
                    <div className="licenseDiv">
                      <div>
                        <label className="licenseLabel buttonText">
                          If you meant to create another user with this email, select this and press save again.
                        </label>
                      </div>
                      <div className="licenseToggle">
                        <div className="buttonAction buttonLicense" id="dupUser" onClick={() => this.props.duplicateUser()} />
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6" style={{ marginBottom: 40 }}>
        <div className="col-12">
          <div style={{ maxWidth: 975, margin: "auto" }}>
            <div className=" labelHeaderBar labelHeaderBarTight" style={{ padding: 10, marginTop: 30 }}>Licenses</div>
            <div className="licenseEditDivContent">

              {this.props.licenseCollection.find(x => x.LicenseId === 'site') && this.props.licenseCollection.find(x => x.LicenseId === 'site').Total > 0 && <div className="licenseDiv">
                <div>
                  <label className="licenseLabel buttonText">SITE SURVEY
                          <label className="labelText labelSmall2">{this.props.licenseCollection.find(x => x.LicenseId === 'site').Used + this.props.licenseCollection.find(x => x.LicenseId === 'site').Edit} of {this.props.licenseCollection.find(x => x.LicenseId === 'site').Total}</label>
                  </label>
                </div>
                <div className="licenseToggle">
                  <div className="buttonAction buttonLicense" id="licensesite" onClick={() => this.props.updateLicense('site')} />
                  <label className="labelText" id="licenseLabelsite">Not Licensed</label>
                </div>
              </div>}

              {/* {this.props.licenseCollection.find(x => x.LicenseId === 'site12') && this.props.licenseCollection.find(x => x.LicenseId === 'site12').Total > 0 && <div className="licenseDiv">
                <div>
                  <label className="licenseLabel buttonText">SITE SURVEY
                    <label className="labelText labelSmall2">{this.props.licenseCollection.find(x => x.LicenseId === 'site12').Used + this.props.licenseCollection.find(x => x.LicenseId === 'site12').Edit} of {this.props.licenseCollection.find(x => x.LicenseId === 'site12').Total}</label>
                  </label>
                </div>
                <div className="licenseToggle">
                  <div className="buttonAction buttonLicense" id="licensesite" onClick={() => this.props.updateLicense('site')} />
                  <label className="labelText" id="licenseLabelsite">Not Licensed</label>
                </div>
              </div>} */}

              {this.props.licenseCollection.find(x => x.LicenseId === 'investigation') && this.props.licenseCollection.find(x => x.LicenseId === 'investigation').Total > 0 && <div className="licenseDiv">
                <div>
                  <label className="licenseLabel buttonText">INCIDENT INVESTIGATION
                            <label className="labelText labelSmall2">{this.props.licenseCollection.find(x => x.LicenseId === 'investigation').Used + this.props.licenseCollection.find(x => x.LicenseId === 'investigation').Edit} of {this.props.licenseCollection.find(x => x.LicenseId === 'investigation').Total}</label>
                  </label>
                </div>
                <div className="licenseToggle">
                  <div className="buttonAction buttonLicense" id="licenseinvestigation" onClick={() => this.props.updateLicense('investigation')} />
                  <label className="labelText" id="licenseLabelinvestigation">Not Licensed</label>
                </div>
              </div>}

              {/* {this.props.licenseCollection.find(x => x.LicenseId === 'investigation22') && this.props.licenseCollection.find(x => x.LicenseId === 'investigation22').Total > 0 &&  <div className="licenseDiv">
                <div>
                  <label className="licenseLabel buttonText">INCIDENT INVESTIGATION
                            <label className="labelText labelSmall2">{this.props.licenseCollection.find(x => x.LicenseId === 'investigation22').Used + this.props.licenseCollection.find(x => x.LicenseId === 'investigation22').Edit} of {this.props.licenseCollection.find(x => x.LicenseId === 'investigation22').Total}</label>
                  </label>
                </div>
                <div className="licenseToggle">
                  <div className="buttonAction buttonLicense" id="licenseinvestigation" onClick={() => this.props.updateLicense('investigation')} />
                  <label className="labelText" id="licenseLabelinvestigation">Not Licensed</label>
                </div>
              </div>} */}

              {this.props.licenseCollection.find(x => x.LicenseId === 'gps') && this.props.licenseCollection.find(x => x.LicenseId === 'gps').Total > 0 && <div className="licenseDiv">
                <div>
                  <label className="licenseLabel buttonText">GPS SURVEY
                            <label className="labelText labelSmall2">{this.props.licenseCollection.find(x => x.LicenseId === 'gps').Used + this.props.licenseCollection.find(x => x.LicenseId === 'gps').Edit} of {this.props.licenseCollection.find(x => x.LicenseId === 'gps').Total}</label>
                  </label>
                </div>
                <div className="licenseToggle">
                  <div className="buttonAction buttonLicense" id="licensegps" onClick={() => this.props.updateLicense('gps')} />
                  <label className="labelText" id="licenseLabelgps">Not Licensed</label>
                </div>
              </div>}

              {this.props.licenseCollection.find(x => x.LicenseId === 'leak') && this.props.licenseCollection.find(x => x.LicenseId === 'leak').Total > 0 && <div className="licenseDiv">
                <div>
                  <label className="licenseLabel buttonText">GIS SURVEY:
                            <label className="labelText labelSmall2">{this.props.licenseCollection.find(x => x.LicenseId === 'leak').Used + this.props.licenseCollection.find(x => x.LicenseId === 'leak').Edit} of {this.props.licenseCollection.find(x => x.LicenseId === 'leak').Total}</label>
                  </label>
                </div>
                <div className="licenseToggle">
                  <div className="buttonAction buttonLicense" id="licenseleak" onClick={() => this.props.updateLicense('leak')} />
                  <label className="labelText" id="licenseLabelleak">Not Licensed</label>
                </div>
              </div>}

              {this.props.licenseCollection.find(x => x.LicenseId == 'sitesafe') && this.props.licenseCollection.find(x => x.LicenseId === 'sitesafe').Total > 0 &&  <div className="licenseDiv">
                <div>
                  <label className="licenseLabel buttonText">SITE SAFE:
                            <label className="labelText labelSmall2">{this.props.licenseCollection.find(x => x.LicenseId === 'sitesafe').Used + this.props.licenseCollection.find(x => x.LicenseId === 'sitesafe').Edit} of {this.props.licenseCollection.find(x => x.LicenseId === 'sitesafe').Total}</label>
                  </label>
                </div>
                <div className="licenseToggle">
                  <div className="buttonAction buttonLicense" id="licensesitesafe" onClick={() => this.props.updateLicense('sitesafe')} />
                  <label className="labelText" id="licenseLabelsitesafe">Not Licensed</label>
                </div>
              </div>}

              {this.props.licenseCollection.find(x => x.LicenseId == 'sitesafescan') && this.props.licenseCollection.find(x => x.LicenseId === 'sitesafescan').Total > 0 && <div className="licenseDiv">
                <div>
                  <label className="licenseLabel buttonText">SITE SAFE GUEST SCREENING:
                            <label className="labelText labelSmall2">{this.props.licenseCollection.find(x => x.LicenseId === 'sitesafescan').Used + this.props.licenseCollection.find(x => x.LicenseId === 'sitesafescan').Edit} of {this.props.licenseCollection.find(x => x.LicenseId === 'sitesafescan').Total}</label>
                  </label>
                </div>
                <div className="licenseToggle">
                  <div className="buttonAction buttonLicense" id="licensesitesafescan" onClick={() => this.props.updateLicense('sitesafescan')} />
                  <label className="labelText" id="licenseLabelsitesafescan">Not Licensed</label>
                </div>
              </div>}

              {this.props.InvalidInput ? <div className="row">
                <div className="col-12">
                  <div style={{ marginLeft: -14, paddingTop: 20 }}>
                    Fill out all the fields before saving
                  </div>
                </div>
              </div> : null}
              {this.props.updateStatusText == "Error creating user: Email address already exists" && <div className="row">
                <div className="col-12">
                  <div className="licenseDiv">
                    <div>
                      <label className="licenseLabel buttonText">
                        If you meant to create another user with this email, select this and press save again.
              </label>
                    </div>
                    <div className="licenseToggle">
                      <div className="buttonAction buttonLicense" id="dupUser" onClick={() => this.props.duplicateUser()} />
                    </div>
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
      </div>
      {
        ((this.props.trialMode && this.props.userId) || (this.state.hadSiteSafeToStart && document.getElementById("licensesitesafe") && document.getElementById("licensesitesafe").classList.contains('active'))) &&
        <div className="row" style={{ marginBottom: 40 }}>
          <div className="col-12">
            <div style={{marginLeft: 15, marginTop: 0}}>
              <div style={{ width: 325 }}>
                <div className=" labelHeaderBar labelHeaderBarTight" style={{ padding: 10 }}>Site Safe QR Code</div>
                <div className="licenseEditDivContent">
                  <div className="row">
                    <div className="col-12" style={{ paddingTop: 3 }}>
                      <div style={{ marginLeft: -14 }}><QRCode id="qr" value={this.props.userId} size={300} /> </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12" style={{ width: 430, borderTop: '4px solid #cacaca', marginTop: 10 }}>
                      <div style={{ marginLeft: -14, marginTop: 15 }}>
                        <Button onClick={() => { this.downloadQR() }} style={{}} className="buttonAction"> <a className="buttonText" id="downloader" download="image.png">Save QR CODE</a></Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div >
  }
}

AddEditUser.propTypes = {
  disableUser: PropTypes.func,
  saveUser: PropTypes.func,
  resetPassword: PropTypes.func,
  cancelSave: PropTypes.func,
  DisplayName: PropTypes.string,
  FirstName: PropTypes.string,
  EditFirstName: PropTypes.func,
  LastName: PropTypes.string,
  EditLastName: PropTypes.func,
  Email: PropTypes.string,
  EditEmail: PropTypes.func,
  Role: PropTypes.string,
  EditRole: PropTypes.func,
  AddingUser: PropTypes.bool,
  NameColor: PropTypes.string,
  EmailColor: PropTypes.string,
  InvalidInput: PropTypes.bool,
  updateStatus: PropTypes.string,
  selectedInactive: PropTypes.bool,
  roleCollection: PropTypes.array,
  editRoleCollection: PropTypes.func,
  userId: PropTypes.string,
  userLicenseCollection : PropTypes.array,
  PhoneNumber : PropTypes.string,
  EditPhoneNumber : PropTypes.func,
  updateStatusText : PropTypes.string,
  duplicateUser : PropTypes.func,
  licenseCollection : PropTypes.array,
  updateLicense : PropTypes.func,
  trialMode : PropTypes.bool,
  PhoneColor : PropTypes.string,
  ExternalIdColor : PropTypes.string,
  ExternalId : PropTypes.string,
  EditExternalId : PropTypes.func,
  twoFactorEnabled : PropTypes.bool,
  updateTwoFactor : PropTypes.func
}

export default AddEditUser;