/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from "prop-types";

const CountBox = (props) => {
  const { label, count, description, surveyTypes } = props;
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.filterCallback({ LeakSurveyTypeId: Number(props.id) }, `Showing ${surveyTypes[Number(props.id)]} Surveys.`);
  }
  return (
    <div onClick={handleClick} style={{overflow: "hidden"}} className="d-flex flex-row align-items-center justify-content-between h-100 w-100">
      <h1 
        style={{minWidth: "20%", backgroundColor: "#16558F", boxShadow: "inset 0px -6px 0px 0px rgba(112,150,186,1)"}} 
        className="border-right text-white border-dark h-100 m-0 d-flex justify-content-center align-items-center"
      >
        <span style={{marginBottom: 8}}>{count}</span>
      </h1>
      <div style={{width: "80%"}} className="d-flex flex-column align-items-center justify-content-center">
        <h2 className="h-100 m-0 d-flex align-items-center justify-content-center">{label}</h2>
        {description&&<small className="text-secondary">{description}</small>}
      </div>
    </div>
  );
}

CountBox.propTypes = {
  label : PropTypes.string,
  count : PropTypes.number,
  description : PropTypes.string
}

export default CountBox;