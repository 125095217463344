import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import PropTypes from "prop-types";

highchartsMore(Highcharts);

const CountTagByTagName = (props) =>{
  const [graphCategories, setGraphCategories] = React.useState([]);
  const [graphData, setGraphData] = React.useState([]);
  const [chart, setChart] = React.useState(null);
  
  
  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])
  React.useEffect(() => {
   
    console.time('CountTagByTagName');
    let parsed = {};
    
    props.data.forEach(item => {
      let ticketTagNames = [];
      if(item.AttachedTagNames){
        ticketTagNames = item.AttachedTagNames.split("|");
      }
      ticketTagNames.forEach((tagName)=>{
        if(tagName == "-1") tagName = "Tickets not yet assigned.";
        parsed[tagName]
        ? parsed[tagName] = { name: tagName, y: parsed[tagName].y + 1}
        : parsed[tagName] = { name: tagName, y : 1}    
      });
        
    });
    
    setGraphCategories(Object.keys(parsed));
    setGraphData(Object.values(parsed));

    console.timeEnd('CountTagByTagName');
  }, [props.data])

  const chartCallback = c => setChart(c);

  return(
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark"}}
      highcharts={Highcharts}
      options={{
        title: {text: "Tags" + (graphCategories.length == 0 ? " - No Tags Found" : "")},
        xAxis: {
          categories: graphCategories,
        },
        plotOptions: {
          series: {
            cursor : "pointer",
            point: {
              events: {
                click: function(event){
                  props.filterCallback({ 
                    filterType: 'ByNameTag',
                    filterValue: event.point.name,
                  },
                  `${event.point.category}`)
                }
              }
            },
            dataLabels: {
              enabled: true,
              color: 'black',
              align: 'right',
              x: -3,
              y: 3,
              style: {
                  color: '#333',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  fontFamily: 'Trebuchet MS, Verdana, sans-serif'
              }
          },
          }
        },
        yAxis : {
          min: 0,
          title: { text: "Tag Count" }
        },
        colors :["#16558F", "#2D7952", "#DE8C26"],
        chart:{
          type: 'bar',
          backgroundColor : "#E9E9EF",
        },
        column: {
          dataLabels: {
              verticalAlign: 'bottom',
              allowOverlap: true,
              enabled: true,
              inside: true
        }},
        series: [
          {
            name: "Tags",
            data : graphData
          }
        ],
        credits: { enabled : false },
        legend : { enabled: false },
      }}
      allowChartUpdate={true}
      callback={chartCallback}
      constructorType="chart"
    />
  )
}

CountTagByTagName.propTypes = {
  refreshKey : PropTypes.number,
  dateFrom : PropTypes.string,
  dateTo : PropTypes.string,
  data : PropTypes.array,
  formData : PropTypes.array,
  filterCallback : PropTypes.func,
  useModified: PropTypes.bool
}
  
export default CountTagByTagName;