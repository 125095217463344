import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import auth from "../common/Auth";
import { BarLoader } from "react-spinners";
import { DirtUtility } from '../../Utility/DirtUtility';
import GetApp from "@material-ui/icons/GetApp";
import FindInPageTwoToneIcon from '@material-ui/icons/FindInPageTwoTone';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History'
import MapIcon from '@material-ui/icons/Map';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { get, set } from 'idb-keyval';


class SurveyStrip extends React.Component {
  constructor(props) {
    super(props);
    let roles = auth.roles();
    let canEdit = false;
    let licenseToCheck = '';
    switch(this.props.entityTypeId){
      case 93:
        licenseToCheck = 'customforms';
        break;
      case 1:
        licenseToCheck = 'investigation';
        break;
      case 0:
        licenseToCheck = 'site';
        break;
      case 13:
        licenseToCheck = 'gps';
        break;
      case 14:
      case 15:
      case 16:
      case 22:
        licenseToCheck = 'leak';
        break;
      case 23:
        licenseToCheck = 'sitesafe';
        break;
      default:
        licenseToCheck = '';
    }
    if (roles != null) {
      roles.split(',').forEach(role =>{
        // Only admins and managers to edit data, must have license for entity being shown
        if((role == 0 || role == 1 || role == 5) && auth.licenses().includes(licenseToCheck)){ 
          canEdit = true;
        }
      })
    }
    this.state = {
      updateStatusText : "",
      loadingMsg: "Data", 
      loadingPdf: "Report", 
      loadingDirt: "DIRT Submit",
      loadingDelete :"Archive" ,
      submitted : this.props.DirtSubmitted == "SUBMITTED" ,
      archiveAlert : false,
      dialogOpen : false,
      pictureDownloadMax : 50,
      asyncDownloadData : false,
      asyncDownloadReport : false,
      canEdit,
      individualScores : {},
      totalScore : 0,
      dirtConfirmDialog :false,
      showDQIDescription : false,
      cleanEntity : true,
      reportDialogOpen : false,
      ticketReportDialogOpen : false
    };
    this.delete = this.delete.bind(this);
  }

  componentDidMount(){
    fetch(process.env.API_URL + "/api/data/pictureCountForAsyncReport",{
      method: "POST",
      headers: {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      }
    }).then(r =>{
      if(r.status == 200){
        r.json().then(response =>{
          if(response.num){
            this.setState({pictureDownloadMax : parseInt(response.num)});
          }
        })
      }
    })
  }

  async delete(){
    let data = await get('resultData');
    if(!data) return;
    
    this.setState({ loadingDelete : "Archiving..."});
    let resultData = JSON.parse(data);
    let urlPostFix = "";
    let type = ""
    let entity = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId()
    }
    switch(this.props.entityTypeId){
      case 0:
        urlPostFix = "/api/survey/save";
        entity.SiteSurvey = this.props.entity;
        entity.SiteSurvey.DeletedDate = new Date();
        entity.SiteSurvey.DeletedBy = auth.employeeId();
        entity.SiteSurvey.DeletedDateUTCOffset = new Date().getTimezoneOffset()/-60;
        type = "Survey";
        break;
      case 1:
        urlPostFix = "/api/investigation/save";
        entity.Investigation = resultData.InvestigationCollection.find(x => x.InvestigationId == this.props.entity.InvestigationId);
        entity.Investigation.DeletedDate = new Date();
        entity.Investigation.DeletedBy = auth.employeeId();
        entity.Investigation.DeletedDateUTCOffset = new Date().getTimezoneOffset()/-60;
        type = "Investigation";
        break;
      case 14:
      case 15:
      case 16:
      case 22:
        urlPostFix = "/api/LeakSurvey/save";
        entity.LeakSurvey = this.props.entity;
        entity.LeakSurvey.DeletedDate = new Date();
        entity.LeakSurvey.DeletedBy = auth.employeeId();
        entity.LeakSurvey.DeletedDateUTCOffset = new Date().getTimezoneOffset()/-60;
        if(this.props.entityTypeId == 14){
          type = "Inaccessible Survey";
        }
        else if(this.props.entityTypeId == 15){
          type = "AOC Survey";
        }
        else if(this.props.entityTypeId == 16){
          type = "Leak Survey";
        }
        else if(this.props.entityTypeId == 22){
          type = "Meter Inspection";
        }
        break;

      case 23:
        urlPostFix = "/api/SiteSafe/save";
        entity.SiteSafe = this.props.entity;
        entity.SiteSafe.DeletedDate = new Date();
        entity.SiteSafe.DeletedBy = auth.employeeId();
        entity.SiteSafe.DeletedDateUTCOffset = new Date().getTimezoneOffset() / -60;
        type = "SITESAFE";
        break;
      case 93:
        urlPostFix = "/api/form/save";
        entity.FormData = this.props.entity.FormData;
        entity.FormData.FieldsToSave = entity.FormData.Fields;
        entity.FormData.DeletedDate = new Date();
        entity.FormData.DeletedBy = auth.employeeId();
        entity.FormData.DeletedDateUTCOffset = new Date().getTimezoneOffset() / -60;
        type = "CUSTOM FORM"
        break;
      default:
      break;
    }
    if(urlPostFix != ""){
      fetch(process.env.API_URL + urlPostFix,{
        method: "POST",
        headers: {
          Accept : "application/json",
          "Content-Type"  : "application/json; charset=utf-8",
          Authorization : "Bearer " + auth.token()
        },
        body: JSON.stringify(entity)
      }).then(r =>{
        if(r.status == 200){
          this.setState({loadingDelete : "Archive", updateStatusText : "Archived " + type, alertSeverity : "success"});
          switch(this.props.entityTypeId){
            case 0:
              resultData.SiteSurveyCollection.splice(resultData.SiteSurveyCollection.findIndex(x => x.SiteSurveyId == this.props.entity.SiteSurveyId),1);
              break;
            case 1:
              resultData.InvestigationCollection.splice(resultData.InvestigationCollection.findIndex(x => x.InvestigationId == this.props.entity.InvestigationId),1);
              break;
            case 14:
            case 15:
            case 16:
            case 22:
              resultData.LeakSurveyCollection.splice(resultData.LeakSurveyCollection.findIndex(x => x.LeakSurveyId == this.props.entity.LeakSurveyId),1);
              break;
            case 23:
              resultData.SiteSafeCollection.splice(resultData.SiteSafeCollection.findIndex(x => x.SiteSafeId = this.props.entity.SiteSafeId),1);
              break;
            case 93:
              switch(entity.FormData.FormTypeId){
                case 0:
                  resultData.SiteSurveyCollection.splice(resultData.SiteSurveyCollection.findIndex(x => x.FormData != null && x.FormData.FormDataId == this.props.entity.FormData.FormDataId),1);
                  break;
                case 1:
                  resultData.InvestigationCollection.splice(resultData.InvestigationCollection.findIndex(x => x.FormData != null && x.FormData.FormDataId == this.props.entity.FormData.FormDataId),1);
                  break;
                case 2:
                  resultData.LeakSurveyCollection.splice(resultData.LeakSurveyCollection.findIndex(x => x.FormData != null && x.FormData.FormDataId == this.props.entity.FormData.FormDataId),1);
                  break;
                case 3:
                  resultData.GPSSurveyCollection.FormDataCollection.splice(resultData.GPSSurveyCollection.FormDataCollection.findIndex(x => x.FormDataId == this.props.entity.FormData.FormDataId),1);
                  break;
              }
              break;
            default:
              break;
          }
          set('resultData',JSON.stringify(resultData));
          window.location.href ="/reporting"
        }
        else{
          this.setState({loadingDelete : "Archive", updateStatusText : "Error archiving " +type, alertSeverity : "error"});
        }
      }).catch(()=>{
        this.setState({loadingDelete : "Archive", updateStatusText : "Error archiving " +type, alertSeverity : "error"});
      });
    }
    else{
      this.setState({loadingDelete : "Archive", updateStatusText : "Error archiving " +type, alertSeverity : "error"});
    }
  }

  async uploadDirt(){
    this.setState({ loadingDirt: "Submitting..." });
    await DirtUtility.uploadDirt(this.props.id)
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
           if(json.ResponseType == 2){
             this.setState({loadingDirt : "DIRT Submit",updateStatusText : "Error Uploading to DIRT: " + json.ValidationMessageCollection[0].MessageText, alertSeverity : "Error"});
           }
           else{
             this.setState({ loadingDirt : "DIRT Submit", updateStatusText : "Submitted to DIRT", submitted : true, alertSeverity : "success"});
          }
        });
      }
    })
    .catch(error =>{
      this.setState({loadingDirt : "DIRT Submit",updateStatusText : "Error Uploading to DIRT" + error, alertSeverity : 'error'})
    });
  }

  async checkScore(){
    this.setState({ loadingDirt : "Checking Score..."});
    await DirtUtility.checkDirtScore(this.props.id)
    .then(r =>{
      if(r.status ==  200){
        r.json().then(responseJson =>{
          console.log(responseJson);
          if(responseJson.result && responseJson.result.dirtIncidentValidation && responseJson.result.dirtIncidentValidation.length > 0){
            let results = responseJson.result.dirtIncidentValidation[0];
            if(results.valid || results.vali){ 
              let dqi = results.dqi;
              let totalScore = dqi.score;
              let individualScores = {};
              dqi.dqiPart.forEach((section) =>{
                if(section.part == 0){
                  individualScores.A = section.score;
                }
                else if(section.part == 1){
                  individualScores.B = section.score;
                }
                else if(section.part == 2){
                  individualScores.C = section.score;
                }
                else if(section.part == 3){
                  individualScores.D = section.score;
                }
                else if(section.part == 4){
                  individualScores.E = section.score;
                }
                else if(section.part == 6){
                  individualScores.G = section.score;
                }
                else if(section.part == 7){
                  individualScores.H = section.score;
                }
                else if(section.part == 8){
                  individualScores.I = section.score;
                }
              });
              this.setState({ loadingDirt : "DIRT Submit", totalScore, individualScores, dirtConfirmDialog : true });
            }
            else{
              this.setState({loadingDirt : "DIRT Submit", updateStatusText : "Validation Error Uploading to Dirt: " + ((results.validationError != null && results.validationError.length >0) ?(results.validationError[0].element + " " + results.validationError[0].value) : ""), alertSeverity : 'error'});
            }
          }
          else{
            this.setState({loadingDirt : "DIRT Submit", updateStatusText : "There was a problem uploading to Dirt. Try again.", alertSeverity : 'error'});
          }
        })
      }
    })
    .catch(error =>{
      this.setState({loadingDirt : "DIRT Submit", updateStatusText : "Error Checking DIRT Score " + error, alertSeverity : "error"});
    })
  }

  downloadZip() {
    if(this.props.pictureCount && this.props.pictureCount > this.state.pictureDownloadMax){ 
      this.setState({dialogOpen : true, asyncDownloadData : true});
    }
    else{
      this.setState({ loadingMsg: "Downloading..." });
      let entityType = this.props.entityTypeId;
      let me = this;
      
      let body = {
        UserId : auth.employeeId(),
        EntityType: entityType,
        EntityId: this.props.showingHistoryRecord ? this.props.historyRecordId : me.props.id,
        Offset : - new Date().getTimezoneOffset() / 60,
        HistoryIdList : this.props.historyIdList && this.props.historyIdList.length > 0 ? this.props.historyIdList : null
      };

      fetch(process.env.API_URL + "/api/data/download/", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + auth.token()
        },
        body: JSON.stringify(body)
      })
      .then(r => r.blob())
      .then(blob => {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display:none";
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = this.props.type + " Data " + ((this.props.entity && this.props.entity.FormData && this.props.entity.FormData.TicketNumber) ? this.props.entity.FormData.TicketNumber : this.props.id ) + (entityType == 26 ? " History Records" : entityType == 25 ? " History Record " + this.props.historyRecordId : this.props.historyIdList && this.props.historyIdList.length > 0 ? "History Records " : this.props.historyRecordId ? "History Record " :  "") + ".zip";
        a.click();
        window.URL.revokeObjectURL(url);
        this.setState({ loadingMsg: "Data" });
      })
      .catch(error => {
        console.log(error.message);
      });
    } 
  }

  handleClose(cancel){
    this.setState({dialogOpen : false});
    if(!cancel){
      if(this.state.asyncDownloadReport){
        this.setState({ loadingPdf: "Generating Report..." });
        let entityType = this.props.entityTypeId;
        console.log(this.props);
        let me = this;
        let date = new Date();
        let body = {
          UserId : auth.employeeId(),
          CompanyId : auth.companyId(),
          EntityType: entityType,
          EntityId: me.props.id,
          TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          Offset: - date.getTimezoneOffset()/60,
          EmailReport : true,
          EmailAddress : auth.userName()
        };
  
        fetch(process.env.API_URL + "/api/data/report/", {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json", 
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + auth.token()
          },
          body: JSON.stringify(body)
        })
        .then(() => {
          this.setState({ loadingPdf: "Report" });
        })
        .catch(error => {
          console.log(error.message);
        });
      }
      else if(this.state.asyncDownloadData){
        this.setState({ loadingMsg: "Loading Data..." });
        let entityType = this.props.entityTypeId;
        let me = this;
        
        let body = {
          UserId : auth.employeeId(),
          CompanyId : auth.companyId(),
          EntityType: entityType,
          EntityId: this.props.showingHistoryRecord ? this.props.historyRecordId : me.props.id,
          Offset : - new Date().getTimezoneOffset() / 60,
          HistoryIdList : this.props.historyIdList && this.props.historyIdList.length > 0 ? this.props.historyIdList : null,
          EmailData : true,
          EmailAddress : auth.userName(),
          TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        fetch(process.env.API_URL + "/api/data/download/", {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + auth.token()
          },
          body: JSON.stringify(body)
        })
        .then(() => {
          this.setState({ loadingMsg: "Data" });
        })
        .catch(error => {
          console.log(error.message);
        });
      }
    }
    this.setState({asyncDownloadReport : false, asyncDownloadData : false});
  }

  generateMapReport(){
    this.setState({ loadingPdf: "Generating Report..."});
    let date = new Date();
    let entityType = this.props.entityTypeId;
    var fileName = this.props.type + " Map Report " + this.props.id + (entityType == 25 || this.props.showingHistoryRecord ? " History Record Map report" : "") + ".pdf";
    let body = {
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      entityId : this.props.showingHistoryRecord ? this.props.historyRecordId : this.props.id,
      TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      Offset : -date.getTimezoneOffset()/60
    }
    fetch(process.env.API_URL + "/api/data/GPSSurveymapReport",{
      method : "POST",
      headers : {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => {
      r.headers.forEach((val,key) =>{
        if(key == "pdfname"){
          if(val != "Map Report.pdf"){
            fileName = val;
          }
        }
      });
      r.blob().then(blob =>{
        let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display:none";
  
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          this.setState({ loadingPdf: "Report" });
      })
    })
    
    .catch(error => {
      console.log(error.message);
    });
  }

  generateReport(includeTicketLog) {
    if(this.props.pictureCount && this.props.pictureCount > this.state.pictureDownloadMax){ 
      this.setState({dialogOpen : true, asyncDownloadReport : true});
    }
    else{
      this.setState({ loadingPdf: "Generating Report..." });
      let entityType = this.props.entityTypeId;
      console.log(this.props);
      let me = this;
      let date = new Date();
      let body = {
        UserId : auth.employeeId(),
        EntityType: entityType,
        EntityId: this.props.showingHistoryRecord ? this.props.historyRecordId : me.props.id,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Offset: - date.getTimezoneOffset()/60,
        EmailReport : false,
        IncludeTicketLog : includeTicketLog
      };

      fetch(process.env.API_URL + "/api/data/report/", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json", 
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + auth.token()
        },
        body: JSON.stringify(body)
      })
      .then(r => r.blob())
      .then(blob => {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display:none";

        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = this.props.type + " Report " + ((this.props.entity && this.props.entity.FormData && this.props.entity.FormData.TicketNumber) ? this.props.entity.FormData.TicketNumber : this.props.id )+ (entityType == 25 || this.props.showingHistoryRecord ? " History Record" : "") + ".pdf";
        a.click();
        window.URL.revokeObjectURL(url);
        this.setState({ loadingPdf: "Report" });
      })
      .catch(error => {
        console.log(error.message);
      });
    }
    
  }

  render() {
    return (
      <div className="pageBar">
      <div className="surveyStrip row p-0">
        {/* <div className="m-0 p-0 barHeaderDiv">
        </div> */}
        <div className="surveySearchDivButtons" style={{justifyContent: "space-between"}}>
          <div>
          <label className="labelHeader scale"> {(this.props.type == 'Site' ? "Survey" : this.props.type) + " " + this.props.id} </label>
          {this.props.showingHistoryList && <label className="historyLabel">- HISTORY</label>}
          {this.props.showingHistoryRecord && <label className="historyLabel">- HISTORY - {this.props.modifiedDate && new Date(this.props.modifiedDate[this.props.modifiedDate.length]-1 !="Z" ? this.props.modifiedDate + "Z" :this.props.modifiedDate).toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"})}</label>}
          </div>
          <div>
            { (this.props.fetchHistory) &&
              <Button className ="buttonAction buttonAccount btn btn-primary" title="History"
                onClick={() =>{this.props.fetchHistory();}}>
                  <HistoryIcon className="buttonIcon light"/>
                  <label className="buttonText withIcon">HISTORY</label>
              </Button>
            }
            {(this.props.ticket && this.props.fetchChangeLog) && 
                <Button className ="buttonAction buttonAccount btn btn-primary" title="History"
                onClick={() =>{this.props.fetchChangeLog();}}>
                  <HistoryIcon className="buttonIcon light"/>
                  <label className="buttonText withIcon">Ticket Log</label>
              </Button>}
          {(!this.props.Secured && !this.props.hideDataDownload) && <Button className="buttonAction buttonAccount btn btn-primary" title="Download Data"
            onClick={() => { this.state.loadingMsg == "Data" ? this.downloadZip() : null; }} >
              <GetApp className="buttonIcon light" />
            <label className="buttonText withIcon">{this.state.loadingMsg}</label>
          </Button>}
          {(!this.props.hideReport && !this.props.Secured ) && <Button className="buttonAction buttonAccount btn btn-primary" title="Download Report"
            onClick={() => { this.state.loadingPdf == "Report" ? (this.props.entity && this.props.entity.FormData && this.props.entity.FormData.FormTypeId == 3 ? this.setState({reportDialogOpen : true}) : (this.props.entity && this.props.entity.FormData && this.props.entity.FormData.FormTypeId == 4) ? this.setState({ticketReportDialogOpen : true}) : this.generateReport()) : null;}} >
            <FindInPageTwoToneIcon className="buttonIcon light" />
            <label className="buttonText withIcon">{this.state.loadingPdf}</label>
          </Button>}
          {(this.props.entityTypeId == 1 && this.props.dirtConfigured) && !this.props.showingHistoryList && !this.props.showingHistoryRecord && (this.state.cleanEntity) && 
          <Button className="buttonAction buttonAccount btn btn-primary btnSubmit" title="DIRT Submit"
            onClick={() => {if(auth.isSuperViewer()){return;} this.state.loadingDirt == "DIRT Submit" ? this.checkScore() : null;}} disabled={this.state.submitted} >
            <label className="buttonText" disabled={this.state.submitted}>{this.state.submitted ? "Dirt Submitted" : this.state.loadingDirt}</label>
          </Button>
          }
          {
            (!this.props.hideEdit && this.state.canEdit && !this.props.editing && (this.props.entityTypeId == 1 || this.props.entityTypeId == 0 || this.props.entityTypeId == 93 ||  this.props.entityTypeId == 15 || this.props.entityTypeId == 16 || this.props.entityTypeId == 23 || this.props.entityTypeId == 22 || this.props.entityTypeId == 14)) &&
            <Button className="buttonAction buttonAccount btn btn-primary btnSubmit" title="Edit Survey"
              onClick={() =>{this.props.setUpEditing()}}>
              <EditIcon className="buttonIcon light" />
              <label style={{margin:0, cursor: 'pointer'}} className="buttonText withIcon">EDIT</label>
            </Button>
          }
          
          {
            (this.props.editing && (this.props.entityTypeId == 1 || this.props.entityTypeId == 0 || this.props.entityTypeId == 93 ||  this.props.entityTypeId == 15 || this.props.entityTypeId == 16 || this.props.entityTypeId == 23 || this.props.entityTypeId == 22 || this.props.entityTypeId == 14)) &&
            <div style={{display : "inline"}}>
                <Button className="buttonAction buttonAccount dehighlighted" onClick={() =>{this.props.cancelEditing()}}>
                  <label style ={{margin: 0, cursor : 'pointer'}} className="buttonText">Cancel</label>
                </Button>
                <Button className="buttonAction buttonAccount confirm" onClick={() => {this.setState({cleanEntity : false});this.props.saveSurvey();}}>
                  <label style ={{margin: 0, cursor : 'pointer'}} className="buttonText">{this.props.loadingUpdate}</label>
                </Button>
              </div>
          }
          {!this.props.hideArchive && !this.props.showingHistoryList && !this.props.showingHistoryRecord && <Button className="buttonAction buttonAccount" title="Archive"
            onClick={() =>{if(this.state.loadingDelete == "Archive"){this.setState({archiveAlert : true})}}}>
              <label className="buttonText" style={{margin:0, cursor : 'pointer'}}>{this.state.loadingDelete}</label>
          </Button>}
          {!this.props.hideMap && <Button className="buttonAction buttonAccount btn btn-primary" title='Map'
            onClick={() =>{this.props.goToMap()}}>
              <MapIcon className="buttonIcon light"/>
              <label style={{margin:0, cursor: 'pointer'}} className="buttonText withIcon">MAP</label>
            </Button>}
           {this.props.closeEntity && <Button className="buttonAction buttonAccount btn btn-primary brnSubmit square dehighlighted"
            onClick={() => {this.props.closeEntity();}}><CloseSharpIcon className="buttonIcon light" />
          </Button>}
         
          </div>
        </div>
        <div className="barLoaderPortalBar">
            <BarLoader sizeUnit={"px"} size={25} color={"#095399"} loading={this.state.loadingPdf == "Report" ? false : true}/>
            <BarLoader sizeUnit={"px"} size={25} color={"#095399"} loading={this.state.loadingMsg == "Data" ? false : true }/>
            <BarLoader sizeUnit={"px"} size={25} color={"#095399"} loading={this.state.loadingDirt == "DIRT Submit" ? false : true }/>
            <BarLoader sizeUnit={"px"} size={25} color={"#095399"} loading={this.state.loadingDelete == "Archive" ? false : true }/>
            <BarLoader sizeUnit={"px"} size={25} color={"#095399"} loading={this.props.loadingUpdate == "Save" ? false : true }/>
        </div>
        <Snackbar open={this.state.updateStatusText !=""} onClick={()=> {this.setState({updateStatusText : ''})}} autoHideDuration={6000} onClose={()=>{}}
          anchorOrigin={{ vertical: 'top', horizontal : 'center'}}>
            <Alert onClose={()=>{}} severity={this.state.alertSeverity} variant="filled">
              {this.state.updateStatusText}
            </Alert>
        </Snackbar>
        <Dialog open={this.state.reportDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"DATA REPORT"}</DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-4">
              <Button className="buttonAction buttonAccount btn btn-primary" onClick={()=>{this.generateReport(); this.setState({reportDialogOpen : false})}}>
                <label className="buttonText small" style={{margin : 0, cursor : 'pointer'}}>DATA &amp; PHOTOS</label>
              </Button>
              </div>
              <div className="col-8">
                Includes all data attributes, signatures, and photos and comparisons.
              </div>
            </div>
            <div className="row">
              <div className="col-4 ">
              <Button className="buttonAction buttonAccount btn btn-primary" style={{width : 116}} onClick={()=>{this.generateMapReport(); this.setState({reportDialogOpen : false})}}>
                <label className="buttonText small" style={{margin : 0, cursor : 'pointer'}}>MAP REPORT</label>
              </Button>
              </div>
              <div className="col-8">
                Includes Map and legend of related GPS data, a subset of attributes and photos. 
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.ticketReportDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"DATA REPORT"}</DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-6">
              <Button style={{minHeight: 60, minWidth:200}} className="buttonAction buttonAccount btn btn-primary" onClick={()=>{this.generateReport(false); this.setState({ticketReportDialogOpen : false})}}>
                <label className="buttonText small" style={{margin : 0, cursor : 'pointer'}}>Do Not Include Ticket History Log</label>
              </Button>
              </div>
              <div className="col-6 ">
              <Button style={{minHeight: 60, minWidth:200}} className="buttonAction buttonAccount btn btn-primary" onClick={()=>{this.generateReport(true); this.setState({ticketReportDialogOpen : false})}}>
                <label className="buttonText small" style={{margin : 0, cursor : 'pointer'}}>Include Ticket History Log</label>
              </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.dirtConfirmDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"DIRT Incident Submission"}</DialogTitle>
          <DialogContent>
            <div className="row">
            <div className="col-1">
              <div className="dirtScoreBlock"><div style={{marginTop:15, fontSize:44, position:'relative', top:2}}>{this.state.totalScore}</div></div>
            </div>
            <div className="col">
              <DialogContentText id ="alert-dialog-description" style={{marginLeft : 50, marginTop : 15, fontWeight : "bold"}}>
                Overall Data Quality Score is {this.state.totalScore} out of 100. Would you like to confirm this data quality and submit this incident report?
              </DialogContentText>
              
            </div>
            </div>
            {/**#16548e */}
            {this.state.totalScore <100 ?<DialogContentText style={{marginTop: 10, fontWeight:"bold", color:'#6688a8' }}>
               To Improve Score Ensure fields in the following sections are populated:
              </DialogContentText> : null}
              {this.state.totalScore < 100 ? <DialogContentText style={{fontWeight : 'bold'}}>
                {this.state.individualScores.A && this.state.individualScores.A <100 ? "Event Type and Source. " :null}
                {this.state.individualScores.B && this.state.individualScores.B <100 ? "Type, Date and Location of the event. " :null}
                {this.state.individualScores.C && this.state.individualScores.C <100 ? "Affected Facility Information. " :null}
                {this.state.individualScores.D && this.state.individualScores.D <100 ? "Excavation Information. " :null}
                {this.state.individualScores.E && this.state.individualScores.E <100 ? "Notification and Locating. " :null}
                {this.state.individualScores.G && this.state.individualScores.G <100 ? "Excavator DownTime. " :null}
                {this.state.individualScores.H && this.state.individualScores.H <100 ? "Interruption and Restoration. " :null}
                {this.state.individualScores.I && this.state.individualScores.I <100 ? "Description of the Root Cause. " :null}
              </DialogContentText> : null}
              <DialogContentText onClick={()=>{this.setState({showDQIDescription : !this.state.showDQIDescription})}} style={{color : '#428af5', cursor : 'pointer'}}>What is the DIRT Data Quality Index?</DialogContentText>
              {this.state.showDQIDescription  && <DialogContentText>The Data Quality Index (DQI), a measure of completeness of DIRT reports, has declined in recent years. In
                terms of the number of companies entering DIRT data, a large percentage score fairly well, although they
                submit a small percentage of data. Conversely, there are a small number of companies submitting large
                quantities of poor-quality data. DIRT could become even more useful if the high-quantity, low-DQI
                stakeholders could improve their scores. 
              </DialogContentText>}
          </DialogContent>
          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={()=>{this.setState({dirtConfirmDialog : false})}}>
              <label className="buttonText small" style={{margin : 0, cursor : 'pointer'}}>Cancel</label>
            </Button>
            <Button className="buttonAction btn btn-primary" onClick={()=>{this.uploadDirt(); this.setState({dirtConfirmDialog : false})}}>
              <label className="buttonText small" style={{margin : 0, cursor : 'pointer'}}>Confrim &amp; Submit</label>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.archiveAlert} onClose={()=>{}}>
          <DialogTitle>{"Are you sure you want to archive?"}</DialogTitle>
          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={()=>{this.setState({archiveAlert : false})}} color="primary">
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Cancel</label>
            </Button>
            <Button className="buttonAction btn btn-primary" onClick={() =>{this.setState({archiveAlert : false});this.delete();}} color="primary" autoFocus>
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Confirm</label>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Report Request"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              It could take several minutes to collect your data, you will be emailed a download link when it is ready. (est. 2-5 mins)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={() => this.handleClose(true)}>
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer"}}>Cancel</label>
            </Button>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={() => this.handleClose(false)}>
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Accept</label>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      </div>
    );
  }
}

SurveyStrip.propTypes = {
  entityTypeId: PropTypes.number,
  type: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  swap: PropTypes.func,
  id: PropTypes.number,
  address: PropTypes.string,
  DirtSubmitted : PropTypes.object,
  entity : PropTypes.object,
  pictureCount: PropTypes.number,
  dirtConfigured : PropTypes.bool,
  editing : PropTypes.bool,
  setUpEditing : PropTypes.func,
  cancelEditing : PropTypes.func,
  saveSurvey : PropTypes.func,
  loadingUpdate : PropTypes.string,
  closeEntity : PropTypes.func,
  goToMap : PropTypes.func,
  fetchHistory : PropTypes.func,
  hideReport : PropTypes.bool,
  hideEdit : PropTypes.bool,
  hideArchive : PropTypes.bool,
  hideMap : PropTypes.bool,
  showingHistoryRecord : PropTypes.bool,
  modifiedDate : PropTypes.string,
  historyRecordId : PropTypes.number,
  showingHistoryList : PropTypes.bool,
  historyIdList : PropTypes.array
};

export default SurveyStrip;