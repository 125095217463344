import React from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";
import MarketingContent from './MarketingContent';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import topBackgroundImage from "../../media/stockImages/dozer.jpg";
import topBackgroundImage1 from "../../media/stockImages/AdobeStock1.jpg";
import topBackgroundImage2 from "../../media/stockImages/AdobeStock2.jpg";
import topBackgroundImage3 from "../../media/stockImages/AdobeStock3.jpg";
import topBackgroundImage4 from "../../media/stockImages/gis.jpg";
import sizes from "../../Global/sizes";
import colors from "../../Global/colors";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import { Button } from "@material-ui/core";
import OrgUtility from '../../Utility/Utility';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      phrase: "first",
      runThroughIndex: 0,
      navButtonOptions: [
        {
          header: 'GPS SURVEY',
          subTitle: 'Precision mapping and GIS system publishing',
          icon: FindInPageIcon,
          currentContent: false
        },        
        {
          header: 'GIS SURVEY',
          subTitle: 'Survey. Report. Audit.',
          icon: FindInPageIcon,
          currentContent: false
        },
        {
          header: 'CUSTOM FORMS',
          subTitle: 'Create Any Form For Any Need',
          icon: AssignmentIcon,
          currentContent: false
        },
        {
          header: 'INCIDENT INVESTIGATION',
          subTitle: 'Incident Investigation For Near Misses & Line Strikes',
          icon: AssignmentLateIcon,
          currentContent: false
        },
        {
          header: 'SITE SURVEY',
          subTitle: 'Document Locate Marks & Excavation Activity',
          icon: AssignmentTurnedInIcon,
          currentContent: true
        }
      ]
    }
    this.changeContent = this.changeContent.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.topStripeBannerHeight = 230;
    this.maxMarketingNavbarWidth = 960;
  }

  componentDidMount() {
    var orgUtil = new OrgUtility();
    this.setState({ orgId: orgUtil.getOrgId(), displayInternalLinks: orgUtil.displayInternalLinks() });

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.props.forceRefresh();
  }

  componentDidUpdate(){
    // STYLE CSS by ORG
    new OrgUtility().updateOrgCSS();
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  changeContent(content) {
    
    if (this.interval) {
      clearInterval(this.interval);
    }
    
    let options = this.state.navButtonOptions;
    options.forEach((option) => {
      if (option.header == content) {
        option.currentContent = true;
      }
      else {
        option.currentContent = false;
      }
    });

    this.setState({ navButtonOptions: options });
    let placeToGo = '';

    if (content == 'SITE SURVEY') {
      placeToGo = 'siteSurveyContent'
    }
    else if (content == 'GPS SURVEY') {
      placeToGo = 'gpsSurveyContent'
    }
    else if (content == 'INCIDENT INVESTIGATION') {
      placeToGo = 'incidentInvestigationContent'
    }
    else if (content == 'GIS SURVEY') {
      placeToGo = 'leakSurveyContent'
    }
    else if (content == 'CUSTOM FORMS') {
      placeToGo = 'customFormsContent'
    }
    
    document.getElementById("extraSpace").style.display = "block";
    document.getElementById(placeToGo).scrollIntoView({ block: 'start', behavior: 'smooth' });
    
    // scrolling to element does some weird stuff to the navbar and 
    if (this.state.width < 800) {
      let footer = document.getElementsByClassName("footer")[0];
      if (footer) {
        // top doesn't work as expected so negative bottom.
        footer.style.bottom = "-63px";
      }
    }
    else {
      document.getElementsByClassName("headerRow")[0].style.position = "absolute";
      document.getElementsByClassName("headerRow")[0].style.width = "100%";
      document.getElementsByClassName("headerRow")[0].style.zIndex = "1001";
      document.getElementsByClassName("headerRow")[0].style.height = "70px";
      document.getElementsByClassName("contentMain")[0].style.height = this.state.height - sizes.homePageHeaderHeight + "px";
    }

  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  anchorLink() {
    //window.location.href = "/a="+ index;
  }

  hoverTopBannerButton(mouseIsInside) {
    let elems = document.getElementsByClassName("homePageTopBannerButton");
    let textElems = document.getElementsByClassName("homePageTopBannerTextBottomRow");
    for (let i = 0; i < elems.length; i++) {
      let elem = elems[i];
      if (mouseIsInside) {
        elem.style.border = '2px solid #C3C3C3 !important';
      } else {
        elem.style.border = '2px solid ' + colors.officialLightGray + ' !important';
      }
    }
    for (let j = 0; j < textElems.length; j++) {
      let textElem = textElems[j];
      if (mouseIsInside) {
        textElem.style.color = '#C3C3C3';
      } else {
        textElem.style.color = colors.officialLightGray;
      }
    }
  }

  render() {

    // REDIRECT TO PRIVACY POLICY
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params.get('p') != null) return <Redirect to="/privacy" />

    // External portal -- redirect to login (no home)
    //if (!this.state.displayInternalLinks) return <Redirect to="/login" />;

    let mobileWidth = 800;
    const carouselSettings = {
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 7000,
      arrows: this.state.width >= mobileWidth,
      fade: this.state.width >= mobileWidth,
      pauseOnHover: false
    };

    return (
      <div className="col-8  p-0 m-0" style={{ backgroundColor: "white", minWidth: "100%", overflowX: "hidden" }}>

        <Slider {...carouselSettings}>

        <div>
            <div style={{ backgroundImage: `url(${topBackgroundImage4})`, height: (this.state.height - sizes.homePageHeaderHeight - (this.state.width > this.maxMarketingNavbarWidth ? 57 : 0)) + 'px', width: this.state.width + 'px', backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div style={{ height: 'calc(50% - ' + (this.topStripeBannerHeight / 2) + 'px)' }} />
              {this.state.width >= mobileWidth &&
                <div style={{ height: this.topStripeBannerHeight }} className='homePageTopBannerBackground' />
              }
              <div className={'homePageTopBannerContentContainer'} style={{ height: this.topStripeBannerHeight + 'px' }}>
                <div className='homePageTopBannerContentRow'>
                  <span className='homePageTopBannerContentRowText homePageTopBannerTextTopRow'>GPS Survey</span>
                </div>
                <div className='homePageTopBannerContentRow'>
                  <span className='homePageTopBannerContentRowText homePageTopBannerTextCenterRow'>Precision mapping services that utilize GNSS and RTK correction services. Visualize and share GIS data across your organization.</span>
                </div>
                <div className='homePageTopBannerContentRow'>
                  <Button component={Link} to="/products?GPSSurvey" id='topBannerButton' className={'homePageTopBannerButton'} onMouseOver={() => { this.hoverTopBannerButton(true) }} onMouseOut={() => { this.hoverTopBannerButton(false) }} >
                    <span id='topBannerButtonText' className='homePageTopBannerContentRowText homePageTopBannerTextBottomRow'>Learn More</span>
                  </Button>
                </div>
              </div>
              <div style={{ height: 'calc(50% - ' + (this.topStripeBannerHeight / 2) + 'px)' }} />
            </div>
          </div>
          
          <div>
            <div style={{ backgroundImage: `url(${topBackgroundImage2})`, height: (this.state.height - sizes.homePageHeaderHeight - (this.state.width > this.maxMarketingNavbarWidth ? 57 : 0)) + 'px', width: this.state.width + 'px', backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div style={{ height: 'calc(50% - ' + (this.topStripeBannerHeight / 2) + 'px)' }} />
              {this.state.width >= mobileWidth &&
                <div style={{ height: this.topStripeBannerHeight }} className='homePageTopBannerBackground' />
              }
              <div className={'homePageTopBannerContentContainer'} style={{ height: this.topStripeBannerHeight + 'px' }}>
                <div className='homePageTopBannerContentRow'>
                  <span className='homePageTopBannerContentRowText homePageTopBannerTextTopRow'>GIS Survey</span>
                </div>
                <div className='homePageTopBannerContentRow'>
                  <span className='homePageTopBannerContentRowText homePageTopBannerTextCenterRow'>Optimize your workforce efficiency with intelligent routing, mobile data collection, auditing, and advanced reporting</span>
                </div>
                <div className='homePageTopBannerContentRow'>
                  <Button component={Link} to="/products?PipelineSurvey" id='topBannerButton' className={'homePageTopBannerButton'} onMouseOver={() => { this.hoverTopBannerButton(true) }} onMouseOut={() => { this.hoverTopBannerButton(false) }} >
                    <span id='topBannerButtonText' className='homePageTopBannerContentRowText homePageTopBannerTextBottomRow'>Learn More</span>
                  </Button>
                </div>
              </div>
              <div style={{ height: 'calc(50% - ' + (this.topStripeBannerHeight / 2) + 'px)' }} />
            </div>
          </div>

          <div>
            <div style={{ backgroundImage: `url(${topBackgroundImage3})`, height: (this.state.height - sizes.homePageHeaderHeight - (this.state.width > this.maxMarketingNavbarWidth ? 57 : 0)) + 'px', width: this.state.width + 'px', backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div style={{ height: 'calc(50% - ' + (this.topStripeBannerHeight / 2) + 'px)' }} />
              {this.state.width >= mobileWidth &&
                <div style={{ height: this.topStripeBannerHeight }} className='homePageTopBannerBackground' />
              }
              <div className={'homePageTopBannerContentContainer'} style={{ height: this.topStripeBannerHeight + 'px' }}>
                <div className='homePageTopBannerContentRow'>
                  <span className='homePageTopBannerContentRowText homePageTopBannerTextTopRow'>Custom Forms</span>
                </div>
                <div className='homePageTopBannerContentRow'>
                  <span className='homePageTopBannerContentRowText homePageTopBannerTextCenterRow'>No coding needed, create customized forms, workflows, and alerts that eliminate outdated paper processes</span>
                </div>
                <div className='homePageTopBannerContentRow'>
                <Button component={Link} to="/products?CustomForms" id='topBannerButton' className={'homePageTopBannerButton'} onMouseOver={() => { this.hoverTopBannerButton(true) }} onMouseOut={() => { this.hoverTopBannerButton(false) }} >
                    <span id='topBannerButtonText' className='homePageTopBannerContentRowText homePageTopBannerTextBottomRow'>Learn More</span>
                  </Button>
                </div>
              </div>
              <div style={{ height: 'calc(50% - ' + (this.topStripeBannerHeight / 2) + 'px)' }} />
            </div>
          </div>
          
          <div>
            <div style={{ backgroundImage: `url(${topBackgroundImage1})`, height: (this.state.height - sizes.homePageHeaderHeight - (this.state.width > this.maxMarketingNavbarWidth ? 57 : 0)) + 'px', width: this.state.width + 'px', backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div style={{ height: 'calc(50% - ' + (this.topStripeBannerHeight / 2) + 'px)' }} />
              {this.state.width >= mobileWidth &&
                <div style={{ height: this.topStripeBannerHeight }} className='homePageTopBannerBackground' />
              }
              <div className={'homePageTopBannerContentContainer'} style={{ height: this.topStripeBannerHeight + 'px' }}>
                <div className='homePageTopBannerContentRow'>
                  <span className='homePageTopBannerContentRowText homePageTopBannerTextTopRow'>Incident Investigation</span>
                </div>
                <div className='homePageTopBannerContentRow'>
                  <span className='homePageTopBannerContentRowText homePageTopBannerTextCenterRow'>Reduce liability for near misses and lines strikes with the most comprehensive damage investigation app on the market</span>
                </div>
                <div className='homePageTopBannerContentRow'>
                  <Button component={Link} to="/products?IncidentInvestigation" id='topBannerButton' className={'homePageTopBannerButton'} onMouseOver={() => { this.hoverTopBannerButton(true) }} onMouseOut={() => { this.hoverTopBannerButton(false) }} >
                    <span id='topBannerButtonText' className='homePageTopBannerContentRowText homePageTopBannerTextBottomRow'>Learn More</span>
                  </Button>
                </div>
              </div>
              <div style={{ height: 'calc(50% - ' + (this.topStripeBannerHeight / 2) + 'px)' }} />
            </div>
          </div>

          <div>
            <div style={{ backgroundImage: `url(${topBackgroundImage})`, height: (this.state.height - sizes.homePageHeaderHeight - (this.state.width > this.maxMarketingNavbarWidth ? 57 : 0)) + 'px', width: this.state.width + 'px', backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div style={{ height: 'calc(50% - ' + (this.topStripeBannerHeight / 2) + 'px)' }} />
              <div style={{ height: this.topStripeBannerHeight }} className={this.state.width >= mobileWidth ? 'homePageTopBannerBackground' : 'homePageTopBannerBackgroundMobile'} />
              <div className={'homePageTopBannerContentContainer'} style={{ height: this.topStripeBannerHeight + 'px' }}>
                <div className='homePageTopBannerContentRow'>
                  <span className='homePageTopBannerContentRowText homePageTopBannerTextTopRow'>Site Survey</span>
                </div>
                <div className='homePageTopBannerContentRow'>
                  <span className='homePageTopBannerContentRowText homePageTopBannerTextCenterRow'>Accurately document job site details utilizing photos, videos, &amp; location services</span>
                </div>
                <div className='homePageTopBannerContentRow'>
                  <Button component={Link} to="/products?SiteSurvey" id='topBannerButton' className={'homePageTopBannerButton'} onMouseOver={() => { this.hoverTopBannerButton(true) }} onMouseOut={() => { this.hoverTopBannerButton(false) }} >
                    <span id='topBannerButtonText' className='homePageTopBannerContentRowText homePageTopBannerTextBottomRow'>Learn More</span>
                  </Button>
                </div>
              </div>
              <div style={{ height: 'calc(50% - ' + (this.topStripeBannerHeight / 2) + 'px)' }} />
            </div>
          </div>



        </Slider>

        {this.state.navButtonOptions && this.state.width > this.maxMarketingNavbarWidth ?
          <div className="marketingNavbarContainer">
            <div className="marketingNavbarContainerInner">
              <div style={{ width: '100%' }} />
              {this.state.navButtonOptions.map((navOption, index) => {
                return (
                  <div key={index.toString()} className="marketingNavbarItemContainer" style={{
                    cursor: "pointer",
                    zIndex: navOption.currentContent ? 999 : 998
                  }}
                    onClick={() => { this.changeContent(navOption.header) }}>
                    <div className='marketingNavbarItemText'>
                      {navOption.header}
                    </div>
                  </div>
                )
              })}
              <div style={{ width: '100%' }} />
            </div>
          </div> : null}{/*<div className = "text-center"> MOBILE BUTTONS REMOVE FOR NOW
            {this.state.navButtonOptions.map((navOption, index) =>{
              return(<div className="row text-center" key={index.toString()} style={{marginTop : 15}} onClick={()=>{this.changeContent(navOption.header)}}>
                <div className="registrationButtonsDiv" style={{margin : "0 auto"}}>
                  <div className ="buttonAction btn btn-primary buttonText center backgroundButton" style={{width:'100%', height:50, padding:30}}>                  
                    {navOption.header}
                  </div>
                </div>
            </div>)
            })}
          </div>} 
        <div style={{minHeight:25}}/>*/}
        <MarketingContent width={this.state.width} mobileWidth={mobileWidth} />
      </div>
    );
  }
}
HomePage.propTypes = {
  forceRefresh: PropTypes.func,
  changeBackground: PropTypes.func
};
export default HomePage;
