import React from 'react';
import packageJson from '../../package.json';
import PropTypes from "prop-types";
global.appVersion = packageJson.version != null ? packageJson.version : "0.0.0";

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while(versionsA.length || versionsB.length){
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    //eslint-disable-next-line no-continue
    if(a === b) continue;
    //eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class CacheBuster extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () =>{
        if (caches) {
          caches.keys().then(async function(names) {
          await Promise.all(names.map(name => caches.delete(name)))
          })
          }
          window.location.replace(window.location.href);
      }
    }
  }

  componentDidMount(){
    //console.log('fetchingS')
    // fetch('/meta.json',{headers : {'Content-Type' : 'application/json', 'Accept' : 'application/json'}})
    //   .then((response) => {
    //     console.log(response); 
    //     if(response.status == 200){
    //       response.json().then((meta) =>{
    //         console.log(meta);
    //         const latestVersion = meta.version;
    //         const currentVersion = global.appVersion;
    
    //         const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
    //         if(shouldForceRefresh){
    //           console.log(`We have a new version - ${latestVersion}. Should force refresh`);
    //           this.setState({ loading : false, isLatestVersion: false});
    //         } else{
    //           console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
    //           this.setState({loading: false, isLatestVersion : true});
    //         }
    //       })
    //     }
    //     else{
    //       fetch('/src/meta.json')
    //         .then((response)=>{
    //           response.json().then((meta) =>{
    //             console.log(meta);
    //             const latestVersion = meta.version;
    //             const currentVersion = global.appVersion;
        
    //             const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
    //             if(shouldForceRefresh){
    //               console.log(`We have a new version - ${latestVersion}. Should force refresh`);
    //               this.setState({ loading : false, isLatestVersion: false});
    //             } else{
    //               console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
    //               this.setState({loading: false, isLatestVersion : true});
    //             }
    //           })
    //         })
    //     }
    //   });
    fetch(process.env.API_URL + "/api/CurrentWebVersion",{
      method : "Get",
      headers:{
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
      },
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then((latestVersion)=>{
          if(latestVersion){
            const currentVersion = global.appVersion;
            const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
              if(shouldForceRefresh){
                console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                this.setState({ loading : false, isLatestVersion: false});
              } else{
                  console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                  this.setState({loading: false, isLatestVersion : true});
              }
          }
          else{
            console.log(`Unable to fetch latest version number. Not forcing refresh.`);
            this.setState({loading: false, isLatestVersion : true});
          }
        });
      }
    })
    .catch(error =>{
      console.log(`Error fetching latest version number. Not forcing refresh.`);
      this.setState({loading: false, isLatestVersion : true});
    });
      // import('../meta.json').then(({default : meta}) =>{
      //   const latestVersion = meta.version;
      //   const currentVersion = global.appVersion;
        
      //   const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
      //     if(shouldForceRefresh){
      //       console.log(`We have a new version - ${latestVersion}. Should force refresh`);
      //       this.setState({ loading : false, isLatestVersion: false});
      //     } else{
      //         console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
      //         this.setState({loading: false, isLatestVersion : true});
      //     }
      // } );
  }

  render(){
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}

CacheBuster.propTypes = {
  children: PropTypes.func
};


export default CacheBuster;