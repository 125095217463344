import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import moment from 'moment';
import PropTypes from "prop-types";

highchartsMore(Highcharts);

const CountByStatus = (props) =>{
  const [chart, setChart] = React.useState(null);
  const [sComplete, setData] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  
  const chartCallback = c => setChart(c);
  
  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])
  React.useEffect(() => {
   
    console.time('CountByTicketStatus');
    let sComplete = [];
    
    if (props.data){
  
      let range = getDateRange(props.dateFrom, props.dateTo);
      setCategories(range);
      let offset = (new Date().getTimezoneOffset()) *60*1000;
      props.data.forEach(item =>{
        let dateValue = null;
        let ticketStatus = -1;
        let dateIndex = -1;
    
        dateValue = props.useModified ? item.ModifiedDate : item.CreatedDate;
        try{
          if(item.FormTaskStatus == 0){
            ticketStatus = "Open";
          }
          else if(item.FormTaskStatus == 1){
            ticketStatus = "Closed";
          }
          else if(item.FormTaskStatus == 2){
            ticketStatus = "Project";
          }
          else if(item.FormTaskStatus == 3){
            ticketStatus = "Canceled";
          }
          if(item.DueDate){
            let dueDate = new Date(item.DueDate);
            if(item.CloseDate){
              if(((dueDate - new Date(item.CloseDate)) /1000) < 0){
                ticketStatus = "Past Due"
              }
            }
            else if((dueDate - new Date())/1000 < 0){
              ticketStatus = "Past Due"
            }
          }
        }
        catch(eat){
          //eat
        }

        
        
        if (ticketStatus != -1){
            
          // DETERMINE DATE INDEX
          if(typeof(dateValue) == "string"){
            dateValue = new Date(dateValue);
          }
          let dateFormatted = new Date(dateValue.getTime()-offset).toISOString().split('T')[0];
          //dateIndex = range.findIndex(i => i === dateFormatted);
          dateIndex = range.indexOf(dateFormatted);

          // SERIES BY DATE AND GROUP
          let formIndex = sComplete.findIndex(i => i.name === ticketStatus);
          if (formIndex >= 0)
          {
            sComplete[formIndex].data[dateIndex] = sComplete[formIndex].data[dateIndex] + 1;
          }
          else
          {
            let dataArray = new Array(range.length).fill(0);
            sComplete.push({name: ticketStatus, data: dataArray, color : ticketStatus == "Past Due" ? "#fc0303" : item.FormTaskStatus == 0 ? "#16548e" : item.FormTaskStatus == 1 ? "#a9a9a9" : item.FormTaskStatus == 2 ? "#168e88" : "#dbff29"});
            formIndex = sComplete.findIndex(i => i.name === ticketStatus);
            sComplete[formIndex].data[dateIndex] = 1;
          }
        }
  
      });

      setData(sComplete);
    }

    console.timeEnd('CountByTicketStatus');

  }, [props.data])
   const getDateRange = (startDate, stopDate) => {
    let dateArray = [];
    let fromDate = moment(startDate).toDate();
    let toDate = moment(stopDate).toDate();
    while(fromDate <= toDate) {
      dateArray.push(moment(fromDate).format("YYYY-MM-DD"))
      fromDate = moment(fromDate).add(1, 'days');
    }
    return dateArray;
  }
  return(
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark"}}
      highcharts={Highcharts}
      allowChartUpdate={true}
      callback={chartCallback}
      constructorType="chart"
      options={{
        chart : { type: "column", backgroundColor : "#E1E9EF"},
        title: { text: "Task Status"},
        plotOptions:{
          column:{
            dataLabels: {
              enabled: true,
          }
          },
          series: {
            stacking: "normal",
            marker: {
              enabled: false,
              symbol : "circle",
              radius: 4, 
              states :{
                hover: {
                  enabled: true
                }
              }
            },
            point: {
              events: {
                click : function(event){
                  let formattedDate = moment(event.point.category).format("MM/DD/YYYY");
                  props.filterCallback({ 
                    filterType: 'ByDateTicket',
                    filterValue: event.point.category,
                    filterSubType : "status",
                    filterTicketType : event.point.color == "#fc0303" ? "PastDue" : event.point.color == "#16548e" ? "Open" : event.point.color == "#a9a9a9" ? "Closed" : event.point.color == "#168e88" ? "Project" : "Canceled"
                  },
                  formattedDate + " - " + (event.point.color == "#fc0303" ? "Past Due" : event.point.color == "#16548e" ? "Open" : event.point.color == "#a9a9a9" ? "Closed" : event.point.color == "#168e88" ? "Project" : "Canceled") )
                }
              }
            },
      }
    },
        xAxis: { categories: categories || []},
        yAxis: { title: { text : "Ticket Status by Date"}},
        tooltip: { shared: true },
        series: sComplete,
        credits: {enabled: false},
        legend: {enabled: true}
      }}
    />
  );
}

CountByStatus.propTypes = {
  refreshKey : PropTypes.number,
  dateFrom : PropTypes.string,
  dateTo : PropTypes.string,
  data : PropTypes.array,
  formData : PropTypes.array,
  filterCallback : PropTypes.func,
  useModified: PropTypes.bool
}
  
export default CountByStatus;