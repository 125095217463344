import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import moment from 'moment';
import PropTypes from "prop-types";

highchartsMore(Highcharts);

const OnTimePerformance = (props) =>{
    const [chart, setChart] = React.useState(null);
    const [sComplete, setData] = React.useState(null);
    const [categories, setCategories] = React.useState([]);
    
    const chartCallback = c => setChart(c);
    
    React.useEffect(() => {
      if(chart){
        chart.reflow();
      }
    }, [props.refreshKey])
    React.useEffect(() => {
     
      console.time('OnTimePerformance');
      
      
      if (props.data){
        let sComplete = [{name : "On Time Performance", colorByPoint : true, data : []}];
        let range = getDateRange(props.dateFrom, props.dateTo);
        let onTime = 0;
        let pastDue = 0;
        props.data.forEach(item =>{
          let dateValue = null;
          let ticketStatus = -1;
          let dateIndex = -1;
      
          dateValue = props.useModified ? item.ModifiedDate : item.CreatedDate;
          try{
            //On time
            //Past due use closed date or canceled status. 
            ticketStatus = "On Time"
            if(item.DueDate){
              let dueDate = new Date(item.DueDate);
              if(item.CloseDate){
                if(((dueDate - new Date(item.CloseDate)) /1000) < 0){
                  ticketStatus = "Past Due"
                }
              }
              else if(item.FormTaskStatus != 3 && (dueDate - new Date())/1000 < 0){
                ticketStatus = "Past Due"
              }
            }
            if(ticketStatus == "Past Due") pastDue++;
            else onTime ++;
          }
          catch(eat){
            //eat
          }
        });
        if(onTime > 0){
          sComplete[0].data.push({name: "On Time", y : (onTime/props.data.length) *100});
        }
        if(pastDue > 0){
          sComplete[0].data.push({name: "Past Due", y : (pastDue/props.data.length) *100, color : "#fc0303"});

        }
        if(!props.data || props.data.length == 0){
          sComplete[0].data.push({name: "On Time", y : 100});
        }

        setData(sComplete);
      }
      else{
        let sComplete = [{name : "On Time Performance", colorByPoint : true, data : [{name: "On Time", y : 100}]}];
        setData(sComplete);
      }
  
      console.timeEnd('OnTimePerformance');
  
    }, [props.data])
     const getDateRange = (startDate, stopDate) => {
      let dateArray = [];
      let fromDate = moment(startDate).toDate();
      let toDate = moment(stopDate).toDate();
      while(fromDate <= toDate) {
        dateArray.push(moment(fromDate).format("YYYY-MM-DD"))
        fromDate = moment(fromDate).add(1, 'days');
      }
      return dateArray;
    }
    return(
      <HighchartsReact
        containerProps={{ className: "w-100 h-100 border border-dark"}}
        highcharts={Highcharts}
        allowChartUpdate={true}
        callback={chartCallback}
        constructorType="chart"
        options={{
          chart : { type: "pie", backgroundColor : "#E1E9EF"},
          title: { text: "On Time Performance"},
          plotOptions:{
            pie:{
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
            },
            series: {
              stacking: "normal",
              marker: {
                enabled: false,
                symbol : "circle",
                radius: 4, 
                states :{
                  hover: {
                    enabled: true
                  }
                }
              },
              point: {
                events: {
                  click : function(event){
                    let formattedDate = moment(event.point.category).format("MM/DD/YYYY");
                    props.filterCallback({ 
                      filterType: 'ByDateTicket',
                      filterValue: event.point.category,
                      filterSubType : "onTimePerformance",
                      filterTicketType : event.point.color == "#fc0303" ? "PastDue" : "OnTime"
                    },
                    event.point.color == "#fc0303" ? "Past Due" : "On Time")
                  }
                }
              },
        }
      },
          xAxis: { categories: categories || []},
          yAxis: { title: { text : "On Time Tickets"}},
          tooltip: { shared: true, pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>' },
          accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
          series: sComplete,
          credits: {enabled: false},
          legend: {enabled: true}
        }}
      />
    );
  }
  
  OnTimePerformance.propTypes = {
    refreshKey : PropTypes.number,
    dateFrom : PropTypes.string,
    dateTo : PropTypes.string,
    data : PropTypes.array,
    formData : PropTypes.array,
    filterCallback : PropTypes.func,
    useModified: PropTypes.bool
  }
    
  export default OnTimePerformance;