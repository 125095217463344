import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from "prop-types";

const CountByState = (props) => {
  const [graphData, setGraphData] = React.useState([{name: "No Data", y:1}]);
  const [chart, setChart] = React.useState(null);

  const chartCallback = c => setChart(c);

  React.useEffect(() => {
    if(chart){
      chart.reflow();
    }
  }, [props.refreshKey])

  React.useEffect(() => {
    if(props.data){
      let counts = {};
      let finalData = [];
      props.data.forEach(survey => {
        if (survey.State != null && survey.State != "") counts[survey.State] ? counts[survey.State] += 1 : counts[survey.State] = 1;
        else counts ["No State"] ? counts["No State"] += 1 : counts["No State"] = 1;
      });
      Object.keys(counts).forEach(key => {
        finalData.push({ name: key, y: counts[key] });
      });
      setGraphData(finalData.length > 0 ? finalData : [{ name: 'No Data', y: 1}]);
      console.log('graphData', graphData);
    }
  }, [props.data])

  return(
    <HighchartsReact
      containerProps={{ className: "w-100 h-100 border border-dark"}}
      highcharts={Highcharts}
      allowChartUpdate={true}
      callback={chartCallback}
      constructorType="chart"
      options={{
        chart: { type: 'pie', backgroundColor: "#E9E9EF"},
        title: {text: "State"},
        plotOptions: {
          pie: {
            allowPointSelect : true,
            cursor : 'pointer', 
            point: {
              events:{
                click: function(event){
                  props.filterCallback({ State: event.point.name}, `Showing Surveys from state: ${event.point.name}`);
                }
              }
            }
          }
        },
        series: graphData  ? [
          {
            name: "Surveys",
            type: 'pie',
            data : graphData
          }
        ] : [],
        credits: {enabled : false},
        legend : {enabled : false}
      }} 
    />
  );
}

CountByState.propTypes = {
  refreshKey : PropTypes.number,
  data : PropTypes.array,
  filterCallback : PropTypes.func
}


export default CountByState;