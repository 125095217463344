/* eslint react/prop-types: 0 */
import React from 'react';
import Button from 'react-bootstrap/Button';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateRange from "@material-ui/icons/DateRange";
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import DraggableArea from './DraggableArea';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import { BarLoader } from "react-spinners";
import auth from '../../common/Auth';
import moment from 'moment';
import { MDBDataTable } from 'mdbreact';

const SurveyType = {
  0 : "Survey",
  1 : "Incident",
  10: "External",
  11: "LPDamage",
  12: "Logo",
  13: "GPSSurvey",
  14: "Inaccessible",
  15: "Aoc",
  16: "Leak Survey",
  17: "Assignment Area",
  18: "Initial Audit",
  19: "100Audit",
  20: "Documentation Audit",
  21: "Field Audit",
  22: "Meter Inspection Outside",
  23: "Custom GIS",
  24: "Meter Inspection Inside",
  93: "Custom Form",
  25: "Inaccessible, Open, No Visit",
  26: "Inaccessible, Open, One Visit",
  27: "Inaccessible, Open, Two Visit",
  28: "Inaccessible, Open, Three+ Visit",
  Survey : 0,
  Incident : 1,
  External : 10,
  LPDamage : 11,
  Logo : 12,
  GPSSurvey : 13,
  LeakSurveyInaccessible : 14,
  LeakSurveyAoc : 15,
  LeakSurveyLeak : 16,
  LeakSurveyAssignmentArea : 17,
  LeakSurveyInitialAudit : 18,
  LeakSurvey100Audit : 19,
  LeakSurveyDocumentationAudit : 20,
  LeakSurveyFieldAudit : 21,
  LeakSurveyMeterInspection : 22,
  CustomPipeline : 23,
  LeakSurveyMeterInspectionInside : 23,
  LeakSurveyInaccessibleNoVisit : 25,
  LeakSurveyInaccessibleOneVisit : 26,
  LeakSurveyInaccessibleTwoVisit : 27,
  LeakSurveyInaccessibleThreePlusVisit : 28,
  CustomForm : 93
}

const columns = [
  {
    label: "",
    field: "DetailsBtn",
    cellStyle: {
      width: 100
    },
    headerStyle: {
      width: 100
    }
  },
  {
    label: "Address",
    field: "Address"
  },
  {
    label: "City",
    field: "City"
  },
  {
    label: "State",
    field: "State"
  },
  {
    label: "Survey Type",
    field: "SurveyTypeName"
  },
  {
    label: "Leak Class",
    field: "LeakClass"
  },
  {
    label: "Surface Type",
    field: "SurfaceType"
  },
  {
    label: "Material Type",
    field: "MaterialType"
  },
  {
    label: "Corrosion Grade",
    field: "CorrosionGrade"
  },
  {
    label: "Modified By",
    field: "ModifiedByDisplayName"
  },
  {
    label: "Created",
    field: "FormattedCreatedDate"
  },
  {
    label: "Updated",
    field: "FormattedModifiedDate"
  },
]

class LeakSurveyTab extends React.Component {
  constructor(){
    super();
    this.state = {
      dateTo: null,
      dateFrom: null,
      searchVal: "",
      toPickerOpen: false,
      fromPickerOpen: false,
      data: [],
      unfilteredData: [],
      userStats: [],
      refreshKey: 0,
      countsByType: {
        14: 0, // Inaccessible
        15: 0, // Aoc
        16: 0, // Leak Survey
        22: 0, // Meter Inspection outside
        23: 0, // Pipeline survey
        24: 0, // Meter Inspection inside
        25: 0, //InaccessibleNoVisit
        26: 0, //InaccessibleOneVisit
        27: 0, //InaccessibleTwoVisit
        28: 0, //InaccessibleThree+visits
      },
      surveysByPerson: [],
      footageCounts: [],
      surveysPerDay: [],
      dateRange: [],
      auditData: [],
      loading: false,
      // Graph Variables
      tableOpen: false,
      filteringTable: false,
      filterMessage: "",
      tableData: [],
      filterStatusValue : 'Any Status',
      useModifiedDate : false
    };
    this.filterStatusUpdate = this.filterStatusUpdate.bind(this);
  }

  handleDateToggle = e => {
    e.preventDefault();
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  }

  openFromDate = e => {
    e.preventDefault();
    this.setState({ fromPickerOpen: true });
  }

  openToDate = e => {
    e.preventDefault();
    this.setState({ toPickerOpen: true });
  }

  handleToDateChange = date => {
    date.setHours(new Date().getHours())
    date.setTime(date.getTime() + date.getTimezoneOffset()*60*1000);
    this.setState({ dateTo: date.toLocaleDateString(), toPickerOpen: false });
  }

  handleFromDateChange = date => {
    date.setHours(new Date().getHours())
    date.setTime(date.getTime() + date.getTimezoneOffset()*60*1000);
    this.setState({ dateFrom: date.toLocaleDateString(), fromPickerOpen: false });
  }

  getLeakSurveyData = (searchVal = "", dateFrom = null, dateTo = null) => {
    this.setState({ loading: true });

    let rb = {
      CompanyId : auth.companyId(),
      SearchParam : searchVal,
      ReturnLeakSurveyData: true,
      ReturnUserStatisticData : true,
      ReturnLeakSurveyAuditData: true,
      UserId: auth.employeeId(),
      DateFrom: dateFrom ? dateFrom : null,
      DateTo : dateTo ? dateTo : null,
      WebSearch : true,
      DashboardSearch: true,
      Offset: - new Date().getTimezoneOffset() /60,
      UseModifiedDate : this.state.useModifiedDate
    }

    fetch(process.env.API_URL + "/api/search", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(rb)
    })
    .then(res => res.json())
    .then(r  =>  {
      if(r.length > 0){
        let response = JSON.parse(r);
        // console.log("Leak Survey Tab Response : ", response);
        let d = this.getTableData(response.LeakSurveyCollection);
        let auditData = response.LeakSurveyAuditCollection;
        let userStats = response.UserStatisticCollection;
        this.footageGraphSetup(userStats);
        this.setupSurveyCollection(response.LeakSurveyCollection);
        this.setupTableData(d);
        let dateRange = this.getDateRange(dateFrom, dateTo); // x axis labels
        this.setState({ data: response.LeakSurveyCollection, unfilteredData : response.LeakSurveyCollection, tableData: d, userStats, dateRange, auditData });

        let wasError = false;
        try{
          const { dateFrom, dateTo, searchVal } = this.state;
          this.props.saveDataLocally({dateFrom, dateTo, searchVal, data: response.LeakSurveyCollection, unfilteredData : response.LeakSurveyCollection}, "leaksurvey-collection");
          this.props.saveDataLocally(response.UserStatisticCollection, "leaksurvey-userStatsCollection");
          this.props.saveDataLocally(response.LeakSurveyAuditCollection, "leaksurvey-auditcollection");
          this.props.saveDataLocally(dateRange, "leaksurvey-daterange");
          if(this.state.filterStatusValue != -1){
            this.filterStatusUpdate({target :{value : this.state.filterStatusValue}});
          }
        }catch(err){ 
          console.log("Unable to store all data : ", err);
          wasError = true;
        }

        if(wasError){
          console.log("Removing cached leak survey data");
          localStorage.removeItem("dashboard-leaksurvey-collection");
          localStorage.removeItem("dashboard-leaksurvey-userStatsCollection");
          localStorage.removeItem("dashboard-leaksurvey-auditcollection");
          localStorage.removeItem("dashboard-leaksurvey-daterange");
        }
      }else{
        // handle empty response
      }
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      this.setState({ refreshKey : this.state.refreshKey + 1, loading: false });
    })

  }

  setupTableData = (leakData, shouldApplyFilters = false) => {
    let final = leakData.map(item => ({ 
      ...item, 
      SurveyTypeName: SurveyType[item.LeakSurveyTypeId], 
      FormattedModifiedDate: new Date(item.ModifiedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}),
      FormattedCreatedDate: new Date(item.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}),
      DetailsBtn: <Link to={{ pathname: item.FormData ? '/formdata': '/leaksurveydetails', leakSurvey: item, formData : item}} className="anchorButton padded">DETAILS</Link>
    }));
    this.setState({ tableData: final }, () => {
      if(shouldApplyFilters){
        this.applySavedFilters();
      }
    });
    return final;
  }

  getTableData = leakData => {
    leakData.forEach((leak) =>{
      if(leak.LeakSurveyTypeId == 22 && leak.MeterInside){
        leak.LeakSurveyTypeId = 24;
      }
    });
    let final = leakData.map(item => ({ 
      ...item, 
      SurveyTypeName: SurveyType[item.LeakSurveyTypeId], 
      FormattedModifiedDate: new Date(item.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}),
      DetailsBtn: <Link to={{ pathname: '/leaksurveydetails', leakSurvey: item}} className="anchorButton padded">DETAILS</Link>
    }));
    this.setState({ tableData: final })
    return final;
  }

  getDateRange = (startDate, stopDate) => {
    let dateArray = [];
    let fromDate = moment(startDate, "M/D/YYYY");
    let toDate = moment(stopDate, "M/D/YYYY");
    while (fromDate <= toDate) {
        dateArray.push( moment(fromDate).format('YYYY-MM-DD') )
        fromDate = moment(fromDate).add(1, 'days');
    }
    return dateArray;
  }

  setupSurveyCollection = data => {
      let counts = {};
      let final = [];
      let finalTypeCount = {
        14: 0, // Inaccessible
        15: 0, // Aoc
        16: 0, // Leak Survey
        22: 0, // Meter Inspection outside
        23: 0, // PipelineSurvey
        24: 0, // Meter Inspection inside
        25: 0, //InaccessibleNoVisit
        26: 0, //InaccessibleOneVisit
        27: 0, //InaccessibleTwoVisit
        28: 0, //InaccessibleThree+visits
      };
      let inaccessibles = [];
      data.forEach(s =>{
        if(s.LeakSurveyTypeId == 14 && s.Status == "Open"){
          let newS = JSON.parse(JSON.stringify(s)); //Create a new Copy of s so that any changes to newS aren't also applied to s.
          switch(s.TaskCounter){
            case 0:
              newS.LeakSurveyTypeId = 25;
              break;
            case 1:
              newS.LeakSurveyTypeId = 26;
              break;
            case 2:
              newS.LeakSurveyTypeId = 27;
              break;
            default:
              newS.LeakSurveyTypeId = 28;
              break;
          }
          inaccessibles.push(newS);
        }
      });
      data = data.concat(inaccessibles);
      data.forEach(s => {
        counts[s.ModifiedByDisplayName] ? counts[s.ModifiedByDisplayName] += 1 : counts[s.ModifiedByDisplayName] = 1;
        let c = finalTypeCount[s.LeakSurveyTypeId];
        let toAdd = 1;
        if(c){
          finalTypeCount[s.LeakSurveyTypeId] = c + toAdd;
        }else{
          finalTypeCount[s.LeakSurveyTypeId] = toAdd;
        }
      });
      Object.keys(counts).forEach(key =>  final.push([key, counts[key]]));
      this.setState({ surveysByPerson: final, countsByType: finalTypeCount });
  }

  footageGraphSetup = data => {
    let counts = {};

    data.forEach(item => {
      let count = counts[item.UserDisplayName];
      if(count){
        // changed to use distance instead of just count of points
        counts[item.UserDisplayName] += item.TotalDistance;
      }else
      {
        counts[item.UserDisplayName] = item.TotalDistance
      }
    });
    
    // convert from meters to feet at end
    let final = Object.keys(counts).map(key => [key, parseFloat((counts[key] * 3.28084).toFixed(2))]);
    this.setState({ footageCounts: final })
  }

  handleSearchChange = e => {
    e.preventDefault(); 
    this.setState({ searchVal: e.target.value });
  }

  handleSearch = e => {
    e.preventDefault();
    let {searchVal, dateFrom, dateTo} = this.state;
    this.setState({ filterMessage: "", filteringTable: false });
    localStorage.removeItem("dashboard-leaksurvey-filter");
    this.getLeakSurveyData(searchVal, dateFrom, dateTo);
  }

  componentDidMount(){
    let today = new Date(); today.setDate(today.getDate());
    let d = new Date(); d.setDate(d.getDate() - 7); // Default to 1 week
    this.setState({ dateFrom: d.toLocaleDateString(), dateTo: today.toLocaleDateString() });
    let isSavedCollection = this.props.getLocallySavedDashboard("leaksurvey-collection");
    let isSavedUserStatsCollection = this.props.getLocallySavedDashboard("leaksurvey-userStatsCollection");
    let isSavedAuditCollection = this.props.getLocallySavedDashboard("leaksurvey-auditcollection");
    let isSavedDateRange = this.props.getLocallySavedDashboard("leaksurvey-daterange");
    if((isSavedCollection.data && isSavedCollection.dateTo && isSavedCollection.dateFrom)){
      let leakSurveyData = isSavedCollection.data || [];
      let userStats = isSavedUserStatsCollection || [];
      let auditData = isSavedAuditCollection || [];
      let dateRange = isSavedDateRange || [];
      this.footageGraphSetup(userStats);
      this.setupSurveyCollection(leakSurveyData);
      this.setupTableData(leakSurveyData, true);
      this.setState({
        userStats, 
        dateRange, 
        auditData,
        data: leakSurveyData,
        unfilteredData : leakSurveyData,  
        dateFrom: isSavedCollection.dateFrom, 
        dateTo: isSavedCollection.dateTo,
        searchVal: isSavedCollection.searchVal
      });
    }else{
      this.getLeakSurveyData("", d.toLocaleDateString(), today.toLocaleDateString());
    }
    window.resize();
  }

  toggleTableOpen = e => {
    e.preventDefault();
    this.setState({ tableOpen: !this.state.tableOpen });
  }

  componentDidUpdate(){
    window.resize();
  }

  cancelFilters = (e = null) => {
    e&&e.preventDefault();
    this.setupTableData(this.state.data);
    this.setState({ filterMessage: "", filteringTable: false });
    localStorage.removeItem("dashboard-leaksurvey-filter");
  }

  filterTable = (filters, message = "Applied Filters") => {
    this.setState({ tableOpen: true, filteringTable: true });
    let tableDataCopy = [...this.state.data];
    
    Object.keys(filters).forEach(fieldName => {
      if(fieldName === "CreatedDate"){
        tableDataCopy = tableDataCopy.filter(item => moment(item["CreatedDate"]).format("YYYY-MM-DD") === filters["CreatedDate"]);
      }
      else if(fieldName === "ModifiedDate"){
        tableDataCopy = tableDataCopy.filter(item => moment(item["ModifiedDate"]).format("YYYY-MM-DD") === filters.ModifiedDate);
      }else{
        tableDataCopy = tableDataCopy.filter(item => item[fieldName] === filters[fieldName]);
      }
    });

    this.setupTableData(tableDataCopy);
    this.setState({ filteringTable: false, filterMessage: message });
    
    // TODO: add logic to save filter to cache task 821
    localStorage.setItem("dashboard-leaksurvey-filter", JSON.stringify({filters, message}));
  }

  applySavedFilters = () => {
    let savedFilter = localStorage.getItem("dashboard-leaksurvey-filter");
    if(savedFilter !== null){
      let filter = JSON.parse(savedFilter);
      this.filterTable(filter.filters, filter.message);
    }
  }

  filterStatusUpdate(e){
    this.setState({ tableOpen : true, filteringTable : true});
    let tableDataCopy = [...this.state.unfilteredData];
    switch(e.target.value){
      case "Any Status":
        break;
      case "Open":
        tableDataCopy = tableDataCopy.filter(item => item.Status == "Open");
        break;
      case "Closed":
        tableDataCopy = tableDataCopy.filter(item => item.Status == "Closed");
        break;
      case "Assigned":
        tableDataCopy = tableDataCopy.filter(item => item.AssignedUserId != null);
        break;
      case "Unassigned":
        tableDataCopy = tableDataCopy.filter(item => item.AssignedUserId == null);
        break;
      default:
        break;
    }
    this.setupTableData(tableDataCopy);
    this.setupSurveyCollection(tableDataCopy);
    this.setState({filteringTable : false, filterMessage : "Applied Filters" ,filterStatusValue : e.target.value, data : tableDataCopy});
  }

  render() { 
    const {
      dateTo, 
      dateFrom, 
      toPickerOpen, 
      fromPickerOpen, 
      data, 
      refreshKey, 
      surveysByPerson, 
      footageCounts, 
      loading,
      dateRange,
      auditData,
      tableOpen,
      filteringTable,
      filterMessage,
    } = this.state;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {/* Search Bar */}
        <div className="w-100 d-flex flex-row justify-content-center align-items-center counters-container">
          <div className="d-flex align-items-center flex-row "style={{paddingRight:10, width:500}}>
            <input onChange={this.handleSearchChange} value={this.state.searchVal} className="form-control searchInput" type="search" placeholder="Search" />
            <Button onClick={this.handleSearch} className="buttonAction btn btn-primary buttonSearch">
              <SearchSharpIcon className="buttonIcon light large"/>
            </Button>
          </div>

          <div className="d-flex flex-row">
            <select id="filterStatus" type="text" ref={x => (this.filterStatusValue = x)}
            onChange={this.filterStatusUpdate} className="form-control filterInput short" value={this.state.filterStatusValue}>
              <option value='-1' defaultValue>Any Status</option>
              <option value='Open'>Open</option>
              <option value='Closed'>Closed</option>
              <option value='Assigned'>Assigned</option>
              <option value='Unassigned'>Unassigned</option>
            </select>
          </div>

          <div className="d-flex flex-row">
            <Button onClick={this.openFromDate} name="fromPickerOpen" className="buttonAction btn btn-primary dehighlighted">
              <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
              <label className="buttonText withIcon date" id="FromDate" style={{ margin: 0, cursor: "pointer" }}>
                {dateFrom}
              </label>
            </Button>
            <label className="labelSubHeader4 labelSearchBar" >-</label>
            {fromPickerOpen && ( <DatePicker onChange={this.handleFromDateChange} onClose={() =>{this.setState({fromPickerOpen : false})}} open={this.state.fromPickerOpen} />)}
            <Button onClick={this.openToDate} name="toPickerOpen" className="buttonAction btn btn-primary dehighlighted">
              <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
              <label className="buttonText withIcon date" id="ToDate" style={{ margin: 0, cursor: "pointer" }}>
                {dateTo}
              </label>
            </Button>
            { toPickerOpen && ( <DatePicker onChange={this.handleToDateChange} onClose={() =>{this.setState({toPickerOpen : false})}} open={this.state.toPickerOpen} /> )}
            <div className="dateOptionDiv">
              <input id="useCreatedDateButton" className='dateOptionRadio' type = "radio"checked={!this.state.useModifiedDate} onChange={() => {this.setState({useModifiedDate: false})}}/>
              <label htmlFor="useCreatedDateButton" className='dateOptionLabel'>Created Date</label>
              <br/>
              <input id="useModifiedDateButton" className='dateOptionRadio'type = "radio"  checked={this.state.useModifiedDate} onChange={() => {this.setState({useModifiedDate: true})}}/>
              <label htmlFor="useModifiedDateButton" className='dateOptionLabel'>Modified Date</label>
            </div>
          </div>
        </div>
        {/* Main Content */}
        {loading ? 
        <div className="row mapspin">
          <div className="spinnerDiv">
            <div className="authSpinnerDiv">
              <div className="authSpinnerDivMessage">Loading...</div>
              <div className="barLoaderPortal">
                <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading={true}/>
              </div>
            </div>
          </div>
        </div> : null}
        <div className="draggable-grid-container">
          {
            loading ? null :
            data.length < 1 && footageCounts.length < 1
            ?
            <div className="d-flex justify-content-center align-items-center">
              <label className="labelHeader">No Data</label>
            </div>
            :
            <div id="leak-draggable-container">
            <DraggableArea
              tableOpen={tableOpen}
              key={refreshKey} 
              data={this.state.data} 
              countsByType={this.state.countsByType} 
              surveysByPerson={surveysByPerson} 
              footageCounts={footageCounts} 
              dateRange={dateRange}
              auditData={auditData}
              filterCallback={this.filterTable}
            />
            <button
              onClick={(e) => this.toggleTableOpen(e)}
              className={`text-center border border-dark d-flex align-items-center justify-content-center w-100`} 
              style={{height: "40px"}}
            >
              {tableOpen ? <ExpandMoreIcon title="Open"/> : <ExpandLessIcon title="Close"/>}
            </button>
            {
              tableOpen &&
              <div style={{ maxHeight: "calc(70% - 40px)", overflowY: "scroll", padding:8, fontSize:15, fontWeight:600 }} id="leak-dashboard-table-con">
                {
                  filteringTable && (
                    <div className="row mapspin">
                      <div className="spinnerDiv">
                        <div className="authSpinnerDiv">
                          <div className="authSpinnerDivMessage">Filtering Table...</div>
                          <div className="barLoaderPortal">
                            <BarLoader sizeUnit={"px"} size={150} color={"#095399"}loading={true}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                {
                  filterMessage && (
                  <div className="datatable-filter-message">
                    <span>{filterMessage}</span>
                    <button onClick={this.cancelFilters} className="ml-2 p-0 btn btn-link h-auto">Clear Filter</button>
                  </div>)
                }
                <MDBDataTable
                  id="leak-dashboard-table" 
                  className="w-100 leak-dashboard-table h-100" 
                  data={{columns: columns, rows: this.state.tableData || []}} 
                  small responsiveMd searching btn striped bordered hover 
                  sortable paging={false} searchLabel=""
                />
              </div>
            }
            </div>
          }
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default LeakSurveyTab;