/* eslint react/prop-types: 0 */
import React from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import { BarLoader } from 'react-spinners';
import { MDBDataTable } from 'mdbreact';
import GetApp from "@material-ui/icons/GetApp";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import auth from '../../common/Auth';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateRange from '@material-ui/icons/DateRange';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ContactGraph from './ContactGraph';
import parseTable from './parseTable';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

const columns = [
  {
    label: "id",
    field: "id",
    sort: "disabled"
  },
  {
    label: "",
    field: "btn",
    sort: "disabled"
  },
  {
    label: 'Scanned Name',
    field: 'UserScannedName',
  },
  {
    label: 'Scanner Name',
    field: 'ScannerName',
  },
  {
    label: 'Scanned',
    field: 'ModifiedCreatedDate',
    sort: "asc"
  },
  {
    label: 'Results',
    field: 'ScanResult',
  },
  {
    label: 'Latitude',
    field: 'Latitude',
  },
  {
    label: 'Longitude',
    field: 'Longitude',
  }
]

class SiteSafeTab extends React.Component {
  constructor(){
    super();
    this.state = {
      searchVal: "",
      loading: false,
      tableData: [],
      leakData: [],
      open: false,
      dateFrom: null,
      dateTo: null,
      dialogOpen: false,
      selectedRow: null,
      counts: {
        pass: 0,
        incomplete: 0,
        fail: 0,
        notemperature: 0,
      },
      nodesToShow: {
        "Passed": true,
        "Not Completed": true,
        "Failed": true
      },
      errorOpen: false,
      rowCount: 1000,
      width: window.innerWidth
    }
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  }

  componentDidMount(){
    let today = new Date(); today.setDate(today.getDate());
    let d = new Date(); d.setDate(d.getDate() - 1);
    this.setState({ dateFrom: d.toLocaleDateString(), dateTo: today.toLocaleDateString() });
    let isSavedValue = this.props.getLocallySavedDashboard("sitesafe");
    if(isSavedValue.data && isSavedValue.dateTo && isSavedValue.dateFrom){
      this.setState({ dateFrom: isSavedValue.dateFrom, dateTo: isSavedValue.dateTo, searchVal: isSavedValue.searchVal });
      this.setupTable(isSavedValue.data);
    } else{
      this.getScanData("", d, today);
    }
    window.addEventListener("resize", this.updateSize);
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.updateSize);
  }

  updateSize = () => {this.setState({ width: window.innerWidth })}

  getColor = (status) => status === "Passed" ? "#2D7952" : status === "Failed" ? "#8A4A4A" : "#DE8C26";

  getScanData = (searchVal = "", dateFrom = null, dateTo = null) => {
    this.setState({ loading: true });
    let offset = moment().utcOffset();
    let rb = {
      CompanyId : auth.companyId(),
      SearchParam : searchVal,
      ReturnSiteSafeScanData: "true",
      UserId: auth.employeeId(),
      DateFrom: dateFrom ? moment(dateFrom).subtract(offset, "minutes").toDate().toLocaleString() : null,
      DateTo : dateTo ? moment(dateTo).subtract(offset, "minutes").toDate().toLocaleString() : null,
      // DateFrom: dateFrom ? moment(dateFrom).format("M/D/YYYY") : null,
      // DateTo: dateTo ? moment(dateTo).format("M/D/YYYY") : null
      // DateFrom: dateFrom ? moment(dateFrom).toDate().toLocaleDateString() : null,
      // DateTo: dateTo ? moment(dateTo).toDate().toLocaleDateString() : null,
      WebSearch : true
    }
    // console.log("Request Body : ", rb);
    fetch(process.env.API_URL + "/api/search", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(rb)
    })
    .then(res => res.json())
    .then(r => {
      if(r.length > 0){
        let response = JSON.parse(r);
        // console.log("SiteSafe Response", response);
        let filtered = this.getDistinctScans(response.SiteSafeScanCollection);
        this.setupTable(filtered);
        // let sorted = filtered.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate));
        // this.props.saveDataLocally(sorted, "sitesafe");
        // // Adds View button and modified date field for table
        // // sorted.map if using custom table
        // let td = sorted.map(item => {
        //   item.btn = <span className="d-flex">
        //   {/* <Link style={{ backgroundColor: this.getColor(item.ScanResult) }} className="anchorButton padded" to={{pathname: '/siteSafeDetails', siteSafe: item}}>View</Link> */}
        //   <FindInPageIcon onClick={(e) => {e.preventDefault(); this.handleFindInGraph(item.UserScanned)}} style={{color: this.getColor(item.ScanResult)}}/>
        //   </span>;
        //   item.ModifiedCreatedDate = new Date(item.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
        //   item.id = item.Id;
        //   return item;
        // });
        // // console.log("Table Data", td);
        // this.setState({ tableData: td });
        // this.updateCounts(td);
      }
    })
    .catch(err => {
      console.log("getScanData Error :",err);
      this.setState({ errorOpen: true })
    })
    .finally(() => {
      this.setState({ loading: false });
      // URL Parameters for saving search value, etc after leaving page
      // this.props.history.push(`/dashboard/sitesafe?searchVal=${this.state.searchVal}&dateFrom=${this.state.dateFrom}&dateTo=${this.state.dateTo}`);
    })
  }

  setupTable = (filtered) => { // locally saved data is already filtered for sitesafe dashboard
    let sorted = filtered.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate));
    const { dateFrom, dateTo, searchVal } = this.state;
    let today = new Date(); today.setDate(today.getDate());
    let d = new Date(); d.setDate(d.getDate() - 1);
    this.props.saveDataLocally({ dateFrom: dateFrom ? dateFrom : d, dateTo: dateTo ? dateTo : today, searchVal, data: sorted }, "sitesafe");
    // Adds View button and modified date field for table
    // sorted.map if using custom table
    let td = sorted.map(item => {
      item.btn = <span className="d-flex">
      {/* <Link style={{ backgroundColor: this.getColor(item.ScanResult) }} className="anchorButton padded" to={{pathname: '/siteSafeDetails', siteSafe: item}}>View</Link> */}
      <FindInPageIcon onClick={(e) => {e.preventDefault(); this.handleFindInGraph(item.UserScanned)}} style={{color: this.getColor(item.ScanResult)}}/>
      </span>;
      item.ModifiedCreatedDate = new Date(item.CreatedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
      item.id = item.Id;
      return item;
    });

    this.setState({ tableData: td });
    this.updateCounts(td);
    window.resize();
  }

  getDistinctScans = (scanData) => {
    let found = [];
    let filtered = [];
    scanData.map(scan => {
      if(!found.includes(scan.SiteSafeScanId)){
        found.push(scan.SiteSafeScanId);
        filtered.push(scan);
      }
    });
    return filtered;
  }

  handleFindInGraph = id => {
    this.setState({ selectedRow: id });
    setTimeout(() => {
      if(this.state.selectedRow === id){
        this.setState({ selectedRow: null });
      }
    }, 3000);
  }

  updateCounts = (data) => {
    let counts = {
      fail: 0,
      incomplete: 0,
      pass: 0,
      notemperature: 0
    };
    data.forEach(scan => {
      if(scan.ScanResult === "Not Completed"){
        counts.incomplete = counts.incomplete + 1;
      }
      else if(scan.ScanResult === "Failed"){
        counts.fail = counts.fail + 1;
      }
      else if(scan.ScanResult === "Passed"){
        counts.pass = counts.pass + 1;
      }
      else if(scan.ScanResult === "No Temperature"){
        counts.notemperature = counts.notemperature + 1;
      }
    });
    this.setState({ counts });
  }

  toggleStatus = (e, name) => {
    this.setState({ nodesToShow: {...this.state.nodesToShow, [name] : !this.state.nodesToShow[name]} });
  }

  handleSearchChange = e => {
    e.preventDefault();
    this.setState({ searchVal: e.target.value });
  }

  handleSearch = e => {
    e.preventDefault();
    this.getScanData(this.state.searchVal, this.state.dateFrom, this.state.dateTo);
  }

  openFromDate = e => {
    e.preventDefault();
    this.setState({ fromPickerOpen: true });
  }

  openToDate = e => {
    e.preventDefault();
    this.setState({ toPickerOpen : true });
  }

  handleFromDateChange = date => {
    date.setHours(new Date().getHours())
    date.setTime(date.getTime() + date.getTimezoneOffset() *60*1000);
    this.setState({ dateFrom : date.toLocaleDateString(), fromPickerOpen : false});
  }

  handleToDateChange = date => {
    date.setHours(new Date().getHours())
    date.setTime(date.getTime() + date.getTimezoneOffset() *60 *1000);
    this.setState({ dateTo : date.toLocaleDateString(), toPickerOpen : false});
  }

  hideError = () => {
    this.setState({ errorOpen: false });
  }

  handleClose = () => {this.setState({ dialogOpen: false });}

  downloadRequest = e => {
    e.preventDefault();
    this.setState({ dialogOpen: true });
    let body = {
      UserId: auth.employeeId(),
      CompanyId : auth.companyId(),
      EmailAddress: auth.userName(),
      SearchParam : this.state.searchVal,
      DateTo : this.state.dateTo.toLocaleString(),
      DateFrom : this.state.dateFrom.toLocaleString(),
      ReturnSurveyData : false,
      ReturnIncidentData : false,
      returnGPSSurveyData : false,
      ReturnLeakSurveyData : false,
      ReturnSiteSafeData : true,
      Offset: - new Date().getTimezoneOffset() /60,
    }
    // console.log("/data/batchDownload body", body);
    fetch(process.env.API_URL + "/api/data/batchDownload", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization :  "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r => { if (r.status ==  200 ){ this.setState({ dialogOpen: false }); }
    })
    .catch(error => {
      console.log(error);
    });
  }

  handleNodeClick = (id) => {
    // console.log(id);
    if(id !== null){
      this.setState({ open: true }, () => {
        // doing some weird things because scrollIntoView/scrollTop causes some unexpected css problems
        let rows = document.querySelectorAll("#scan-data-graph tr");
        let table = document.querySelector("#data-table");
        // console.log("Table :", table);
        let parsedRows = parseTable(table);
        // console.log("Parsed Table", parsedRows);
        let index = parsedRows.findIndex(i => i.id === id.toString());
        // console.log("Index", index);
        rows.forEach(row => row.style.border = "none");
        if(index >= 0) {
          let header = document.querySelector(".Header");
          // console.log("Rows :", rows);
          header.style.display = "none";
          let offsetTop = rows[index + 1].offsetTop;
          let parentNode = document.querySelector(".scan-data-container");
          // console.log("Parent Node", parentNode);
          parentNode.scrollTop = offsetTop;
          rows[index + 1].style.border = "2px solid #16548E";
          header.style.width = "100vw";
          header.style.zIndex = "9999";
          header.style.display = "block";
        }
      });
    }
  }

  render() { 
      const {
        open, 
        counts, 
        tableData, 
        dateTo, 
        dateFrom, 
        searchVal, 
        loading, 
        nodesToShow,
        errorOpen,
        leakData,
        selectedRow
      } = this.state;
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Snackbar open={errorOpen} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
          onClick={this.hideError} onClose={this.hideError}>

          <Alert onClose={this.hideError} severity="error" variant="filled">There was a problem recieving users</Alert>
        </Snackbar>
        <div className="w-100 d-flex counters-container flex-column flex-lg-row justify-content-between align-items-center p-0">
          <div style={{ width: 230, }} className="d-flex flex-row justify-content-between align-items-center barHeaderDiv m-0">
            <div className="w-25 d-flex flex-column align-items-center justify-content-center mr-2">
              <span style={{ backgroundColor: "rgb(45, 121, 82)" }} className="surveyCountBlock countBlock">{counts.pass}</span>
              <label className="labelHeaderSm labelCountBlock w-100 justify-content-center d-flex">Pass</label>
            </div>
            <div className="w-25 d-flex flex-column align-items-center justify-content-center mr-2">
              <span style={{ backgroundColor: "#8a4a4a" }} className="surveyCountBlock countBlock">{counts.fail}</span>
              <label className="labelHeaderSm labelCountBlock w-100 justify-content-center d-flex">Fail</label>
            </div>
            <div className="w-25 d-flex flex-column align-items-center justify-content-center mr-2">
              <span style={{ backgroundColor: "rgb(222, 140, 38)" }} className="surveyCountBlock countBlock">{counts.incomplete}</span>
              <label className="labelHeaderSm labelCountBlock w-100 justify-content-center d-flex">Incomp.</label>
            </div>
            <div className="w-25 d-flex flex-column align-items-center justify-content-center">
              <span style={{ backgroundColor: "#16548E" }} className="surveyCountBlock countBlock">{counts.pass + counts.fail + counts.incomplete + counts.notemperature}</span>
              <label className="labelHeaderSm labelCountBlock w-100 justify-content-center d-flex">Total</label>
            </div>
          </div>
          <div style={{ width: "calc(50% + 230px)" }} className="d-flex justify-content-between align-items-center flex-column flex-md-row">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <input onChange={this.handleSearchChange} value={searchVal} className="form-control searchInput" type="search" placeholder="Search" />
              <Button disabled={loading} onClick={this.handleSearch} className="buttonAction btn btn-primary buttonSearch">
                <SearchSharpIcon className="buttonIcon light large"/>
              </Button>
            </div>
            <div className="d-flex flex-row">
            <div className="d-flex flex-row">
              <Button onClick={this.openFromDate} name="fromPickerOpen" className="buttonAction btn btn-primary dehighlighted">
                <DateRange className="buttonIcon light" style={{marginRight: 4, marginBottom: 2}}/>
                <label className="buttonText withIcon date" id="FromDate" style={{ margin: 0, cursor : "pointer"}}>
                  {dateFrom}
                </label>
              </Button>
              <label className="labelSubHeader4 labelSearchBar">-</label>
              {this.state.fromPickerOpen && ( <DatePicker onChange={this.handleFromDateChange} onClose={ () => {this.setState({fromPickerOpen : false})}} open={this.state.fromPickerOpen}/>)}
              <Button onClick={this.openToDate} name="toPickerOpen" className="buttonAction btn btn-primary dehighlighted">
                <DateRange className="buttonIcon light" style={{marginRight: 4, marginBottom:2}}/>
                <label className="buttonText withIcon date" id="ToDate" style={{margin : 0, cursor : "pointer"}}>
                  {dateTo}
                </label>
              </Button>
                { this.state.toPickerOpen && ( <DatePicker onChange={this.handleToDateChange} onClose={ () => {this.setState({toPickerOpen : false})}} open={this.state.toPickerOpen} />)}
              </div>
              <Button onClick={this.downloadRequest} data-toggle="tooltip" title="Download Data" style={{width: 70, margin: "0px 10px"}} className="buttonAction btn btn-primary d-flex flex-row justify-content-center align-items-center">
                <GetApp className="buttonIcon light m-0"/>
                <label className={`buttonText withIcon small p-0 ${this.state.width < 1200 ? "d-none" : "d-block"}`} style={{ margin: 0, cursor: "pointer"}}>Data</label>
              </Button>
            </div>
          </div>
        </div>
        {loading ? 
        <div className="row mapspin" style={{zIndex: 3}}>
          <div className="spinnerDiv">
            <div className="authSpinnerDiv">
                <div className="authSpinnerDivMessage">Loading...</div>
              <br />
              <div className="barLoaderPortal">
                <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading={true}/>
              </div>
            </div>
          </div>
        </div> : ''}
        <div id="sitesafe-content-container">
            <div className="w-100 d-flex flex-column" style={window.innerWidth > 900 ? open? {height: "55%"} : {height: "100%"} : {}}>
              <div style={{height: "90%"}} className="d-flex w-100 align-items-start">
                <ContactGraph selectedId={selectedRow} nodeClick={this.handleNodeClick} open={open} data={tableData} leakData={leakData} toShow={nodesToShow} />
                <div className="d-flex flex-column network-filters p-1" style={{ width: "215px", marginLeft: "-215px", zIndex: 2 }}>
                  <button disabled={loading} onClick={e => this.toggleStatus(e, "Passed")} style={{ height: "35px", fontSize: "13px", backgroundColor: "rgb(45, 121, 82)" }} className="w-100 mt-1 esri-widget font-weight-bold text-white pl-3 py-1 d-flex align-items-center esri-layer-list__item-container">
                    {nodesToShow.Passed ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                    <span style={{marginLeft: "10px"}}>PASS</span>
                  </button>
                  <button disabled={loading} onClick={e => this.toggleStatus(e, "Not Completed")} style={{ height: "35px", fontSize: "13px", backgroundColor: "rgb(222, 140, 38)" }} className="w-100 mt-1 esri-widget font-weight-bold text-white pl-3 py-1 d-flex align-items-center esri-layer-list__item-container">
                    {nodesToShow["Not Completed"] ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                    <span style={{marginLeft: "10px"}}>INCOMPLETE</span>
                  </button>
                  <button disabled={loading} onClick={e => this.toggleStatus(e, "Failed")} style={{ height: "35px", fontSize: "13px", backgroundColor: "#8a4a4a" }} className="w-100 mt-1 esri-widget font-weight-bold text-white pl-3 py-1 d-flex align-items-center esri-layer-list__item-container">
                    {nodesToShow.Failed ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                    <span style={{marginLeft: "10px"}}>FAIL</span>
                  </button>
                </div>
              </div>
              <div 
                style={{cursor: "pointer", backgroundColor: "#E9E9EF", zIndex: 1, minHeight: 35, position: open ? "relative" : "fixed", bottom: open ? "auto" : 10}} 
                onClick={() => this.toggleOpen()} 
                className={`w-100 d-flex border border-secondary align-items-center ${open ? "justify-content-center" : "justify-content-start mt-auto"}`}
              >
                <div className={`w-50 d-flex justify-content-${open ? "center" : "between"} p-0 m-0`}>
                {!open&&<span style={{width: 100}} className="font-weight-bold pl-3">Details</span>}
                {
                  open ? <ExpandMoreIcon title="Collapse Table"/> : <ExpandLessIcon style={{marginRight: 100}} title="Open Table"/>              
                }
                </div>
            </div>
          </div>
          <Collapse in={open} className="scan-data-container">
            <div id="scan-data-graph">
              {/* onScroll={this.handleScroll}  <--- Use for lazy loading if I get it better */}
              {this.state.rowCount < tableData.length && <div style={{marginBottom: -20, padding: 2}}>Only displaying top {this.state.rowCount} results.</div>}
              <MDBDataTable 
                id="data-table"
                className="w-100 dashboard-sitesafe-datatable" 
                data={{columns: columns, rows: tableData || []}} 
                small responsiveMd searching btn striped bordered hover sortable paging={false}
              />
            </div>
          </Collapse>
        </div>
        <Dialog open={this.state.dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Data Export Request"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              It could take several minutes to collect your data, you will be emailed a download link when it is ready.  (est. 2-5 mins)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={() => this.handleClose()}>
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Cancel</label>
            </Button>
            <Button className="buttonAction btn btn-primary dehighlighted" onClick={() => this.handleClose()}>
              <label className="buttonText small" style={{ margin: 0, cursor: "pointer" }}>Accept</label>
            </Button>
          </DialogActions>
        </Dialog>
      </MuiPickersUtilsProvider>
    );
  }
}

export default SiteSafeTab;