/* eslint react/prop-types: 0 */
import React from 'react';
import auth from '../../common/Auth';
import ReactGridLayout, { WidthProvider } from 'react-grid-layout';
import Button from 'react-bootstrap/Button';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateRange from "@material-ui/icons/DateRange";
import { BarLoader } from "react-spinners";
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Widget Imports
import CountByTech from './CountByTech';
import RootCauses from './RootCauses';
import ExcavationWorkPerformed from './ExcavationWorkPerformed';
import DamagesByDate from './DamagesByDate';
import AtFaultWorkPerformed from './AtFaultWorkPerformed';
import FacilityOperationType from './FacilityOperationType';
import FacilityTypeSize from './FacilityTypeSize';
const GridLayout = WidthProvider(ReactGridLayout);

const defaultLayout = [
  {i: "countByTech", x: 0, y: 0, w: 6, h: 4, static: false, minW: 5, maxW: 12, minH: 4, maxH: 12},
  {i: "rootCauses", x: 6, y: 0, w: 6, h: 4, static: false, minW: 2, maxW: 12, minH: 2, maxH: 4},
  {i: "excavationEquipmentWorkPerformed", x: 0, y: 4, w: 6, h: 4, static: false, minW: 5, maxW: 12, minH: 4, maxH: 6},
  {i: "damagesByDate", x: 10, y: 10, w: 6, h: 4, static: false, minW: 5, maxW: 12, minH: 4, maxH: 6},
  {i: "atFaultWorkPerformed", x: 6, y: 4, w: 6, h: 4, static: false, minW: 5, maxW: 12, minH: 4, maxH: 6},
  {i: "facilityOperationType", x: 6, y: 8, w: 6, h: 4, static: false, minW: 3, maxW: 12, minH: 3, maxH: 6},
  {i: "facilityTypeSize", x: 0, y: 8, w: 6, h: 4, static: false, minW: 2, maxW: 12, minH: 2, maxH: 4},
];

const tableColumns = [
  {field: "DetailsBtn", label: ""},
  {field: "Address", label: "Address"},
  {field: "City", label: "City"},
  {field: "State", label: "State"},
  {field: "FacilityType", label: "Facility Type"},
  {field: "WorkPerformed", label: "Work Performed"},
  {field: "RootCauseList", label: "Root Cause"},
  {field: "ExcavatorType", label: "Excavator Type"},
  {field: "FormattedModifiedDate", label: "Update Date"},
  // Testing Fields Below
  // {field: "ModifiedByDisplayName", label: "Modified By"},
]

// Function to create mobile layouts
// const defaultMobileLayout = defaultLayout.map((widget, index) => ({ ...widget, w: 12, h: 4, minW: 12, minH: 4, static: true, y: index * 4, x: 0 }));

class InvestigationTab extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      layout: defaultLayout,
      data: [],
      dateFrom: null,
      dateTo: null,
      fromPickerOpen: false,
      toPickerOpen: false,
      searchVal: "",
      refreshKeys: {
        countByTech: 0,
        rootCauses: 0,
        excavationEquipmentWorkPerformed: 0,
        atFaultWorkPerformed: 0,
        facilityOperationType: 0,
        facilityTypeSize: 0,
        damagesByDate: 0
      },
      originalLayout: [],
      loadingLayout : false,
      // Table Variables
      isTableOpen: false,
      tableData: [],
      originalData: [],
      filterMessage: "",
      useModifiedDate : false
    }
  }

  componentDidMount(){
    let today = new Date(); today.setDate(today.getDate());
    let d = new Date(); d.setDate(d.getDate() - 7); // Default to 1 week
    this.setState({ dateFrom: d.toLocaleDateString(), dateTo: today.toLocaleDateString() }, () => {
      let isSavedValue = this.props.getLocallySavedDashboard("investigation");
      if(isSavedValue.data && isSavedValue.dateFrom && isSavedValue.dateTo){
        let finalData = this.setupTableRows(isSavedValue.data);
        this.setState({ data: finalData, tableData: finalData, originalData: finalData, dateFrom: isSavedValue.dateFrom, dateTo: isSavedValue.dateTo, searchVal: isSavedValue.searchVal });
      }else{
        this.getInvestigationData();
      }
    });
    // Getting users layout
    this.setState({ loadingLayout: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let requestBody = {
      UserId: auth.userId(),
      DashboardLayoutUpdate: false,
      User: {
        DashboardLayout: null,
        UserId: auth.userId()
      }
    };
    fetch(process.env.API_URL+"/api/account/dashboardlayout", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body: JSON.stringify(requestBody)
    })
      .then(res => res.json())
      .then(response => {
        let r = JSON.parse(response);
        if(r.DashboardLayout){
          let layouts = JSON.parse(r.DashboardLayout);
          // console.log("Draggable Area Original Layout : ", layouts);
          this.setState({ originalLayouts: layouts });
          if(layouts.investigation){
            let finalLayout = defaultLayout.map(item => {
              let userItem = layouts.investigation.find(userItem => userItem.i === item.i);
              if(userItem){
                return { ...item, x: userItem.x, y: userItem.y, w: userItem.w, h: userItem.h };
              }else{ return item }
            });
            this.setState({ layout: finalLayout });
          }else{
            this.setState({ layout: defaultLayout });
          }
        }else{
          this.setState({ layout: defaultLayout });
        }
      })
      .catch(error => console.log('error', error))
      .finally(() => this.setState({ loadingLayout: false }));
    window.resize();
  }

  getInvestigationData = () => {
    const { searchVal, dateFrom, dateTo } = this.state;
    this.setState({ loading: true });
    // let offset = moment().utcOffset();
    let rb = {
      CompanyId : auth.companyId(),  //auth.companyId() change when done
      SearchParam : searchVal,
      ReturnIncidentData: true,
      UserId: auth.employeeId(),
      DateFrom: dateFrom ? dateFrom : null,
      DateTo : dateTo ? dateTo : null,
      WebSearch : true,
      Offset: - new Date().getTimezoneOffset() /60,
      UseModifiedDate : this.state.useModifiedDate
    }
    // console.log("getInvestigationData Request Body", rb);
    fetch(process.env.API_URL + "/api/search", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + auth.token()
      },
      body: JSON.stringify(rb)
    })
    .then(res => res.json())
    .then(r => {
      if(r.length > 0){
        let response = JSON.parse(r);
        console.log("Investigation Data : ", response);
        let finalData = this.setupTableRows(response.InvestigationCollection);
        // console.log("Final Data : ", finalData);
        this.setState({ data: response.InvestigationCollection, originalData: finalData, tableData: finalData }); //response.InvestigationCollection
        const { dateTo, dateFrom, searchVal } = this.state;
        this.props.saveDataLocally({dateTo, dateFrom, searchVal, data: response.InvestigationCollection}, "investigation");
      }else{
        // handle empty response
      }
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => {
      this.setState({ loading: false })
    })
  }

  openFromDate = e => {
    e.preventDefault();
    this.setState({ fromPickerOpen: true });
  }

  openToDate = e => {
    e.preventDefault();
    this.setState({ toPickerOpen: true });
  }

  handleToDateChange = date => {
    date.setHours(new Date().getHours())
    date.setTime(date.getTime() + date.getTimezoneOffset()*60*1000);
    // console.log(date.toLocaleDateString());
    this.setState({ dateTo: date.toLocaleDateString(), toPickerOpen: false });
  }

  handleSearchChange = e => {
    e.preventDefault();
    this.setState({ searchVal: e.target.value });
  }

  handleFromDateChange = date => {
    date.setHours(new Date().getHours())
    date.setTime(date.getTime() + date.getTimezoneOffset()*60*1000);
    this.setState({ dateFrom: date.toLocaleDateString(), fromPickerOpen: false });
  }
  //eslint-disable-next-line
  handleResizeChange = (layout, oldItem, newItem, placeholder, e, element) => {
    e.preventDefault();
    // Changes refresh keys of widgets based on their id
    let item = newItem.i;
    this.setState({ 
      refreshKeys: {...this.state.refreshKeys, [item] : this.state.refreshKeys[item] + 1} 
    });
    this.saveLayout(layout);
  }

  saveLayout = (newLayout) => {
    // console.log("New Layout : ", newLayout)
    this.setState({ layout: newLayout });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let requestBody = {
      UserId: auth.userId(),
      DashboardLayoutUpdate: true,
      User: {
        DashboardLayout: JSON.stringify({
          ...this.state.originalLayouts,
          investigation: newLayout
        }),
        UserId: auth.userId()
      }
    };
    // console.log("Save Layout Request Body : ", requestBody);
    fetch(process.env.API_URL+"/api/account/dashboardlayout", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin" : "*",
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body: JSON.stringify(requestBody)
    })
      .then(res => res.json())
      //eslint-disable-next-line
      .then(response => {
        // successful call, do something?
      })
      .catch(error => console.log('error', error));
  }

  handleSubmit = e => {
    e.preventDefault();
    this.getInvestigationData();
  }

  componentDidUpdate(){
    window.resize();
  }

  toggleTableOpen = e => {
    e.preventDefault();
    this.setState({ isTableOpen: !this.state.isTableOpen });
  }

  setupTableRows = data => data.map(item => ({ 
    ...item, 
    FormattedModifiedDate: new Date(item.ModifiedDate + "Z").toLocaleString("en-us",{ month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"}),
    DetailsBtn: <Link to={{pathname: "/investigation", incident: item}} className="anchorButton padded">DETAILS</Link>,
  }))

  filterTable = (filters, message = "Applied Filters") => {
    // console.log("Investigation Filter Table : ", filters);
    let copy = [...this.state.originalData];
    Object.keys(filters).forEach(fieldName => {
      copy = copy.filter(item => {
        if(fieldName === "FormattedCreatedDate"){
          return moment(item.CreatedDate).format("MM/DD/YYYY") === filters[fieldName];
        }
        else if(fieldName === "CallOrWhiteLined"){
          return((!item.NotificationOneCallNotified || !item.WorkAreaWhiteLined));
        }
        else if(fieldName === "SingleRootCause"){
          return(item.RootCauseList.includes( filters[fieldName] ))
        }
        else if(fieldName === "FacilityNearMissFalsey"){
          return((item["FacilityNearMiss"] === null || item["FacilityNearMiss"] === 0));
        }
        return item[fieldName] === filters[fieldName]
      });
    })
    this.setState({ tableData: this.setupTableRows(copy), isTableOpen: true, filterMessage: message });
  }

  cancelFilters = e => {
    e.preventDefault();
    this.setState({ tableData: this.setupTableRows(this.state.originalData), filterMessage: "" })
  }

  render() {
    const { loading, layout, data, dateFrom, dateTo, 
      fromPickerOpen, toPickerOpen, 
      refreshKeys, loadingLayout, isTableOpen, filterMessage } = this.state;
    return (
      <MuiPickersUtilsProvider utils = {DateFnsUtils}>
      {/* Search Bar */}
      <div className="w-100 d-flex flex-row justify-content-center align-items-center counters-container">
        <div className="d-flex align-items-center flex-row "style={{paddingRight:10, width:500}}>
          <input onChange={this.handleSearchChange} value={this.state.searchVal} className="form-control  searchInput" type="search" placeholder="Search" />
          <Button onClick={this.handleSubmit} className="buttonAction btn btn-primary buttonSearch">
            <SearchSharpIcon className="buttonIcon light large"/>
          </Button>
        </div>

        <div className="d-flex flex-row">
          <Button onClick={this.openFromDate} name="fromPickerOpen" className="buttonAction btn btn-primary dehighlighted">
            <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
            <label className="buttonText withIcon date" id="FromDate" style={{ margin: 0, cursor: "pointer" }}>
              {dateFrom}
            </label>
          </Button>
          <label className="labelSubHeader4 labelSearchBar" >-</label>
          {fromPickerOpen && ( <DatePicker onChange={this.handleFromDateChange} onClose={()=>{this.setState({fromPickerOpen : false})}} open={this.state.fromPickerOpen} />)}
          <Button onClick={this.openToDate} name="toPickerOpen" className="buttonAction btn btn-primary dehighlighted">
            <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
            <label className="buttonText withIcon date" id="ToDate" style={{ margin: 0, cursor: "pointer" }}>
              {dateTo}
            </label>
          </Button>
          { toPickerOpen && ( <DatePicker onChange={this.handleToDateChange} onClose={()=>{this.setState({toPickerOpen : false})}} open={this.state.toPickerOpen} /> )}
          <div className="dateOptionDiv">
            <input id="useCreatedDateButton" className='dateOptionRadio' type = "radio"checked={!this.state.useModifiedDate} onChange={() => {this.setState({useModifiedDate: false})}}/>
            <label htmlFor="useCreatedDateButton" className='dateOptionLabel'>Created Date</label>
            <br/>
            <input id="useModifiedDateButton" className='dateOptionRadio'type = "radio"  checked={this.state.useModifiedDate} onChange={() => {this.setState({useModifiedDate: true})}}/>
            <label htmlFor="useModifiedDateButton" className='dateOptionLabel'>Modified Date</label>
          </div>
        </div>
      </div>

      <div id="investigation-tab-container" style={{ overflowY: "hidden" }}>
        {/* Loading Icon */}
        {loading || loadingLayout? 
        <div className="row mapspin">
          <div className="spinnerDiv">
            <div className="authSpinnerDiv">
              <div className="authSpinnerDivMessage">{loading ? "Loading..." : "Loading Layout..."}</div>
              <div className="barLoaderPortal">
                <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading={true}/>
              </div>
            </div>
          </div>
        </div> : null}
        {
          loading || loadingLayout
          ? null
          :
          <div id="investigation-content-area" className="h-100">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: isTableOpen ? "45%" : "calc(100% - 40px)", overflowY: "scroll"}}>
              {/* Draggable Area */}
              <GridLayout
                cols={12}
                id="dashboard-investigation-grid"
                className="w-100 h-100 d-flex justify-content-between hide-vertical-scrollbar"
                layout={layout}
                onLayoutChange={this.saveLayout}
                rowHeight={65}
                margin={[10, 10]}
                autoSize={false}
                onResizeStop={this.handleResizeChange}
              >
                <div key="countByTech"><CountByTech filterCallback={this.filterTable} refreshKey={refreshKeys.countByTech} data={data}/></div>
                <div key="rootCauses"><RootCauses filterCallback={this.filterTable} refreshKey={refreshKeys.rootCauses} data={data}/></div>
                <div key="excavationEquipmentWorkPerformed"><ExcavationWorkPerformed filterCallback={this.filterTable} refreshKey={refreshKeys.excavationEquipmentWorkPerformed} data={data}/></div>
                <div key="atFaultWorkPerformed"><AtFaultWorkPerformed filterCallback={this.filterTable} refreshKey={refreshKeys.atFaultWorkPerformed} data={data}/></div>
                <div key="damagesByDate"><DamagesByDate filterCallback={this.filterTable} refreshKey={refreshKeys.damagesByDate} dateFrom={dateFrom} dateTo={dateTo} data={data}/></div>
                <div key="facilityOperationType"><FacilityOperationType filterCallback={this.filterTable} refreshKey={refreshKeys.facilityOperationType} data={data}/></div>
                <div key="facilityTypeSize"><FacilityTypeSize filterCallback={this.filterTable} refreshKey={refreshKeys.facilityTypeSize} data={data}/></div>
              </GridLayout>
            </div>
            {/* Toggle Button */}
            <button onClick={this.toggleTableOpen} style={{height: 40, zIndex: 2}} className="text-center border border-dark d-flex align-items-center justify-content-center w-100">
              {isTableOpen ? <ExpandMoreIcon title="Open" /> : <ExpandLessIcon title="Close" />}
            </button>
            {/* Datatable Wrapper*/}
            {isTableOpen && <div style={{ height: "calc(55% - 40px)", overflowY: "scroll" }}>
              { 
                filterMessage && (
                <div className="datatable-filter-message">
                  <span style={{ padding: 6 }}>{filterMessage}</span>
                  <button onClick={this.cancelFilters} className="ml-2 p-0 btn btn-link h-auto">Clear Filter</button>
                </div>)
              }
              <MDBDataTable 
                id="investigation-dashboard-table"
                className="w-100 investigation-dashboard-table"
                data={{ columns: tableColumns, rows: this.state.tableData || [] }} 
                small responsiveMd searching btn striped 
                bordered hover sortable paging={false} searchLabel=""
              />
              </div>
            }
          </div>
        }
      </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default InvestigationTab;